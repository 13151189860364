import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateNumber from '../../../molecules/doubleStateField/DoubleStateNumber'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import KozmikTextField from '../../../atoms/kozmikTextField/KozmikTextField'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import relationTypes from '../../../../common/enums/relationTypes'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	inputLight: {
		borderRadius: 3,
		backgroundColor: '#ffffff',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const ProgressBar = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	/****TITLE***************************/
	const [title, setTitle] = useState('')
	const titleChange = (e) => {
		timeoutDelay(submitTitle, e.target.value, 1000)
		setTitle(e.target.value)
	}

	const submitTitle = (x) => {
		const modifiedElementData = update(props.elementData, {
			title: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	const numericFields = getEntityFieldsByDataTypes(
		props?.entities,
		props?.activeScreen.data?.source,
		['Number', 'Rollup'],
		null,
		true,
		relationTypes.one
	)

	/****VALUE***************************/
	const [value, setValue] = useState('')
	const valuceChange = (e) => {
		submitValue(e.target.value)
		setValue(e.target.value)
	}
	const submitValue = (x) => {
		const selectedField = numericFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				value: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****MIN_VALUE***************************/
	const [isMinTextMode, setIsMinTextMode] = useState(false)
	const [minField, setMinField] = useState('')
	const [minDefault, setMinDefault] = useState(0)

	const minChange = (data) => {
		if (isMinTextMode) {
			timeoutDelay(submitMin, data.target.value, 1000)
			setMinDefault(data.target.value)
		} else {
			submitMin(data?.value)
			setMinField(data?.value)
		}
	}
	const submitMin = (x) => {
		const modifiedElementData = update(props.elementData, {
			mapping: {
				minValue: {
					field: { $set: isMinTextMode ? null : x },
					default: { $set: isMinTextMode ? x : null }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****MAX_VALUE***************************/
	const [isMaxTextMode, setIsMaxTextMode] = useState(false)
	const [maxField, setMaxField] = useState('')
	const [maxDefault, setMaxDefault] = useState(0)

	const maxChange = (data) => {
		if (isMaxTextMode) {
			timeoutDelay(submitMax, data.target.value, 1000)
			setMaxDefault(data.target.value)
		} else {
			submitMax(data?.value)
			setMaxField(data?.value)
		}
	}
	const submitMax = (x) => {
		const modifiedElementData = update(props.elementData, {
			mapping: {
				maxValue: {
					field: { $set: isMaxTextMode ? null : x },
					default: { $set: isMaxTextMode ? x : null }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****CONTENT***************************/
		setValue(elementData?.mapping?.value?.field ?? '')
		/****MIN_VALUE***************************/
		elementData?.mapping?.minValue?.field ||
		elementData?.mapping?.minValue?.field === ''
			? setIsMinTextMode(false)
			: setIsMinTextMode(true)

		setMinField(elementData?.mapping?.minValue?.field || '')
		setMinDefault(elementData?.mapping?.minValue?.default || 0)
		/****MAX_VALUE***************************/
		elementData?.mapping?.maxValue?.field ||
		elementData?.mapping?.maxValue?.field === ''
			? setIsMaxTextMode(false)
			: setIsMaxTextMode(true)

		setMaxField(elementData?.mapping?.maxValue?.field || '')
		setMaxDefault(elementData?.mapping?.maxValue?.default || 0)
		/****DESIGN***************************/
		setTitle(elementData?.title ?? '')
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
		}
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.progressBar.value')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.progressBar.emptyField')}
								className={classes.input}
								onChange={valuceChange}
								value={value}
								data={numericFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					{/* <Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.progressBar.minValue')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateNumber
								fullWidth={true}
								isTextMode={isMinTextMode}
								setIsTextMode={setIsMinTextMode}
								valChange={minChange}
								textVal={minDefault?.toString()}
								autoCompVal={minField}
								autoCompOptions={numericFields}
								autoCompEmptyText={t(
									'screens.elementSettings.progressBar.emptyField'
								)}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid> */}
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.progressBar.maxValue')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateNumber
								fullWidth={true}
								isTextMode={isMaxTextMode}
								setIsTextMode={setIsMaxTextMode}
								valChange={maxChange}
								textVal={maxDefault?.toString()}
								autoCompVal={maxField}
								autoCompOptions={numericFields}
								autoCompEmptyText={t(
									'screens.elementSettings.progressBar.emptyField'
								)}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.progressBar.title')}</Label>
						</Grid>
						<Grid item xs={8}>
							<KozmikTextField fullWidth value={title} onChange={titleChange} />
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default ProgressBar

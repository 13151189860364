import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Popover, Grid } from '@mui/material'
import { DeleteOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import PopoverItem from '../popoverItem/PopoverItem'
import WarningDialog from '../dialog/warningDialog/WarningDialog'
import AccordionItem from '../../atoms/accordionItem/AccordionItem'
import { default as Label } from '../../atoms/settingLabel/SettingLabel'
import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'
import fieldDataTypes from '../../../common/fieldDataTypes'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	popoverBox: {
		padding: 8,
		width: 200
	},
	popoverIcon: {
		color: '#000000',
		opacity: 0.7,
		width: '14px'
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	labelBox: {
		width: 80,
		minWidth: 80
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#ffffff',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	}
}))

const ColumnListItem = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const {
		field,
		element,
		deleteColumn,
		activeScreen,
		index,
		columnTitleChange,
		entities,
		...restProps
	} = props

	const [elementPop, setElementPop] = useState(null)

	const openPop = (event) => {
		setElementPop(event.currentTarget)
	}

	const closePop = () => {
		setElementPop(null)
	}

	const elementPopOpen = Boolean(elementPop)

	const titleChange = (e) => {
		columnTitleChange(index, e.target.value)
	}

	return (
		<div {...restProps}>
			<AccordionItem
				title={field?.label || element?.mapping?.field}
				expanded={false}
				moreClick={openPop}
				bgColor='#F0F0F4'
			>
				<Grid container alignItems='center' className={classes.mb10}>
					<Grid item xs={4}>
						<Label>{t('screens.elementSettings.table.column.title')}</Label>
					</Grid>
					<Grid item xs={8}>
						<KozmikTextField
							fullWidth
							bgColor='#ffffff'
							value={element?.title?.default ?? ''}
							onChange={titleChange}
						/>
					</Grid>
				</Grid>
			</AccordionItem>
			<Popover
				open={elementPopOpen}
				anchorEl={elementPop}
				onClose={() => {
					closePop()
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<Box className={classes.popoverBox}>
					<PopoverItem
						text={t('screens.elementSettings.table.column.delete')}
						onClick={() => {
							closePop()
							deleteColumn()
						}}
						color='#FF2619'
					>
						<DeleteOutlined
							className={classes.popoverIcon}
							style={{ color: '#FF2619', opacity: 1 }}
						/>
					</PopoverItem>
				</Box>
			</Popover>
		</div>
	)
}

export default ColumnListItem

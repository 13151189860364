const convertBase64 = (file) => {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader()
		fileReader.readAsDataURL(file)
		// fileReader.onload = () => {
		// 	resolve(fileReader.result)
		// }
		fileReader.onloadend = () => {
			resolve({
				name: file.name,
				data: fileReader.result,
				info: file
			})
		}
		fileReader.onerror = (error) => {
			reject(error)
		}
	})
}

export default convertBase64

import { v4 as uuidv4 } from 'uuid'

import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import relationTypes from '../../common/enums/relationTypes'
import i18next from '../../i18n/i18n'

import filterModal from './filterModal'

const method = (table, searcheable = false, actions = null) => {
	const numericFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Number'],
		table,
		true,
		relationTypes.one
	)

	const numericField = numericFields?.length ? numericFields[0] : null

	const object = {
		_uid: uuidv4(),
		component: 'numbers',
		source: table?.name,
		mapping: {
			title: {
				field: null,
				type: null,
				default: i18next.t('schemas.numbers.text')
			},
			description: {
				field: null,
				type: null,
				default: i18next.t('schemas.numbers.text')
			},
			value: {
				field: numericField?.name,
				type: numericField?.dataType
			},
			aggregation: 'count'
		},
		filters: {
			groupOp: 'AND',
			rules: []
		},
		searcheable: searcheable,
		searchPlaceholder: {
			default: i18next.t('schemas.numbers.searchPlaceholder')
		},
		filterModal: filterModal(),
		actions: actions ?? [],
		limit: 10,
		background: 'none',
		spacing: 'xl'
	}

	return object
}

export default method

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import { Grid } from '@mui/material'
import cx from 'classnames'

import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'

import timeoutDelay from '../../../methods/timeoutDelay'
import { default as Checkbox } from '../../atoms/kozmikCheckBox/KozmikCheckBox'
import { default as Label } from '../../atoms/settingLabel/SettingLabel'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const SearchOptions = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	/****SEARCHEABLE***************************/
	const [searcheable, setSearcheable] = useState(false)

	const searcheableChange = (e) => {
		setSearcheable(e.target.checked)
		submitSearcheable(e.target.checked)
	}

	const submitSearcheable = (x) => {
		const modifiedElementData = update(props.elementData, {
			searcheable: { $set: x }
		})

		props.updateSelectedElement(modifiedElementData)
		// props.updateElementSearcheable(modifiedElementData)
	}

	/****SEARCH_PLACEHOLDER***************************/
	const [searchPlaceholder, setSearchPlaceholder] = useState('')

	const searchPlaceholderChange = (e) => {
		timeoutDelay(submitSearchPlaceholder, e.target.value, 1000)
		setSearchPlaceholder(e.target.value)
	}

	const submitSearchPlaceholder = (x) => {
		const modifiedElementData = update(props.elementData, {
			searchPlaceholder: {
				default: { $set: x }
			}
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****SEARCHEABLE***************************/
		setSearcheable(!!props?.elementData?.searcheable)
		/****SEARCH_PLACEHOLDER***************************/
		setSearchPlaceholder(
			props?.elementData?.searchPlaceholder?.default
				? props?.elementData?.searchPlaceholder?.default
				: ''
		)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
		}
	}, [props.elementData])

	return (
		<div>
			<Grid container alignItems='center' className={classes.mb10}>
				<div className={cx(classes.item)}>
					<div>
						<Checkbox checked={searcheable} onChange={searcheableChange} />
					</div>
					<div className={cx(classes.labelBox, classes.growBox)}>
						<Label>
							{t('screens.elementSettings.common.search.searcheable')}
						</Label>
					</div>
				</div>
			</Grid>
			<Grid container alignItems='center' className={classes.mb10}>
				<Grid item xs={4}>
					<Label>
						{t('screens.elementSettings.common.search.placeholder')}
					</Label>
				</Grid>
				<Grid item xs={8}>
					<KozmikTextField
						fullWidth
						placeholder={t(
							'screens.elementSettings.common.search.placeholderEx'
						)}
						value={searchPlaceholder}
						onChange={searchPlaceholderChange}
					/>
				</Grid>
			</Grid>
		</div>
	)
}

export default SearchOptions

const method = () => {
	let object = {
		title: {
			default: 'Filter'
		},
		style: 'transparent',
		buttons: {
			clear: {
				text: {
					default: 'Clear'
				},
				icon: null,
				corner: 'oval',
				type: 'transparent'
			},
			filter: {
				text: {
					default: 'Filter'
				},
				icon: null,
				corner: 'oval',
				type: 'full'
			}
		},
		body: []
	}

	return object
}

export default method

import React from 'react'

import MenuItem from '../menuItem/MenuItem'

const Menu = (props) => {
	const { menuItems } = props

	return (
		<>
			{menuItems.map((element, index) => (
				<MenuItem
					key={index}
					element={element}
					collapseHamburger={props?.collapseHamburger}
				/>
			))}
		</>
	)
}

export default Menu

import { v4 as uuidv4 } from 'uuid'

import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../common/fieldDataTypes'
import languages from '../../i18n/languages'
import relationTypes from '../../common/enums/relationTypes'
import getArrayExcludingForbidArray from '../../methods/getArrayExcludingForbidArray'
import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'

import filterModal from './filterModal'

const method = (table, searcheable = false, actions = null) => {
	const txtFields = getEntityFieldsByDataTypes(
		null,
		null,
		fieldDataTypes.textBased,
		table,
		true,
		relationTypes.one
	)
	const imgFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Image'],
		table,
		true,
		relationTypes.one
	)

	const filteredTxtFields = getArrayExcludingForbidArray(
		txtFields,
		'name',
		systemFieldNames.all
	)

	const titleField = txtFields?.find((x) => x.name == 'Name')
	const firstImgField = imgFields?.length ? imgFields[0] : null

	const object = {
		_uid: uuidv4(),
		component: 'list',
		title: '',
		source: table?.name,
		mapping: {
			id: {
				field: 'Id',
				type: 'RowId'
			},
			title: {
				field: titleField?.name,
				type: 'Text'
			},
			text: {
				field: filteredTxtFields?.[0]?.name,
				type: filteredTxtFields?.[0]?.dataType
			},
			subText: {
				field: filteredTxtFields?.[1]?.name,
				type: filteredTxtFields?.[1]?.dataType
			},
			imgUrl: {
				field: firstImgField?.name,
				type: firstImgField?.dataType
			},
			groupBy: {
				field: null,
				type: null
			}
		},
		groupBy: {
			showGroupsOnTop: true
		},
		sorting: {
			field: titleField?.name,
			sort: 'asc'
		},
		filters: {
			groupOp: 'AND',
			rules: []
		},
		searcheable: searcheable,
		searchPlaceholder: {
			default: languages.en.schemas.list.searchPlaceholder
		},
		filterModal: filterModal(),
		corner: 'oval',
		fontSize: 'md',
		height: 80,
		imgPosition: 'left',
		actions: actions ?? [
			{
				type: null
			}
		],
		limit: 100,
		seeAllUrl: null,
		seeAllText: {
			default: languages.en.schemas.list.seeAll
		},
		pageSize: 25,
		moreText: {
			default: languages.en.schemas.list.more
		},
		spacing: 'xl',
		emptyState: {
			default: languages.en.schemas.list.emptyState
		}
	}

	return object
}

export default method

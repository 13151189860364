const relationDisplayFieldTypes = [
	'RowId',
	'Text',
	'LongText',
	'Checkbox',
	'Number',
	'Image',
	'File',
	'Date',
	'Email',
	'Phone',
	'URL',
	'GeoLocation'
]

export default relationDisplayFieldTypes

import { v4 as uuidv4 } from 'uuid'

const method = (mappingKey = 'name', corner = 'oval', height = 48) => {
	let object = {
		_uid: uuidv4(),
		component: 'userInfo',
		height: height,
		fontSize: 'sm',
		spacing: 'xl',
		corner: corner, //'circle','oval','square'
		mapping: {
			text: {
				field: mappingKey,
				type: null
			}
		},
		icon: 'alert-circle'
	}

	return object
}

export default method

import {
	SETUSERINFO,
	SETGHOSTUSER,
	SETORG,
	SETCURRENTORG
} from '../action-types/userInfoActionTypes'

const initialState = {
	userInfo: null,
	ghostUser: null,
	org: [],
	currentOrg: null
}

const userInfoReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case SETUSERINFO:
			return { ...state, userInfo: action.payload }
		case SETGHOSTUSER:
			return { ...state, ghostUser: action.payload }
		case SETORG:
			return { ...state, org: action.payload }
		case SETCURRENTORG:
			return { ...state, currentOrg: action.payload }
		default:
			return state
	}
}

export default userInfoReducer

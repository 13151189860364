import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import cx from 'classnames'

const useStyles = makeStyles((theme) => ({
	toolbar: {
		width: '100px',
		textAlign: 'left'
	},
	justifyContent: {
		justifyContent: 'space-between'
	},
	count: {
		fontSize: '22px',
		lineHeight: '17px',
		textAlign: 'right'
	},
	flex: {
		display: 'flex'
	},
	marginTop: {
		marginTop: '8px'
	},
	root: {
		'& > span': {
			margin: theme.spacing(2)
		}
	},
	active: {
		color: `${theme.custom.colors.kozmikBlue} !important`,
		backgroundColor: theme.custom.colors.kozmikLightBlue,
		textDecoration: 'none !important',
		fontWeight: 'bolder',
		borderRadius: 4
	},
	passive: {
		color: theme.custom.colors.textPrimary,
		textDecoration: 'none !important'
	},
	text: {
		paddingLeft: '7px',
		fontSize: '14px',
		fontWeight: 600,
		lineHeight: '24px',
		width: '122px',
		textAlign: 'left'
	}
}))

const MenuItem = (props) => {
	const classes = useStyles()
	let currentPage = useLocation().pathname

	return (
		<Link to={props.element.Href} onClick={props?.collapseHamburger}>
			<div
				className={cx(
					classes.root,
					classes.marginTop,
					props.element.Href == currentPage ? classes.active : classes.passive
				)}
			>
				<Box
					display='flex'
					alignItems='center'
					justifyContent='space-between'
					height='40px'
					style={{ padding: '0 10px', overflow: 'hidden' }}
				>
					<Box display='flex' alignItems='center'>
						{props.element?.icon && (
							<props.element.icon style={{ fontSize: 18 }} />
						)}

						<Typography className={cx(classes.toolbar, classes.text)}>
							{props.element.Name}
						</Typography>

						{props.element.SubText ? (
							<Typography>{props.element.SubText}</Typography>
						) : null}
					</Box>
					{props.element.Href == currentPage ? (
						<Typography style={{ fontWeight: 600, fontSize: 14 }}>
							{props.element.Count}
						</Typography>
					) : null}
				</Box>
			</div>
		</Link>
	)
}

export default MenuItem

import React, { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Grid from '@mui/material/Grid'

import Tile from '../../molecules/tile/Tile'
import Label from '../../atoms/label/Label'
// import SearchHeader from '../../organisms/searchHeader/SearchHeader'

const useStyles = makeStyles((theme) => ({
	labelStyle: {
		color: '#000000',
		//fontFamily: 'OpenSans',
		fontSize: '18px',
		lineHeight: '22px',
		textAlign: 'left',
		display: 'block',
		fontWeight: 'bold',
		paddingTop: '3%'
	},
	root: {
		flexGrow: 1
	},
	tile: {
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		width: 217,
		[theme.breakpoints.down('sm')]: {
			width: '50%'
		}
	}
}))

const AppsTemplate = (props) => {
	const classes = useStyles()

	return (
		<>
			{/* <SearchHeader /> */}
			{/* <Grid container>
				<Grid container item md={10} spacing={3} style={{ paddingTop: '3%' }}> TODO*/}

			{props?.title && (
				<Label text={props.title} className={classes.labelStyle} />
			)}
			<Grid container spacing={2} style={{ paddingTop: 20 }}>
				{!!props?.fixData?.length &&
					props.fixData.map((item, index) => (
						<div key={`fix${index}`} className={classes.tile}>
							<Tile
								data={item}
								linkUrl={props?.linkUrl}
								deleteUrl={props?.deleteUrl}
							/>
						</div>
					))}
				{!!props?.data?.length &&
					props.data.map((item, index) => (
						<div key={index} className={classes.tile}>
							<Tile
								data={item}
								isInteractive={props.isInteractive}
								linkUrl={props?.linkUrl}
								deleteUrl={props?.deleteUrl}
								reload={props?.reload}
							/>
						</div>
					))}
			</Grid>
		</>
	)
}

export default AppsTemplate

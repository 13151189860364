import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

import Menu from '../../molecules/menu/Menu'
import Account from '../../organisms/account/Account'
import OrgSelection from '../../organisms/orgSelection/OrgSelection'

import { ReactComponent as KozmikLogo } from '../../../assets/icons/KozmikLogo.svg'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		borderRadius: 16,
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.03)',
		textAlign: 'left',
		backgroundColor: '#FFFFFF',
		padding: '20px 10px',
		justifyContent: 'space-between',
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	logo: {
		width: 100,
		height: 'auto',
		paddingLeft: 10,
		marginBottom: 20,
		[theme.breakpoints.down('md')]: {
			//For MasterLayout hamburger menu
			display: 'none'
		}
	}
}))

const LeftFrame = (props) => {
	const classes = useStyles()

	return (
		<div className={classes.mainContainer}>
			<div>
				<KozmikLogo className={classes.logo} />
				<OrgSelection />
				<Menu
					menuItems={props.menuItems}
					collapseHamburger={props?.collapseHamburger}
				/>
			</div>
			<div>
				<Account collapseHamburger={props?.collapseHamburger} />
			</div>
		</div>
	)
}

export default LeftFrame

import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateText from '../../../molecules/doubleStateField/DoubleStateText'
import DoubleStateMentions from '../../../molecules/doubleStateField/DoubleStateMentions'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import ActionList from '../../actionList/ActionList'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import validateWithRegExp from '../../../../methods/validateWithRegExp'
import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import dataTypeRegExes from '../../../../common/regExp/dataType'
import relationTypes from '../../../../common/enums/relationTypes'
import formulas from '../../../../common/formulas'

import { ReactComponent as KapakTamEkran } from '../../../../assets/icons/Settings/Kapak-TamEkran.svg'
import { ReactComponent as KapakKart } from '../../../../assets/icons/Settings/Kapak-Kart.svg'
import { ReactComponent as KapakProfil } from '../../../../assets/icons/Settings/Kapak-Profil.svg'
import { ReactComponent as ImageOdakli } from '../../../../assets/icons/Settings/Image-Odakli.svg'
import { ReactComponent as ImageOrtali } from '../../../../assets/icons/Settings/Image-Ortali.svg'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const TitleCard = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const propsRef = useRef()
	propsRef.current = props

	const [elementName, setElementName] = useState(null)

	const imageFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen?.data?.source,
		['Image'],
		null,
		true,
		relationTypes.one
	)
	const imageOptions = imageFields?.length ? imageFields : formulas.profile
	const imageOptionsWithEmpty = [{ label: '-', name: '' }, ...imageOptions]

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		fieldDataTypes.textBased,
		null,
		true,
		relationTypes.one
	)
	const textOptions = textFields?.length ? textFields : formulas.profile
	const textOptionsWithEmpty = [{ label: '-', name: '' }, ...textOptions]

	const appearanceOptions = [
		{
			text: t('screens.elementSettings.titleCard.appearOpts.full'),
			value: 'full',
			icon: KapakTamEkran
		},
		{
			text: t('screens.elementSettings.titleCard.appearOpts.card'),
			value: 'card',
			icon: KapakKart
		},
		{
			text: t('screens.elementSettings.titleCard.appearOpts.profile'),
			value: 'profile',
			icon: KapakProfil
		}
	]

	const resizeOptions = [
		{
			text: t('screens.elementSettings.image.resizeOpts.cover'),
			value: 'cover',
			icon: ImageOdakli
		},
		{
			text: t('screens.elementSettings.image.resizeOpts.contain'),
			value: 'contain',
			icon: ImageOrtali
		}
	]

	/****TITLE***************************/
	const [isTitleTextMode, setIsTitleTextMode] = useState(false)
	const [titleField, setTitleField] = useState('')
	const [titleDefault, setTitleDefault] = useState('')

	const titleChange = (data) => {
		if (isTitleTextMode) {
			submitTitle(data)
			setTitleDefault(data)
		} else {
			submitTitle(data?.value)
			setTitleField(data?.value)
		}
	}
	const submitTitle = (x) => {
		const selectedField = textOptions?.find((y) => y.name == x)
		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				title: {
					field: { $set: isTitleTextMode ? null : x },
					default: { $set: isTitleTextMode ? x : null },
					type: { $set: isTitleTextMode ? null : selectedField?.dataType }
				}
			}
		})

		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****TEXT***************************/
	const [isTextMode, setIsTextMode] = useState(false)
	const [textField, setTextField] = useState('')
	const [textDefault, setTextDefault] = useState('')

	const textChange = (data) => {
		if (isTextMode) {
			submitText(data)
			setTextDefault(data)
		} else {
			submitText(data?.value)
			setTextField(data?.value)
		}
	}
	const submitText = (x) => {
		const selectedField = textOptions?.find((y) => y.name == x)
		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				text: {
					field: { $set: isTextMode ? null : x },
					default: { $set: isTextMode ? x : null },
					type: { $set: isTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****IMG_URL***************************/
	const [isImgUrlTextMode, setIsImgUrlTextMode] = useState(false)
	const [imgUrlField, setImgUrlField] = useState('')
	const [imgUrlDefault, setImgUrlDefault] = useState('')
	const [imgUrlDefaultValid, setImgUrlDefaultValid] = useState(true)

	const imgUrlChange = (data) => {
		if (isImgUrlTextMode) {
			const imgUrlRegExp = dataTypeRegExes.Image.re

			validateWithRegExp(data.target.value, imgUrlRegExp).then((isValid) => {
				setImgUrlDefaultValid(isValid || data.target.value === '')
				if (isValid || data.target.value === '') {
					timeoutDelay(submitImgUrl, data.target.value, 1000)
				}
			})

			setImgUrlDefault(data.target.value)
		} else {
			submitImgUrl(data?.value)
			setImgUrlField(data?.value)
		}
	}
	const submitImgUrl = (x) => {
		const selectedField = imageOptions?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				imgUrl: {
					field: { $set: isImgUrlTextMode ? null : x },
					default: { $set: isImgUrlTextMode ? x : null },
					type: { $set: isImgUrlTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****APPEARANCE***************************/
	const [appearance, setAppearance] = useState(null)

	const appearanceChange = (val) => {
		submitAppearance(val)
		setAppearance(val)
	}

	const submitAppearance = (x) => {
		const modifiedElementData = update(props.elementData, {
			appearance: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****IMAGE_RESIZE_MODE***************************/
	const [imageResizeMode, setImageResizeMode] = useState(null)

	const imageResizeModeChange = (val) => {
		submitImageResizeMode(val)
		setImageResizeMode(val)
	}

	const submitImageResizeMode = (x) => {
		const modifiedElementData = update(props.elementData, {
			imageResizeMode: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ACTIONS***************************/
	const updateActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			actions: { $set: newActions }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****TITLE***************************/
		elementData?.mapping?.title?.field ||
		elementData?.mapping?.title?.field === ''
			? setIsTitleTextMode(false)
			: setIsTitleTextMode(true)

		setTitleField(elementData?.mapping?.title?.field || '')
		setTitleDefault(elementData?.mapping?.title?.default || '')

		/****TEXT***************************/
		elementData?.mapping?.text?.field ||
		elementData?.mapping?.text?.field === ''
			? setIsTextMode(false)
			: setIsTextMode(true)

		setTextField(elementData?.mapping?.text?.field || '')
		setTextDefault(elementData?.mapping?.text?.default || '')
		/****IMG_URL***************************/
		elementData?.mapping?.imgUrl?.field ||
		elementData?.mapping?.imgUrl?.field === ''
			? setIsImgUrlTextMode(false)
			: setIsImgUrlTextMode(true)

		setImgUrlField(elementData?.mapping?.imgUrl?.field || '')
		setImgUrlDefault(elementData?.mapping?.imgUrl?.default || '')
		/****APPEARANCE***************************/
		setAppearance(elementData?.appearance)
		/****IMAGE_RESIZE_MODE***************************/
		setImageResizeMode(elementData?.imageResizeMode)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		props?.elementData && initStates(props.elementData)
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.titleCard.title')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateMentions
								name={`${elementName}-title`}
								multiline={false}
								fullWidth={true}
								isTextMode={isTitleTextMode}
								setIsTextMode={setIsTitleTextMode}
								valChange={titleChange}
								textVal={titleDefault}
								autoCompVal={titleField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.titleCard.desc')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateMentions
								name={`${elementName}-desc`}
								multiline={false}
								fullWidth={true}
								isTextMode={isTextMode}
								setIsTextMode={setIsTextMode}
								valChange={textChange}
								textVal={textDefault}
								autoCompVal={textField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.titleCard.image')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateText
								fullWidth={true}
								textError={!imgUrlDefaultValid}
								isTextMode={isImgUrlTextMode}
								setIsTextMode={setIsImgUrlTextMode}
								textModePlaceholder={t(
									'screens.elementSettings.titleCard.textPlaceholder'
								)}
								valChange={imgUrlChange}
								textVal={imgUrlDefault}
								autoCompVal={imgUrlField}
								autoCompOptions={imageOptionsWithEmpty}
								uppyPicker
								uppyAllowedFileTypes={['image/*']}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.titleCard.style')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={appearanceOptions}
								getChange={appearanceChange}
								activeValue={appearance}
							/>
						</Grid>
					</Grid>
					{/* <Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.titleCard.imgUsage')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={resizeOptions}
								getChange={imageResizeModeChange}
								activeValue={imageResizeMode}
							/>
						</Grid>
					</Grid> */}
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.actions')}
					expanded={true}
				>
					<ActionList
						name={elementName}
						entities={props.entities}
						activeScreen={props.activeScreen}
						actions={props.elementData.actions}
						updateActions={updateActions}
					/>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default TitleCard

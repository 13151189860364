import { v4 as uuidv4 } from 'uuid'

import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../common/fieldDataTypes'
import i18next from '../../i18n/i18n'
import languages from '../../i18n/languages'
import getFirstFieldExcludingForbidArray from '../../methods/getFirstFieldExcludingForbidArray'
import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'

const method = (table) => {
	const txtFields = getEntityFieldsByDataTypes(
		null,
		null,
		fieldDataTypes.textBased,
		table
	)

	const firstTxtField = getFirstFieldExcludingForbidArray(
		txtFields,
		'name',
		systemFieldNames.notVisible
	)

	const object = {
		_uid: uuidv4(),
		component: 'noteBox',
		mapping: {
			note: {
				field: firstTxtField?.name,
				type: firstTxtField?.dataType
			},
			title: {
				field: null,
				default: i18next.t('schemas.noteBox.title'),
				type: null
			},
			placeholder: {
				field: null,
				default: i18next.t('schemas.noteBox.placeholder'),
				type: null
			}
		},
		submit: {
			text: {
				default: languages.en.schemas.noteBox.submit
			}
		},
		cancel: {
			text: {
				default: languages.en.schemas.noteBox.cancel
			}
		},
		editText: {
			default: languages.en.schemas.noteBox.edit
		},
		spacing: 'xl'
	}

	return object
}

export default method

import { v4 as uuidv4 } from 'uuid'
import update from 'immutability-helper'

const getIncrementedScreen = (allScreens, screen) => {
	const nameParts = screen.name.split(' ')
	const nameContainsNumber = !isNaN(nameParts[nameParts.length - 1])
	let newItemCreated = false

	let nameRoot = screen.name
	const nameLastNumberPart = nameRoot.match(/\d+$/)
	if (nameLastNumberPart?.length) {
		//NOTE:remove last number part
		nameRoot = nameRoot.substr(
			0,
			nameRoot.length - nameLastNumberPart[0].length
		)
	}

	// let pathRoot = screen.idPath ? screen.path.substr(0, screen.path.indexOf('/:id')) : screen.path
	let pathRoot = screen.path?.replace('/:id', '')

	const pathLastNumberPart = pathRoot.match(/\d+$/)
	if (pathLastNumberPart?.length) {
		//NOTE:remove last number part
		pathRoot = pathRoot.substr(
			0,
			pathRoot.length - pathLastNumberPart[0].length
		)
	}

	let number = 0
	let createdItem

	const key = uuidv4()

	if (nameContainsNumber) {
		number = nameParts[nameParts.length - 1] //current number
		nameRoot = nameParts.slice(0, nameParts.length - 1).join(' ')
	}

	while (!newItemCreated) {
		number++
		// const newPath = `${pathRoot}${number}${screen.idPath ? '/:id' : ''}`
		const newPath = screen.source
			? `${pathRoot}-${number}/:id`
			: `${pathRoot}-${number}`
		const samePathList = allScreens.filter(
			(x) =>
				x.path == `${pathRoot}-${number}/:id` ||
				x.path == `${pathRoot}-${number}`
		)

		if (!samePathList.length) {
			newItemCreated = true
			const newName = `${nameRoot} ${number}`

			createdItem = update(screen, {
				key: { $set: key },
				path: { $set: newPath },
				name: { $set: newName }
			})

			if (createdItem?.header?.title) {
				createdItem = update(createdItem, {
					header: {
						mapping: {
							title: {
								field: null,
								default: { $set: newName }
							}
						}
					}
				})
			}
		}
	}
	return createdItem
}

export default getIncrementedScreen

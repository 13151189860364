import update from 'immutability-helper'

const getModifiedScreensByElement = (
	screens,
	activeScreen,
	activeElement,
	element
) => {
	const screenGroup = activeScreen.screenGroup
	const groupScreens = screens[screenGroup]
	const activeGroupScreen = groupScreens?.find(
		(x) => x.key == activeScreen?.data?.key
	)
	const index = groupScreens?.findIndex((x) => x.key == activeGroupScreen?.key)
	const elementIndex = groupScreens?.[index]?.body?.findIndex(
		(x) => x._uid == (activeElement?._uid ?? element?._uid)
	)

	const modifiedScreens = update(screens, {
		[screenGroup]: {
			[index]: {
				body:
					elementIndex < 0
						? { $push: [element] }
						: { [elementIndex]: { $set: element } }
			}
		}
	})

	return modifiedScreens
}

export default getModifiedScreensByElement

import { v4 as uuidv4 } from 'uuid'

import i18next from '../../i18n/i18n'
import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
// import fieldDataTypes from '../../common/fieldDataTypes'
import relationTypes from '../../common/enums/relationTypes'
// import getFirstFieldExcludingForbidArray from '../../methods/getFirstFieldExcludingForbidArray'
// import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'

const method = (table) => {
	// const txtFields = getEntityFieldsByDataTypes(
	// 	null,
	// 	null,
	// 	fieldDataTypes.textBased,
	// 	table,
	// 	true,
	// 	relationTypes.one
	// )
	const phoneFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Phone'],
		table,
		true,
		relationTypes.one
	)

	// const firstTxtField = getFirstFieldExcludingForbidArray(
	// 	txtFields,
	// 	'name',
	// 	systemFieldNames.notVisible
	// )
	const firstPhoneField = phoneFields?.length ? phoneFields[0] : null

	const object = {
		_uid: uuidv4(),
		component: 'phoneButton',
		mapping: {
			title: {
				default: i18next.t('schemas.phoneButton.title'),
				field: null,
				type: null
			},
			address: {
				field: firstPhoneField?.name || '',
				default: null,
				type: firstPhoneField?.name ? firstPhoneField?.dataType : null
			},
			body: {
				field: '',
				default: null,
				type: null
			}
		},
		showCall: true,
		showChat: true,
		spacing: 'xl',
		fontSize: 'sm'
	}

	return object
}

export default method

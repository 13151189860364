import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import SubscriptionTemplate from '../../templates/subscriptionTemplate/SubscriptionTemplate'
import SubscriptionModal from '../../organisms/subscriptionModal/SubscriptionModal'

import useQuery from '../../../hooks/UseQuery/useQuery'

const Subscription = () => {
	const { t } = useTranslation()
	let queryParams = useQuery()

	const [modalVisible, setModalVisible] = useState(false)

	useEffect(() => {
		document.title = t('docTitles.subscription')
		if (queryParams?.modal) {
			setModalVisible(true)
		}
	}, [])

	return (
		<>
			<SubscriptionTemplate setModalVisible={setModalVisible} />

			<SubscriptionModal
				visible={modalVisible}
				setModalVisible={setModalVisible}
			/>
		</>
	)
}

export default Subscription

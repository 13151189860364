import React from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'qs'

function useQuery() {
	const { search } = useLocation()

	let urlSearchParams = React.useMemo(
		() => new URLSearchParams(search),
		[search]
	)

	let queryParams = qs.parse(urlSearchParams.toString(), {
		ignoreQueryPrefix: true
	})

	return queryParams
}

export default useQuery

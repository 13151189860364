import { v4 as uuidv4 } from 'uuid'

import i18next from '../../i18n/i18n'
import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../common/fieldDataTypes'
import relationTypes from '../../common/enums/relationTypes'
import getArrayExcludingForbidArray from '../../methods/getArrayExcludingForbidArray'
import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'

const method = (table) => {
	const txtFields = getEntityFieldsByDataTypes(
		null,
		null,
		fieldDataTypes.textBased,
		table,
		true,
		relationTypes.one
	)

	const urlFields = getEntityFieldsByDataTypes(
		null,
		null,
		['URL'],
		table,
		true,
		relationTypes.one
	)

	const filteredTxtFields = getArrayExcludingForbidArray(
		txtFields,
		'name',
		systemFieldNames.notVisible
	)

	const firstUrlField = urlFields?.length ? urlFields[0] : null

	const object = {
		_uid: uuidv4(),
		component: 'linkButton',
		mapping: {
			title: {
				default: i18next.t('schemas.linkButton.title'),
				field: null,
				type: null
			},
			label: {
				field: firstUrlField?.name,
				default: firstUrlField?.name
					? null
					: i18next.t('schemas.linkButton.label'),
				type: firstUrlField?.name ? firstUrlField?.dataType : null
			},
			address: {
				field: firstUrlField?.name,
				default: firstUrlField?.name
					? null
					: i18next.t('schemas.linkButton.address'),
				type: firstUrlField?.name ? firstUrlField?.dataType : null
			}
		},
		spacing: 'xl',
		fontSize: 'sm'
	}

	return object
}

export default method

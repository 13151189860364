import { v4 as uuidv4 } from 'uuid'

import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../common/fieldDataTypes'
import aggOperators from '../../common/aggOperators'
import languages from '../../i18n/languages'
import relationTypes from '../../common/enums/relationTypes'
import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'

const method = (table, actions = null) => {
	const numericFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Number'],
		table,
		true,
		relationTypes.one
	)

	const numericField = numericFields?.length ? numericFields[0] : null

	const object = {
		_uid: uuidv4(),
		component: 'progressBar',
		title: '',
		mapping: {
			value: {
				field: numericField?.name,
				type: numericField?.dataType
			},
			minValue: {
				field: null,
				type: null,
				default: 0
			},
			maxValue: {
				field: null,
				type: null,
				default: 10
			}
		},
		spacing: 'xl'
	}

	return object
}

export default method

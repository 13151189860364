import Ionicons_ttf from 'react-native-vector-icons/Fonts/Ionicons.ttf'

const IconsCSS = `
@font-face {
  src: url(${Ionicons_ttf});
  font-family: Ionicons;
}
`

const style = document.createElement('style')
style.type = 'text/css'
if (style.styleSheet) style.styleSheet.cssText = IconsCSS
else style.appendChild(document.createTextNode(IconsCSS))

document.head.appendChild(style)

//NOTE: https://gist.github.com/sturmenta/246e8cb61dc891a29f8a36eceb55d529

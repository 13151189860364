import React, { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import update from 'immutability-helper'
import {
	AddBox,
	DeleteOutlined,
	ToggleOnOutlined,
	CheckBoxOutlined
} from '@mui/icons-material'
import {
	Box,
	Popover,
	Grid,
	Button,
	List,
	ListItem,
	ListItemButton,
	ListItemText
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import cx from 'classnames'

import timeoutDelay from '../../../methods/timeoutDelay'
import { getInputByDataType } from '../../../defaultElementSchemas/schemas/form'

import AccordionItem from '../../atoms/accordionItem/AccordionItem'
import AppPageBox from '../../atoms/appPageBox/AppPageBox'
import Accordion from '../../atoms/accordion/Accordion'

import PopoverItem from '../popoverItem/PopoverItem'
import FormElementListItem from '../formElementListItem/FormElementListItem'

import { ReactComponent as Koseli } from '../../../assets/icons/Settings/Koseli.svg'
import { ReactComponent as Oval } from '../../../assets/icons/Settings/Oval.svg'
import { ReactComponent as Dropdown } from '../../../assets/icons/Settings/Dropdown.svg'
import { ReactComponent as Chips } from '../../../assets/icons/Settings/Chips.svg'
import { ReactComponent as Radio } from '../../../assets/icons/Settings/Radio.svg'
import { ReactComponent as CheckList } from '../../../assets/icons/Settings/CheckList.svg'

const getListStyle = (isDraggingOver) => ({
	background: isDraggingOver ? '#D7DBFB' : 'transparent',
	borderRadius: 6,
	padding: 2,
	width: '100%'
})

const getElementStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	margin: `0 0 10px 0`,
	borderRadius: 6,
	border: isDragging ? '2px dashed gray' : 'none',
	overflow: isDragging ? 'hidden' : 'visible',
	cursor: 'default',

	...draggableStyle
})

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	popoverBox: {
		padding: 8,
		width: 200
	},
	popoverIcon: {
		color: '#000000',
		opacity: 0.7,
		width: '14px'
	}
}))

const FormElementList = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const elementList = props.localElementData?.body
	const fields = props.entities?.find((x) => x.name === props.source)?.fields

	const [selectedElementIndex, setSelectedElementIndex] = useState(null)

	const checkboxTypeOptions = [
		{
			text: t('screens.elementSettings.form.checkboxTypeOpts.check'),
			value: 'checkbox',
			icon: CheckBoxOutlined
		},
		{
			text: t('screens.elementSettings.form.checkboxTypeOpts.switch'),
			value: 'switch',
			icon: ToggleOnOutlined
		}
	]

	const choiceTypeOptions = [
		{
			text: t('screens.elementSettings.choice.typeOpts.dropdown'),
			value: 'dropdown',
			icon: Dropdown
		},
		{
			text: t('screens.elementSettings.choice.typeOpts.chips'),
			value: 'chips',
			icon: Chips
		},
		{
			text: t('screens.elementSettings.choice.typeOpts.radio'),
			value: 'radio',
			icon: Radio
		},
		{
			text: t('screens.elementSettings.choice.typeOpts.checkbox'),
			value: 'checkbox',
			icon: CheckList
		}
	]

	/****ADD_ELEMENT_POPOVER***************************/
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const onDragEnd = (result) => {
		const { destination } = result

		if (!destination) {
			return
		}

		const elements = reorder(
			elementList,
			result.source.index,
			result.destination.index
		)

		props.setElements(elements)
	}

	const [elementPop, setElementPop] = useState(null)
	const elementPopOpen = Boolean(elementPop)

	const openPop = (event, index) => {
		setSelectedElementIndex(index)
		setElementPop(event.currentTarget)
	}

	const closePop = () => {
		setElementPop(null)
	}

	/****ELEMENTS***************************/

	const elementTitleChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			body: {
				[index]: {
					title: { $set: value }
				}
			}
		})

		timeoutDelay(props.updateSelectedElement, modifiedElementData, 1000)
		props.setLocalElementData(modifiedElementData)
	}

	const elementLabelChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			body: {
				[index]: {
					label: {
						default: { $set: value }
					}
				}
			}
		})

		timeoutDelay(props.updateSelectedElement, modifiedElementData, 1000)
		props.setLocalElementData(modifiedElementData)
	}

	const elementPlaceholderChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			body: {
				[index]: {
					placeholder: {
						default: { $set: value }
					}
				}
			}
		})

		timeoutDelay(props.updateSelectedElement, modifiedElementData, 1000)
		props.setLocalElementData(modifiedElementData)
	}

	const elementRequiredChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			body: {
				[index]: {
					required: {
						status: { $set: value }
					}
				}
			}
		})

		props.updateSelectedElement(modifiedElementData)
		props.setLocalElementData(modifiedElementData)
	}

	const elementPrecisionChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			body: {
				[index]: {
					precision: { $set: value }
				}
			}
		})

		props.updateSelectedElement(modifiedElementData)
		props.setLocalElementData(modifiedElementData)
	}

	const elementMaxValChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			body: {
				[index]: {
					maxVal: { $set: value }
				}
			}
		})

		timeoutDelay(props.updateSelectedElement, modifiedElementData, 1000)
		props.setLocalElementData(modifiedElementData)
	}

	const elementMinValChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			body: {
				[index]: {
					minVal: { $set: value }
				}
			}
		})

		timeoutDelay(props.updateSelectedElement, modifiedElementData, 1000)
		props.setLocalElementData(modifiedElementData)
	}

	const elementLimitChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			body: {
				[index]: {
					limit: { $set: value }
				}
			}
		})

		timeoutDelay(props.updateSelectedElement, modifiedElementData, 1000)
		props.setLocalElementData(modifiedElementData)
	}

	const elementCheckBoxTypeChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			body: {
				[index]: {
					type: { $set: value }
				}
			}
		})

		props.updateSelectedElement(modifiedElementData)
		props.setLocalElementData(modifiedElementData)
	}

	const elementTypeChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			body: {
				[index]: {
					type: { $set: value }
				}
			}
		})

		props.updateSelectedElement(modifiedElementData)
		props.setLocalElementData(modifiedElementData)
	}

	const elementRelationFieldChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			body: {
				[index]: {
					relation: {
						field: { $set: value }
					}
				}
			}
		})

		props.updateSelectedElement(modifiedElementData)
		props.setLocalElementData(modifiedElementData)
	}

	const elementScannerChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			body: {
				[index]: {
					scanner: { $set: value }
				}
			}
		})

		props.updateSelectedElement(modifiedElementData)
		props.setLocalElementData(modifiedElementData)
	}

	const elementEnableRecurrenceChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			body: {
				[index]: {
					enableRecurrence: { $set: value }
				}
			}
		})

		props.updateSelectedElement(modifiedElementData)
		props.setLocalElementData(modifiedElementData)
	}

	const elementAllowMultipleChange = (index, value) => {
		let modifiedElementData

		if (
			props.elementData?.body?.[index]?.allowMultiple === null ||
			props.elementData?.body?.[index]?.allowMultiple === undefined
		) {
			modifiedElementData = update(props.elementData, {
				body: {
					[index]: {
						$set: {
							...props.elementData?.body?.[index],
							...{
								allowMultiple: value
							}
						}
					}
				}
			})
		} else {
			modifiedElementData = update(props.elementData, {
				body: {
					[index]: {
						allowMultiple: { $set: value }
					}
				}
			})
		}

		props.updateSelectedElement(modifiedElementData)
		props.setLocalElementData(modifiedElementData)
	}

	const elementEnableRepeatChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			body: {
				[index]: {
					enableRepeat: { $set: value }
				}
			}
		})

		props.updateSelectedElement(modifiedElementData)
		props.setLocalElementData(modifiedElementData)
	}

	const addNewElement = (field) => {
		const newElement = getInputByDataType(field)
		const modifiedElementData = update(props.elementData, {
			body: {
				$push: [newElement]
			}
		})

		props.updateSelectedElement(modifiedElementData)

		setSelectedElementIndex(elementList.length)
	}

	const deleteElement = () => {
		const modifiedElementData = update(props.elementData, {
			body: {
				$splice: [[selectedElementIndex, 1]]
			}
		})

		props.updateSelectedElement(modifiedElementData)
		props.setLocalElementData(modifiedElementData)
		setSelectedElementIndex(null)
	}

	return (
		<AppPageBox>
			<Accordion
				title={t('screens.elementSettings.form.fieldsTitle')}
				expanded={true}
			>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId='droppable'>
						{(provided, snapshot) => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
								style={getListStyle(snapshot.isDraggingOver)}
							>
								{!!elementList?.length &&
									elementList.map((item, index) => (
										<Draggable
											key={item._uid}
											draggableId={item._uid}
											index={index}
										>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={getElementStyle(
														snapshot.isDragging,
														provided.draggableProps.style
													)}
												>
													<div key={index} className={classes.mb10}>
														<AccordionItem
															title={
																fields?.find(
																	(x) => x.name === item?.mapping?.field
																)?.label || item?.mapping?.field
															}
															expanded={selectedElementIndex === index}
															onChange={() => (event, newExpanded) => {
																setSelectedElementIndex(
																	selectedElementIndex === index ? null : index
																)
															}}
															moreClick={(e) => openPop(e, index)}
														>
															<FormElementListItem
																source={props.source}
																activeScreen={props.activeScreen}
																field={fields?.find(
																	(x) => x.name === item?.mapping?.field
																)}
																languageCode={props.languageCode}
																element={item}
																index={index}
																elementTitleChange={elementTitleChange}
																elementLabelChange={elementLabelChange}
																elementPlaceholderChange={
																	elementPlaceholderChange
																}
																elementRequiredChange={elementRequiredChange}
																elementPrecisionChange={elementPrecisionChange}
																elementMaxValChange={elementMaxValChange}
																elementMinValChange={elementMinValChange}
																elementLimitChange={elementLimitChange}
																elementTypeChange={elementTypeChange}
																elementScannerChange={elementScannerChange}
																elementEnableRecurrenceChange={
																	elementEnableRecurrenceChange
																}
																elementAllowMultipleChange={
																	elementAllowMultipleChange
																}
																elementEnableRepeatChange={
																	elementEnableRepeatChange
																}
																choiceTypeOptions={choiceTypeOptions}
																checkboxTypeOptions={checkboxTypeOptions}
																elementCheckBoxTypeChange={
																	elementCheckBoxTypeChange
																}
																entities={props.entities}
																elementRelationFieldChange={
																	elementRelationFieldChange
																}
																sorting={props.sorting}
																filtering={props.filtering}
															/>
														</AccordionItem>
													</div>
												</div>
											)}
										</Draggable>
									))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>

				<div style={{ width: '100%', position: 'relative', padding: 2 }}>
					<Button
						aria-describedby={id}
						startIcon={<AddBox />}
						fullWidth
						size='small'
						variant='contained'
						onClick={props.unusedFields?.length ? handleClick : null}
						sx={{
							textTransform: 'none',
							backgroundColor: '#D7DBFB !important', //kozmikLightBlue
							color: '#1B36FE', //kozmikBlue
							boxShadow: 'none',
							fontSize: '14px',
							borderRadius: '6px'
						}}
					>
						{t('screens.elementSettings.form.addNew')}
					</Button>
					{!!props.unusedFields?.length && (
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left'
							}}
							PaperProps={{
								style: { right: 17 } //NOTE:for 100% width of parent container
							}}
						>
							<List>
								{props.unusedFields.map((item, index) => (
									<ListItem disablePadding key={`form_element_${index}`}>
										<ListItemButton
											style={{ height: 35 }}
											onClick={() => {
												handleClose()
												addNewElement(item)
											}}
										>
											<ListItemText
												primary={item.label}
												primaryTypographyProps={{ fontSize: 13 }}
											/>
										</ListItemButton>
									</ListItem>
								))}
							</List>
						</Popover>
					)}
				</div>

				<Popover
					open={elementPopOpen}
					anchorEl={elementPop}
					onClose={() => {
						closePop()
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
				>
					<Box className={classes.popoverBox}>
						<PopoverItem
							text={t('screens.elementSettings.form.element.delete')}
							onClick={() => {
								closePop()
								deleteElement()
							}}
							color='#FF2619'
						>
							<DeleteOutlined
								className={classes.popoverIcon}
								style={{ color: '#FF2619', opacity: 1 }}
							/>
						</PopoverItem>
					</Box>
				</Popover>
			</Accordion>
		</AppPageBox>
	)
}

export default FormElementList

import React, { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
	labelStyle: {
		color: '#000000',
		//fontFamily: 'OpenSans',
		fontSize: '26px',
		lineHeight: '22px',
		textAlign: 'left',
		display: 'block',
		fontWeight: 'bold',
		paddingTop: '3%'
	},
	root: {
		flexGrow: 1
	},
	tile: {
		padding: theme.spacing(3),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		minHeight: '100px'
	}
}))

const Resources = () => {
	const classes = useStyles()
	const { t } = useTranslation()

	useEffect(() => {
		document.title = t('docTitles.resources')
	}, [])

	return (
		<div className={classes.root}>
			<div>
				<h1>Resources Page</h1>
				<p>These are resources</p>
			</div>
		</div>
	)
}

export default Resources

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import { Grid } from '@mui/material'

import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'

import timeoutDelay from '../../../methods/timeoutDelay'
import { default as Label } from '../../atoms/settingLabel/SettingLabel'

const useStyles = makeStyles((theme) => ({}))

const MoreOptions = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	/****EMPTY_STATE***************************/
	const [moreMessage, setMoreMessage] = useState('')

	const moreMessageChange = (e) => {
		timeoutDelay(submitMoreMessage, e.target.value, 1000)
		setMoreMessage(e.target.value)
	}

	const submitMoreMessage = (x) => {
		const modifiedElementData = update(props.elementData, {
			moreText: {
				default: { $set: x }
			}
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****EMPTY_STATE***************************/
		setMoreMessage(
			elementData?.moreText?.default ? elementData?.moreText?.default : ''
		)

		if (!elementData.moreText) {
			const modifiedElementData = {
				...elementData,
				moreText: {
					default: t('screens.elementSettings.common.seeMore.text')
				}
			}

			props.updateSelectedElement(modifiedElementData)
		}
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
		}
	}, [props.elementData])

	return (
		<div>
			<Grid container alignItems='center'>
				<Grid item xs={4}>
					<Label>{t('screens.elementSettings.common.labels.text')}</Label>
				</Grid>
				<Grid item xs={8}>
					<KozmikTextField
						fullWidth
						value={moreMessage}
						onChange={moreMessageChange}
					/>
				</Grid>
			</Grid>
		</div>
	)
}

export default MoreOptions

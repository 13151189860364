import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import DetailMenuItem from '../detailMenuItem/DetailMenuItem'

const useStyles = makeStyles(() => ({
	mainContainer: {
		//paddingTop: '20px'
		justifyContent: 'space-around'
	}
}))

const DetailMenu = (props) => {
	const classes = useStyles()

	return (
		<Grid container className={classes.mainContainer}>
			{props.menuItems.map((element, index) => (
				<DetailMenuItem
					activeMenuEvent={props.activeMenuEvent}
					setActiveMenuEvent={props.setActiveMenuEvent}
					key={index}
					data={element}
				/>
			))}
		</Grid>
	)
}

export default DetailMenu

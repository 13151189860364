import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { SwapVert, FilterAltOutlined, PlusOne } from '@mui/icons-material'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import Accordion from '../../../atoms/accordion/Accordion'
import NumericInput from '../../../atoms/numericInput/NumericInput'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import KozmikTextField from '../../../atoms/kozmikTextField/KozmikTextField'
import AccordionItem from '../../../atoms/accordionItem/AccordionItem'
import FilterList from '../../../molecules/filterList/FilterList'
import Sorting from '../../../molecules/sorting/Sorting'
import MoreOptions from '../../../molecules/moreOptions/MoreOptions'
import ActionList from '../../actionList/ActionList'
import FilterModalOptions from '../../../molecules/filterModalOptions/FilterModalOptions'
import SearchOptions from '../../../molecules/searchOptions/SearchOptions'
import EmptyOptions from '../../../molecules/emptyOptions/EmptyOptions'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import getFilterOpByDataType from '../../../../methods/getFilterOpByDataType'
import relationTypes from '../../../../common/enums/relationTypes'
import getArrayExcludingForbidArray from '../../../../methods/getArrayExcludingForbidArray'
import systemFieldNames from '../../../../common/systemFieldNames/systemFieldNames'

import { ReactComponent as Standart } from '../../../../assets/icons/Settings/Standard.svg'
import { ReactComponent as CalendarDay } from '../../../../assets/icons/Settings/CalendarDay.svg'
import { ReactComponent as Calendar3Day } from '../../../../assets/icons/Settings/Calendar3Day.svg'
import { ReactComponent as CalendarWeek } from '../../../../assets/icons/Settings/CalendarWeek.svg'
import { ReactComponent as CalendarMonth } from '../../../../assets/icons/Settings/CalendarMonth.svg'

import defaultElementSchemas from '../../../../defaultElementSchemas'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	inputLight: {
		borderRadius: 3,
		backgroundColor: '#ffffff',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const CalendarList = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [elementName, setElementName] = useState(null)

	const typeOptions = [
		{
			text: t('screens.elementSettings.calendarList.typeOpts.day'),
			value: 'day',
			icon: CalendarDay
		},
		{
			text: t('screens.elementSettings.calendarList.typeOpts.threeDays'),
			value: '3days',
			icon: Calendar3Day
		},
		{
			text: t('screens.elementSettings.calendarList.typeOpts.week'),
			value: 'week',
			icon: CalendarWeek
		},
		{
			text: t('screens.elementSettings.calendarList.typeOpts.month'),
			value: 'month',
			icon: CalendarMonth
		},
		{
			text: t('screens.elementSettings.calendarList.typeOpts.list'),
			value: 'list',
			icon: Standart
		}
	]

	/****SOURCE***************************/
	const [source, setSource] = useState('')

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		fieldDataTypes.textBased,
		null,
		true
		// relationTypes.one
	)
	const dateFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		['Date'],
		null,
		true,
		relationTypes.one
	)

	const sourceChange = (e) => {
		submitSource(source, e.target.value)
		setSource(e.target.value)
	}

	const submitSource = (oldTableName, tableName) => {
		const table = props?.entities?.find((y) => y.name == tableName)

		const txtFields = getEntityFieldsByDataTypes(
			props?.entities,
			tableName,
			fieldDataTypes.textBased,
			null,
			true,
			relationTypes.one
		)
		const dateFields = getEntityFieldsByDataTypes(
			props?.entities,
			tableName,
			['Date'],
			null,
			true,
			relationTypes.one
		)

		const filteredDateFields = getArrayExcludingForbidArray(
			dateFields,
			'name',
			systemFieldNames.notVisible
		)

		const filteredTxtFields = getArrayExcludingForbidArray(
			txtFields,
			'name',
			systemFieldNames.all
			// systemFieldNames.notVisible
		)
		const titleField = txtFields?.find((x) => x.name == 'Name')

		let modifiedElementData = update(props.elementData, {
			source: { $set: tableName },
			mapping: {
				title: {
					field: { $set: titleField?.name },
					type: { $set: titleField?.dataType }
				},
				text: {
					field: { $set: filteredTxtFields?.[0]?.name },
					type: { $set: filteredTxtFields?.[0]?.dataType }
				},
				startDate: {
					field: { $set: filteredDateFields?.[0]?.name },
					type: { $set: filteredDateFields?.[0]?.dataType }
				},
				endDate: {
					field: { $set: filteredDateFields?.[1]?.name },
					type: { $set: filteredDateFields?.[1]?.dataType }
				}
			},
			sorting: {
				field: { $set: titleField?.name },
				sort: { $set: 'asc' }
			},
			filters: {
				groupOp: { $set: 'AND' },
				rules: { $set: [] }
			},
			seeAllUrl: {
				$set: props.elementData?.seeAllUrl
			},
			filterModal: {
				$set: defaultElementSchemas.filterModal()
			}
		})

		props.updateBulkScreensWithModifiedData(
			table.name,
			null,
			modifiedElementData
		)
	}

	/****TITLE***************************/
	const [title, setTitle] = useState('')

	const titleChange = (e) => {
		submitTitle(e.target.value)
		setTitle(e.target.value)
	}

	const submitTitle = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)

		const selectedFieldFilterOps = selectedField
			? getFilterOpByDataType(selectedField.dataType)
			: null

		const modifiedElementData = update(props.elementData, {
			mapping: {
				title: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****TEXT***************************/
	const [text, setText] = useState('')

	const textChange = (e) => {
		submitText(e.target.value)
		setText(e.target.value)
	}

	const submitText = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				text: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****START_DATE***************************/
	const [startDate, setStartDate] = useState('')

	const startDateChange = (e) => {
		submitStartDate(e.target.value)
		setStartDate(e.target.value)
	}

	const submitStartDate = (x) => {
		const selectedField = dateFields?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				startDate: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****END_DATE***************************/
	const [endDate, setEndDate] = useState('')

	const endDateChange = (e) => {
		submitEndDate(e.target.value)
		setEndDate(e.target.value)
	}

	const submitEndDate = (x) => {
		const selectedField = dateFields?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				endDate: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ACTIONS***************************/
	const updateActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			actions: { $set: newActions }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****TYPE***************************/
	const [type, setType] = useState(null)

	const typeChange = (val) => {
		submitType(val)
		setType(val)
	}

	const submitType = (x) => {
		const modifiedElementData = update(props.elementData, {
			type: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****LIMIT***************************/
	const [limit, setLimit] = useState(25)
	const limitChange = (val) => {
		timeoutDelay(submitLimit, val, 1000)
		setLimit(val)
	}
	const submitLimit = (x) => {
		const modifiedElementData = update(props.elementData, {
			limit: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****FILTER_&_SORT***************************/
	const [localElementData, setLocalElementData] = useState(null)

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****SOURCE***************************/
		setSource(elementData?.source ? elementData.source : '')
		/****TITLE***************************/
		setTitle(
			elementData?.mapping?.title?.field ? elementData.mapping.title.field : ''
		)
		/****TEXT***************************/
		setText(
			elementData?.mapping?.text?.field ? elementData.mapping.text.field : ''
		)
		/****START_DATE***************************/
		setStartDate(
			elementData?.mapping?.startDate?.field
				? elementData.mapping.startDate.field
				: ''
		)
		/****END_DATE***************************/
		setEndDate(
			elementData?.mapping?.endDate?.field
				? elementData.mapping.endDate.field
				: ''
		)
		/****TYPE***************************/
		setType(elementData?.type)
		/****LIMIT***************************/
		setLimit(elementData?.limit ?? 10)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
			setLocalElementData(props.elementData)
		}
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.source')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={12}>
							<SelectBox
								className={classes.input}
								onChange={sourceChange}
								value={source}
								data={props.entities}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>

					<div className={classes.mb10}>
						<AccordionItem
							icon={FilterAltOutlined}
							title={t('screens.elementSettings.calendarList.filter')}
							expanded={false}
							padding={8}
						>
							<FilterList
								filter={localElementData?.filters}
								table={props?.entities?.find(
									(y) => y.name == props?.elementData?.source
								)}
								activeScreen={props?.activeScreen}
								entities={props?.entities}
								filtering={props.filtering}
								setLocalElementData={setLocalElementData}
							/>
						</AccordionItem>
					</div>

					<div className={classes.mb10}>
						<AccordionItem
							icon={SwapVert}
							title={t('screens.elementSettings.calendarList.sort')}
							expanded={false}
							padding={8}
						>
							<Sorting
								sortingData={localElementData?.sorting}
								sorting={props.sorting}
								fieldOptions={textFields}
							/>
						</AccordionItem>
					</div>

					<div className={classes.mb10}>
						<AccordionItem
							icon={PlusOne}
							title={t('screens.elementSettings.calendarList.limit')}
							expanded={false}
							padding={8}
						>
							<NumericInput minVal={0} value={limit} onChange={limitChange} />
						</AccordionItem>
					</div>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.calendarList.title')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.calendarList.emptyField')}
								className={classes.input}
								onChange={titleChange}
								value={title}
								data={textFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.calendarList.text')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.calendarList.emptyField')}
								className={classes.input}
								onChange={textChange}
								value={text}
								data={textFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>
								{t('screens.elementSettings.calendarList.startDate')}
							</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.calendarList.emptyField')}
								className={classes.input}
								onChange={startDateChange}
								value={startDate}
								data={dateFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.calendarList.endDate')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.calendarList.emptyField')}
								className={classes.input}
								onChange={endDateChange}
								value={endDate}
								data={dateFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid
						item
						alignItems='center'
						className={classes.mb10}
						style={{
							display: 'flex',
							justifyContent: 'space-around'
						}}
					>
						<SquareOptions
							data={typeOptions}
							getChange={typeChange}
							activeValue={type}
						/>
					</Grid>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.empty')}
					expanded={true}
				>
					<EmptyOptions
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.more')}
					expanded={true}
				>
					<MoreOptions
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.search')}
					expanded={true}
				>
					<SearchOptions
						source={source}
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.inAppFilter')}
					expanded={false}
				>
					{source && (
						<FilterModalOptions
							source={source}
							activeScreen={props.activeScreen}
							elementData={props.elementData}
							entities={props.entities}
							sorting={props.sorting}
							filtering={props.filtering}
							updateSelectedElement={props.updateSelectedElement}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.actions')}
					expanded={true}
				>
					{source && (
						<ActionList
							name={elementName}
							entities={props.entities}
							activeScreen={props.activeScreen}
							actions={props.elementData.actions}
							updateActions={updateActions}
							isList={true}
							listSource={source}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default CalendarList

import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import AppMenuItem from '../appMenuItem/AppMenuItem'

const useStyles = makeStyles(() => ({
	mainContainer: {
		paddingTop: 20
	}
}))

const AppMenu = (props) => {
	const classes = useStyles()

	return (
		<Grid container className={classes.mainContainer}>
			{props.menuItems.map((element, index) => (
				<AppMenuItem key={index} data={element} />
			))}
		</Grid>
	)
}

export default AppMenu

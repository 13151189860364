import { v4 as uuidv4 } from 'uuid'

import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import convertFieldsToActionSourceFields from '../../methods/convertFieldsToActionSourceFields'
import languages from '../../i18n/languages'
import relationTypes from '../../common/enums/relationTypes'
import getArrayExcludingForbidArray from '../../methods/getArrayExcludingForbidArray'
import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'

const method = (table, userTableName, actions = null) => {
	const idFields = getEntityFieldsByDataTypes(null, null, ['RowId'], table)
	const txtFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Text', 'Lookup'],
		table,
		true,
		relationTypes.one
	)
	const longTxtFields = getEntityFieldsByDataTypes(
		null,
		null,
		['LongText'],
		table,
		true,
		relationTypes.one
	)
	const userFields = table?.fields?.filter(
		(x) => x.relatedEntityName === userTableName
	)
	const filteredUserFields = getArrayExcludingForbidArray(
		userFields,
		'name',
		systemFieldNames.all
	)
	const dateFields = getEntityFieldsByDataTypes(null, null, ['Date'], table)
	const emailFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Email', 'Text', 'LongText', 'Formula'],
		table
	)
	const imgFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Image', 'Lookup'],
		table,
		true,
		relationTypes.one
	)

	const idField = idFields?.[0] || null
	const userEmailField = emailFields?.[0] || null
	const userNameField = txtFields?.[0] || null
	const userPhoto = imgFields?.[0] || null
	const messageField = longTxtFields?.[0] || null
	const timeField = dateFields?.[0] || null

	const actionSourceFields = convertFieldsToActionSourceFields(
		table,
		table.name,
		null,
		null
	)

	const object = {
		_uid: uuidv4(),
		component: 'chat',
		source: table?.name,
		mapping: {
			id: {
				field: idField?.name || null,
				type: idField?.dataType || null
			},
			userEmail: {
				field: userEmailField?.name || null,
				type: userEmailField?.dataType || null
			},
			userName: {
				field: userNameField?.name || null,
				type: userNameField?.dataType || null
			},
			userPhoto: {
				field: userPhoto?.name || null,
				type: userPhoto?.dataType || null
			},
			message: {
				field: messageField?.name || null,
				type: messageField?.dataType || null
			},
			time: {
				field: timeField?.name || null,
				type: timeField?.dataType || null
			}
		},
		sorting: {
			field: timeField?.name || null,
			sort: 'desc'
		},
		filters: {
			groupOp: 'AND',
			rules: []
		},
		searcheable: false,
		searchPlaceholder: {
			default: languages.en.schemas.chat.searchPlaceholder
		},
		actions: [
			{
				type: 'create',
				source: table.name,
				fields: actionSourceFields
			}
		],
		limit: 100,
		moreText: {
			default: languages.en.schemas.chat.more
		},
		emptyState: {
			default: languages.en.schemas.chat.emptyState
		},
		spacing: 'xl'
	}

	return object
}

export default method

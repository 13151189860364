import { v4 as uuidv4 } from 'uuid'

import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
// import getFilterOpByDataType from '../../methods/getFilterOpByDataType'
import fieldDataTypes from '../../common/fieldDataTypes'
// import i18next from '../../i18n/i18n'
import languages from '../../i18n/languages'
import relationTypes from '../../common/enums/relationTypes'
import getArrayExcludingForbidArray from '../../methods/getArrayExcludingForbidArray'
import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'

const method = (table, actions = null) => {
	const txtFields = getEntityFieldsByDataTypes(
		null,
		null,
		fieldDataTypes.textBased,
		table,
		true,
		relationTypes.one
	)
	const imgFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Image'],
		table,
		true,
		relationTypes.one
	)

	const filteredTxtFields = getArrayExcludingForbidArray(
		txtFields,
		'name',
		systemFieldNames.all
	)

	const titleField = txtFields?.find((x) => x.name == 'Name')
	const firstImgField = imgFields?.length ? imgFields[0] : null

	const object = {
		_uid: uuidv4(),
		component: 'carousel',
		title: '',
		source: table?.name,
		mapping: {
			imgUrl: {
				field: firstImgField?.name,
				type: firstImgField?.dataType
			}
		},
		sorting: {
			field: titleField?.name,
			sort: 'asc'
		},
		filters: {
			groupOp: 'AND',
			rules: []
		},
		corner: 'oval',
		imageResizeMode: 'cover',
		actions: actions ?? [
			{
				type: null
			}
		],
		limit: 25,
		spacing: 'xl'
	}

	return object
}

export default method

import React, { useEffect, useState } from 'react'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'
import { AddBox, DeleteOutlined } from '@mui/icons-material'
import { Box, Popover } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import ActionSelection from '../actionSelection/ActionSelection'
import TextButton from '../../atoms/button/TextButton'
import AccordionItem from '../../atoms/accordionItem/AccordionItem'
import PopoverItem from '../../molecules/popoverItem/PopoverItem'

import actionTypes from '../../../common/actionTypes'

const useStyles = makeStyles(() => ({
	mb10: {
		marginBottom: 10
	},
	popoverBox: {
		padding: 8
	},
	popoverIcon: {
		color: '#000000',
		opacity: 0.7,
		width: '14px'
	}
}))

const ActionList = (props) => {
	const classes = useStyles()
	const { t } = useTranslation()

	const [actionPop, setActionPop] = useState(null)
	const [selectedActionIndex, setSelectedActionIndex] = useState(null)
	const actionPopOpen = Boolean(actionPop)

	const addNewAction = () => {
		const modifiedActions = update(props.actions, { $push: [{ type: null }] })

		props.updateActions(modifiedActions)
		setSelectedActionIndex(props.actions.length)
	}

	const deleteAction = (index) => {
		const modifiedActions = update(props.actions, {
			$splice: [[index, 1]]
		})

		props.updateActions(modifiedActions)
		setSelectedActionIndex(0)
	}

	const updateAction = (updatedAction, index) => {
		const modifiedActions = update(props.actions, {
			[index]: { $set: updatedAction }
		})
		props.updateActions(modifiedActions)
	}

	const openActionPop = (event, index) => {
		setSelectedActionIndex(index)
		setActionPop(event.currentTarget)
	}

	const closeActionPop = () => {
		setActionPop(null)
	}

	return (
		<>
			{!!props?.actions?.length &&
				props.actions.map((item, index) => (
					<div key={index} className={classes.mb10}>
						<AccordionItem
							title={
								actionTypes?.find((x) => x.value === item?.type)?.label ||
								t('screens.elementSettings.actionSelection.noAction')
							}
							expanded={selectedActionIndex === index}
							padding={8}
							onChange={() => (event, newExpanded) => {
								setSelectedActionIndex(
									selectedActionIndex === index ? null : index
								)
							}}
							moreClick={(e) => openActionPop(e, index)}
							bgColor='#F0F0F4'
						>
							<ActionSelection
								bgColor='#ffffff'
								key={index}
								actionTypes={actionTypes}
								actions={props.actions}
								action={item}
								actionIndex={index}
								updateAction={updateAction}
								entities={props.entities}
								formSource={props.formSource}
								activeScreen={props.activeScreen}
								isList={!!props?.isList}
								listSource={props?.listSource}
							/>
						</AccordionItem>
					</div>
				))}
			<TextButton
				onClick={addNewAction}
				icon={AddBox}
				text={t('screens.elementSettings.actionSelection.addNewAction')}
			/>

			<Popover
				open={actionPopOpen}
				anchorEl={actionPop}
				onClose={() => {
					closeActionPop()
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<Box className={classes.popoverBox}>
					<PopoverItem
						text={t('screens.elementSettings.actionSelection.delete')}
						onClick={() => {
							closeActionPop()
							deleteAction(selectedActionIndex)
						}}
						color='#FF2619'
					>
						<DeleteOutlined
							className={classes.popoverIcon}
							style={{ color: '#FF2619', opacity: 1 }}
						/>
					</PopoverItem>
				</Box>
			</Popover>
		</>
	)
}

export default ActionList

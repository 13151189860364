import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import DoubleStateField from '../doubleStateField/DoubleStateField'

import getFilterOpByDataType from '../../../methods/getFilterOpByDataType'
import validateWithRegExp from '../../../methods/validateWithRegExp'
import getRegExpByDataType from '../../../methods/getRegExpByDataType'
import timeoutDelay from '../../../methods/timeoutDelay'
import dataClient from '../../../services/dataClient'
import showToastResponseError from '../../../methods/showToastResponseError'
import getCurrentTableField from '../../../methods/getCurrentTableField'
import getFieldDataType from '../../../methods/getFieldDataType'

import formulas from '../../../common/formulas'

const useStyles = makeStyles((theme) => ({}))

const Deprecated_FormHiddenFieldListItem = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	let { appId } = useParams()

	const {
		activeScreen,
		entityField,
		entityFields,
		element,
		updateHiddenField,
		conditionIndex,
		condition,
		languageCode,
		...restProps
	} = props

	/****RULE_DATA***************************/
	const [dataPrimitiveType, setDataPrimitiveType] = useState(null)
	const [isDataSecondaryMode, setIsDataSecondaryMode] = useState(false)
	const [dataField, setDataField] = useState('')
	const [ruleDataDefault, setDataDefault] = useState('')
	const [dataDefaultValid, setDataDefaultValid] = useState(true)
	const [dataRegExp, setDataRegExp] = useState(null)
	const [dataErrorText, setDataErrorText] = useState('')

	const [relationTableData, setRelationTableData] = useState([])
	const [dataRelation, setDataRelation] = useState([])

	const updateHiddenFieldValue = (val, isRelation = false) => {
		var valueType = isRelation
			? 'Relation'
			: isDataSecondaryMode
			? 'Data'
			: 'auto'

		updateHiddenField(conditionIndex, val, valueType)
	}

	const filterTableFieldsByDataType = (
		dataType,
		tableFields,
		addFormulaFields = false
	) => {
		if (dataType) {
			const filterOps = getFilterOpByDataType(dataType)
			const allowedDataTypes = filterOps?.dataTypes

			let filteredFields =
				tableFields
					?.filter((x) => allowedDataTypes?.includes(x.dataType))
					?.sort(function (a, b) {
						return a.label.localeCompare(b.label)
					}) ?? []

			if (addFormulaFields) {
				if (filterOps?.type === 'string') {
					filteredFields = filteredFields
						.concat(formulas.profile)
						.concat(formulas.string)
				} else if (filterOps?.type === 'relation') {
					filteredFields = filteredFields.concat(formulas.onlyProfile)
				} else if (filterOps?.type === 'datetime') {
					filteredFields = filteredFields.concat(formulas.datetime)
				}
			}

			return filteredFields
		} else {
			return []
		}
	}

	const fieldOptions = filterTableFieldsByDataType(
		entityField?.dataType,
		entityFields,
		true
	)

	const getScreenFieldsOptions = (screenSource) => {
		if (screenSource) {
			const screenFields = props?.entities?.find(
				(x) => x.name == screenSource
			)?.fields

			const filteredScreenFields = filterTableFieldsByDataType(
				entityField?.dataType,
				screenFields
			)

			const formattedScreenFields = filteredScreenFields?.map((x) => ({
				label: `Screen.${x.label}`,
				name: `Screen.${x.name}`
			}))

			if (entityField?.dataType == 'Relation') {
				formattedScreenFields.unshift({
					label: `Screen`,
					name: `Screen`
				})
			}

			return formattedScreenFields?.sort(function (a, b) {
				return a.label.localeCompare(b.label)
			})
		} else {
			return []
		}
	}

	const getFormFieldsOptions = (formSource) => {
		if (formSource) {
			const usedInputFields = props?.elements?.length
				? props?.elements.map((x) => x?.mapping?.field)
				: []

			const formFields = props?.entities
				?.find((x) => x.name == formSource)
				?.fields?.filter((x) => usedInputFields.includes(x.name))

			const filteredFormFields = filterTableFieldsByDataType(
				entityField?.dataType,
				formFields
			)

			const formattedFormFields = filteredFormFields?.map((x) => ({
				label: `Form.${x.label}`,
				name: `Form.${x.name}`
			}))

			return formattedFormFields?.sort(function (a, b) {
				return a.label.localeCompare(b.label)
			})
		} else {
			return []
		}
	}

	const screenFieldsOptions = getScreenFieldsOptions(activeScreen.data?.source)

	const formFieldsOptions = getFormFieldsOptions(props?.source)

	const fieldOptionsWithScreenFields = [
		...fieldOptions,
		...screenFieldsOptions,
		...formFieldsOptions
	]

	const getRelationTableData = async (field) => {
		if (!field) return

		setRelationTableData([])

		const parameters = {
			sort: 'Created desc',
			fields: 'Id,' + (field?.displayFieldName ?? 'Name')
		}

		return dataClient
			.searchData(appId, field.relatedEntityName, parameters)
			.then((response) => {
				if (response?.data) {
					setRelationTableData(response.data.records)
				} else {
					showToastResponseError(response)
				}
			})
	}

	const getFormattedRelationDataById = (field, idArray) => {
		const filteredData = relationTableData.filter((x) => idArray.includes(x.Id))
		const convertedData = filteredData?.map((item) => ({
			Id: item?.Id,
			Name: item?.[field.displayFieldName ?? 'Name']
		}))

		if (field.relationType == 'One') {
			return convertedData ? convertedData[0] : {}
		} else {
			return convertedData ? convertedData : []
		}
	}

	const fieldDataChange = (e) => {
		if (dataPrimitiveType === 'string' || dataPrimitiveType === 'numeric') {
			if (isDataSecondaryMode) {
				validateWithRegExp(e.target.value, dataRegExp).then((isValid) => {
					setDataDefaultValid(isValid)
					if (isValid) {
						timeoutDelay(updateHiddenFieldValue, e.target.value, 1000)
					}
				})

				setDataDefault(e.target.value)
				setDataField('')
			} else {
				updateHiddenFieldValue(e.value)

				setDataField(e.value)
				setDataDefault('')
			}
			setDataRelation([])
		} else if (dataPrimitiveType === 'boolean') {
			if (isDataSecondaryMode) {
				const vl = typeof e.target.value === 'boolean' ? e.target.value : null

				updateHiddenFieldValue(vl)

				setDataDefault('')
				setDataField('')
			} else {
				updateHiddenFieldValue(e.value)

				setDataField(e.value)
				setDataDefault('')
			}
			setDataRelation([])
		} else if (dataPrimitiveType === 'datetime') {
			if (isDataSecondaryMode) {
				updateHiddenFieldValue(e)

				setDataDefault(e)
				setDataField('')
			} else {
				updateHiddenFieldValue(e.value)

				setDataField(e.value)
				setDataDefault('')
			}
			setDataRelation([])
		} else if (dataPrimitiveType === 'relation') {
			if (isDataSecondaryMode) {
				const idArray =
					typeof e.target.value === 'string'
						? e.target.value.split(',')
						: e.target.value
				const dataForSubmit = getFormattedRelationDataById(entityField, idArray)

				updateHiddenFieldValue(dataForSubmit, true)

				setDataRelation(dataForSubmit?.length ? dataForSubmit : [])
				setDataField('')
				setDataDefault('')
			} else {
				updateHiddenFieldValue(e.value)

				setDataField(e.value)
				setDataDefault('')
				setDataRelation([])
			}
		}
	}

	const changeFieldDataMode = (isSecondaryMode, value) => {
		if (isSecondaryMode) {
			validateWithRegExp(value, dataRegExp).then((isValid) => {
				setDataDefaultValid(isValid)
			})
		}
	}

	const initDataRegExp = (dataType) => {
		const proceedWithCondition = (condition) => {
			const dataTypeRegExObj = getRegExpByDataType(dataType, languageCode)
			if (condition) {
				if (dataTypeRegExObj) {
					setDataRegExp(dataTypeRegExObj.re)
					setDataErrorText(dataTypeRegExObj.msg)
					return dataTypeRegExObj.re
				} else {
					setDataRegExp(null)
					setDataErrorText('')
					return null
				}
			} else {
				setDataRegExp(null) //accept all
				return null
			}
		}

		switch (dataType) {
			// case 'AutoNumber':
			case 'Number':
				return proceedWithCondition(true)
			case 'Checkbox':
			case 'Date':
				return proceedWithCondition(false)
			default:
				return proceedWithCondition(false)
		}
	}

	/****INITIALIZATION***************************/
	const initStates = (cond) => {
		const fieldPrimitiveType = getFieldDataType(cond.field, entityFields)

		setDataPrimitiveType(fieldPrimitiveType)

		// const isSecondaryMode = false
		const isSecondaryMode = !(
			cond.valueType == 'Screen' ||
			cond.valueType == 'Form' ||
			cond.valueType == 'Field' ||
			cond.valueType == 'Variables' ||
			cond.valueType == 'Action'
		)

		const fieldValueText =
			typeof cond.value == 'boolean' ? cond.value : cond.value || ''

		setIsDataSecondaryMode(isSecondaryMode)

		const regExp = initDataRegExp(cond.type)
		if (fieldPrimitiveType === 'string' || fieldPrimitiveType === 'numeric') {
			if (isSecondaryMode) {
				setDataDefault(fieldValueText)
				setDataField('')
				validateWithRegExp(fieldValueText, regExp).then((isValid) => {
					setDataDefaultValid(isValid)
				})
			} else {
				setDataField(cond.value || '')
				setDataDefault('')
			}
			setDataRelation([])
		} else if (fieldPrimitiveType === 'boolean') {
			if (isSecondaryMode) {
				setDataDefault(fieldValueText)
				setDataField('')
			} else {
				setDataField(cond.value || '')
				setDataDefault('')
			}
			setDataRelation([])
		} else if (fieldPrimitiveType === 'datetime') {
			if (isSecondaryMode) {
				setDataDefault(fieldValueText)
				setDataField('')
			} else {
				setDataField(cond.value || '')
				setDataDefault('')
			}
			setDataRelation([])
		} else if (fieldPrimitiveType === 'relation') {
			if (isSecondaryMode) {
				if (entityField?.relationType === 'One')
					setDataRelation(cond.value ? [cond.value] : [])
				else setDataRelation(cond.value?.length ? cond.value : [])

				setDataField('')
				setDataDefault('')
			} else {
				setDataRelation([])
				setDataField(cond.value || '')
				setDataDefault('')
			}
		}
	}

	useEffect(() => {
		initStates(condition)
	}, [condition])

	return (
		<>
			<DoubleStateField
				appId={appId}
				languageCode={languageCode}
				fieldOptionsWithScreenFields={fieldOptionsWithScreenFields}
				dataType={dataPrimitiveType}
				fieldValue={dataField}
				fieldDataType={
					getCurrentTableField(entityField?.name, entityFields)?.dataType
				}
				fieldDataChange={fieldDataChange}
				entityField={getCurrentTableField(entityField?.name, entityFields)}
				isSecondaryMode={isDataSecondaryMode}
				setIsSecondaryMode={setIsDataSecondaryMode}
				callback={changeFieldDataMode}
				dataDefault={
					dataPrimitiveType === 'relation' ? dataRelation : ruleDataDefault
				}
				dataDefaultValid={!dataDefaultValid}
				dataErrorText={dataErrorText}
				relationTableData={relationTableData}
				refreshRelation={getRelationTableData}
			></DoubleStateField>
		</>
	)
}

export default Deprecated_FormHiddenFieldListItem

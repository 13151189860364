import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import AppInformationTemplate from '../../templates/appInformationTemplate/AppInformationTemplate'

const AppInformation = () => {
	const { t } = useTranslation()

	useEffect(() => {
		document.title = t('docTitles.settingsAppInfo')
	}, [])

	return <AppInformationTemplate />
}

export default AppInformation

import { v4 as uuidv4 } from 'uuid'

import i18next from '../../i18n/i18n'

const method = (table) => {
	let object = {
		_uid: uuidv4(),
		component: 'video',
		mapping: {
			videoUrl: {
				field: null,
				default: i18next.t('schemas.video.videoUrl'),
				type: null
			}
		}
	}

	return object
}

export default method

import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import {
	Popover,
	Button,
	List,
	ListItem,
	ListItemButton,
	ListItemText
} from '@mui/material'
import { AddBox } from '@mui/icons-material'

import { getInputByDataType } from '../../../defaultElementSchemas/schemas/form'
import timeoutDelay from '../../../methods/timeoutDelay'

import ColumnListItem from '../columnListItem/ColumnListItem'

const getListStyle = (isDraggingOver) => ({
	background: isDraggingOver ? '#D7DBFB' : 'transparent',
	borderRadius: 6,
	padding: 2,
	width: '100%'
})

const getElementStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	margin: `0 0 10px 0`,
	borderRadius: 6,
	border: isDragging ? '2px dashed gray' : 'none',
	overflow: isDragging ? 'hidden' : 'visible',
	cursor: 'default',

	...draggableStyle
})

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}
const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	}
}))

const ColumnList = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const localTable = props?.entities?.find((y) => y.name == props.source)

	const onDragEnd = (result) => {
		const { destination } = result

		if (!destination) {
			return
		}

		const elements = reorder(
			localElementData?.columns,
			result.source.index,
			result.destination.index
		)

		updateColumns(elements)
	}

	const fields = props.entities?.find((x) => x.name === props.source)?.fields

	/****ELEMENTS***************************/
	const [localElementData, setLocalElementData] = useState(null)

	const columnTitleChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			columns: {
				[index]: {
					title: {
						default: { $set: value }
					}
				}
			}
		})
		timeoutDelay(props.updateSelectedElement, modifiedElementData, 1000)
		setLocalElementData(modifiedElementData)
	}

	const deleteColumn = (index) => {
		const modifiedElementData = update(props.elementData, {
			columns: {
				$splice: [[index, 1]]
			}
		})

		props.updateSelectedElement(modifiedElementData)
		setLocalElementData(modifiedElementData)
	}

	const updateColumns = (elements) => {
		const modifiedElementData = update(props.elementData, {
			columns: { $set: elements }
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****ADD_ELEMENT***************************/
	const [unusedColumns, setUnusedColumns] = useState(null)
	//TODO:getInputByDataType('asd')

	const updateUnusedColumns = (elementData) => {
		const usedColumns = elementData?.columns?.length
			? elementData?.columns.map((x) => x?.field)
			: []

		const availableColumns = localTable?.fields?.filter(
			(x) => !usedColumns?.includes(x.name)
		)

		setUnusedColumns(availableColumns)
	}

	const addNewColumn = (field) => {
		const newElement = {
			title: {
				default: field?.label
			},
			field: field?.name,
			type: field?.dataType
		}

		const modifiedElementData = update(props.elementData, {
			columns: {
				$push: [newElement]
			}
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****ADD_ELEMENT_POPOVER***************************/
	const [anchorEl, setAnchorEl] = useState(null)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	useEffect(() => {
		if (props?.elementData) {
			updateUnusedColumns(props.elementData)
			setLocalElementData(props.elementData)
		}
	}, [props.elementData, props.source])

	return (
		<div>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId='droppable'>
					{(provided, snapshot) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							style={getListStyle(snapshot.isDraggingOver)}
						>
							{!!localElementData?.columns?.length &&
								localElementData?.columns?.map((item, index) => (
									<Draggable
										key={`droppable-${index}`}
										draggableId={`droppable-${index}`}
										index={index}
									>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={getElementStyle(
													snapshot.isDragging,
													provided.draggableProps.style
												)}
											>
												<ColumnListItem
													source={props.source}
													activeScreen={props.activeScreen}
													field={fields?.find((x) => x.name === item?.field)}
													element={item}
													deleteColumn={() => deleteColumn(index)}
													index={index}
													columnTitleChange={columnTitleChange}
													entities={props.entities}
												/>
											</div>
										)}
									</Draggable>
								))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			<div
				style={{ width: '100%', position: 'relative', padding: 2 }}
				className={classes.mb10}
			>
				<Button
					aria-describedby={id}
					startIcon={<AddBox />}
					fullWidth
					size='small'
					variant='contained'
					onClick={unusedColumns?.length ? handleClick : null}
					sx={{
						textTransform: 'none',
						backgroundColor: '#D7DBFB !important', //kozmikLightBlue
						color: '#1B36FE', //kozmikBlue
						boxShadow: 'none',
						fontSize: '14px',
						borderRadius: '6px'
					}}
				>
					{t('screens.elementSettings.table.column.addNewColumn')}
				</Button>
				{!!unusedColumns?.length && (
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left'
						}}
						PaperProps={{
							style: { right: 17 } //NOTE:for 100% width of parent container
						}}
					>
						<List>
							{unusedColumns.map((item, index) => (
								<ListItem disablePadding key={`form_element_${index}`}>
									<ListItemButton
										style={{ height: 35 }}
										onClick={() => {
											handleClose()
											addNewColumn(item)
										}}
									>
										<ListItemText
											primary={item.label}
											primaryTypographyProps={{ fontSize: 13 }}
										/>
									</ListItemButton>
								</ListItem>
							))}
						</List>
					</Popover>
				)}
			</div>
		</div>
	)
}

export default ColumnList

const all = [
	'RowId',
	'Text',
	'LongText',
	// 'AutoNumber',
	'Checkbox',
	'Number',
	'Image',
	'File',
	'Date',
	'Email',
	'Phone',
	'URL',
	'GeoLocation',
	'Relation',
	'Lookup',
	'Rollup',
	'Formula'
]

export default all

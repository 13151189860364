import { Typography } from '@mui/material'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import cx from 'classnames'

const useStyles = makeStyles((theme) => ({
	toolbar: {
		color: theme.custom.colors.kozmikBlue,
		//fontFamily: "DMSans",
		fontSize: 14,
		//lineHeight: "2.2vw",
		textAlign: 'left',
		fontWeight: 'bolder'
	}
}))

const Label = (props) => {
	const classes = useStyles()

	const { className, ...restProps } = props

	return (
		<Typography
			className={cx(classes.toolbar, className ? className : null)}
			{...restProps}
		>
			{props.text}{' '}
		</Typography>
	)
}

export default Label

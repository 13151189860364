import React, { useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'

import Cookies from 'universal-cookie'
import { useTranslation } from 'react-i18next'

import KozmikProgressBar from '../../atoms/kozmikProgressBar/KozmikProgressBar'

import kozmikTheme from '../../../assets/theme/theme.json'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		width: '100%',
		padding: '10px 15px',
		backgroundColor: theme.custom.colors.gray,
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	},
	headContainer: {
		padding: '0 10px'
	},
	title: {
		fontSize: 14,
		fontWeight: 'bold',
		display: 'inline-block'
	},
	subTitle: {
		fontSize: 12,
		display: 'inline-block'
	},
	progressContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	progress: {
		margin: '15px 0',
		flexGrow: 1
	},
	progressTxt: {
		fontSize: 10,
		fontWeight: 'bold',
		paddingLeft: 11
	}
}))

const cookieVersion = '0.0.2' //increment version for data changes

const OnboardingMobile = (props) => {
	const { t } = useTranslation()
	const cookies = new Cookies()
	const classes = useStyles()

	const localizedData = t('onboarding.data', { returnObjects: true })

	const [cookieData, setCookieData] = useState(null)

	const setOnboardingCookie = (data) => {
		cookies.set('onboard', data, {
			path: '/',
			sameSite: 'none',
			secure: true,
			domain: process.env.REACT_APP_COOKIE_DOMAIN
			// httpOnly: true
		})
		setCookieData(data)
	}

	const getCookie = () => {
		const cData = cookies.get('onboard')
		if (cData?.v === cookieVersion) {
			setCookieData(cData)
		} else {
			const newcData = {
				v: cookieVersion,
				closed: false,
				progress: Array(localizedData.length).fill(0)
			}

			setOnboardingCookie(newcData)
		}
	}

	const completedCount = cookieData?.progress?.reduce((a, b) => a + b, 0)
	const itemCount = cookieData?.progress?.length

	const renderOnboarding = () => {
		return props.justSayHello ? (
			<div className={classes.mainContainer}>
				<div className={classes.headContainer}>
					<span className={classes.title}>{t('onboarding.title')}</span>
					<span className={classes.subTitle}>{t('onboarding.subTitle')}</span>
				</div>
			</div>
		) : (
			itemCount != completedCount && (
				<div
					className={classes.mainContainer}
					onClick={() => {
						props.setIsOnboardingVisible(true)
					}}
				>
					<div className={classes.headContainer}>
						<span className={classes.title}>{t('onboarding.title')}</span>
						<span className={classes.subTitle}>{t('onboarding.subTitle')}</span>
						<div className={classes.progressContainer}>
							<KozmikProgressBar
								barColor={'#ffffff'}
								progressColor={kozmikTheme.colors.kozmikGreen}
								height={6}
								variant='determinate'
								value={(100 / itemCount) * completedCount}
								className={classes.progress}
							/>
							{!!cookieData && (
								<span className={classes.progressTxt}>
									{completedCount}/{itemCount}
								</span>
							)}
						</div>
					</div>
				</div>
			)
		)
	}

	useEffect(() => {
		getCookie()
	}, [])

	useEffect(() => {
		getCookie()
	}, [props.isOnboardingVisible])

	return props.builderOnMobile && renderOnboarding()
}

export default OnboardingMobile

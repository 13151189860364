import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import SplashSettingsTemplate from '../../templates/splashSettingsTemplate/SplashSettingsTemplate'

const SplashSettings = () => {
	const { t } = useTranslation()

	useEffect(() => {
		document.title = t('docTitles.settingsSplashSettings')
	}, [])

	return <SplashSettingsTemplate />
}

export default SplashSettings

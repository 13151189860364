import getIncrementedScreen from './getIncrementedScreen'
import getAutoGeneratedScreensByTable from './getAutoGeneratedScreensByTable'
import generateScreenPathByName from './generateScreenPathByName'
import getModifiedScreensByElement from './getModifiedScreensByElement'
import getModifiedScreensByGroup from './getModifiedScreensByGroup'
import fixDetailScreenActions from './fixDetailScreenActions'

export default {
	getIncrementedScreen,
	getAutoGeneratedScreensByTable,
	generateScreenPathByName,
	getModifiedScreensByElement,
	getModifiedScreensByGroup,
	fixDetailScreenActions
}

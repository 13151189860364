import i18next from '../../i18n/i18n'

const elementNames = {
	button: i18next.t('elementNames.button'),
	buttonBar: i18next.t('elementNames.buttonBar'),
	calendarList: i18next.t('elementNames.calendarList'),
	checkbox: i18next.t('elementNames.checkbox'),
	choice: i18next.t('elementNames.choice'),
	emailButton: i18next.t('elementNames.emailButton'),
	form: i18next.t('elementNames.form'),
	image: i18next.t('elementNames.image'),
	linkButton: i18next.t('elementNames.linkButton'),
	list: i18next.t('elementNames.list'),
	table: i18next.t('elementNames.table'),
	columns: i18next.t('elementNames.columns'),
	logoutButton: i18next.t('elementNames.logoutButton'),
	settingsButton: i18next.t('elementNames.settingsButton'),
	map: i18next.t('elementNames.map'),
	noteBox: i18next.t('elementNames.noteBox'),
	numberEntry: i18next.t('elementNames.numberEntry'),
	phoneButton: i18next.t('elementNames.phoneButton'),
	seperator: i18next.t('elementNames.seperator'),
	textArea: i18next.t('elementNames.textArea'),
	tileList: i18next.t('elementNames.tileList'),
	mapList: i18next.t('elementNames.mapList'),
	reviews: i18next.t('elementNames.reviews'),
	chat: i18next.t('elementNames.chat'),
	carousel: i18next.t('elementNames.carousel'),
	container: i18next.t('elementNames.container'),
	gallery: i18next.t('elementNames.gallery'),
	titleCard: i18next.t('elementNames.titleCard'),
	userInfo: i18next.t('elementNames.userInfo'),
	video: i18next.t('elementNames.video'),
	webView: i18next.t('elementNames.webView'),
	chartLine: i18next.t('elementNames.chartLine'),
	chartBar: i18next.t('elementNames.chartBar'),
	chartPie: i18next.t('elementNames.chartPie'),
	chartProgress: i18next.t('elementNames.chartProgress'),
	progressBar: i18next.t('elementNames.progressBar'),
	barcode: i18next.t('elementNames.barcode'),
	qrCode: i18next.t('elementNames.qrCode'),
	numbers: i18next.t('elementNames.numbers'),
	rating: i18next.t('elementNames.rating')
}

export default elementNames

import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from '@mui/material'
import cx from 'classnames'

const useStyles = makeStyles((theme) => ({
	menuItem: {
		cursor: 'pointer',
		textDecoration: 'none !important',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		height: 40,
		padding: '0 15px',
		borderRadius: 6,
		justifyContent: 'space-between',
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: theme.custom.colors.disabledGray
		}
	},
	active: {
		backgroundColor: `${theme.custom.colors.kozmikLightBlue} !important`,
		'& $text': {
			color: theme.custom.colors.kozmikBlue,
			fontWeight: '600'
		}
	},
	text: {
		flex: 1,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		fontSize: 14,
		color: theme.custom.colors.textPrimary,
		textAlign: 'left'
	}
}))

const KozmikMenuItem = (props) => {
	const classes = useStyles()

	const { text, isActive, adornment, onClick, ...restProps } = props

	return (
		<div {...restProps}>
			<div
				className={cx(classes.menuItem, isActive && classes.active)}
				onClick={onClick}
			>
				<Typography className={classes.text}>{text}</Typography>
				{adornment}
			</div>
		</div>
	)
}

export default KozmikMenuItem

import React from 'react'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { Box, Button } from '@mui/material'
import cx from 'classnames'
import { CheckCircle, RemoveCircle, ContentCopy } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '4px 12px',
		backgroundColor: theme.custom.colors.kozmikGray,
		borderRadius: 8,
		marginBottom: 5
	},
	infoContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	text: {
		fontSize: 12,
		color: theme.custom.colors.textPrimary
	},
	version: {
		width: 60
	},
	status: {
		width: 20,
		display: 'flex',
		alignItems: 'center'
	},
	btnTxt: {
		fontSize: 12,
		fontWeight: '600',
		textAlign: 'left',
		textTransform: 'none'
	},
	btnIcon: {
		color: theme.custom.colors.kozmikBlue,
		fontSize: 12
	},
	checkGreen: {
		fontSize: 15,
		color: theme.custom.colors.kozmikMatGreen
	},
	checkRed: {
		fontSize: 15,
		color: theme.custom.colors.lightRed
	},
	kozmikBlue: {
		color: theme.custom.colors.kozmikBlue
	},
	kozmikRed: {
		color: theme.custom.colors.red
	},
	copyBtnIcon: {
		color: theme.custom.colors.kozmikBlue,
		fontSize: 16,
		cursor: 'pointer'
	},
	buttonContainer: {
		display: 'flex',
		alignItems: 'center'
	}
}))

const DomainItem = (props) => {
	const classes = useStyles()

	const copyToClipboard = (val) => {
		navigator.clipboard.writeText(val)
	}

	return (
		<Box className={classes.mainContainer}>
			<Box className={classes.infoContainer}>
				<Box className={classes.status}>
					{props.item.DomainVerified ? (
						<CheckCircle className={classes.checkGreen} />
					) : (
						<RemoveCircle className={classes.checkRed} />
					)}
				</Box>
				<a
					href={`https://${props.item.Domain}`}
					target={'_blank'}
					className={cx(classes.text, classes.version)}
					rel='noreferrer'
				>
					{props.item.Domain}
				</a>
			</Box>
			<Box className={classes.buttonContainer}>
				<ContentCopy
					className={classes.copyBtnIcon}
					onClick={() => copyToClipboard(`https://${props.item.Domain}`)}
				/>
				{!props.item.DomainVerified && (
					<Button
						size='small'
						variant='contained'
						onClick={() => {
							props?.verifyBtnClick(props.item)
						}}
						sx={{
							textTransform: 'none',
							backgroundColor: `transparent !important`,
							boxShadow: 'none !important',
							fontSize: 14,
							borderRadius: '4px'
						}}
					>
						<Typography className={cx(classes.btnTxt, classes.kozmikBlue)}>
							{props?.verifyBtnText}
						</Typography>
					</Button>
				)}
				<Button
					size='small'
					variant='contained'
					onClick={() => {
						props?.deleteBtnClick(props.item)
					}}
					sx={{
						textTransform: 'none',
						backgroundColor: `transparent !important`,
						boxShadow: 'none !important',
						fontSize: 14,
						borderRadius: '4px'
					}}
				>
					<Typography className={cx(classes.btnTxt, classes.kozmikRed)}>
						{props?.deleteBtnText}
					</Typography>
				</Button>
			</Box>
		</Box>
	)
}

export default DomainItem

import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import {
	Grid,
	Typography,
	Box,
	Button,
	CardMedia,
	CircularProgress
} from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import { useParams } from 'react-router-dom'

import { default as kozmikTheme } from '../../../../assets/theme/theme.json'
import { default as successImage } from '../../../../assets/images/success.png'

import KozmikModal from '../../../molecules/kozmikModal/KozmikModal'
import KozmikMenuItem from '../../../molecules/kozmikMenuItem/KozmikMenuItem'
import KozmikTextField from '../../../atoms/kozmikTextField/KozmikTextField'
// import KozmikCheckBox from '../../../atoms/kozmikCheckBox/KozmikCheckBox'
import SelectBox from '../../../molecules/selectBox/SelectBox'
// import RemovableImageList from '../../removableImageList/RemovableImageList'
import TemplateImageList from '../../templateImageList/TemplateImageList'
import HistoryItem from '../../../molecules/historyItem/HistoryItem'
import Badge from '../../../atoms/badge/Badge'

import templateClient from '../../../../services/templateClient'
import showToastResponseError from '../../../../methods/showToastResponseError'

const useStyles = makeStyles((theme) => ({
	gridContainer: {
		width: '100%',
		height: '100%',
		overflowY: 'auto'
	},
	leftBox: {
		padding: '45px 20px 20px 20px',
		backgroundColor: theme.custom.colors.kozmikGray,
		flexDirection: 'column',
		justifyContent: 'space-between',
		overflowY: 'auto',
		[theme.breakpoints.up('sm')]: {
			height: '100%'
		}
	},
	rightBox: {
		padding: '45px 20px 20px 20px',
		backgroundColor: '#ffffff',
		overflowY: 'auto',
		[theme.breakpoints.down('md')]: {
			padding: '25px 20px 20px 20px'
		},
		[theme.breakpoints.up('sm')]: {
			height: '100%'
		}
	},
	checkActive: {
		fontSize: 15,
		color: theme.custom.colors.kozmikMatGreen
	},
	checkPassive: {
		fontSize: 15,
		color: '#BBBBBD'
	},
	grayBox: {
		borderRadius: 8,
		minHeight: 50,
		width: '100%',
		marginBottom: 10,
		padding: '5px 25px',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: theme.custom.colors.kozmikGray
	},
	radioLabel: {
		fontSize: 14
	},
	mainTitle: {
		marginBottom: 25,
		fontSize: 16,
		fontWeight: '600'
	},
	contentTitle: {
		marginBottom: 10,
		fontSize: 16,
		fontWeight: '600'
	},
	contentDesc: {
		marginBottom: 15,
		fontSize: 14
	},
	subTitle: {
		fontSize: 12,
		fontWeight: '600'
	},
	text: {
		fontSize: 12
	},
	textDNS: {
		fontSize: 14,
		padding: '4px 0'
	},
	buttonText: {
		color: '#ffffff',
		fontSize: 14,
		fontWeight: 'bold'
	},
	input: {
		fontSize: 14,
		backgroundColor: theme.custom.colors.kozmikGray,
		borderRadius: 6,
		border: 'none',
		overflow: 'hidden',
		'& .MuiOutlinedInput-root': {
			padding: 0
		},
		'& .MuiOutlinedInput-adornedStart': {
			paddingLeft: 0
		},
		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: 0
		}
	},
	defaultInput: {
		fontSize: 14,
		backgroundColor: theme.custom.colors.kozmikGray,
		borderRadius: 6,
		border: 'none'
	},
	adornmentText: {
		fontSize: 12,
		backgroundColor: theme.custom.colors.gray,
		padding: '16px 12px',
		color: theme.custom.colors.textPrimary
	},
	copyBtnTxt: {
		color: theme.custom.colors.kozmikBlue,
		fontSize: 12,
		fontWeight: '600',
		textAlign: 'left',
		textTransform: 'none'
	},
	copyBtnIcon: {
		color: theme.custom.colors.kozmikBlue,
		fontSize: 12
	},
	link: {
		color: theme.custom.colors.kozmikBlue,
		textDecoration: 'underline',
		cursor: 'pointer'
	},
	successModalBox: {
		width: '100%',
		height: '100%',
		padding: 30
	},
	successTitle: {
		marginLeft: 10,
		marginTop: 20,
		fontWeight: '600',
		fontSize: 16
	},
	successText: {
		fontSize: 14,
		marginBottom: 10
	},
	mb20: {
		marginBottom: 20
	}
}))

const TemplateSubmit = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	let { appId } = useParams()

	const [selectedTab, setSelectedTab] = useState(0)
	const [history, setHistory] = useState([])
	// const [imageList, setImageList] = useState([
	// 	{
	// 		src: 'https://cdn.kozmik.io/cdn/images/511609ed-0e9c-4ba8-874d-64be5bff9d23.jpg'
	// 	},
	// 	{
	// 		src: 'https://cdn.kozmik.io/cdn/images/511609ed-0e9c-4ba8-874d-64be5bff9d23.jpg'
	// 	},
	// 	{
	// 		src: 'https://cdn.kozmik.io/cdn/images/511609ed-0e9c-4ba8-874d-64be5bff9d23.jpg'
	// 	}
	// ])
	const [successVisible, setSuccessVisible] = useState(false)

	/****APP_NAME***************************/
	const [appName, setAppName] = useState('')

	const changeAppName = (e) => {
		setAppName(e.target.value)
	}

	/****DESCRIPTION***************************/
	const [description, setDescription] = useState('')

	const changeDescription = (e) => {
		setDescription(e.target.value)
	}

	/****HIGHLIGHTS***************************/
	const [highlights, setHighlights] = useState('')

	const changeHighlights = (e) => {
		setHighlights(e.target.value)
	}

	/****TERMS***************************/
	// const [termsAccepted, setTermsAccepted] = useState(false)

	// const changeTermAccepted = (e) => {
	// 	setTermsAccepted(e.target.checked)
	// }

	/****PRICE***************************/
	const [price, setPrice] = useState(0)

	const priceOptions = [
		{
			label: 'Free', //TODO:Localization
			value: 0
		}
	]

	const priceChange = (e) => {
		setPrice(e.target.value)
	}

	/****FEATURES***************************/
	const [features, setFeatures] = useState([])
	const [featuresOptions, setFeaturesOptions] = useState([])

	const featuresChange = (e) => {
		setFeatures(
			typeof e.target.value === 'string'
				? e.target.value.split(',')
				: e.target.value
		)
	}

	const getTags = () => {
		templateClient
			.getTags()
			.then((response) => {
				if (response?.data?.success) {
					setFeaturesOptions(response.data?.data || [])
				} else {
					setFeaturesOptions([])
					showToastResponseError(response)
				}
			})
			.catch((error) => {
				setFeaturesOptions([])
			})
	}

	/****CATEGORIES***************************/
	const [categories, setCategories] = useState([])
	const [categoriesOptions, setCategoriesOptions] = useState([])

	const categoriesChange = (e) => {
		setCategories(
			typeof e.target.value === 'string'
				? e.target.value.split(',')
				: e.target.value
		)
	}

	const getCategories = () => {
		templateClient
			.getCategories()
			.then((response) => {
				if (response?.data?.success) {
					setCategoriesOptions(response.data?.data || [])
				} else {
					setCategoriesOptions([])
					showToastResponseError(response)
				}
			})
			.catch((error) => {
				setCategoriesOptions([])
			})
	}

	/****IMAGES***************************/
	const [image1, setImage1] = useState(null)
	const [image2, setImage2] = useState(null)
	const [image3, setImage3] = useState(null)

	/****SUBMIT***************************/
	const [submitting, setSubmitting] = useState(false)

	const submit = () => {
		setSubmitting(true)
		const submitData = {
			Name: appName,
			Description: description,
			Highlights: highlights,
			Categories: categories,
			Tags: features,
			Price: price,
			Image1: image1,
			Image2: image2,
			Image3: image3
		}

		templateClient
			.submitTemplate(appId, submitData)
			.then((response) => {
				if (response?.data?.success) {
					props.hideModal()
					setSuccessVisible(true)
				} else {
					showToastResponseError(response)
				}
				setSubmitting(false)
			})
			.catch((error) => {
				setSubmitting(false)
			})
	}

	/****************************************/
	const tab0Completed = !!appName && !!description && !!highlights //&& termsAccepted
	const tab1Completed = !!features?.length && !!categories?.length
	const tab2Completed = image1 && image2 && image3

	const submitButtonActive = tab0Completed && tab1Completed && tab2Completed

	const getHistory = () => {
		templateClient.getSubmitHistory(appId).then((response) => {
			if (response?.data?.success) {
				setHistory(response?.data?.data)
			} else {
				showToastResponseError(response)
			}
		})
	}

	const initModal = () => {
		setSelectedTab(0)
		setAppName('')
		setDescription('')
		setHighlights('')
		// setTermsAccepted(false)
		setPrice(0)
		setFeatures([])
		setCategories([])
		getCategories()
		getTags()
		getHistory()
		setImage1(null)
		setImage2(null)
		setImage3(null)
		setSubmitting(false)
	}

	useEffect(() => {
		props.visible && initModal()
	}, [props.visible])

	return (
		<>
			<KozmikModal visible={props.visible} hideModal={props.hideModal}>
				<Grid container className={classes.gridContainer}>
					<Grid container item xs={12} sm={4} className={classes.leftBox}>
						<Grid
							item
							display={{ xs: 'block', sm: 'block', md: 'block', lg: 'none' }}
							className={classes.mb20}
						>
							<Button
								fullWidth
								disabled={!submitButtonActive || submitting}
								size='small'
								variant='contained'
								onClick={() => submit()}
								sx={{
									textTransform: 'none',
									backgroundColor: `${kozmikTheme.colors.kozmikBlue} !important`,
									opacity: submitButtonActive ? 1 : 0.4,
									boxShadow: 'none !important',
									height: '40px',
									borderRadius: '8px'
								}}
							>
								<Box
									style={{
										display: 'flex',
										alignItems: 'center'
									}}
								>
									<Typography className={classes.buttonText}>
										{t('publishModule.templateModal.publishBtn')}
									</Typography>
									{submitting && (
										<CircularProgress
											size={20}
											sx={{
												margin: '10px',
												color: '#ffffff'
											}}
										/>
									)}
								</Box>
							</Button>
						</Grid>
						<Grid item>
							<Typography
								className={classes.mainTitle}
								display={{
									xs: 'none',
									sm: 'none',
									md: 'none',
									lg: 'block'
								}}
							>
								{t('publishModule.templateModal.title')}
							</Typography>
							<KozmikMenuItem
								text={t('publishModule.templateModal.appInfoMenu')}
								onClick={() => {
									setSelectedTab(0)
								}}
								isActive={selectedTab === 0}
								adornment={
									<CheckCircle
										className={
											tab0Completed ? classes.checkActive : classes.checkPassive
										}
									/>
								}
							/>
							<KozmikMenuItem
								text={t('publishModule.templateModal.listingMenu')}
								onClick={() => {
									setSelectedTab(1)
								}}
								isActive={selectedTab === 1}
								adornment={
									<CheckCircle
										className={
											tab1Completed ? classes.checkActive : classes.checkPassive
										}
									/>
								}
							/>
							<KozmikMenuItem
								text={t('publishModule.templateModal.screensMenu')}
								onClick={() => {
									setSelectedTab(2)
								}}
								isActive={selectedTab === 2}
								adornment={
									<CheckCircle
										className={
											tab2Completed ? classes.checkActive : classes.checkPassive
										}
									/>
								}
							/>
							<KozmikMenuItem
								text={t('publishModule.templateModal.history')}
								onClick={() => setSelectedTab(3)}
								isActive={selectedTab === 3}
								adornment={<Badge text={history?.length || 0} />}
							/>
						</Grid>
						<Grid
							item
							display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block' }}
						>
							<Button
								fullWidth
								disabled={!submitButtonActive || submitting}
								size='small'
								variant='contained'
								onClick={() => submit()}
								sx={{
									textTransform: 'none',
									backgroundColor: `${kozmikTheme.colors.kozmikBlue} !important`,
									opacity: submitButtonActive ? 1 : 0.4,
									boxShadow: 'none !important',
									height: '40px',
									borderRadius: '8px'
								}}
							>
								<Box
									style={{
										display: 'flex',
										alignItems: 'center'
									}}
								>
									<Typography className={classes.buttonText}>
										{t('publishModule.templateModal.publishBtn')}
									</Typography>
									{submitting && (
										<CircularProgress
											size={20}
											sx={{
												margin: '10px',
												color: '#ffffff'
											}}
										/>
									)}
								</Box>
							</Button>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={8} className={classes.rightBox}>
						{selectedTab === 0 && (
							<>
								<Typography className={classes.contentTitle}>
									{t('publishModule.templateModal.appInfoTitle')}
								</Typography>
								<Typography className={classes.contentDesc}>
									{t('publishModule.templateModal.appInfoHighlights')}
								</Typography>

								<Box style={{ marginBottom: 10 }}>
									<Typography className={classes.subTitle}>
										{t('publishModule.templateModal.appName')}
									</Typography>
									<KozmikTextField
										className={classes.input}
										fullWidth
										placeholder={t(
											'publishModule.templateModal.appNamePlaceholder'
										)}
										value={appName}
										onChange={changeAppName}
									/>
								</Box>

								<Box style={{ marginBottom: 10 }}>
									<Typography className={classes.subTitle}>
										{t('publishModule.templateModal.description')}
									</Typography>
									<KozmikTextField
										className={classes.input}
										fullWidth
										placeholder={t(
											'publishModule.templateModal.descriptionPlaceholder'
										)}
										value={description}
										onChange={changeDescription}
									/>
								</Box>

								<Box style={{ marginBottom: 10 }}>
									<Typography className={classes.subTitle}>
										{t('publishModule.templateModal.highlights')}
									</Typography>
									<KozmikTextField
										className={classes.defaultInput}
										fullWidth
										placeholder={t(
											'publishModule.templateModal.highlightsPlaceholder'
										)}
										value={highlights}
										onChange={changeHighlights}
										multiline
										rows={2}
									/>
								</Box>

								{/* <Box style={{ marginBottom: 10 }}>
									<Typography className={classes.subTitle}>
										{t('publishModule.templateModal.termsOfUse')}
									</Typography>
									<Typography className={classes.text}>
										{t('publishModule.templateModal.pleaseRead')}{' '}
										<a className={classes.link}>
											{t('publishModule.templateModal.termsLinkText')}
										</a>
									</Typography>
									<Typography className={classes.text}>
										{t('publishModule.templateModal.guidelines')}
									</Typography>

									<Box style={{ display: 'flex', alignItems: 'center' }}>
										<KozmikCheckBox
											checked={termsAccepted}
											onChange={changeTermAccepted}
										/>
										<Typography className={classes.text}>
											{t('publishModule.templateModal.acceptText')}
										</Typography>
									</Box>
								</Box> */}
							</>
						)}

						{selectedTab === 1 && (
							<>
								<Typography className={classes.contentTitle}>
									{t('publishModule.templateModal.listingTitle')}
								</Typography>
								<Typography className={classes.contentDesc}>
									{t('publishModule.templateModal.listingDesc')}
								</Typography>

								<Box style={{ marginBottom: 10 }}>
									<Typography className={classes.subTitle}>
										{t('publishModule.templateModal.features')}
									</Typography>
									<SelectBox
										className={classes.input}
										onChange={featuresChange}
										value={features}
										data={featuresOptions}
										textKey='Name'
										valueKey='Id'
										multiple
										renderValue={(selected) => {
											if (selected.length === 0) {
												return t(
													'publishModule.templateModal.featuresPlaceholder'
												)
											} else {
												return featuresOptions
													?.filter((x) => selected?.includes(x.Id))
													?.map((x) => x.Name)
													?.join(',')
											}
										}}
									/>
								</Box>

								<Box style={{ marginBottom: 10 }}>
									<Typography className={classes.subTitle}>
										{t('publishModule.templateModal.categories')}
									</Typography>
									<SelectBox
										className={classes.input}
										onChange={categoriesChange}
										value={categories}
										data={categoriesOptions}
										textKey='Name'
										valueKey='Id'
										multiple
										renderValue={(selected) => {
											if (selected.length === 0) {
												return t(
													'publishModule.templateModal.categoriesPlaceholder'
												)
											} else {
												return categoriesOptions
													?.filter((x) => selected?.includes(x.Id))
													?.map((x) => x.Name)
													?.join(',')
											}
										}}
									/>
								</Box>

								<Box style={{ marginBottom: 10 }}>
									<Typography className={classes.subTitle}>
										{t('publishModule.templateModal.price')}
									</Typography>
									<SelectBox
										className={classes.input}
										onChange={priceChange}
										value={price}
										data={priceOptions}
										textKey='label'
										valueKey='value'
										// placeholder={t(
										// 	'publishModule.templateModal.pricePlaceholder'
										// )}
									/>
								</Box>
							</>
						)}

						{selectedTab === 2 && (
							<>
								<Typography className={classes.contentTitle}>
									{t('publishModule.templateModal.screensTitle')}
								</Typography>
								<Typography className={classes.contentDesc}>
									{t('publishModule.templateModal.screensDesc')}
								</Typography>

								{/* <RemovableImageList items={imageList} setItems={setImageList} /> */}
								<TemplateImageList
									image1={image1}
									image2={image2}
									image3={image3}
									setImage1={setImage1}
									setImage2={setImage2}
									setImage3={setImage3}
								/>
							</>
						)}

						{selectedTab === 3 && (
							<>
								<Typography className={classes.contentTitle}>
									{t('publishModule.templateModal.historyTitle')}
								</Typography>

								{history?.length > 0 ? (
									history.map((item, index) => (
										<HistoryItem platform='template' item={item} key={index} />
									))
								) : (
									<Typography className={classes.contentDesc}>
										{t('publishModule.templateModal.notPublished')}
									</Typography>
								)}
							</>
						)}
					</Grid>
				</Grid>
			</KozmikModal>

			<KozmikModal
				visible={successVisible}
				hideModal={() => setSuccessVisible(false)}
				flexHeight
				sm
			>
				<Grid
					container
					justifyContent='center'
					className={classes.successModalBox}
				>
					<Grid
						item
						container
						xs={12}
						alignItems='center'
						className={classes.mb20}
					>
						<CardMedia
							image={successImage}
							component='img'
							style={{ width: 52, height: 52 }}
						/>
						<Typography className={classes.successTitle}>
							{t('publishModule.templateModal.success.title')}
						</Typography>
					</Grid>
					<Grid item xs={12} className={classes.mb20}>
						<Typography className={classes.successText}>
							{t('publishModule.templateModal.success.paragraph1')}
						</Typography>
					</Grid>
					<Grid item container xs={12} justifyContent='center'>
						<Button
							onClick={() => setSuccessVisible(false)}
							size='small'
							variant='contained'
							sx={{
								textTransform: 'none',
								backgroundColor: `${kozmikTheme.colors.kozmikBlue} !important`,
								boxShadow: 'none !important',
								height: '40px',
								width: '216px',
								borderRadius: '4px'
							}}
						>
							<Typography className={classes.buttonText}>
								{t('publishModule.templateModal.success.button')}
							</Typography>
						</Button>
					</Grid>
				</Grid>
			</KozmikModal>
		</>
	)
}

export default TemplateSubmit

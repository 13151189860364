import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material'
import cx from 'classnames'

const useStyles = makeStyles((theme) => ({
	input: {
		borderRadius: 3,
		borderWidth: 0,
		height: 37,
		backgroundColor: (props) =>
			props?.bgColor ? props.bgColor : theme.custom.colors.kozmikGray,
		fontSize: '14px',
		color: theme.custom.colors.textPrimary,
		outline: 'none',
		padding: '0 21.5px 0 10px',
		minWidth: 60,
		width: '100%'
	},
	button: {
		borderRadius: 3,
		cursor: 'pointer',
		position: 'absolute',
		zIndex: 1,
		right: 3,
		width: 18.5,
		height: 18.5
	},
	increase: {
		top: 0
	},
	decrease: {
		bottom: 0
	},
	disabled: {
		color: `${theme.custom.colors.disabledPrimary} !important`,
		backgroundColor: `${theme.custom.colors.disabledGray} !important`,
		cursor: 'default !important'
	}
}))

const NumericInput = (props) => {
	const classes = useStyles(props)
	const {
		value,
		onChange,
		className,
		disabled,
		maxVal,
		minVal,
		bgColor,
		...restProps
	} = props

	const handleChange = (e) => {
		// const checkedVal = e.target.validity.valid && e.target.value >= 0 ? e.target.value : value
		const checkedVal = !isNaN(e.target.value) ? e.target.value : value

		if (checkedVal != value) {
			const submitVal = checkedVal ? parseInt(checkedVal) : 0
			onChange(submitVal)
		}
	}

	const increase = () => {
		if (!disabled) {
			const convertedVal = value ? parseInt(value) : 0
			const submitVal = convertedVal + 1

			if (maxVal) {
				convertedVal < maxVal && onChange(submitVal)
			} else {
				onChange(submitVal)
			}
		}
	}

	const decrease = () => {
		if (!disabled) {
			const convertedVal = value ? parseInt(value) : 0
			const submitVal = convertedVal - 1

			if (minVal) {
				convertedVal > minVal && onChange(submitVal)
			} else {
				onChange(submitVal)
			}
		}
	}

	return (
		<div style={{ position: 'relative' }}>
			<input
				disabled={disabled}
				type='text'
				pattern='[0-9]*'
				onInput={handleChange}
				value={value}
				className={cx(classes.input, className, disabled && classes.disabled)}
				{...restProps}
			/>
			<ArrowDropUp
				className={cx(
					classes.button,
					classes.increase,
					disabled && classes.disabled
				)}
				onClick={increase}
			/>
			<ArrowDropDown
				className={cx(
					classes.button,
					classes.decrease,
					disabled && classes.disabled
				)}
				onClick={decrease}
			/>
		</div>
	)
}
export default NumericInput

import { ApiClient } from './client'

import { default as appConfig } from '../config/app.json'

const appUserDomain = `${appConfig.domains.accountDomain}/api/a`

export default {
	async invite(appId, data) {
		let url = '/inviteMultiple'
		let headers = {}
		headers['AppId'] = appId
		headers['Content-Type'] = 'application/json'

		return await new ApiClient(appUserDomain, headers).post(url, data)
	}
}

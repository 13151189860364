import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import ColumnList from '../../../molecules/columnList/ColumnList'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import { ReactComponent as Koseli } from '../../../../assets/icons/Settings/Koseli.svg'
import { ReactComponent as Oval } from '../../../../assets/icons/Settings/Oval.svg'
import { ReactComponent as Daire } from '../../../../assets/icons/Settings/KaroTip-Daire.svg'
import { ReactComponent as MetinBoyutuKucuk } from '../../../../assets/icons/Settings/MetinBoyutu-Kucuk.svg'
import { ReactComponent as MetinBoyutuStandart } from '../../../../assets/icons/Settings/MetinBoyutu-Standart.svg'
import { ReactComponent as MetinBoyutuBuyuk } from '../../../../assets/icons/Settings/MetinBoyutu-Buyuk.svg'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	}
}))

const Columns = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [elementName, setElementName] = useState(null)

	const fontSizeOptions = [
		{
			text: t('screens.elementSettings.table.fontOpts.sm'),
			value: 'sm',
			icon: MetinBoyutuKucuk
		},
		{
			text: t('screens.elementSettings.table.fontOpts.md'),
			value: 'md',
			icon: MetinBoyutuStandart
		},
		{
			text: t('screens.elementSettings.table.fontOpts.lg'),
			value: 'lg',
			icon: MetinBoyutuBuyuk
		}
	]

	/****IMG_CORNER***************************/
	const [imgCorner, setImgCorner] = useState(null)
	const imgCornerChange = (val) => {
		submitImgCorner(val)
		setImgCorner(val)
	}
	const submitImgCorner = (x) => {
		const modifiedElementData = update(props.elementData, {
			corner: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}
	const imgCornerOptions = [
		{
			text: t('screens.elementSettings.table.imgCornerOpts.square'),
			value: 'square',
			icon: Koseli
		},
		{
			text: t('screens.elementSettings.table.imgCornerOpts.oval'),
			value: 'oval',
			icon: Oval
		},
		{
			text: t('screens.elementSettings.table.imgCornerOpts.circle'),
			value: 'circle',
			icon: Daire
		}
	]

	/****FONT_SIZE***************************/
	const [fontSize, setFontSize] = useState(null)
	const fontSizeChange = (val) => {
		submitFontSize(val)
		setFontSize(val)
	}
	const submitFontSize = (x) => {
		const modifiedElementData = update(props.elementData, {
			fontSize: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****FILTER_&_SORT***************************/
	const [localElementData, setLocalElementData] = useState(null)

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****IMG_CORNER***************************/
		setImgCorner(elementData?.corner ? elementData.corner : null)
		/****FONT_SIZE***************************/
		setFontSize(elementData?.fontSize)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
			setLocalElementData(props.elementData)
		}
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.columns')}
					expanded={true}
				>
					<ColumnList
						source={props.activeScreen?.data?.source}
						activeScreen={props.activeScreen}
						elementData={props.elementData}
						entities={props.entities}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.table.imgCorner')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={imgCornerOptions}
								getChange={imgCornerChange}
								activeValue={imgCorner}
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.table.fontSize')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={fontSizeOptions}
								getChange={fontSizeChange}
								activeValue={fontSize}
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default Columns

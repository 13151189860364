import { v4 as uuidv4 } from 'uuid'

import languages from '../../i18n/languages'

const method = (fieldData) => {
	let object = {
		_uid: uuidv4(),
		component: 'inputChoice',
		type: 'dropdown', // dropdown,chips,radio,checkbox
		allowMultiple: fieldData.relationType === 'Many' ? true : false,
		enableRepeat: false,
		relation: {
			type: fieldData.relationType,
			source: fieldData.relatedEntityName,
			field: fieldData.displayFieldName
		},
		mapping: {
			field: fieldData.name,
			type: fieldData.dataType
		},
		title: fieldData.label,
		required: {
			status: fieldData.isRequired,
			text: {
				default: languages.en.schemas.inputChoice.required
			}
		},
		errorText: {
			default: languages.en.schemas.inputChoice.error
		},
		sorting: {
			field: fieldData.displayFieldName,
			sort: 'asc'
		},
		filters: {
			groupOp: 'OR',
			rules: []
		},
		submitText: {
			default: languages.en.schemas.inputChoice.submit
		},
		cancel: {
			text: {
				default: languages.en.schemas.inputChoice.cancel
			}
		},
		placeholder: {
			default: languages.en.schemas.inputChoice.placeholder
		},
		emptyText: {
			default: languages.en.schemas.inputChoice.empty
		},
		spacing: 'xl',
		limit: 1000
	}

	return object
}

export default method

import React from 'react'
import ReactDOM from 'react-dom'
// import reportWebVitals from './reportWebVitals'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { LicenseInfo } from '@mui/x-license-pro'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import App from './App'
import './index.css'
import theme from './assets/theme/theme'
import store from './redux/store/store'
import './i18n/i18n'
import 'react-toastify/dist/ReactToastify.css'

import './icons/icons'

LicenseInfo.setLicenseKey(
	'ea5593f55e2284de6c33824b3d3e468bT1JERVI6NDIzNDcsRVhQSVJZPTE2ODIyMzgzNzIwMDAsS0VZVkVSU0lPTj0x'
)

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<App />
					<ToastContainer autoClose={2000} />
				</ThemeProvider>
			</StyledEngineProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()

import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { Typography, Button, CardMedia } from '@mui/material'
import cx from 'classnames'

import kozmikTheme from '../../../assets/theme/theme.json'

import KozmikModal from '../../molecules/kozmikModal/KozmikModal'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: '60px 40px',
		height: '100%',
		justifyContent: 'space-between',
		overflowY: 'auto',
		[theme.breakpoints.down('md')]: {
			padding: '40px 20px'
		}
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column'
	},
	footerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	btnContainer: {
		width: 220,
		display: 'flex',
		justifyContent: 'flex-end'
	},
	btnTxt: {
		color: '#FFFFFF',
		fontSize: 14,
		fontWeight: 'bold',
		textAlign: 'left',
		textTransform: 'none'
	},
	kozmikBlue: {
		color: theme.custom.colors.kozmikBlue
	},
	pager: {
		fontSize: 14,
		fontWeight: 'bold'
	},
	image: {
		width: '100%',
		objectFit: 'contain',
		marginBottom: 60,
		[theme.breakpoints.down('md')]: {
			marginBottom: 80
		}
	},
	title: {
		fontSize: 16,
		fontWeight: '600'
	},
	text: {
		fontSize: 14
	}
}))

const OnboardingModal = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [pageIndex, setPageIndex] = useState(0)

	const next = () => {
		setPageIndex((current) => current + 1)
	}
	const back = () => {
		setPageIndex((current) => current - 1)
	}

	useEffect(() => {
		setPageIndex(0)
	}, [props.pages])

	return (
		<KozmikModal
			maxHeight={props.builderOnMobile ? 550 : 660}
			visible={props.visible}
			hideModal={props.hideModal}
		>
			<div className={classes.mainContainer}>
				<div className={classes.contentContainer}>
					<CardMedia
						image={props.pages[pageIndex].image}
						component='img'
						className={classes.image}
					/>

					<span className={classes.title}>{props.pages[pageIndex].title}</span>

					<div
						className={classes.text}
						dangerouslySetInnerHTML={{ __html: props.pages[pageIndex].text }}
					/>
				</div>

				<div className={classes.footerContainer}>
					<span className={classes.pager}>
						{pageIndex + 1}/{props.pages.length}
					</span>
					<div className={classes.btnContainer}>
						{pageIndex > 0 && (
							<Button
								variant='contained'
								onClick={back}
								sx={{
									textTransform: 'none',
									backgroundColor: `#ffffff !important`,
									boxShadow: 'none !important',
									fontSize: 16,
									borderRadius: '4px',
									width: '48%',
									marginRight: '4%'
								}}
							>
								<Typography className={cx(classes.btnTxt, classes.kozmikBlue)}>
									{t('onboarding.back')}
								</Typography>
							</Button>
						)}
						{props.pages.length - 1 !== pageIndex ? (
							<Button
								variant='contained'
								onClick={next}
								sx={{
									textTransform: 'none',
									backgroundColor: `${kozmikTheme.colors.kozmikBlue} !important`,
									boxShadow: 'none !important',
									fontSize: 16,
									borderRadius: '4px',
									width: '48%'
								}}
							>
								<Typography className={classes.btnTxt}>
									{t('onboarding.next')}
								</Typography>
							</Button>
						) : (
							<Button
								variant='contained'
								onClick={() => {
									props.submit()
									props.hideModal()
								}}
								sx={{
									textTransform: 'none',
									backgroundColor: `${kozmikTheme.colors.kozmikBlue} !important`,
									boxShadow: 'none !important',
									fontSize: 16,
									borderRadius: '4px',
									width: '48%'
								}}
							>
								<Typography className={classes.btnTxt}>
									{t('onboarding.done')}
								</Typography>
							</Button>
						)}
					</div>
				</div>
			</div>
		</KozmikModal>
	)
}

export default OnboardingModal

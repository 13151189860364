import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateText from '../../../molecules/doubleStateField/DoubleStateText'
import DoubleStateMentions from '../../../molecules/doubleStateField/DoubleStateMentions'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import { default as Checkbox } from '../../../atoms/kozmikCheckBox/KozmikCheckBox'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import validateWithRegExp from '../../../../methods/validateWithRegExp'
import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import dataTypeRegExes from '../../../../common/regExp/dataType'
import relationTypes from '../../../../common/enums/relationTypes'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const PhoneButton = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const propsRef = useRef()
	propsRef.current = props

	const [elementName, setElementName] = useState(null)

	const phoneFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		['Phone'],
		null,
		true,
		relationTypes.one
	)
	const phoneOptions = phoneFields?.length ? phoneFields : []

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		fieldDataTypes.textBased,
		null,
		true,
		relationTypes.one
	)
	const textOptions = textFields?.length ? textFields : []
	const textOptionsWithEmpty = [{ label: '-', name: '' }, ...textOptions]

	/****PHONE***************************/
	const [isPhoneTextMode, setIsPhoneTextMode] = useState(false)
	const [phoneField, setPhoneField] = useState('')
	const [phoneDefault, setPhoneDefault] = useState('')
	const [phoneDefaultValid, setPhoneDefaultValid] = useState(true)

	const phoneChange = (data) => {
		if (isPhoneTextMode) {
			const phoneRegExp = dataTypeRegExes.Phone.re

			validateWithRegExp(data.target.value, phoneRegExp).then((isValid) => {
				setPhoneDefaultValid(isValid)
				if (isValid) {
					timeoutDelay(submitPhone, data.target.value, 1000)
				}
			})

			setPhoneDefault(data.target.value)
		} else {
			submitPhone(data?.value)
			setPhoneField(data?.value)
		}
	}
	const submitPhone = (x) => {
		const selectedField = phoneFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				address: {
					field: { $set: isPhoneTextMode ? null : x },
					default: { $set: isPhoneTextMode ? x : null },
					type: { $set: isPhoneTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****TITLE***************************/
	const [isTitleTextMode, setIsTitleTextMode] = useState(false)
	const [titleField, setTitleField] = useState('')
	const [titleDefault, setTitleDefault] = useState('')

	const titleChange = (data) => {
		if (isTitleTextMode) {
			submitTitle(data)
			setTitleDefault(data)
		} else {
			submitTitle(data?.value)
			setTitleField(data?.value)
		}
	}
	const submitTitle = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				title: {
					field: { $set: isTitleTextMode ? null : x },
					default: { $set: isTitleTextMode ? x : null },
					type: { $set: isTitleTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****BODY***************************/
	const [isBodyTextMode, setIsBodyTextMode] = useState(false)
	const [bodyField, setBodyField] = useState('')
	const [bodyDefault, setBodyDefault] = useState('')

	const bodyChange = (data) => {
		if (isBodyTextMode) {
			submitBody(data)
			setBodyDefault(data)
		} else {
			submitBody(data?.value)
			setBodyField(data?.value)
		}
	}
	const submitBody = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)

		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				body: {
					field: { $set: isBodyTextMode ? null : x },
					default: { $set: isBodyTextMode ? x : null },
					type: { $set: isBodyTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****SHOWCALL***************************/
	const [showCall, setShowCall] = useState(true)

	const showCallValChange = (e) => {
		const showCallVal = e.target.checked
		setShowCall(showCallVal)
		submitShowCall(showCallVal)
	}

	const submitShowCall = (x) => {
		const modifiedElementData = update(props.elementData, {
			showCall: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****SHOWCHAT***************************/
	const [showChat, setShowChat] = useState(true)

	const showChatValChange = (e) => {
		const showChatVal = e.target.checked
		setShowChat(showChatVal)
		submitShowChat(showChatVal)
	}

	const submitShowChat = (x) => {
		const modifiedElementData = update(props.elementData, {
			showChat: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		elementData?.mapping?.address?.field ||
		elementData?.mapping?.address?.field === ''
			? setIsPhoneTextMode(false)
			: setIsPhoneTextMode(true)

		setPhoneField(elementData?.mapping?.address?.field || '')
		setPhoneDefault(elementData?.mapping?.address?.default || '')
		/****TITLE***************************/
		elementData?.mapping?.title?.field ||
		elementData?.mapping?.title?.field === ''
			? setIsTitleTextMode(false)
			: setIsTitleTextMode(true)

		setTitleField(elementData?.mapping?.title?.field || '')
		setTitleDefault(elementData?.mapping?.title?.default || '')
		/****BODY***************************/
		elementData?.mapping?.body?.field ||
		elementData?.mapping?.body?.field === ''
			? setIsBodyTextMode(false)
			: setIsBodyTextMode(true)

		setBodyField(elementData?.mapping?.body?.field || '')
		setBodyDefault(elementData?.mapping?.body?.default || '')

		setShowChat(elementData?.showChat)
		setShowCall(elementData?.showCall)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		props?.elementData && initStates(props.elementData)
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.phoneButton.phone')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateText
								fullWidth={true}
								textError={!phoneDefaultValid}
								isTextMode={isPhoneTextMode}
								setIsTextMode={setIsPhoneTextMode}
								valChange={phoneChange}
								textVal={phoneDefault}
								autoCompVal={phoneField}
								autoCompOptions={phoneOptions}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.phoneButton.title')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateMentions
								name={`${elementName}-title`}
								multiline={false}
								fullWidth={true}
								isTextMode={isTitleTextMode}
								setIsTextMode={setIsTitleTextMode}
								valChange={titleChange}
								textVal={titleDefault}
								autoCompVal={titleField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.phoneButton.body')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateMentions
								name={`${elementName}-body`}
								multiline={false}
								fullWidth={true}
								isTextMode={isBodyTextMode}
								setIsTextMode={setIsBodyTextMode}
								valChange={bodyChange}
								textVal={bodyDefault}
								autoCompVal={bodyField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
					<div className={cx(classes.item, classes.mb10)}>
						<div>
							<Checkbox checked={showCall} onChange={showCallValChange} />
						</div>
						<div className={cx(classes.labelBox, classes.growBox)}>
							<Label>
								{t('screens.elementSettings.phoneButton.callPermission')}
							</Label>
						</div>
					</div>
					<div className={cx(classes.item, classes.mb10)}>
						<div>
							<Checkbox checked={showChat} onChange={showChatValChange} />
						</div>
						<div className={cx(classes.labelBox, classes.growBox)}>
							<Label>
								{t('screens.elementSettings.phoneButton.smsPermission')}
							</Label>
						</div>
					</div>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default PhoneButton

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Add } from '@mui/icons-material'
import { connect } from 'react-redux'

import NewAppModal from '../../organisms/newAppModal/NewAppModal'
import AppsTemplate from '../../templates/appsTemplate/AppsTemplate'
import SearchHeader from '../../templates/searchHeader/SearchHeader'
import appClient from '../../../services/appClient'

import showToastResponseError from '../../../methods/showToastResponseError'
import { setApps } from '../../../redux/actions/applicationsActions'

const Apps = (props) => {
	const { t } = useTranslation()

	const selectData = [
		{
			text: t('applications.sortOldest'),
			value: 'Created asc'
		},
		{
			text: t('applications.sortNewest'),
			value: 'Created desc'
		},
		{
			text: t('applications.sortAlphabetical'),
			value: 'Name asc'
		}
	]

	const [appData, setAppData] = useState(null)
	const [newModalOpen, setNewModalOpen] = useState(false)

	const getAppData = (searchTerm = null, filterValue = 'Created desc') => {
		appClient
			.search({
				term: searchTerm,
				sort: filterValue
			})
			.then((response) => {
				if (response?.data?.success) {
					setAppData(response.data.data)
					if (searchTerm == null) {
						props.setApps(response.data.data)
					}
				} else {
					showToastResponseError(response)
				}
			})
	}

	const searchAndFilter = (searchTerm = null, filterValue = null) => {
		if (searchTerm != null && filterValue != null) {
			getAppData(searchTerm, filterValue)
		} else if (searchTerm != null) {
			getAppData(searchTerm)
		} else if (filterValue != null) {
			getAppData(null, filterValue)
		}
	}

	const reload = () => {
		getAppData()
	}

	const newClick = () => {
		setNewModalOpen(true)
	}

	const fixData = [
		{
			Name: t('applications.newApp'),
			Icon: Add,
			AccentColor: 'blue',
			cardColor: 'rgba(240, 240, 244,0.5)',
			click: newClick
			// linkUrl: 'https://templates.kozmik.io'
		}
	]

	useEffect(() => {
		document.title = t('docTitles.applications')
	}, [])

	useEffect(() => {
		!!props?.currentOrg?.id && getAppData()
	}, [props.currentOrg])

	return (
		<>
			<SearchHeader
				title={t('applications.apps')}
				placeholders={{
					search: t('applications.search'),
					select: t('applications.sort')
				}}
				selectData={selectData}
				searchAndFilter={searchAndFilter}
			/>

			{
				<AppsTemplate
					fixData={fixData}
					data={appData}
					linkUrl='app/:appId/screens'
					deleteUrl='/delete'
					copyUrl='/copy'
					reload={reload}
					isInteractive={true}
				/>
				//TODO:SkeletonScreen || Loader
			}

			<NewAppModal
				open={newModalOpen}
				setOpen={setNewModalOpen}
				apps={appData}
			/>
		</>
	)
}

const mapStateToProps = (state) => ({
	...state.userInfo
})

const mapDispatchToProps = (dispatch) => {
	return {
		setApps: (x) => dispatch(setApps(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Apps)

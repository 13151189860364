import { SETAUTH } from '../action-types/authActionTypes'

const initialState = {
	auth: false
}

const authReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case SETAUTH:
			return { ...state, auth: action.payload }

		default:
			return state
	}
}

export default authReducer

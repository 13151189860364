import React, { useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Popover, Grid, Typography, Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import { default as kozmikTheme } from '../../../assets/theme/theme.json'

import PopoverItem from '../../molecules/popoverItem/PopoverItem'

import TemplateSubmit from './modals/TemplateSubmit'

import appClient from '../../../services/appClient'
import storeSettingClient from '../../../services/storeSettingClient'
import { setDetail } from '../../../redux/actions/appDataActions'
import showToastResponseError from '../../../methods/showToastResponseError'

const useStyles = makeStyles((theme) => ({
	popoverBox: {
		width: 225
	},
	box: {
		padding: '8px 16px',
		width: '100%'
	},
	spaceBetween: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	text: {
		fontSize: 14
	},
	textSm: {
		fontSize: 12
	},
	badge: {
		fontSize: 9,
		fontWeight: 'bold',
		borderRadius: 7.5,
		padding: '2px 8px',
		backgroundImage:
			'linear-gradient(90deg, #96f1ce 50%, rgba(27,54,254,0.3) 100%)'
	}
}))

const PublishModule = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	let { appId } = useParams()

	const [visibleModalNo, setVisibleModalNo] = useState(null)

	const handleClickOpen = (modalNo) => {
		props.onClose()
		setVisibleModalNo(modalNo)
	}
	const handleClose = () => {
		setVisibleModalNo(null)
	}

	return (
		<>
			<Popover
				id={'publish-popover'}
				open={props.open}
				anchorEl={props.anchorEl}
				onClose={props.onClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<Grid container className={classes.popoverBox}>
					<Grid
						item
						xs={12}
						style={{ borderTop: `2px solid ${kozmikTheme.colors.gray}` }}
						className={classes.box}
					>
						<PopoverItem onClick={() => handleClickOpen(4)}>
							<Typography className={classes.textSm}>
								{t('publishModule.submitTemplate')}
							</Typography>
						</PopoverItem>
					</Grid>

					{/* {(props?.userInfo?.email?.includes('@kozmik.io') ||
						props?.userInfo?.email?.includes('@logicalbond.com')) && (
						<Grid
							item
							xs={12}
							style={{ borderTop: `2px solid ${kozmikTheme.colors.gray}` }}
							className={classes.box}
						>
							<PopoverItem onClick={() => handleClickOpen(4)}>
								<Typography className={classes.textSm}>
									{t('publishModule.submitTemplate')}
								</Typography>
							</PopoverItem>
						</Grid>
					)} */}
				</Grid>
			</Popover>

			<TemplateSubmit visible={visibleModalNo === 4} hideModal={handleClose} />
		</>
	)
}

const mapStateToProps = (state) => ({
	...state.appData,
	...state.userInfo,
	...state.currentOrg
})

const mapDispatchToProps = (dispatch) => {
	return {
		setDetail: (x) => dispatch(setDetail(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishModule)

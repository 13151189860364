import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Close } from '@mui/icons-material'

import kozmikTheme from '../../../assets/theme/theme.json'

const useStyles = makeStyles((theme) => ({
	tagsContainer: {
		backgroundColor: theme.custom.colors.kozmikGray,
		border: '2px solid transparent',
		padding: '5px 14px',
		borderRadius: 4,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		minHeight: 40,
		maxHeight: 150,
		overflowY: 'auto'
	},
	tagItem: {
		backgroundColor: theme.custom.colors.kozmikLightBlue,
		padding: '2px 7px',
		borderRadius: 20,
		display: 'flex',
		alignItems: 'center',
		margin: 3
	},
	tagClose: {
		height: 16,
		width: 16,
		color: theme.custom.colors.kozmikBlue,
		marginLeft: 5,
		cursor: 'pointer'
	},
	tagsInput: {
		backgroundColor: theme.custom.colors.kozmikGray,
		flexGrow: 1,
		padding: '5px 0',
		border: 'none',
		outline: 'none'
	},
	text: {
		fontSize: 14,
		color: '#000000',
		wordBreak: 'break-all'
	}
}))

const TagInput = (props) => {
	const classes = useStyles()

	const handleKeyDown = (e) => {
		if (e.key !== 'Enter') return
		processTag(e)
	}

	const processTag = (e) => {
		const value = e.target.value
		if (!value.trim()) return
		if (!props?.error) {
			props.setTags([...props.tags, value])
			e.target.value = ''
		}
	}

	const removeTag = (index) => {
		props.setTags(props.tags.filter((el, i) => i !== index))
	}

	return (
		<div
			className={classes.tagsContainer}
			style={props?.error ? { borderColor: kozmikTheme.colors.red } : null}
		>
			{props.tags?.map((tag, index) => (
				<div className={classes.tagItem} key={index}>
					<span className={classes.text}>{tag}</span>
					<Close
						className={classes.tagClose}
						onClick={() => removeTag(index)}
					/>
				</div>
			))}
			<input
				onBlur={processTag}
				onKeyDown={handleKeyDown}
				onChange={props?.onInputChange}
				type='text'
				className={classes.tagsInput}
				placeholder={props?.placeholder || ''}
				autoFocus
			/>
		</div>
	)
}

export default TagInput

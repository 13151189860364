import { v4 as uuidv4 } from 'uuid'

import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../common/fieldDataTypes'
import relationTypes from '../../common/enums/relationTypes'
import formulas from '../../common/formulas'

const method = (table) => {
	const txtFields = getEntityFieldsByDataTypes(
		null,
		null,
		fieldDataTypes.textBased,
		table,
		true,
		relationTypes.one
	)

	const titleField =
		txtFields?.find((x) => x.name == 'Name') ?? formulas.profile[1]

	let object = {
		_uid: uuidv4(),
		component: 'settingsButton',
		fontSize: 'sm',
		height: 48,
		spacing: 'xl',
		corner: 'circle', //'circle','oval','square'
		mapping: {
			title: {
				field: titleField?.name,
				type: titleField?.dataType,
				default: null
			}
		},
		icon: 'alert-circle',
		actions: [{ type: null }]
	}

	return object
}

export default method

import { v4 as uuidv4 } from 'uuid'

import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../common/fieldDataTypes'
import getArrayExcludingForbidArray from '../../methods/getArrayExcludingForbidArray'
import i18next from '../../i18n/i18n'
import relationTypes from '../../common/enums/relationTypes'
import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'

const method = (table) => {
	const geoLocationFields = getEntityFieldsByDataTypes(
		null,
		null,
		['GeoLocation'],
		table,
		true,
		relationTypes.one
	)
	const txtFields = getEntityFieldsByDataTypes(
		null,
		null,
		fieldDataTypes.textBased,
		table,
		true,
		relationTypes.one
	)
	const imgFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Image'],
		table,
		true,
		relationTypes.one
	)

	const filteredTxtFields = getArrayExcludingForbidArray(
		txtFields,
		'name',
		systemFieldNames.all
	)

	const titleField = txtFields?.find((x) => x.name == 'Name')
	const firstImgField = imgFields?.length ? imgFields[0] : null
	const firstGeoLocationField = geoLocationFields?.length
		? geoLocationFields[0]
		: null

	let object = {
		_uid: uuidv4(),
		component: 'map',
		mapping: {
			title: {
				field: titleField?.name,
				type: titleField?.dataType,
				default: null
			},
			text: {
				field: filteredTxtFields?.[0]?.name,
				type: filteredTxtFields?.[0]?.dataType,
				default: null
			},
			subText: {
				field: filteredTxtFields?.[1]?.name,
				type: filteredTxtFields?.[1]?.dataType,
				default: null
			},
			markerText: {
				field: filteredTxtFields?.[1]?.name,
				type: filteredTxtFields?.[1]?.dataType,
				default: null
			},
			imgUrl: {
				field: firstImgField?.name,
				type: firstImgField?.dataType,
				default: null
			},
			geoLocation: {
				field: firstGeoLocationField?.name,
				type: firstGeoLocationField?.name
					? firstGeoLocationField?.dataType
					: null,
				default: firstGeoLocationField?.name
					? null
					: i18next.t('schemas.map.geoLocation')
			}
		},
		markerIcon: 'location',
		zoom: '2x',
		appearance: 'rectangle',
		mapType: 'roadmap', // roadmap, satellite, hybrid
		scrollwheel: true,
		zoomControl: true
	}

	return object
}

export default method

import React, { useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import {
	PeopleAlt,
	Close,
	Lightbulb,
	ConnectingAirportsOutlined
} from '@mui/icons-material'
import Cookies from 'universal-cookie'
import { useTranslation } from 'react-i18next'

import KozmikModal from '../../molecules/kozmikModal/KozmikModal'

import KozmikProgressBar from '../../atoms/kozmikProgressBar/KozmikProgressBar'
import OnboardingListItem from '../../atoms/onboardingListItem/OnboardingListItem'
import OnboardingModal from './OnboardingModal'

import kozmikTheme from '../../../assets/theme/theme.json'

const useStyles = makeStyles((theme) => ({
	button: {
		height: 35,
		position: 'absolute',
		right: '26%',
		bottom: 25,
		borderRadius: 8,
		backgroundColor: theme.custom.colors.kozmikDarkBlue,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '0 12px',
		cursor: 'pointer',
		[theme.breakpoints.down('md')]: {
			display: 'none'
		}
	},
	buttonTxt: {
		fontSize: 14,
		color: '#ffffff',
		paddingLeft: 6
	},
	icon: {
		fontSize: '18px',
		color: '#ffffff'
	},
	mainContainer: {
		width: 300,
		borderRadius: 16,
		backgroundColor: theme.custom.colors.gray,
		padding: '21px 14px',
		position: 'absolute',
		right: '23%',
		bottom: 70,
		boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.1)',
		zIndex: 11,
		[theme.breakpoints.down('md')]: {
			display: 'none'
		}
	},
	mainContainerMobile: {
		padding: '20px 15px',
		backgroundColor: theme.custom.colors.gray,
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	},
	closeIcon: {
		display: 'block',
		fontSize: '16px',
		color: theme.custom.colors.textPrimary,
		marginLeft: 'auto',
		cursor: 'pointer'
	},
	headContainer: {
		padding: '0 10px'
	},
	title: {
		fontSize: 14,
		fontWeight: 'bold',
		display: 'inline-block'
	},
	subTitle: {
		fontSize: 12,
		display: 'inline-block'
	},
	progressContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	progress: {
		margin: '15px 0',
		flexGrow: 1
	},
	progressTxt: {
		fontSize: 10,
		fontWeight: 'bold',
		paddingLeft: 11
	}
}))

const cookieVersion = '0.0.2' //increment version for data changes

const Onboarding = (props) => {
	const { t } = useTranslation()
	const cookies = new Cookies()
	const classes = useStyles()

	const localizedData = t('onboarding.data', { returnObjects: true })

	const [cookieData, setCookieData] = useState(null)

	const [isModalVisible, setIsModalVisible] = useState(false)
	const [modalCookieIndex, setModalCookieIndex] = useState(0)

	const setOnboardingCookie = (data) => {
		cookies.set('onboard', data, {
			path: '/',
			sameSite: 'none',
			secure: true,
			domain: process.env.REACT_APP_COOKIE_DOMAIN
			// httpOnly: true
		})
		setCookieData(data)
	}

	const getCookie = () => {
		const cData = cookies.get('onboard')
		if (cData?.v === cookieVersion) {
			setCookieData(cData)
			!cData.closed && props.setIsOnboardingVisible(true)
		} else {
			const newcData = {
				v: cookieVersion,
				closed: false,
				progress: Array(localizedData.length).fill(0)
			}

			setOnboardingCookie(newcData)
			props.setIsOnboardingVisible(true)
		}
	}

	const completedCount = cookieData?.progress?.reduce((a, b) => a + b, 0)
	const itemCount = cookieData?.progress?.length

	const openModalWithData = (cookieIndex) => {
		setModalCookieIndex(cookieIndex)
		props.builderOnMobile && props.setIsOnboardingVisible(false)
		setIsModalVisible(true)
	}

	const toggleList = () => {
		if (props.isOnboardingVisible) {
			closeList()
		} else {
			props.setIsOnboardingVisible(true)
		}
	}

	const closeList = () => {
		props.setIsOnboardingVisible(false)

		let newCookieData = { ...cookieData }

		if (!newCookieData.closed) {
			newCookieData.closed = true
			setOnboardingCookie(newCookieData)
		}
	}

	const submitCookie = (index) => {
		let newCookieData = { ...cookieData }
		newCookieData.progress[index] = 1

		setOnboardingCookie(newCookieData)
	}

	const renderOnboarding = () => {
		return (
			<div
				className={
					props.builderOnMobile
						? classes.mainContainerMobile
						: classes.mainContainer
				}
			>
				{!props.builderOnMobile && (
					<Close className={classes.closeIcon} onClick={closeList} />
				)}
				<div className={classes.headContainer}>
					<span className={classes.title}>{t('onboarding.title')}</span>
					<span className={classes.subTitle}>{t('onboarding.subTitle')}</span>
					<div className={classes.progressContainer}>
						<KozmikProgressBar
							barColor={'#ffffff'}
							progressColor={kozmikTheme.colors.kozmikGreen}
							height={6}
							variant='determinate'
							value={(100 / itemCount) * completedCount}
							className={classes.progress}
						/>
						{!!cookieData && (
							<span className={classes.progressTxt}>
								{completedCount}/{itemCount}
							</span>
						)}
					</div>
				</div>
				{!!cookieData && (
					<div>
						{localizedData?.map((item, index) => (
							<OnboardingListItem
								key={index}
								completed={!!cookieData.progress[index]}
								text={item.title}
								onClick={() => openModalWithData(index)}
							/>
						))}
					</div>
				)}
			</div>
		)
	}

	useEffect(() => {
		getCookie()
	}, [])

	return (
		<>
			<div className={classes.button} onClick={toggleList}>
				<Lightbulb className={classes.icon} />
				<span className={classes.buttonTxt}>{t('onboarding.btnText')}</span>
			</div>

			{!props.builderOnMobile &&
				props.isOnboardingVisible &&
				renderOnboarding()}

			{
				<KozmikModal
					flexHeight
					visible={props.builderOnMobile && props.isOnboardingVisible}
					hideModal={closeList}
				>
					{renderOnboarding()}
				</KozmikModal>
			}

			<OnboardingModal
				builderOnMobile={props.builderOnMobile}
				visible={isModalVisible}
				hideModal={() => {
					setIsModalVisible(false)
					props.builderOnMobile && props.setIsOnboardingVisible(true)
				}}
				pages={localizedData?.[modalCookieIndex]?.pages}
				submit={() => submitCookie(modalCookieIndex)}
			/>
		</>
	)
}

export default Onboarding

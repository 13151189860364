import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateMentions from '../../../molecules/doubleStateField/DoubleStateMentions'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import ActionList from '../../actionList/ActionList'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import relationTypes from '../../../../common/enums/relationTypes'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	}
}))

const QRCode = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const propsRef = useRef()
	propsRef.current = props

	const [elementName, setElementName] = useState(null)

	const textFieldsWithLookupAndRel = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		fieldDataTypes.textBased,
		null,
		true,
		relationTypes.one
	)
	const textOptions = textFieldsWithLookupAndRel?.length
		? textFieldsWithLookupAndRel
		: []
	const textOptionsWithEmpty = [{ label: '-', name: '' }, ...textOptions]

	/****TEXT***************************/
	const [isTextTextMode, setIsTextTextMode] = useState(false)
	const [textField, setTextField] = useState('')
	const [textDefault, setTextDefault] = useState('')

	const textChange = (data) => {
		if (isTextTextMode) {
			submitText(data)
			setTextDefault(data)
		} else {
			submitText(data?.value)
			setTextField(data?.value)
		}
	}

	const submitText = (x) => {
		const selectedField = textFieldsWithLookupAndRel?.find((y) => y.name == x)
		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				text: {
					field: { $set: isTextTextMode ? null : x },
					default: { $set: isTextTextMode ? x : null },
					type: { $set: isTextTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****ACTIONS***************************/
	const updateActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			actions: { $set: newActions }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****TEXT***************************/
		elementData?.mapping?.text?.field ||
		elementData?.mapping?.text?.field === ''
			? setIsTextTextMode(false)
			: setIsTextTextMode(true)

		setTextField(elementData?.mapping?.text?.field || '')
		setTextDefault(elementData?.mapping?.text?.default || '')

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		props?.elementData && initStates(props.elementData)
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.settings')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.common.labels.text')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateMentions
								name={`${elementName}`}
								multiline={false}
								fullWidth={true}
								isTextMode={isTextTextMode}
								setIsTextMode={setIsTextTextMode}
								valChange={textChange}
								textVal={textDefault}
								autoCompVal={textField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.actions')}
					expanded={true}
				>
					<ActionList
						name={elementName}
						entities={props.entities}
						activeScreen={props.activeScreen}
						actions={props.elementData.actions}
						updateActions={updateActions}
					/>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default QRCode

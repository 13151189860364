const textBased = [
	'RowId',
	'Text',
	'LongText',
	// 'AutoNumber',
	'Number',
	'Date',
	'Email',
	'Phone',
	'URL',
	'GeoLocation',
	'Formula',
	'Rollup'
]

export default textBased

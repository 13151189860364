import { v4 as uuidv4 } from 'uuid'

// import i18next from '../../i18n/i18n'
import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import defaultElementSchemas from '..'
import fieldDataTypes from '../../common/fieldDataTypes'
import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'
import languages from '../../i18n/languages'

import filterModal from './filterModal'

const method = (table, searcheable = false, actions = null) => {
	let acceptedFields = getEntityFieldsByDataTypes(
		null,
		null,
		fieldDataTypes.all,
		table
	)

	acceptedFields = acceptedFields?.filter(
		(x) =>
			!systemFieldNames.notVisible.includes(x.name) &&
			x.dataType != 'Lookup' &&
			x.dataType != 'Rollup' &&
			x.dataType != 'Formula'
	)

	let object = {
		_uid: uuidv4(),
		component: 'table',
		title: '',
		source: table?.name,
		columns: [],
		sorting: {
			field: 'Created',
			sort: 'desc'
		},
		filters: {
			groupOp: 'AND',
			rules: []
		},
		searcheable: searcheable,
		searchPlaceholder: {
			default: languages.en.schemas.list.searchPlaceholder
		},
		filterModal: filterModal(),
		corner: 'oval',
		fontSize: 'md',
		actions: actions ?? [
			{
				type: null
			}
		],
		limit: 100,
		pageSize: 25,
		spacing: 'xl',
		emptyState: {
			default: languages.en.schemas.list.emptyState
		}
	}

	acceptedFields?.slice(0, 10)?.map((field) => {
		const column = {
			title: {
				default: field?.label
			},
			field: field?.name,
			type: field?.dataType
		}

		column && object.columns.push(column)
	})

	return object
}

export default method

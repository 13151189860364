import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'

import KozmikProgressBar from '../../atoms/kozmikProgressBar/KozmikProgressBar'
import kozmikTheme from '../../../assets/theme/theme.json'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		width: '100%',
		borderRadius: 4,
		backgroundColor: theme.custom.colors.gray,
		paddingBottom: 16,
		display: 'flex',
		flexWrap: 'wrap'
	},
	itemContainer: {
		width: '25%',
		padding: 16,
		[theme.breakpoints.down('sm')]: {
			width: '50%'
		}
	},
	text: {
		fontSize: 12,
		fontWeight: '600'
	},
	progress: {
		margin: '8px 0',
		width: '80%',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	}
}))

const calculatePercentage = (limit, value) => {
	const calculation = (value * 100) / limit
	const result =
		calculation === Infinity || isNaN(calculation) ? 100 : calculation

	return result > 100 ? 100 : result
}

const bytesToSize = (bytes) => {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
	if (bytes === 0) return '0'

	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)

	if (i === 0) return `${bytes} ${sizes[i]})`

	return `${(bytes / 1024 ** i).toFixed(0)} ${sizes[i]}`
}

const UsageTable = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const culture = localStorage.getItem('i18nextLng')
	// const languageCode = culture === 'tr-TR' ? 'tr' : 'en'
	const languageCode = 'en'

	const limitText = t('subscriptionTemp.usageTable.limit')

	const usageData = props?.data
		? [
				{
					title: t('subscriptionTemp.usageTable.apps'),
					percent: calculatePercentage(
						props.data.LimitApps,
						props.data.UsageApps
					),
					text: `${props?.data.UsageApps} / ${props.data.LimitApps}`
				},
				{
					title: t('subscriptionTemp.usageTable.users'),
					percent: calculatePercentage(
						props.data.LimitUsers,
						props.data.UsageUsers
					),
					text: `${props.data.UsageUsers.toLocaleString(
						languageCode
					)} / ${props.data.LimitUsers.toLocaleString(languageCode)}`
				},
				{
					title: t('subscriptionTemp.usageTable.dataStorage'),
					percent: calculatePercentage(
						props.data.LimitDataStorage,
						props.data.UsageDataStorage
					),
					text: `${bytesToSize(
						props.data.UsageDataStorage * (1024 * 1024)
					)} / ${bytesToSize(props.data.LimitDataStorage * (1024 * 1024))}`
				},
				{
					title: t('subscriptionTemp.usageTable.storage'),
					percent: calculatePercentage(
						props.data.LimitFileStorage,
						props.data.UsageFileStorage
					),
					text: `${bytesToSize(
						props.data.UsageFileStorage * (1024 * 1024)
					)} / ${bytesToSize(props.data.LimitFileStorage * (1024 * 1024))}`
				}
		  ]
		: []

	return (
		<div className={classes.mainContainer}>
			{usageData?.map((item, index) => (
				<div key={index} className={classes.itemContainer}>
					<div className={classes.text}>{item.title}</div>
					<KozmikProgressBar
						barColor={'#ffffff'}
						progressColor={
							item.percent >= 80
								? kozmikTheme.colors.darkRed
								: kozmikTheme.colors.kozmikDarkBlue
						}
						height={6}
						variant='determinate'
						value={item.percent}
						className={classes.progress}
					/>
					<div className={classes.text}>{item.text}</div>
				</div>
			))}
		</div>
	)
}

export default UsageTable

import React, { useEffect, useState } from 'react'
import { CardMedia, Grid, Typography, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import cx from 'classnames'

import KozmikTextField from '../../../atoms/kozmikTextField/KozmikTextField'
import KozmikFilePicker from '../../../atoms/kozmikFilePicker/KozmikFilePicker'

import timeoutDelay from '../../../../methods/timeoutDelay'

import appClient from '../../../../services/appClient'
import storeSettingClient from '../../../../services/storeSettingClient'
import showToastResponseError from '../../../../methods/showToastResponseError'

import { setDetail } from '../../../../redux/actions/appDataActions'

const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%',
		paddingTop: 20,
		paddingBottom: 20
	},
	contentTitle: {
		marginBottom: 10,
		fontSize: 16,
		fontWeight: '600'
	},
	contentDesc: {
		marginBottom: 15,
		fontSize: 14
	},
	subTitle: {
		fontSize: 12,
		fontWeight: '600'
	},
	input: {
		fontSize: 14,
		backgroundColor: theme.custom.colors.kozmikGray,
		borderRadius: 6,
		border: 'none',
		'& .MuiOutlinedInput-root': {
			padding: 0
		},
		'& .MuiOutlinedInput-adornedStart': {
			paddingLeft: 0
		},
		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: 0
		}
	}
}))

const PlayStore = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	let { appId } = useParams()

	const chooseText = t('publishModule.androidModal.chooseFile')

	const [setting, setSetting] = useState(null)

	/****PACKAGENAME***************************/
	const [packageName, setPackageName] = useState('')
	const changePackageName = (e) => {
		setPackageName(e.target.value)

		timeoutDelay(
			updateSetting,
			{
				AndroidPackageName: e.target.value
			},
			1000
		)
	}

	/****SERVICES_KEY_FILE***************************/
	const [servicesFile, setServicesFile] = useState(null)
	const [servicesFileName, setServicesFileName] = useState(chooseText)
	const servicesFileOnChange = (fileObject) => {
		setServicesFileName(fileObject?.name)
		setServicesFile(fileObject?.base64File)

		props.updateSetting({
			FirebaseGoogleServiceFile: fileObject?.base64File
		})
	}

	/****ACCOUNT_KEY_FILE***************************/
	const [accountFile, setAccountFile] = useState(null)
	const [accountFileName, setAccountFileName] = useState(chooseText)
	const accountFileOnChange = (fileObject) => {
		setAccountFileName(fileObject?.name)
		setAccountFile(fileObject?.base64File)

		props.updateSetting({
			AndroidGoogleAccountFile: fileObject?.base64File
		})
	}

	const getSetting = () => {
		storeSettingClient.getSetting(appId).then((response) => {
			if (response?.data?.success) {
				setSetting(response?.data?.data)
			} else {
				showToastResponseError(response)
			}
		})
	}

	const updateSetting = (data) => {
		storeSettingClient
			.updateSetting(appId, data)
			.then((response) => {
				if (response?.data?.success) {
					setSetting({ ...setting, ...data })
				} else {
					setSetting(setting)
					showToastResponseError(response)
				}
			})
			.catch((error) => {
				setSetting(setting)
			})
	}

	const initializationSetting = (setting) => {
		/****FRONTEND_FILE***************************/
		setServicesFile(setting?.FirebaseGoogleServiceFile || null)
		setServicesFileName(
			setting?.FirebaseGoogleServiceFile
				? t('publishModule.androidModal.servicesFile')
				: chooseText
		)
		/****ACCOUNT_KEY_FILE***************************/
		setAccountFile(setting?.AndroidGoogleAccountFile || null)
		setAccountFileName(
			setting?.AndroidGoogleAccountFile
				? t('publishModule.androidModal.accountFile')
				: chooseText
		)
		/****PACKAGE_NAME***************************/
		setPackageName(setting?.AndroidPackageName || '')
	}

	useEffect(() => {
		setting && initializationSetting(setting)
	}, [setting])

	useEffect(() => {
		getSetting()
	}, [])

	return (
		<div className={classes.container}>
			<Typography className={classes.contentTitle}>
				{t('publishModule.androidModal.credentialsTitle')}
			</Typography>
			<Typography className={classes.contentDesc}>
				{t('publishModule.androidModal.credentialsDesc')}
			</Typography>

			<Box style={{ marginBottom: 10 }}>
				<Typography className={classes.subTitle}>
					{t('publishModule.androidModal.packageName')}
				</Typography>
				<KozmikTextField
					className={classes.input}
					fullWidth
					placeholder={t('publishModule.androidModal.packageNamePlaceholder')}
					style={{ backgroundColor: '#FAFAFC', overflow: 'hidden' }}
					value={packageName}
					onChange={changePackageName}
				/>
			</Box>

			<Box style={{ marginBottom: 30 }}>
				<Typography className={classes.subTitle}>
					{t('publishModule.androidModal.accountKeyFile')}
				</Typography>
				<KozmikFilePicker
					onChange={accountFileOnChange}
					fileName={accountFileName}
					accept='.json'
				/>
			</Box>

			<Box style={{ marginBottom: 10 }}>
				<Typography className={classes.contentTitle}>
					{t('publishModule.androidModal.pushCredentialsTitle')}
				</Typography>
				<Typography className={classes.contentDesc}>
					{t('publishModule.androidModal.pushCredentialsDesc')}
				</Typography>
			</Box>

			<Box style={{ marginBottom: 10 }}>
				<Typography className={classes.subTitle}>
					{t('publishModule.androidModal.servicesKeyFile')}
				</Typography>
				<KozmikFilePicker
					onChange={servicesFileOnChange}
					fileName={servicesFileName}
					accept='.json'
				/>
			</Box>
		</div>
	)
}

const mapStateToProps = (state) => ({
	...state.appData
})

const mapDispatchToProps = (dispatch) => {
	return {
		setDetail: (x) => dispatch(setDetail(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayStore)

import React from 'react'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import cx from 'classnames'

import { useTranslation } from 'react-i18next'

import systemFieldNames from '../../../common/systemFieldNames/systemFieldNames'

const useStyles = makeStyles((theme) => ({
	footerContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: 10,
		borderTop: `1px solid ${theme.custom.colors.gray}`
	},
	text: {
		fontSize: '14px'
	}
}))

function DataTableFooter(props) {
	const { t } = useTranslation()
	const classes = useStyles()

	const { totalRecords } = props

	return (
		<Box className={classes.footerContainer}>
			<Typography className={cx(classes.text)}>
				{t('appData.totalRows')} {totalRecords}
			</Typography>
		</Box>
	)
}

export default DataTableFooter

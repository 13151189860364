// import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
// import fieldDataTypes from '../../common/fieldDataTypes'
// import i18next from '../../i18n/i18n'
import languages from '../../i18n/languages'
// import relationTypes from '../../common/enums/relationTypes'
// import getFirstFieldExcludingForbidArray from '../../methods/getFirstFieldExcludingForbidArray'
// import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'

const method = (
	leftButtons = [],
	rightButtons = [],
	title = '',
	table = null
) => {
	// let firstAcceptedField = null

	// if (table) {
	// 	const acceptedFields = getEntityFieldsByDataTypes(
	// 		null,
	// 		null,
	// 		fieldDataTypes.textBased,
	// 		table,
	// 		true,
	// 		relationTypes.one
	// 	)

	// 	firstAcceptedField = getFirstFieldExcludingForbidArray(
	// 		acceptedFields,
	// 		'name',
	// 		systemFieldNames.notVisible
	// 	)
	// }

	const object = {
		visible: true,
		mapping: {
			// title: {
			// 	field: pageHasSource ? firstAcceptedField?.name : null,
			// 	default: pageHasSource ? null : title,
			// 	type: pageHasSource ? firstAcceptedField?.dataType : null
			// }
			title: {
				field: table ? 'Name' : null,
				default: table ? null : title,
				type: table ? 'Text' : null
			}
		},
		showLogo: false,
		buttons: {
			left: leftButtons,
			right: rightButtons
		}
	}

	return object
}

export default method

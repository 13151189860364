import React from 'react'
import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import cx from 'classnames'
import { connect } from 'react-redux'

import { default as kozmikTheme } from '../../../assets/theme/theme.json'

import { default as appConfig } from '../../../config/app.json'

const paymentDomain = appConfig.domains.payment

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		backgroundColor: '#ffffff',
		width: '100%',
		height: '100%',
		padding: 20,
		border: `1px solid transparent`,
		borderRadius: 16,
		overflow: 'hidden'
	},
	button: {
		fontSize: 16,
		height: 40,
		textTransform: 'none',
		fontWeight: '600',
		borderRadius: 8,
		boxShadow: 'none !important',
		backgroundColor: theme.custom.colors.gray,
		color: theme.custom.colors.kozmikDarkBlue,
		'&:hover': {
			backgroundColor: theme.custom.colors.gray
		}
	},
	highlightedButton: {
		borderColor: `${theme.custom.colors.kozmikGreen} !important`,
		backgroundColor: `${theme.custom.colors.kozmikGreen} !important`,
		color: `${theme.custom.colors.kozmikDarkBlue} !important`
	},
	currentButton: {
		borderColor: `${theme.custom.colors.gray} !important`,
		backgroundColor: theme.custom.colors.gray,
		color: `${theme.custom.colors.kozmikDarkBlue} !important`
	},
	title: {
		fontSize: 21,
		fontWeight: '600',
		color: theme.custom.colors.kozmikDarkBlue,
		margin: 0
	},
	priceBox: {
		marginTop: 10,
		marginBottom: 20,
		display: 'flex',
		alignItems: 'center'
	},
	price: {
		fontSize: 28,
		fontWeight: '700',
		color: theme.custom.colors.kozmikDarkBlue,
		marginRight: 10
	},
	priceText: {
		fontSize: 14,
		color: theme.custom.colors.kozmikDarkBlue,
		opacity: 0.6
	},
	list: {
		listStyle: 'none',
		padding: 0,
		margin: 0,
		marginTop: 20,
		[theme.breakpoints.down('lg')]: {
			marginTop: 20
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: 50
		}
	},
	listItem: {
		fontSize: 14,
		display: 'flex',
		alignItems: 'center',
		marginBottom: 10
	},
	doneIcon: {
		width: 20,
		marginRight: 10,
		height: 'auto',
		color: theme.custom.colors.kozmikGreen
	}
}))

const PriceCard = (props) => {
	const classes = useStyles()

	return (
		<div
			className={classes.mainContainer}
			style={{
				border: props?.highlighted
					? `1px solid ${kozmikTheme.colors.darkGray}`
					: 'none',
				boxShadow: props?.highlighted
					? '0 8px 16px 0 rgba(0, 0, 0, 0.17)'
					: 'none'
			}}
		>
			<div>
				<h5 className={classes.title}>{props?.title}</h5>
				<div className={classes.priceBox}>
					<span className={classes.price}>{props?.price}</span>
					<span className={classes.priceText}>{props?.text}</span>
				</div>
				<form action={paymentDomain.checkout} method='POST'>
					<input type='hidden' name='orgId' value={props?.currentOrg?.id} />
					<input type='hidden' name='userId' value={props.userInfo?.id} />
					<input type='hidden' name='email' value={props.userInfo?.email} />
					<input type='hidden' name='plan' value={props?.plan} />
					<Button
						disabled={props?.current}
						type='submit'
						className={cx(
							classes.button,
							props?.highlighted
								? classes.highlightedButton
								: props?.current
								? classes.currentButton
								: null
						)}
						variant={props?.current ? 'outlined' : 'contained'}
						fullWidth
					>
						{props?.current ? props?.btnCurrentText : props?.btnText}
					</Button>
				</form>
			</div>
			<div>
				<ul className={classes.list}>
					{props?.features?.map((item, index) => (
						<li key={index} className={classes.listItem}>
							<DoneIcon className={classes.doneIcon} />
							{item}
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	...state.userInfo
})

export default connect(mapStateToProps, null)(PriceCard)

import { v4 as uuidv4 } from 'uuid'

import defaultElementSchemas from '../defaultElementSchemas'
import generateScreenPathByName from '../methods/app/generateScreenPathByName'

function Screen(
	body = [],
	name = 'New Screen',
	type = null,
	headerOptions = {
		leftButtons: []
	},
	refs = [],
	source = null
) {
	const headerOpts = {
		leftButtons: headerOptions?.leftButtons ? headerOptions.leftButtons : [],
		rightButtons: headerOptions?.rightButtons ? headerOptions.rightButtons : [],
		title: headerOptions?.title ? headerOptions.title : name
	}

	this.key = uuidv4()
	this.path = generateScreenPathByName(
		null,
		null,
		name,
		source !== null
	)?.generatedPath
	this.type = type
	this.name = name
	this.icon = 'rocket'
	this.style = 'screen'
	this.backgroundColor = null
	this.mapping = {
		backgroundImageUrl: {
			field: null,
			default: null,
			type: null
		}
	}
	this.visibility = {
		visible: 'everyone',
		conditions: {
			groupOp: 'AND',
			groups: [
				{
					groupOp: 'AND',
					rules: []
				},
				{
					groupOp: 'AND',
					rules: []
				}
			]
		}
	}
	this.header = defaultElementSchemas.header(
		headerOpts.leftButtons,
		headerOpts.rightButtons,
		headerOpts.title,
		source
	)
	this.body = body
	this.refs = refs
	this.source = source
}

export default Screen

import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { Box } from '@mui/material'

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiOutlinedInput-input': {
			color: theme.custom.colors.textPrimary
		},
		'& .MuiInputLabel-root': {
			color: theme.custom.colors.textPrimary
		},
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent'
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
			backgroundColor: 'transparent',
			color: theme.custom.colors.textPrimary
		},
		'& .MuiInputLabel-root.Mui-focused': {
			color: theme.custom.colors.textPrimary
		},
		'& .MuiSvgIcon-root.MuiSelect-icon': {
			color: theme.custom.colors.textSecondary
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent'
		}
	},
	extraButton: {
		color: theme.custom.colors.kozmikBlue,
		fontSize: 14,
		padding: '14px 16px 6px 16px',
		borderTop: `1px solid ${theme.custom.colors.gray}`,
		cursor: 'pointer'
	},
	menuItem: {
		fontSize: 14
	}
}))

const SelectBox = (props) => {
	const classes = useStyles()

	const {
		textKey,
		valueKey,
		objectValueKey,
		data,
		emptyText,
		extraButton,
		...restProps
	} = props

	return (
		<FormControl
			variant='outlined'
			size='small'
			fullWidth={true}
			className={classes.root}
		>
			<Select variant='outlined' displayEmpty {...restProps}>
				{restProps?.placeholder && (
					<MenuItem disabled value={''} className={classes.menuItem}>
						{restProps.placeholder}
					</MenuItem>
				)}
				{!!emptyText && (
					<MenuItem value={''} className={classes.menuItem}>
						{emptyText}
					</MenuItem>
				)}
				{!!data?.length &&
					data.map((item, index) => (
						<MenuItem
							key={index}
							value={
								objectValueKey
									? item?.[valueKey]?.[objectValueKey]
									: valueKey
									? item?.[valueKey]
									: item
							}
							className={classes.menuItem}
						>
							{item?.[textKey]}
						</MenuItem>
					))}
				{extraButton && (
					<Box className={classes.extraButton} onClick={extraButton.onClick}>
						{extraButton.text}
					</Box>
				)}
			</Select>
		</FormControl>
	)
}
export default SelectBox

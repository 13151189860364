import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

import BorderlessTextField from '../borderlessTextField/BorderlessTextField'

const useStyles = makeStyles((theme) => ({
	input: {
		borderRadius: 3,
		backgroundColor: (props) =>
			props?.bgColor ? props.bgColor : theme.custom.colors.kozmikGray,
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	}
}))

const KozmikTextField = (props) => {
	const { InputProps, bgColor, ...restProps } = props
	const classes = useStyles(props)

	return (
		<BorderlessTextField
			size='small'
			variant='outlined'
			InputProps={{
				classes: {
					input: classes.input,
					...InputProps?.classes
				},
				...InputProps
			}}
			{...restProps}
		/>
	)
}
export default KozmikTextField

import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { Box, Button, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import ProfileCard from '../../molecules/profileCard/ProfileCard'
import KozmikProgressBar from '../../atoms/kozmikProgressBar/KozmikProgressBar'

import authorizationClient from '../../../services/authorizationClient'

import kozmikTheme from '../../../assets/theme/theme.json'

const useStyles = makeStyles((theme) => ({
	toolbar: {
		fontSize: '22px',
		lineHeight: '17px',
		textAlign: 'left'
	},
	row: {
		display: 'flex'
	},
	accountPopover: {
		position: 'absolute',
		right: 12,
		bottom: 50,
		backgroundColor: '#FFFFFF',
		borderRadius: '4px',
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.17)',
		width: '10.2vw',
		padding: '10px',
		[theme.breakpoints.down('md')]: {
			width: '30vw'
		}
	},
	paddingTop2: {
		paddingTop: '2%'
	},
	root: {
		'& > span': {
			margin: theme.spacing(2)
		}
	},
	userInfo: {
		flexGrow: 1,
		paddingLeft: 5,
		overflow: 'hidden'
	},
	name: {
		color: theme.custom.colors.textPrimary,
		fontSize: 12,
		textAlign: 'left',
		fontWeight: 600,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	email: {
		color: theme.custom.colors.textPrimary,
		fontSize: 10,
		textAlign: 'left',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	exitIcon: {
		color: theme.custom.colors.textPrimary,
		width: '14px'
	},
	accountContainer: {
		display: 'flex',
		alignItems: 'center',
		height: 45,
		border: `1px solid ${theme.custom.colors.gray}`,
		borderRadius: 6,
		padding: 5,
		cursor: 'pointer',
		marginBottom: 5
	},
	exitButton: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		marginTop: 15
	},
	exitText: {
		paddingLeft: 5,
		color: theme.custom.colors.textPrimary,
		fontSize: 12,
		fontWeight: 'bold'
	},
	subscriptionText: {
		paddingBottom: 5,
		color: theme.custom.colors.textPrimary,
		fontSize: 12,
		fontWeight: '600'
	},
	upgradeButton: {
		textTransform: 'none',
		backgroundColor: `${theme.custom.colors.kozmikGreen} !important`,
		boxShadow: 'none !important',
		borderRadius: '5px',
		marginLeft: '10px',
		height: '25px'
	},
	btnTxt: {
		fontSize: 8,
		color: theme.custom.colors.kozmikDarkBlue,
		fontWeight: 'bold'
	}
}))

const Account = (props) => {
	const classes = useStyles()
	const { t } = useTranslation()
	let location = useLocation()

	return (
		<div>
			{!!props?.usage && (
				<Link to={'/subscription'} onClick={props?.collapseHamburger}>
					<div
						className={classes.accountContainer}
						style={
							location?.pathname == '/subscription'
								? { backgroundColor: kozmikTheme.colors.gray }
								: null
						}
					>
						<Box sx={{ flexGrow: 1 }}>
							<div className={classes.subscriptionText}>
								{props.usage?.Plan}
							</div>
							<KozmikProgressBar
								barColor={
									location?.pathname == '/subscription' ? '#ffffff' : null
								}
								variant='determinate'
								value={props.usage?.Percentage}
							/>
						</Box>
						<Button
							size='small'
							variant='contained'
							className={classes.upgradeButton}
						>
							<Typography className={classes.btnTxt}>
								{t('masterAccount.upgrade')}
							</Typography>
						</Button>
					</div>
				</Link>
			)}
			<Link to={'/account'} onClick={props?.collapseHamburger}>
				<div
					className={classes.accountContainer}
					style={
						location?.pathname == '/account'
							? { backgroundColor: kozmikTheme.colors.gray }
							: null
					}
				>
					<ProfileCard
						text={props.userInfo?.name}
						imgUrl={props.userInfo?.photo}
						size={35}
						circle
						bgColor={kozmikTheme.colors.gray}
					/>
					<div className={classes.userInfo}>
						<div className={classes.name}>{props.userInfo?.name}</div>
						<div className={classes.email}>{props.userInfo?.email}</div>
					</div>
				</div>
			</Link>
			<div
				onClick={() => authorizationClient.logout()}
				className={classes.exitButton}
			>
				<ExitToAppIcon className={classes.exitIcon} />
				<span className={classes.exitText}>{t('masterAccount.logOut')}</span>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	...state.userInfo,
	...state.usage
})

// const mapDispatchToProps = (dispatch) => {
// 	return {
// 		setUserInfo: (x) => dispatch(setUserInfo(x)),
// 		setAuth: (x) => dispatch(setAuth(x))
// 	}
// }

export default connect(mapStateToProps, null)(Account)

const convertibleTextBased = [
	'Text',
	'LongText',
	'Email',
	'Phone',
	'URL',
	'GeoLocation',
	'Image',
	'File'
]

export default convertibleTextBased

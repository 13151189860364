import React from 'react'

import { Typography, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import cx from 'classnames'

const useStyles = makeStyles((theme) => ({
	btn: {
		padding: '5px 16px',
		marginBottom: 10,
		borderRadius: 4,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		'&:hover': {
			backgroundColor: theme.custom.colors.kozmikGray
		},
		'&:hover $icon': {
			display: 'block'
		}
	},
	btnActive: {
		backgroundColor: `${theme.custom.colors.kozmikLightBlue} !important`,
		'& $btnTxt': {
			color: theme.custom.colors.kozmikBlue
		},
		'& $icon': {
			color: theme.custom.colors.kozmikBlue
		}
	},
	btnTxt: {
		color: theme.custom.colors.textPrimary,
		paddingLeft: 5,
		fontSize: 12,
		fontWeight: '600',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	},
	icon: {
		color: theme.custom.colors.textPrimary,
		fontSize: 20
	}
}))

const DensityMenuItem = (props) => {
	const classes = useStyles()

	const { item, isActive, ...restProps } = props

	return (
		<Grid
			item
			xs={12}
			className={cx(classes.btn, isActive ? classes.btnActive : null)}
			{...restProps}
		>
			{!!item?.icon && <item.icon className={classes.icon} />}
			<Typography className={classes.btnTxt}>{item.label}</Typography>
		</Grid>
	)
}

export default DensityMenuItem

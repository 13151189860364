import React, { useEffect, useState, useRef } from 'react'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'

import TeamTemplate from '../../templates/teamTemplate/TeamTemplate'
import WarningDialog from '../../molecules/dialog/warningDialog/WarningDialog'
import KozmikModal from '../../molecules/kozmikModal/KozmikModal'
import TagInput from '../../atoms/tagInput/TagInput'
import kozmikTheme from '../../../assets/theme/theme.json'
import SelectBox from '../../molecules/selectBox/SelectBox'
import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'

import userClient from '../../../services/userClient'
import showToastResponseError from '../../../methods/showToastResponseError'

const useStyles = makeStyles((theme) => ({
	buttonTxt: {
		color: '#ffffff',
		fontSize: 14,
		fontWeight: '600',
		textAlign: 'left',
		textTransform: 'none',
		whiteSpace: 'nowrap'
	},
	selectInput: {
		marginTop: 10,
		height: 40,
		width: 210,
		backgroundColor: theme.custom.colors.kozmikGray,
		borderRadius: 4,
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	multilineInput: {
		marginTop: 10,
		borderRadius: 4,
		backgroundColor: theme.custom.colors.kozmikGray,
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	modalTitle: {
		fontSize: 16,
		fontWeight: '600',
		color: '#000000'
	},
	inviteSubTitle: {
		fontSize: 14,
		color: '#000000',
		marginBottom: 10
	}
}))

const Users = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const orgId = props?.currentOrg?.id

	const [userData, setUserData] = useState([])
	const [deleteId, setDeleteId] = useState()
	const [visibleModal, setVisibleModal] = useState(null) //invite,edit,delete

	const closeDialog = () => {
		setVisibleModal(null)
	}

	const getUserData = (searchTerm = '') => {
		if (orgId) {
			const data = {
				Sort: 'Created asc'
			}
			if (searchTerm) {
				data['Term'] = searchTerm
			}

			userClient.userSearch(data).then((response) => {
				if (response?.data?.success) {
					setUserData(response.data?.data)
				} else {
					showToastResponseError(response)
				}
			})
		}
	}

	const search = (searchTerm) => {
		getUserData(searchTerm)
	}

	const deleteUser = (id) => {
		userClient.userDelete(id).then((response) => {
			if (response?.data?.success) {
				const userIndex = userData?.findIndex((x) => x.Id == id)
				const modifiedUserData = update(userData, {
					$splice: [[userIndex, 1]]
				})
				setUserData(modifiedUserData)
				closeDialog()
			} else {
				showToastResponseError(response)
			}
		})
	}

	const handleDeleteAccept = () => {
		deleteUser(deleteId)
	}

	/****USER_GROUP***************************/
	const roleOpts = [
		{
			label: t('team.roleOpts.owner'),
			value: 'Owner'
		},
		{
			label: t('team.roleOpts.developer'),
			value: 'Developer'
		}
	]

	const userGroupsChange = (e, id) => {
		const data = {
			Id: id,
			RoleType: e.target.value
		}
		userClient.userUpdate(data).then((response) => {
			if (response?.data?.success) {
				const userIndex = userData?.findIndex((x) => x.Id == id)
				const modifiedUserData = update(userData, {
					[userIndex]: {
						RoleType: { $set: e.target.value }
					}
				})
				setUserData(modifiedUserData)
			} else {
				showToastResponseError(response)
			}
		})
	}

	/****STATUS***************************/
	const statusOpts = [
		{
			value: true,
			label: t('team.statusOpts.true')
		},
		{
			value: false,
			label: t('team.statusOpts.false')
		}
	]

	const statusChange = (e, id) => {
		const data = {
			Id: id,
			IsActive: e.target.value
		}
		userClient.userUpdate(data).then((response) => {
			if (response?.data?.success) {
				const userIndex = userData?.findIndex((x) => x.Id == id)
				const modifiedUserData = update(userData, {
					[userIndex]: {
						IsActive: { $set: e.target.value }
					}
				})
				setUserData(modifiedUserData)
			} else {
				showToastResponseError(response)
			}
		})
	}

	/****INVITE***************************/
	const [emails, setEmails] = useState([])
	const [isEmailValid, setIsEmailValid] = useState(true)
	const [inviteUserGroup, setInviteUserGroup] = useState('')
	const [inviteMessage, setInviteMessage] = useState(
		t('team.inviteModal.defaultMessage')
	)

	const validateEmail = (email) => {
		return new Promise((resolve) => {
			const re = /\S+@\S+\.\S+/
			const isValid = re.test(email)
			if (isValid || email == '') {
				setIsEmailValid(true)
			} else {
				setIsEmailValid(false)
			}
			resolve(isValid)
		})
	}

	const inviteUsers = () => {
		const data = {
			Emails: emails,
			RoleType: inviteUserGroup,
			Message: inviteMessage
		}

		userClient.userInvite(data).then((response) => {
			if (response?.status == 200) {
				if (response?.data?.every((x) => x?.success)) {
					// toast.success(t('team.inviteToast.success'))
					setVisibleModal(null)
					getUserData()
				} else if (response?.data?.every((x) => !x?.success)) {
					toast.error(t('team.inviteToast.fail'))
				} else if (response?.data?.some((x) => !x?.success)) {
					toast.error(t('team.inviteToast.partial'))
					setVisibleModal(null)
					getUserData()
				}
			} else {
				showToastResponseError(response)
			}
		})
	}

	useEffect(() => {
		document.title = t('docTitles.team')
	}, [])

	useEffect(() => {
		getUserData()
	}, [orgId])

	useEffect(() => {
		if (visibleModal == 'invite') {
			setEmails([])
			setIsEmailValid(true)
			setInviteUserGroup('')
			setInviteMessage(t('team.inviteModal.defaultMessage'))
		}
	}, [visibleModal])

	const childRef = useRef()

	return (
		<>
			<TeamTemplate
				ref={childRef}
				userData={userData}
				roleOpts={roleOpts}
				userGroupsChange={userGroupsChange}
				statusOpts={statusOpts}
				statusChange={statusChange}
				setVisibleModal={setVisibleModal}
				setDeleteId={setDeleteId}
				//columns={columns}
				search={search}
				// className={classes.outerContainer}
			/>

			<KozmikModal
				sm
				visible={visibleModal == 'invite'}
				hideModal={closeDialog}
			>
				<Box
					style={{
						height: '100%',
						padding: '35px 20px',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between'
					}}
				>
					<Box>
						<Typography className={classes.modalTitle}>
							{t('team.inviteModal.title')}
						</Typography>
						<Typography className={classes.inviteSubTitle}>
							{t('team.inviteModal.subTitle')}
						</Typography>
						<TagInput
							error={!isEmailValid}
							tags={emails}
							setTags={setEmails}
							onInputChange={(e) => validateEmail(e.target.value)}
							placeholder={t('team.inviteModal.emailPlaceholder')}
						/>
						<SelectBox
							className={classes.selectInput}
							onChange={(e) => setInviteUserGroup(e.target.value)}
							value={inviteUserGroup}
							data={roleOpts}
							textKey='label'
							valueKey='value'
							placeholder={t('team.inviteModal.rolePlaceholder')}
						/>
						<KozmikTextField
							fullWidth
							placeholder={t('team.inviteModal.messagePlaceholder')}
							value={inviteMessage}
							onChange={(e) => setInviteMessage(e.target.value)}
							className={classes.multilineInput}
							multiline
							rows={3}
						/>
					</Box>
					<Box style={{ display: 'flex', justifyContent: 'center' }}>
						<Button
							// fullWidth
							disabled={!isEmailValid || !emails?.length}
							size='large'
							variant='contained'
							onClick={inviteUsers}
							sx={{
								width: '50%',
								textTransform: 'none',
								backgroundColor: `${kozmikTheme.colors.kozmikBlue} !important`,
								color: '#ffffff',
								boxShadow: 'none !important',
								fontSize: '14px',
								borderRadius: '4px',
								border: `1px solid ${kozmikTheme.colors.kozmikBlue}`,
								opacity: !isEmailValid || !emails?.length ? 0.4 : 1 //for disabled
							}}
						>
							<Typography className={classes.buttonTxt}>
								{t('team.inviteModal.submit')}
							</Typography>
						</Button>
					</Box>
				</Box>
			</KozmikModal>

			<WarningDialog
				dialogVisible={visibleModal == 'delete'}
				dialogClose={closeDialog}
				dialogAccept={handleDeleteAccept}
				dialogTitle={t('team.confirmModal.dialogTitle')}
				title={null}
				text={t('team.confirmModal.text')}
				dialogCancelText={t('team.confirmModal.closeButton')}
				dialogAcceptText={t('team.confirmModal.acceptButton')}
			/>
		</>
	)
}

const mapStateToProps = (state) => ({
	...state.userInfo
})

export default connect(mapStateToProps, null)(Users)

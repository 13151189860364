import React from 'react'
import { ListItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
	GridColumnMenuContainer,
	SortGridMenuItems,
	GridFilterMenuItem
	// GridColumnPinningMenuItems
	// GridColumnMenu,
} from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'

import systemFieldNames from '../../../common/systemFieldNames/systemFieldNames'

const useStyles = makeStyles((theme) => ({
	errorText: {
		color: theme.custom.colors.red
	}
}))

function DataTableColumnMenu(props) {
	const { t } = useTranslation()
	const classes = useStyles()
	const {
		hideMenu,
		currentColumn,
		color,
		openEditPop,
		setSelectedFieldId,
		setDeleteConfirmationVisible,
		...other
	} = props

	const originalData = currentColumn.originalData

	const openUpdatePop = (e) => {
		e.stopPropagation()
		hideMenu(e)
		const heightBetweenHeader = 130
		openEditPop(
			{ left: e.pageX, top: e.pageY - heightBetweenHeader },
			originalData.id,
			originalData.label ? originalData.label : '',
			originalData.description ? originalData.description : '',
			originalData
		)
	}
	const openDeleteConfirmationModal = (e) => {
		e.stopPropagation()
		hideMenu(e)
		setSelectedFieldId(originalData.id)
		setDeleteConfirmationVisible(true)
	}

	return (
		<GridColumnMenuContainer
			hideMenu={hideMenu}
			currentColumn={currentColumn}
			className={classes[color]}
			{...other}
		>
			<SortGridMenuItems onClick={hideMenu} column={currentColumn} />
			<GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
			{(!originalData.managed ||
				systemFieldNames.partiallyManaged.includes(originalData.name)) && (
				<ListItem button onClick={openUpdatePop}>
					{t('appData.fieldMenuEdit')}
				</ListItem>
			)}
			{!originalData.managed && (
				<ListItem button onClick={openDeleteConfirmationModal}>
					<span className={classes.errorText}>{t('appData.fieldMenuDel')}</span>
				</ListItem>
			)}
			{/* <GridColumnPinningMenuItems onClick={hideMenu} column={currentColumn} /> */}
		</GridColumnMenuContainer>
	)
}

export default DataTableColumnMenu

import React from 'react'
import withStyles from '@mui/styles/withStyles'
import TextField from '@mui/material/TextField'

const BorderlessInput = withStyles({
	root: {
		justifyContent: 'center',
		'& label.Mui-focused': {
			color: 'transparent'
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: 'transparent'
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'transparent'
			},
			'&:hover fieldset': {
				borderColor: 'transparent'
			},
			'&.Mui-focused fieldset': {
				borderColor: 'transparent'
			}
		},
		'& .MuiOutlinedInput-root.Mui-error': {
			'& fieldset': {
				borderColor: 'red'
			},
			'&:hover fieldset': {
				borderColor: 'red'
			},
			'&.Mui-focused fieldset': {
				borderColor: 'red'
			}
		}
	}
})(TextField)

const BorderlessTextField = (props) => {
	return <BorderlessInput variant='outlined' {...props} />
}
export default BorderlessTextField

import {
	SETUSERINFO,
	SETGHOSTUSER,
	SETORG,
	SETCURRENTORG
} from '../action-types/userInfoActionTypes'

export const setUserInfo = (value) => ({
	type: SETUSERINFO,
	payload: value
})
export const setGhostUser = (value) => ({
	type: SETGHOSTUSER,
	payload: value
})

export const setOrg = (value) => ({
	type: SETORG,
	payload: value
})

export const setCurrentOrg = (value) => ({
	type: SETCURRENTORG,
	payload: value
})

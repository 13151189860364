import { v4 as uuidv4 } from 'uuid'

import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import relationTypes from '../../common/enums/relationTypes'
import i18next from '../../i18n/i18n'

const method = (table, actions = null) => {
	const numericFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Number'],
		table,
		true,
		relationTypes.one
	)

	const numericField = numericFields?.length ? numericFields[0] : null

	const object = {
		_uid: uuidv4(),
		component: 'rating',
		source: table?.name,
		mapping: {
			text: {
				default: i18next.t('schemas.rating.text')
			},
			rating: {
				field: numericField?.name,
				type: numericField?.dataType
			}
		},
		filters: {
			groupOp: 'AND',
			rules: []
		},
		maxRating: 5,
		actions: actions ?? [],
		limit: 10,
		spacing: 'xl'
	}

	return object
}

export default method

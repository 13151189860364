import React, { useEffect, useState } from 'react'
import { CardMedia, Grid, Typography, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import cx from 'classnames'
import { z } from 'zod'

import KozmikTextField from '../../../atoms/kozmikTextField/KozmikTextField'
import KozmikFilePicker from '../../../atoms/kozmikFilePicker/KozmikFilePicker'

import timeoutDelay from '../../../../methods/timeoutDelay'

import appClient from '../../../../services/appClient'
import storeSettingClient from '../../../../services/storeSettingClient'
import showToastResponseError from '../../../../methods/showToastResponseError'

import { setDetail } from '../../../../redux/actions/appDataActions'

const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%',
		paddingTop: 20,
		paddingBottom: 20
	},
	contentTitle: {
		marginBottom: 10,
		fontSize: 16,
		fontWeight: '600'
	},
	contentDesc: {
		marginBottom: 15,
		fontSize: 14
	},
	subTitle: {
		fontSize: 12,
		fontWeight: '600'
	},
	input: {
		fontSize: 14,
		backgroundColor: theme.custom.colors.kozmikGray,
		borderRadius: 6,
		border: 'none',
		'& .MuiOutlinedInput-root': {
			padding: 0
		},
		'& .MuiOutlinedInput-adornedStart': {
			paddingLeft: 0
		},
		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: 0
		}
	}
}))

const WebPush = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	let { appId } = useParams()

	const chooseText = t('publishModule.androidModal.chooseFile')

	const [setting, setSetting] = useState(null)

	/****ACCOUNT_KEY_FILE***************************/
	const [accountFile, setAccountFile] = useState(null)
	const [accountFileName, setAccountFileName] = useState(chooseText)
	const accountFileOnChange = (fileObject) => {
		setAccountFileName(fileObject?.name)
		setAccountFile(fileObject?.base64File)

		updateSetting({
			AndroidGoogleAccountFile: fileObject?.base64File
		})
	}

	/****FIREBASE_CONFIG***************************/
	const firebaseConfigSample = {
		apiKey: 'apiKey',
		authDomain: 'authDomain',
		projectId: 'projectId',
		storageBucket: 'storageBucket',
		messagingSenderId: 'messagingSenderId',
		appId: 'appId'
	}

	const firebaseConfigSchema = z.object({
		apiKey: z.string(),
		authDomain: z.string(),
		projectId: z.string(),
		storageBucket: z.string(),
		messagingSenderId: z.string(),
		appId: z.string()
	})

	const requiredFirebaseConfigSchema = firebaseConfigSchema.required()

	const [firebaseConfigError, setFirebaseConfigError] = useState('')
	const [firebaseConfig, setFirebaseConfig] = useState(
		JSON.stringify(firebaseConfigSample, null, 4)
	)
	const changeFirebaseConfig = (e) => {
		try {
			const parsedJson = JSON.parse(e.target.value)
			const parseResult = requiredFirebaseConfigSchema.safeParse(parsedJson)
			if (parseResult.success) {
				const parsedJsonAsText = JSON.stringify(parsedJson, null, 4)

				setFirebaseConfigError('')
				setFirebaseConfig(parsedJsonAsText)
				timeoutDelay(
					updateSetting,
					{
						FirebaseConfig: parsedJsonAsText
					},
					1000
				)
			} else {
				setFirebaseConfigError(parseResult.error.issues?.[0].message)
			}
		} catch (error) {
			setFirebaseConfigError(error.message)
		}
	}

	const getSetting = () => {
		storeSettingClient.getSetting(appId).then((response) => {
			if (response?.data?.success) {
				setSetting(response?.data?.data)
			} else {
				showToastResponseError(response)
			}
		})
	}

	const updateSetting = (data) => {
		storeSettingClient
			.updateSetting(appId, data)
			.then((response) => {
				if (response?.data?.success) {
					setSetting({ ...setting, ...data })
				} else {
					setSetting(setting)
					showToastResponseError(response)
				}
			})
			.catch((error) => {
				setSetting(setting)
			})
	}

	const initializationSetting = (setting) => {
		/****ACCOUNT_KEY_FILE***************************/
		setAccountFile(setting?.AndroidGoogleAccountFile || null)
		setAccountFileName(
			setting?.AndroidGoogleAccountFile
				? t('publishModule.androidModal.accountFile')
				: chooseText
		)
		/****FIREBASE_CONFIG***************************/
		setFirebaseConfig(
			setting?.FirebaseConfig || JSON.stringify(firebaseConfigSample, null, 4)
		)
	}

	useEffect(() => {
		setting && initializationSetting(setting)
	}, [setting])

	useEffect(() => {
		getSetting()
	}, [])

	return (
		<div className={classes.container}>
			<Typography className={classes.contentTitle}>
				{t('publishModule.webModal.pushCredentialsTitle')}
			</Typography>
			<Typography className={classes.contentDesc}>
				{t('publishModule.webModal.pushCredentialsDesc')}
			</Typography>

			<Box style={{ marginBottom: 10 }}>
				<Typography className={classes.subTitle}>
					{t('publishModule.webModal.accountKeyFile')}
				</Typography>
				<KozmikFilePicker
					onChange={accountFileOnChange}
					fileName={accountFileName}
					accept='.json'
				/>
			</Box>

			<Box style={{ marginBottom: 10 }}>
				<Typography className={classes.subTitle}>
					{t('publishModule.webModal.firebaseConfig')}
				</Typography>
				<KozmikTextField
					multiline
					className={classes.input}
					fullWidth
					// error={!!firebaseConfigError}
					helperText={firebaseConfigError}
					value={firebaseConfig}
					rows={8}
					onChange={changeFirebaseConfig}
				/>
			</Box>
		</div>
	)
}

const mapStateToProps = (state) => ({
	...state.appData
})

const mapDispatchToProps = (dispatch) => {
	return {
		setDetail: (x) => dispatch(setDetail(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WebPush)

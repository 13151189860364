import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { SwapVert, FilterAltOutlined, PlusOne } from '@mui/icons-material'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateText from '../../../molecules/doubleStateField/DoubleStateText'
import DoubleStateMentions from '../../../molecules/doubleStateField/DoubleStateMentions'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import AccordionItem from '../../../atoms/accordionItem/AccordionItem'
import Sorting from '../../../molecules/sorting/Sorting'
import FilterList from '../../../molecules/filterList/FilterList'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import NumericInput from '../../../atoms/numericInput/NumericInput'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import relationTypes from '../../../../common/enums/relationTypes'
import getArrayExcludingForbidArray from '../../../../methods/getArrayExcludingForbidArray'
import systemFieldNames from '../../../../common/systemFieldNames/systemFieldNames'

import { ReactComponent as Dropdown } from '../../../../assets/icons/Settings/Dropdown.svg'
import { ReactComponent as Chips } from '../../../../assets/icons/Settings/Chips.svg'
import { ReactComponent as Radio } from '../../../../assets/icons/Settings/Radio.svg'
import { ReactComponent as CheckList } from '../../../../assets/icons/Settings/CheckList.svg'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const Choice = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const propsRef = useRef()
	propsRef.current = props

	const [elementName, setElementName] = useState(null)

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		fieldDataTypes.textBased,
		null,
		true,
		relationTypes.one
	)
	const textOptions = textFields?.length ? textFields : []
	const textOptionsWithEmpty = [{ label: '-', name: '' }, ...textOptions]

	const relationFields = props?.entities
		?.find((x) => x.name == props.activeScreen.data?.source)
		?.fields?.filter((x) => x.dataType == 'Relation')

	const filteredRelationFields = getArrayExcludingForbidArray(
		relationFields,
		'name',
		systemFieldNames.notVisible
	)

	const relatedEntity = props?.entities?.find(
		(x) => x.name === props?.elementData?.relation?.source
	)

	// const relatedFields = relatedEntity?.fields

	const relatedTextFields = getEntityFieldsByDataTypes(
		props?.entities,
		relatedEntity?.name,
		fieldDataTypes.textBased
	)

	const typeOptions = [
		{
			text: t('screens.elementSettings.choice.typeOpts.dropdown'),
			value: 'dropdown',
			icon: Dropdown
		},
		{
			text: t('screens.elementSettings.choice.typeOpts.chips'),
			value: 'chips',
			icon: Chips
		},
		{
			text: t('screens.elementSettings.choice.typeOpts.radio'),
			value: 'radio',
			icon: Radio
		},
		{
			text: t('screens.elementSettings.choice.typeOpts.checkbox'),
			value: 'checkbox',
			icon: CheckList
		}
	]

	/****FIELD***************************/
	const [field, setField] = useState('')

	const fieldChange = (e) => {
		submitField(e.target.value)
		setField(e.target.value)
	}

	const submitField = (x) => {
		const selectedField = filteredRelationFields?.find((y) => y.name === x)

		const modifiedElementData = update(props.elementData, {
			relation: {
				type: { $set: selectedField.relationType },
				source: { $set: selectedField.relatedEntityName },
				field: { $set: 'Name' }
			},
			mapping: {
				value: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			},
			sorting: {
				field: { $set: 'Name' },
				sort: { $set: 'asc' }
			},
			filters: {
				groupOp: { $set: 'OR' },
				rules: { $set: [] }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****LIMIT***************************/
	const [limit, setLimit] = useState(25)
	const limitChange = (val) => {
		timeoutDelay(submitLimit, val, 1000)
		setLimit(val)
	}
	const submitLimit = (x) => {
		const modifiedElementData = update(props.elementData, {
			limit: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****RELATION_FIELD***************************/
	const [relationField, setRelationField] = useState('')

	const relationFieldChange = (e) => {
		submitRelationField(e.target.value)
		setRelationField(e.target.value)
	}

	const submitRelationField = (x) => {
		const modifiedElementData = update(props.elementData, {
			relation: {
				field: { $set: x }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****FILTER_&_SORT***************************/
	const [localElementData, setLocalElementData] = useState(null)

	/****TITLE***************************/
	const [isTitleTextMode, setIsTitleTextMode] = useState(false)
	const [titleField, setTitleField] = useState('')
	const [titleDefault, setTitleDefault] = useState('')

	const titleChange = (data) => {
		if (isTitleTextMode) {
			submitTitle(data)
			setTitleDefault(data)
		} else {
			submitTitle(data?.value)
			setTitleField(data?.value)
		}
	}
	const submitTitle = (x) => {
		const selectedField = textFields?.find((y) => y.name === x)
		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				title: {
					field: { $set: isTitleTextMode ? null : x },
					default: { $set: isTitleTextMode ? x : null },
					type: { $set: isTitleTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****TYPE***************************/
	const [type, setType] = useState(null)
	const typeChange = (val) => {
		submitType(val)
		setType(val)
	}
	const submitType = (x) => {
		const modifiedElementData = update(props.elementData, {
			type: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****FIELD***************************/
		setField(
			elementData?.mapping?.value?.field ? elementData.mapping.value.field : ''
		)
		/****RELATION_FIELD***************************/
		setRelationField(
			elementData?.relation?.field ? elementData.relation.field : ''
		)
		/****TITLE***************************/
		elementData?.mapping?.title?.field ||
		elementData?.mapping?.title?.field === ''
			? setIsTitleTextMode(false)
			: setIsTitleTextMode(true)

		setTitleField(elementData?.mapping?.title?.field || '')
		setTitleDefault(elementData?.mapping?.title?.default || '')
		/****TYPE***************************/
		setType(elementData?.type || null)
		/****LIMIT***************************/
		setLimit(elementData?.limit ?? 10)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
			setLocalElementData(props.elementData)
		}
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.source')}
					expanded={true}
				>
					<div className={classes.mb10}>
						<AccordionItem
							icon={FilterAltOutlined}
							title={t('screens.elementSettings.choice.filter')}
							expanded={false}
							padding={8}
						>
							<FilterList
								filter={localElementData?.filters}
								table={relatedEntity}
								filtering={props.filtering}
								setLocalElementData={setLocalElementData}
								activeScreen={props?.activeScreen}
								entities={props?.entities}
							/>
						</AccordionItem>
					</div>

					<div className={classes.mb10}>
						<AccordionItem
							icon={SwapVert}
							title={t('screens.elementSettings.choice.sort')}
							expanded={false}
							padding={8}
						>
							<Sorting
								sortingData={props.elementData?.sorting}
								sorting={props.sorting}
								fieldOptions={relatedTextFields}
							/>
						</AccordionItem>
					</div>

					<div className={classes.mb10}>
						<AccordionItem
							icon={PlusOne}
							title={t('screens.elementSettings.choice.limit')}
							expanded={false}
							padding={8}
						>
							<NumericInput minVal={0} value={limit} onChange={limitChange} />
						</AccordionItem>
					</div>
				</Accordion>
			</AppPageBox>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.choice.relation')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								// emptyText={t('screens.elementSettings.choice.emptyField')}
								className={cx(classes.input, classes.inputSize)}
								onChange={fieldChange}
								value={field}
								data={filteredRelationFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.choice.title')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateMentions
								name={`${elementName}`}
								fullWidth={true}
								isTextMode={isTitleTextMode}
								setIsTextMode={setIsTitleTextMode}
								valChange={titleChange}
								multiline={false}
								textVal={titleDefault}
								autoCompVal={titleField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.choice.relatedLabel')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								// emptyText={t('screens.elementSettings.choice.emptyField')}
								className={cx(classes.input, classes.inputSize)}
								onChange={relationFieldChange}
								value={relationField}
								data={relatedTextFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.choice.style')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={typeOptions}
								getChange={typeChange}
								activeValue={type}
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default Choice

const checkBase64 = (data) => {
	const str = data.split(',')[1]
	return new Promise((resolve) => {
		const base64Matcher = new RegExp(
			'^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$'
		)
		const isBase64 = base64Matcher.test(str)

		resolve(isBase64)
	})
}

export default checkBase64

import React from 'react'

import { Typography, Grid } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import cx from 'classnames'

const useStyles = makeStyles((theme) => ({
	tableBtn: {
		paddingLeft: 10,
		height: 40,
		marginBottom: 5,
		borderRadius: 6,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'&:hover': {
			backgroundColor: theme.custom.colors.kozmikGray
		},
		'&:hover $moreIcon': {
			display: 'block'
		}
	},
	tableBtnActive: {
		backgroundColor: `${theme.custom.colors.kozmikLightBlue} !important`,
		'& $tableBtnTxt': {
			color: theme.custom.colors.kozmikBlue
		},
		'& $moreIcon': {
			display: 'block'
		}
	},
	txtContainer: {
		overflowX: 'hidden'
	},
	tableBtnTxt: {
		fontSize: 14,
		color: theme.custom.colors.textPrimary,
		fontWeight: '600'
	},
	moreIcon: {
		color: theme.custom.colors.textPrimary,
		display: 'none'
	}
}))

const TableMenuItem = (props) => {
	const classes = useStyles()

	return (
		<Grid
			item
			xs={12}
			className={cx(
				classes.tableBtn,
				props.isActive ? classes.tableBtnActive : null
			)}
			onClick={() => {
				!props.isActive && props.click(props.item)
			}}
		>
			<div className={classes.txtContainer}>
				<Typography className={classes.tableBtnTxt}>
					{props.item.label}
				</Typography>
			</div>
			<MoreVert
				className={classes.moreIcon}
				onClick={(e) => {
					props.editClick(props.item, e)
				}}
			/>
		</Grid>
	)
}

export default TableMenuItem

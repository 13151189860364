import { v4 as uuidv4 } from 'uuid'

import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../common/fieldDataTypes'
// import i18next from '../../i18n/i18n'
import relationTypes from '../../common/enums/relationTypes'
import getArrayExcludingForbidArray from '../../methods/getArrayExcludingForbidArray'
import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'

const method = (table) => {
	const txtFields = getEntityFieldsByDataTypes(
		null,
		null,
		fieldDataTypes.textBased,
		table,
		true,
		relationTypes.one
	)
	const filteredTxtFields = getArrayExcludingForbidArray(
		txtFields,
		'name',
		systemFieldNames.notVisible
	)

	const imgFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Image'],
		table,
		true,
		relationTypes.one
	)
	const firstImgField = imgFields?.length ? imgFields[0] : null

	let object = {
		_uid: uuidv4(),
		component: 'titleCard',
		mapping: {
			title: {
				field: filteredTxtFields?.[0]?.name,
				default: filteredTxtFields?.[0]?.name ? null : '', //i18next.t('schemas.titleCard.title'),
				type: filteredTxtFields?.[0]?.name
					? filteredTxtFields[0]?.dataType
					: null
			},
			text: {
				field: filteredTxtFields?.[1]?.name,
				default: filteredTxtFields?.[1]?.name ? null : '', //i18next.t('schemas.titleCard.text'),
				type: filteredTxtFields?.[1]?.name
					? filteredTxtFields[1]?.dataType
					: null
			},
			imgUrl: {
				field: firstImgField?.name,
				default: null,
				type: firstImgField?.name ? firstImgField?.dataType : null
			}
		},
		spacing: 'xl',
		appearance: 'full',
		imageResizeMode: 'cover',
		fontSize: 'md',
		textAlign: 'left',
		width: 160,
		actions: [{ type: null }]
	}

	return object
}

export default method

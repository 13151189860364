import { ApiClient } from './client'

import { default as appConfig } from '../config/app.json'

const teamDomain = `${appConfig.domains.accountDomain}/api/team`

export default {
	async userSearch(data) {
		let url = '/search'
		let headers = {}
		// let data = {
		// 	Term: '',
		// 	Sort: 'Created asc',
		// 	Page: 1,
		// 	PageSize: 10
		// }

		headers['Content-Type'] = 'application/json'

		return await new ApiClient(teamDomain, headers).post(url, data)
	},
	async userDelete(userId) {
		let url = `/delete/${userId}`
		let headers = {}

		return await new ApiClient(teamDomain, headers).delete(url)
	},
	async userInvite(data) {
		let url = '/invite'
		let headers = {}
		headers['Content-Type'] = 'application/json'

		return await new ApiClient(teamDomain, headers).post(url, data)
	},
	async userUpdate(data) {
		let url = '/update'
		let headers = {}
		headers['Content-Type'] = 'application/json'

		return await new ApiClient(teamDomain, headers).put(url, data)
	}
}

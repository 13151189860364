import React, { useEffect } from 'react'

import { Link, useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import cx from 'classnames'

const useStyles = makeStyles((theme) => ({
	menuItem: {
		textDecoration: 'none !important',
		width: 45,
		marginBottom: 25,
		[theme.breakpoints.down('md')]: {
			margin: '0 12px'
		}
	},
	active: {
		'& $text': {
			fontWeight: '600'
		},
		'& $iconBox': {
			backgroundColor: `${theme.custom.colors.kozmikLightBlue} !important`
		},
		'& $icon': {
			color: `${theme.custom.colors.kozmikBlue} !important`
		}
	},
	text: {
		paddingTop: 3,
		color: theme.custom.colors.textPrimary,
		fontSize: 12,
		textAlign: 'left'
	},
	iconBox: {
		width: '45px',
		height: '45px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#ffffff',
		borderRadius: 6
	},
	icon: {
		fontSize: '25px',
		color: theme.custom.colors.kozmikLightBlue
	}
}))

const DetailMenuItem = (props) => {
	const classes = useStyles()
	let currentPage = useLocation().pathname

	const activeHrefs = props.data?.activeHrefs || props.data.href
	const isActive = activeHrefs.indexOf(currentPage) >= 0

	useEffect(() => {
		isActive &&
			props.setActiveMenuEvent &&
			props.setActiveMenuEvent({
				id: props.data.id,
				reVisit: false
			})
	}, [])

	return (
		<Link
			to={props.data.href}
			onClick={() => {
				props.setActiveMenuEvent &&
					props.setActiveMenuEvent({
						id: props.data.id,
						reVisit: props.activeMenuEvent?.id === props.data.id
					})
			}}
			className={cx(classes.menuItem, isActive && classes.active)}
		>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'center'
				}}
			>
				<div className={classes.iconBox}>
					{props.data?.icon && <props.data.icon className={classes.icon} />}
				</div>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Typography className={classes.text}>{props.data.name}</Typography>
				</div>
			</div>
		</Link>
	)
}

export default DetailMenuItem

import { v4 as uuidv4 } from 'uuid'

import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import i18next from '../../i18n/i18n'
import relationTypes from '../../common/enums/relationTypes'

const method = (table) => {
	const urlFields = getEntityFieldsByDataTypes(
		null,
		null,
		['URL'],
		table,
		true,
		relationTypes.one
	)
	const firstUrlField = urlFields?.length ? urlFields[0] : null

	let object = {
		_uid: uuidv4(),
		component: 'webView',
		mapping: {
			url: {
				field: firstUrlField?.name,
				default: firstUrlField?.name ? null : i18next.t('schemas.webView.url'),
				type: firstUrlField?.name ? firstUrlField.dataType : null
			}
		},
		scrollable: true,
		appearance: 'square'
	}

	return object
}

export default method

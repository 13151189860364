import React, { Component } from 'react'
import { connect } from 'react-redux'
import Cookies from 'universal-cookie'

import { setAuth } from '../redux/actions/authActions'
import authorizationClient from '../services/authorizationClient'
import { default as appConfig } from '../config/app.json'

const withAuth = (ChildComponent) => {
	const cookies = new Cookies()

	class HOComponent extends Component {
		componentDidMount() {
			const token = cookies.get(appConfig.cookieKeys.MY_SECURE_TOKEN_KEY)
			if (token === undefined || token === null) {
				window.location.href = '/login'
			} else if (!this.props.auth) {
				this.props.setAuth(true)

				if (!this.props.userInfo) {
					authorizationClient.getUserInfo()
				}
			}
		}

		render() {
			return this.props.auth ? <ChildComponent {...this.props} /> : null //TODO:loading
		}
	}

	const mapStateToProps = (state) => ({
		...state.userInfo,
		...state.auth
	})

	const mapDispatchToProps = (dispatch) => {
		return {
			setAuth: (x) => dispatch(setAuth(x))
		}
	}

	return connect(mapStateToProps, mapDispatchToProps)(HOComponent)
}

export default withAuth

const getFirstFieldExcludingForbidArray = (
	allArray,
	key,
	forbidValuesArray
) => {
	const arrayWithoutForbids = allArray?.filter(
		(x) => !forbidValuesArray.includes(x?.[key])
	)

	return arrayWithoutForbids?.length ? arrayWithoutForbids[0] : null
}

export default getFirstFieldExcludingForbidArray

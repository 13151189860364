import React, { useState, useRef } from 'react'
import withStyles from '@mui/styles/withStyles'
import CircularProgress from '@mui/material/CircularProgress'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Assignment, ColorLensOutlined, MoreHoriz } from '@mui/icons-material'
import cx from 'classnames'
import { /*useNavigate*/ useHistory } from 'react-router-dom'
import LaunchIcon from '@mui/icons-material/Launch'
import FilterNoneIcon from '@mui/icons-material/FilterNone'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CardMedia from '@mui/material/CardMedia'
import { useTranslation } from 'react-i18next'
import { blue } from '@mui/material/colors'

import PopoverItem from '../popoverItem/PopoverItem'

import authorizationClient from '../../../services/authorizationClient'
import appClient from '../../../services/appClient'
import useOutsideClick from '../../../hooks/UseOutsideClick/useOutsideClick'
import WarningDialog from '../dialog/warningDialog/WarningDialog'
import showToastResponseError from '../../../methods/showToastResponseError'

const styles = (theme) => ({
	aspectRatioContainer: {
		height: 0,
		width: '100%',
		paddingTop: '100%',
		position: 'relative'
	},
	card: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		borderRadius: 16,
		'&:hover': {
			boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.17)'
		},
		cursor: 'pointer'
	},
	cardHeader: {
		padding: '0 10px',
		height: '20%'
	},
	contentContainer: {
		padding: 0,
		display: 'flex',
		flexWrap: 'wrap',
		height: '100%',
		flexDirection: 'column',
		alignItems: 'center'
	},
	media: {
		height: 0,
		paddingTop: '56.25%' // 16:9
	},
	actions: {
		display: 'flex'
	},
	expand: {
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		}),
		marginLeft: 'auto',
		[theme.breakpoints.up('sm')]: {
			marginRight: -8
		}
	},
	span: {
		backgroundColor: '#56d87b',
		color: 'white',
		padding: '1px 5px',
		borderRadius: '10px',
		fontSize: 9,
		fontWeight: 'bold'
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	},
	avatar: {
		backgroundColor: blue[500]
	},
	rounded: {
		backgroundColor: blue[500],
		height: '37.5%',
		width: '33%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 8
	},
	icon: {
		width: '50%',
		height: 'auto',
		color: '#ffffff'
	},
	icon2: {
		width: '100%',
		height: 'auto',
		borderRadius: 8
	},
	text: {
		width: '100%',
		fontWeight: 600,
		color: theme.custom.colors.textPrimary
	},
	primaryText: {
		marginTop: '11%',
		fontSize: 14
	},
	secondaryText: {
		opacity: 0.62,
		fontSize: 12
	},
	tilePopover: {
		position: 'absolute',
		top: '15%',
		left: '5%',
		width: '90%',
		backgroundColor: '#FFFFFF',
		borderRadius: '4px',
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.17)',
		padding: '5%',
		zIndex: 1
	},
	popoverIcon: {
		color: '#000000',
		opacity: 0.3,
		width: '14px'
	},
	expandButton: {
		margin: '12px 8px',
		borderRadius: '4px',
		width: '30px',
		height: '10px'
	}
})

const Tile = (props) => {
	const { t } = useTranslation()
	const [expanded, setExpanded] = useState(false)
	const [dialogVisible, setDialogVisible] = useState(false)
	const history = useHistory()
	const navigate = (url) => {
		history.push(url)
	} //let navigate = useNavigate()

	const [fetching, setFetching] = useState(false)

	const actionsPopover = useRef(null)
	useOutsideClick(actionsPopover, () => {
		setExpanded(false)
	})

	const { classes } = props

	const copyApp = () => {
		if (props.data?.Id) {
			setFetching(true)

			appClient
				.copy(props.data.Id)
				.then(async (response) => {
					if (response?.data?.success) {
						await authorizationClient.refreshTokenAndUserInfo()

						response?.data?.data?.Id &&
							navigate(
								props.linkUrl.replace(':appId', response?.data?.data?.Id)
							)
					} else {
						showToastResponseError(response)
					}
					setFetching(false)
				})
				.catch((error) => {
					setFetching(false)
					showToastResponseError(error.response)
				})
		}
	}

	const deleteApp = () => {
		if (props.data?.Id) {
			appClient.delete(props.data.Id).then((response) => {
				if (response?.data?.success) {
					handleDialogClose()
					setExpanded(false)
					props?.reload && props.reload()
				} else {
					showToastResponseError(response)
				}
			})
		}
	}

	const handleDialogClose = () => {
		setDialogVisible(false)
	}

	const redirect = (url) => {
		window.location.href = url
	}

	const getDetailLink = () => {
		return props.data?.linkUrl
			? `${props.data.linkUrl}`
			: props.linkUrl.replace(':appId', props.data?.Id)
	}

	const click = () => {
		if (props.data?.click) {
			props.data.click()
		} else {
			props.data?.linkUrl
				? redirect(`${props.data.linkUrl}`)
				: props?.linkUrl &&
				  navigate(props.linkUrl.replace(':appId', props.data?.Id))
		}
	}

	return (
		<>
			<div className={classes.aspectRatioContainer} onClick={() => click()}>
				<Card
					elevation={0}
					className={classes.card}
					style={{
						backgroundColor: props.data?.cardColor
							? props.data.cardColor
							: '#ffffff'
					}}
				>
					<CardHeader
						className={classes.cardHeader}
						avatar={
							props.data?.Published ? (
								<Typography className={classes.span}>
									{props.data?.publishedText
										? props.data.publishedText
										: t('tile.published')}
								</Typography>
							) : null
						}
						action={
							props?.isInteractive ? (
								<IconButton
									className={classes.expandButton}
									style={{ backgroundColor: expanded && '#F0F0F4' }}
									onClick={(e) => {
										e.stopPropagation()
										setExpanded(!expanded)
									}}
									size='large'
								>
									<MoreHoriz />
								</IconButton>
							) : null
						}
					/>
					<CardContent className={classes.contentContainer}>
						{props.data?.Icon ? (
							typeof props.data.Icon == 'string' ? (
								<div
									className={classes.rounded}
									style={{ backgroundColor: 'unset' }}
								>
									<CardMedia
										image={props.data.Icon}
										className={classes.icon2}
										// className={classes.icon}
										component='img'
									/>
								</div>
							) : (
								<div
									className={classes.rounded}
									style={{ backgroundColor: props.data?.AccentColor }}
								>
									<props.data.Icon className={classes.icon} />
								</div>
							)
						) : (
							<div
								className={classes.rounded}
								style={{ backgroundColor: props.data?.AccentColor }}
							>
								<Assignment className={classes.icon} />
							</div>
						)}
						{props.data?.Name && (
							<Typography className={cx(classes.text, classes.primaryText)}>
								{props.data.Name}
							</Typography>
						)}

						{!props.data?.IsPublic && (
							<Typography className={cx(classes.text, classes.secondaryText)}>
								{t('tile.private')}
							</Typography>
						)}
					</CardContent>
				</Card>
				{expanded && (
					<div
						ref={actionsPopover}
						className={classes.tilePopover}
						onClick={(e) => {
							e.stopPropagation()
						}}
					>
						<PopoverItem
							text={t('tile.openApp')}
							linkTo={() => getDetailLink()}
							color='#000000'
						>
							<LaunchIcon className={classes.popoverIcon} />
						</PopoverItem>
						<PopoverItem
							text={t('tile.copy')}
							onClick={copyApp}
							color='#000000'
						>
							{fetching ? (
								<CircularProgress
									size={14}
									sx={{
										color: '#000000'
									}}
								/>
							) : (
								<FilterNoneIcon className={classes.popoverIcon} />
							)}
						</PopoverItem>
						<PopoverItem
							text={t('tile.delete')}
							color='#FF2619'
							onClick={() => setDialogVisible(true)}
						>
							<DeleteOutlineIcon
								className={classes.popoverIcon}
								style={{ color: '#FF2619', opacity: 1 }}
							/>
						</PopoverItem>
					</div>
				)}
			</div>
			<WarningDialog
				dialogVisible={dialogVisible}
				dialogClose={handleDialogClose}
				dialogAccept={deleteApp}
				dialogTitle={t('tile.modal.dialogTitle')}
				title={null}
				text={t('tile.modal.text')}
				dialogCancelText={t('tile.modal.closeButton')}
				dialogAcceptText={t('tile.modal.acceptButton')}
			/>
		</>
	)
}

export default withStyles(styles)(Tile)

import React from 'react'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import cx from 'classnames'

const useStyles = makeStyles((theme) => ({
	text: {
		paddingLeft: 5,
		color: '#000000',
		fontSize: '12px',
		fontWeight: 600
	},
	buttonContainer: {
		cursor: 'pointer',
		borderRadius: 4,
		height: 30,
		'&:hover': {
			backgroundColor: theme.custom.colors.gray
		},
		padding: '0 5px'
	}
}))

const PopoverButton = (props) => {
	const classes = useStyles()

	return (
		<Box
			display='flex'
			alignItems='center'
			justifyContent='start'
			className={cx(classes.buttonContainer)}
			{...props}
		>
			{props?.children}
			<Typography className={classes.text} style={{ color: props?.color }}>
				{props?.text}{' '}
			</Typography>
		</Box>
	)
}

export default PopoverButton

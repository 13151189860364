import filterOperators from '../common/filterOperators'

const getFilterOpByDataType = (dataType) => {
	const filterOpsIndex = filterOperators.findIndex((x) =>
		x.dataTypes.includes(dataType)
	)

	return filterOpsIndex >= 0 ? filterOperators[filterOpsIndex] : null
}

export default getFilterOpByDataType

import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import {
	SwapVert,
	BrowserNotSupported,
	FilterAltOutlined,
	PlusOne
} from '@mui/icons-material'
import { ChromePicker } from 'react-color'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateText from '../../../molecules/doubleStateField/DoubleStateText'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'
import colorpicker from '../../../../assets/images/colorpicker.png'
import RadioButton from '../../../atoms/radioButton/RadioButton'
import useOutsideClick from '../../../../hooks/UseOutsideClick/useOutsideClick'

import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import relationTypes from '../../../../common/enums/relationTypes'
import validateWithRegExp from '../../../../methods/validateWithRegExp'
import timeoutDelay from '../../../../methods/timeoutDelay'
import dataTypeRegExes from '../../../../common/regExp/dataType'
import formulas from '../../../../common/formulas'

import { ReactComponent as Koseli } from '../../../../assets/icons/Settings/Koseli.svg'
import { ReactComponent as Oval } from '../../../../assets/icons/Settings/Oval.svg'
import { ReactComponent as XSmall } from '../../../../assets/icons/Settings/TileExtraSmall.svg'
import { ReactComponent as Small } from '../../../../assets/icons/Settings/TileSmall.svg'
import { ReactComponent as Medium } from '../../../../assets/icons/Settings/TileMedium.svg'
import { ReactComponent as Large } from '../../../../assets/icons/Settings/TileLarge.svg'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	inputLight: {
		borderRadius: 3,
		backgroundColor: '#ffffff',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const Container = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [elementName, setElementName] = useState(null)

	const imageFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen?.data?.source,
		['Image'],
		null,
		true,
		relationTypes.one
	)
	const imageOptions = imageFields?.length ? imageFields : formulas.profile
	const imageOptionsWithEmpty = [{ label: '-', name: '' }, ...imageOptions]

	const sizeOptions = [
		{
			text: t('screens.elementSettings.tileList.sizeOpts.xs'),
			value: 'xs',
			icon: XSmall
		},
		{
			text: t('screens.elementSettings.tileList.sizeOpts.sm'),
			value: 'sm',
			icon: Small
		},
		{
			text: t('screens.elementSettings.tileList.sizeOpts.md'),
			value: 'md',
			icon: Medium
		},
		{
			text: t('screens.elementSettings.tileList.sizeOpts.lg'),
			value: 'lg',
			icon: Large
		}
	]
	const backgroundOptions = [
		{
			text: t('screens.elementSettings.chart.backgroundOpts.none'),
			value: 'none',
			icon: BrowserNotSupported
		},
		{
			text: t('screens.elementSettings.chart.backgroundOpts.square'),
			value: 'square',
			icon: Koseli
		},
		{
			text: t('screens.elementSettings.chart.backgroundOpts.oval'),
			value: 'oval',
			icon: Oval
		}
	]

	/****BACKGROUND***************************/
	const [background, setBackground] = useState('none')
	const backgroundChange = (val) => {
		submitBackground(val)
		setBackground(val)
	}
	const submitBackground = (x) => {
		const modifiedElementData = update(props.elementData, {
			background: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****SIZE***************************/
	const [size, setSize] = useState(null)

	const sizeChange = (val) => {
		submitSize(val)
		setSize(val)
	}

	const submitSize = (x) => {
		const modifiedElementData = update(props.elementData, {
			size: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****BACKGROUND_COLOR***************************/
	const colorList = [
		{
			color: '#E22345',
			name: '#E22345'
		},
		{
			color: '#F25757',
			name: '#F25757'
		},
		{
			color: '#F27777',
			name: '#F27777'
		},
		{
			color: '#D98841',
			name: '#D98841'
		},
		{
			color: '#D9A768',
			name: '#D9A768'
		},
		{
			color: '#F2C84C',
			name: '#F2C84C'
		},
		{
			color: '#9DA658',
			name: '#9DA658'
		},
		{
			color: '#1EBF92',
			name: '#1EBF92'
		},
		{
			color: '#2E8C6A',
			name: '#2E8C6A'
		},
		{
			color: '#06AFF2',
			name: '#06AFF2'
		},
		{
			color: '#0440F2',
			name: '#0440F2'
		},
		{
			color: '#A673D9',
			name: '#A673D9'
		}
	]

	const [backgroundColor, setBackgroundColor] = useState('')
	const [backgroundColorIndex, setBackgroundColorIndex] = useState(0)
	const [backgroundCustomColor, setBackgroundCustomColor] = useState('#BB0101')
	const [backgroundColorPickerVisible, setBackgroundColorPickerVisible] =
		useState(false)

	const submitBackgroundColor = (x) => {
		const modifiedElementData = update(props.elementData, {
			backgroundColor: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	const backgroundColorPickerRef = useRef(null)

	useOutsideClick(backgroundColorPickerRef, () => {
		setBackgroundColorPickerVisible(false)
	})

	/****BACKGROUND_IMAGE***************************/
	const [isBackgroundImageUrlTextMode, setIsBackgroundImageUrlTextMode] =
		useState(false)
	const [backgroundImageUrlField, setBackgroundImageUrlField] = useState('')
	const [backgroundImageUrlDefault, setBackgroundImageUrlDefault] = useState('')
	const [backgroundImageUrlDefaultValid, setBackgroundImageUrlDefaultValid] =
		useState(true)

	const backgroundImageUrlChange = (data) => {
		if (isBackgroundImageUrlTextMode) {
			const backgroundImageUrlRegExp = dataTypeRegExes.Image.re

			validateWithRegExp(data.target.value, backgroundImageUrlRegExp).then(
				(isValid) => {
					setBackgroundImageUrlDefaultValid(isValid)
					if (isValid) {
						timeoutDelay(submitBackgroundImageUrl, data.target.value, 1000)
					}
				}
			)

			setBackgroundImageUrlDefault(data.target.value)
		} else {
			submitBackgroundImageUrl(data?.value)
			setBackgroundImageUrlField(data?.value)
		}
	}
	const submitBackgroundImageUrl = (x) => {
		const selectedField = imageOptions?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				backgroundImageUrl: {
					field: { $set: isBackgroundImageUrlTextMode ? null : x },
					default: { $set: isBackgroundImageUrlTextMode ? x : null },
					type: {
						$set: isBackgroundImageUrlTextMode ? null : selectedField?.dataType
					}
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****DESIGN***************************/
		setBackground(elementData?.background ?? 'none')
		setSize(elementData?.size)

		/****BACKGROUND_COLOR***************************/
		const tempColorIndex = colorList.indexOf(
			colorList.filter((x) => x.color == elementData?.backgroundColor)[0]
		)
		if (tempColorIndex > -1) {
			setBackgroundColorIndex(tempColorIndex)
			setBackgroundColor(colorList[tempColorIndex].color)
		} else {
			if (elementData?.backgroundColor) {
				setBackgroundColorIndex('custom')
				setBackgroundColor(elementData?.backgroundColor)
				setBackgroundCustomColor(elementData?.backgroundColor)
			} else {
				setBackgroundColorIndex('none')
				setBackgroundColor(null)
			}
		}

		/****BACKGROUND_IMAGE***************************/
		elementData?.mapping?.backgroundImageUrl?.field ||
		elementData?.mapping?.backgroundImageUrl?.field === ''
			? setIsBackgroundImageUrlTextMode(false)
			: setIsBackgroundImageUrlTextMode(true)
		setBackgroundImageUrlField(
			elementData?.mapping?.backgroundImageUrl?.field || ''
		)
		setBackgroundImageUrlDefault(
			elementData?.mapping?.backgroundImageUrl?.default || ''
		)

		if (!elementData?.mapping) {
			const modifiedElementData = update(props.elementData, {
				mapping: {
					$set: {
						backgroundImageUrl: {
							field: null,
							default: null,
							type: null
						}
					}
				}
			})
			props.updateSelectedElement(modifiedElementData)
		}

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
		}
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.container.size')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={sizeOptions}
								getChange={sizeChange}
								activeValue={size}
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>
								{t('screens.elementSettings.container.backgroundShape')}
							</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={backgroundOptions}
								getChange={backgroundChange}
								activeValue={background}
							/>
						</Grid>
					</Grid>
					{background !== 'none' && (
						<>
							<Grid
								container
								display={'flex'}
								className={classes.mb10}
								style={{
									justifyContent: 'space-between',
									alignItems: 'center'
								}}
							>
								<Grid item xs={12} lg={12} className={classes.mb10}>
									<Label>
										{t('screens.elementSettings.container.backgroundColor')}
									</Label>
								</Grid>
								<Grid
									item
									container
									xs={12}
									lg={12}
									display='flex'
									alignItems='center'
									justifyContent={'flex-end'}
									style={{ position: 'relative' }}
								>
									<RadioButton
										className={classes.radio}
										checked={'none' == backgroundColorIndex}
										onClick={() => {
											setBackgroundColorIndex('none')
											submitBackgroundColor(null)
										}}
										icon={BrowserNotSupported}
									/>
									{colorList.map((item, index) => (
										<RadioButton
											className={classes.radio}
											checked={index == backgroundColorIndex}
											key={index}
											onClick={() => {
												setBackgroundColor(item.color)
												setBackgroundColorIndex(index)
												submitBackgroundColor(item.color)
											}}
											radioColor={item?.color}
											imgUrl={item?.imgUrl}
										/>
									))}
									<RadioButton
										className={classes.radio}
										checked={'custom' == backgroundColorIndex}
										onClick={() => {
											setBackgroundColorPickerVisible(true)
										}}
										imgUrl={colorpicker}
									/>
									{backgroundColorPickerVisible && (
										<div
											ref={backgroundColorPickerRef}
											className={classes.pickerPopover}
										>
											<ChromePicker
												disableAlpha
												color={backgroundColor ? backgroundColor : '#000000'}
												onChangeComplete={(color) => {
													setBackgroundColor(color.hex)
													setBackgroundColorIndex('custom')
													setBackgroundCustomColor(color.hex)
													submitBackgroundColor(color.hex)
												}}
											/>
										</div>
									)}
								</Grid>
							</Grid>
							<Grid container alignItems='center' className={classes.mb10}>
								<Grid item xs={12} className={classes.mb10}>
									<Label>
										{t('screens.elementSettings.container.backgroundImage')}
									</Label>
								</Grid>
								<Grid item xs={12}>
									<DoubleStateText
										fullWidth={true}
										textError={!backgroundImageUrlDefaultValid}
										isTextMode={isBackgroundImageUrlTextMode}
										setIsTextMode={setIsBackgroundImageUrlTextMode}
										valChange={backgroundImageUrlChange}
										textVal={backgroundImageUrlDefault}
										autoCompVal={backgroundImageUrlField}
										autoCompOptions={imageOptionsWithEmpty}
										uppyPicker
										uppyAllowedFileTypes={['image/*']}
										valueKey='name'
										labelKey='label'
									/>
								</Grid>
							</Grid>
						</>
					)}
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default Container

import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Box, Popover, Typography, Button } from '@mui/material'
import {
	MoreVert,
	Add,
	AddBox,
	Delete,
	DeleteOutlined,
	DragIndicator
} from '@mui/icons-material'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import TextButton from '../../atoms/button/TextButton'

import PopoverItem from '../popoverItem/PopoverItem'
import WarningDialog from '../dialog/warningDialog/WarningDialog'
import elementNames from '../../../common/enums/elementNames'

const getListStyle = (isDraggingOver) => ({
	background: 'transparent',
	borderRadius: 6,
	width: '100%'
})

const getElementStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	margin: `0 0 5px 0`,
	borderRadius: 6,
	boxShadow: isDragging ? '0 4px 8px 0 rgba(0, 0, 0, 0.17)' : 'none',
	overflow: isDragging ? 'hidden' : 'visible',

	...draggableStyle
})

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	mt10: {
		marginTop: 10
	},
	menuItem: {
		border: (props) =>
			props.element?.component === 'container'
				? `1px dashed ${theme.custom.colors.middleGray}`
				: 0,
		cursor: 'move',
		textDecoration: 'none !important',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		borderRadius: 6,
		justifyContent: 'center',
		backgroundColor: '#ffffff'
	},
	menuItemGroup: {
		width: '100%',
		minHeight: 30,
		// paddingLeft: 10,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderRadius: 6,
		'&:hover': {
			backgroundColor: theme.custom.colors.kozmikGray,
			'& $actionsContainer': {
				visibility: 'visible'
			}
		}
	},
	active: {
		backgroundColor: `${theme.custom.colors.kozmikLightBlue} !important`,
		borderBottomLeftRadius: (props) =>
			props.element?.component === 'container' ? 0 : 6,
		borderBottomRightRadius: (props) =>
			props.element?.component === 'container' ? 0 : 6,
		'& $text': {
			color: theme.custom.colors.kozmikBlue,
			fontWeight: '600'
		},
		'& $actionsContainer': {
			visibility: 'visible'
		}
	},
	text: {
		flex: 1,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		fontSize: 14,
		color: theme.custom.colors.textPrimary,
		textAlign: 'left'
	},
	menuGroupItems: {
		borderTop: `1px dashed ${theme.custom.colors.middleGray}`,
		borderBottom: `1px dashed ${theme.custom.colors.middleGray}`,
		width: '100%',
		display: 'flex',
		padding: 5,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start'
	},
	actionsContainer: {
		display: 'flex',
		visibility: 'hidden'
	},
	deleteElement: {
		marginRight: 5,
		fontSize: 20,
		color: theme.custom.colors.disabledPrimary,
		cursor: 'pointer',
		'&:hover': {
			color: theme.custom.colors.textPrimary
		}
	},
	addElement: {
		width: '100%',
		paddingTop: 7,
		paddingBottom: 2,
		paddingLeft: 10
	},
	visible: {
		visibility: 'visible'
	},
	dragIcon: {
		marginLeft: 5,
		marginRight: 5,
		fontSize: 16,
		color: theme.custom.colors.disabledPrimary
	}
}))

const ElementListItem = (props) => {
	const { t } = useTranslation()
	const {
		activeScreen,
		activeElement,
		parentElement,
		element,
		setElements,
		setRightContentType,
		setActiveParentElement,
		setActiveElement,
		setIsDragDisabled,
		scrollToPreviewElement,
		deleteElement,
		builderOnMobile,
		setAddElementModalVisible,
		...restProps
	} = props
	const classes = useStyles({ element })

	const elementList = element.component === 'container' ? element.body : []
	const isActive = activeElement?._uid === element._uid

	const onDragEnd = (result) => {
		const { destination } = result

		if (!destination) {
			return
		}

		const elements = reorder(
			elementList,
			result.source.index,
			result.destination.index
		)

		const index = activeScreen.data?.body?.findIndex(
			(x) => x._uid == element?._uid
		)

		const modifiedElementData = update(element, {
			body: {
				$set: elements
			}
		})

		const modifiedScreenData = update(activeScreen.data, {
			body: {
				[index]: {
					$set: modifiedElementData
				}
			}
		})

		setElements(
			modifiedScreenData,
			activeScreen.screenGroup,
			modifiedScreenData?.body
		)
	}

	return (
		<div key={`element-list-item-${element._uid}`} {...restProps}>
			<div className={cx(classes.menuItem)}>
				<div
					className={cx(classes.menuItemGroup, isActive && classes.active)}
					onClick={(e) => {
						e.stopPropagation()

						setActiveParentElement(parentElement)
						setActiveElement(element)

						!builderOnMobile &&
							scrollToPreviewElement &&
							scrollToPreviewElement(element._uid)
					}}
					onMouseEnter={() => {
						if (parentElement) {
							setIsDragDisabled && setIsDragDisabled(true)
						}
					}}
					onMouseLeave={() => {
						if (parentElement) {
							setIsDragDisabled && setIsDragDisabled(false)
						}
					}}
				>
					<DragIndicator className={classes.dragIcon} />

					<Typography className={classes.text}>
						{elementNames?.[element?.component] || element?.component}
					</Typography>

					<div
						className={cx(
							builderOnMobile
								? [classes.actionsContainer, classes.visible]
								: [classes.actionsContainer]
						)}
					>
						<DeleteOutlined
							onClick={(e) => {
								e.stopPropagation()

								deleteElement(element, parentElement)
							}}
							className={classes.deleteElement}
						/>
					</div>
				</div>

				{!!elementList?.length && (
					<div className={classes.menuGroupItems}>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId='droppable'>
								{(provided, snapshot) => (
									<div
										{...provided.droppableProps}
										ref={provided.innerRef}
										style={getListStyle(snapshot.isDraggingOver)}
									>
										{!!props?.emptyText && !elementList?.length ? (
											<span style={{ fontSize: 12, fontStyle: 'italic' }}>
												{props.emptyText}
											</span>
										) : null}
										{elementList.map((item, index) => (
											<Draggable
												key={item._uid}
												draggableId={item._uid}
												index={index}
											>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														style={getElementStyle(
															snapshot.isDragging,
															provided.draggableProps.style
														)}
													>
														<ElementListItem
															parentElement={element}
															element={item}
															activeScreen={activeScreen}
															activeElement={activeElement}
															setActiveElement={setActiveElement}
															setActiveParentElement={setActiveParentElement}
															deleteElement={deleteElement}
															setRightContentType={setRightContentType}
															setElements={setElements}
															setIsDragDisabled={setIsDragDisabled}
															builderOnMobile={builderOnMobile}
															setAddElementModalVisible={
																setAddElementModalVisible
															}
														/>
													</div>
												)}
											</Draggable>
										))}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</div>
				)}
				{element?.component === 'container' && (
					<div className={classes.addElement}>
						<TextButton
							onClick={() => {
								setActiveParentElement(element)

								builderOnMobile
									? setAddElementModalVisible(true)
									: setRightContentType('newContainerElement')
							}}
							color={'#464646'}
							icon={AddBox}
							iconSize={16}
							fontSize={12}
							text={t('screens.elementListItem.addElement')}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default ElementListItem

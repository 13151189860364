import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateText from '../../../molecules/doubleStateField/DoubleStateText'
import DoubleStateMentions from '../../../molecules/doubleStateField/DoubleStateMentions'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import validateWithRegExp from '../../../../methods/validateWithRegExp'
import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import dataTypeRegExes from '../../../../common/regExp/dataType'
import relationTypes from '../../../../common/enums/relationTypes'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const LinkButton = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const propsRef = useRef()
	propsRef.current = props

	const [elementName, setElementName] = useState(null)

	const linkFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		['URL', 'File', 'Image', 'Text'],
		null,
		true,
		relationTypes.one
	)
	const linkOptions = linkFields?.length ? linkFields : []

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		fieldDataTypes.textBased,
		null,
		true,
		relationTypes.one
	)
	const textOptions = textFields?.length ? textFields : []
	const textOptionsWithEmpty = [{ label: '-', name: '' }, ...textOptions]

	/****ADDRESS***************************/
	const [isLinkTextMode, setIsLinkTextMode] = useState(false)
	const [linkField, setLinkField] = useState('')
	const [linkDefault, setLinkDefault] = useState('')
	const [linkDefaultValid, setLinkDefaultValid] = useState(true)

	const linkChange = (data) => {
		if (isLinkTextMode) {
			const linkRegExp = dataTypeRegExes.URL.re

			validateWithRegExp(data.target.value, linkRegExp).then((isValid) => {
				setLinkDefaultValid(isValid)
				if (isValid) {
					timeoutDelay(submitLink, data.target.value, 1000)
				}
			})

			setLinkDefault(data.target.value)
		} else {
			submitLink(data?.value)
			setLinkField(data?.value)
		}
	}
	const submitLink = (x) => {
		const selectedField = linkFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				address: {
					field: { $set: isLinkTextMode ? null : x },
					default: { $set: isLinkTextMode ? x : null },
					type: { $set: isLinkTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****TITLE***************************/
	const [isTitleTextMode, setIsTitleTextMode] = useState(false)
	const [titleField, setTitleField] = useState('')
	const [titleDefault, setTitleDefault] = useState('')

	const titleChange = (data) => {
		if (isTitleTextMode) {
			submitTitle(data)
			setTitleDefault(data)
		} else {
			submitTitle(data?.value)
			setTitleField(data?.value)
		}
	}
	const submitTitle = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				title: {
					field: { $set: isTitleTextMode ? null : x },
					default: { $set: isTitleTextMode ? x : null },
					type: { $set: isTitleTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****LABEL***************************/
	const [isLabelTextMode, setIsLabelTextMode] = useState(false)
	const [labelField, setLabelField] = useState('')
	const [labelDefault, setLabelDefault] = useState('')

	const labelChange = (data) => {
		if (isLabelTextMode) {
			submitLabel(data)
			setLabelDefault(data)
		} else {
			submitLabel(data?.value)
			setLabelField(data?.value)
		}
	}
	const submitLabel = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				label: {
					field: { $set: isLabelTextMode ? null : x },
					default: { $set: isLabelTextMode ? x : null },
					type: { $set: isLabelTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****ADDRESS***************************/
		elementData?.mapping?.address?.field ||
		elementData?.mapping?.address?.field === ''
			? setIsLinkTextMode(false)
			: setIsLinkTextMode(true)

		setLinkField(elementData?.mapping?.address?.field || '')
		setLinkDefault(elementData?.mapping?.address?.default || '')

		/****TITLE***************************/
		elementData?.mapping?.title?.field ||
		elementData?.mapping?.title?.field == ''
			? setIsTitleTextMode(false)
			: setIsTitleTextMode(true)

		setTitleField(elementData?.mapping?.title?.field || '')
		setTitleDefault(elementData?.mapping?.title?.default || '')

		/****LABEL***************************/
		elementData?.mapping?.label?.field ||
		elementData?.mapping?.label?.field === ''
			? setIsLabelTextMode(false)
			: setIsLabelTextMode(true)

		setLabelField(elementData?.mapping?.label?.field || '')
		setLabelDefault(elementData?.mapping?.label?.default || '')

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		props?.elementData && initStates(props.elementData)
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.linkButton.link')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateText
								fullWidth={true}
								textError={!linkDefaultValid}
								isTextMode={isLinkTextMode}
								setIsTextMode={setIsLinkTextMode}
								valChange={linkChange}
								textVal={linkDefault}
								autoCompVal={linkField}
								autoCompOptions={linkOptions}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					{/* <Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.linkButton.label')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateMentions
								name={`${elementName}-label`}
								multiline={false}
								fullWidth={true}
								isTextMode={isLabelTextMode}
								setIsTextMode={setIsLabelTextMode}
								valChange={labelChange}
								textVal={labelDefault}
								autoCompVal={labelField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid> */}
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.linkButton.title')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateMentions
								name={`${elementName}-title`}
								multiline={false}
								fullWidth={true}
								isTextMode={isTitleTextMode}
								setIsTextMode={setIsTitleTextMode}
								valChange={titleChange}
								textVal={titleDefault}
								autoCompVal={titleField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default LinkButton

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

import { connect } from 'react-redux'
import { setOnboarding } from '../../../redux/actions/onboardingActions'

import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie'
import {
	CardMedia,
	TextField,
	Button,
	CircularProgress,
	Typography
} from '@mui/material'

import authorizationClient from '../../../services/authorizationClient'
import tokenServices from '../../../services/tokenServices'
import { default as appConfig } from '../../../config/app.json'
import showToastResponseError from '../../../methods/showToastResponseError'
import useQuery from '../../../hooks/UseQuery/useQuery'

import KozmikLogoLight from '../../../assets/icons/KozmikLogoLight.png'

import commonRegExes from '../../../common/regExp/commonRegExes'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		padding: '0px 27px'
	},
	contentContainer: {
		marginTop: '20vh',
		maxWidth: 374,
		width: 374,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('md')]: {
			marginTop: '5vh'
		}
	},
	titleBox: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 40,
		padding: '0px 20px'
	},
	title: {
		textAlign: 'center',
		fontSize: 30,
		marginBottom: 20,
		color: '#ffffff'
	},
	subTitle: {
		textAlign: 'center',
		fontSize: 14,
		color: '#ffffff'
	},
	inputContainer: {
		marginBottom: 20
	},
	buttonContainer: {
		marginTop: 20
	},
	bottomText: {
		marginTop: 20,
		textAlign: 'center',
		fontSize: 14,
		alignContent: 'center',
		color: '#ffffff'
	},
	greenLink: {
		color: theme.custom.colors.kozmikGreen,
		cursor: 'pointer',
		fontWeight: 'bold'
	},
	logoBox: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 80
	},
	logo: {
		width: 163,
		height: 'auto'
	},
	input: {
		'& .MuiOutlinedInput-root': {
			overflow: 'hidden',
			height: 50,
			backgroundColor: 'rgba(255, 255, 255, 0.6)',
			borderRadius: 8,
			fontSize: 14,
			fontWeight: 600
		}
	},
	button: {
		borderRadius: 8,
		boxShadow: 'none !important',
		textTransform: 'unset',
		height: 50,
		backgroundColor: `${theme.custom.colors.kozmikGreen} !important`
	},
	textBox: {
		marginTop: 20,
		marginLeft: 14,
		color: '#ffffff',
		fontSize: 12
	},
	underline: {
		color: '#ffffff',
		textDecoration: 'underline'
	},
	submitText: {
		fontSize: 16,
		fontWeight: 600,
		color: theme.custom.colors.kozmikDarkBlue
	},
	loader: {
		margin: '20px',
		color: theme.custom.colors.kozmikDarkBlue
	}
}))

const Signup = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	let queryParams = useQuery()

	const [submitting, setSubmitting] = useState(false)

	const cookies = new Cookies()
	const history = useHistory()
	const navigate = (url) => {
		history.push(url)
	}
	const token = cookies.get(appConfig.cookieKeys.MY_SECURE_TOKEN_KEY)

	const {
		control,
		handleSubmit,
		formState: { errors, isValid }
	} = useForm({
		defaultValues: {
			name: '',
			email: '',
			password: ''
		}
	})
	const onSubmit = (data) => {
		setSubmitting(true)
		authorizationClient
			.register(data)
			.then((response) => {
				if (response?.data?.success) {
					tokenServices.add(response.data.data)
					if (queryParams?.plan && queryParams?.type) {
						//TODO: subscription
					} else {
						props.setOnboarding(true)

						history.replace('/onboarding')
					}
				} else {
					showToastResponseError(response)
				}
				setSubmitting(false)
			})
			.catch(() => {
				setSubmitting(false)
			})
	}

	const handleUserKeyPress = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			handleSubmit(onSubmit)()
		}
	}

	useEffect(() => {
		if (token && token !== 'undefined') {
			history.replace('')
		}
	}, [])

	return (
		<div className={classes.mainContainer}>
			<div className={classes.contentContainer}>
				{/* <div className={classes.logoBox}>
					<a href='https://kozmik.io'>
						<CardMedia
							image={KozmikLogoLight}
							component='img'
							className={classes.logo}
						/>
					</a>
				</div> */}
				<div className={classes.titleBox}>
					<span className={classes.title}>
						Sign up for free and start building
					</span>
					<span className={classes.subTitle}>No credit card required</span>
				</div>
				<div className={classes.inputContainer}>
					<Controller
						control={control}
						name='name'
						rules={{
							required: {
								value: true,
								message: t('authScreens.signup.nameInput.requredError')
							}
						}}
						render={({ field: { onChange, value } }) => {
							return (
								<TextField
									className={classes.input}
									error={!!errors?.name}
									helperText={errors?.name ? errors?.name?.message : ''}
									placeholder={t('authScreens.signup.nameInput.placeholder')}
									fullWidth
									value={value}
									onChange={(e) => onChange(e.target.value)}
									onKeyPress={handleUserKeyPress}
									inputProps={{
										autoComplete: 'off'
									}}
								/>
							)
						}}
					/>
				</div>
				<div className={classes.inputContainer}>
					<Controller
						control={control}
						name='email'
						rules={{
							required: {
								value: true,
								message: t('authScreens.signup.mailInput.requredError')
							},
							pattern: {
								value:
									/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: t('authScreens.signup.mailInput.patternError')
							}
						}}
						render={({ field: { onChange, value } }) => {
							return (
								<TextField
									className={classes.input}
									error={!!errors?.email}
									helperText={errors?.email ? errors?.email?.message : ''}
									placeholder={t('authScreens.signup.mailInput.placeholder')}
									fullWidth
									value={value}
									onChange={(e) => onChange(e.target.value)}
									onKeyPress={handleUserKeyPress}
									inputProps={{
										autoComplete: 'off'
									}}
								/>
							)
						}}
					/>
				</div>
				<div className={classes.inputContainer}>
					<Controller
						control={control}
						name='password'
						rules={{
							required: {
								value: true,
								message: t('authScreens.signup.passwordInput.requredError')
							},
							pattern: {
								value: commonRegExes.password,
								message: t('authScreens.signup.passwordInput.patternError')
							}
						}}
						render={({ field: { onChange, value } }) => {
							return (
								<TextField
									className={classes.input}
									error={!!errors?.password}
									helperText={errors?.password ? errors?.password?.message : ''}
									placeholder={t(
										'authScreens.signup.passwordInput.placeholder'
									)}
									fullWidth
									value={value}
									type='password'
									onChange={(e) => onChange(e.target.value)}
									onKeyPress={handleUserKeyPress}
									inputProps={{
										autoComplete: 'new-password'
									}}
								/>
							)
						}}
					/>
				</div>

				<div className={classes.textBox}>
					<span>{t('authScreens.signup.agreement')}</span>{' '}
					<a
						href='https://kozmik.io/legal/terms'
						target='_blank'
						rel='noreferrer'
						className={classes.underline}
					>
						{t('authScreens.signup.terms')}
					</a>{' '}
					&{' '}
					<a
						href='https://kozmik.io/legal/privacy'
						target='_blank'
						rel='noreferrer'
						className={classes.underline}
					>
						{t('authScreens.signup.privacyPolicy')}
					</a>
					.
				</div>

				<div className={classes.buttonContainer}>
					<Button
						id={'signUpButton'}
						fullWidth
						variant='contained'
						className={classes.button}
						onClick={handleSubmit(onSubmit)}
						disabled={submitting}
						sx={{
							opacity: submitting ? 0.6 : 1
						}}
					>
						<Typography className={classes.submitText}>
							{t('authScreens.signup.signupButton')}
						</Typography>
						{submitting && (
							<CircularProgress
								id={'signUpButtonProgress'}
								size={20}
								className={classes.loader}
							/>
						)}
					</Button>
				</div>

				<div className={classes.bottomText}>
					{t('authScreens.signup.haveAccount')}{' '}
					<span
						className={classes.greenLink}
						onClick={() => navigate('/login')}
					>
						{t('authScreens.signup.login')}
					</span>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	...state.onboarding
})

const mapDispatchToProps = (dispatch) => {
	return {
		setOnboarding: (x) => dispatch(setOnboarding(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup)

import update from 'immutability-helper'

import defaultElementSchemas from '../../defaultElementSchemas'
import Screen from '../../objects/Screen'
import i18next from '../../i18n/i18n'
import getNewDetailBody from './getNewDetailBody'
import generateScreenPathByName from './generateScreenPathByName'

const addRightButton = (screens, screenKey, targetScreenKey, icon) => {
	const targetScreen = screens.find((x) => x.key === targetScreenKey)
	const currentScreenIndex = screens.findIndex((x) => x.key === screenKey)

	if (targetScreen && currentScreenIndex >= 0) {
		screens = update(screens, {
			[currentScreenIndex]: {
				header: {
					buttons: {
						right: {
							$set: [
								{
									mapping: {
										text: {
											field: null,
											default: null,
											type: null
										}
									},
									icon: icon,
									actions: [
										{
											type: 'navigate',
											screenKey: targetScreen.key
										}
									]
								}
							]
						}
					}
				}
			}
		})
	}

	return screens
}

const getAutoGeneratedScreensByTable = (
	table,
	screens,
	linking,
	options = {}
) => {
	const allScreens = screens
		? [...screens.tabs, ...screens.drawers, ...screens.hiddens]
		: []

	let modifiedScreens = screens
	let newScreens = []
	let modifiedLinking = linking

	const defaultsOptions = {
		create: false,
		update: false,
		detail: false,
		list: false
	}
	let actualOptions = Object.assign({}, defaultsOptions, options)

	const screenPaths = Object.values(modifiedLinking.config.screens)
	const createPath = generateScreenPathByName(
		null,
		null,
		`${table.label} Create`,
		false
	)
	const updatePath = generateScreenPathByName(
		null,
		null,
		`${table.label} Update`,
		true
	)
	const detailPath = generateScreenPathByName(
		null,
		null,
		`${table.label} Detail`,
		true
	)

	let listScreenKey = null
	let createScreenKey = null
	let updateScreenKey = null
	let detailScreenKey = null

	/*****CREATE**********************************/
	if (
		actualOptions.create &&
		screenPaths.indexOf(`${createPath.generatedPath}`) < 0
	) {
		const createScreen = new Screen(
			[defaultElementSchemas.form(table, false)],
			`${table.label} Create`,
			null,
			{
				leftButtons: [],
				rightButtons: [],
				title: i18next.t('screens.bulkScreens.createTitle')
			},
			[],
			null
		)

		createScreenKey = createScreen.key
		newScreens.push(createScreen)
	}

	/*****UPDATE**********************************/
	if (
		actualOptions.update &&
		screenPaths.indexOf(`${updatePath.generatedPath}`) < 0
	) {
		const updateScreen = new Screen(
			[defaultElementSchemas.form(table, true)],
			`${table.label} Update`,
			null,
			{
				leftButtons: [],
				rightButtons: [],
				title: i18next.t('screens.bulkScreens.updateTitle')
			},
			[],
			table.name
		)

		updateScreenKey = updateScreen.key
		newScreens.push(updateScreen)
	}

	/*****DETAIL**********************************/
	if (
		actualOptions.detail &&
		screenPaths.indexOf(`${detailPath.generatedPath}`) < 0 &&
		!allScreens?.find((x) => x.source === table.name)
	) {
		const detailBody = getNewDetailBody(table)

		const detailScreen = new Screen(
			detailBody,
			`${table.label} Detail`,
			null,
			{
				leftButtons: [],
				rightButtons: [],
				title: i18next.t('screens.bulkScreens.detailTitle')
			},
			[],
			table.name
		)

		detailScreenKey = detailScreen.key
		newScreens.push(detailScreen)
	}

	if (actualOptions.list) {
		const actions = [
			{
				type: 'navigate',
				screenKey: detailScreenKey
			}
		]
		const mainListElement = defaultElementSchemas.list(table, false, actions)
		const listScreen = new Screen([mainListElement], table.label)

		listScreenKey = listScreen.key
		newScreens.push(listScreen)
	}

	newScreens.forEach((item) => {
		//NOTE:Setting  detail header right button actions
		if (actualOptions.create && item.key == listScreenKey) {
			newScreens = addRightButton(newScreens, item.key, createScreenKey, 'add')
		} else if (actualOptions.update && item.key == detailScreenKey) {
			newScreens = addRightButton(
				newScreens,
				item.key,
				updateScreenKey,
				'md-pencil-sharp'
			)
		}

		// Fixing screen keys and paths
		modifiedLinking = update(modifiedLinking, {
			config: {
				screens: {
					[item.key]: { $set: item.path }
				}
			}
		})
	})

	const tabs = newScreens.filter((x) => x.key === listScreenKey)
	const hiddens = newScreens.filter((x) => x.key !== listScreenKey)

	modifiedScreens = update(modifiedScreens, {
		tabs: {
			$push: tabs
		},
		hiddens: {
			$push: hiddens
		}
	})

	return {
		screens: modifiedScreens,
		linking: modifiedLinking
	}
}

export default getAutoGeneratedScreensByTable

import {
	LocalPhoneOutlined,
	CalendarMonthOutlined,
	AttachFile,
	TextFormat,
	Link,
	NumbersOutlined,
	LocationOn,
	Polyline,
	ManageSearch,
	Notes,
	CheckBoxOutlined,
	EmailOutlined,
	ImageOutlined,
	Functions
} from '@mui/icons-material'
import { ReactComponent as Rollup } from '../../../assets/icons/rollup.svg'
import i18next from '../../../i18n/i18n'

const externalData = [
	{
		icon: ManageSearch,
		label: i18next.t('appData.fieldDataTypes.lookup'),
		value: 'Lookup'
	},
	{
		icon: Rollup,
		label: i18next.t('appData.fieldDataTypes.rollup'),
		value: 'Rollup'
	},
	{
		icon: Functions,
		label: i18next.t('appData.fieldDataTypes.formula'),
		value: 'Formula'
	}
]

export default externalData

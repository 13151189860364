import { v4 as uuidv4 } from 'uuid'

import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../common/fieldDataTypes'
import languages from '../../i18n/languages'
import relationTypes from '../../common/enums/relationTypes'
import getArrayExcludingForbidArray from '../../methods/getArrayExcludingForbidArray'
import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'

import filterModal from './filterModal'

const method = (table, actions = null) => {
	const idFields = getEntityFieldsByDataTypes(null, null, ['RowId'], table)
	const geoLocationFields = getEntityFieldsByDataTypes(
		null,
		null,
		['GeoLocation'],
		table,
		true,
		relationTypes.one
	)
	const txtFields = getEntityFieldsByDataTypes(
		null,
		null,
		fieldDataTypes.textBased,
		table,
		true,
		relationTypes.one
	)
	const imgFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Image'],
		table,
		true,
		relationTypes.one
	)

	const filteredTxtFields = getArrayExcludingForbidArray(
		txtFields,
		'name',
		systemFieldNames.all
	)

	const idField = idFields?.[0] || null
	const titleField = txtFields?.find((x) => x.name == 'Name')
	const firstImgField = imgFields?.length ? imgFields[0] : null
	const firstGeoLocationField = geoLocationFields?.length
		? geoLocationFields[0]
		: null

	const object = {
		_uid: uuidv4(),
		component: 'mapList',
		source: table?.name,
		mapping: {
			id: {
				field: idField?.name || null,
				type: idField?.dataType || null
			},
			title: {
				field: titleField?.name,
				type: titleField?.dataType,
				default: null
			},
			text: {
				field: filteredTxtFields?.[0]?.name,
				type: filteredTxtFields?.[0]?.dataType,
				default: null
			},
			subText: {
				field: filteredTxtFields?.[1]?.name,
				type: filteredTxtFields?.[1]?.dataType,
				default: null
			},
			markerText: {
				field: filteredTxtFields?.[1]?.name,
				type: filteredTxtFields?.[1]?.dataType,
				default: null
			},
			imgUrl: {
				field: firstImgField?.name,
				type: firstImgField?.dataType,
				default: null
			},
			geoLocation: {
				field: firstGeoLocationField?.name,
				type: firstGeoLocationField?.dataType,
				default: null
			}
		},
		sorting: {
			field: titleField?.name || null,
			sort: 'asc'
		},
		filters: {
			groupOp: 'AND',
			rules: []
		},
		searcheable: false,
		searchPlaceholder: {
			default: languages.en.schemas.mapList.searchPlaceholder
		},
		filterModal: filterModal(),
		actions: actions ?? [
			{
				type: null
			}
		],
		limit: 100,
		seeAllUrl: null,
		seeAllText: {
			default: languages.en.schemas.mapList.seeAll
		},
		moreText: {
			default: languages.en.schemas.mapList.more
		},
		emptyState: {
			default: languages.en.schemas.mapList.emptyState
		},
		spacing: 'xl',
		markerIcon: 'location',
		zoom: '2x',
		appearance: 'rectangle',
		mapType: 'roadmap', // roadmap, satellite, hybrid
		scrollwheel: true,
		zoomControl: true
	}

	return object
}

export default method

import React, { useState, useEffect } from 'react'

import { Grid, Autocomplete } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import cx from 'classnames'

import { default as kozmikTheme } from '../../../assets/theme/theme.json'

import SelectBox from '../../molecules/selectBox/SelectBox'
import Dropdown from '../../molecules/dropdown/Dropdown'
import DoubleStateText from '../../molecules/doubleStateField/DoubleStateText'
import MentionsInput from '../../atoms/mentionsInput/MentionsInput'
import { default as Label } from '../../atoms/settingLabel/SettingLabel'
import BorderlessTextField from '../../atoms/borderlessTextField/BorderlessTextField'
import KozmikCheckBox from '../../atoms/kozmikCheckBox/KozmikCheckBox'

import getEntityFieldsByDataTypes from '../../../methods/getEntityFieldsByDataTypes'
import convertFieldsToActionSourceFields from '../../../methods/convertFieldsToActionSourceFields'
import validateWithRegExp from '../../../methods/validateWithRegExp'
import timeoutDelay from '../../../methods/timeoutDelay'
import fieldDataTypes from '../../../common/fieldDataTypes'
import dataTypeRegExes from '../../../common/regExp/dataType'
import formulas from '../../../common/formulas'

import dataClient from '../../../services/dataClient'
import showToastResponseError from '../../../methods/showToastResponseError'
import ActionFieldMapping from '../../molecules/actionFieldMapping/ActionFieldMapping'
import getValueType from '../../../methods/getValueType'

import systemFieldNames from '../../../common/systemFieldNames/systemFieldNames'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	input: {
		borderRadius: 3,
		backgroundColor: (props) => (props?.bgColor ? props.bgColor : '#FAFAFC'),
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	inputSize: {
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	title: {
		fontWeight: '600',
		fontSize: 12
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		marginBottom: 15
	},
	growBox: {
		flexGrow: 1
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const ActionSelection = (props) => {
	const { t } = useTranslation()
	let { appId } = useParams()
	// const languageCode = culture === 'tr-TR' ? 'tr' : 'en'
	const languageCode = 'en'

	const classes = useStyles({ bgColor: props?.bgColor || null })

	const allScreens = [
		...props.appConfig.screens.hiddens,
		...props.appConfig.screens.tabs,
		...props.appConfig.screens.drawers
	]

	const activeScreenSource = props.activeScreen?.data?.source

	const defaultSource =
		props.listSource ?? props.formSource ?? activeScreenSource ?? null

	const listFields =
		(props.listSource &&
			getEntityFieldsByDataTypes(
				props.entities,
				props.listSource,
				fieldDataTypes.all
			)) ??
		[]

	const formFields =
		(props.formSource &&
			getEntityFieldsByDataTypes(
				props.entities,
				props.formSource,
				fieldDataTypes.all
			)) ??
		[]
	const activeScreenFields =
		(activeScreenSource &&
			getEntityFieldsByDataTypes(
				props.entities,
				activeScreenSource,
				fieldDataTypes.all
			)) ??
		[]

	const urlFields =
		getEntityFieldsByDataTypes(props?.entities, activeScreenSource, [
			'URL',
			'File',
			'Image',
			'Text'
		]) ?? []

	const getTagOptions = (screenSource, formSource) => {
		let formattedFields = []
			.concat(
				formulas.profile.map((x) => ({
					label: x.label,
					name: x.name,
					dataType: x.dataType
				}))
			)
			.concat(
				formulas.datetime.map((x) => ({
					label: x.label,
					name: x.name,
					dataType: x.dataType
				}))
			)
			.concat(
				formulas.string.map((x) => ({
					label: x.label,
					name: x.name,
					dataType: x.dataType
				}))
			)

		if (screenSource) {
			const fields = activeScreenFields
				?.map((x) => ({
					id: x.id,
					name: `Screen.${x.name}`,
					label: `Screen.${x.label}`,
					dataType: x.dataType
				}))
				?.sort(function (a, b) {
					return a.label.localeCompare(b.label)
				})

			formattedFields = formattedFields?.concat(fields)
		}

		if (formSource) {
			const fields = formFields
				?.map((x) => ({
					id: x.id,
					name: `Form.${x.name}`,
					label: `Form.${x.label}`,
					dataType: x.dataType
				}))
				?.sort(function (a, b) {
					return a.label.localeCompare(b.label)
				})

			formattedFields = formattedFields?.concat(fields)
		}

		return formattedFields ?? []
	}

	const getCorrelationIdOptions = () => {
		let formattedFields = []

		if (!!props?.actions?.length === true) {
			const prevs = props.actions?.slice(
				0,
				props.actionIndex > 0 ? props.actionIndex + 1 : 0
			)

			const fields =
				prevs
					?.map((item, index) => {
						if (!(item.type === 'create')) return

						const ent = props.entities?.find((e) => e.name === item.source)
						if (!ent) return

						const actionLabel = props.actionTypes?.find(
							(x) => x.value === 'create'
						)?.label
						const label = ent.label
						const idLabel = ent.fields?.find((x) => x.isKeyField)?.label

						return {
							id: null,
							name: `Action.${index}.data`,
							label: `Action.${actionLabel}.${label}.${idLabel}`,
							dataType: 'RowId'
						}
					})
					?.filter((x) => x) ?? []

			formattedFields = formattedFields?.concat(fields)
		}

		if (props.listSource) {
			const fields = listFields
				?.filter(
					(x) =>
						x.dataType === 'RowId' ||
						(x.dataType === 'Relation' && x.relationType === 'One')
				)
				?.map((x) => ({
					id: x.id,
					name: x.name,
					label: x.label,
					dataType: x.dataType
				}))
				?.sort(function (a, b) {
					return a.label.localeCompare(b.label)
				})

			formattedFields = formattedFields?.concat(fields)
		}

		if (activeScreenSource) {
			const fields = activeScreenFields
				?.filter(
					(x) =>
						x.dataType === 'RowId' ||
						(x.dataType === 'Relation' && x.relationType === 'One')
				)
				?.map((x) => ({
					id: x.id,
					name: `Screen.${x.name}`,
					label: `Screen.${x.label}`,
					dataType: x.dataType
				}))
				?.sort(function (a, b) {
					return a.label.localeCompare(b.label)
				})

			formattedFields = formattedFields?.concat(fields)
		}

		if (props.formSource) {
			const fields = formFields
				?.filter(
					(x) =>
						!systemFieldNames.notVisible.includes(x.name) &&
						x.dataType === 'Relation' &&
						x.relationType === 'One'
				)
				?.map((x) => ({
					id: x.id,
					name: `Form.${x.name}`,
					label: `Form.${x.label}`,
					dataType: x.dataType
				}))
				?.sort(function (a, b) {
					return a.label.localeCompare(b.label)
				})

			formattedFields = formattedFields?.concat(fields)
		}

		return formattedFields ?? []
	}

	const getScreenParamOptions = (screenKey) => {
		if (screenKey) {
			let formattedFields = []

			const targetScreenSource = allScreens?.find(
				(x) => x.key === screenKey
			)?.source

			if (!!props?.actions?.length === true) {
				const prevs = props.actions?.slice(
					0,
					props.actionIndex > 0 ? props.actionIndex + 1 : 0
				)

				const fields =
					prevs
						?.map((item, index) => {
							if (
								!(item.type === 'create' && item.source === targetScreenSource)
							)
								return

							const ent = props.entities?.find((e) => e.name === item.source)

							const actionLabel = props.actionTypes?.find(
								(x) => x.value === 'create'
							)?.label
							const label = ent.label
							const idLabel = ent.fields?.find((x) => x.isKeyField)?.label

							return {
								id: null,
								name: `Action.${index}.data`,
								label: `Action.${actionLabel}.${label}.${idLabel}`,
								dataType: 'RowId'
							}
						})
						?.filter((x) => x) ?? []

				formattedFields = formattedFields?.concat(fields)
			}

			if (!targetScreenSource) {
				return formattedFields
			}

			if (props.listSource) {
				const fields = listFields
					?.filter(
						(x) =>
							(props.listSource === targetScreenSource &&
								x.dataType === 'RowId') ||
							(x.dataType === 'Relation' &&
								x.relationType === 'One' &&
								x.relatedEntityName === targetScreenSource)
					)
					?.map((x) => ({
						id: x.id,
						name: x.name,
						label: x.label,
						dataType: x.dataType
					}))
					?.sort(function (a, b) {
						return a.label.localeCompare(b.label)
					})

				formattedFields = formattedFields?.concat(fields)
			}

			if (activeScreenSource) {
				const fields = activeScreenFields
					?.filter(
						(x) =>
							(activeScreenSource === targetScreenSource &&
								x.dataType === 'RowId') ||
							(x.dataType === 'Relation' &&
								x.relationType === 'One' &&
								x.relatedEntityName === targetScreenSource)
					)
					?.map((x) => ({
						id: x.id,
						name: `Screen.${x.name}`,
						label: `Screen.${x.label}`,
						dataType: x.dataType
					}))
					?.sort(function (a, b) {
						return a.label.localeCompare(b.label)
					})

				formattedFields = formattedFields?.concat(fields)
			}

			if (props.formSource) {
				const fields = formFields
					?.filter(
						(x) =>
							!systemFieldNames.notVisible.includes(x.name) &&
							x.dataType === 'Relation' &&
							x.relationType === 'One' &&
							x.relatedEntityName === targetScreenSource
					)
					?.map((x) => ({
						id: x.id,
						name: `Form.${x.name}`,
						label: `Form.${x.label}`,
						dataType: x.dataType
					}))
					?.sort(function (a, b) {
						return a.label.localeCompare(b.label)
					})

				formattedFields = formattedFields?.concat(fields)
			}

			return formattedFields ?? []
		} else {
			return []
		}
	}

	const getScreenDefaultParam = (screenKey, params) => {
		if (!screenKey) return null

		const screen = allScreens?.find((x) => x.key === screenKey)

		const param =
			params?.length > 0
				? screen?.source == props.listSource
					? 'Id'
					: params[0].name
				: null

		return param
	}

	const getRecipientsFieldsOptions = (userTableName) => {
		if (userTableName) {
			let formattedFields = [].concat(
				formulas.onlyProfile.map((x) => ({
					label: x.label,
					name: x.name,
					dataType: x.dataType
				}))
			)

			if (activeScreenSource) {
				const fields = activeScreenFields
					?.filter(
						(x) =>
							x.dataType === 'Relation' && x.relatedEntityName === userTableName
					)
					?.map((x) => ({
						id: x.id,
						name: `Screen.${x.name}`,
						label: `Screen.${x.label}`,
						dataType: x.dataType
					}))
					?.sort(function (a, b) {
						return a.label.localeCompare(b.label)
					})

				formattedFields = formattedFields?.concat(fields)
			}

			if (props.formSource) {
				const fields = formFields
					?.filter(
						(x) =>
							x.dataType === 'Relation' && x.relatedEntityName === userTableName
					)
					?.map((x) => ({
						id: x.id,
						name: `Form.${x.name}`,
						label: `Form.${x.label}`,
						dataType: x.dataType
					}))
					?.sort(function (a, b) {
						return a.label.localeCompare(b.label)
					})

				formattedFields = formattedFields?.concat(fields)
			}

			if (props.listSource) {
				const fields = listFields
					?.filter(
						(x) =>
							x.dataType === 'Relation' && x.relatedEntityName === userTableName
					)
					?.map((x) => ({
						id: x.id,
						name: x.name,
						label: x.label,
						dataType: x.dataType
					}))
					?.sort(function (a, b) {
						return a.label.localeCompare(b.label)
					})

				formattedFields = formattedFields?.concat(fields)
			}

			return formattedFields ?? []
		} else {
			return []
		}
	}

	const getWhenFieldsOptions = () => {
		let formattedFields = []

		if (props.listSource) {
			const fields = listFields
				?.filter(
					(x) =>
						!systemFieldNames.notVisible.includes(x.name) &&
						x.dataType === 'Date'
				)
				?.map((x) => ({
					id: x.id,
					name: x.name,
					label: x.label,
					dataType: x.dataType
				}))
				?.sort(function (a, b) {
					return a.label.localeCompare(b.label)
				})

			formattedFields = formattedFields?.concat(fields)
		}

		if (activeScreenSource) {
			const fields = activeScreenFields
				?.filter(
					(x) =>
						!systemFieldNames.notVisible.includes(x.name) &&
						x.dataType === 'Date'
				)
				?.map((x) => ({
					id: x.id,
					name: `Screen.${x.name}`,
					label: `Screen.${x.label}`,
					dataType: x.dataType
				}))
				?.sort(function (a, b) {
					return a.label.localeCompare(b.label)
				})

			formattedFields = formattedFields?.concat(fields)
		}

		if (props.formSource) {
			const fields = formFields
				?.filter(
					(x) =>
						!systemFieldNames.notVisible.includes(x.name) &&
						x.dataType === 'Date'
				)
				?.map((x) => ({
					id: x.id,
					name: `Form.${x.name}`,
					label: `Form.${x.label}`,
					dataType: x.dataType
				}))
				?.sort(function (a, b) {
					return a.label.localeCompare(b.label)
				})

			formattedFields = formattedFields?.concat(fields)
		}

		return formattedFields ?? []
	}

	const tagOptions = getTagOptions(activeScreenSource, props.formSource)

	const recipientsFieldOptions = getRecipientsFieldsOptions(
		props?.appDetail?.DataSource?.UsersTableName
	)

	const whenFieldOptions = getWhenFieldsOptions()

	const whenOpts = [
		{
			label: t(
				'screens.elementSettings.actionSelection.whenOptions.immediately'
			),
			value: 'immediately'
		},
		{
			label: t('screens.elementSettings.actionSelection.whenOptions.field'),
			value: 'field'
		}
	]

	const whenTimeOpts = [
		{
			label: t(
				'screens.elementSettings.actionSelection.whenTimeOptions.minute'
			),
			value: 'minute'
		},
		{
			label: t('screens.elementSettings.actionSelection.whenTimeOptions.hour'),
			value: 'hour'
		},
		{
			label: t('screens.elementSettings.actionSelection.whenTimeOptions.day'),
			value: 'day'
		}
	]

	const sendToOpts = [
		{
			label: t(
				'screens.elementSettings.actionSelection.sendToOptions.everyone'
			),
			value: 'everyone'
		},
		{
			label: t(
				'screens.elementSettings.actionSelection.sendToOptions.specific'
			),
			value: 'specific'
		},
		{
			label: t('screens.elementSettings.actionSelection.sendToOptions.field'),
			value: 'field'
		}
	]

	const sameSource =
		(props.formSource && activeScreenSource === props.formSource) ||
		(!props.formSource && props.listSource) ||
		(!props.formSource && activeScreenSource)

	const usersOnly =
		props.formSource == props.appDetail?.DataSource?.UsersTableName

	const actionTypeOpts = (
		props.appConfig.screens.tabs?.length
			? props.actionTypes
			: props.actionTypes.filter((x) => x.value != 'navigateTab')
	).map((x) => {
		return {
			label: x.label,
			value: x.value,
			type: x.type,
			tooltip: x.tooltip,
			disabled:
				(sameSource ? false : !!x.sameSource) ||
				(usersOnly ? false : !!x.usersOnly)
		}
	})

	const getSelectableScreens = () => {
		return allScreens?.filter(
			(x) =>
				!x.source ||
				x.source === props.formSource ||
				x.source === props.listSource ||
				x.source === activeScreenSource ||
				props.actions?.some((r) => r.source === x.source) ||
				(props.formSource &&
					formFields.some(
						(r) => r.dataType === 'Relation' && r.relatedEntityName === x.source
					)) ||
				(props.listSource &&
					listFields.some(
						(r) => r.dataType === 'Relation' && r.relatedEntityName === x.source
					)) ||
				(activeScreenSource &&
					activeScreenFields.some(
						(r) => r.dataType === 'Relation' && r.relatedEntityName === x.source
					))
		)
	}

	const [usersDisplayField, setUsersDisplayField] = useState('Email')
	const [usersData, setUsersData] = useState([])

	/****ACTION***************************/
	const [actionType, setActionType] = useState(null)

	const actionTypeChange = (e) => {
		submitAction(e.value)
		setActionType(e.value)
	}

	const getAction = (type) => {
		switch (type) {
			case 'invite':
				return {
					type: 'invite',
					mapping: {
						message: ''
					}
				}
			case 'signOut':
				return {
					type: 'signOut'
				}
			case 'notify':
				return {
					type: 'notify',
					mapping: {
						title: '',
						message: ''
					}
				}
			case 'push':
				return {
					type: 'push',
					when: {
						type: 'immediately', // [ 'immediately', 'scheduled']
						field: null,
						before: {
							unit: null,
							value: null
						} // { unit: 'minute', value: 10 }
					},
					audiences: {
						sendTo: 'everyone', // [ 'everyone', 'specific', 'field', 'filter']
						specific: null,
						field: null,
						filter: null
					},
					mapping: {
						title: '',
						message: '',
						image: '',
						screenKey: props.appConfig?.screens?.tabs?.[0]?.key
					},
					correlation: {
						correlationId: null,
						category: null,
						clean: false
					}
				}
			case 'email':
				return {
					type: 'email',
					when: {
						type: 'immediately', // [ 'immediately', 'scheduled']
						field: null,
						before: {
							unit: null,
							value: null
						} // { unit: 'minute', value: 10 }
					},
					audiences: {
						sendTo: 'everyone', // [ 'everyone', 'specific', 'field', 'filter']
						specific: null,
						field: null,
						filter: null
					},
					mapping: {
						subject: '',
						body: ''
					},
					correlation: {
						correlationId: null,
						category: null,
						clean: false
					}
				}
			case 'back':
				return {
					type: 'back'
				}
			case 'navigate': {
				const targetScreen = props.listSource
					? allScreens?.find((x) => x.source === props.listSource)
					: allScreens?.find((x) => !x.source)

				const targetScreenParams = getScreenParamOptions(targetScreen?.key)
				const targetScreenParam = getScreenDefaultParam(
					targetScreen?.key,
					targetScreenParams
				)

				return {
					type: 'navigate',
					screenKey: targetScreen?.key,
					screenParam: targetScreenParam,
					emptyScreenKey: null,
					emptyScreenParam: null
				}
			}
			case 'navigateTab':
				return {
					type: 'navigateTab',
					screenKey: props.appConfig?.screens?.tabs?.[0]?.key
				}
			case 'openLink':
				return {
					type: 'openLink',
					mapping: {
						url: {
							default: t(
								'screens.elementSettings.actionSelection.linkUrlDefault'
							),
							field: null
						}
					}
				}
			case 'shareLink':
				return {
					type: 'shareLink'
				}
			case 'drawer':
				return {
					type: 'drawer'
				}
			case 'create':
				return {
					type: 'create',
					source: defaultSource,
					fields: defaultSource ? getActionSourceFields(defaultSource) : []
				}
			case 'update':
				return {
					type: 'update',
					source: defaultSource,
					fields: defaultSource ? getActionSourceFields(defaultSource) : []
				}
			case 'delete':
				return {
					type: 'delete',
					source: defaultSource
				}
			default:
				return null
		}
	}

	const getActionSourceFields = (source) => {
		const entity = props.entities?.find((x) => x.name === source)
		if (entity) {
			return convertFieldsToActionSourceFields(
				entity,
				props.listSource,
				props.formSource,
				activeScreenSource,
				props.entities
			)
		}

		return []
	}

	const submitAction = (x) => {
		const newAction = getAction(x)
		props.updateAction(newAction, props.actionIndex)
	}

	/****OPEN_LINK***************************/
	const [isOpenLinkTextMode, setIsOpenLinkTextMode] = useState(false)
	const [openLinkField, setOpenLinkField] = useState('')
	const [openLinkDefault, setOpenLinkDefault] = useState('')
	const [openLinkDefaultValid, setOpenLinkDefaultValid] = useState(true)

	const openLinkChange = (data) => {
		if (isOpenLinkTextMode) {
			const openLinkRegExp = dataTypeRegExes.URL.re

			validateWithRegExp(data.target.value, openLinkRegExp).then((isValid) => {
				setOpenLinkDefaultValid(isValid)
				if (isValid) {
					timeoutDelay(submitOpenLink, data.target.value, 1000)
				}
			})

			setOpenLinkDefault(data.target.value)
		} else {
			submitOpenLink(data?.value)
			setOpenLinkField(data?.value)
			setOpenLinkDefault('')
		}
	}
	const submitOpenLink = (x) => {
		const modifiedAction = update(props.action, {
			mapping: {
				url: {
					field: { $set: isOpenLinkTextMode ? null : x },
					default: { $set: isOpenLinkTextMode ? x : null }
				}
			}
		})
		props.updateAction(modifiedAction, props.actionIndex)
	}

	/****NAVIGATE***************************/

	const [screenKey, setScreenKey] = useState('')
	const [screenParamOptions, setScreenParamOptions] = useState([])

	const screenKeyChange = (e) => {
		const key = e.target.value
		const params = getScreenParamOptions(key)
		const param = getScreenDefaultParam(key, params)

		const modifiedAction = update(props.action, {
			screenKey: { $set: key },
			screenParam: { $set: param },
			emptyScreenKey: { $set: null },
			emptyScreenParam: { $set: null }
		})

		setScreenKey(key)
		setScreenParamOptions(params)
		setScreenParam(param)

		props.updateAction(modifiedAction, props.actionIndex)
	}

	const [screenParam, setScreenParam] = useState('')
	const screenParamOnChange = (e) => {
		const param = e.value

		let modifiedAction
		if (param) {
			modifiedAction = update(props.action, {
				screenParam: { $set: param }
			})
		} else {
			modifiedAction = update(props.action, {
				screenParam: { $set: null },
				emptyScreenKey: { $set: null },
				emptyScreenParam: { $set: null }
			})
		}

		setScreenParam(param)

		props.updateAction(modifiedAction, props.actionIndex)
	}

	const [emptyScreenKey, setEmptyScreenKey] = useState('')
	const [emptyScreenParamOptions, setEmptyScreenParamOptions] = useState([])

	const emptyScreenKeyChange = (e) => {
		const key = e.target.value
		const params = getScreenParamOptions(key)
		const param = getScreenDefaultParam(key, params)

		const modifiedAction = update(props.action, {
			emptyScreenKey: { $set: key },
			emptyScreenParam: { $set: param }
		})

		setEmptyScreenKey(key)
		setEmptyScreenParamOptions(params)
		setEmptyScreenParam(param)

		props.updateAction(modifiedAction, props.actionIndex)
	}

	const [emptyScreenParam, setEmptyScreenParam] = useState('')
	const emptyScreenParamOnChange = (e) => {
		const param = e.value

		const modifiedAction = update(props.action, {
			emptyScreenParam: { $set: param || null }
		})

		setEmptyScreenParam(param)

		props.updateAction(modifiedAction, props.actionIndex)
	}

	/**********DATA_ACTIONS***************************/
	const actionSources =
		(actionType === 'create'
			? props.entities
			: actionType === 'update'
			? props.entities?.filter((x) => x.name === defaultSource)
			: actionType === 'delete'
			? props.entities?.filter((x) => x.name === defaultSource)
			: []) ?? []

	const [actionSource, setActionSource] = useState('')
	const [actionSourceFields, setActionSourceFields] = useState([])

	const actionSourceOnChange = (e) => {
		const entity = props.entities?.find((x) => x.name === e.target.value)
		if (!entity) return

		setActionSource(e.target.value)
		setActionSourceFields(getActionSourceFields(e.target.value))

		const modifiedAction = update(props.action, {
			source: { $set: entity.name },
			fields: { $set: getActionSourceFields(entity.name) }
		})

		props.updateAction(modifiedAction, props.actionIndex)
	}

	const updateActionFieldMapping = (index, val, valueType) => {
		const modifiedAction = update(props.action, {
			fields: {
				[index]: {
					value: { $set: val },
					valueType: { $set: getValueType(val, valueType) }
				}
			}
		})

		props.updateAction(modifiedAction, props.actionIndex)
	}

	/**********NOTIFY_TITLE***************************/
	const [notifyTitleDefault, setNotifyTitleDefault] = useState('')
	const notifyTitleOnChange = (value) => {
		const modifiedAction = update(props.action, {
			mapping: {
				title: { $set: value }
			}
		})
		props.updateAction(modifiedAction, props.actionIndex)
	}

	/**********NOTIFY_MESSAGE***************************/
	const [notifyMessageDefault, setNotifyMessageDefault] = useState('')
	const notifyMessageOnChange = (value) => {
		const modifiedAction = update(props.action, {
			mapping: {
				message: { $set: value }
			}
		})
		props.updateAction(modifiedAction, props.actionIndex)
	}

	/**********INVITE_MESSAGE***************************/
	const [inviteMessageDefault, setInviteMessageDefault] = useState('')
	const inviteMessageOnChange = (value) => {
		const modifiedAction = update(props.action, {
			mapping: {
				message: { $set: value }
			}
		})
		props.updateAction(modifiedAction, props.actionIndex)
	}

	/**********PUSH_CATEGORY***************************/
	const [pushCategory, setPushCategory] = useState('')
	const pushCategoryOnChange = (data) => {
		timeoutDelay(submitPushCategory, data.target.value, 1000)
		setPushCategory(data.target.value)
	}
	const submitPushCategory = (data) => {
		const modifiedAction = update(props.action, {
			correlation: {
				category: { $set: data }
			}
		})

		props.updateAction(modifiedAction, props.actionIndex)
	}

	/**********PUSH_CORRELATIONID***************************/
	const [pushCorrelationIdOptions, setPushCorrelationIdOptions] = useState([])
	const [pushCorrelationId, setPushCorrelationId] = useState('')
	const pushCorrelationIdOnChange = (e) => {
		submitPushCorrelationId(e.value)
		setPushCorrelationId(e.value)
	}
	const submitPushCorrelationId = (data) => {
		const modifiedAction = update(props.action, {
			correlation: {
				correlationId: { $set: data }
			}
		})

		props.updateAction(modifiedAction, props.actionIndex)
	}

	/**********PUSH_CLEAN***************************/
	const [pushClean, setPushClean] = useState(false)
	const pushCleanChange = (e) => {
		submitPushClean(e.target.checked)
		setPushClean(e.target.checked)
	}
	const submitPushClean = (checked) => {
		const modifiedAction = update(props.action, {
			correlation: {
				clean: { $set: checked }
			}
		})

		props.updateAction(modifiedAction, props.actionIndex)
	}

	/**********PUSH_TITLE***************************/
	const [pushTitleDefault, setPushTitleDefault] = useState('')
	const pushTitleOnChange = (value) => {
		const modifiedAction = update(props.action, {
			mapping: {
				title: { $set: value }
			}
		})
		props.updateAction(modifiedAction, props.actionIndex)
	}

	/**********PUSH_MESSAGE***************************/
	const [pushMessageDefault, setPushMessageDefault] = useState('')
	const pushMessageOnChange = (value) => {
		const modifiedAction = update(props.action, {
			mapping: {
				message: { $set: value }
			}
		})
		props.updateAction(modifiedAction, props.actionIndex)
	}

	/****PUSH_AUDIENCES***************************/
	const [pushSendTo, setPushSendTo] = useState('everyone')
	const pushSendToOnChange = (e) => {
		submitPushSendTo(e.target.value)

		setPushSendTo(e.target.value)
		setPushToField('')
		setPushToSpecific([])
	}
	const submitPushSendTo = (data) => {
		const modifiedAction = update(props.action, {
			audiences: {
				sendTo: { $set: data },
				specific: { $set: null },
				field: { $set: null },
				filter: { $set: null }
			}
		})
		props.updateAction(modifiedAction, props.actionIndex)
	}

	const [pushToField, setPushToField] = useState('')
	const pushToFieldOnChange = (e) => {
		submitPushToField(e.value)
		setPushToField(e.value)
	}
	const submitPushToField = (data) => {
		const modifiedAction = update(props.action, {
			audiences: {
				field: { $set: data }
			}
		})

		props.updateAction(modifiedAction, props.actionIndex)
	}

	const [pushToSpecific, setPushToSpecific] = useState([])
	const pushToSpecificOnChange = (e, newValue = null) => {
		submitPushToSpecific(newValue)
		setPushToSpecific(newValue ?? [])
	}
	const submitPushToSpecific = (data) => {
		const modifiedAction = update(props.action, {
			audiences: {
				specific: { $set: data }
			}
		})

		props.updateAction(modifiedAction, props.actionIndex)
	}

	const [pushWhen, setPushWhen] = useState('everyone')
	const pushWhenOnChange = (e) => {
		submitPushWhen(e.target.value)
		setPushWhen(e.target.value)
	}
	const submitPushWhen = (data) => {
		const modifiedAction = update(props.action, {
			when: {
				$set:
					data === 'field'
						? {
								type: data,
								field: whenFieldOptions?.[0]?.name,
								before: {
									unit: 'minute',
									value: 30
								}
						  }
						: {
								type: data,
								field: null,
								before: {
									unit: null,
									value: null
								}
						  }
			}
		})
		props.updateAction(modifiedAction, props.actionIndex)
	}

	const [pushWhenField, setPushWhenField] = useState('')
	const pushWhenFieldOnChange = (e) => {
		submitPushWhenField(e.value)
		setPushWhenField(e.value)
	}
	const submitPushWhenField = (data) => {
		const modifiedAction = update(props.action, {
			when: {
				field: { $set: data }
			}
		})

		props.updateAction(modifiedAction, props.actionIndex)
	}

	const [pushWhenBeforeUnit, setPushWhenBeforeUnit] = useState('')
	const pushWhenBeforeUnitOnChange = (e) => {
		submitPushWhenBeforeUnit(e.value)
		setPushWhenBeforeUnit(e.value)
	}
	const submitPushWhenBeforeUnit = (data) => {
		const modifiedAction = update(props.action, {
			when: {
				before: {
					unit: { $set: data }
				}
			}
		})

		props.updateAction(modifiedAction, props.actionIndex)
	}

	const [pushWhenBeforeValue, setPushWhenBeforeValue] = useState(0)
	const pushWhenBeforeValueOnChange = (data) => {
		timeoutDelay(submitPushWhenBeforeValue, data.target.value, 1000)
		setPushWhenBeforeValue(data.target.value)
	}
	const submitPushWhenBeforeValue = (data) => {
		const modifiedAction = update(props.action, {
			when: {
				before: {
					value: { $set: data }
				}
			}
		})

		props.updateAction(modifiedAction, props.actionIndex)
	}

	/**********EMAIL_CATEGORY***************************/
	const [emailCategory, setEmailCategory] = useState('')
	const emailCategoryOnChange = (data) => {
		timeoutDelay(submitEmailCategory, data.target.value, 1000)
		setEmailCategory(data.target.value)
	}
	const submitEmailCategory = (data) => {
		const modifiedAction = update(props.action, {
			correlation: {
				category: { $set: data }
			}
		})

		props.updateAction(modifiedAction, props.actionIndex)
	}

	/**********EMAIL_CORRELATIONID***************************/
	const [emailCorrelationIdOptions, setEmailCorrelationIdOptions] = useState([])
	const [emailCorrelationId, setEmailCorrelationId] = useState('')
	const emailCorrelationIdOnChange = (e) => {
		submitEmailCorrelationId(e.value)
		setEmailCorrelationId(e.value)
	}
	const submitEmailCorrelationId = (data) => {
		const modifiedAction = update(props.action, {
			correlation: {
				correlationId: { $set: data }
			}
		})

		props.updateAction(modifiedAction, props.actionIndex)
	}

	/**********EMAIL_CLEAN***************************/
	const [emailClean, setEmailClean] = useState(false)
	const emailCleanChange = (e) => {
		submitEmailClean(e.target.checked)
		setEmailClean(e.target.checked)
	}
	const submitEmailClean = (checked) => {
		const modifiedAction = update(props.action, {
			correlation: {
				clean: { $set: checked }
			}
		})

		props.updateAction(modifiedAction, props.actionIndex)
	}

	/**********EMAIL_SUBJECT***************************/
	const [emailSubjectDefault, setEmailSubjectDefault] = useState('')
	const emailSubjectOnChange = (value) => {
		const modifiedAction = update(props.action, {
			mapping: {
				subject: { $set: value }
			}
		})
		props.updateAction(modifiedAction, props.actionIndex)
	}

	/**********EMAIL_BODY***************************/
	const [emailBodyDefault, setEmailBodyDefault] = useState('')
	const emailBodyOnChange = (value) => {
		const modifiedAction = update(props.action, {
			mapping: {
				body: { $set: value }
			}
		})
		props.updateAction(modifiedAction, props.actionIndex)
	}

	/****EMAIL_AUDIENCES***************************/
	const [emailSendTo, setEmailSendTo] = useState('everyone')
	const emailSendToOnChange = (e) => {
		submitEmailSendTo(e.target.value)

		setEmailSendTo(e.target.value)
		setEmailToField('')
		setEmailToSpecific([])
	}
	const submitEmailSendTo = (data) => {
		const modifiedAction = update(props.action, {
			audiences: {
				sendTo: { $set: data },
				specific: { $set: null },
				field: { $set: null },
				filter: { $set: null }
			}
		})
		props.updateAction(modifiedAction, props.actionIndex)
	}

	const [emailToField, setEmailToField] = useState('')

	const emailToFieldOnChange = (e) => {
		submitEmailToField(e.value)
		setEmailToField(e.value)
	}
	const submitEmailToField = (data) => {
		const modifiedAction = update(props.action, {
			audiences: {
				field: { $set: data }
			}
		})

		props.updateAction(modifiedAction, props.actionIndex)
	}

	const [emailToSpecific, setEmailToSpecific] = useState([])
	const emailToSpecificOnChange = (e, newValue = null) => {
		submitEmailToSpecific(newValue)
		setEmailToSpecific(newValue ?? [])
	}
	const submitEmailToSpecific = (data) => {
		const modifiedAction = update(props.action, {
			audiences: {
				specific: { $set: data }
			}
		})

		props.updateAction(modifiedAction, props.actionIndex)
	}

	const [emailWhen, setEmailWhen] = useState('everyone')
	const emailWhenOnChange = (e) => {
		submitEmailWhen(e.target.value)
		setEmailWhen(e.target.value)
	}
	const submitEmailWhen = (data) => {
		const modifiedAction = update(props.action, {
			when: {
				$set:
					data === 'field'
						? {
								type: data,
								field: whenFieldOptions?.[0]?.name,
								before: {
									unit: 'minute',
									value: 30
								}
						  }
						: {
								type: data,
								field: null,
								before: {
									unit: null,
									value: null
								}
						  }
			}
		})
		props.updateAction(modifiedAction, props.actionIndex)
	}

	const [emailWhenField, setEmailWhenField] = useState('')
	const emailWhenFieldOnChange = (e) => {
		submitEmailWhenField(e.value)
		setEmailWhenField(e.value)
	}
	const submitEmailWhenField = (data) => {
		const modifiedAction = update(props.action, {
			when: {
				field: { $set: data }
			}
		})

		props.updateAction(modifiedAction, props.actionIndex)
	}

	const [emailWhenBeforeUnit, setEmailWhenBeforeUnit] = useState('minute')
	const emailWhenBeforeUnitOnChange = (e) => {
		submitEmailWhenBeforeUnit(e.value)
		setEmailWhenBeforeUnit(e.value)
	}
	const submitEmailWhenBeforeUnit = (data) => {
		const modifiedAction = update(props.action, {
			when: {
				before: {
					unit: { $set: data }
				}
			}
		})

		props.updateAction(modifiedAction, props.actionIndex)
	}

	const [emailWhenBeforeValue, setEmailWhenBeforeValue] = useState(0)
	const emailWhenBeforeValueOnChange = (data) => {
		timeoutDelay(submitEmailWhenBeforeValue, data.target.value, 1000)
		setEmailWhenBeforeValue(data.target.value)
	}
	const submitEmailWhenBeforeValue = (data) => {
		const modifiedAction = update(props.action, {
			when: {
				before: {
					value: { $set: data }
				}
			}
		})

		props.updateAction(modifiedAction, props.actionIndex)
	}

	const getUsers = async () => {
		setUsersData([])

		const parameters = {
			sort: 'Created desc',
			fields: `Id,${usersDisplayField}`
		}

		return dataClient
			.searchData(
				appId,
				props?.appDetail?.DataSource?.UsersTableName,
				parameters
			)
			.then((response) => {
				if (response?.data) {
					setUsersData(response.data.records)
				} else {
					showToastResponseError(response)
				}
			})
	}

	/****INITIALIZATION***************************/
	const initStates = (actionObj) => {
		/****ACTION***************************/
		setActionType(actionObj?.type)
		/****OPEN_LINK***************************/
		if (actionObj?.type === 'openLink') {
			actionObj?.mapping?.url?.field || actionObj?.mapping?.url?.field === ''
				? setIsOpenLinkTextMode(false)
				: setIsOpenLinkTextMode(true)

			setOpenLinkField(actionObj?.mapping?.url?.field || '')
			setOpenLinkDefault(actionObj?.mapping?.url?.default || '')
		}
		/****NAVIGATE***************************/
		if (actionObj?.type === 'navigate' || actionObj?.type === 'navigateTab') {
			setScreenKey(actionObj?.screenKey || '')
			setEmptyScreenKey(actionObj?.emptyScreenKey || '')

			if (actionObj.screenKey) {
				const params = getScreenParamOptions(actionObj.screenKey)
				const param = getScreenDefaultParam(actionObj.screenKey, params) ?? ''

				setScreenParamOptions(params)

				if (actionObj.screenParam) {
					setScreenParam(actionObj.screenParam)
				} else {
					setScreenParam(param)
				}
			}

			if (actionObj.emptyScreenKey) {
				const params = getScreenParamOptions(actionObj.emptyScreenKey)
				const param =
					getScreenDefaultParam(actionObj.emptyScreenKey, params) ?? ''

				setEmptyScreenParamOptions(params)

				if (actionObj.emptyScreenParam) {
					setEmptyScreenParam(actionObj.emptyScreenParam)
				} else {
					setEmptyScreenParam(param)
				}
			}
		}

		/****NOTIFY***************************/
		if (actionObj?.type === 'notify') {
			setNotifyTitleDefault(actionObj?.mapping?.title ?? '')
			setNotifyMessageDefault(actionObj?.mapping?.message ?? '')
		}
		/****PUSH***************************/
		if (actionObj?.type === 'push') {
			setPushSendTo(actionObj?.audiences?.sendTo ?? 'everyone')
			setPushToSpecific(actionObj?.audiences?.specific ?? [])
			setPushToField(actionObj?.audiences?.field ?? '')
			setPushTitleDefault(actionObj?.mapping?.title ?? '')
			setPushMessageDefault(actionObj?.mapping?.message ?? '')
			setPushWhen(actionObj?.when?.type ?? 'immediately')
			setPushWhenField(actionObj?.when?.field ?? '')
			setPushWhenBeforeUnit(actionObj?.when?.before?.unit ?? 'minute')
			setPushWhenBeforeValue(actionObj?.when?.before?.value ?? 30)

			setPushCategory(actionObj?.correlation?.category ?? '')
			setPushCorrelationId(actionObj?.correlation?.correlationId ?? '')
			setPushClean(actionObj?.correlation?.clean || false)

			setPushCorrelationIdOptions(getCorrelationIdOptions())

			if (actionObj?.audiences?.sendTo === 'specific') getUsers()

			if (!actionObj.when) {
				const modifiedAction = {
					...props.action,
					when: {
						type: 'immediately',
						field: null,
						before: {
							unit: null,
							value: null
						}
					}
				}

				props.updateAction(modifiedAction, props.actionIndex)
			}

			if (!actionObj.correlation) {
				const modifiedAction = {
					...props.action,
					correlation: {
						correlationId: null,
						category: null,
						clean: false
					}
				}

				props.updateAction(modifiedAction, props.actionIndex)
			}
		}
		/****EMAIL***************************/
		if (actionObj?.type === 'email') {
			setEmailSendTo(actionObj?.audiences?.sendTo ?? 'everyone')
			setEmailToSpecific(actionObj?.audiences?.specific ?? [])
			setEmailToField(actionObj?.audiences?.field ?? '')
			setEmailSubjectDefault(actionObj?.mapping?.subject ?? '')
			setEmailBodyDefault(actionObj?.mapping?.body ?? '')
			setEmailWhen(actionObj?.when?.type ?? 'immediately')
			setEmailWhenField(actionObj?.when?.field ?? '')
			setEmailWhenBeforeUnit(actionObj?.when?.before?.unit ?? 'minute')
			setEmailWhenBeforeValue(actionObj?.when?.before?.value ?? '30')

			setEmailCategory(actionObj?.correlation?.category ?? '')
			setEmailCorrelationId(actionObj?.correlation?.correlationId ?? '')
			setEmailClean(actionObj?.correlation?.clean || false)

			setEmailCorrelationIdOptions(getCorrelationIdOptions())

			if (actionObj?.audiences?.sendTo === 'specific') getUsers()

			if (!actionObj.when) {
				const modifiedAction = {
					...props.action,
					when: {
						type: 'immediately',
						field: null,
						before: {
							unit: null,
							value: null
						}
					}
				}

				props.updateAction(modifiedAction, props.actionIndex)
			}

			if (!actionObj.correlation) {
				const modifiedAction = {
					...props.action,
					correlation: {
						correlationId: null,
						category: null,
						clean: false
					}
				}

				props.updateAction(modifiedAction, props.actionIndex)
			}
		}
		/****INVITE***************************/
		if (actionObj?.type === 'invite') {
			setInviteMessageDefault(actionObj?.mapping?.message ?? '')
		}
		/****DATA***************************/
		if (
			actionObj?.type === 'create' ||
			actionObj?.type === 'update' ||
			actionObj?.type === 'delete'
		) {
			const source = actionObj?.source ?? defaultSource ?? ''

			setActionSource(source)

			if (actionObj?.type === 'create' || actionObj?.type === 'update') {
				const fields = getActionSourceFields(source)

				if (actionObj?.fields?.length > 0) {
					const missingFields =
						fields?.filter(
							(x) => !actionObj.fields.some((y) => y.field === x.field)
						) ?? []

					const actionSourceFields = [
						...actionObj.fields,
						...missingFields
					]?.filter((x) => fields.some((y) => y.field === x.field))

					if (actionObj.fields.length != actionSourceFields.length) {
						const modifiedAction = update(props.action, {
							fields: {
								$set: actionSourceFields
							}
						})

						props.updateAction(modifiedAction, props.actionIndex)
					}

					setActionSourceFields(actionSourceFields)
				} else {
					setActionSourceFields(fields)
				}
			} else {
				setActionSourceFields([])
			}
		}
	}

	useEffect(() => {
		props?.action && initStates(props.action)
	}, [props.action])

	return (
		<>
			<Grid container alignItems='center' className={classes.mb10}>
				<Grid
					item
					container
					xs={12}
					alignItems='center'
					justifyContent='center'
				>
					<Dropdown
						bgColor={props?.bgColor}
						emptyText={t('screens.elementSettings.actionSelection.noAction')}
						onItemClick={actionTypeChange}
						value={actionType}
						data={actionTypeOpts}
						textKey='label'
						valueKey='value'
						groupKey='type'
					/>
				</Grid>
			</Grid>
			{actionType === 'openLink' && (
				<Grid container alignItems='center' className={classes.mb10}>
					<Grid item xs={3}>
						<Label>
							{t('screens.elementSettings.actionSelection.openLinkLabel')}
						</Label>
					</Grid>
					<Grid item xs={9}>
						<DoubleStateText
							bgColor={props?.bgColor}
							fullWidth={true}
							textError={!openLinkDefaultValid}
							isTextMode={isOpenLinkTextMode}
							setIsTextMode={setIsOpenLinkTextMode}
							valChange={openLinkChange}
							textVal={openLinkDefault}
							autoCompVal={openLinkField}
							autoCompOptions={urlFields}
							valueKey='name'
							labelKey='label'
						/>
					</Grid>
				</Grid>
			)}
			{actionType === 'navigate' && (
				<>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>
								{t('screens.elementSettings.actionSelection.navigateLabel')}
							</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t(
									'screens.elementSettings.actionSelection.emptyScreen'
								)}
								className={classes.input}
								onChange={screenKeyChange}
								value={screenKey}
								data={
									defaultSource
										? getSelectableScreens()
										: allScreens?.filter((x) => !x.source)
								}
								textKey='name'
								valueKey='key'
							/>
						</Grid>
					</Grid>
					{allScreens?.find((x) => x.key === screenKey)?.source && (
						<>
							<Grid container alignItems='center' className={classes.mb10}>
								<Grid item xs={4}>
									<Label>
										{t(
											'screens.elementSettings.actionSelection.navigate.label.rowId'
										)}
									</Label>
								</Grid>
								<Grid item xs={8}>
									<Dropdown
										bgColor={props?.bgColor}
										onItemClick={screenParamOnChange}
										value={screenParam}
										data={screenParamOptions}
										textKey='label'
										valueKey='name'
										groupKey='label'
										groupKeySeperator='.'
									/>
								</Grid>
							</Grid>
							{screenParam && screenParam !== 'Id' && (
								<>
									<Grid container alignItems='center' className={classes.mb10}>
										<Grid item xs={12} lg={12}>
											<span className={classes.title}>
												{t(
													'screens.elementSettings.actionSelection.navigate.emptyState'
												)}
											</span>
										</Grid>
									</Grid>
									<Grid container alignItems='center' className={classes.mb10}>
										<Grid item xs={4}>
											<Label>
												{t(
													'screens.elementSettings.actionSelection.navigate.label.screen'
												)}
											</Label>
										</Grid>
										<Grid item xs={8}>
											<SelectBox
												emptyText={t(
													'screens.elementSettings.actionSelection.navigate.placeholder.screen'
												)}
												className={classes.input}
												onChange={emptyScreenKeyChange}
												value={emptyScreenKey}
												data={
													defaultSource
														? getSelectableScreens()
														: allScreens?.filter((x) => !x.source)
												}
												textKey='name'
												valueKey='key'
											/>
										</Grid>
									</Grid>
									{allScreens?.find((x) => x.key === emptyScreenKey)
										?.source && (
										<>
											<Grid
												container
												alignItems='center'
												className={classes.mb10}
											>
												<Grid item xs={4}>
													<Label>
														{t(
															'screens.elementSettings.actionSelection.navigate.label.rowId'
														)}
													</Label>
												</Grid>
												<Grid item xs={8}>
													<Dropdown
														bgColor={props?.bgColor}
														onItemClick={emptyScreenParamOnChange}
														value={emptyScreenParam}
														data={emptyScreenParamOptions}
														textKey='label'
														valueKey='name'
														groupKey='label'
														groupKeySeperator='.'
													/>
												</Grid>
											</Grid>
										</>
									)}
								</>
							)}
						</>
					)}
				</>
			)}
			{actionType === 'navigateTab' && (
				<Grid container alignItems='center' className={classes.mb10}>
					<Grid item xs={4}>
						<Label>
							{t('screens.elementSettings.actionSelection.navigateTabLabel')}
						</Label>
					</Grid>
					<Grid item xs={8}>
						<SelectBox
							emptyText={t(
								'screens.elementSettings.actionSelection.emptyScreen'
							)}
							className={classes.input}
							onChange={screenKeyChange}
							value={screenKey}
							data={props.appConfig.screens.tabs}
							textKey='name'
							valueKey='key'
						/>
					</Grid>
				</Grid>
			)}
			{actionType === 'notify' && (
				<>
					<Grid container alignItems='flex-start' className={classes.mb10}>
						<Grid item xs={12} lg={12} className={classes.mb10}>
							<Label>
								{t(
									'screens.elementSettings.actionSelection.notify.label.title'
								)}
							</Label>
						</Grid>
						<Grid item xs={12} lg={12}>
							<MentionsInput
								name={`${props.actionIndex}-notify-title`}
								whitelist={tagOptions}
								onChange={notifyTitleOnChange}
								value={notifyTitleDefault}
								multiline={false}
								valueKey='name'
								labelKey='label'
								inputStyle={{
									backgroundColor: props?.bgColor
										? props.bgColor
										: kozmikTheme.colors.kozmikGray,
									borderRadius: '3px !important',
									border: 'none',
									color: kozmikTheme.colors.textPrimary
								}}
							></MentionsInput>
						</Grid>
					</Grid>
					<Grid container alignItems='flex-start' className={classes.mb10}>
						<Grid item xs={12} lg={12} className={classes.mb10}>
							<Label>
								{t(
									'screens.elementSettings.actionSelection.notify.label.message'
								)}
							</Label>
						</Grid>
						<Grid item xs={12} lg={12}>
							<MentionsInput
								name={`${props.actionIndex}-notify-message`}
								whitelist={tagOptions}
								onChange={notifyMessageOnChange}
								value={notifyMessageDefault}
								multiline={true}
								valueKey='name'
								labelKey='label'
								inputStyle={{
									backgroundColor: props?.bgColor
										? props.bgColor
										: kozmikTheme.colors.kozmikGray,
									borderRadius: '3px !important',
									border: 'none',
									color: kozmikTheme.colors.textPrimary
								}}
							></MentionsInput>
						</Grid>
					</Grid>
				</>
			)}
			{actionType === 'push' && (
				<>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={3}>
							<Label>
								{t(
									'screens.elementSettings.actionSelection.push.label.audiences'
								)}
							</Label>
						</Grid>
						<Grid item xs={9}>
							<SelectBox
								className={classes.input}
								onChange={pushSendToOnChange}
								value={pushSendTo}
								data={sendToOpts}
								textKey='label'
								valueKey='value'
							/>
						</Grid>
					</Grid>
					{pushSendTo === 'specific' && (
						<Grid container alignItems='center' className={classes.mb10}>
							<Grid item xs={3}>
								<Label>
									{t(
										'screens.elementSettings.actionSelection.push.label.recipients'
									)}
								</Label>
							</Grid>
							<Grid item xs={9}>
								<Autocomplete
									sx={{
										'& .MuiOutlinedInput-root': {
											borderRadius: '4px',
											backgroundColor: '#ffffff',
											fontSize: '14px',
											color: kozmikTheme.colors.textPrimary
										}
									}}
									value={pushToSpecific}
									onChange={pushToSpecificOnChange}
									isOptionEqualToValue={(option, value) => {
										return option?.Id === value?.Id
									}}
									fullWidth={true}
									size='small'
									multiple={true}
									id='tags-outlined'
									options={usersData}
									getOptionLabel={(option) => {
										return option[usersDisplayField] || ''
									}}
									renderOption={(props, option) => {
										return (
											<div {...props} style={{ fontSize: 14 }}>
												{option[usersDisplayField] || ''}
											</div>
										)
									}}
									renderInput={(params) => (
										<BorderlessTextField
											{...params}
											placeholder={t(
												'screens.elementSettings.actionSelection.push.label.selectRecipients'
											)}
										/>
									)}
								/>
							</Grid>
						</Grid>
					)}
					{pushSendTo === 'field' && (
						<Grid container alignItems='center' className={classes.mb10}>
							<Grid item xs={3}>
								<Label>
									{t(
										'screens.elementSettings.actionSelection.push.label.field'
									)}
								</Label>
							</Grid>
							<Grid item xs={9}>
								<Dropdown
									bgColor={props?.bgColor}
									onItemClick={pushToFieldOnChange}
									value={pushToField}
									data={recipientsFieldOptions}
									textKey='label'
									valueKey='name'
									groupKey='label'
									groupKeySeperator='.'
									emptyText={t(
										'screens.elementSettings.actionSelection.push.label.noRecipients'
									)}
								/>
							</Grid>
						</Grid>
					)}
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={3}>
							<Label>
								{t('screens.elementSettings.actionSelection.push.label.when')}
							</Label>
						</Grid>
						<Grid item xs={9}>
							<SelectBox
								className={classes.input}
								onChange={pushWhenOnChange}
								value={pushWhen}
								data={whenOpts}
								textKey='label'
								valueKey='value'
							/>
						</Grid>
					</Grid>
					{pushWhen === 'field' && (
						<>
							<Grid container alignItems='center' className={classes.mb10}>
								<Grid item xs={3}>
									<Label>
										{t(
											'screens.elementSettings.actionSelection.push.label.whenField'
										)}
									</Label>
								</Grid>
								<Grid item xs={9}>
									<Dropdown
										bgColor={props?.bgColor}
										onItemClick={pushWhenFieldOnChange}
										value={pushWhenField}
										data={whenFieldOptions}
										textKey='label'
										valueKey='name'
										groupKey='label'
										groupKeySeperator='.'
									/>
								</Grid>
							</Grid>
							<Grid container alignItems='center' className={classes.mb10}>
								<Grid item xs={3}>
									<Label>
										{t(
											'screens.elementSettings.actionSelection.push.label.whenBefore'
										)}
									</Label>
								</Grid>
								<Grid item xs={4}>
									<BorderlessTextField
										type='number'
										size='small'
										variant='outlined'
										value={pushWhenBeforeValue}
										onChange={pushWhenBeforeValueOnChange}
										className={classes.input}
										InputProps={{
											classes: {
												input: classes.inputSize
											}
										}}
									/>
								</Grid>
								<Grid item xs={5} style={{ paddingLeft: 10 }}>
									<Dropdown
										bgColor={props?.bgColor}
										onItemClick={pushWhenBeforeUnitOnChange}
										value={pushWhenBeforeUnit}
										data={whenTimeOpts}
										textKey='label'
										valueKey='value'
										groupKey='label'
									/>
								</Grid>
							</Grid>
						</>
					)}
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={3}>
							<Label>
								{t(
									'screens.elementSettings.actionSelection.push.label.category'
								)}
							</Label>
						</Grid>
						<Grid item xs={9}>
							<BorderlessTextField
								fullWidth
								size='small'
								variant='outlined'
								value={pushCategory}
								onChange={pushCategoryOnChange}
								className={classes.input}
								InputProps={{
									classes: {
										input: classes.inputSize
									}
								}}
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={3}>
							<Label>
								{t(
									'screens.elementSettings.actionSelection.push.label.correlationId'
								)}
							</Label>
						</Grid>
						<Grid item xs={9}>
							<Dropdown
								bgColor={props?.bgColor}
								onItemClick={pushCorrelationIdOnChange}
								value={pushCorrelationId}
								data={pushCorrelationIdOptions}
								emptyText={t(
									'screens.elementSettings.actionSelection.push.label.emptyText'
								)}
								textKey='label'
								valueKey='name'
								groupKey='label'
								groupKeySeperator='.'
							/>
						</Grid>
					</Grid>
					<Grid item>
						<div className={cx(classes.item)}>
							<div>
								<KozmikCheckBox
									checked={pushClean}
									onChange={pushCleanChange}
								/>
							</div>
							<div className={cx(classes.labelBox, classes.growBox)}>
								<Label>
									{t(
										'screens.elementSettings.actionSelection.push.label.clean'
									)}
								</Label>
							</div>
						</div>
					</Grid>
					<Grid container alignItems='flex-start' className={classes.mb10}>
						<Grid item xs={12} lg={12} className={classes.mb10}>
							<Label>
								{t('screens.elementSettings.actionSelection.push.label.title')}
							</Label>
						</Grid>
						<Grid item xs={12} lg={12}>
							<MentionsInput
								name={`${props.actionIndex}-push-title`}
								whitelist={tagOptions}
								onChange={pushTitleOnChange}
								value={pushTitleDefault}
								multiline={false}
								valueKey='name'
								labelKey='label'
								inputStyle={{
									backgroundColor: props?.bgColor
										? props.bgColor
										: kozmikTheme.colors.kozmikGray,
									borderRadius: '3px !important',
									border: 'none',
									color: kozmikTheme.colors.textPrimary
								}}
							></MentionsInput>
						</Grid>
					</Grid>
					<Grid container alignItems='flex-start' className={classes.mb10}>
						<Grid item xs={12} lg={12} className={classes.mb10}>
							<Label>
								{t(
									'screens.elementSettings.actionSelection.push.label.message'
								)}
							</Label>
						</Grid>
						<Grid item xs={12} lg={12}>
							<MentionsInput
								name={`${props.actionIndex}-push-message`}
								whitelist={tagOptions}
								onChange={pushMessageOnChange}
								value={pushMessageDefault}
								multiline={true}
								valueKey='name'
								labelKey='label'
								inputStyle={{
									backgroundColor: props?.bgColor
										? props.bgColor
										: kozmikTheme.colors.kozmikGray,
									borderRadius: '3px !important',
									border: 'none',
									color: kozmikTheme.colors.textPrimary
								}}
							></MentionsInput>
						</Grid>
					</Grid>
				</>
			)}
			{actionType === 'email' && (
				<>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={3}>
							<Label>
								{t(
									'screens.elementSettings.actionSelection.email.label.audiences'
								)}
							</Label>
						</Grid>
						<Grid item xs={9}>
							<SelectBox
								className={classes.input}
								onChange={emailSendToOnChange}
								value={emailSendTo}
								data={sendToOpts}
								textKey='label'
								valueKey='value'
							/>
						</Grid>
					</Grid>
					{emailSendTo === 'specific' && (
						<Grid container alignItems='center' className={classes.mb10}>
							<Grid item xs={3}>
								<Label>
									{t('screens.elementSettings.actionSelection.email.label.to')}
								</Label>
							</Grid>
							<Grid item xs={9}>
								<Autocomplete
									sx={{
										'& .MuiOutlinedInput-root': {
											borderRadius: '4px',
											backgroundColor: '#ffffff',
											fontSize: '14px',
											color: kozmikTheme.colors.textPrimary
										}
									}}
									value={emailToSpecific}
									onChange={emailToSpecificOnChange}
									isOptionEqualToValue={(option, value) => {
										return option?.Id === value?.Id
									}}
									fullWidth={true}
									size='small'
									multiple={true}
									id='tags-outlined'
									options={usersData}
									getOptionLabel={(option) => {
										return option[usersDisplayField] || ''
									}}
									renderOption={(props, option) => {
										return (
											<div {...props} style={{ fontSize: 14 }}>
												{option[usersDisplayField] || ''}
											</div>
										)
									}}
									renderInput={(params) => (
										<BorderlessTextField
											{...params}
											placeholder={t(
												'screens.elementSettings.actionSelection.email.label.selectRecipients'
											)}
										/>
									)}
								/>
							</Grid>
						</Grid>
					)}
					{emailSendTo === 'field' && (
						<Grid container alignItems='center' className={classes.mb10}>
							<Grid item xs={3}>
								<Label>
									{t('screens.elementSettings.actionSelection.email.label.to')}
								</Label>
							</Grid>
							<Grid item xs={9}>
								<Dropdown
									bgColor={props?.bgColor}
									onItemClick={emailToFieldOnChange}
									value={emailToField}
									data={recipientsFieldOptions}
									textKey='label'
									valueKey='name'
									groupKey='label'
									groupKeySeperator='.'
									emptyText={t(
										'screens.elementSettings.actionSelection.email.label.noRecipients'
									)}
								/>
							</Grid>
						</Grid>
					)}
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={3}>
							<Label>
								{t('screens.elementSettings.actionSelection.email.label.when')}
							</Label>
						</Grid>
						<Grid item xs={9}>
							<SelectBox
								className={classes.input}
								onChange={emailWhenOnChange}
								value={emailWhen}
								data={whenOpts}
								textKey='label'
								valueKey='value'
							/>
						</Grid>
					</Grid>
					{emailWhen === 'field' && (
						<>
							<Grid container alignItems='center' className={classes.mb10}>
								<Grid item xs={3}>
									<Label>
										{t(
											'screens.elementSettings.actionSelection.email.label.whenField'
										)}
									</Label>
								</Grid>
								<Grid item xs={9}>
									<Dropdown
										bgColor={props?.bgColor}
										onItemClick={emailWhenFieldOnChange}
										value={emailWhenField}
										data={whenFieldOptions}
										textKey='label'
										valueKey='name'
										groupKey='label'
										groupKeySeperator='.'
									/>
								</Grid>
							</Grid>
							<Grid container alignItems='center' className={classes.mb10}>
								<Grid item xs={3}>
									<Label>
										{t(
											'screens.elementSettings.actionSelection.email.label.whenBefore'
										)}
									</Label>
								</Grid>
								<Grid item xs={4}>
									<BorderlessTextField
										type='number'
										size='small'
										variant='outlined'
										value={emailWhenBeforeValue}
										onChange={emailWhenBeforeValueOnChange}
										className={classes.input}
										InputProps={{
											classes: {
												input: classes.inputSize
											}
										}}
									/>
								</Grid>
								<Grid item xs={5} style={{ paddingLeft: 10 }}>
									<Dropdown
										bgColor={props?.bgColor}
										onItemClick={emailWhenBeforeUnitOnChange}
										value={emailWhenBeforeUnit}
										data={whenTimeOpts}
										textKey='label'
										valueKey='value'
										groupKey='label'
									/>
								</Grid>
							</Grid>
						</>
					)}
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={3}>
							<Label>
								{t(
									'screens.elementSettings.actionSelection.email.label.category'
								)}
							</Label>
						</Grid>
						<Grid item xs={9}>
							<BorderlessTextField
								fullWidth
								size='small'
								variant='outlined'
								value={emailCategory}
								onChange={emailCategoryOnChange}
								className={classes.input}
								InputProps={{
									classes: {
										input: classes.inputSize
									}
								}}
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={3}>
							<Label>
								{t(
									'screens.elementSettings.actionSelection.email.label.correlationId'
								)}
							</Label>
						</Grid>
						<Grid item xs={9}>
							<Dropdown
								bgColor={props?.bgColor}
								onItemClick={emailCorrelationIdOnChange}
								value={emailCorrelationId}
								data={emailCorrelationIdOptions}
								emptyText={t(
									'screens.elementSettings.actionSelection.email.label.emptyText'
								)}
								textKey='label'
								valueKey='name'
								groupKey='label'
								groupKeySeperator='.'
							/>
						</Grid>
					</Grid>
					<Grid item>
						<div className={cx(classes.item)}>
							<div>
								<KozmikCheckBox
									checked={emailClean}
									onChange={emailCleanChange}
								/>
							</div>
							<div className={cx(classes.labelBox, classes.growBox)}>
								<Label>
									{t(
										'screens.elementSettings.actionSelection.email.label.clean'
									)}
								</Label>
							</div>
						</div>
					</Grid>
					<Grid container alignItems='flex-start' className={classes.mb10}>
						<Grid item xs={12} lg={12} className={classes.mb10}>
							<Label>
								{t(
									'screens.elementSettings.actionSelection.email.label.subject'
								)}
							</Label>
						</Grid>
						<Grid item xs={12} lg={12}>
							<MentionsInput
								name={`${props.actionIndex}-email-subject`}
								whitelist={tagOptions}
								onChange={emailSubjectOnChange}
								value={emailSubjectDefault}
								multiline={false}
								valueKey='name'
								labelKey='label'
								inputStyle={{
									backgroundColor: props?.bgColor
										? props.bgColor
										: kozmikTheme.colors.kozmikGray,
									borderRadius: '3px !important',
									border: 'none',
									color: kozmikTheme.colors.textPrimary
								}}
							></MentionsInput>
						</Grid>
					</Grid>
					<Grid container alignItems='flex-start' className={classes.mb10}>
						<Grid item xs={12} lg={12} className={classes.mb10}>
							<Label>
								{t('screens.elementSettings.actionSelection.email.label.body')}
							</Label>
						</Grid>
						<Grid item xs={12} lg={12}>
							<MentionsInput
								name={`${props.actionIndex}-email-body`}
								whitelist={tagOptions}
								onChange={emailBodyOnChange}
								value={emailBodyDefault}
								multiline={true}
								valueKey='name'
								labelKey='label'
								inputStyle={{
									backgroundColor: props?.bgColor
										? props.bgColor
										: kozmikTheme.colors.kozmikGray,
									borderRadius: '3px !important',
									border: 'none',
									color: kozmikTheme.colors.textPrimary
								}}
							></MentionsInput>
						</Grid>
					</Grid>
				</>
			)}
			{actionType === 'invite' && (
				<>
					<Grid container alignItems='flex-start' className={classes.mb10}>
						<Grid item xs={12} lg={12} className={classes.mb10}>
							<Label>
								{t(
									'screens.elementSettings.actionSelection.invite.label.message'
								)}
							</Label>
						</Grid>
						<Grid item xs={12} lg={12}>
							<MentionsInput
								name={`${props.actionIndex}-invite-message`}
								whitelist={tagOptions}
								onChange={inviteMessageOnChange}
								value={inviteMessageDefault}
								multiline={true}
								valueKey='name'
								labelKey='label'
								inputStyle={{
									backgroundColor: props?.bgColor
										? props.bgColor
										: kozmikTheme.colors.kozmikGray,
									borderRadius: '3px !important',
									border: 'none',
									color: kozmikTheme.colors.textPrimary
								}}
							></MentionsInput>
						</Grid>
					</Grid>
				</>
			)}
			{(actionType === 'create' ||
				actionType === 'update' ||
				actionType === 'delete') && (
				<>
					{props.action?.source !== undefined && (
						<Grid container alignItems='center' className={classes.mb10}>
							<Grid item xs={4}>
								<Label>
									{t(
										'screens.elementSettings.actionSelection.data.label.source'
									)}
								</Label>
							</Grid>
							<Grid item xs={8}>
								<SelectBox
									className={classes.input}
									onChange={actionSourceOnChange}
									value={actionSource}
									data={actionSources}
									textKey='label'
									valueKey='name'
								/>
							</Grid>
						</Grid>
					)}
					{!!actionSourceFields?.length &&
						actionSourceFields.map((item, index) => {
							const fields = props.entities?.find(
								(x) => x.name === actionSource
							)?.fields
							const field = fields?.find((x) => x.name === item.field)

							return (
								<div key={`action_data_mapping_container_${index}`}>
									<Grid container alignItems='center' className={classes.mb10}>
										<Grid item xs={4}>
											<Label>{field.label}</Label>
										</Grid>
										<Grid item xs={8}>
											<ActionFieldMapping
												key={`action_data_mapping_${index}`}
												actions={props.actions}
												actionIndex={props.actionIndex}
												actionTypes={props.actionTypes}
												languageCode={languageCode}
												updateActionFieldMapping={updateActionFieldMapping}
												index={index}
												item={item}
												activeScreen={props.activeScreen}
												actionSource={actionSource}
												listSource={props.listSource}
												formSource={props.formSource}
												entities={props.entities}
												entityFields={fields}
												entityField={field}
											/>
										</Grid>
									</Grid>
								</div>
							)
						})}
				</>
			)}
		</>
	)
}

const mapStateToProps = (state) => ({
	...state.appData
})

export default connect(mapStateToProps)(ActionSelection)

import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { SwapVert, FilterAltOutlined, PlusOne } from '@mui/icons-material'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import Accordion from '../../../atoms/accordion/Accordion'
import NumericInput from '../../../atoms/numericInput/NumericInput'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import KozmikTextField from '../../../atoms/kozmikTextField/KozmikTextField'
import { default as Slider } from '../../../atoms/kozmikSlider/KozmikSlider'
import AccordionItem from '../../../atoms/accordionItem/AccordionItem'
import FilterList from '../../../molecules/filterList/FilterList'
import Sorting from '../../../molecules/sorting/Sorting'
import MoreOptions from '../../../molecules/moreOptions/MoreOptions'
import ActionList from '../../actionList/ActionList'
import FilterModalOptions from '../../../molecules/filterModalOptions/FilterModalOptions'
import SearchOptions from '../../../molecules/searchOptions/SearchOptions'
import EmptyOptions from '../../../molecules/emptyOptions/EmptyOptions'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import getFilterOpByDataType from '../../../../methods/getFilterOpByDataType'
import relationTypes from '../../../../common/enums/relationTypes'
import getArrayExcludingForbidArray from '../../../../methods/getArrayExcludingForbidArray'
import systemFieldNames from '../../../../common/systemFieldNames/systemFieldNames'

import { ReactComponent as KaroKoseli } from '../../../../assets/icons/Settings/KaroTip-Koseli.svg'
import { ReactComponent as KaroOval } from '../../../../assets/icons/Settings/KaroTip-Oval.svg'
import { ReactComponent as KaroDaire } from '../../../../assets/icons/Settings/KaroTip-Daire.svg'
import { ReactComponent as YonDikey } from '../../../../assets/icons/Settings/Yon-Dikey.svg'
import { ReactComponent as YonYatay } from '../../../../assets/icons/Settings/Yon-Yatay.svg'
import { ReactComponent as ImageOdakli } from '../../../../assets/icons/Settings/Image-Odakli.svg'
import { ReactComponent as ImageOrtali } from '../../../../assets/icons/Settings/Image-Ortali.svg'
import { ReactComponent as MetinAltta } from '../../../../assets/icons/Settings/Metin-Altta.svg'
import { ReactComponent as MetinUzerinde } from '../../../../assets/icons/Settings/Metin-Üzerinde.svg'
import { ReactComponent as MetinBoyutuKucuk } from '../../../../assets/icons/Settings/MetinBoyutu-Kucuk.svg'
import { ReactComponent as MetinBoyutuStandart } from '../../../../assets/icons/Settings/MetinBoyutu-Standart.svg'
import { ReactComponent as MetinBoyutuBuyuk } from '../../../../assets/icons/Settings/MetinBoyutu-Buyuk.svg'
import { ReactComponent as XSmall } from '../../../../assets/icons/Settings/TileExtraSmall.svg'
import { ReactComponent as Small } from '../../../../assets/icons/Settings/TileSmall.svg'
import { ReactComponent as Medium } from '../../../../assets/icons/Settings/TileMedium.svg'
import { ReactComponent as Large } from '../../../../assets/icons/Settings/TileLarge.svg'

import defaultElementSchemas from '../../../../defaultElementSchemas'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	inputLight: {
		borderRadius: 3,
		backgroundColor: '#ffffff',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const TileList = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [elementName, setElementName] = useState(null)

	const cornerOptions = [
		{
			text: t('screens.elementSettings.tileList.cornerOpts.square'),
			value: 'square',
			icon: KaroKoseli
		},
		{
			text: t('screens.elementSettings.tileList.cornerOpts.oval'),
			value: 'oval',
			icon: KaroOval
		},
		{
			text: t('screens.elementSettings.tileList.cornerOpts.circle'),
			value: 'circle',
			icon: KaroDaire
		}
	]
	const sizeOptions = [
		{
			text: t('screens.elementSettings.tileList.sizeOpts.xs'),
			value: 'xs',
			icon: XSmall
		},
		{
			text: t('screens.elementSettings.tileList.sizeOpts.sm'),
			value: 'sm',
			icon: Small
		},
		{
			text: t('screens.elementSettings.tileList.sizeOpts.md'),
			value: 'md',
			icon: Medium
		},
		{
			text: t('screens.elementSettings.tileList.sizeOpts.lg'),
			value: 'lg',
			icon: Large
		}
	]
	const directionOptions = [
		//NOTE: horizontal
		{
			text: t('screens.elementSettings.tileList.directionOpts.vert'),
			value: false,
			icon: YonDikey
		},
		{
			text: t('screens.elementSettings.tileList.directionOpts.hori'),
			value: true,
			icon: YonYatay
		}
	]
	const imageOptions = [
		{
			text: t('screens.elementSettings.tileList.imgOpts.cover'),
			value: 'cover',
			icon: ImageOdakli
		},
		{
			text: t('screens.elementSettings.tileList.imgOpts.contain'),
			value: 'contain',
			icon: ImageOrtali
		}
	]
	const positionOptions = [
		//NOTE: square
		{
			text: t('screens.elementSettings.tileList.posOpts.bottom'),
			value: false,
			icon: MetinAltta
		},
		{
			text: t('screens.elementSettings.tileList.posOpts.top'),
			value: true,
			icon: MetinUzerinde
		}
	]
	const fontSizeOptions = [
		{
			text: t('screens.elementSettings.tileList.fontOpts.sm'),
			value: 'sm',
			icon: MetinBoyutuKucuk
		},
		{
			text: t('screens.elementSettings.tileList.fontOpts.md'),
			value: 'md',
			icon: MetinBoyutuStandart
		},
		{
			text: t('screens.elementSettings.tileList.fontOpts.lg'),
			value: 'lg',
			icon: MetinBoyutuBuyuk
		}
	]

	/****SOURCE***************************/
	const [source, setSource] = useState('')

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		fieldDataTypes.textBased,
		null,
		true
		// relationTypes.one
	)
	const imageFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		['Image'],
		null,
		true,
		relationTypes.one
	)

	const sourceChange = (e) => {
		submitSource(source, e.target.value)
		setSource(e.target.value)
	}

	const submitSource = (oldTableName, tableName) => {
		const table = props?.entities?.find((y) => y.name == tableName)

		const txtFields = getEntityFieldsByDataTypes(
			props?.entities,
			tableName,
			fieldDataTypes.textBased,
			null,
			true
			// relationTypes.one
		)
		const imgFields = getEntityFieldsByDataTypes(
			props?.entities,
			tableName,
			['Image'],
			null,
			true,
			relationTypes.one
		)

		const firstImgField = imgFields?.length ? imgFields[0] : null

		const filteredTxtFields = getArrayExcludingForbidArray(
			txtFields,
			'name',
			systemFieldNames.all
			// systemFieldNames.notVisible
		)
		const titleField = txtFields?.find((x) => x.name == 'Name')

		let modifiedElementData = update(props.elementData, {
			source: { $set: tableName },
			mapping: {
				title: {
					field: { $set: titleField?.name },
					type: { $set: titleField?.dataType }
				},
				text: {
					field: { $set: filteredTxtFields?.[0]?.name },
					type: { $set: filteredTxtFields?.[0]?.dataType }
				},
				imgUrl: {
					field: { $set: firstImgField?.name },
					type: { $set: firstImgField?.dataType }
				}
			},
			sorting: {
				field: { $set: titleField?.name },
				sort: { $set: 'asc' }
			},
			filters: {
				groupOp: { $set: 'AND' },
				rules: { $set: [] }
			},
			seeAllUrl: {
				$set: props.elementData?.seeAllUrl
			},
			filterModal: {
				$set: defaultElementSchemas.filterModal()
			}
		})

		props.updateBulkScreensWithModifiedData(
			table.name,
			null,
			modifiedElementData
		)
	}

	/****TITLE***************************/
	const [title, setTitle] = useState('')

	const titleChange = (e) => {
		submitTitle(e.target.value)
		setTitle(e.target.value)
	}

	const submitTitle = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)

		const selectedFieldFilterOps = selectedField
			? getFilterOpByDataType(selectedField.dataType)
			: null

		const modifiedElementData = update(props.elementData, {
			mapping: {
				title: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****TEXT***************************/
	const [text, setText] = useState('')

	const textChange = (e) => {
		submitText(e.target.value)
		setText(e.target.value)
	}

	const submitText = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				text: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****IMGURL***************************/
	const [imgUrl, setImgUrl] = useState('')

	const imgUrlChange = (e) => {
		submitImgUrl(e.target.value)
		setImgUrl(e.target.value)
	}

	const submitImgUrl = (x) => {
		const selectedField = imageFields?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				imgUrl: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ACTIONS***************************/
	const updateActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			actions: { $set: newActions }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****LIMIT***************************/
	const [limit, setLimit] = useState(25)
	const limitChange = (val) => {
		timeoutDelay(submitLimit, val, 1000)
		setLimit(val)
	}
	const submitLimit = (x) => {
		const modifiedElementData = update(props.elementData, {
			limit: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****CORNER***************************/
	const [corner, setCorner] = useState(null)

	const cornerChange = (val) => {
		submitCorner(val)
		setCorner(val)
	}

	const submitCorner = (x) => {
		const modifiedElementData = update(props.elementData, {
			corner: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****HORIZONTAL***************************/
	const [horizontal, setHorizontal] = useState(null)

	const horizontalChange = (val) => {
		submitHorizontal(val)
		setHorizontal(val)
	}

	const submitHorizontal = (x) => {
		const modifiedElementData = update(props.elementData, {
			horizontal: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****IMAGE_RESIZE_MODE***************************/
	const [imageResizeMode, setImageResizeMode] = useState(null)

	const imageResizeModeChange = (val) => {
		submitImageResizeMode(val)
		setImageResizeMode(val)
	}

	const submitImageResizeMode = (x) => {
		const modifiedElementData = update(props.elementData, {
			imageResizeMode: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****SQUARE***************************/
	const [square, setSquare] = useState(null)

	const squareChange = (val) => {
		submitSquare(val)
		setSquare(val)
	}

	const submitSquare = (x) => {
		const modifiedElementData = update(props.elementData, {
			square: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****FONT_SIZE***************************/
	const [fontSize, setFontSize] = useState(null)

	const fontSizeChange = (val) => {
		submitFontSize(val)
		setFontSize(val)
	}

	const submitFontSize = (x) => {
		const modifiedElementData = update(props.elementData, {
			fontSize: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****SIZE***************************/
	const [size, setSize] = useState(null)

	const sizeChange = (val) => {
		submitSize(val)
		setSize(val)
	}

	const submitSize = (x) => {
		const modifiedElementData = update(props.elementData, {
			size: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****FILTER_&_SORT***************************/
	const [localElementData, setLocalElementData] = useState(null)

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****SOURCE***************************/
		setSource(elementData?.source ? elementData.source : '')
		/****TITLE***************************/
		setTitle(
			elementData?.mapping?.title?.field ? elementData.mapping.title.field : ''
		)
		/****TEXT***************************/
		setText(
			elementData?.mapping?.text?.field ? elementData.mapping.text.field : ''
		)
		/****IMGURL***************************/
		setImgUrl(
			elementData?.mapping?.imgUrl?.field
				? elementData.mapping.imgUrl.field
				: ''
		)
		/****LIMIT***************************/
		setLimit(elementData?.limit ?? 10)
		/****CORNER***************************/
		setCorner(elementData?.corner)
		/****HORIZONTAL***************************/
		setHorizontal(elementData?.horizontal)
		/****IMAGE_RESIZE_MODE***************************/
		setImageResizeMode(elementData?.imageResizeMode)
		/****SQUARE***************************/
		setSquare(elementData?.square)
		/****FONT_SIZE***************************/
		setFontSize(elementData?.fontSize)
		/****SIZE***************************/
		setSize(elementData?.size)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
			setLocalElementData(props.elementData)
		}
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.source')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={12}>
							<SelectBox
								className={classes.input}
								onChange={sourceChange}
								value={source}
								data={props.entities}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>

					<div className={classes.mb10}>
						<AccordionItem
							icon={FilterAltOutlined}
							title={t('screens.elementSettings.tileList.filter')}
							expanded={false}
							padding={8}
						>
							<FilterList
								filter={localElementData?.filters}
								table={props?.entities?.find(
									(y) => y.name == props?.elementData?.source
								)}
								activeScreen={props?.activeScreen}
								entities={props?.entities}
								tableName={props?.elementData?.source}
								screenTable={props?.entities?.find(
									(y) => y.name == props.activeScreen?.data?.source
								)}
								filtering={props.filtering}
								setLocalElementData={setLocalElementData}
							/>
						</AccordionItem>
					</div>

					<div className={classes.mb10}>
						<AccordionItem
							icon={SwapVert}
							title={t('screens.elementSettings.tileList.sort')}
							expanded={false}
							padding={8}
						>
							<Sorting
								sortingData={localElementData?.sorting}
								sorting={props.sorting}
								fieldOptions={textFields}
							/>
						</AccordionItem>
					</div>

					<div className={classes.mb10}>
						<AccordionItem
							icon={PlusOne}
							title={t('screens.elementSettings.tileList.limit')}
							expanded={false}
							padding={8}
						>
							<NumericInput minVal={0} value={limit} onChange={limitChange} />
						</AccordionItem>
					</div>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.tileList.title')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.tileList.emptyField')}
								className={classes.input}
								onChange={titleChange}
								value={title}
								data={textFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.tileList.desc')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.tileList.emptyField')}
								className={classes.input}
								onChange={textChange}
								value={text}
								data={textFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.tileList.image')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.tileList.emptyField')}
								className={classes.input}
								onChange={imgUrlChange}
								value={imgUrl}
								data={imageFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.tileList.tileType')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={cornerOptions}
								getChange={cornerChange}
								activeValue={corner}
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.tileList.tileSize')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={sizeOptions}
								getChange={sizeChange}
								activeValue={size}
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.tileList.direction')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={directionOptions}
								getChange={horizontalChange}
								activeValue={horizontal}
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.tileList.imgUsage')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={imageOptions}
								getChange={imageResizeModeChange}
								activeValue={imageResizeMode}
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.tileList.position')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={positionOptions}
								getChange={squareChange}
								activeValue={square}
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.tileList.size')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={fontSizeOptions}
								getChange={fontSizeChange}
								activeValue={fontSize}
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.empty')}
					expanded={true}
				>
					<EmptyOptions
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.more')}
					expanded={true}
				>
					<MoreOptions
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.search')}
					expanded={true}
				>
					<SearchOptions
						source={source}
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.inAppFilter')}
					expanded={false}
				>
					{source && (
						<FilterModalOptions
							source={source}
							activeScreen={props.activeScreen}
							elementData={props.elementData}
							entities={props.entities}
							sorting={props.sorting}
							filtering={props.filtering}
							updateSelectedElement={props.updateSelectedElement}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.actions')}
					expanded={true}
				>
					{source && (
						<ActionList
							name={elementName}
							entities={props.entities}
							activeScreen={props.activeScreen}
							actions={props.elementData.actions}
							updateActions={updateActions}
							isList={true}
							listSource={source}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default TileList

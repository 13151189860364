const validateWithRegExp = (text, regExp) => {
	return new Promise((resolve) => {
		if (!text) resolve(true)

		if (regExp) {
			const isValid = regExp?.test(text)
			if (isValid) {
				resolve(true)
			} else {
				resolve(false)
			}
		} else {
			resolve(true)
		}
	})
}

export default validateWithRegExp

const builtInScreenPaths = [
	'login',
	'signup',
	'forgotpassword',
	'resetpassword',
	'invitation',
	'changepassword',
	'*'
]

export default builtInScreenPaths

import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import {
	Box,
	Grid,
	Popover,
	Button,
	List,
	ListItem,
	ListItemButton,
	ListItemText
} from '@mui/material'
import {
	AddBox,
	DeleteOutlined,
	ToggleOnOutlined,
	CheckBoxOutlined
} from '@mui/icons-material'

import FilterModalElement from '../filterModalElement/FilterModalElement'
import PopoverItem from '../popoverItem/PopoverItem'

import systemFieldNames from '../../../common/systemFieldNames/systemFieldNames'
import { getInputByDataType } from '../../../defaultElementSchemas/schemas/form'
import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'
import AccordionItem from '../../atoms/accordionItem/AccordionItem'
import SquareOptions from '../squareOprions/SquareOptions'
import IoniconsSelect from '../IoniconsSelect/IoniconsSelect'
import timeoutDelay from '../../../methods/timeoutDelay'
import { default as Checkbox } from '../../atoms/kozmikCheckBox/KozmikCheckBox'
import { default as Label } from '../../atoms/settingLabel/SettingLabel'

import { ReactComponent as Koseli } from '../../../assets/icons/Settings/Koseli.svg'
import { ReactComponent as Oval } from '../../../assets/icons/Settings/Oval.svg'
import { ReactComponent as Dropdown } from '../../../assets/icons/Settings/Dropdown.svg'
import { ReactComponent as Chips } from '../../../assets/icons/Settings/Chips.svg'
import { ReactComponent as Radio } from '../../../assets/icons/Settings/Radio.svg'
import { ReactComponent as CheckList } from '../../../assets/icons/Settings/CheckList.svg'
import { ReactComponent as ButonDolu } from '../../../assets/icons/Settings/Buton-Filled.svg'
import { ReactComponent as ButonTransparan } from '../../../assets/icons/Settings/Buton-Transparent.svg'
import { ReactComponent as ButonCerceveli } from '../../../assets/icons/Settings/Buton-Framed.svg'
import { ReactComponent as ButonNone } from '../../../assets/icons/Settings/Button-None.svg'

const getListStyle = (isDraggingOver) => ({
	background: isDraggingOver ? '#D7DBFB' : 'transparent',
	borderRadius: 6,
	padding: 2,
	width: '100%'
})

const getElementStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	margin: `0 0 10px 0`,
	borderRadius: 6,
	border: isDragging ? '2px dashed gray' : 'none',
	overflow: isDragging ? 'hidden' : 'visible',
	cursor: 'default',

	...draggableStyle
})

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	popoverBox: {
		padding: 8,
		width: 200
	},
	popoverIcon: {
		color: '#000000',
		opacity: 0.7,
		width: '14px'
	}
}))

const FilterModalOptions = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const localTable = props?.entities?.find((x) => x.name == props.source)
	const fields = localTable?.fields ?? []

	const [selectedElementIndex, setSelectedElementIndex] = useState(null)

	const checkboxTypeOptions = [
		{
			text: t('screens.elementSettings.form.checkboxTypeOpts.check'),
			value: 'checkbox',
			icon: CheckBoxOutlined
		},
		{
			text: t('screens.elementSettings.form.checkboxTypeOpts.switch'),
			value: 'switch',
			icon: ToggleOnOutlined
		}
	]

	const choiceTypeOptions = [
		{
			text: t('screens.elementSettings.choice.typeOpts.dropdown'),
			value: 'dropdown',
			icon: Dropdown
		},
		{
			text: t('screens.elementSettings.choice.typeOpts.chips'),
			value: 'chips',
			icon: Chips
		},
		{
			text: t('screens.elementSettings.choice.typeOpts.radio'),
			value: 'radio',
			icon: Radio
		},
		{
			text: t('screens.elementSettings.choice.typeOpts.checkbox'),
			value: 'checkbox',
			icon: CheckList
		}
	]

	const btnTypeOptions = [
		{
			text: t('screens.elementSettings.form.typeOpts.full'),
			value: 'full',
			icon: ButonDolu
		},
		{
			text: t('screens.elementSettings.form.typeOpts.transparent'),
			value: 'transparent',
			icon: ButonTransparan
		},
		{
			text: t('screens.elementSettings.form.typeOpts.border'),
			value: 'border',
			icon: ButonCerceveli
		},
		{
			text: t('screens.elementSettings.form.typeOpts.none'),
			value: 'none',
			icon: ButonNone
		}
	]

	const cornerOptions = [
		{
			text: t('screens.elementSettings.form.cornerOpts.square'),
			value: 'square',
			icon: Koseli
		},
		{
			text: t('screens.elementSettings.form.cornerOpts.oval'),
			value: 'oval',
			icon: Oval
		}
	]

	const onDragEnd = (result) => {
		const { destination } = result

		if (!destination) {
			return
		}

		const elements = reorder(
			localElementData?.filterModal?.body,
			result.source.index,
			result.destination.index
		)

		updateModalBody(elements)
	}

	/****FILTER_MODAL_TITLE***************************/
	const [filterModalTitle, setFilterModalTitle] = useState('')

	const filterModalTitleChange = (e) => {
		timeoutDelay(submitFilterModalTitle, e.target.value, 1000)
		setFilterModalTitle(e.target.value)
	}

	const submitFilterModalTitle = (x) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				title: {
					default: { $set: x }
				}
			}
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****ELEMENTS***************************/
	const [localElementData, setLocalElementData] = useState(null)

	const elementTitleChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				body: {
					[index]: {
						title: { $set: value }
					}
				}
			}
		})
		timeoutDelay(props.updateSelectedElement, modifiedElementData, 1000)
		setLocalElementData(modifiedElementData)
	}

	const elementOperatorChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				body: {
					[index]: {
						operator: { $set: value }
					}
				}
			}
		})
		timeoutDelay(props.updateSelectedElement, modifiedElementData, 1000)
		setLocalElementData(modifiedElementData)
	}

	const elementLabelChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				body: {
					[index]: {
						label: {
							default: { $set: value }
						}
					}
				}
			}
		})

		timeoutDelay(props.updateSelectedElement, modifiedElementData, 1000)
		setLocalElementData(modifiedElementData)
	}

	const elementPlaceholderChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				body: {
					[index]: {
						placeholder: {
							default: { $set: value }
						}
					}
				}
			}
		})

		timeoutDelay(props.updateSelectedElement, modifiedElementData, 1000)
		setLocalElementData(modifiedElementData)
	}

	const elementPrecisionChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				body: {
					[index]: {
						precision: { $set: value }
					}
				}
			}
		})

		props.updateSelectedElement(modifiedElementData)
		setLocalElementData(modifiedElementData)
	}

	const elementLimitChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				body: {
					[index]: {
						limit: { $set: value }
					}
				}
			}
		})

		timeoutDelay(props.updateSelectedElement, modifiedElementData, 1000)
		setLocalElementData(modifiedElementData)
	}

	const elementCheckBoxTypeChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				body: {
					[index]: {
						type: { $set: value }
					}
				}
			}
		})

		props.updateSelectedElement(modifiedElementData)
		setLocalElementData(modifiedElementData)
	}

	const elementTypeChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				body: {
					[index]: {
						type: { $set: value }
					}
				}
			}
		})

		props.updateSelectedElement(modifiedElementData)
		setLocalElementData(modifiedElementData)
	}

	const elementScannerChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				body: {
					[index]: {
						scanner: { $set: value }
					}
				}
			}
		})

		props.updateSelectedElement(modifiedElementData)
		setLocalElementData(modifiedElementData)
	}

	const elementRelationFieldChange = (index, value) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				body: {
					[index]: {
						relation: {
							field: { $set: value }
						}
					}
				}
			}
		})

		props.updateSelectedElement(modifiedElementData)
		setLocalElementData(modifiedElementData)
	}

	const deleteElement = () => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				body: {
					$splice: [[selectedElementIndex, 1]]
				}
			}
		})

		props.updateSelectedElement(modifiedElementData)
		setLocalElementData(modifiedElementData)
		setSelectedElementIndex(null)
	}

	const updateModalBody = (elements) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: { body: { $set: elements } }
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****ADD_ELEMENT***************************/
	const [unusedFields, setUnusedFields] = useState(null)

	const updateUnusedFields = (elementData) => {
		// const usedFields = elementData?.filterModal.body?.length
		// 	? elementData?.filterModal.body.map((x) => x?.mapping?.field)
		// 	: []

		const availableFields = fields?.filter(
			(x) =>
				// !systemFieldNames.notVisible.includes(x.name) &&
				// x.dataType !== 'Lookup' &&
				x.dataType !== 'Image' && x.dataType !== 'File'
			//&&
			// !usedFields?.includes(x.name)
		)

		setUnusedFields(availableFields)
	}

	const addNewElement = (field) => {
		const operator =
			(field.dataType === 'Relation'
				? 'rcn'
				: field.dataType === 'Number' ||
				  field.displayFieldDataType === 'Number' ||
				  field.dataType === 'Checkbox' ||
				  field.displayFieldDataType === 'Checkbox'
				? 'eq'
				: field.dataType === 'Date' || field.displayFieldDataType === 'Date'
				? 'deq'
				: 'cn') ?? 'cn'

		const newElement = getInputByDataType(
			{
				...field,
				isRequired: false,
				dataType:
					field.dataType === 'Relation'
						? field.dataType
						: field.displayFieldDataType ?? field.dataType
			},
			false
		)

		const modifiedElementData = update(props.elementData, {
			filterModal: {
				body: {
					$push: [{ ...newElement, operator: operator }]
				}
			}
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****ADD_ELEMENT_POPOVER***************************/
	const [anchorEl, setAnchorEl] = useState(null)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	/****FILTER_BUTTON***************************/
	const [filterText, setFilterText] = useState('')
	const [filterIcon, setFilterIcon] = useState('')
	const [filterType, setFilterType] = useState(null)
	const [filterCorner, setFilterCorner] = useState(null)

	const filterTextChange = (e) => {
		timeoutDelay(submitFilterText, e.target.value, 1000)
		setFilterText(e.target.value)
	}

	const submitFilterText = (x) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				buttons: {
					filter: {
						text: {
							default: { $set: x }
						}
					}
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/******************/
	const filterIconChange = (val) => {
		submitFilterIcon(val)
		setFilterIcon(val)
	}

	const submitFilterIcon = (x) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				buttons: {
					filter: {
						icon: { $set: x }
					}
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/******************/
	const filterTypeChange = (val) => {
		submitFilterType(val)
		setFilterType(val)
	}

	const submitFilterType = (x) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				buttons: {
					filter: {
						type: { $set: x }
					}
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/******************/
	const filterCornerChange = (val) => {
		submitFilterCorner(val)
		setFilterCorner(val)
	}

	const submitFilterCorner = (x) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				buttons: {
					filter: {
						corner: { $set: x }
					}
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****CLEAR_BUTTON***************************/
	const [clearText, setClearText] = useState('')
	const [clearIcon, setClearIcon] = useState('')
	const [clearType, setClearType] = useState(null)
	const [clearCorner, setClearCorner] = useState(null)

	/******************/
	const clearTextChange = (e) => {
		timeoutDelay(submitClearText, e.target.value, 1000)
		setClearText(e.target.value)
	}

	const submitClearText = (x) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				buttons: {
					clear: {
						text: {
							default: { $set: x }
						}
					}
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/******************/
	const clearIconChange = (val) => {
		submitClearIcon(val)
		setClearIcon(val)
	}

	const submitClearIcon = (x) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				buttons: {
					clear: {
						icon: { $set: x }
					}
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/******************/
	const clearTypeChange = (val) => {
		submitClearType(val)
		setClearType(val)
	}

	const submitClearType = (x) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				buttons: {
					clear: {
						type: { $set: x }
					}
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/******************/
	const clearCornerChange = (val) => {
		submitClearCorner(val)
		setClearCorner(val)
	}

	const submitClearCorner = (x) => {
		const modifiedElementData = update(props.elementData, {
			filterModal: {
				buttons: {
					clear: {
						corner: { $set: x }
					}
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	const [elementPop, setElementPop] = useState(null)
	const elementPopOpen = Boolean(elementPop)

	const openPop = (event, index) => {
		setSelectedElementIndex(index)
		setElementPop(event.currentTarget)
	}

	const closePop = () => {
		setElementPop(null)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****FILTER_MODAL_TITLE***************************/
		setFilterModalTitle(elementData?.filterModal?.title?.default || '')
		/****FILTER_BUTTON***************************/
		setFilterText(
			elementData?.filterModal?.buttons?.filter?.text?.default || ''
		)
		setFilterIcon(elementData?.filterModal?.buttons?.filter?.icon || '')
		setFilterType(elementData?.filterModal?.buttons?.filter?.type || null)
		setFilterCorner(elementData?.filterModal?.buttons?.filter?.corner || null)
		/****CLEAR_BUTTON***************************/
		setClearText(elementData?.filterModal?.buttons?.clear?.text?.default || '')
		setClearIcon(elementData?.filterModal?.buttons?.clear?.icon || '')
		setClearType(elementData?.filterModal?.buttons?.clear?.type || null)
		setClearCorner(elementData?.filterModal?.buttons?.clear?.corner || null)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
			updateUnusedFields(props.elementData)
			setLocalElementData(props.elementData)
		}
	}, [props.elementData, props.source])

	return (
		<div>
			<Grid container alignItems='center' className={classes.mb10}>
				<Grid item xs={4}>
					<Label>
						{t('screens.elementSettings.common.labels.filterTitle')}
					</Label>
				</Grid>
				<Grid item xs={8}>
					<KozmikTextField
						fullWidth
						placeholder={t('screens.elementSettings.common.filterModal.title')}
						value={filterModalTitle}
						onChange={filterModalTitleChange}
					/>
				</Grid>
			</Grid>
			<div className={classes.mb10}>
				<AccordionItem
					title={t('screens.elementSettings.common.filterModal.filters')}
					expanded={true}
				>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId='droppable'>
							{(provided, snapshot) => (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
								>
									{!!localElementData?.filterModal?.body?.length &&
										localElementData?.filterModal?.body?.map((item, index) => (
											<Draggable
												key={item._uid}
												draggableId={item._uid}
												index={index}
											>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														style={getElementStyle(
															snapshot.isDragging,
															provided.draggableProps.style
														)}
													>
														<div key={index} className={classes.mb10}>
															<AccordionItem
																bgColor='#F0F0F4'
																title={
																	fields?.find(
																		(x) => x.name === item?.mapping?.field
																	)?.label || item?.mapping?.field
																}
																expanded={selectedElementIndex === index}
																onChange={() => (event, newExpanded) => {
																	setSelectedElementIndex(
																		selectedElementIndex === index
																			? null
																			: index
																	)
																}}
																moreClick={(e) => openPop(e, index)}
															>
																<FilterModalElement
																	source={props.source}
																	activeScreen={props.activeScreen}
																	field={fields?.find(
																		(x) => x.name === item?.mapping?.field
																	)}
																	element={item}
																	index={index}
																	elementOperatorChange={elementOperatorChange}
																	elementTitleChange={elementTitleChange}
																	elementLabelChange={elementLabelChange}
																	elementPlaceholderChange={
																		elementPlaceholderChange
																	}
																	elementPrecisionChange={
																		elementPrecisionChange
																	}
																	elementLimitChange={elementLimitChange}
																	elementTypeChange={elementTypeChange}
																	choiceTypeOptions={choiceTypeOptions}
																	checkboxTypeOptions={checkboxTypeOptions}
																	elementCheckBoxTypeChange={
																		elementCheckBoxTypeChange
																	}
																	entities={props.entities}
																	elementRelationFieldChange={
																		elementRelationFieldChange
																	}
																	elementScannerChange={elementScannerChange}
																	sorting={props.sorting}
																	filtering={props.filtering}
																/>
															</AccordionItem>
															<Popover
																open={elementPopOpen}
																anchorEl={elementPop}
																onClose={() => {
																	closePop()
																}}
																anchorOrigin={{
																	vertical: 'bottom',
																	horizontal: 'center'
																}}
																transformOrigin={{
																	vertical: 'top',
																	horizontal: 'center'
																}}
															>
																<Box className={classes.popoverBox}>
																	<PopoverItem
																		text={t(
																			'screens.elementSettings.common.filterModal.element.delete'
																		)}
																		onClick={() => {
																			closePop()
																			deleteElement()
																		}}
																		color='#FF2619'
																	>
																		<DeleteOutlined
																			className={classes.popoverIcon}
																			style={{ color: '#FF2619', opacity: 1 }}
																		/>
																	</PopoverItem>
																</Box>
															</Popover>
														</div>
													</div>
												)}
											</Draggable>
										))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>

					<div
						style={{ width: '100%', position: 'relative', padding: 2 }}
						className={classes.mb10}
					>
						<Button
							aria-describedby={id}
							startIcon={<AddBox />}
							fullWidth
							size='small'
							variant='contained'
							onClick={unusedFields?.length ? handleClick : null}
							sx={{
								textTransform: 'none',
								backgroundColor: '#D7DBFB !important', //kozmikLightBlue
								color: '#1B36FE', //kozmikBlue
								boxShadow: 'none',
								fontSize: '14px',
								borderRadius: '6px'
							}}
						>
							{t('screens.elementSettings.common.filterModal.addNewFilter')}
						</Button>

						{!!unusedFields?.length && (
							<Popover
								id={id}
								open={open}
								anchorEl={anchorEl}
								onClose={handleClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left'
								}}
								PaperProps={{
									style: { right: 17 } //NOTE:for 100% width of parent container
								}}
							>
								<List>
									{unusedFields.map((item, index) => (
										<ListItem disablePadding key={`form_element_${index}`}>
											<ListItemButton
												style={{ height: 35 }}
												onClick={() => {
													handleClose()
													addNewElement(item)
												}}
											>
												<ListItemText
													primary={item.label}
													primaryTypographyProps={{ fontSize: 13 }}
												/>
											</ListItemButton>
										</ListItem>
									))}
								</List>
							</Popover>
						)}
					</div>
				</AccordionItem>
			</div>
			<div>
				<AccordionItem
					title={t('screens.elementSettings.common.filterModal.buttons')}
					expanded={true}
				>
					<div className={classes.mb10}>
						<AccordionItem
							title={t('screens.elementSettings.common.filterModal.btn.filter')}
							expanded={false}
							bgColor='#F0F0F4'
						>
							<Grid container alignItems='center' className={classes.mb10}>
								<Grid item xs={4}>
									<Label>
										{t('screens.elementSettings.common.filterModal.btn.title')}
									</Label>
								</Grid>
								<Grid item xs={8}>
									<KozmikTextField
										fullWidth
										value={filterText}
										onChange={filterTextChange}
										bgColor='#ffffff'
									/>
								</Grid>
							</Grid>
							<Grid
								container
								alignItems='center'
								justifyContent='space-between'
								className={classes.mb10}
							>
								<Grid item xs={4}>
									<Label>
										{t('screens.elementSettings.common.filterModal.btn.icon')}
									</Label>
								</Grid>
								<Grid item xs={4}>
									<IoniconsSelect
										onChange={filterIconChange}
										value={filterIcon}
										emptySelectable
										bgColor='#ffffff'
										fullWidth
									/>
								</Grid>
							</Grid>
							<Grid container alignItems='center' className={classes.mb10}>
								<Grid item xs={4}>
									<Label>
										{t('screens.elementSettings.common.filterModal.btn.style')}
									</Label>
								</Grid>
								<Grid item xs={8}>
									<SquareOptions
										boxColor='#ffffff'
										compact
										data={btnTypeOptions}
										getChange={filterTypeChange}
										activeValue={filterType}
									/>
								</Grid>
							</Grid>
							{filterType !== 'none' && (
								<Grid container alignItems='center' className={classes.mb10}>
									<Grid item xs={4}>
										<Label>
											{t(
												'screens.elementSettings.common.filterModal.btn.corners'
											)}
										</Label>
									</Grid>
									<Grid item xs={8}>
										<SquareOptions
											boxColor='#ffffff'
											compact
											data={cornerOptions}
											getChange={filterCornerChange}
											activeValue={filterCorner}
										/>
									</Grid>
								</Grid>
							)}
						</AccordionItem>
					</div>
					<div className={classes.mb10}>
						<AccordionItem
							title={t('screens.elementSettings.common.filterModal.btn.clear')}
							expanded={false}
							bgColor='#F0F0F4'
						>
							<Grid container alignItems='center' className={classes.mb10}>
								<Grid item xs={4}>
									<Label>
										{t('screens.elementSettings.common.filterModal.btn.title')}
									</Label>
								</Grid>
								<Grid item xs={8}>
									<KozmikTextField
										fullWidth
										value={clearText}
										onChange={clearTextChange}
										bgColor='#ffffff'
									/>
								</Grid>
							</Grid>
							<Grid
								container
								alignItems='center'
								justifyContent='space-between'
								className={classes.mb10}
							>
								<Grid item xs={4}>
									<Label>
										{t('screens.elementSettings.common.filterModal.btn.icon')}
									</Label>
								</Grid>
								<Grid item xs={4}>
									<IoniconsSelect
										onChange={clearIconChange}
										value={clearIcon}
										emptySelectable
										bgColor='#ffffff'
										fullWidth
									/>
								</Grid>
							</Grid>
							<Grid container alignItems='center' className={classes.mb10}>
								<Grid item xs={4}>
									<Label>
										{t('screens.elementSettings.common.filterModal.btn.style')}
									</Label>
								</Grid>
								<Grid item xs={8}>
									<SquareOptions
										boxColor='#ffffff'
										compact
										data={btnTypeOptions}
										getChange={clearTypeChange}
										activeValue={clearType}
									/>
								</Grid>
							</Grid>
							{clearType !== 'none' && (
								<Grid container alignItems='center' className={classes.mb10}>
									<Grid item xs={4}>
										<Label>
											{t(
												'screens.elementSettings.common.filterModal.btn.corners'
											)}
										</Label>
									</Grid>
									<Grid item xs={8}>
										<SquareOptions
											boxColor='#ffffff'
											compact
											data={cornerOptions}
											getChange={clearCornerChange}
											activeValue={clearCorner}
										/>
									</Grid>
								</Grid>
							)}
						</AccordionItem>
					</div>
				</AccordionItem>
			</div>
		</div>
	)
}

export default FilterModalOptions

import { ApiClient } from './client'

import { default as appConfig } from '../config/app.json'

const dataEntityUrl = `${appConfig.domains.dataDomain}/api/entity`
const dataFieldUrl = `${appConfig.domains.dataDomain}/api/field`

export default {
	async entities(appId, params) {
		let headers = {}
		let data = params ? { params: params } : null
		headers['AppId'] = appId

		return await new ApiClient(dataEntityUrl, headers).get('/entities', data)
	},
	async metadata(appId, entityId) {
		let url = `/metadata/${entityId}`
		let headers = {}
		headers['AppId'] = appId

		return await new ApiClient(dataEntityUrl, headers).get(url)
	},
	async createEntity(appId, data = null, headers = {}) {
		headers['AppId'] = appId

		return await new ApiClient(dataEntityUrl, headers).post('/create', data)
	},
	async updateEntity(appId, data = null, headers = {}) {
		headers['Content-Type'] = 'application/json'
		headers['AppId'] = appId

		return await new ApiClient(dataEntityUrl, headers).post('/update', data)
	},
	async deleteEntity(appId, entityId, headers = {}) {
		let url = `/delete/${entityId}`
		headers['Content-Type'] = 'application/json'
		headers['AppId'] = appId

		return await new ApiClient(dataEntityUrl, headers).post(url)
	},
	async createField(appId, data = null, headers = {}) {
		headers['AppId'] = appId
		headers['Content-Type'] = 'application/json'

		return await new ApiClient(dataFieldUrl, headers).post('/create', data)
	},
	async updateField(appId, data = null, headers = {}) {
		headers['Content-Type'] = 'application/json'
		headers['AppId'] = appId

		return await new ApiClient(dataFieldUrl, headers).post('/update', data)
	},
	async deleteField(appId, fieldId, headers = {}) {
		let url = `/delete/${fieldId}`
		headers['AppId'] = appId

		return await new ApiClient(dataFieldUrl, headers).post(url)
	},
	async reOrder(appId, data, headers = {}) {
		headers['AppId'] = appId

		return await new ApiClient(dataEntityUrl, headers).post('/reOrder', data)
	}
}

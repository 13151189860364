import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Popover } from '@mui/material'
import { connect } from 'react-redux'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Cookies from 'universal-cookie'

import ProfileCard from '../../molecules/profileCard/ProfileCard'
import PopoverItem from '../../molecules/popoverItem/PopoverItem'

import { setCurrentOrg } from '../../../redux/actions/userInfoActions'
import currentOrgService from '../../../services/custom/currentOrgService'

import kozmikTheme from '../../../assets/theme/theme.json'

const useStyles = makeStyles((theme) => ({
	infoBox: {
		flexGrow: 1,
		paddingLeft: 5,
		overflow: 'hidden'
	},
	nameTxt: {
		color: theme.custom.colors.textPrimary,
		fontSize: 12,
		textAlign: 'left',
		fontWeight: 600,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	mainContainer: {
		display: 'flex',
		alignItems: 'center',
		height: 45,
		// border: `1px solid ${theme.custom.colors.gray}`,
		borderRadius: 6,
		padding: 5,
		cursor: 'pointer',
		marginBottom: 5
	},
	popoverBox: {
		padding: 8,
		display: 'flex',
		flexDirection: 'column'
	}
}))

const OrgSelection = (props) => {
	const cookies = new Cookies()
	const classes = useStyles()

	const [orgsPop, setOrgsPop] = useState(null)

	const openOrgsPop = (event) => {
		setOrgsPop(event.currentTarget)
	}
	const closeOrgsPop = () => {
		setOrgsPop(null)
	}
	const orgsPopOpen = Boolean(orgsPop)

	const selectOrg = (org) => {
		currentOrgService.set(org)
		closeOrgsPop()
	}

	return (
		<>
			<div className={classes.mainContainer} onClick={openOrgsPop}>
				<ProfileCard
					text={props.currentOrg?.name}
					// imgUrl={}
					size={35}
					circle
					bgColor={kozmikTheme.colors.gray}
				/>
				<div className={classes.infoBox}>
					<div className={classes.nameTxt}>{props.currentOrg?.name}</div>
				</div>
				{props.org?.length > 1 && (
					<ArrowDropDownIcon style={{ fontSize: 18 }} />
				)}
			</div>

			{props.org?.length > 1 && (
				<Popover
					open={orgsPopOpen}
					anchorEl={orgsPop}
					onClose={closeOrgsPop}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right'
					}}
				>
					<Box className={classes.popoverBox}>
						{props.org
							.filter((x) => x.id != props.currentOrg?.id)
							.map((item, index) => (
								<PopoverItem
									key={index}
									text={item?.name}
									onClick={() => {
										selectOrg(item)
									}}
									color='#000000'
								>
									<ProfileCard
										text={item?.name}
										size={25}
										circle
										bgColor={kozmikTheme.colors.gray}
									/>
								</PopoverItem>
							))}
					</Box>
				</Popover>
			)}
		</>
	)
}

const mapStateToProps = (state) => ({
	...state.userInfo
})

const mapDispatchToProps = (dispatch) => {
	return {
		setCurrentOrg: (x) => dispatch(setCurrentOrg(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgSelection)

import {
	LocalPhoneOutlined,
	CalendarMonthOutlined,
	AttachFile,
	TextFormat,
	Link,
	NumbersOutlined,
	LocationOn,
	Polyline,
	ManageSearch,
	Notes,
	CheckBoxOutlined,
	EmailOutlined,
	ImageOutlined,
	Functions
} from '@mui/icons-material'
import { ReactComponent as Rollup } from '../../../assets/icons/rollup.svg'
import i18next from '../../../i18n/i18n'

const allData = [
	{
		icon: TextFormat,
		label: i18next.t('appData.fieldDataTypes.text'),
		value: 'Text'
	},
	{
		icon: Notes,
		label: i18next.t('appData.fieldDataTypes.longText'),
		value: 'LongText'
	},
	{
		icon: NumbersOutlined,
		label: i18next.t('appData.fieldDataTypes.number'),
		value: 'Number'
	},
	{
		icon: CheckBoxOutlined,
		label: i18next.t('appData.fieldDataTypes.checkbox'),
		value: 'Checkbox'
	},
	{
		icon: CalendarMonthOutlined,
		label: i18next.t('appData.fieldDataTypes.date'),
		value: 'Date'
	},
	{
		icon: ImageOutlined,
		label: i18next.t('appData.fieldDataTypes.image'),
		value: 'Image'
	},
	{
		icon: AttachFile,
		label: i18next.t('appData.fieldDataTypes.file'),
		value: 'File'
	},
	{
		icon: EmailOutlined,
		label: i18next.t('appData.fieldDataTypes.email'),
		value: 'Email'
	},
	{
		icon: LocalPhoneOutlined,
		label: i18next.t('appData.fieldDataTypes.phone'),
		value: 'Phone'
	},
	{
		icon: Link,
		label: i18next.t('appData.fieldDataTypes.url'),
		value: 'URL'
	},
	{
		icon: LocationOn,
		label: i18next.t('appData.fieldDataTypes.geoLocation'),
		value: 'GeoLocation'
	},
	{
		icon: Polyline,
		label: i18next.t('appData.fieldDataTypes.relation'),
		value: 'Relation'
	},
	{
		icon: ManageSearch,
		label: i18next.t('appData.fieldDataTypes.lookup'),
		value: 'Lookup'
	},
	{
		icon: Rollup,
		label: i18next.t('appData.fieldDataTypes.rollup'),
		value: 'Rollup'
	},
	{
		icon: Functions,
		label: i18next.t('appData.fieldDataTypes.formula'),
		value: 'Formula'
	}
]

export default allData

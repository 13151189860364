import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import AccountTemplate from '../../templates/accountTemplate/AccountTemplate'

const Account = () => {
	const { t } = useTranslation()

	useEffect(() => {
		document.title = t('docTitles.account')
	}, [])

	return <AccountTemplate />
}

export default Account

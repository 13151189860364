import React from 'react'
import Label from '../../atoms/label/Label'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((/*theme*/) => ({
	labelStyle: {
		color: '#000000',
		//fontFamily: "OpenSans",
		fontSize: 16,
		lineHeight: '22px',
		textAlign: 'left',
		display: 'block',
		fontWeight: '600'
	},
	width100: {
		width: '100%'
	},
	width70: {
		width: '70%'
	},
	width30: {
		width: '30%'
	},
	width40: {
		width: '40%'
	},
	headerBar: {
		display: 'flex',
		zIndex: 1
	}
}))

const Header = (props) => {
	const classes = useStyles()

	return (
		<div className={classes.headerBar}>
			<div
				className={classes.width100}
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					flexDirection: 'column',
					justifyContent: 'space-between'
				}}
			>
				<div className={classes.width100} style={{ marginBottom: 10 }}>
					<Label text={props.text} className={classes.labelStyle} />
				</div>
			</div>
		</div>
	)
}
export default Header

import i18next from '../../i18n/i18n'

const all = [
	{
		label: i18next.t('actionTypes.back'),
		value: 'back',
		type: i18next.t('actionGroups.navigation')
	},
	{
		label: i18next.t('actionTypes.navigateTab'),
		value: 'navigateTab',
		type: i18next.t('actionGroups.navigation')
	},
	{
		label: i18next.t('actionTypes.navigate'),
		value: 'navigate',
		type: i18next.t('actionGroups.navigation')
	},
	{
		label: i18next.t('actionTypes.openLink'),
		value: 'openLink',
		type: i18next.t('actionGroups.navigation')
	},
	{
		label: i18next.t('actionTypes.shareLink'),
		value: 'shareLink',
		type: i18next.t('actionGroups.navigation')
	},
	{
		label: i18next.t('actionTypes.drawer'),
		value: 'drawer',
		type: i18next.t('actionGroups.navigation')
	},
	{
		label: i18next.t('actionTypes.notify'),
		value: 'notify',
		type: i18next.t('actionGroups.communication')
	},
	{
		label: i18next.t('actionTypes.push'),
		value: 'push',
		type: i18next.t('actionGroups.communication')
	},
	{
		label: i18next.t('actionTypes.email'),
		value: 'email',
		type: i18next.t('actionGroups.communication')
	},
	{
		label: i18next.t('actionTypes.invite'),
		value: 'invite',
		type: i18next.t('actionGroups.user'),
		usersOnly: true,
		tooltip: i18next.t('actionTypes.inviteTooltip')
	},
	{
		label: i18next.t('actionTypes.signOut'),
		value: 'signOut',
		type: i18next.t('actionGroups.user')
	},
	{
		label: i18next.t('actionTypes.create'),
		value: 'create',
		type: i18next.t('actionGroups.data')
	},
	{
		label: i18next.t('actionTypes.update'),
		value: 'update',
		type: i18next.t('actionGroups.data'),
		sameSource: true,
		tooltip: i18next.t('actionTypes.updateTooltip')
	},
	{
		label: i18next.t('actionTypes.delete'),
		value: 'delete',
		type: i18next.t('actionGroups.data'),
		sameSource: true,
		tooltip: i18next.t('actionTypes.deleteTooltip')
	}
]

export default all

import axios from 'axios'
import Cookies from 'universal-cookie'

import { default as appConfig } from '../config/app.json'

import tokenServices from './tokenServices'

import showToastCatchError from '../methods/showToastCatchError'

const accountDomain = appConfig.domains.accountDomain

const cookies = new Cookies()

let refreshTokenPromise

const getRefreshToken = (refreshToken) =>
	axios.post(
		accountDomain + '/api/b/refresh',
		{
			refreshToken: refreshToken
		},
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
	)

const getClient = async (baseUrl = null, headers = null) => {
	const options = {
		baseURL: baseUrl,
		headers: headers,
		timeout: 300000
	}

	let client

	const initClient = (options, token, refreshToken, orgId) => {
		options.headers['Accept-Language'] = localStorage.getItem('i18nextLng')
		options.headers['X-Timezone-Offset'] = new Date().getTimezoneOffset()
		if (orgId && orgId != 'undefined' && orgId != 'null') {
			options.headers['OrgId'] = orgId
		}

		client = axios.create(options)

		client.interceptors.request.use(
			(requestConfig) => {
				if (token && token !== null && token !== undefined) {
					requestConfig.headers.Authorization = token
				}

				return requestConfig
			},
			(requestError) => {
				console.log('requestError', requestError)
				return Promise.reject(requestError)
			}
		)

		client.interceptors.response.use(
			(response) => response,
			(error) => {
				const originalRequest = error.config

				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response)
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request)
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message)
				}

				if (error.response) {
					if (error.response.status == 401 && !originalRequest._retry) {
						originalRequest._retry = true

						if (refreshToken && refreshToken !== 'undefined') {
							if (!refreshTokenPromise) {
								// check for an existing in-progress request
								// if nothing is in-progress, start a new refresh token request
								refreshTokenPromise = getRefreshToken(refreshToken).then(
									(x) => {
										refreshTokenPromise = null // clear state
										return x // resolve with the new token
									}
								)
							}
							return refreshTokenPromise
								.then((res) => {
									if (res.data.success) {
										originalRequest.headers['Authorization'] =
											'Bearer ' + res.data.data.access_token

										tokenServices.add(res.data.data)

										return axios(originalRequest)
									} else {
										tokenServices.remove()
										window.location.href = '/login'
									}
								})
								.catch(() => {
									tokenServices.remove()
									window.location.href = '/login'
								})
						}
					}
					// if (error.response.status >= 500) {
					// 	//TODO:ERROR
					// }
				}

				return Promise.reject(error)
			}
		)
	}

	let tokenText, refreshToken
	const token = cookies.get(appConfig.cookieKeys.MY_SECURE_TOKEN_KEY)
	const orgId = cookies.get('orgId')

	if (token && token !== undefined && token !== null) {
		tokenText = `Bearer ${token}`
		refreshToken = cookies.get(appConfig.cookieKeys.MY_SECURE_REFRESH_TOKEN_KEY)
	}

	initClient(options, tokenText, refreshToken, orgId)

	return client
}

class ApiClient {
	constructor(baseUrl = null, headers = null) {
		this.baseUrl = baseUrl
		this.headers = headers
	}

	async get(url, conf = {}) {
		return await getClient(this.baseUrl, this.headers).then((c) => {
			return c
				.get(url, conf)
				.then((response) => {
					return response
				})
				.catch((error) => {
					//TODO:LOG
					showToastCatchError(error)
					return Promise.reject(error)
				})
		})
	}
	async delete(url, conf = {}) {
		return await getClient(this.baseUrl, this.headers).then((c) => {
			return c
				.delete(url, conf)
				.then((response) => {
					return response
				})
				.catch((error) => {
					//TODO:LOG
					showToastCatchError(error)
					return Promise.reject(error)
				})
		})
	}
	async head(url, conf = {}) {
		return await getClient(this.baseUrl, this.headers).then((c) => {
			return c
				.head(url, conf)
				.then((response) => {
					return response
				})
				.catch((error) => {
					//TODO:LOG
					showToastCatchError(error)
					return Promise.reject(error)
				})
		})
	}
	async options(url, conf = {}) {
		return await getClient(this.baseUrl, this.headers).then((c) => {
			return c
				.options(url, conf)
				.then((response) => {
					return response
				})
				.catch((error) => {
					//TODO:LOG
					showToastCatchError(error)
					return Promise.reject(error)
				})
		})
	}
	async post(url, data = {}, conf = {}) {
		return await getClient(this.baseUrl, this.headers).then((c) => {
			return c
				.post(url, data, conf)
				.then((response) => {
					return response
				})
				.catch((error) => {
					//TODO:LOG
					showToastCatchError(error)
					return Promise.reject(error)
				})
		})
	}
	async put(url, data = {}, conf = {}) {
		return await getClient(this.baseUrl, this.headers).then((c) => {
			return c
				.put(url, data, conf)
				.then((response) => {
					return response
				})
				.catch((error) => {
					//TODO:LOG
					showToastCatchError(error)
					return Promise.reject(error)
				})
		})
	}
	async patch(url, data = {}, conf = {}) {
		return await getClient(this.baseUrl, this.headers).then((c) => {
			return c
				.patch(url, data, conf)
				.then((response) => {
					return response
				})
				.catch((error) => {
					//TODO:LOG
					showToastCatchError(error)
					return Promise.reject(error)
				})
		})
	}
}
export { ApiClient }

import i18next from '../../i18n/i18n'

const filterOperators = [
	{
		type: 'string',
		dataTypes: [
			'RowId',
			'Text',
			'LongText',
			'Image',
			'File',
			'Email',
			'Phone',
			'URL',
			'GeoLocation',
			'Lookup',
			'Rollup',
			'Formula'
		],
		operators: [
			{
				label: i18next.t('screens.elementSettings.filter.filterOps.string.nu'),
				value: 'nu'
			},
			{
				label: i18next.t('screens.elementSettings.filter.filterOps.string.nn'),
				value: 'nn'
			},
			{
				label: i18next.t('screens.elementSettings.filter.filterOps.string.eq'),
				value: 'eq'
			},
			{
				label: i18next.t('screens.elementSettings.filter.filterOps.string.ne'),
				value: 'ne'
			},
			{
				label: i18next.t('screens.elementSettings.filter.filterOps.string.cn'),
				value: 'cn'
			},
			{
				label: i18next.t('screens.elementSettings.filter.filterOps.string.nc'),
				value: 'nc'
			}
		],
		searchOp: 'eq'
	},
	{
		type: 'numeric',
		dataTypes: [/*'AutoNumber',*/ 'Number', 'Formula', 'Rollup'],
		operators: [
			{
				label: i18next.t('screens.elementSettings.filter.filterOps.numeric.nu'),
				value: 'nu'
			},
			{
				label: i18next.t('screens.elementSettings.filter.filterOps.numeric.nn'),
				value: 'nn'
			},
			{
				label: i18next.t('screens.elementSettings.filter.filterOps.numeric.eq'),
				value: 'eq'
			},
			{
				label: i18next.t('screens.elementSettings.filter.filterOps.numeric.ne'),
				value: 'ne'
			},
			{
				label: i18next.t('screens.elementSettings.filter.filterOps.numeric.lt'),
				value: 'lt'
			},
			{
				label: i18next.t('screens.elementSettings.filter.filterOps.numeric.le'),
				value: 'le'
			},
			{
				label: i18next.t('screens.elementSettings.filter.filterOps.numeric.gt'),
				value: 'gt'
			},
			{
				label: i18next.t('screens.elementSettings.filter.filterOps.numeric.ge'),
				value: 'ge'
			}
		],
		searchOp: 'eq'
	},
	{
		type: 'datetime',
		dataTypes: ['Date'],
		operators: [
			{
				label: i18next.t(
					'screens.elementSettings.filter.filterOps.datetime.nu'
				),
				value: 'nu'
			},
			{
				label: i18next.t(
					'screens.elementSettings.filter.filterOps.datetime.nn'
				),
				value: 'nn'
			},
			{
				label: i18next.t(
					'screens.elementSettings.filter.filterOps.datetime.eq'
				),
				value: 'deq'
			},
			{
				label: i18next.t(
					'screens.elementSettings.filter.filterOps.datetime.ne'
				),
				value: 'dne'
			},
			{
				label: i18next.t(
					'screens.elementSettings.filter.filterOps.datetime.dgt'
				),
				value: 'dgt'
			},
			{
				label: i18next.t(
					'screens.elementSettings.filter.filterOps.datetime.dge'
				),
				value: 'dge'
			},
			{
				label: i18next.t(
					'screens.elementSettings.filter.filterOps.datetime.dlt'
				),
				value: 'dlt'
			},
			{
				label: i18next.t(
					'screens.elementSettings.filter.filterOps.datetime.dle'
				),
				value: 'dle'
			}
		],
		searchOp: 'deq'
	},
	{
		type: 'boolean',
		dataTypes: ['Checkbox'],
		operators: [
			{
				label: i18next.t('screens.elementSettings.filter.filterOps.boolean.nu'),
				value: 'nu'
			},
			{
				label: i18next.t('screens.elementSettings.filter.filterOps.boolean.nn'),
				value: 'nn'
			},
			{
				label: i18next.t('screens.elementSettings.filter.filterOps.boolean.eq'),
				value: 'eq'
			},
			{
				label: i18next.t('screens.elementSettings.filter.filterOps.boolean.ne'),
				value: 'ne'
			}
		],
		searchOp: 'eq'
	},
	{
		type: 'relation',
		dataTypes: ['Relation', 'Lookup', 'RowId'],
		operators: [
			{
				label: i18next.t(
					'screens.elementSettings.filter.filterOps.relation.rnu'
				),
				value: 'rnu'
			},
			{
				label: i18next.t(
					'screens.elementSettings.filter.filterOps.relation.rnn'
				),
				value: 'rnn'
			},
			{
				label: i18next.t(
					'screens.elementSettings.filter.filterOps.relation.req'
				),
				value: 'req'
			},
			{
				label: i18next.t(
					'screens.elementSettings.filter.filterOps.relation.rne'
				),
				value: 'rne'
			},
			{
				label: i18next.t(
					'screens.elementSettings.filter.filterOps.relation.rcn'
				),
				value: 'rcn'
			},
			{
				label: i18next.t(
					'screens.elementSettings.filter.filterOps.relation.rnc'
				),
				value: 'rnc'
			}
		],
		searchOp: 'req'
	}
]

export default filterOperators

import { v4 as uuidv4 } from 'uuid'

// import i18next from '../../i18n/i18n'
import languages from '../../i18n/languages'

const method = (fieldData) => {
	let object = {
		_uid: uuidv4(),
		component: 'filePicker',
		mapping: {
			field: fieldData.name,
			type: fieldData.dataType
		},
		title: fieldData.label,
		required: {
			status: fieldData?.isRequired || false,
			text: {
				default: languages.en.schemas.filePicker.required
			}
		},
		errorText: {
			default: languages.en.schemas.filePicker.error
		},
		spacing: 'xl',
		confirmation: {
			primaryText: {
				default: languages.en.schemas.filePicker.modal.primary
			},
			secondaryText: {
				default: languages.en.schemas.filePicker.modal.secondary
			},
			confirmText: {
				default: languages.en.schemas.filePicker.modal.confirm
			},
			cancelText: {
				default: languages.en.schemas.filePicker.modal.cancel
			}
		},
		placeholder: {
			default: languages.en.schemas.filePicker.placeholder
		},
		selectedText: {
			default: languages.en.schemas.filePicker.selected
		}
	}

	return object
}

export default method

import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateMentions from '../../../molecules/doubleStateField/DoubleStateMentions'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import { default as Checkbox } from '../../../atoms/kozmikCheckBox/KozmikCheckBox'
import { default as Slider } from '../../../atoms/kozmikSlider/KozmikSlider'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import relationTypes from '../../../../common/enums/relationTypes'
import formulas from '../../../../common/formulas'

import { ReactComponent as MetinKenar } from '../../../../assets/icons/Settings/MetinHizalama-Kenarlara.svg'
import { ReactComponent as MetinOrtali } from '../../../../assets/icons/Settings/MetinHizalama-Ortali.svg'
import { ReactComponent as MetinSag } from '../../../../assets/icons/Settings/MetinHizalama-Sag.svg'
import { ReactComponent as MetinSol } from '../../../../assets/icons/Settings/MetinHizalama-Sol.svg'
import { ReactComponent as TextUnderline } from '../../../../assets/icons/Settings/TextUnderline.svg'
import { ReactComponent as TextItalic } from '../../../../assets/icons/Settings/TextItalic.svg'
import { ReactComponent as TextBold } from '../../../../assets/icons/Settings/TextBold.svg'
import { ReactComponent as TextRegular } from '../../../../assets/icons/Settings/TextRegular.svg'

import { ChromePicker } from 'react-color'
import { BrowserNotSupported } from '@mui/icons-material'
import colorpicker from '../../../../assets/images/colorpicker.png'
import RadioButton from '../../../atoms/radioButton/RadioButton'
import useOutsideClick from '../../../../hooks/UseOutsideClick/useOutsideClick'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const TextArea = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const propsRef = useRef()
	propsRef.current = props

	const [elementName, setElementName] = useState(null)

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen?.data?.source,
		fieldDataTypes.textBased,
		null,
		true
		// relationTypes.one
	)
	const textOptions = textFields?.length ? textFields : formulas.profile

	/****TEXT***************************/
	const [isTextTextMode, setIsTextTextMode] = useState(false)
	const [textField, setTextField] = useState('')
	const [textDefault, setTextDefault] = useState('')

	const textChange = (data) => {
		if (isTextTextMode) {
			submitText(data)
			setTextDefault(data)
		} else {
			submitText(data?.value)
			setTextField(data?.value)
		}
	}
	const submitText = (x) => {
		const selectedField = textOptions?.find((y) => y.name == x)
		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				text: {
					field: { $set: isTextTextMode ? null : x },
					default: { $set: isTextTextMode ? x : null },
					type: { $set: isTextTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****TEXT_LINE_LIMIT***************************/
	const [lineLimit, setLineLimit] = useState(false)

	const lineLimitChange = (e) => {
		submitLineCount(e.target.checked ? 1 : null)
		setLineLimit(e.target.checked)
	}

	/****TEXT_LINE_COUNT***************************/
	const [lineCount, setLineCount] = useState(null)

	const lineCountChange = (e, newVal) => {
		if (typeof newVal === 'number') {
			submitLineCount(newVal)
			setLineCount(newVal)
		}
	}

	const submitLineCount = (x) => {
		const modifiedElementData = update(props.elementData, {
			lineCount: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****TEXT_TEXT_ALIGN***************************/
	const alignOptions = [
		{
			text: '',
			value: 'left',
			icon: MetinSol
		},
		{
			text: '',
			value: 'center',
			icon: MetinOrtali
		},
		{
			text: '',
			value: 'right',
			icon: MetinSag
		},
		{
			text: '',
			value: 'justify',
			icon: MetinKenar
		}
	]

	const [textAlign, setTextAlign] = useState(false)

	const textAlignChange = (val) => {
		submitTextAlign(val)
		setTextAlign(val)
	}

	const submitTextAlign = (x) => {
		const modifiedElementData = update(props.elementData, {
			textAlign: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****TEXT_SIZE***************************/
	const sizeOptions = [
		{
			label: t('screens.elementSettings.textArea.sizeOpts.xs'),
			value: 'xs'
		},
		{
			label: t('screens.elementSettings.textArea.sizeOpts.sm'),
			value: 'sm'
		},
		{
			label: t('screens.elementSettings.textArea.sizeOpts.md'),
			value: 'md'
		},
		{
			label: t('screens.elementSettings.textArea.sizeOpts.lg'),
			value: 'lg'
		},
		{
			label: t('screens.elementSettings.textArea.sizeOpts.xl'),
			value: 'xl'
		},
		{
			label: t('screens.elementSettings.textArea.sizeOpts.xxl'),
			value: 'xxl'
		},
		{
			label: t('screens.elementSettings.textArea.sizeOpts.xxxl'),
			value: 'xxxl'
		}
	]

	const [textSize, setTextSize] = useState('')

	const textSizeChange = (e) => {
		setTextSize(e.target.value)
		submitTextSize(e.target.value)
	}

	const submitTextSize = (x) => {
		const modifiedElementData = update(props.elementData, {
			size: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****TEXT_STYLE***************************/
	const styleOptions = [
		{
			text: t('screens.elementSettings.textArea.styleOpts.regular'),
			value: 'regular',
			icon: TextRegular
		},
		{
			text: t('screens.elementSettings.textArea.styleOpts.bold'),
			value: 'bold',
			icon: TextBold
		},
		{
			text: t('screens.elementSettings.textArea.styleOpts.italic'),
			value: 'italic',
			icon: TextItalic
		},
		{
			text: t('screens.elementSettings.textArea.styleOpts.underline'),
			value: 'underline',
			icon: TextUnderline
		}
	]

	const [textStyle, setTextStyle] = useState('')

	const textStyleChange = (value) => {
		setTextStyle(value)
		submitTextStyle(value)
	}

	const submitTextStyle = (x) => {
		const modifiedElementData = update(props.elementData, {
			style: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****TEXT_COLOR***************************/
	const colorList = [
		{
			color: '#E22345',
			name: '#E22345'
		},
		{
			color: '#F25757',
			name: '#F25757'
		},
		{
			color: '#F27777',
			name: '#F27777'
		},
		{
			color: '#D98841',
			name: '#D98841'
		},
		{
			color: '#D9A768',
			name: '#D9A768'
		},
		{
			color: '#F2C84C',
			name: '#F2C84C'
		},
		{
			color: '#D9C358',
			name: '#D9C358'
		},
		{
			color: '#9DA658',
			name: '#9DA658'
		},
		{
			color: '#1EBF92',
			name: '#1EBF92'
		},
		{
			color: '#2E8C6A',
			name: '#2E8C6A'
		},
		{
			color: '#06AFF2',
			name: '#06AFF2'
		},
		{
			color: '#0440F2',
			name: '#0440F2'
		},
		{
			color: '#7030F2',
			name: '#7030F2'
		},
		{
			color: '#A673D9',
			name: '#A673D9'
		}
	]

	const [textColor, setTextColor] = useState('')
	const [textColorIndex, setTextColorIndex] = useState(0)
	const [textCustomColor, setTextCustomColor] = useState('#BB0101')
	const [textColorPickerVisible, setTextColorPickerVisible] = useState(false)

	const submitTextColor = (x) => {
		const modifiedElementData = update(props.elementData, {
			color: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	const textColorPickerRef = useRef(null)

	useOutsideClick(textColorPickerRef, () => {
		setTextColorPickerVisible(false)
	})

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****TEXT***************************/
		elementData?.mapping?.text?.field ||
		elementData?.mapping?.text?.field === ''
			? setIsTextTextMode(false)
			: setIsTextTextMode(true)

		setTextField(elementData?.mapping?.text?.field || '')
		setTextDefault(elementData?.mapping?.text?.default || '')

		setLineLimit(!!elementData?.lineCount)
		setLineCount(elementData?.lineCount)
		setTextAlign(elementData?.textAlign)
		setTextStyle(elementData?.style)
		setTextSize(elementData?.size)

		const tempColorIndex = colorList.indexOf(
			colorList.filter((x) => x.color == elementData?.color)[0]
		)
		if (tempColorIndex > -1) {
			setTextColorIndex(tempColorIndex)
			setTextColor(colorList[tempColorIndex].color)
		} else {
			if (elementData?.color) {
				setTextColorIndex('custom')
				setTextColor(elementData?.color)
				setTextCustomColor(elementData?.color)
			} else {
				setTextColorIndex('none')
				setTextColor(null)
			}
		}

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		props?.elementData && initStates(props.elementData)
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='flex-start' className={classes.mb10}>
						{/* <Grid item xs={12} lg={12} className={classes.mb10}>
							<Label>{t('screens.elementSettings.textArea.text')}</Label>
						</Grid> */}
						<Grid item xs={12} lg={12}>
							<DoubleStateMentions
								name={`${elementName}`}
								fullWidth={true}
								isTextMode={isTextTextMode}
								setIsTextMode={setIsTextTextMode}
								valChange={textChange}
								multiline={true}
								textVal={textDefault}
								autoCompVal={textField}
								autoCompOptions={textOptions}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.textArea.size')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								className={cx(classes.input, classes.inputSize)}
								onChange={textSizeChange}
								value={textSize}
								data={sizeOptions}
								textKey='label'
								valueKey='value'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.textArea.style')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={styleOptions}
								getChange={textStyleChange}
								activeValue={textStyle}
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.textArea.alignment')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={alignOptions}
								getChange={textAlignChange}
								activeValue={textAlign}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						display={'flex'}
						className={classes.mb10}
						style={{ justifyContent: 'space-between', alignItems: 'center' }}
					>
						<Grid item xs={12} lg={12} className={classes.mb10}>
							<Label>{t('screens.elementSettings.textArea.color')}</Label>
						</Grid>
						<Grid
							item
							container
							xs={12}
							lg={12}
							display='flex'
							alignItems='center'
							justifyContent={'flex-end'}
							style={{ position: 'relative' }}
						>
							<RadioButton
								className={classes.radio}
								checked={'none' == textColorIndex}
								onClick={() => {
									setTextColorIndex('none')
									submitTextColor(null)
								}}
								icon={BrowserNotSupported}
							/>
							{colorList.map((item, index) => (
								<RadioButton
									className={classes.radio}
									checked={index == textColorIndex}
									key={index}
									onClick={() => {
										setTextColor(item.color)
										setTextColorIndex(index)
										submitTextColor(item.color)
									}}
									radioColor={item?.color}
									imgUrl={item?.imgUrl}
								/>
							))}
							<RadioButton
								className={classes.radio}
								checked={'custom' == textColorIndex}
								onClick={() => {
									setTextColorPickerVisible(true)
								}}
								imgUrl={colorpicker}
							/>
							{textColorPickerVisible && (
								<div ref={textColorPickerRef} className={classes.pickerPopover}>
									<ChromePicker
										disableAlpha
										color={textColor ? textColor : '#000000'}
										onChangeComplete={(color) => {
											setTextColor(color.hex)
											setTextColorIndex('custom')
											setTextCustomColor(color.hex)
											submitTextColor(color.hex)
										}}
									/>
								</div>
							)}
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={8} lg={9}>
							<div className={cx(classes.item)}>
								<div>
									<Checkbox checked={lineLimit} onChange={lineLimitChange} />
								</div>
								<div className={cx(classes.labelBox, classes.growBox)}>
									<Label>
										{t('screens.elementSettings.textArea.lineLimit')}
									</Label>
								</div>
							</div>
						</Grid>
					</Grid>
					{lineLimit && (
						<Grid container alignItems='center' className={classes.mb10}>
							<Grid item xs={4}>
								<Label>{t('screens.elementSettings.textArea.lineCount')}</Label>
							</Grid>
							<Grid
								item
								style={{
									display: 'flex',
									alignItems: 'center'
								}}
								xs={12}
								lg={8}
							>
								<Slider
									value={lineCount}
									min={1}
									step={1}
									max={6}
									onChange={lineCountChange}
									aria-labelledby='non-linear-slider'
								/>
							</Grid>
						</Grid>
					)}
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default TextArea

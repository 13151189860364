const getEntityFieldsByDataTypes = (
	entities,
	entityName,
	dataTypeArray,
	entity = null,
	checkLookupAndRelation = false,
	relationType = null
) => {
	const entityFields = entity
		? entity?.fields
		: entities?.find((x) => x.name == entityName)?.fields

	const dataTypeList = checkLookupAndRelation
		? [...dataTypeArray, 'Lookup', 'Relation']
		: dataTypeArray

	const acceptedFields = entityFields?.filter(
		(x) =>
			dataTypeList?.includes(
				x.formula?.formulaFieldDataType ?? x.displayFieldDataType ?? x.dataType
			) &&
			(!checkLookupAndRelation ||
				(checkLookupAndRelation &&
					((x.dataType != 'Lookup' && x.dataType != 'Relation') ||
						((x.dataType == 'Lookup' || x.dataType == 'Relation') &&
							x.displayFieldDataType != 'Lookup' &&
							x.displayFieldDataType != 'Relation' &&
							dataTypeList.includes(x.displayFieldDataType) &&
							(!relationType || x.relationType === relationType)))))
	)

	return acceptedFields?.sort(function (a, b) {
		return a.label.localeCompare(b.label)
	})
}

export default getEntityFieldsByDataTypes

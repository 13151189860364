import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { CheckCircle } from '@mui/icons-material'
import cx from 'classnames'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		width: '100%',
		borderRadius: 8,
		padding: 8,
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: theme.custom.colors.kozmikGray
		}
	},
	icon: {
		fontSize: '18px',
		color: '#BBBBBD'
	},
	completed: {
		color: theme.custom.colors.kozmikGreen
	},
	text: {
		fontSize: 14,
		paddingLeft: 4
	}
}))

const OnboardingListItem = (props) => {
	const classes = useStyles()

	const { text, completed, ...restProps } = props

	return (
		<div className={classes.mainContainer} {...restProps}>
			<CheckCircle
				className={cx(classes.icon, completed ? classes.completed : null)}
			/>
			<span className={classes.text}>{text}</span>
		</div>
	)
}

export default OnboardingListItem

import React, { useState, useEffect } from 'react'
import {
	Button,
	Box,
	Typography,
	Popover,
	InputAdornment,
	Grid,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	CardMedia
} from '@mui/material'
import { ArrowBackIos, ContentCopy, OpenInNew } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import QRCode from 'qrcode.react'

import TagInput from '../../atoms/tagInput/TagInput'
import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'
import SquareOptions from '../../molecules/squareOprions/SquareOptions'
import PreviewButtons from '../../molecules/previewButtons/PreviewButtons'
import PublishModule from '../../organisms/publishModule/PublishModule'
import KozmikModal from '../../molecules/kozmikModal/KozmikModal'
import MediaPicker from '../../atoms/mediaPicker/MediaPicker'
import HistoryItem from '../../molecules/historyItem/HistoryItem'

import kozmikTheme from '../../../assets/theme/theme.json'

import appUserClient from '../../../services/appUserClient'
import appClient from '../../../services/appClient'

import { setDetail } from '../../../redux/actions/appDataActions'

import showToastResponseError from '../../../methods/showToastResponseError'
import timeoutDelay from '../../../methods/timeoutDelay'

import { default as successImage } from '../../../assets/images/success.png'

import { ReactComponent as PublishWeb } from '../../../assets/icons/Publish/PublishWeb.svg'
import { ReactComponent as PublishAppStore } from '../../../assets/icons/Publish/PublishAppStore.svg'
import { ReactComponent as PublishGooglePlay } from '../../../assets/icons/Publish/PublishGooglePlay.svg'
import { alignProperty } from '@mui/material/styles/cssUtils'

import Kozmik from '../../../assets/images/Kozmik.png'

const useStyles = makeStyles((theme) => ({
	mt10: {
		marginTop: 10
	},
	mb10: {
		marginBottom: 10
	},
	mb20: {
		marginBottom: 20
	},
	mainContainer: {
		border: `1px solid ${theme.custom.colors.gray}`,
		backgroundColor: '#FFFFFF',
		padding: '12px 12px',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		height: 64,
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		[theme.breakpoints.down('md')]: {
			height: 'unset'
		}
	},
	taRight: {
		textAlign: 'right'
	},
	jcSBetween: {
		justifyContent: 'space-between'
	},
	textBox: {
		display: 'flex',
		alignItems: 'center'
	},
	textName: {
		color: '#000000',
		fontSize: '14px',
		lineHeight: '19px',
		fontWeight: 'bold',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	},
	modalContainer: {
		width: 400,
		maxHeight: 675,
		padding: 30,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		[theme.breakpoints.down('md')]: {
			width: 'unset'
		}
	},
	modalQrContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		marginBottom: 40
	},
	modalLinkContainer: {
		width: '100%',
		marginLeft: 20,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start'
	},
	modalTitle: {
		fontSize: 16,
		fontWeight: '600',
		color: '#000000'
	},
	modalSubTitle: {
		fontSize: 14,
		color: '#000000',
		marginBottom: 30
	},
	modalButtonText: {
		color: '#ffffff',
		fontSize: 14,
		fontWeight: '600',
		textAlign: 'left',
		textTransform: 'none',
		whiteSpace: 'nowrap'
	},
	modalMultilineInput: {
		marginTop: 10,
		borderRadius: 4,
		backgroundColor: theme.custom.colors.kozmikGray,
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	share: {
		borderRadius: '4px',
		width: '98px',
		height: '35px',
		marginRight: '2%',
		'&:hover': {
			backgroundColor: 'transparent'
		}
	},
	shareText: {
		fontSize: '14px',
		fontWeight: 'bold',
		textAlign: 'left',
		textTransform: 'none',
		color: theme.custom.colors.kozmikDarkBlue
	},
	publish: {
		backgroundColor: theme.custom.colors.kozmikDarkBlue,
		borderRadius: '4px',
		width: '98px',
		height: '35px',
		'&:hover': {
			backgroundColor: theme.custom.colors.kozmikDarkBlue
		}
	},
	publishText: {
		color: '#FFFFFF',
		fontSize: '14px',
		fontWeight: 'bold',
		textAlign: 'left',
		textTransform: 'none'
	},
	isPublishedBadge: {
		backgroundColor: '#56d87b',
		color: 'white',
		padding: '1px 5px',
		marginLeft: '20px',
		borderRadius: '10px',
		fontSize: '9px',
		lineHeight: '11px',
		fontWeight: 'bold',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '5px'
		}
	},
	leftContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		paddingLeft: '10px',
		width: 'calc(16.66% + 75px)',
		[theme.breakpoints.down('md')]: {
			width: '60%'
		}
	},
	centerContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			display: 'none !important'
			// paddingLeft: 75,
			// width: '100%'
		}
	},
	rightContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '25%',
		[theme.breakpoints.down('md')]: {
			width: '40%'
		}
	},
	qrCode: {
		padding: 10,
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.17)'
	},
	input: {
		fontSize: 14,
		backgroundColor: theme.custom.colors.kozmikGray,
		borderRadius: 6,
		border: 'none',
		'& .MuiOutlinedInput-root': {
			padding: 0
		},
		'& .MuiOutlinedInput-adornedStart': {
			paddingLeft: 0
		},
		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: 0
		}
	},
	appLinkAdornment: {
		fontSize: 12,
		padding: '16px 12px',
		color: theme.custom.colors.textPrimary,
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center'
	},
	adornmentText: {
		fontSize: 12,
		backgroundColor: theme.custom.colors.gray,
		padding: '16px 6px',
		color: theme.custom.colors.textPrimary
	},
	adornmentIcon: {
		fontSize: 14,
		color: theme.custom.colors.textPrimary,
		marginRight: 3
	},
	copyText: {
		color: theme.custom.colors.kozmikBlue,
		fontSize: 12,
		fontWeight: '600',
		textAlign: 'left',
		textTransform: 'none'
	},
	copyIcon: {
		fontSize: '14px !important',
		color: theme.custom.colors.kozmikBlue
	},
	groups: {
		marginBottom: 10
	},
	group: {
		marginRight: 10,
		padding: '5px 10px',
		fontSize: 14,
		lineHeight: '22px',
		fontWeight: '600',
		textAlign: 'center',
		cursor: 'pointer',
		color: theme.custom.colors.darkGray,
		border: `1px solid ${theme.custom.colors.gray}`,
		borderRadius: '4px',
		[theme.breakpoints.down('md')]: {
			padding: '7px 10px'
		}
	},
	activeGroup: {
		color: '#000000',
		backgroundColor: theme.custom.colors.gray,
		border: `1px solid ${theme.custom.colors.gray}`,
		borderRadius: '4px'
	},
	grayBox: {
		borderRadius: 8,
		minHeight: 50,
		width: '100%',
		marginBottom: 10,
		padding: '5px 25px',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: theme.custom.colors.kozmikGray
	},
	radioLabel: {
		fontSize: 14
	},
	successModalBox: {
		width: '100%',
		height: '100%',
		padding: 30
	},
	successTitle: {
		marginLeft: 10,
		marginTop: 20,
		fontWeight: '600',
		fontSize: 16
	},
	successSubTitle: {
		fontWeight: 'bold',
		fontSize: 12,
		color: theme.custom.colors.darkGray
	},
	iconBorder: {
		padding: 10,
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.17)'
	},
	radioBoxDesc: {
		fontSize: 12,
		color: theme.custom.colors.darkGray,
		opacity: 0.7,
		marginBottom: 9,
		marginTop: -5
	},
	copyBtnTxt: {
		color: theme.custom.colors.kozmikBlue,
		fontSize: 12,
		fontWeight: '600',
		textAlign: 'left',
		textTransform: 'none'
	},
	copyBtnIcon: {
		color: theme.custom.colors.kozmikBlue,
		fontSize: 12
	},
	historyModalBox: {
		width: '100%',
		height: '100%',
		padding: 30
	},
	notPublished: {
		fontSize: 12,
		color: theme.custom.colors.darkGray,
		opacity: 0.7
	},
	contentTitle: {
		marginBottom: 5,
		fontSize: 16,
		fontWeight: '600'
	},
	contentDesc: {
		marginBottom: 10,
		fontSize: 12,
		color: theme.custom.colors.darkGray,
		opacity: 0.7
	},
	privacyGroup: {
		borderBottom: `1px solid ${theme.custom.colors.gray}`,
		paddingBottom: 10,
		marginBottom: 20
	},
	privacyGroupLast: {
		borderBottom: 'unset'
	},
	publishButtonText: {
		fontSize: 14,
		color: theme.custom.colors.kozmikDarkBlue,
		fontWeight: 'bold'
	},
	buttonText: {
		color: '#FFFFFF',
		fontSize: 14,
		fontWeight: 'bold'
	},
	appInfo: {
		width: '100%',
		borderBottom: `1px solid ${theme.custom.colors.gray}`,
		paddingBottom: 30,
		marginTop: 20,
		marginBottom: 20,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'flex-start'
	},
	appInfoLeft: {
		marginRight: 10
	},
	appInfoRight: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'center'
	},
	appIcon: {
		width: 70,
		height: 70,
		borderRadius: 8
	},
	appName: {
		fontSize: 12,
		fontWeight: 'bold',
		color: theme.custom.colors.darkGray
	},
	seeHistory: {
		fontSize: 12,
		color: theme.custom.colors.kozmikBlue,
		cursor: 'pointer'
	},
	historyTitle: {
		marginBottom: 15,
		fontSize: 16,
		fontWeight: '600'
	},
	credentials: {
		fontSize: 12,
		color: theme.custom.colors.red,
		textAlign: 'center',
		paddingTop: 5,
		paddingBottom: 10
	},
	platforms: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 30
	},
	platformsInfo: {
		fontSize: 12,
		marginBottom: 10
	},
	platform: {
		position: 'relative',
		width: 100,
		height: 100,
		borderRadius: 8,
		cursor: 'pointer',
		backgroundColor: theme.custom.colors.kozmikGray,
		border: `1px solid ${theme.custom.colors.kozmikGray}`
	},
	platformSelected: {
		border: `1px solid ${theme.custom.colors.kozmikDarkBlue}`
	},
	platformIconDisabled: {
		opacity: 0.4
	},
	badge: {
		fontSize: 9,
		fontWeight: 'bold',
		borderRadius: 7.5,
		padding: '2px 8px',
		position: 'absolute',
		top: 5,
		right: 5,
		backgroundImage:
			'linear-gradient(90deg, #96f1ce 50%, rgba(27,54,254,0.3) 100%)'
	}
}))

const AppHeader = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	let { appId } = useParams()

	const defaultMessage = t('appUsers.inviteModal.defaultMessage')

	const [successVisible, setSuccessVisible] = useState(false)
	const [historyVisible, setHistoryVisible] = useState(false)
	const [isPublished, setIsPublished] = useState(false)

	const [appName, setAppName] = useState('')
	const [icon, setIcon] = useState(null)
	const [accentColor, setAccentColor] = useState(null)

	const [history, setHistory] = useState([])

	const [appLink, setAppLink] = useState('')

	/*****PUBLISH_MODULE*****/
	const [publishPop, setPublishPop] = useState(null)

	const openPublishPop = (event) => {
		setPublishPop(event.currentTarget)
	}

	const closePublishPop = () => {
		setPublishPop(null)
	}
	const publishPopOpen = Boolean(publishPop)

	/*****SHARE_MODULE*****/
	const [sharePop, setSharePop] = useState(null)

	const openSharePop = (event) => {
		setSharePop(event.currentTarget)
	}

	const closeSharePop = () => {
		setSharePop(null)
	}
	const sharePopOpen = Boolean(sharePop)

	/****SUBDOMAIN***************************/
	const [subDomain, setSubDomain] = useState('')
	const changeSubDomain = (e) => {
		timeoutDelay(submitSubDomain, e.target.value, 1000)
		setSubDomain(e.target.value)
	}
	const submitSubDomain = (sDomain) => {
		postAppDetailData('ShortName', sDomain, initialization)
	}

	/****INVITE***************************/
	const [emails, setEmails] = useState([])
	const [isEmailValid, setIsEmailValid] = useState(true)
	const [inviteMessage, setInviteMessage] = useState(defaultMessage)

	const validateEmail = (email) => {
		return new Promise((resolve) => {
			const re = /\S+@\S+\.\S+/
			const isValid = re.test(email)
			if (isValid || email == '') {
				setIsEmailValid(true)
			} else {
				setIsEmailValid(false)
			}
			resolve(isValid)
		})
	}

	const inviteUsers = () => {
		const data = {
			Emails: emails,
			Message: inviteMessage
		}
		appUserClient.invite(appId, data).then((response) => {
			if (response?.status == 200) {
				if (response?.data?.every((x) => x?.success)) {
					setSharePop(false)
				} else if (response?.data?.every((x) => !x?.success)) {
					toast.error(response?.data?.[0].errorDetails)
				} else if (response?.data?.some((x) => !x?.success)) {
					toast.error(t('appUsers.inviteToast.partial'))
					setSharePop(false)
				}
			} else {
				showToastResponseError(response)
			}
		})
	}

	const copyToClipboard = (val) => {
		navigator.clipboard.writeText(val)
	}

	/****INVITE_ONLY***************************/
	const [radioInviteVal, setRadioInviteVal] = useState(false)
	const changeRadioInviteVal = (e) => {
		submitInviteOnly(e.target.value)
		setRadioInviteVal(e.target.value)
	}
	const submitInviteOnly = (val) => {
		postAppDetailData('InviteOnly', val, initialization)
	}

	/****SIGN_IN_REQUIRED***************************/
	const [radiosSignInRequiredVal, setRadiosSignInRequiredVal] = useState(false)
	const changeRadioSignInRequiredVal = (e) => {
		submitSignInRequired(e.target.value)
		setRadiosSignInRequiredVal(e.target.value)
	}
	const submitSignInRequired = (val) => {
		postAppDetailData('SignInRequired', val, initialization)
	}

	/****LOGIN_METHOD***************************/
	const [loginMethod, setLoginMethod] = useState(null)
	const changeRadioLoginMethodVal = (e) => {
		setLoginMethod(e.target.value)
		submitLoginMethod(e.target.value)
	}
	const submitLoginMethod = (val) => {
		postAppDetailData('LoginMethod', val, initialization)
	}

	const platforms = [
		{
			text: 'Web',
			value: 'web',
			icon: PublishWeb,
			plans: ['free', 'starter', 'pro', 'business']
		},
		{
			text: 'App Store',
			value: 'ios',
			icon: PublishAppStore,
			plans: ['pro', 'business']
		},
		{
			text: 'Play Store',
			value: 'android',
			icon: PublishGooglePlay,
			plans: ['pro', 'business']
		}
	]

	/****PLATFORM***************************/
	const [platform, setPlatform] = useState('web')

	/****SUBMIT***************************/
	const submit = () => {
		if (!platform) return

		appClient.appPublish(appId, platform).then((response) => {
			if (response?.data?.success) {
				updateReduxDetailData('Published', true)

				setAppLink(response.data?.data?.start_url)
				setSuccessVisible(true)
			} else {
				showToastResponseError(response)
			}
		})
	}

	const initialization = (appDetail) => {
		getHistory()

		setAppName(appDetail?.Name || '')
		setIcon(appDetail?.Icon || '')

		setIsPublished(appDetail?.Published || false)

		setAppLink(`https://${appDetail?.ShortName || ''}.kozmik.app`)
		setSubDomain(appDetail?.ShortName || '')

		setRadioInviteVal(appDetail?.InviteOnly || false)
		setRadiosSignInRequiredVal(appDetail?.SignInRequired || false)
		setLoginMethod(appDetail?.LoginMethod || null)

		setAccentColor(appDetail?.AccentColor || null)
	}

	const initializationSetting = (setting) => {
		setWebConfigured(true)
		setAndroidConfigured(setting.AndroidConfigured)
		setIosConfigured(setting.IOSConfigured)
	}

	const postAppDetailData = (propName, propData, callback = null) => {
		if (appId) {
			let updateData = {
				Id: appId
			}
			updateData[propName] = propData

			appClient
				.update(updateData)
				.then((response) => {
					if (response.status == 200) {
						updateReduxDetailData(propName, propData)
					} else {
						showToastResponseError(response)
						callback()
					}
				})
				.catch((error) => {
					callback()
				})
		}
	}

	const updateReduxDetailData = (propName, propData) => {
		let tempData = { ...props.appDetail }
		tempData[propName] = propData
		props.setDetail(tempData)
	}

	const groups = [
		{
			name: 'publish',
			title: t('publishModule.groups.publish')
		},
		{
			name: 'privacy',
			title: t('publishModule.groups.privacy')
		}
	]

	const [group, setGroup] = useState('publish')
	const [webConfigured, setWebConfigured] = useState(false)
	const [androidConfigured, setAndroidConfigured] = useState(false)
	const [iosConfigured, setIosConfigured] = useState(false)

	const [submitButtonActive, setSubmitButtonActive] = useState(true)

	const checkSubmitButtonActive = (platform) => {
		switch (platform) {
			case 'web':
				setSubmitButtonActive(webConfigured)
				break
			case 'android':
				setSubmitButtonActive(androidConfigured)
				break
			case 'ios':
				setSubmitButtonActive(iosConfigured)
				break

			default:
				break
		}
	}

	const getHistory = () => {
		appClient.getHistory(appId, 'null').then((response) => {
			if (response?.data?.success) {
				setHistory(response?.data?.data)
			} else {
				showToastResponseError(response)
			}
		})
	}

	useEffect(() => {
		if (sharePop) {
			setEmails([])
			setIsEmailValid(true)
			setInviteMessage(defaultMessage)
		}
	}, [sharePop])

	useEffect(() => {
		props.appDetail && initialization(props.appDetail)
	}, [props.appDetail])

	useEffect(() => {
		props.setting && initializationSetting(props.setting)
	}, [props.setting])

	return (
		<>
			<div className={classes.mainContainer}>
				<div className={classes.leftContainer}>
					<Link to={'/'}>
						<div className={cx(classes.textBox)}>
							<ArrowBackIos style={{ fontSize: 14, color: '#464646' }} />
							<Typography className={classes.textName}>{appName}</Typography>
							{isPublished ? (
								<Typography
									component={'span'}
									className={classes.isPublishedBadge}
								>
									{t('appHeader.published')}
								</Typography>
							) : null}
						</div>
					</Link>
				</div>
				<div
					className={classes.centerContainer}
					style={{ display: props.previewButtonsVisible ? 'flex' : 'none' }}
				>
					<PreviewButtons
						previewButtonIndex={props.previewButtonIndex}
						setPreviewButtonIndex={props.setPreviewButtonIndex}
					/>
				</div>
				<div
					className={cx(
						classes.rightContainer,
						classes.taRight,
						classes.jcSBetween
					)}
				>
					{props.appDetail?.Published && (
						<Button
							className={classes.share}
							color='primary'
							variant='text'
							onClick={openSharePop}
						>
							<Typography className={classes.shareText}>
								{t('appHeader.share')}
							</Typography>{' '}
						</Button>
					)}

					<Button
						className={classes.publish}
						color='primary'
						variant='contained'
						onClick={openPublishPop}
					>
						<Typography className={classes.publishText}>
							{t('appHeader.publish')}
						</Typography>{' '}
					</Button>
				</div>
			</div>

			<Popover
				id={'share-popover'}
				open={sharePopOpen}
				anchorEl={sharePop}
				onClose={closeSharePop}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: -10,
					horizontal: 'center'
				}}
			>
				<Box className={classes.modalContainer}>
					<Box className={classes.modalQrContainer}>
						<Box>
							<QRCode
								value={appLink}
								className={classes.qrCode}
								style={{ width: 100, height: 100 }}
							/>
						</Box>
						<Box className={classes.modalLinkContainer}>
							<Box style={{ width: '100%' }}>
								<KozmikTextField
									className={classes.input}
									fullWidth
									placeholder={t('publishModule.privacy.subDomainPlaceholder')}
									style={{
										backgroundColor: kozmikTheme.colors.kozmikGray,
										overflow: 'hidden'
									}}
									value={subDomain}
									onChange={changeSubDomain}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<Typography className={classes.adornmentText}>
													https://
												</Typography>
											</InputAdornment>
										),

										endAdornment: (
											<InputAdornment position='end'>
												<Typography className={classes.adornmentText}>
													.kozmik.app
												</Typography>
											</InputAdornment>
										)
									}}
								/>
							</Box>
							<Box
								style={{
									width: '100%',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'flex-start'
								}}
							>
								<a href={appLink} target='_blank' rel='noreferrer'>
									<Typography className={classes.appLinkAdornment}>
										<OpenInNew className={classes.adornmentIcon} />
										{t('appHeader.visit')}
									</Typography>
								</a>

								<Button
									startIcon={<ContentCopy className={classes.copyIcon} />}
									size='large'
									variant='contained'
									onClick={() => copyToClipboard(appLink)}
									sx={{
										textTransform: 'none',
										backgroundColor: `transparent !important`,
										boxShadow: 'none !important',
										fontSize: 12,
										borderRadius: '4px',
										margin: '5px'
									}}
								>
									<Typography className={classes.copyText}>
										{t('appHeader.copy')}
									</Typography>
								</Button>
							</Box>
						</Box>
					</Box>

					<Box>
						<Typography className={classes.modalTitle}>
							{t('appUsers.inviteModal.title')}
						</Typography>
						<Typography className={classes.modalSubTitle}>
							{t('appUsers.inviteModal.subTitle')}
						</Typography>
						<TagInput
							error={!isEmailValid}
							tags={emails}
							setTags={setEmails}
							onInputChange={(e) => validateEmail(e.target.value)}
							placeholder={t('appUsers.inviteModal.emailPlaceholder')}
						/>
						<KozmikTextField
							fullWidth
							placeholder={t('appUsers.inviteModal.messagePlaceholder')}
							value={inviteMessage}
							onChange={(e) => setInviteMessage(e.target.value)}
							className={classes.modalMultilineInput}
							multiline
							rows={3}
						/>
					</Box>

					<Box
						style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}
					>
						<Button
							fullWidth
							disabled={!isEmailValid || !emails?.length}
							size='large'
							variant='contained'
							onClick={inviteUsers}
							sx={{
								textTransform: 'none',
								backgroundColor: `${kozmikTheme.colors.kozmikBlue} !important`,
								color: '#ffffff',
								boxShadow: 'none !important',
								fontSize: '14px',
								borderRadius: '4px',
								border: `1px solid ${kozmikTheme.colors.kozmikBlue}`,
								opacity: !isEmailValid || !emails?.length ? 0.4 : 1 //for disabled
							}}
						>
							<Typography className={classes.modalButtonText}>
								{t('appUsers.inviteModal.submit')}
							</Typography>
						</Button>
					</Box>
				</Box>
			</Popover>

			<Popover
				id={'publish-popover'}
				open={publishPopOpen}
				anchorEl={publishPop}
				onClose={closePublishPop}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: -10,
					horizontal: 'center'
				}}
			>
				<Box className={classes.modalContainer}>
					<Box display={'flex'} flexDirection={'column'}>
						<Box
							display={'flex'}
							flexDirection={'row'}
							className={classes.groups}
						>
							{groups.map((i, index) => (
								<Typography
									key={index}
									className={cx(
										classes.group,
										group === i.name && classes.activeGroup
									)}
									onClick={() => {
										setGroup(i.name)
									}}
								>
									{i.title}
								</Typography>
							))}
						</Box>

						{group === 'publish' && (
							<Box style={{ width: '100%' }}>
								<Box className={classes.appInfo}>
									<Box className={classes.appInfoLeft}>
										<CardMedia
											image={icon}
											src={Kozmik}
											component='img'
											className={classes.appIcon}
											style={{
												backgroundColor: accentColor
											}}
										/>
									</Box>
									<Box className={classes.appInfoRight}>
										<div className={classes.mt10}>
											<Typography className={classes.appName}>
												{appName}
											</Typography>
										</div>

										<div
											className={classes.mt10}
											onClick={() => setHistoryVisible(true)}
										>
											<Typography className={classes.seeHistory}>
												{t('publishModule.publish.history')}
											</Typography>
										</div>
									</Box>
								</Box>
								<Box style={{ width: '100%' }}>
									<Typography className={classes.platformsInfo}>
										{t('publishModule.publish.selectPlatform')}
									</Typography>
								</Box>
								<Box className={classes.platforms}>
									{platforms.map((p, i) => {
										const authority = p.plans.some((x) =>
											props?.currentOrg?.plan?.includes(x)
										)

										return (
											<div
												key={i}
												className={cx(
													classes.platform,
													platform === p.value && classes.platformSelected
												)}
												onClick={() => {
													if (authority) {
														setPlatform(p.value)
														checkSubmitButtonActive(p.value)
													} else {
														window.location.href = '/subscription?modal=true'
													}
												}}
											>
												<p.icon
													key={i}
													className={cx(
														!authority && classes.platformIconDisabled
													)}
												/>

												{!authority && (
													<span className={classes.badge}>
														{t('appIntegrationsTemp.upgrade')}
													</span>
												)}
											</div>
										)
									})}
								</Box>
								<Box style={{ width: '100%' }}>
									{!submitButtonActive && (
										<Typography className={classes.credentials}>
											{t('publishModule.publish.credentials')}
										</Typography>
									)}
									<Button
										fullWidth
										disabled={!submitButtonActive}
										size='small'
										variant='contained'
										onClick={() => submit()}
										sx={{
											textTransform: 'none',
											backgroundColor: `#6BEFC1 !important`,
											opacity: submitButtonActive ? 1 : 0.4,
											boxShadow: 'none !important',
											height: '40px',
											borderRadius: '4px'
										}}
									>
										<Typography className={classes.publishButtonText}>
											{t('publishModule.publish.publish')}
										</Typography>
									</Button>
								</Box>
							</Box>
						)}

						{group === 'privacy' && (
							<Box style={{ padding: 10 }}>
								<Box className={classes.privacyGroup}>
									<Typography className={classes.contentTitle}>
										{t('publishModule.privacy.signUpTitle')}
									</Typography>
									<Typography className={classes.contentDesc}>
										{t('publishModule.privacy.signUpDesc')}
									</Typography>
									<FormControl style={{ width: '100%' }}>
										<RadioGroup
											value={radioInviteVal}
											onChange={changeRadioInviteVal}
											style={{ width: '100%' }}
										>
											<Box>
												<FormControlLabel
													classes={{
														label: classes.radioLabel
													}}
													value={true}
													control={<Radio size='small' />}
													label={t('publishModule.privacy.signUpPrivate')}
												/>
											</Box>
											<Box>
												<FormControlLabel
													classes={{
														label: classes.radioLabel
													}}
													value={false}
													control={<Radio size='small' />}
													label={t('publishModule.privacy.signUpPublic')}
												/>
											</Box>
										</RadioGroup>
									</FormControl>
								</Box>
								<Box className={classes.privacyGroup}>
									<Typography className={classes.contentTitle}>
										{t('publishModule.privacy.signInTitle')}
									</Typography>
									<Typography className={classes.contentDesc}>
										{t('publishModule.privacy.signInDesc')}
									</Typography>
									<FormControl style={{ width: '100%' }}>
										<RadioGroup
											value={radiosSignInRequiredVal}
											onChange={changeRadioSignInRequiredVal}
											style={{ width: '100%' }}
										>
											<Box>
												<Grid item container xs={12}>
													<Grid item xs={12}>
														<FormControlLabel
															classes={{
																label: classes.radioLabel
															}}
															value={true}
															control={<Radio size='small' />}
															label={t('publishModule.privacy.signInRequired')}
														/>
													</Grid>
													<Grid item xs={12}>
														<Typography className={classes.radioBoxDesc}>
															{t('publishModule.privacy.signInRequiredDesc')}
														</Typography>
													</Grid>
												</Grid>
											</Box>
											<Box>
												<Grid item container xs={12}>
													<Grid item xs={12}>
														<FormControlLabel
															classes={{
																label: classes.radioLabel
															}}
															value={false}
															control={<Radio size='small' />}
															label={t('publishModule.privacy.signInOptional')}
														/>
													</Grid>
													<Grid item xs={12}>
														<Typography className={classes.radioBoxDesc}>
															{t('publishModule.privacy.signInOptionalDesc')}
														</Typography>
													</Grid>
												</Grid>
											</Box>
										</RadioGroup>
									</FormControl>
								</Box>
								<Box
									className={cx(classes.privacyGroup, classes.privacyGroupLast)}
								>
									<Typography className={classes.contentTitle}>
										{t('publishModule.privacy.authenticationTitle')}
									</Typography>
									<Typography className={classes.contentDesc}>
										{t('publishModule.privacy.authenticationDesc')}
									</Typography>

									<FormControl style={{ width: '100%' }}>
										<RadioGroup
											value={loginMethod}
											onChange={changeRadioLoginMethodVal}
											style={{ width: '100%' }}
										>
											<Box>
												<Grid item container xs={12}>
													<Grid item xs={12}>
														<FormControlLabel
															classes={{
																label: classes.radioLabel
															}}
															value='EmailPassword'
															control={<Radio size='small' />}
															label={t(
																'publishModule.privacy.authenticationEmailPassword'
															)}
														/>
													</Grid>
													<Grid item xs={12}>
														<Typography className={classes.radioBoxDesc}>
															{t(
																'publishModule.privacy.authenticationEmailPasswordDesc'
															)}
														</Typography>
													</Grid>
												</Grid>
											</Box>
											<Box>
												<Grid item container xs={12}>
													<Grid item xs={12}>
														<FormControlLabel
															classes={{
																label: classes.radioLabel
															}}
															value='Pin'
															control={<Radio size='small' />}
															label={t(
																'publishModule.privacy.authenticationPin'
															)}
														/>
													</Grid>
													<Grid item xs={12}>
														<Typography className={classes.radioBoxDesc}>
															{t('publishModule.privacy.authenticationPinDesc')}
														</Typography>
													</Grid>
												</Grid>
											</Box>
										</RadioGroup>
									</FormControl>
								</Box>
							</Box>
						)}
					</Box>
				</Box>
			</Popover>

			<KozmikModal
				visible={successVisible}
				hideModal={() => setSuccessVisible(false)}
				flexHeight
				sm
			>
				<Grid
					container
					justifyContent='center'
					className={classes.successModalBox}
				>
					<Grid
						item
						container
						xs={12}
						alignItems='center'
						className={classes.mb20}
					>
						<CardMedia
							image={successImage}
							component='img'
							style={{ width: 52, height: 52 }}
						/>
						<Typography className={classes.successTitle}>
							{t('publishModule.webModal.success.title')}
						</Typography>
					</Grid>
					<Grid item xs={12} container justifyContent='center'>
						<QRCode
							value={appLink}
							className={classes.iconBorder}
							style={{ width: 150, height: 150 }}
						/>
					</Grid>
					<Grid item xs={12} className={cx(classes.mt15, classes.mb20)}>
						<Typography className={classes.successSubTitle}>
							{t('publishModule.webModal.success.appLinkTitle')}
						</Typography>
						<Grid
							item
							container
							xs={12}
							direction='row'
							justifyContent='space-between'
							alignItems='center'
							style={{ marginBottom: 10 }}
						>
							<Grid item xs={9}>
								<KozmikTextField
									className={classes.input}
									fullWidth
									style={{
										backgroundColor: kozmikTheme.colors.kozmikGray,
										overflow: 'hidden'
									}}
									value={appLink}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<a href={appLink} target='_blank' rel='noreferrer'>
													<Typography className={classes.appLinkAdornment}>
														<OpenInNew className={classes.adornmentIcon} />
														{t('publishModule.webModal.success.visit')}
													</Typography>
												</a>
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<Button
									startIcon={<ContentCopy className={classes.copyBtnIcon} />}
									// fullWidth
									size='large'
									variant='contained'
									onClick={() => copyToClipboard(appLink)}
									sx={{
										textTransform: 'none',
										backgroundColor: `transparent !important`,
										boxShadow: 'none !important',
										fontSize: 14,
										borderRadius: '4px',
										margin: '5px'
									}}
								>
									<Typography className={classes.copyBtnTxt}>
										{t('publishModule.webModal.success.copy')}
									</Typography>
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid item container xs={12} justifyContent='center'>
						<Button
							onClick={() => setSuccessVisible(false)}
							size='small'
							variant='contained'
							sx={{
								textTransform: 'none',
								backgroundColor: `${kozmikTheme.colors.kozmikBlue} !important`,
								boxShadow: 'none !important',
								height: '40px',
								width: '216px',
								borderRadius: '4px'
							}}
						>
							<Typography className={classes.buttonText}>
								{t('publishModule.webModal.success.button')}
							</Typography>
						</Button>
					</Grid>
				</Grid>
			</KozmikModal>

			<KozmikModal
				visible={historyVisible}
				hideModal={() => setHistoryVisible(false)}
				md
			>
				<Box className={classes.historyModalBox}>
					<Typography className={classes.historyTitle}>
						{t('publishModule.androidModal.historyTitle')}
					</Typography>

					{history?.length > 0 ? (
						history.map((item, index) => (
							<HistoryItem
								platform={history.Platform}
								item={item}
								key={index}
							/>
						))
					) : (
						<Typography className={classes.notPublished}>
							{t('publishModule.androidModal.notPublished')}
						</Typography>
					)}
				</Box>
			</KozmikModal>
		</>
	)
}

const mapStateToProps = (state) => ({
	...state.appData,
	...state.userInfo,
	...state.currentOrg
})

const mapDispatchToProps = (dispatch) => {
	return {
		setDetail: (x) => dispatch(setDetail(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader)

import React from 'react'
import { LaptopMac, TabletMac, PhoneIphone } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import cx from 'classnames'

const useStyles = makeStyles(() => ({
	mainContainer: {
		// width:'100%'
		display: 'flex',
		alignItems: 'center'
	},
	button: {
		padding: 5,
		margin: '0 20px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		color: '#BBBBBD'
	},
	icon: {
		fontSize: 25
	},
	active: {
		color: '#000000'
	},
	seperator: {
		width: 0,
		height: 25,
		border: '1px solid #BBBBBD'
	}
}))

const PreviewButtons = (props) => {
	const classes = useStyles()

	const buttonClass = (index) =>
		index === props.previewButtonIndex && classes.active

	return (
		<div className={classes.mainContainer}>
			<div
				className={cx(classes.button, buttonClass(0))}
				onClick={() => props.setPreviewButtonIndex(0)}
			>
				<PhoneIphone className={classes.icon} />
			</div>
			<div className={classes.seperator}>{/*SEPERATOR*/}</div>
			<div
				className={cx(classes.button, buttonClass(1))}
				onClick={() => props.setPreviewButtonIndex(1)}
			>
				<TabletMac className={classes.icon} />
			</div>
			<div className={classes.seperator}>{/*SEPERATOR*/}</div>
			<div
				className={cx(classes.button, buttonClass(2))}
				onClick={() => props.setPreviewButtonIndex(2)}
			>
				<LaptopMac className={classes.icon} style={{ fontSize: 30 }} />
			</div>
		</div>
	)
}

export default PreviewButtons

import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Ionicons from 'react-native-vector-icons/dist/Ionicons'
import glyphMap from 'react-native-vector-icons/dist/glyphmaps/Ionicons.json'
import {
	Box,
	Popover,
	CircularProgress,
	InputAdornment,
	Typography
} from '@mui/material'
import { Search, ArrowDropDown } from '@mui/icons-material'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'
import InfiniteScroll from '../../atoms/infiniteScroll/InfiniteScroll'

const useStyles = makeStyles((theme) => ({
	selectButton: {
		width: (props) => (props?.fullWidth ? '100%' : 80),
		padding: '0 8px',
		height: 38,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		cursor: 'pointer',
		borderRadius: 3,
		backgroundColor: (props) =>
			props?.bgColor ? props.bgColor : theme.custom.colors.kozmikGray
	},
	emptyText: {
		fontSize: 14,
		color: theme.custom.colors.textSecondary
	},
	popoverBox: {
		padding: 8,
		display: 'flex',
		flexDirection: 'column'
	},
	iconBox: {
		width: '20%',
		height: 45,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		borderRadius: 8,
		'&:hover': {
			backgroundColor: theme.custom.colors.kozmikGray
		}
	},
	inputBox: {
		width: 233
	},
	inputWithAdornment: {
		backgroundColor: theme.custom.colors.kozmikGray,
		borderRadius: '3px',
		border: 'none',
		marginBottom: 4,
		'& .MuiInputBase-adornedStart': {
			paddingLeft: 0,
			overflow: 'hidden'
		}
	},
	adornment: {
		backgroundColor: theme.custom.colors.kozmikGray,
		padding: '6px 10px',
		color: theme.custom.colors.textPrimary,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	inputIcon: {
		color: theme.custom.colors.textSecondary,
		opacity: 0.6,
		cursor: 'pointer'
	},
	scrollBox: {
		width: 233,
		maxHeight: 360,
		overflow: 'auto'
	},
	infiniteScroll: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	active: {
		backgroundColor: `${theme.custom.colors.kozmikLightBlue} !important`
	}
}))

const IoniconsSelect = (props) => {
	const { t } = useTranslation()
	const classes = useStyles(props)

	const iconNames = Object.keys(glyphMap).filter(
		(x) => x.startsWith('ios') && !x.endsWith('-sharp')
	)
	let scrollerRef = null

	const [iconPop, setIconPop] = useState(null)
	const iconPopOpen = Boolean(iconPop)
	const openIconPop = (event) => {
		setIconPop(event.currentTarget)
		setIcons(iconNames)
		setVisibleIcons([])
		setSearchTerm('')
	}

	const [searchTerm, setSearchTerm] = useState('')
	const [icons, setIcons] = useState([])
	const [visibleIcons, setVisibleIcons] = useState([])

	const loadFunc = (pageNumber) => {
		const visibles = icons.slice(0, pageNumber * 40)
		setVisibleIcons(visibles)
	}

	const searchTermOnchange = (e) => {
		scrollerRef.pageLoaded = 0 //Workaround:reset InfiniteScroll pageNumber

		setSearchTerm(e.target.value)
		const filteredIcons = iconNames?.filter((x) =>
			x?.toLowerCase()?.includes(e.target.value?.toLowerCase())
		)
		setIcons(filteredIcons)
		setVisibleIcons([])
	}

	const onChangeHandler = (newVal, currentVal) => {
		if (newVal != currentVal) {
			props?.onChange(newVal)
		}
		setIconPop(null)
	}

	return (
		<>
			<Box className={classes.selectButton} onClick={openIconPop}>
				{props?.value ? (
					<Ionicons name={props?.value} size={17} color='#000000' />
				) : (
					<Typography className={classes.emptyText}>
						{t('ionIconsSelect.select')}
					</Typography>
				)}

				<ArrowDropDown className={classes.inputIcon} />
			</Box>
			<Popover
				id={'icon-popover'}
				open={iconPopOpen}
				anchorEl={iconPop}
				onClose={() => setIconPop(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<Box className={classes.popoverBox}>
					<Box className={classes.inputBox}>
						<KozmikTextField
							className={classes.inputWithAdornment}
							fullWidth
							placeholder={t('ionIconsSelect.search')}
							value={searchTerm}
							onChange={searchTermOnchange}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<Box className={classes.adornment}>
											<Search className={classes.inputIcon} />
										</Box>
									</InputAdornment>
								)
							}}
						/>
					</Box>
					<Box className={classes.scrollBox}>
						<InfiniteScroll
							ref={(scroll) => {
								scrollerRef = scroll
							}}
							pageStart={0}
							loadMore={loadFunc}
							hasMore={icons?.length != visibleIcons?.length}
							loader={
								<CircularProgress key={0} size={16} sx={{ margin: '10px' }} />
							}
							useWindow={false}
							className={classes.infiniteScroll}
						>
							{!!props?.emptySelectable && (
								<Box
									className={cx(
										classes.iconBox,
										!props?.value ? classes.active : null
									)}
									// style={{ border: '1px solid gray' }}
									onClick={() => onChangeHandler('', props?.value)}
								/>
							)}
							{visibleIcons.map((item, index) => (
								<Box
									key={index}
									className={cx(
										classes.iconBox,
										item == props?.value ? classes.active : null
									)}
									onClick={() => onChangeHandler(item, props?.value)}
								>
									<Ionicons name={item} size={25} color='#000000' />
								</Box>
							))}
						</InfiniteScroll>
					</Box>
				</Box>
			</Popover>
		</>
	)
}
export default IoniconsSelect

import { v4 as uuidv4 } from 'uuid'

import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
// import getFilterOpByDataType from '../../methods/getFilterOpByDataType'
import fieldDataTypes from '../../common/fieldDataTypes'
// import i18next from '../../i18n/i18n'
import languages from '../../i18n/languages'
import relationTypes from '../../common/enums/relationTypes'
import getArrayExcludingForbidArray from '../../methods/getArrayExcludingForbidArray'
import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'

import filterModal from './filterModal'

const method = (table, searcheable = false, actions = null) => {
	const txtFields = getEntityFieldsByDataTypes(
		null,
		null,
		fieldDataTypes.textBased,
		table,
		true,
		relationTypes.one
	)
	const dateFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Date'],
		table,
		true,
		relationTypes.one
	)

	const filteredTxtFields = getArrayExcludingForbidArray(
		txtFields,
		'name',
		systemFieldNames.all
	)
	const filteredDateFields = getArrayExcludingForbidArray(
		dateFields,
		'name',
		systemFieldNames.notVisible
	)
	const titleField = txtFields?.find((x) => x.name == 'Name')

	// const firstTxtFieldFilterOps = filteredTxtFields?.[0]
	// 	? getFilterOpByDataType(filteredTxtFields[0].dataType)
	// 	: null
	// const searchOp = firstTxtFieldFilterOps
	// 	? firstTxtFieldFilterOps.searchOp
	// 	: null

	const object = {
		_uid: uuidv4(),
		component: 'calendarList',
		type: 'list', // list, day, threeDays, week, month
		title: '',
		source: table?.name,
		mapping: {
			id: {
				field: 'Id',
				type: 'RowId'
			},
			title: {
				field: titleField?.name,
				type: 'Text'
			},
			text: {
				field: filteredTxtFields?.[0]?.name,
				type: filteredTxtFields?.[0]?.dataType
			},
			startDate: {
				field: filteredDateFields?.[0]?.name,
				type: filteredDateFields?.[0]?.dataType
			},
			endDate: {
				field: filteredDateFields?.[1]?.name,
				type: filteredDateFields?.[1]?.dataType
			}
		},
		sorting: {
			field: titleField?.name,
			sort: 'asc'
		},
		filters: {
			groupOp: 'OR',
			rules: []
		},
		searcheable: searcheable,
		searchPlaceholder: {
			default: languages.en.schemas.calendarList.searchPlaceholder
		},
		filterModal: filterModal(),
		actions: actions ?? [
			{
				type: null
			}
		],
		limit: 120,
		seeAllUrl: null,
		seeAllText: {
			default: languages.en.schemas.calendarList.seeAll
		},
		moreText: {
			default: languages.en.schemas.list.more
		},
		spacing: 'xl',
		emptyState: {
			default: languages.en.schemas.calendarList.emptyState
		}
	}

	return object
}

export default method

import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from '@mui/material'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import MenuIcon from '@mui/icons-material/Menu'
import { Apps, People, Work } from '@mui/icons-material'
import { Link } from 'react-router-dom'

import LeftFrame from '../../templates/leftFrame/LeftFrame'

// import OnboardingMobile from '../../organisms/onboarding/OnboardingMobile'

import { ReactComponent as KozmikLogo } from '../../../assets/icons/KozmikLogo.svg'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		width: '100%',
		height: '100vh',
		backgroundColor: theme.custom.colors.kozmikGray,
		display: 'flex',
		flexWrap: 'nowrap',
		overflow: 'hidden',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column'
		}
	},
	leftFrameContainer: {
		padding: 20,
		width: '256px',
		minWidth: '256px',
		[theme.breakpoints.down('md')]: {
			display: 'none'
		}
	},
	contentContainer: {
		flexGrow: 1,
		backgroundColor: (props) =>
			props?.bgColor ? props.bgColor : 'transparent',
		overflowY: 'auto',
		padding: '30px 20px 30px 0',
		[theme.breakpoints.down('md')]: {
			padding: '30px 20px'
		}
	},
	overlayMenu: {
		height: '0%',
		width: '100%',
		position: 'fixed',
		zIndex: 10,
		top: 0,
		left: 0,
		backgroundColor: '#ffffff',
		overflowY: 'hidden',
		transition: '0.5s',
		display: 'none',
		flexDirection: 'column',
		[theme.breakpoints.down('md')]: {
			display: 'flex'
		}
	},
	overlayContent: {
		flexGrow: 1
	},
	closeBtn: {},
	headerContainer: {
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '10px 20px',
		borderBottom: `1px solid ${theme.custom.colors.gray}`,
		backgroundColor: '#ffffff',
		display: 'none',
		[theme.breakpoints.down('md')]: {
			display: 'flex'
		}
	},
	hamburger: {
		cursor: 'pointer',
		width: 29,
		height: 'auto',
		color: theme.custom.colors.kozmikBlue
	},
	logo: {
		width: 100,
		height: 'auto'
	},
	rightFrameContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	upgradeButton: {
		textTransform: 'none',
		backgroundColor: `${theme.custom.colors.kozmikGreen} !important`,
		boxShadow: 'none !important',
		borderRadius: '5px',
		height: '25px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '5px 10px',
		marginRight: 10
	},
	upgradeText: {
		color: theme.custom.colors.textPrimary,
		fontSize: 12,
		fontWeight: '600'
	}
}))

const MasterLayout = (props) => {
	const classes = useStyles({ bgColor: props?.bgColor })
	const { t } = useTranslation()

	const menuList = [
		{
			icon: Apps,
			Name: t('masterNav.apps'),
			Count: props?.apps?.length || 0,
			Href: '/',
			isActive: true
		},
		{
			icon: People,
			Name: t('masterNav.team'),
			Count: '0',
			Href: '/team',
			isActive: false
		}
		// {
		// 	icon: Work,
		// 	Name: t('masterNav.resources'),
		// 	Count: '0',
		// 	Href: 'https://docs.kozmik.io',
		// 	isActive: false
		// }
	]

	const [isMenuVisible, setIsMenuVisible] = useState(false)

	return (
		<div className={classes.mainContainer}>
			{props.auth && (
				<>
					<div className={classes.leftFrameContainer}>
						<LeftFrame menuItems={menuList} />
					</div>
					<div className={classes.headerContainer}>
						<Link to={'/'}>
							<KozmikLogo className={classes.logo} />
						</Link>
						<div className={classes.rightFrameContainer}>
							{!!props?.usage && (
								<Link
									to={'/subscription?modal=true'}
									className={classes.upgradeButton}
								>
									<Typography className={classes.upgradeText}>
										{t('masterAccount.upgrade')}
									</Typography>
								</Link>
							)}
							<MenuIcon
								onClick={() => setIsMenuVisible(true)}
								className={classes.hamburger}
							/>
						</div>
					</div>
					<div
						className={classes.overlayMenu}
						style={{ height: isMenuVisible ? '100%' : '0%' }}
					>
						<div className={classes.headerContainer}>
							<KozmikLogo className={classes.logo} />
							<MenuIcon
								onClick={() => setIsMenuVisible(false)}
								className={classes.hamburger}
							/>
						</div>
						<div className={classes.overlayContent}>
							<LeftFrame
								menuItems={menuList}
								collapseHamburger={() => setIsMenuVisible(false)}
							/>
						</div>
					</div>
				</>
			)}

			{/* {(props?.apps?.length || 0) === 0 && (
				<OnboardingMobile
					builderOnMobile={props.builderOnMobile}
					justSayHello={true}
				/>
			)} */}

			<div className={classes.contentContainer}>{props?.children}</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	...state.auth,
	...state.usage,
	apps: state.applications
})

export default connect(mapStateToProps, null)(MasterLayout)

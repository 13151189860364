import { v4 as uuidv4 } from 'uuid'

import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import convertFieldsToActionSourceFields from '../../methods/convertFieldsToActionSourceFields'
import defaultElementSchemas from '..'
import fieldDataTypes from '../../common/fieldDataTypes'
import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'
import languages from '../../i18n/languages'

const getInputByDataType = (fieldData, skipSystemFields = true) => {
	if (
		skipSystemFields &&
		systemFieldNames.notVisible.includes(fieldData.name)
	) {
		return null
	}

	switch (fieldData.dataType) {
		case 'Email':
			return defaultElementSchemas.inputMail(fieldData)
		case 'Phone':
			return defaultElementSchemas.inputPhone(fieldData)
		case 'Image':
			return defaultElementSchemas.imagePicker(fieldData)
		case 'Number':
			return fieldData.precision && fieldData.precision > 0
				? defaultElementSchemas.inputMoney(fieldData)
				: defaultElementSchemas.inputNumeric(fieldData)
		case 'Checkbox':
			return defaultElementSchemas.inputCheckbox(fieldData)
		case 'File':
			return defaultElementSchemas.filePicker(fieldData)
		case 'Date':
			return fieldData.includeTime
				? defaultElementSchemas.datePicker(fieldData, 'datetime')
				: defaultElementSchemas.datePicker(fieldData, 'date')
		case 'Relation':
			return defaultElementSchemas.inputChoice(fieldData)
		case 'Lookup':
			return null
		default:
			return defaultElementSchemas.inputText(fieldData)
	}
}

const method = (table, sameSource, skipSystemFields = true) => {
	let acceptedFields = getEntityFieldsByDataTypes(
		null,
		null,
		fieldDataTypes.all,
		table
	)

	acceptedFields = acceptedFields?.filter(
		(x) =>
			x.dataType != 'Lookup' &&
			x.dataType != 'Rollup' &&
			x.dataType != 'Formula'
	)

	const actionSourceFields = convertFieldsToActionSourceFields(
		table,
		null,
		table.name,
		null
	)

	const actions = sameSource
		? [
				{
					type: 'update',
					source: table.name,
					fields: actionSourceFields
				},
				{ type: 'back' }
		  ]
		: [
				{
					type: 'create',
					source: table.name,
					fields: actionSourceFields
				},
				{ type: 'back' }
		  ]

	const defaultButton = defaultElementSchemas.button(
		actions,
		languages.en.schemas.form.done
	)

	let object = {
		_uid: uuidv4(),
		component: 'form',
		header: false,
		source: table.name,
		buttons: [defaultButton],
		body: []
	}

	acceptedFields.map((field) => {
		const input = getInputByDataType(field, skipSystemFields)
		input && object.body.push(input)
	})

	return object
}

export default method
export { getInputByDataType }

import { v4 as uuidv4 } from 'uuid'

import languages from '../../i18n/languages'

const method = (actions = null, textDefault = null) => {
	const object = {
		_uid: uuidv4(),
		component: 'button',
		mapping: {
			text: {
				field: null,
				default: textDefault ? textDefault : languages.en.schemas.button.text,
				type: null
			}
		},
		icon: null, // 'account-cowboy-hat',
		corner: 'oval',
		type: 'full', // border,full,transparent
		spacing: 'sm',
		actions: actions ? actions : [{ type: null }]
	}

	return object
}

export default method

import * as React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
	buttonContainer: {
		display: 'inline-flex',
		alignItems: 'center',
		cursor: 'pointer'
	},
	icon: {
		fontSize: (props) => (props?.iconSize ? props.iconSize : 18),
		color: (props) =>
			props?.color ? props.color : theme.custom.colors.textPrimary
	},
	text: {
		paddingLeft: (props) => (props?.icon ? 5 : 0),
		color: (props) =>
			props?.color ? props.color : theme.custom.colors.textPrimary,
		fontSize: (props) => (props?.fontSize ? props.fontSize : 14),
		fontWeight: 'bold'
	}
}))

const TextButton = (props) => {
	const { text, icon, iconSize, fontSize, color, ...restProps } = props
	const classes = useStyles({ icon, iconSize, fontSize, color })

	return (
		<div className={classes.buttonContainer} {...restProps}>
			{!!icon && <props.icon className={classes.icon} />}
			{!!text && <Typography className={classes.text}>{text}</Typography>}
		</div>
	)
}

export default TextButton

import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { MoreVert, ArrowDropDown } from '@mui/icons-material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'

const AccordionBox = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: 'none',
	borderRadius: 4,
	backgroundColor: 'transparent',
	'&.Mui-expanded': {
		backgroundColor: theme.custom.colors.kozmikGray,
		boxShadow: 'none'
	},
	'&:before': {
		display: 'none'
	}
}))

const AccordionSummary = styled((props) => {
	const { icon, bgColor, ...restProps } = props

	return (
		<MuiAccordionSummary
			expandIcon={
				icon ? (
					<props.icon style={{ color: '#000000', fontSize: 16 }} />
				) : (
					<ArrowDropDown style={{ color: '#000000', fontSize: 20 }} />
				)
			}
			{...restProps}
			style={bgColor ? { backgroundColor: bgColor } : null}
		/>
	)
})(({ theme }) => ({
	backgroundColor: theme.custom.colors.kozmikGray,
	minHeight: '37px !important',
	paddingRight: '10px !important',
	borderRadius: 4,
	'&.Mui-expanded': {
		borderRadius: '4px 4px 0 0',
		backgroundColor: `${theme.custom.colors.gray} !important`
	},
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(180deg)'
	},
	'& .MuiAccordionSummary-content': {
		margin: 0
	}
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	backgroundColor: theme.custom.colors.kozmikGray,
	padding: 10,
	border: `1px solid ${theme.custom.colors.gray}`,
	borderRadius: '0 0 4px 4px'
}))

const useStyles = makeStyles((theme) => ({
	title: {
		paddingLeft: (props) => (props?.moreClick ? 5 : 15),
		fontSize: 14,
		color: theme.custom.colors.textPrimary,
		fontWeight: '600'
	},
	detailBox: {
		padding: (props) =>
			props?.padding ? `${props.padding}px !important` : '10px !important'
	},
	moreIcon: {
		color: theme.custom.colors.textPrimary,
		cursor: 'pointer'
	}
}))

const AccordionItem = (props) => {
	const classes = useStyles({
		padding: props?.padding,
		moreClick: props?.moreClick
	})

	const [expanded, setExpanded] = useState(false)

	const handleChange = () => (event, newExpanded) => {
		setExpanded(newExpanded ? true : false)
	}

	useEffect(() => {
		if (typeof props?.expanded == 'boolean') {
			setExpanded(props.expanded)
		}
	}, [props.expanded])

	return (
		<AccordionBox
			expanded={expanded}
			onChange={props?.onChange ? props?.onChange() : handleChange()}
		>
			<AccordionSummary icon={props?.icon} bgColor={props?.bgColor}>
				{!!props?.moreClick && (
					<MoreVert
						className={classes.moreIcon}
						onClick={(e) => {
							e.stopPropagation()
							props.moreClick(e)
						}}
					/>
				)}
				<Typography className={classes.title}>{props.title}</Typography>
			</AccordionSummary>
			<AccordionDetails className={classes.detailBox}>
				{props.children}
			</AccordionDetails>
		</AccordionBox>
	)
}

export default AccordionItem

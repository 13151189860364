import React, { useEffect, useState } from 'react'
import { Typography, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'

import KozmikModal from '../../molecules/kozmikModal/KozmikModal'

import TextSwitch from '../../atoms/textSwitch/TextSwitch'
import PriceCard from '../../molecules/priceCard/PriceCard'
import { ReactComponent as Arrow } from '../../../assets/icons/arrow.svg'

const useStyles = makeStyles((theme) => ({
	modalContainer: {
		backgroundColor: theme.custom.colors.kozmikGray,
		height: '100%',
		padding: '75px 81px',
		overflowY: 'auto',
		[theme.breakpoints.down('lg')]: {
			padding: '30px 20px'
		}
	},
	flexContainer: {
		marginTop: 10,
		display: 'flex',
		justifyContent: 'center',
		position: 'relative',
		[theme.breakpoints.down('md')]: {
			marginTop: 34
		}
	},
	switchContainer: {
		position: 'relative'
	},
	arrowContainer: {
		position: 'absolute',
		right: -65,
		top: -20,
		width: 56,
		[theme.breakpoints.down('md')]: {
			right: 0,
			top: -44,
			width: 80
		}
	},
	arrow: {
		height: 48,
		width: 'auto',
		transform: 'rotate(90deg)',
		[theme.breakpoints.down('md')]: {
			transform: 'rotate(65deg)'
		}
	},
	arrowText: {
		color: theme.custom.colors.kozmikDarkBlue,
		textAlign: 'center',
		fontSize: 12,
		fontWeight: '600',
		position: 'absolute',
		top: 42,
		left: 0,
		[theme.breakpoints.down('md')]: {
			top: 29,
			left: 24
		}
	},
	cardContainer: {
		marginTop: 20
	},
	title: {
		display: 'block',
		fontSize: 21,
		fontWeight: 'bold',
		width: '100%'
	}
}))

const SubscriptionModal = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [isYearPrices, setIsYearPrices] = useState(true)
	const [highlightedPackage, setHighlightedPackage] = useState('pro')

	const hideModal = () => {
		props.setModalVisible(false)
	}

	const localizeFeatures = {
		oneApp: t('subscriptionModal.features.oneApp'),
		twoApp: t('subscriptionModal.features.twoApp'),
		threeApp: t('subscriptionModal.features.threeApp'),
		fourApp: t('subscriptionModal.features.fourApp'),
		fiveApp: t('subscriptionModal.features.fiveApp'),
		freeUsers: t('subscriptionModal.features.freeUsers'),
		starterUsers: t('subscriptionModal.features.starterUsers'),
		proUsers: t('subscriptionModal.features.proUsers'),
		businessUsers: t('subscriptionModal.features.businessUsers'),
		kozmikBranding: t('subscriptionModal.features.kozmikBranding'),
		customDomain: t('subscriptionModal.features.customDomain'),
		pwa: t('subscriptionModal.features.pwa'),
		stores: t('subscriptionModal.features.stores'),
		tablesAPI: t('subscriptionModal.features.tablesAPI'),
		notificationAPI: t('subscriptionModal.features.notificationAPI'),
		connectExternal: t('subscriptionModal.features.connectExternal'),
		twoCollaborators: t('subscriptionModal.features.twoCollaborators'),
		fiveCollaborators: t('subscriptionModal.features.fiveCollaborators'),
		tenCollaborators: t('subscriptionModal.features.tenCollaborators')
	}

	const freeFeatures = [
		localizeFeatures.oneApp,
		localizeFeatures.freeUsers,
		localizeFeatures.kozmikBranding,
		localizeFeatures.pwa
	]
	const starterFeatures = [
		localizeFeatures.twoApp,
		localizeFeatures.starterUsers,
		localizeFeatures.customDomain,
		localizeFeatures.pwa
	]
	const proFeatures = [
		localizeFeatures.twoApp,
		localizeFeatures.proUsers,
		localizeFeatures.customDomain,
		localizeFeatures.pwa,
		localizeFeatures.stores
	]
	const businessFeatures = [
		localizeFeatures.fiveApp,
		localizeFeatures.businessUsers,
		localizeFeatures.customDomain,
		localizeFeatures.pwa,
		localizeFeatures.stores,
		localizeFeatures.connectExternal
	]

	const data = {
		month: [
			{
				plan: 'free',
				title: t('subscriptionModal.free'),
				price: t('subscriptionModal.prices.free.month'),
				text: t('subscriptionModal.perMonth'),
				features: freeFeatures
			},
			{
				plan: 'starter',
				title: t('subscriptionModal.starter'),
				price: t('subscriptionModal.prices.starter.month'),
				text: t('subscriptionModal.perMonth'),
				features: starterFeatures
			},
			{
				plan: 'pro',
				title: t('subscriptionModal.pro'),
				price: t('subscriptionModal.prices.pro.month'),
				text: t('subscriptionModal.perMonth'),
				features: proFeatures
			},
			{
				plan: 'business',
				title: t('subscriptionModal.business'),
				price: t('subscriptionModal.prices.business.month'),
				text: t('subscriptionModal.perMonth'),
				features: businessFeatures
			}
		],
		year: [
			{
				plan: 'yearly_free',
				title: t('subscriptionModal.free'),
				price: t('subscriptionModal.prices.free.year'),
				text: t('subscriptionModal.perYear'),
				features: freeFeatures
			},
			{
				plan: 'yearly_starter',
				title: t('subscriptionModal.starter'),
				price: t('subscriptionModal.prices.starter.year'),
				text: t('subscriptionModal.perYear'),
				features: starterFeatures
			},
			{
				plan: 'yearly_pro',
				title: t('subscriptionModal.pro'),
				price: t('subscriptionModal.prices.pro.year'),
				text: t('subscriptionModal.perYear'),
				features: proFeatures
			},
			{
				plan: 'yearly_business',
				title: t('subscriptionModal.business'),
				price: t('subscriptionModal.prices.business.year'),
				text: t('subscriptionModal.perYear'),
				features: businessFeatures
			}
		]
	}

	useEffect(() => {
		const plan = props.currentOrg?.plan?.toLocaleLowerCase()
		const yearly = plan?.indexOf('yearly') > -1 ? true : false

		setIsYearPrices(yearly)

		if (yearly) {
			setHighlightedPackage(
				plan?.indexOf('business') > -1 ? 'yearly_business' : 'yearly_pro'
			)
		} else {
			setHighlightedPackage(plan?.indexOf('business') > -1 ? 'business' : 'pro')
		}
	}, [props.currentOrg])
	return (
		<KozmikModal lg visible={props.visible} hideModal={hideModal}>
			<Box className={classes.modalContainer}>
				<Typography className={classes.title}>
					{t('subscriptionModal.title')}
				</Typography>
				<div className={classes.flexContainer}>
					<div className={classes.switchContainer}>
						<TextSwitch
							onColor='#fff'
							isOn={isYearPrices}
							handleToggle={() => setIsYearPrices(!isYearPrices)}
							offText={t('subscriptionModal.monthly')}
							onText={t('subscriptionModal.yearly')}
						/>
						<div className={classes.arrowContainer}>
							<div style={{ position: 'relative' }}>
								<Arrow className={classes.arrow} />
								<span className={classes.arrowText}>
									{t('subscriptionModal.discountText')}
								</span>
							</div>
						</div>
					</div>
				</div>
				<Grid
					container
					spacing={2}
					className={classes.cardContainer}
					justifyContent='center'
				>
					{data?.[isYearPrices ? 'year' : 'month']?.map((item, index) => (
						<Grid key={index} item xs={12} sm={6} md={3}>
							<PriceCard
								title={item.title}
								price={item.price}
								text={item.text}
								features={item.features}
								highlighted={item.plan === highlightedPackage}
								current={item.plan == props.currentOrg?.plan}
								btnText={t('subscriptionModal.selectPlan')}
								plan={item.plan}
								btnCurrentText={t('subscriptionModal.current')}
							/>
						</Grid>
					))}
				</Grid>
			</Box>
		</KozmikModal>
	)
}

const mapStateToProps = (state) => ({
	...state.userInfo
})

export default connect(mapStateToProps, null)(SubscriptionModal)

const getValueType = (value, type) => {
	if (type == 'Data') {
		return 'Data'
	} else if (type == 'Relation') {
		return 'Relation'
	} else {
		const variables = [
			'{{profile.id}}',
			'{{profile.email}}',
			'{{profile.name}}',
			'{{profile.photo}}',
			'{{datetime.year}}',
			'{{datetime.month}}',
			'{{datetime.date}}',
			'{{datetime.time}}'
		]

		if (value?.startsWith('Screen')) {
			return 'Screen'
		} else if (value?.startsWith('Form')) {
			return 'Form'
		} else if (value?.startsWith('Action')) {
			return 'Action'
		} else if (variables.includes(value)) {
			return 'Variables'
		} else {
			return 'Field'
		}
	}
}

export default getValueType

import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import IoniconsSelect from '../../../molecules/IoniconsSelect/IoniconsSelect'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import { default as Slider } from '../../../atoms/kozmikSlider/KozmikSlider'

import { ReactComponent as Koseli } from '../../../../assets/icons/Settings/Koseli.svg'
import { ReactComponent as Oval } from '../../../../assets/icons/Settings/Oval.svg'
import { ReactComponent as Daire } from '../../../../assets/icons/Settings/KaroTip-Daire.svg'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	mb15: {
		marginBottom: 15
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const UserInfo = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const cornerOptions = [
		{
			text: t('screens.elementSettings.userInfo.cornerOpts.square'),
			value: 'square',
			icon: Koseli
		},
		{
			text: t('screens.elementSettings.userInfo.cornerOpts.oval'),
			value: 'oval',
			icon: Oval
		},
		{
			text: t('screens.elementSettings.userInfo.cornerOpts.circle'),
			value: 'circle',
			icon: Daire
		}
	]

	/****MAPPING_KEY***************************/
	const [mappingKey, setMappingKey] = useState('')

	const userInfoFields = [
		{
			label: 'Name',
			value: 'name'
		},
		{
			label: 'Email',
			value: 'email'
		}
	]

	const mappingKeyChange = (e) => {
		submitMappingKey(e.target.value)
		setMappingKey(e.target.value)
	}

	const submitMappingKey = (x) => {
		const modifiedElementData = update(props.elementData, {
			mapping: {
				text: {
					field: { $set: x }
					// type: { $set: null }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ICON***************************/
	const [icon, setIcon] = useState('')

	const iconChange = (val) => {
		submitIcon(val)
		setIcon(val)
	}

	const submitIcon = (x) => {
		const modifiedElementData = update(props.elementData, {
			icon: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****CORNER***************************/
	const [corner, setCorner] = useState(null)

	const cornerChange = (val) => {
		submitCorner(val)
		setCorner(val)
	}

	const submitCorner = (x) => {
		const modifiedElementData = update(props.elementData, {
			corner: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****HEIGHT***************************/
	const [height, setHeight] = useState(null)

	const heightChange = (e, newVal) => {
		if (typeof newVal === 'number') {
			submitHeight(newVal)
			setHeight(newVal)
		}
	}

	const submitHeight = (x) => {
		const modifiedElementData = update(props.elementData, {
			height: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****MAPPING_KEY***************************/
		setMappingKey(elementData?.mapping?.text?.field || '')
		/****ICON***************************/
		setIcon(elementData?.icon || '')
		/****CORNER***************************/
		setCorner(elementData?.corner || null)
		/****HEIGHT***************************/
		setHeight(elementData?.height || null)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
		}
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.userInfo.title')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								// emptyText='Empty Field'
								className={cx(classes.input, classes.inputSize)}
								onChange={mappingKeyChange}
								value={mappingKey}
								data={userInfoFields}
								textKey='label'
								valueKey='value'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid
						container
						alignItems='center'
						justifyContent='space-between'
						className={classes.mb10}
					>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.userInfo.icon')}</Label>
						</Grid>
						<Grid item xs={4}>
							<IoniconsSelect
								onChange={iconChange}
								value={icon}
								emptySelectable
								fullWidth
							/>
						</Grid>
					</Grid>
					{icon && (
						<>
							<Grid container alignItems='center' className={classes.mb10}>
								<Grid item xs={4}>
									<Label>{t('screens.elementSettings.userInfo.size')}</Label>
								</Grid>
								<Grid
									item
									style={{
										display: 'flex',
										alignItems: 'center'
									}}
									xs={12}
									lg={8}
								>
									<Slider
										value={height}
										min={48}
										step={10}
										max={78}
										onChange={heightChange}
										aria-labelledby='non-linear-slider'
									/>
								</Grid>
							</Grid>
							<Grid container alignItems='center' className={classes.mb10}>
								<Grid item xs={4}>
									<Label>{t('screens.elementSettings.userInfo.corners')}</Label>
								</Grid>
								<Grid item xs={8}>
									<SquareOptions
										compact
										data={cornerOptions}
										getChange={cornerChange}
										activeValue={corner}
									/>
								</Grid>
							</Grid>
						</>
					)}
				</Accordion>
			</AppPageBox>
		</>
	)
}

export default UserInfo

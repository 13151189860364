import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateText from '../../../molecules/doubleStateField/DoubleStateText'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import { default as Checkbox } from '../../../atoms/kozmikCheckBox/KozmikCheckBox'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'
import IoniconsSelect from '../../../molecules/IoniconsSelect/IoniconsSelect'

import validateWithRegExp from '../../../../methods/validateWithRegExp'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import dataTypeRegExes from '../../../../common/regExp/dataType'
import relationTypes from '../../../../common/enums/relationTypes'

import { ReactComponent as ImageSquare } from '../../../../assets/icons/Settings/Square.svg'
import { ReactComponent as ImageRectangle } from '../../../../assets/icons/Settings/Rectangle.svg'
import { ReactComponent as ImageFull } from '../../../../assets/icons/Settings/Full.svg'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const Map = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [elementName, setElementName] = useState(null)

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen?.data?.source,
		fieldDataTypes.textBased,
		null,
		true
		// relationTypes.one
	)
	const textOptions = textFields?.length ? textFields : []
	const textOptionsWithEmpty = [{ label: '-', name: '' }, ...textOptions]

	const imageFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen?.data?.source,
		['Image'],
		null,
		true,
		relationTypes.one
	)
	const imageOptions = imageFields?.length ? imageFields : []
	const imageOptionsWithEmpty = [{ label: '-', name: '' }, ...imageOptions]

	const geoLocationFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen?.data?.source,
		['GeoLocation', 'Text'],
		null,
		true,
		relationTypes.one
	)
	const geoLocationOptions = geoLocationFields?.length ? geoLocationFields : []
	const geoLocationOptionsWithEmpty = [
		{ label: '-', name: '' },
		...geoLocationOptions
	]

	const appearanceOptions = [
		{
			text: t('screens.elementSettings.map.appearOpts.full'),
			value: 'full',
			icon: ImageFull
		},
		{
			text: t('screens.elementSettings.map.appearOpts.square'),
			value: 'square',
			icon: ImageSquare
		},
		{
			text: t('screens.elementSettings.map.appearOpts.rectangle'),
			value: 'rectangle',
			icon: ImageRectangle
		}
	]

	const mapTypeOptions = [
		{
			label: t('screens.elementSettings.map.typeOpts.roadmap'),
			name: 'roadmap'
		},
		{
			label: t('screens.elementSettings.map.typeOpts.satellite'),
			name: 'satellite'
		},
		{
			label: t('screens.elementSettings.map.typeOpts.hybrid'),
			name: 'hybrid'
		}
	]

	/****TITLE***************************/
	const [isTitleTextMode, setIsTitleTextMode] = useState(false)
	const [titleField, setTitleField] = useState('')
	const [titleDefault, setTitleDefault] = useState('')

	const titleChange = (data) => {
		if (isTitleTextMode) {
			timeoutDelay(submitTitle, data.target.value, 1000)
			setTitleDefault(data.target.value)
		} else {
			submitTitle(data?.value)
			setTitleField(data?.value)
		}
	}
	const submitTitle = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				title: {
					field: { $set: isTitleTextMode ? null : x },
					default: { $set: isTitleTextMode ? x : null },
					type: { $set: isTitleTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****TEXT***************************/
	const [isTextTextMode, setIsTextTextMode] = useState(false)
	const [textField, setTextField] = useState('')
	const [textDefault, setTextDefault] = useState('')

	const textChange = (data) => {
		if (isTextTextMode) {
			timeoutDelay(submitText, data.target.value, 1000)
			setTextDefault(data.target.value)
		} else {
			submitText(data?.value)
			setTextField(data?.value)
		}
	}
	const submitText = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				text: {
					field: { $set: isTextTextMode ? null : x },
					default: { $set: isTextTextMode ? x : null },
					type: { $set: isTextTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****SUBTEXT***************************/
	const [isSubTextTextMode, setIsSubTextTextMode] = useState(false)
	const [subTextField, setSubTextField] = useState('')
	const [subTextDefault, setSubTextDefault] = useState('')

	const subTextChange = (data) => {
		if (isSubTextTextMode) {
			timeoutDelay(submitSubText, data.target.value, 1000)
			setSubTextDefault(data.target.value)
		} else {
			submitSubText(data?.value)
			setSubTextField(data?.value)
		}
	}
	const submitSubText = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				subText: {
					field: { $set: isSubTextTextMode ? null : x },
					default: { $set: isSubTextTextMode ? x : null },
					type: { $set: isSubTextTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****MARKER_TEXT***************************/
	const [isMarkerTextTextMode, setIsMarkerTextTextMode] = useState(false)
	const [markerTextField, setMarkerTextField] = useState('')
	const [markerTextDefault, setMarkerTextDefault] = useState('')

	const markerTextChange = (data) => {
		if (isMarkerTextTextMode) {
			timeoutDelay(submitMarkerText, data.target.value, 1000)
			setMarkerTextDefault(data.target.value)
		} else {
			submitMarkerText(data?.value)
			setMarkerTextField(data?.value)
		}
	}
	const submitMarkerText = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				markerText: {
					field: { $set: isMarkerTextTextMode ? null : x },
					default: { $set: isMarkerTextTextMode ? x : null },
					type: { $set: isMarkerTextTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****MARKER_ICON***************************/
	const [markerIcon, setMarkerIcon] = useState('')
	const markerIconChange = (val) => {
		setMarkerIcon(val)

		const modifiedElementData = update(props.elementData, {
			markerIcon: { $set: val }
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****IMGURL***************************/
	const [isImgUrlTextMode, setIsImgUrlTextMode] = useState(false)
	const [imgUrlField, setImgUrlField] = useState('')
	const [imgUrlDefault, setImgUrlDefault] = useState('')
	const [imgUrlDefaultValid, setImgUrlDefaultValid] = useState(true)

	const imgUrlChange = (data) => {
		if (isImgUrlTextMode) {
			const imgUrlRegExp = dataTypeRegExes.Image.re

			validateWithRegExp(data.target.value, imgUrlRegExp).then((isValid) => {
				setImgUrlDefaultValid(isValid)
				if (isValid) {
					timeoutDelay(submitImgUrl, data.target.value, 1000)
				}
			})

			setImgUrlDefault(data.target.value)
		} else {
			submitImgUrl(data?.value)
			setImgUrlField(data?.value)
		}
	}
	const submitImgUrl = (x) => {
		const selectedField = imageFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				imgUrl: {
					field: { $set: isImgUrlTextMode ? null : x },
					default: { $set: isImgUrlTextMode ? x : null },
					type: { $set: isImgUrlTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****GEOLOCATION***************************/
	const [isGeoLocationTextMode, setIsGeoLocationTextMode] = useState(false)
	const [geoLocationField, setGeoLocationField] = useState('')
	const [geoLocationDefault, setGeoLocationDefault] = useState('')
	const [geoLocationDefaultValid, setGeoLocationDefaultValid] = useState(true)

	const geoLocationChange = (data) => {
		if (isGeoLocationTextMode) {
			const geoLocationRegExp = dataTypeRegExes.GeoLocation.re

			validateWithRegExp(data.target.value, geoLocationRegExp).then(
				(isValid) => {
					setGeoLocationDefaultValid(isValid)
					if (isValid) {
						timeoutDelay(submitGeoLocation, data.target.value, 1000)
					}
				}
			)

			setGeoLocationDefault(data.target.value)
		} else {
			submitGeoLocation(data?.value)
			setGeoLocationField(data?.value)
		}
	}
	const submitGeoLocation = (x) => {
		const selectedField = geoLocationFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				geoLocation: {
					field: { $set: isGeoLocationTextMode ? null : x },
					default: { $set: isGeoLocationTextMode ? x : null },
					type: { $set: isGeoLocationTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ZOOM***************************/
	const [zoom, setZoom] = useState('')

	const zoomOptions = [
		{
			label: t('screens.elementSettings.map.zoomOpts.1x'),
			value: '1x'
		},
		{
			label: t('screens.elementSettings.map.zoomOpts.2x'),
			value: '2x'
		},
		{
			label: t('screens.elementSettings.map.zoomOpts.3x'),
			value: '3x'
		}
	]

	const zoomChange = (e) => {
		submitZoom(e.target.value)
		setZoom(e.target.value)
	}

	const submitZoom = (x) => {
		const modifiedElementData = update(props.elementData, {
			zoom: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****APPEARANCE***************************/
	const [appearance, setAppearance] = useState(null)

	const appearanceChange = (val) => {
		submitAppearance(val)
		setAppearance(val)
	}

	const submitAppearance = (x) => {
		const modifiedElementData = update(props.elementData, {
			appearance: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****APPEARANCE***************************/
	const [mapType, setMapType] = useState(null)

	const mapTypeChange = (e) => {
		submitMapType(e.target.value)
		setMapType(e.target.value)
	}

	const submitMapType = (x) => {
		const modifiedElementData = update(props.elementData, {
			mapType: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ZOOM_CONTROL***************************/
	const [zoomControl, setZoomControl] = useState(false)

	const zoomControlChange = (e) => {
		setZoomControl(e.target.checked)
		submitZoomControl(e.target.checked)
	}

	const submitZoomControl = (x) => {
		const modifiedElementData = update(props.elementData, {
			zoomControl: { $set: x }
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****SCROLLWHEEL***************************/
	const [scrollwheel, setScrollwheel] = useState(true)

	const scrollwheelChange = (e) => {
		setScrollwheel(e.target.checked)
		submitScrollwheel(e.target.checked)
	}

	const submitScrollwheel = (x) => {
		const modifiedElementData = update(props.elementData, {
			scrollwheel: { $set: x }
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****TITLE***************************/
		elementData?.mapping?.title?.field ||
		elementData?.mapping?.title?.field === ''
			? setIsTitleTextMode(false)
			: setIsTitleTextMode(true)

		setTitleField(elementData?.mapping?.title?.field || '')
		setTitleDefault(elementData?.mapping?.title?.default || '')
		/****TEXT***************************/
		elementData?.mapping?.text?.field ||
		elementData?.mapping?.text?.field === ''
			? setIsTextTextMode(false)
			: setIsTextTextMode(true)

		setTextField(elementData?.mapping?.text?.field || '')
		setTextDefault(elementData?.mapping?.text?.default || '')
		/****SUBTEXT***************************/
		elementData?.mapping?.subText?.field ||
		elementData?.mapping?.subText?.field === ''
			? setIsSubTextTextMode(false)
			: setIsSubTextTextMode(true)

		setSubTextField(elementData?.mapping?.subText?.field || '')
		setSubTextDefault(elementData?.mapping?.subText?.default || '')
		/****MARKER_TEXT***************************/
		elementData?.mapping?.markerText?.field ||
		elementData?.mapping?.markerText?.field === ''
			? setIsMarkerTextTextMode(false)
			: setIsMarkerTextTextMode(true)

		setMarkerTextField(elementData?.mapping?.markerText?.field || '')
		setMarkerTextDefault(elementData?.mapping?.markerText?.default || '')
		/****IMGURL***************************/
		elementData?.mapping?.imgUrl?.field ||
		elementData?.mapping?.imgUrl?.field === ''
			? setIsImgUrlTextMode(false)
			: setIsImgUrlTextMode(true)

		setImgUrlField(elementData?.mapping?.imgUrl?.field || '')
		setImgUrlDefault(elementData?.mapping?.imgUrl?.default || '')
		/****GEOLOCATION***************************/
		elementData?.mapping?.geoLocation?.field ||
		elementData?.mapping?.geoLocation?.field === ''
			? setIsGeoLocationTextMode(false)
			: setIsGeoLocationTextMode(true)

		setGeoLocationField(elementData?.mapping?.geoLocation?.field || '')
		setGeoLocationDefault(elementData?.mapping?.geoLocation?.default || '')
		/****APPEARANCE***************************/
		setAppearance(elementData?.appearance)
		/****ZOOM***************************/
		setZoom(elementData?.zoom)
		/****MAP_TYPE***************************/
		setMapType(elementData?.mapType)
		/****ZOOM_CONTROL***************************/
		setZoomControl(elementData?.zoomControl)
		/****SCROLL_WHEEL***************************/
		setScrollwheel(elementData?.scrollwheel)
		/****MARKER_ICON***************************/
		setMarkerIcon(elementData?.markerIcon)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		props?.elementData && initStates(props.elementData)
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.map.geoLoc')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateText
								name={`${elementName}-geoLocation`}
								fullWidth={true}
								textError={!geoLocationDefaultValid}
								isTextMode={isGeoLocationTextMode}
								setIsTextMode={setIsGeoLocationTextMode}
								valChange={geoLocationChange}
								textVal={geoLocationDefault}
								autoCompVal={geoLocationField}
								autoCompOptions={geoLocationOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.map.markerText')}</Label>
						</Grid>

						<Grid container xs={8}>
							<Grid item xs={9} lg={9} style={{ paddingRight: 10 }}>
								<DoubleStateText
									name={`${elementName}-markerText`}
									fullWidth={true}
									isTextMode={isMarkerTextTextMode}
									setIsTextMode={setIsMarkerTextTextMode}
									valChange={markerTextChange}
									textVal={markerTextDefault}
									autoCompVal={markerTextField}
									autoCompOptions={textOptionsWithEmpty}
									valueKey='name'
									labelKey='label'
								/>
							</Grid>
							<Grid item xs={3} lg={3}>
								<IoniconsSelect
									fullWidth
									onChange={markerIconChange}
									value={markerIcon}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.map.title')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateText
								name={`${elementName}-title`}
								fullWidth={true}
								isTextMode={isTitleTextMode}
								setIsTextMode={setIsTitleTextMode}
								valChange={titleChange}
								textVal={titleDefault}
								autoCompVal={titleField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.map.desc')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateText
								name={`${elementName}-text`}
								fullWidth={true}
								isTextMode={isTextTextMode}
								setIsTextMode={setIsTextTextMode}
								valChange={textChange}
								textVal={textDefault}
								autoCompVal={textField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.map.subTitle')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateText
								name={`${elementName}-subText`}
								fullWidth={true}
								isTextMode={isSubTextTextMode}
								setIsTextMode={setIsSubTextTextMode}
								valChange={subTextChange}
								textVal={subTextDefault}
								autoCompVal={subTextField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.map.image')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateText
								name={`${elementName}-imgUrl`}
								fullWidth={true}
								textError={!imgUrlDefaultValid}
								isTextMode={isImgUrlTextMode}
								setIsTextMode={setIsImgUrlTextMode}
								valChange={imgUrlChange}
								textVal={imgUrlDefault}
								autoCompVal={imgUrlField}
								autoCompOptions={imageOptionsWithEmpty}
								uppyPicker
								uppyAllowedFileTypes={['image/*']}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.map.size')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={appearanceOptions}
								getChange={appearanceChange}
								activeValue={appearance}
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.map.zoom')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								className={classes.input}
								onChange={zoomChange}
								value={zoom}
								data={zoomOptions}
								textKey='label'
								valueKey='value'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.map.mapType')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								className={classes.input}
								onChange={mapTypeChange}
								value={mapType}
								data={mapTypeOptions}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid item className={classes.mb10}>
						<div className={cx(classes.item)}>
							<div>
								<Checkbox checked={zoomControl} onChange={zoomControlChange} />
							</div>
							<div className={cx(classes.labelBox, classes.growBox)}>
								<Label>{t('screens.elementSettings.map.zoomControl')}</Label>
							</div>
						</div>
					</Grid>
					<Grid item>
						<div className={cx(classes.item)}>
							<div>
								<Checkbox checked={scrollwheel} onChange={scrollwheelChange} />
							</div>
							<div className={cx(classes.labelBox, classes.growBox)}>
								<Label>{t('screens.elementSettings.map.scrollwheel')}</Label>
							</div>
						</div>
					</Grid>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default Map

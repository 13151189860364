import getEntityFieldsByDataTypes from '../getEntityFieldsByDataTypes'
import relationTypes from '../../common/enums/relationTypes'
import defaultElementSchemas from '../../defaultElementSchemas'
import fieldDataTypes from '../../common/fieldDataTypes'

const getNewDetailBody = (table) => {
	let detailBody = []

	const imgFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Image'],
		table,
		true,
		relationTypes.one
	)

	const txtFields = getEntityFieldsByDataTypes(
		null,
		null,
		fieldDataTypes.textBased,
		table,
		true,
		relationTypes.one
	)

	const emailFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Email'],
		table,
		true,
		relationTypes.one
	)

	const phoneFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Phone'],
		table,
		true,
		relationTypes.one
	)

	!!imgFields?.length && detailBody.push(defaultElementSchemas.titleCard(table))
	!!txtFields?.length && detailBody.push(defaultElementSchemas.textArea(table))
	!!emailFields?.length &&
		detailBody.push(defaultElementSchemas.emailButton(table))
	!!phoneFields?.length &&
		detailBody.push(defaultElementSchemas.phoneButton(table))

	return detailBody
}

export default getNewDetailBody

import React, { useState } from 'react'
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
} from 'react-router-dom'

import Apps from './atomic/pages/apps/Apps'
import Resources from './atomic/pages/resources/Resources'
import Team from './atomic/pages/team/Team'
import Login from './atomic/pages/auth/Login'
import Signup from './atomic/pages/auth/Signup'
import InvitationRegister from './atomic/pages/auth/InvitationRegister'
import AcceptInvitation from './atomic/pages/auth/AcceptInvitation'
import ForgotPassword from './atomic/pages/auth/ForgotPassword'
import ResetPassword from './atomic/pages/auth/ResetPassword'
import Account from './atomic/pages/account/Account'
import Subscription from './atomic/pages/subscription/Subscription'
import AppTheme from './atomic/pages/appTheme/AppTheme'
// import AppTypography from './atomic/pages/appTypography/AppTypography'
import AppInformation from './atomic/pages/appInformation/AppInformation'
import AppIntegrations from './atomic/pages/appIntegrations/AppIntegrations'
import SplashSettings from './atomic/pages/splashSettings/SplashSettings'
import CopyApp from './atomic/pages/copyApp/CopyApp'
import Onboarding from './atomic/pages/onboarding/Onboarding'
import ErrorPage from './atomic/pages/errorPage/ErrorPage'
import Screens from './atomic/pages/screens/Screens'

import MasterLayout from './atomic/themes/masterLayout/MasterLayout'
import AuthLayout from './atomic/themes/authLayout/AuthLayout'
import AppDetailLayout from './atomic/themes/appDetailLayout/AppDetailLayout'
import AppPreviewLayout from './atomic/themes/appPreviewLayout/AppPreviewLayout'
import withAuth from './hoc/withAuth'

import AppDataTemplate from './atomic/templates/appDataTemplate/AppDataTemplate'

import useMediaQuery from '@mui/material/useMediaQuery'

const AppsWA = withAuth(Apps)
const ResourcesWA = withAuth(Resources)
const TeamWA = withAuth(Team)
const AccountWA = withAuth(Account)
const SubscriptionWA = withAuth(Subscription)
const AppDetailLayoutWA = withAuth(AppDetailLayout)

const App = () => {
	const builderOnMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

	const [previewButtonIndex, setPreviewButtonIndex] = useState(0)
	const [activeMenuEvent, setActiveMenuEvent] = useState(null)
	const [previewButtonsVisible, setPreviewButtonsVisible] = useState(false)
	const [isFullPagePreview, setIsFullPagePreview] = useState(false)

	return (
		<Router>
			<Switch>
				<Route path='/app/:appId/:path'>
					<AppDetailLayoutWA
						previewButtonsVisible={previewButtonsVisible}
						previewButtonIndex={previewButtonIndex}
						setPreviewButtonIndex={setPreviewButtonIndex}
						isFullPagePreview={isFullPagePreview}
						activeMenuEvent={activeMenuEvent}
						setActiveMenuEvent={setActiveMenuEvent}
					>
						<Switch>
							<Route path='/app/:appId/settings/info' exact>
								<AppPreviewLayout
									builderOnMobile={builderOnMobile}
									isFullPagePreview={isFullPagePreview}
									setIsFullPagePreview={setIsFullPagePreview}
									previewButtonIndex={previewButtonIndex}
									setPreviewButtonsVisible={setPreviewButtonsVisible}
									settings
								>
									<AppInformation />
								</AppPreviewLayout>
							</Route>

							<Route path='/app/:appId/settings/theme' exact>
								<AppPreviewLayout
									builderOnMobile={builderOnMobile}
									isFullPagePreview={isFullPagePreview}
									setIsFullPagePreview={setIsFullPagePreview}
									previewButtonIndex={previewButtonIndex}
									setPreviewButtonsVisible={setPreviewButtonsVisible}
									settings
								>
									<AppTheme />
								</AppPreviewLayout>
							</Route>

							<Route path='/app/:appId/settings/splash' exact>
								<AppPreviewLayout
									builderOnMobile={builderOnMobile}
									isFullPagePreview={isFullPagePreview}
									setIsFullPagePreview={setIsFullPagePreview}
									previewButtonIndex={previewButtonIndex}
									setPreviewButtonsVisible={setPreviewButtonsVisible}
									settings
									splash
								>
									<SplashSettings />
								</AppPreviewLayout>
							</Route>

							<Route path='/app/:appId/screens' exact>
								<AppPreviewLayout
									builderOnMobile={builderOnMobile}
									isFullPagePreview={isFullPagePreview}
									setIsFullPagePreview={setIsFullPagePreview}
									previewButtonIndex={previewButtonIndex}
									setPreviewButtonsVisible={setPreviewButtonsVisible}
									screens
								>
									<Screens
										activeMenuEvent={activeMenuEvent}
										previewButtonIndex={previewButtonIndex}
									/>
								</AppPreviewLayout>
							</Route>

							<Route path='/app/:appId/data' exact>
								<AppPreviewLayout
									builderOnMobile={builderOnMobile}
									isFullPagePreview={isFullPagePreview}
									setIsFullPagePreview={setIsFullPagePreview}
									previewButtonIndex={previewButtonIndex}
									setPreviewButtonsVisible={setPreviewButtonsVisible}
									data
								>
									<AppDataTemplate />
								</AppPreviewLayout>
							</Route>

							<Route path='/app/:appId/integrations' exact>
								<AppPreviewLayout
									builderOnMobile={builderOnMobile}
									isFullPagePreview={isFullPagePreview}
									setIsFullPagePreview={setIsFullPagePreview}
									previewButtonIndex={previewButtonIndex}
									setPreviewButtonsVisible={setPreviewButtonsVisible}
									integrations
								>
									<AppIntegrations />
								</AppPreviewLayout>
							</Route>

							<Route path='*' render={() => <Redirect to='/error' />} />
						</Switch>
					</AppDetailLayoutWA>
				</Route>

				<Route>
					<Switch>
						<Route exact path='/'>
							<MasterLayout builderOnMobile={builderOnMobile}>
								<AppsWA />
							</MasterLayout>
						</Route>

						<Route path='/import/:shortName'>
							<CopyApp />
						</Route>

						<Route path='/onboarding'>
							<Onboarding />
						</Route>

						<Route path='/resources'>
							<MasterLayout builderOnMobile={builderOnMobile}>
								<ResourcesWA />
							</MasterLayout>
						</Route>

						<Route path='/team'>
							<MasterLayout builderOnMobile={builderOnMobile}>
								<TeamWA />
							</MasterLayout>
						</Route>

						<Route path='/account'>
							<MasterLayout builderOnMobile={builderOnMobile}>
								<AccountWA />
							</MasterLayout>
						</Route>

						<Route path='/subscription'>
							<MasterLayout builderOnMobile={builderOnMobile}>
								<SubscriptionWA />
							</MasterLayout>
						</Route>

						<Route path='/error'>
							<ErrorPage />
						</Route>

						<Route path='*'>
							<AuthLayout>
								<Switch>
									<Route path='/login'>
										<Login />
									</Route>
									<Route path='/signup'>
										<Signup />
									</Route>
									<Route path='/invitation'>
										<InvitationRegister />
									</Route>
									<Route path='/acceptinvitation'>
										<AcceptInvitation />
									</Route>
									<Route path='/forgotpassword'>
										<ForgotPassword />
									</Route>
									<Route path='/resetpassword'>
										<ResetPassword />
									</Route>
									<Route path='*' render={() => <Redirect to='/error' />} />
								</Switch>
							</AuthLayout>
						</Route>
					</Switch>
				</Route>
			</Switch>
		</Router>
	)
}

export default App

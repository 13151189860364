import { v4 as uuidv4 } from 'uuid'

import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import i18next from '../../i18n/i18n'
import getFirstFieldExcludingForbidArray from '../../methods/getFirstFieldExcludingForbidArray'
import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'

const method = (table) => {
	const numFields = getEntityFieldsByDataTypes(null, null, ['Number'], table)

	const firstNumField = getFirstFieldExcludingForbidArray(
		numFields,
		'name',
		systemFieldNames.notVisible
	)

	const object = {
		_uid: uuidv4(),
		component: 'numberEntry',
		mapping: {
			number: {
				field: firstNumField?.name,
				type: firstNumField?.dataType
			},
			title: {
				field: null,
				default: i18next.t('schemas.numberEntry.title'),
				type: null
			},
			min: {
				field: null,
				default: 0,
				type: null
			},
			max: {
				field: null,
				default: 100,
				type: null
			}
		},
		spacing: 'xl'
	}

	return object
}

export default method

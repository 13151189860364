import React from 'react'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import cx from 'classnames'
import { DeleteOutline } from '@mui/icons-material'

import ProfileCard from '../../molecules/profileCard/ProfileCard'
import SelectBox from '../../molecules/selectBox/SelectBox'

const useStyles = makeStyles((theme) => ({
	selectInput: {
		marginTop: 5,
		marginBottom: 5,
		marginLeft: '-10px',
		backgroundColor: theme.custom.colors.kozmikGray,
		borderRadius: 4,
		fontSize: '14px',
		color: theme.custom.colors.textPrimary,
		width: '75%',
		[theme.breakpoints.down('md')]: {
			width: 100
		},
		'& .MuiSelect-select': {
			padding: '8.5px 10px'
		}
	},
	tableBox: {
		width: '100%',
		maxHeight: '100%',
		overflowX: 'auto',
		overflowY: 'hidden',
		flexDirection: 'column',
		paddingBottom: 70
	},
	tableBodyContainer: {
		flexGrow: 1,
		width: 'fit-content',
		minWidth: '100%',
		maxHeight: '100%',
		overflowY: 'auto',
		overflowX: 'hidden'
	},
	tableHeadContainer: {
		width: 'fit-content',
		minWidth: '100%',
		borderBottom: `1px solid ${theme.custom.colors.gray}`,
		alignItems: 'flex-end !important',
		marginBottom: 15
	},
	tableHorizontalPadding: {
		padding: '0 10px'
	},
	tableRow: {
		display: 'flex',
		alignItems: 'center',
		minWidth: '100%',
		height: 50
	},
	hoverBox: {
		borderRadius: 4,
		'&:hover': {
			backgroundColor: theme.custom.colors.gray
		},
		'&:hover $deleteButton': {
			display: 'block'
		}
	},
	tableColumnTitle: {
		fontSize: 14,
		color: '#000000',
		fontWeight: '600',
		paddingBottom: '8px !important',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap'
	},
	tableColumnText: {
		fontSize: 14,
		color: theme.custom.colors.textPrimary,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap'
	},
	nameColumn: {
		minWidth: '30%',
		[theme.breakpoints.down('md')]: {
			minWidth: 220,
			width: 220
		},
		padding: '0 10px'
	},
	emailColumn: {
		minWidth: '30%',
		[theme.breakpoints.down('md')]: {
			minWidth: 220,
			width: 220
		},
		padding: '0 10px'
	},
	roleColumn: {
		minWidth: '17.5%',
		[theme.breakpoints.down('md')]: {
			minWidth: 140,
			width: 140
		},
		padding: '0 10px'
	},
	statusColumn: {
		minWidth: '17.5%',
		[theme.breakpoints.down('md')]: {
			minWidth: 100,
			width: 100
		},
		padding: '0 10px'
	},
	deleteColumn: {
		height: 20,
		minWidth: '5%',
		[theme.breakpoints.down('md')]: {
			minWidth: 50,
			width: 50
		},
		padding: '0 10px',
		display: 'flex',
		justifyContent: 'center'
	},
	deleteButton: {
		cursor: 'pointer',
		color: theme.custom.colors.textPrimary,
		display: 'none'
	}
}))

const TeamTable = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const currentUserEmail = props?.userInfo?.email

	return (
		<Box className={classes.tableBox}>
			<Box
				className={cx(
					classes.tableHeadContainer,
					classes.tableRow,
					classes.tableHorizontalPadding
				)}
			>
				<Typography
					className={cx(classes.tableColumnTitle, classes.nameColumn)}
				>
					{t('teamTable.name')}
				</Typography>
				<Typography
					className={cx(classes.tableColumnTitle, classes.emailColumn)}
				>
					{t('teamTable.email')}
				</Typography>
				<Typography
					className={cx(classes.tableColumnTitle, classes.roleColumn)}
				>
					{t('teamTable.role')}
				</Typography>
				<Typography
					className={cx(classes.tableColumnTitle, classes.statusColumn)}
				>
					{t('teamTable.status')}
				</Typography>
				<Box className={classes.deleteColumn}>{/**/}</Box>
			</Box>

			<Box className={classes.tableBodyContainer}>
				{props?.userData?.map((item, index) => (
					<Box key={index} className={classes.tableHorizontalPadding}>
						<Box className={cx(classes.tableRow, classes.hoverBox)}>
							<Box
								className={classes.nameColumn}
								style={{ display: 'flex', alignItems: 'center' }}
							>
								<ProfileCard text={item.Name} />

								<Typography
									className={classes.tableColumnText}
									style={{ marginLeft: 10 }}
								>
									{item.Name}
								</Typography>
							</Box>
							<Typography
								className={cx(classes.tableColumnText, classes.emailColumn)}
							>
								{item.Email}
							</Typography>
							{props?.permission && currentUserEmail != item.Email ? (
								<Box
									className={cx(classes.tableColumnText, classes.roleColumn)}
								>
									<SelectBox
										className={classes.selectInput}
										onChange={(e) => props.userGroupsChange(e, item.Id)}
										value={item.RoleType}
										data={props.roleOpts}
										textKey='label'
										valueKey='value'
									/>
								</Box>
							) : (
								<Typography
									className={cx(classes.tableColumnText, classes.roleColumn)}
								>
									{props.roleOpts?.find((x) => x.value == item.RoleType)?.label}
								</Typography>
							)}
							{props?.permission && currentUserEmail != item.Email ? (
								<Box
									className={cx(classes.tableColumnText, classes.statusColumn)}
								>
									<SelectBox
										className={classes.selectInput}
										onChange={(e) => props.statusChange(e, item.Id)}
										value={item.IsActive}
										data={props.statusOpts}
										textKey='label'
										valueKey='value'
									/>
								</Box>
							) : (
								<Typography
									className={cx(classes.tableColumnText, classes.statusColumn)}
								>
									{
										props.statusOpts?.find((x) => x.value == item.IsActive)
											?.label
									}
								</Typography>
							)}
							<Box className={classes.deleteColumn}>
								{!!props?.permission && currentUserEmail != item.Email && (
									<DeleteOutline
										className={classes.deleteButton}
										onClick={() => {
											props.setDeleteId(item.Id)
											props.setVisibleModal('delete')
										}}
									/>
								)}
							</Box>
						</Box>
					</Box>
				))}
			</Box>
		</Box>
	)
}

const mapStateToProps = (state) => ({
	...state.userInfo
})

export default connect(mapStateToProps, null)(TeamTable)

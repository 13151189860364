import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateText from '../../../molecules/doubleStateField/DoubleStateText'
import DoubleStateMentions from '../../../molecules/doubleStateField/DoubleStateMentions'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import IoniconsSelect from '../../../molecules/IoniconsSelect/IoniconsSelect'
import ActionList from '../../actionList/ActionList'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import relationTypes from '../../../../common/enums/relationTypes'

import { ReactComponent as ButonDolu } from '../../../../assets/icons/Settings/Buton-Filled.svg'
import { ReactComponent as ButonTransparan } from '../../../../assets/icons/Settings/Buton-Transparent.svg'
import { ReactComponent as ButonCerceveli } from '../../../../assets/icons/Settings/Buton-Framed.svg'
import { ReactComponent as ButonNone } from '../../../../assets/icons/Settings/Button-None.svg'
import { ReactComponent as Koseli } from '../../../../assets/icons/Settings/Koseli.svg'
import { ReactComponent as Oval } from '../../../../assets/icons/Settings/Oval.svg'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	}
}))

const ButtonBar = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const propsRef = useRef()
	propsRef.current = props

	const [elementName, setElementName] = useState(null)

	const textFieldsWithLookupAndRel = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		fieldDataTypes.textBased,
		null,
		true,
		relationTypes.one
	)
	const textOptions = textFieldsWithLookupAndRel?.length
		? textFieldsWithLookupAndRel
		: []
	const textOptionsWithEmpty = [{ label: '-', name: '' }, ...textOptions]

	const btnTypeOptions = [
		{
			text: t('screens.elementSettings.buttonBar.typeOpts.full'),
			value: 'full',
			icon: ButonDolu
		},
		{
			text: t('screens.elementSettings.buttonBar.typeOpts.transparent'),
			value: 'transparent',
			icon: ButonTransparan
		},
		{
			text: t('screens.elementSettings.buttonBar.typeOpts.border'),
			value: 'border',
			icon: ButonCerceveli
		},
		{
			text: t('screens.elementSettings.buttonBar.typeOpts.none'),
			value: 'none',
			icon: ButonNone
		}
	]

	const cornerOptions = [
		{
			text: t('screens.elementSettings.buttonBar.cornerOpts.square'),
			value: 'square',
			icon: Koseli
		},
		{
			text: t('screens.elementSettings.buttonBar.cornerOpts.oval'),
			value: 'oval',
			icon: Oval
		}
	]

	/****LEFT_TEXT***************************/
	const [isLeftTextTextMode, setIsLeftTextTextMode] = useState(false)
	const [leftTextField, setLeftTextField] = useState('')
	const [leftTextDefault, setLeftTextDefault] = useState('')

	const leftTextChange = (data) => {
		if (isLeftTextTextMode) {
			submitLeftText(data)
			setLeftTextDefault(data)
		} else {
			submitLeftText(data?.value)
			setLeftTextField(data?.value)
		}
	}
	const submitLeftText = (x) => {
		const selectedField = textFieldsWithLookupAndRel?.find((y) => y.name == x)
		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				leftText: {
					field: { $set: isLeftTextTextMode ? null : x },
					default: { $set: isLeftTextTextMode ? x : null },
					type: { $set: isLeftTextTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****RIGHT_TEXT***************************/
	const [isRightTextTextMode, setIsRightTextTextMode] = useState(false)
	const [rightTextField, setRightTextField] = useState('')
	const [rightTextDefault, setRightTextDefault] = useState('')

	const rightTextChange = (data) => {
		if (isRightTextTextMode) {
			submitRightText(data)
			setRightTextDefault(data)
		} else {
			submitRightText(data?.value)
			setRightTextField(data?.value)
		}
	}
	const submitRightText = (x) => {
		const selectedField = textFieldsWithLookupAndRel?.find((y) => y.name == x)
		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				rightText: {
					field: { $set: isRightTextTextMode ? null : x },
					default: { $set: isRightTextTextMode ? x : null },
					type: { $set: isRightTextTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****LEFT_ICON***************************/
	const [leftIcon, setLeftIcon] = useState('')

	const leftIconChange = (val) => {
		submitLeftIcon(val)
		setLeftIcon(val)
	}

	const submitLeftIcon = (x) => {
		const modifiedElementData = update(props.elementData, {
			left: {
				icon: { $set: x }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****RIGHT_ICON***************************/
	const [rightIcon, setRightIcon] = useState('')

	const rightIconChange = (val) => {
		submitRightIcon(val)
		setRightIcon(val)
	}

	const submitRightIcon = (x) => {
		const modifiedElementData = update(props.elementData, {
			right: {
				icon: { $set: x }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****LEFT_TYPE***************************/
	const [leftType, setLeftType] = useState(null)

	const leftTypeChange = (val) => {
		submitLeftType(val)
		setLeftType(val)
	}

	const submitLeftType = (x) => {
		const modifiedElementData = update(props.elementData, {
			left: {
				type: { $set: x }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****RIGHT_TYPE***************************/
	const [rightType, setRightType] = useState(null)

	const rightTypeChange = (val) => {
		submitRightType(val)
		setRightType(val)
	}

	const submitRightType = (x) => {
		const modifiedElementData = update(props.elementData, {
			right: {
				type: { $set: x }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****LEFT_SIZE***************************/
	const [leftSize, setLeftSize] = useState(null)

	const leftSizeChange = (val) => {
		submitLeftSize(val)
		setLeftSize(val)
	}

	const submitLeftSize = (x) => {
		const modifiedElementData = update(props.elementData, {
			left: { size: { $set: x } }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****RIGHT_SIZE***************************/
	const [rightSize, setRightSize] = useState(null)

	const rightSizeChange = (val) => {
		submitRightSize(val)
		setRightSize(val)
	}

	const submitRightSize = (x) => {
		const modifiedElementData = update(props.elementData, {
			right: { size: { $set: x } }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****LEFT_CORNER***************************/
	const [leftCorner, setLeftCorner] = useState(null)

	const leftCornerChange = (val) => {
		submitLeftCorner(val)
		setLeftCorner(val)
	}

	const submitLeftCorner = (x) => {
		const modifiedElementData = update(props.elementData, {
			left: {
				corner: { $set: x }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****RIGHT_CORNER***************************/
	const [rightCorner, setRightCorner] = useState(null)

	const rightCornerChange = (val) => {
		submitRightCorner(val)
		setRightCorner(val)
	}

	const submitRightCorner = (x) => {
		const modifiedElementData = update(props.elementData, {
			right: {
				corner: { $set: x }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ACTIONS***************************/
	const updateLeftActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			left: {
				actions: { $set: newActions }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}
	const updateRightActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			right: {
				actions: { $set: newActions }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****LEFT_TEXT***************************/
		elementData?.mapping?.leftText?.field ||
		elementData?.mapping?.leftText?.field === ''
			? setIsLeftTextTextMode(false)
			: setIsLeftTextTextMode(true)

		setLeftTextField(elementData?.mapping?.leftText?.field || '')
		setLeftTextDefault(elementData?.mapping?.leftText?.default || '')
		/****RIGHT_TEXT***************************/
		elementData?.mapping?.rightText?.field ||
		elementData?.mapping?.rightText?.field === ''
			? setIsRightTextTextMode(false)
			: setIsRightTextTextMode(true)

		setRightTextField(elementData?.mapping?.rightText?.field || '')
		setRightTextDefault(elementData?.mapping?.rightText?.default || '')
		/****LEFT_ICON***************************/
		setLeftIcon(elementData?.left?.icon || '')
		/****RIGHT_ICON***************************/
		setRightIcon(elementData?.right?.icon || '')
		/****LEFT_TYPE***************************/
		setLeftType(elementData?.left?.type)
		/****RIGHT_TYPE***************************/
		setRightType(elementData?.right?.type)
		/****LEFT_CORNER***************************/
		setLeftCorner(elementData?.left?.corner || null)
		/****RIGHT_CORNER***************************/
		setRightCorner(elementData?.right?.corner || null)
		/****LEFT_SIZE***************************/
		setLeftSize(elementData?.left?.size)
		/****RIGHT_SIZE***************************/
		setRightSize(elementData?.right?.size)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		props?.elementData && initStates(props.elementData)
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.buttonBar.left.settingsTitle')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>
								{t('screens.elementSettings.buttonBar.left.textLabel')}
							</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateMentions
								name={`${elementName}-left`}
								fullWidth={true}
								isTextMode={isLeftTextTextMode}
								setIsTextMode={setIsLeftTextTextMode}
								valChange={leftTextChange}
								multiline={false}
								textVal={leftTextDefault}
								autoCompVal={leftTextField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
					<Grid
						container
						alignItems='center'
						justifyContent='space-between'
						className={classes.mb10}
					>
						<Grid item xs={4}>
							<Label>
								{t('screens.elementSettings.buttonBar.left.iconLabel')}
							</Label>
						</Grid>
						<Grid item xs={4}>
							<IoniconsSelect
								onChange={leftIconChange}
								value={leftIcon}
								emptySelectable
								fullWidth
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>
								{t('screens.elementSettings.buttonBar.left.typeLabel')}
							</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={btnTypeOptions}
								getChange={leftTypeChange}
								activeValue={leftType}
							/>
						</Grid>
					</Grid>
					{leftType !== 'none' && (
						<Grid container alignItems='center' className={classes.mb10}>
							<Grid item xs={4}>
								<Label>
									{t('screens.elementSettings.buttonBar.left.cornerLabel')}
								</Label>
							</Grid>
							<Grid item xs={8}>
								<SquareOptions
									compact
									data={cornerOptions}
									getChange={leftCornerChange}
									activeValue={leftCorner}
								/>
							</Grid>
						</Grid>
					)}

					<Accordion
						title={t('screens.elementSettings.common.titles.actions')}
						expanded={true}
					>
						<ActionList
							name={`${elementName}-left`}
							entities={props.entities}
							activeScreen={props.activeScreen}
							actions={props.elementData.left.actions}
							updateActions={updateLeftActions}
						/>
					</Accordion>
				</Accordion>
			</AppPageBox>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.buttonBar.right.settingsTitle')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>
								{t('screens.elementSettings.buttonBar.right.textLabel')}
							</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateMentions
								name={`${elementName}-right`}
								fullWidth={true}
								isTextMode={isRightTextTextMode}
								setIsTextMode={setIsRightTextTextMode}
								valChange={rightTextChange}
								multiline={false}
								textVal={rightTextDefault}
								autoCompVal={rightTextField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
					<Grid
						container
						alignItems='center'
						justifyContent='space-between'
						className={classes.mb10}
					>
						<Grid item xs={4}>
							<Label>
								{t('screens.elementSettings.buttonBar.right.iconLabel')}
							</Label>
						</Grid>
						<Grid item xs={4}>
							<IoniconsSelect
								onChange={rightIconChange}
								value={rightIcon}
								emptySelectable
								fullWidth
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>
								{t('screens.elementSettings.buttonBar.right.typeLabel')}
							</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={btnTypeOptions}
								getChange={rightTypeChange}
								activeValue={rightType}
							/>
						</Grid>
					</Grid>

					{rightType !== 'none' && (
						<Grid container alignItems='center' className={classes.mb10}>
							<Grid item xs={4}>
								<Label>
									{t('screens.elementSettings.buttonBar.right.cornerLabel')}
								</Label>
							</Grid>
							<Grid item xs={8}>
								<SquareOptions
									compact
									data={cornerOptions}
									getChange={rightCornerChange}
									activeValue={rightCorner}
								/>
							</Grid>
						</Grid>
					)}

					<Accordion
						title={t('screens.elementSettings.common.titles.actions')}
						expanded={true}
					>
						<ActionList
							name={`${elementName}-right`}
							entities={props.entities}
							activeScreen={props.activeScreen}
							actions={props.elementData.right.actions}
							updateActions={updateRightActions}
						/>
					</Accordion>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default ButtonBar

import React from 'react'
import { styled } from '@mui/material/styles'
import Tab from '@mui/material/Tab'

const KozmikTab = styled((props) => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		padding: '0 10px',
		textTransform: 'none',
		fontSize: 14,
		marginRight: theme.spacing(1),
		backgroundColor: theme.custom.colors.gray,
		color: '#000000',
		borderRadius: '6px 6px 0 0',
		minHeight: 40,
		'&.Mui-selected': {
			border: 'none',
			color: '#1B36FE',
			backgroundColor: '#ffffff',
			borderColor: 'red',
			'& .MuiTab-iconWrapper': {
				visibility: 'visible'
			}
		},
		'&.Mui-focusVisible': {
			backgroundColor: 'rgba(100, 95, 228, 0.32)'
		},
		'& .MuiTab-iconWrapper': {
			visibility: 'hidden'
		},
		'&:hover': {
			'& .MuiTab-iconWrapper': {
				visibility: 'visible'
			}
		}
	})
)

export default KozmikTab

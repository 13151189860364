import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Grid from '@mui/material/Grid'

import TemplateImageItem from './TemplateImageItem'

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	tile: {
		padding: theme.spacing(3),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		minHeight: '100px'
	}
}))

const TemplateImageList = (props) => {
	const classes = useStyles()

	return (
		<Grid container spacing={1}>
			<Grid className={classes.tile} item xs={4}>
				<TemplateImageItem image={props.image1} setImage={props.setImage1} />
			</Grid>
			<Grid className={classes.tile} item xs={4}>
				<TemplateImageItem image={props.image2} setImage={props.setImage2} />
			</Grid>
			<Grid className={classes.tile} item xs={4}>
				<TemplateImageItem image={props.image3} setImage={props.setImage3} />
			</Grid>
		</Grid>
	)
}

export default TemplateImageList

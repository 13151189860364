import { ApiClient } from './client'

import { default as appConfig } from '../config/app.json'

const storeSettingDomain = `${appConfig.domains.platformDomain}/api/store/setting`

export default {
	async getSetting(appId, headers = {}) {
		headers['AppId'] = appId

		return await new ApiClient(storeSettingDomain, headers).get('')
	},
	async updateSetting(appId, data = null, headers = {}) {
		headers['Content-Type'] = 'application/json'
		headers['AppId'] = appId

		return await new ApiClient(storeSettingDomain, headers).post(
			'/update',
			data
		)
	}
}

import React from 'react'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: 14,
		color: theme.custom.colors.textPrimary,
		fontWeight: 'bold'
	},
	titleBox: {
		// paddingBottom: '10px',
		fontWeight: '600',
		fontSize: '30px'
	}
}))

const AppPageBoxTitle = (props) => {
	const classes = useStyles()
	return (
		<Grid item xs={12} className={classes.titleBox}>
			<Typography
				className={props.classList ? props.classList : classes.title}
				{...props}
			>
				{props.text}
			</Typography>
		</Grid>
	)
}

export default AppPageBoxTitle

import React from 'react'
import { AddBox } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import FilterListItem from '../filterListItem/FilterListItem'
import TextButton from '../../atoms/button/TextButton'

const FilterList = (props) => {
	const { t } = useTranslation()

	const ruleGroupOpOpts = [
		{
			text: t('screens.elementSettings.filter.list.opOpts.and'),
			value: 'AND'
		},
		{
			text: t('screens.elementSettings.filter.list.opOpts.or'),
			value: 'OR'
		}
	]

	const addNewRule = () => {
		if (props.index === undefined) {
			props.filtering.addNewRule(props.table)
		} else {
			props.filtering.addNewRule(
				props.table,
				props.index,
				props.feature === undefined ? null : props.feature
			)
		}
	}

	return props?.table ? (
		<>
			{!!props.filter?.rules?.length &&
				props.filter?.rules.map((item, index) => (
					<div key={`filter_${index}`}>
						<FilterListItem
							currentRule={item}
							ruleIndex={index}
							isLast={index == props.filter.rules.length - 1}
							filter={props.filter}
							table={props.table}
							activeScreen={props.activeScreen}
							entities={props.entities}
							filtering={props.filtering}
							setLocalElementData={props?.setLocalElementData}
							ruleGroupOpOpts={ruleGroupOpOpts}
							index={props.index}
							feature={props.feature}
							formSource={props?.formSource}
						/>
					</div>
				))}
			<TextButton
				onClick={addNewRule}
				icon={AddBox}
				text={
					props.addNewText || t('screens.elementSettings.filter.list.addFilter')
				}
			/>
		</>
	) : null
}

export default FilterList

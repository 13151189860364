import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateText from '../../../molecules/doubleStateField/DoubleStateText'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import { default as Checkbox } from '../../../atoms/kozmikCheckBox/KozmikCheckBox'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import validateWithRegExp from '../../../../methods/validateWithRegExp'
import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import dataTypeRegExes from '../../../../common/regExp/dataType'
import relationTypes from '../../../../common/enums/relationTypes'

import { ReactComponent as ImageSquare } from '../../../../assets/icons/Settings/Square.svg'
import { ReactComponent as ImageRectangle } from '../../../../assets/icons/Settings/Rectangle.svg'
import { ReactComponent as ImageFull } from '../../../../assets/icons/Settings/Full.svg'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const WebView = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const urlFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen?.data?.source,
		['URL', 'Text'],
		null,
		true,
		relationTypes.one
	)
	const urlOptions = urlFields?.length ? urlFields : []
	const urlOptionsWithEmpty = [{ label: '-', name: '' }, ...urlOptions]

	const appearanceOptions = [
		{
			text: t('screens.elementSettings.webView.appearOpts.full'),
			value: 'full',
			icon: ImageFull
		},
		{
			text: t('screens.elementSettings.webView.appearOpts.square'),
			value: 'square',
			icon: ImageSquare
		},
		{
			text: t('screens.elementSettings.webView.appearOpts.rectangle'),
			value: 'rectangle',
			icon: ImageRectangle
		}
	]

	/****URL***************************/
	const [isUrlTextMode, setIsUrlTextMode] = useState(false)
	const [urlField, setUrlField] = useState('')
	const [urlDefault, setUrlDefault] = useState('')
	const [urlDefaultValid, setUrlDefaultValid] = useState(true)

	const urlChange = (data) => {
		if (isUrlTextMode) {
			const urlRegExp = dataTypeRegExes.URL.re

			validateWithRegExp(data.target.value, urlRegExp).then((isValid) => {
				setUrlDefaultValid(isValid)
				if (isValid) {
					timeoutDelay(submitUrl, data.target.value, 1000)
				}
			})

			setUrlDefault(data.target.value)
		} else {
			submitUrl(data?.value)
			setUrlField(data?.value)
		}
	}
	const submitUrl = (x) => {
		const selectedField = urlFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				url: {
					field: { $set: isUrlTextMode ? null : x },
					default: { $set: isUrlTextMode ? x : null },
					type: { $set: isUrlTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****APPEARANCE***************************/
	const [appearance, setAppearance] = useState(null)

	const appearanceChange = (val) => {
		submitAppearance(val)
		setAppearance(val)
	}

	const submitAppearance = (x) => {
		const modifiedElementData = update(props.elementData, {
			appearance: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****SCROLLABLE***************************/
	const [scrollable, setScrollable] = useState(false)

	const scrollableChange = (e) => {
		setScrollable(e.target.checked)
		submitScrollable(e.target.checked)
	}

	const submitScrollable = (x) => {
		const modifiedElementData = update(props.elementData, {
			scrollable: { $set: x }
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****URL***************************/
		elementData?.mapping?.url?.field || elementData?.mapping?.url?.field === ''
			? setIsUrlTextMode(false)
			: setIsUrlTextMode(true)

		setUrlField(elementData?.mapping?.url?.field || '')
		setUrlDefault(elementData?.mapping?.url?.default || '')
		/****APPEARANCE***************************/
		setAppearance(elementData?.appearance)
		/****SCROLLABLE***************************/
		setScrollable(!!elementData?.scrollable)
	}

	useEffect(() => {
		props?.elementData && initStates(props.elementData)
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.webView.link')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateText
								fullWidth={true}
								textError={!urlDefaultValid}
								isTextMode={isUrlTextMode}
								setIsTextMode={setIsUrlTextMode}
								valChange={urlChange}
								textVal={urlDefault}
								autoCompVal={urlField}
								autoCompOptions={urlOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.webView.size')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={appearanceOptions}
								getChange={appearanceChange}
								activeValue={appearance}
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={8} lg={9}>
							<div className={cx(classes.item)}>
								<div>
									<Checkbox checked={scrollable} onChange={scrollableChange} />
								</div>
								<div className={cx(classes.labelBox, classes.growBox)}>
									<Label>
										{t('screens.elementSettings.webView.scrollable')}
									</Label>
								</div>
							</div>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default WebView

import update from 'immutability-helper'
import getModifiedScreensByElement from './getModifiedScreensByElement'

const fixDetailScreenActions = (
	screens,
	activeScreen,
	activeElement,
	element
) => {
	if (!screens || !activeScreen || !element) return screens

	const grouppedScreens = Object.values(screens)
	const flattenScreens = [].concat(...grouppedScreens)
	const detailScreen = flattenScreens.filter(
		(x) =>
			x.source == element.source && x.body.some((c) => c.component != 'form')
	)[0]

	element.actions?.map((action, index) => {
		if (!action) {
			element = update(element, {
				actions: {
					[index]: {
						$set: {
							type: 'navigate',
							screenKey: detailScreen?.key
						}
					}
				}
			})
		} else {
			if (action.type == null || action.type == 'navigate') {
				element = update(element, {
					actions: {
						[index]: {
							type: {
								$set: 'navigate'
							},
							screenKey: {
								$set: detailScreen?.key
							}
						}
					}
				})
			}
		}
	})

	return getModifiedScreensByElement(
		screens,
		activeScreen,
		activeElement,
		element
	)
}

export default fixDetailScreenActions

import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateText from '../../../molecules/doubleStateField/DoubleStateText'
import DoubleStateMentions from '../../../molecules/doubleStateField/DoubleStateMentions'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import validateWithRegExp from '../../../../methods/validateWithRegExp'
import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import dataTypeRegExes from '../../../../common/regExp/dataType'
import relationTypes from '../../../../common/enums/relationTypes'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	}
}))

const EmailButton = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const propsRef = useRef()
	propsRef.current = props

	const [elementName, setElementName] = useState(null)

	const emailFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		['Email'],
		null,
		true,
		relationTypes.one
	)
	const emailOptions = emailFields?.length ? emailFields : []
	const emailOptionsWithEmpty = [{ label: '-', name: '' }, ...emailOptions]

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		fieldDataTypes.textBased,
		null,
		true,
		relationTypes.one
	)
	const textOptions = textFields?.length ? textFields : []
	const textOptionsWithEmpty = [{ label: '-', name: '' }, ...textOptions]

	/****EMAIL***************************/
	const [isMailTextMode, setIsMailTextMode] = useState(false)
	const [mailField, setMailField] = useState('')
	const [mailDefault, setMailDefault] = useState('')
	const [mailDefaultValid, setMailDefaultValid] = useState(true)

	const mailChange = (data) => {
		if (isMailTextMode) {
			const mailRegExp = dataTypeRegExes.Email.re

			validateWithRegExp(data.target.value, mailRegExp).then((isValid) => {
				setMailDefaultValid(isValid)
				if (isValid) {
					timeoutDelay(submitMail, data.target.value, 1000)
				}
			})

			setMailDefault(data.target.value)
		} else {
			submitMail(data?.value)
			setMailField(data?.value)
		}
	}
	const submitMail = (x) => {
		const selectedField = emailFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				address: {
					field: { $set: isMailTextMode ? null : x },
					default: { $set: isMailTextMode ? x : null },
					type: { $set: isMailTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****SUBJECT***************************/
	const [isSubjectTextMode, setIsSubjectTextMode] = useState(false)
	const [subjectField, setSubjectField] = useState('')
	const [subjectDefault, setSubjectDefault] = useState('')

	const subjectChange = (data) => {
		if (isSubjectTextMode) {
			submitSubject(data)
			setSubjectDefault(data)
		} else {
			submitSubject(data?.value)
			setSubjectField(data?.value)
		}
	}
	const submitSubject = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				subject: {
					field: { $set: isSubjectTextMode ? null : x },
					default: { $set: isSubjectTextMode ? x : null },
					type: { $set: isSubjectTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****BODY***************************/
	const [isBodyTextMode, setIsBodyTextMode] = useState(false)
	const [bodyField, setBodyField] = useState('')
	const [bodyDefault, setBodyDefault] = useState('')

	const bodyChange = (data) => {
		if (isBodyTextMode) {
			submitBody(data)
			setBodyDefault(data)
		} else {
			submitBody(data?.value)
			setBodyField(data?.value)
		}
	}
	const submitBody = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)

		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				body: {
					field: { $set: isBodyTextMode ? null : x },
					default: { $set: isBodyTextMode ? x : null },
					type: { $set: isBodyTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****CC***************************/
	const [isCCTextMode, setIsCCTextMode] = useState(false)
	const [ccField, setCCField] = useState('')
	const [ccDefault, setCCDefault] = useState('')
	const [ccDefaultValid, setCCDefaultValid] = useState(true)

	const ccChange = (data) => {
		if (isCCTextMode) {
			const ccRegExp = dataTypeRegExes.Email.re

			validateWithRegExp(data.target.value, ccRegExp).then((isValid) => {
				setCCDefaultValid(isValid)
				if (isValid) {
					timeoutDelay(submitCC, data.target.value, 1000)
				}
			})

			setCCDefault(data.target.value)
		} else {
			submitCC(data?.value)
			setCCField(data?.value)
		}
	}
	const submitCC = (x) => {
		const selectedField = emailFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				cc: {
					field: { $set: isCCTextMode ? null : x },
					default: { $set: isCCTextMode ? x : null },
					type: { $set: isCCTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****BCC***************************/
	const [isBCCTextMode, setIsBCCTextMode] = useState(false)
	const [bccField, setBCCField] = useState('')
	const [bccDefault, setBCCDefault] = useState('')
	const [bccDefaultValid, setBCCDefaultValid] = useState(true)

	const bccChange = (data) => {
		if (isBCCTextMode) {
			const bccRegExp = dataTypeRegExes.Email.re

			validateWithRegExp(data.target.value, bccRegExp).then((isValid) => {
				setBCCDefaultValid(isValid)
				if (isValid) {
					timeoutDelay(submitBCC, data.target.value, 1000)
				}
			})

			setBCCDefault(data.target.value)
		} else {
			submitBCC(data?.value)
			setBCCField(data?.value)
		}
	}
	const submitBCC = (x) => {
		const selectedField = emailFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				bcc: {
					field: { $set: isBCCTextMode ? null : x },
					default: { $set: isBCCTextMode ? x : null },
					type: { $set: isBCCTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****TITLE***************************/
	const [isTitleTextMode, setIsTitleTextMode] = useState(false)
	const [titleField, setTitleField] = useState('')
	const [titleDefault, setTitleDefault] = useState('')

	const titleChange = (data) => {
		if (isTitleTextMode) {
			submitTitle(data)
			setTitleDefault(data)
		} else {
			submitTitle(data?.value)
			setTitleField(data?.value)
		}
	}
	const submitTitle = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				title: {
					field: { $set: isTitleTextMode ? null : x },
					default: { $set: isTitleTextMode ? x : null },
					type: { $set: isTitleTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStateByKey = (
		elementData,
		checkKey,
		setTextModeFunc,
		setFieldFunc,
		setDefaultFunc,
		emptySelectable = false
	) => {
		elementData?.mapping?.[checkKey]?.field ||
		(emptySelectable && elementData?.mapping?.[checkKey]?.field === '')
			? setTextModeFunc(false)
			: setTextModeFunc(true)

		setFieldFunc(elementData?.mapping?.[checkKey]?.field || '')
		setDefaultFunc(elementData?.mapping?.[checkKey]?.default || '')
	}
	const initStates = (elementData) => {
		/****EMAIL***************************/
		initStateByKey(
			elementData,
			'address',
			setIsMailTextMode,
			setMailField,
			setMailDefault
		)
		/****SUBJECT***************************/
		initStateByKey(
			elementData,
			'subject',
			setIsSubjectTextMode,
			setSubjectField,
			setSubjectDefault,
			true
		)
		/****BODY***************************/
		initStateByKey(
			elementData,
			'body',
			setIsBodyTextMode,
			setBodyField,
			setBodyDefault,
			true
		)
		/****CC***************************/
		initStateByKey(
			elementData,
			'cc',
			setIsCCTextMode,
			setCCField,
			setCCDefault,
			true
		)
		/****BCC***************************/
		initStateByKey(
			elementData,
			'bcc',
			setIsBCCTextMode,
			setBCCField,
			setBCCDefault,
			true
		)
		/****TITLE***************************/
		initStateByKey(
			elementData,
			'title',
			setIsTitleTextMode,
			setTitleField,
			setTitleDefault,
			true
		)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		props?.elementData && initStates(props.elementData)
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.emailButton.to')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateText
								fullWidth={true}
								textError={!mailDefaultValid}
								isTextMode={isMailTextMode}
								setIsTextMode={setIsMailTextMode}
								valChange={mailChange}
								textVal={mailDefault}
								autoCompVal={mailField}
								autoCompOptions={emailOptions}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.emailButton.subject')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateMentions
								name={`${elementName}-subject`}
								multiline={false}
								fullWidth={true}
								isTextMode={isSubjectTextMode}
								setIsTextMode={setIsSubjectTextMode}
								valChange={subjectChange}
								textVal={subjectDefault}
								autoCompVal={subjectField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.emailButton.body')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateMentions
								name={`${elementName}-body`}
								multiline={false}
								fullWidth={true}
								isTextMode={isBodyTextMode}
								setIsTextMode={setIsBodyTextMode}
								valChange={bodyChange}
								textVal={bodyDefault}
								autoCompVal={bodyField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.emailButton.cc')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateText
								fullWidth={true}
								textError={!ccDefaultValid}
								isTextMode={isCCTextMode}
								setIsTextMode={setIsCCTextMode}
								valChange={ccChange}
								textVal={ccDefault}
								autoCompVal={ccField}
								autoCompOptions={emailOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.emailButton.bcc')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateText
								fullWidth={true}
								textError={!bccDefaultValid}
								isTextMode={isBCCTextMode}
								setIsTextMode={setIsBCCTextMode}
								valChange={bccChange}
								textVal={bccDefault}
								autoCompVal={bccField}
								autoCompOptions={emailOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.emailButton.title')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateMentions
								name={`${elementName}-title`}
								multiline={false}
								fullWidth={true}
								isTextMode={isTitleTextMode}
								setIsTextMode={setIsTitleTextMode}
								valChange={titleChange}
								textVal={titleDefault}
								autoCompVal={titleField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default EmailButton

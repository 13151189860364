import { styled } from '@mui/material/styles'
import LinearProgress, {
	linearProgressClasses
} from '@mui/material/LinearProgress'

const KozmikProgressBar = styled((props) => {
	const { progressColor, barColor, height, ...restProps } = props

	return <LinearProgress {...restProps} />
})(({ progressColor, barColor, height, theme }) => ({
	height: height || 4,
	borderRadius: height ? height / 2 : 2,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: barColor || theme.custom.colors.gray
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: height ? height / 2 : 2,
		backgroundColor: progressColor || theme.custom.colors.kozmikDarkBlue
	}
}))

export default KozmikProgressBar

import React from 'react'
import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'

const KozmikTabs = styled((props) => (
	<Tabs
		{...props}
		TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
	/>
))({
	minHeight: 40,
	'& .MuiTabs-indicator': {
		display: 'none'
	}
})

export default KozmikTabs

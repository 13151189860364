import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

import kozmikbackground from '../../../assets/images/kozmikbackground.png'

const useStyles = makeStyles(() => ({
	mainContainer: {
		width: '100%',
		height: '100vh',
		flexWrap: 'nowrap',
		overflow: 'hidden',
		backgroundImage: `url("${kozmikbackground}")`,
		backgroundSize: 'cover'
	}
}))

const AuthLayout = (props) => {
	const classes = useStyles()

	return <div className={classes.mainContainer}>{props?.children}</div>
}

export default AuthLayout

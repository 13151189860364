import React from 'react'
import { Button, Typography, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import cx from 'classnames'

import KozmikModal from '../../kozmikModal/KozmikModal'

import kozmikTheme from '../../../../assets/theme/theme.json'

const useStyles = makeStyles((theme) => ({
	modalButton: {
		width: '48%',
		height: 40,
		textTransform: 'none',
		borderRadius: 8
	},
	modalButtonText: {
		fontWeight: 'bold',
		fontSize: 14
	},
	handleClose: {
		backgroundColor: '#FFFFFF',
		border: `1px solid ${theme.custom.colors.red}`,
		'&:hover': {
			border: `1px solid ${theme.custom.colors.red}`
		}
	},
	handlePublishPopupClick: {
		backgroundColor: theme.custom.colors.red,
		float: 'right',
		'&:hover': {
			backgroundColor: theme.custom.colors.darkRed
		}
	},
	fontWeight700: {
		fontWeight: theme.custom.fontWeight.fw700
	},
	fontSize14: {
		fontSize: '14px'
	},
	mt30: {
		marginTop: '30px !important'
	},
	mt15: {
		marginTop: '15px !important'
	},
	modalContent: {
		padding: 20
	},
	dFlex: {
		display: 'flex'
	},
	padding10: {
		padding: '10px 0'
	},
	cancelButton: {
		color: theme.custom.colors.red,
		textAlign: 'left'
	},
	modalBorder: {
		backgroundColor: theme.custom.colors.red,
		height: 10
	}
}))

const WarningDialog = (props) => {
	const classes = useStyles()

	return (
		<KozmikModal
			accentColor={kozmikTheme.colors.red}
			visible={props.dialogVisible}
			hideModal={props.dialogClose}
			flexHeight
			sm
		>
			<Grid container justifyContent='center' className={classes.modalContent}>
				{props?.dialogTitle && (
					<Grid item container xs={12}>
						<Grid
							item
							container
							xs={12}
							alignItems='center'
							className={cx(classes.dFlex, classes.padding10)}
						>
							<Typography
								className={classes.fontWeight700}
								style={{ fontSize: 18 }}
							>
								{props.dialogTitle}
							</Typography>
						</Grid>
					</Grid>
				)}
				<Grid item xs={12}>
					{props?.title && (
						<Typography
							gutterBottom
							className={cx(classes.fontWeight700, classes.fontSize14)}
						>
							{props.title}
						</Typography>
					)}
					{props?.text && <p className={classes.fontSize14}>{props.text}</p>}
				</Grid>
				<Grid item xs={12} className={classes.mt30}>
					{props?.dialogCancelText && (
						<Button
							onClick={props.dialogClose}
							className={cx(classes.handleClose, classes.modalButton)}
							variant='outlined'
							color='primary'
						>
							<Typography
								className={cx(classes.cancelButton, classes.modalButtonText)}
							>
								{props.dialogCancelText}
							</Typography>
						</Button>
					)}
					{props?.dialogAcceptText && (
						<Button
							onClick={props.dialogAccept}
							className={cx(
								classes.handlePublishPopupClick,
								classes.modalButton
							)}
							variant='contained'
							color='primary'
						>
							<Typography className={classes.modalButtonText}>
								{props?.dialogAcceptText}
							</Typography>
						</Button>
					)}
				</Grid>
			</Grid>
		</KozmikModal>
	)
}

export default WarningDialog

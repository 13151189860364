import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button, Box, TextField } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import cx from 'classnames'

import Header from '../../molecules/header/Header'
import ProfileCard from '../../molecules/profileCard/ProfileCard'
import PageBox from '../../atoms/pageBox/PageBox'
import MediaUploadButton from '../../atoms/mediaPicker/MediaUploadButton'
import WarningDialog from '../../molecules/dialog/warningDialog/WarningDialog'
import authorizationClient from '../../../services/authorizationClient'
import showToastResponseError from '../../../methods/showToastResponseError'

import commonRegExes from '../../../common/regExp/commonRegExes'

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '16px',
		fontWeight: 600
	},
	button: {
		marginTop: 22,
		fontSize: '14px',
		fontWeight: 600,
		borderRadius: 4,
		height: 40,
		boxShadow: 'none !important',
		textTransform: 'unset'
	},
	blueButton: {
		color: '#ffffff',
		backgroundColor: `${theme.custom.colors.kozmikBlue} !important`
	},
	redButton: {
		color: theme.custom.colors.red,
		backgroundColor: `${theme.custom.colors.lightRed} !important`
	},
	label: {
		fontSize: 12,
		fontWeight: 600,
		color: '#9A9A9A',
		paddingBottom: 4
	},
	infoText: {
		fontSize: 14
	},
	pageBox: {
		padding: 20,
		paddingBottom: 40
	},
	verifiedBox: {
		backgroundColor: theme.custom.colors.gray,
		borderRadius: 4,
		fontSize: 14,
		fontWeight: 600,
		padding: '10px 10px 10px 20px',
		display: 'flex',
		alignItems: 'center'
	},
	checkActive: {
		fontSize: 15,
		color: theme.custom.colors.kozmikMatGreen,
		marginLeft: 20
	}
}))

const AccountTemplate = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [imgUrl, setImgUrl] = useState(null)
	const [dialogVisible, setDialogVisible] = useState(false)

	const [profile, setProfile] = useState({
		photo: null,
		name: null,
		email: null
	})
	const [password, setPassword] = useState({
		oldPassword: null,
		password: null,
		confirmPassword: null
	})

	const [isPasswordError, setIsPasswordError] = useState(false)
	const [passwordErrorText, setPasswordErrorText] = useState('')
	const [isNewPasswordError, setIsNewPasswordError] = useState(false)
	const [newPasswordErrorText, setNewPasswordErrorText] = useState('')
	const [isNewPasswordReError, setIsNewPasswordReError] = useState(false)
	const [newPasswordReErrorText, setNewPasswordReErrorText] = useState('')
	const [isEmailError, setIsEmailError] = useState(false)
	const [emailErrorText, setEmailErrorText] = useState('')
	const [isNameError, setIsNameError] = useState(false)
	const [nameErrorText, setNameErrorText] = useState('')

	const errorInfo = t('account.errorLenth')

	useEffect(() => {
		setProfile({
			photo: props?.userInfo?.photo ? props.userInfo.photo : null,
			name: props?.userInfo?.name ? props.userInfo.name : null,
			email: props?.userInfo?.email ? props.userInfo.email : null
		})
		setImgUrl(props?.userInfo?.photo ? props.userInfo.photo : null)
	}, [props.userInfo, props.orgs])

	const handleDialogClose = () => {
		setDialogVisible(false)
	}

	const deleteAccount = () => {
		setDialogVisible(false)
		authorizationClient.accountDeactivate().then((response) => {
			if (!response?.data?.success) {
				showToastResponseError(response)
			}
		})
	}

	const validateName = (name) => {
		if (name != '') {
			setIsNameError(false)
			setNameErrorText('')
		} else {
			setIsNameError(true)
			setNameErrorText(t('account.errorName'))
		}
	}

	const nameChange = (e) => {
		validateName(e.target.value)
		setProfile({
			name: e.target.value,
			photo: profile.photo,
			email: profile.email
		})
	}

	const validateEmail = (email) => {
		var re = /\S+@\S+\.\S+/
		const isValid = re.test(email)

		if (isValid) {
			setIsEmailError(false)
			setEmailErrorText('')
		} else {
			setIsEmailError(true)
			setEmailErrorText(t('account.errorEmail'))
		}
	}

	const emailChange = (e) => {
		validateEmail(e.target.value)
		setProfile({
			name: profile.name,
			photo: profile.photo,
			email: e.target.value
		})
	}

	const currentPassword = (e) => {
		setPassword({
			oldPassword: e.target.value,
			password: password.password,
			confirmPassword: password.confirmPassword
		})
		if (e.target.value.length < 8) {
			setIsPasswordError(true)
			setPasswordErrorText(errorInfo)
		} else {
			setIsPasswordError(false)
			setPasswordErrorText('')
		}
	}

	const newPassword = (e) => {
		setPassword({
			oldPassword: password.oldPassword,
			password: e.target.value,
			confirmPassword: password.confirmPassword
		})
		if (passwordValidator(e.target.value) == false) {
			setIsNewPasswordError(true)
			setNewPasswordErrorText(errorInfo)
		} else {
			setIsNewPasswordError(false)
			setNewPasswordErrorText('')
		}
	}

	const newPasswordRe = (e) => {
		setPassword({
			oldPassword: password.oldPassword,
			password: password.password,
			confirmPassword: e.target.value
		})
		if (
			password.password != e.target.value ||
			passwordValidator(e.target.value) == false
		) {
			setIsNewPasswordReError(true)
			if (password.password != e.target.value) {
				setNewPasswordReErrorText(t('account.errorDiff'))
			} else {
				setNewPasswordReErrorText(errorInfo)
			}
		} else {
			setIsNewPasswordReError(false)
			setNewPasswordReErrorText('')
		}
	}

	const passwordValidator = (e) => {
		var pattern = new RegExp(commonRegExes.password)

		const isOk = pattern.test(e)
		return isOk
	}

	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader()
			fileReader.readAsDataURL(file)
			fileReader.onload = () => {
				resolve(fileReader.result)
			}
			fileReader.onerror = (error) => {
				reject(error)
			}
		})
	}

	const submitProfile = async () => {
		let profileData = { ...profile }

		if (profileData.name === props.userInfo.name) {
			delete profileData.name
		}
		if (profileData.email === props.userInfo.email) {
			delete profileData.email
		}
		if (profileData?.photo === props.userInfo?.photo) {
			delete profileData.photo
		}

		if (!isNameError && !isEmailError && Object.keys(profileData).length > 0) {
			authorizationClient.changeProfile(profileData).then((response) => {
				if (response?.data?.success) {
					toast.success(response.data?.message)
					authorizationClient.getUserInfo()
				} else {
					showToastResponseError(response)
				}
			})
		}
	}

	const pickImage = async (e) => {
		if (e.target.files[0]) {
			const imageUrl = URL.createObjectURL(e.target.files[0])
			const base64Photo = await convertBase64(e.target.files[0])

			// setImage(e.target.files[0])
			setProfile({
				name: profile.name,
				photo: base64Photo,
				email: profile.email
			})
			setImgUrl(imageUrl)
		}
	}

	const submitPassword = () => {
		if (!(isPasswordError || isNewPasswordError || isNewPasswordReError)) {
			authorizationClient.changePassword(password).then((response) => {
				if (response?.data?.success) {
					toast.success(response.data?.message)
				} else {
					showToastResponseError(response)
				}
			})
		}
	}

	return (
		<Grid container item xs={12} lg={10} spacing={2}>
			<Grid item xs={12}>
				<Header text={t('account.myAccount')}></Header>
			</Grid>
			<Grid item xs={12}>
				<PageBox className={classes.pageBox}>
					<Grid item container spacing={2} alignItems='start'>
						<Grid container item xs={12} justifyContent='space-between'>
							<Typography className={classes.title}>
								{t('account.profileInfo')}
							</Typography>

							<Box className={classes.verifiedBox}>
								{t('account.accountVerified')}
								<CheckCircle className={classes.checkActive} />
							</Box>
						</Grid>

						<Grid
							item
							xs={12}
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<ProfileCard
								text={profile.name}
								imgUrl={imgUrl}
								size={60}
								circle
								bgColor='#FAFAFC'
							/>
							<MediaUploadButton
								text={t('account.addPhoto')}
								onChange={(e) => {
									pickImage(e)
								}}
							/>
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<Box>
								<Typography className={classes.label}>
									{t('account.nameSurname')}
								</Typography>
								<TextField
									fullWidth
									size='small'
									variant='outlined'
									// label={t('account.nameSurname')}
									value={profile.name}
									onChange={nameChange}
									error={isNameError}
									helperText={nameErrorText}
									placeholder=''
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<Box>
								<Typography className={classes.label}>
									{t('account.email')}
								</Typography>
								<TextField
									fullWidth
									size='small'
									variant='outlined'
									// label={t('account.email')}
									value={profile.email}
									onChange={emailChange}
									error={isEmailError}
									helperText={emailErrorText}
									placeholder=''
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={4} style={{ marginLeft: 'auto' }}>
							<Box display='flex' justifyContent='center'>
								<Button
									fullWidth
									variant='contained'
									className={cx(classes.button, classes.blueButton)}
									onClick={() => {
										submitProfile()
									}}
								>
									{t('account.save')}
								</Button>
							</Box>
						</Grid>
					</Grid>
				</PageBox>
			</Grid>
			<Grid item xs={12}>
				<PageBox className={classes.pageBox}>
					<Grid item container spacing={2} alignItems='start'>
						<Grid item xs={12}>
							<Typography className={classes.title}>
								{t('account.password')}
							</Typography>
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<Box>
								<Typography className={classes.label}>
									{t('account.password')}
								</Typography>
								<TextField
									fullWidth
									size='small'
									variant='outlined'
									defaultValue=''
									error={isPasswordError}
									helperText={passwordErrorText}
									onChange={currentPassword}
									placeholder=''
									type='password'
								/>
							</Box>
						</Grid>
						<Box
							component={Grid}
							item
							xs={6}
							display={{ xs: 'none', sm: 'block' }}
						>
							{/*SPACE*/}
						</Box>
						<Grid item xs={12} sm={6} md={4}>
							<Box>
								<Typography className={classes.label}>
									{t('account.newPassword')}
								</Typography>
								<TextField
									fullWidth
									size='small'
									variant='outlined'
									error={isNewPasswordError}
									helperText={newPasswordErrorText}
									onChange={newPassword}
									defaultValue=''
									placeholder=''
									type='password'
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<Box>
								<Typography className={classes.label}>
									{t('account.newPasswordAgain')}
								</Typography>
								<TextField
									fullWidth
									size='small'
									variant='outlined'
									onChange={newPasswordRe}
									error={isNewPasswordReError}
									helperText={newPasswordReErrorText}
									defaultValue=''
									placeholder=''
									type='password'
								/>
							</Box>
						</Grid>

						<Grid item xs={12} sm={6} md={4} style={{ marginLeft: 'auto' }}>
							<Box display='flex' justifyContent='center'>
								<Button
									fullWidth
									variant='contained'
									className={cx(classes.button, classes.blueButton)}
									// disabled={isPasswordChangeable}
									onClick={() => {
										submitPassword()
									}}
								>
									{t('account.updatePassword')}
								</Button>
							</Box>
						</Grid>
					</Grid>
				</PageBox>
			</Grid>

			<Grid item xs={12}>
				<PageBox className={classes.pageBox}>
					<WarningDialog
						dialogVisible={dialogVisible}
						dialogClose={handleDialogClose}
						dialogAccept={deleteAccount}
						dialogTitle={t('account.modal.dialogTitle')}
						title={null}
						text={t('account.modal.text')}
						dialogCancelText={t('account.modal.closeButton')}
						dialogAcceptText={t('account.modal.acceptButton')}
					/>
					<Grid item container spacing={2} alignItems='center'>
						<Grid item xs={12}>
							<Typography className={classes.title}>
								{t('account.deleteAccount')}
							</Typography>
						</Grid>

						<Grid item xs={12} sm={6} md={8}>
							<Typography className={classes.infoText}>
								{t('account.infoText')}
							</Typography>
							<Typography className={classes.infoText}>
								{t('account.infoText2')}
							</Typography>
						</Grid>

						<Grid item xs={12} sm={6} md={4} style={{ marginLeft: 'auto' }}>
							<Box display='flex' justifyContent='center'>
								<Button
									fullWidth
									variant='contained'
									className={cx(classes.button, classes.redButton)}
									onClick={() => setDialogVisible(true)}
								>
									{t('account.deleteAccount')}
								</Button>
							</Box>
						</Grid>
					</Grid>
				</PageBox>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state) => ({
	...state.userInfo,
	...state.orgs
})

// const mapDispatchToProps = (dispatch) => {
// 	return {
// 		setUserInfo: (x) => dispatch(setUserInfo(x)),
// 		setAuth: (x) => dispatch(setAuth(x))
// 	}
// }

export default connect(mapStateToProps, null)(AccountTemplate)

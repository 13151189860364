import { v4 as uuidv4 } from 'uuid'

const method = (size = 10, linePosition = 'center') => {
	let object = {
		_uid: uuidv4(),
		component: 'seperator',
		lineVisible: false,
		size: size,
		linePosition: linePosition
	}

	return object
}

export default method

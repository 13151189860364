import React, { useEffect, useState } from 'react'
import { Search } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import InputAdornment from '@mui/material/InputAdornment'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import Grid from '@mui/material/Grid'
import cx from 'classnames'
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing'

import Header from '../../molecules/header/Header'
import BorderlessTextField from '../../atoms/borderlessTextField/BorderlessTextField'
import SelectBox from '../../molecules/selectBox/SelectBox'

const useStyles = makeStyles((theme) => ({
	labelStyle: {
		color: '#000000',
		fontSize: 18,
		lineHeight: '22px',
		textAlign: 'left',
		display: 'block',
		fontWeight: 'bold'
	},
	width100: {
		width: '100%'
	},
	width70: {
		width: '70%'
	},
	width30: {
		width: '30%'
	},
	width40: {
		width: '40%'
	},
	input: {
		backgroundColor: '#ffffff',
		borderRadius: 4
	},
	inputSize: {
		fontSize: '14px'
	},
	inputIcon: {
		color: theme.custom.colors.textSecondary,
		opacity: 0.6
	},
	headerBar: {
		display: 'flex',
		backgroundColor: '#fafafc',
		zIndex: 1
	}
}))

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const SearchHeader = (props) => {
	// let query = useQuery();
	const classes = useStyles()
	const [searchVal, setSearchVal] = useState('')
	const [selectVal, setSelectVal] = useState('Created desc')

	const selectChange = (e) => {
		setSelectVal(e.target.value)
		props?.searchAndFilter(searchVal, e.target.value)
		// props?.filter(e.target.value)
	}

	const searchOnKeyPress = (e) => {
		if (e.charCode === 13) {
			props?.searchAndFilter(e.target.value, selectVal)
			// props?.search(e.target.value)
		}
	}

	const clearSearch = () => {
		setSearchVal('')
		props?.searchAndFilter('', selectVal)
	}

	useEffect(() => {}, [])
	return (
		<div className={classes.headerBar}>
			{/* <img src={logo} alt="Xcelvations Logo" height="40" /> */}

			<div
				className={classes.width100}
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					flexWrap: 'wrap'
				}}
			>
				{props?.title && <Header text={props.title}></Header>}

				<div>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<SelectBox
								className={cx(classes.input, classes.inputSize)}
								onChange={selectChange}
								startAdornment={
									<InputAdornment position='start'>
										<FormatLineSpacingIcon className={classes.inputIcon} />
									</InputAdornment>
								}
								value={selectVal}
								data={props.selectData}
								textKey='text'
								valueKey='value'
								placeholder={
									props?.placeholders?.select
										? props.placeholders.select
										: 'Sort'
								}
							/>
						</Grid>
						<Grid item xs={6}>
							<BorderlessTextField
								className={classes.input}
								size='small'
								id='standard-search'
								//type='search'
								placeholder={
									props?.placeholders?.search
										? props.placeholders.search
										: 'Search...'
								}
								variant='outlined'
								fullWidth={true}
								value={searchVal}
								onChange={(e) => setSearchVal(e.target.value)}
								onKeyPress={searchOnKeyPress}
								InputProps={{
									classes: {
										input: classes.inputSize
									},
									startAdornment: (
										<InputAdornment position='start'>
											<Search className={classes.inputIcon} />
										</InputAdornment>
									),

									endAdornment: searchVal && (
										<InputAdornment
											onClick={() => clearSearch()}
											position='end'
											style={{ cursor: 'pointer' }}
										>
											<CancelRoundedIcon className={classes.inputIcon} />
										</InputAdornment>
									)
								}}
							/>
						</Grid>
					</Grid>
				</div>
			</div>
		</div>
	)
}
export default SearchHeader

import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import cx from 'classnames'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { ArrowBackIos, Visibility } from '@mui/icons-material'

import { connect } from 'react-redux'

import ViewAs from '../viewAs/ViewAs'

const useStyles = makeStyles((theme) => ({
	preview: {
		border: '6px solid #000000',
		borderRadius: 47,
		overflow: 'hidden',
		zIndex: 1
	},
	previewMobileBar: {
		display: 'none',
		backgroundColor: theme.custom.colors.kozmikLightBlue,
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		height: 40,
		padding: '0px 10px',
		position: 'fixed',
		top: 0,
		left: 0,
		cursor: 'pointer',
		zIndex: 101,
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			visibility: 'visible'
		}
	},
	previewBrowserBar: {
		display: 'none',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute',
		backgroundColor: '#F0F0F4',
		width: '100%',
		height: 40,
		top: 0,
		left: 0,
		right: 0,
		zIndex: 1
	},
	viewAsBox: {
		position: 'absolute',
		display: 'flex',
		left: 15
	},
	browserUrlBox: {
		display: 'flex',
		alignItems: 'center',
		padding: '0 10px',
		width: 300,
		height: 24,
		overflow: 'hidden',
		borderRadius: 6,
		backgroundColor: '#FFFFFF'
	},
	browserUrl: {
		fontSize: 14
	},
	overlay: {
		'& $iframe': {
			paddingTop: 40
		}
	},
	phone: {
		width: 340,
		height: 700,
		borderRadius: 44
	},
	tablet: {
		width: 517,
		height: 700,
		borderRadius: 32
	},
	desktop: {
		border: '1px solid #D1D1D1',
		paddingTop: 44,
		width: 900,
		height: 660,
		borderRadius: 6,

		'& $previewBrowserBar': {
			display: 'flex'
		},
		'& $iframe': {
			transform: 'scale(0.55)',
			transformOrigin: '0 0',
			width: '182%',
			height: '182%'
		}
	},
	iframe: {
		width: '100%',
		height: '100%',
		border: 0
	},
	visible: {
		[theme.breakpoints.down('md')]: {
			position: 'fixed',
			bottom: 0,
			left: 0,
			zIndex: 100,
			display: 'block !important',
			visibility: 'visible !important'
		}
	},

	previewMobileButton: {
		display: 'none',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		width: 60,
		height: 80,
		backgroundColor: '#6BEFC1',
		position: 'fixed',
		right: 0,
		bottom: 0,
		zIndex: 101,
		visibility: 'visible',
		cursor: 'pointer',
		padding: 5,
		[theme.breakpoints.down('md')]: {
			display: 'flex'
		}
	},
	previewMobileIcon: {
		fontSize: 22,
		color: theme.custom.colors.kozmikDarkBlue
	},
	previewMobileBackButton: {
		display: 'none',
		alignItems: 'center',
		cursor: 'pointer',
		[theme.breakpoints.down('md')]: {
			display: 'flex'
		}
	},
	previewMobileButtonText: {
		fontSize: 10,
		color: theme.custom.colors.kozmikDarkBlue,
		fontWeight: theme.custom.fontWeight.fw700
	},
	previewMobileBackButtonIcon: {
		fontSize: 14,
		color: theme.custom.colors.textPrimary
	},
	previewMobileBackButtonText: {
		fontSize: 12,
		color: theme.custom.colors.textPrimary,
		fontWeight: theme.custom.fontWeight.fw600
	}
}))

const Preview = (props) => {
	const { t } = useTranslation()

	const classes = useStyles()

	const mobileButtonClick = () => {
		if (props.isFullPagePreview) {
			props.setIsFullPagePreview(false)
		} else {
			props.setIsFullPagePreview(true)
		}
	}

	const previewClass = (selectedIndex, isFullPagePreview) => {
		if (isFullPagePreview) {
			return classes.overlay
		}

		switch (selectedIndex) {
			case 0:
				return classes.phone
			case 1:
				return classes.tablet
			case 2:
				return classes.desktop
		}
	}

	return props.previewButtonIndex != null ? (
		<>
			<Grid container>
				<Grid item container justifyContent='center' alignItems='center'>
					<div
						className={cx(
							classes.preview,
							previewClass(props.previewButtonIndex, props.isFullPagePreview)
						)}
						style={{
							position: 'relative',
							marginTop: 21,
							zIndex: 11,
							backgroundColor:
								props.previewButtonIndex != 2 &&
								(props.appDetail?.Theme == 'Dark' ? '#24272a' : '#FFFFFF')
						}}
					>
						{props?.splash && props.previewButtonIndex != 2 && (
							<div
								style={{
									position: 'absolute',
									top: 0,
									bottom: 0,
									left: 0,
									right: 0,
									backgroundColor: props.appDetail?.SplashUseAccentColor
										? props.appDetail?.AccentColor
											? props.appDetail.AccentColor
											: props.appDetail?.Theme == 'Light'
											? '#24272a'
											: '#FFFFFF'
										: props.appDetail?.Theme == 'Light'
										? '#FFFFFF'
										: '#24272a',
									zIndex: 1,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center'
								}}
							>
								{props.appDetail?.SplashIcon && (
									<img
										src={props.appDetail?.SplashIcon}
										alt='splash screen icon'
										style={{
											width: '100%',
											height: '100%',
											objectFit: props.appDetail?.SplashResizeMode || 'contain'
										}}
									></img>
								)}
							</div>
						)}

						<div className={classes.previewBrowserBar}>
							<div className={classes.viewAsBox}>
								<ViewAs inline={true} users={props.users} />
							</div>
							<div className={classes.browserUrlBox}>
								<span className={classes.browserUrl}>{`https://${
									props?.shortName ? props.shortName : t('preview.yourapp')
								}.kozmik.io`}</span>
							</div>
						</div>

						<iframe
							className={cx(
								classes.iframe,
								props.isFullPagePreview ? classes.visible : null
							)}
							id='kozmikPreviewFrame'
							// loading='lazy'
							allow='autoplay; camera; microphone; web-share; fullscreen'
							// src='https://localhost:19006'
							src='https://play.kozmik.io'
						></iframe>

						{props.isFullPagePreview && (
							<div className={classes.previewMobileBar}>
								<div
									className={classes.previewMobileBackButton}
									onClick={mobileButtonClick}
								>
									<div
										style={{
											display: 'flex',
											flexWrap: 'wrap',
											alignItems: 'center',
											justifyContent: 'center',
											textAlign: 'center'
										}}
									>
										<ArrowBackIos
											className={classes.previewMobileBackButtonIcon}
										/>
										<Typography className={classes.previewMobileBackButtonText}>
											{t('preview.backToBuilder')}
										</Typography>
									</div>
								</div>
								<ViewAs mobile={true} users={props.users} />
							</div>
						)}
					</div>
				</Grid>

				{!props.isFullPagePreview && (
					<div
						className={classes.previewMobileButton}
						onClick={mobileButtonClick}
					>
						<div
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'center'
							}}
						>
							<Visibility className={classes.previewMobileIcon} />
							<Typography className={classes.previewMobileButtonText}>
								{t('preview.goApp')}
							</Typography>
						</div>
					</div>
				)}
			</Grid>
		</>
	) : null
}

const mapStateToProps = (state) => ({
	...state.appData
})

export default connect(mapStateToProps, null)(Preview)

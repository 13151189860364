import { v4 as uuidv4 } from 'uuid'

import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../common/fieldDataTypes'
import aggOperators from '../../common/aggOperators'
import languages from '../../i18n/languages'
import relationTypes from '../../common/enums/relationTypes'
import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'

import filterModal from './filterModal'

const method = (table, searcheable = false, actions = null) => {
	const txtFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Text', 'LongText', 'Number', 'Date', 'Email'],
		table,
		true,
		relationTypes.one
	)
	const numericFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Number'],
		table,
		true,
		relationTypes.one
	)

	const labelField =
		txtFields?.find((x) => x.name === 'Name') ??
		txtFields?.find((x) => !numericFields?.find((y) => x.name === y.name)) ??
		txtFields?.[0]
	const numericField = numericFields?.length ? numericFields[0] : null

	const object = {
		_uid: uuidv4(),
		component: 'chartProgress',
		title: '',
		source: table?.name,
		mapping: {
			aggregation: 'count',
			label: {
				field: labelField?.name,
				type: labelField?.dataType
			},
			value: {
				field: numericField?.name,
				type: numericField?.dataType
			}
		},
		maxValue: {
			field: null,
			type: null,
			default: 1
		},
		sorting: {
			field: numericField?.name,
			sort: 'asc'
		},
		filters: {
			groupOp: 'AND',
			rules: []
		},
		searcheable: searcheable,
		searchPlaceholder: {
			default: languages.en.schemas.list.searchPlaceholder
		},
		filterModal: filterModal(),
		actions: actions ?? [],
		limit: 3,
		background: 'none',
		spacing: 'xl',
		style: 'circle' // circle,line
	}

	return object
}

export default method

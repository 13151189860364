import { Box } from '@mui/material'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import cx from 'classnames'

const useStyles = makeStyles((/*theme*/) => ({
	box: {
		backgroundColor: '#ffffff',
		borderRadius: 8,
		display: 'flex',
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.03)'
	}
}))

const PageBox = (props) => {
	const classes = useStyles()

	const { className, ...restProps } = props

	return (
		<Box className={cx(classes.box, className || null)} {...restProps}></Box>
	)
}

export default PageBox

import { ApiClient } from './client'
import { default as appConfig } from '../config/app.json'
import cryptoServices from '../services/cryptoServices'

const appDomain = appConfig.domains.appDomain

export default {
	async search(params = null) {
		let headers = {}
		let data = params ? { params: params } : null
		headers['Content-Type'] = 'application/json'
		headers['Access-Control-Allow-Origin'] = window.location.origin

		return await new ApiClient(appDomain, headers).get('/search', data)
	},
	async detail(appId) {
		let url = `/detail/${appId}`
		let headers = {}
		headers['AppId'] = appId
		headers['Content-Type'] = 'application/json'
		headers['Access-Control-Allow-Origin'] = window.location.origin
		return await new ApiClient(appDomain, headers).get(url)
	},
	async create(data) {
		let url = '/create'
		let headers = {}
		headers['Content-Type'] = 'application/json'
		headers['Access-Control-Allow-Origin'] = window.location.origin

		return await new ApiClient(appDomain, headers).post(
			url,
			JSON.stringify(data)
		)
	},
	async update(data = null, headers = {}) {
		headers['Content-Type'] = 'application/json'

		return await new ApiClient(appDomain, headers).post(
			'/update',
			JSON.stringify(data)
		)
	},
	async delete(appId, headers = {}, data = null) {
		let url = `/delete/${appId}`
		headers['AppId'] = appId
		headers['Content-Type'] = 'application/json'
		headers['Access-Control-Allow-Origin'] = window.location.origin

		return await new ApiClient(appDomain, headers).post(
			url,
			JSON.stringify(data)
		)
	},
	async copy(appId, headers = {}, data = null) {
		let url = `/copy/${appId}`
		headers['AppId'] = appId
		headers['Content-Type'] = 'application/json'
		headers['Access-Control-Allow-Origin'] = window.location.origin

		return await new ApiClient(appDomain, headers).post(
			url,
			JSON.stringify(data)
		)
	},
	async deploy(appId, platform, headers = {}) {
		let url = `/publish/${appId}/${platform}`
		headers['AppId'] = appId

		return await new ApiClient(appDomain, headers).post(url)
	},
	async import(shortName, headers = {}) {
		let url = `/import/${shortName}`

		return await new ApiClient(appDomain, headers).post(url)
	},
	async config(appId, data = null) {
		let url = `/screens/${appId}`
		let headers = {}
		let dataAsString = JSON.stringify(data)

		headers['AppId'] = appId
		headers['Content-Type'] = 'application/json'
		headers['Access-Control-Allow-Origin'] = window.location.origin

		const encryptedData = cryptoServices.encrypt(dataAsString)

		return await new ApiClient(appDomain, headers).post(url, encryptedData)
	},
	async getHistory(appId, platform, headers = {}) {
		//platformArray=['web','android','ios']
		headers['AppId'] = appId

		const url = `/history/${appId}/${platform}`

		return await new ApiClient(appDomain, headers).get(url)
	},
	async appPublish(appId, platform, headers = {}) {
		//platformArray=['web','android','ios']
		headers['AppId'] = appId

		const url = `/publish/${appId}/${platform}`

		return await new ApiClient(appDomain, headers).post(url)
	}
}

import i18next from '../../i18n/i18n'

const all = [
	{
		label: i18next.t('aggOperators.sum'),
		value: 'sum'
	},
	{
		label: i18next.t('aggOperators.avg'),
		value: 'avg'
	},
	{
		label: i18next.t('aggOperators.min'),
		value: 'min'
	},
	{
		label: i18next.t('aggOperators.max'),
		value: 'max'
	},
	{
		label: i18next.t('aggOperators.count'),
		value: 'count'
	}
]

export default all

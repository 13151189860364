import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
	box: {
		width: '100%',
		backgroundColor: 'white',
		padding: '10px 15px',
		borderBottom: '3px solid #fbfbfc'
	}
}))

const AppPageBox = (props) => {
	const classes = useStyles()
	return <div className={classes.box} {...props}></div>
}

export default AppPageBox

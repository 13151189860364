import React, { useEffect, useState, useRef } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { Grid, Box, Popover } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import {
	AddBox,
	DeleteOutlined,
	BrowserNotSupported
} from '@mui/icons-material'
import { ChromePicker } from 'react-color'
import { Link, useParams } from 'react-router-dom'

import { default as kozmikTheme } from '../../../assets/theme/theme.json'

import DoubleStateMentions from '../doubleStateField/DoubleStateMentions'
import DoubleStateText from '../doubleStateField/DoubleStateText'
import { default as Label } from '../../atoms/settingLabel/SettingLabel'
import AccordionItem from '../../atoms/accordionItem/AccordionItem'
import SelectBox from '../selectBox/SelectBox'
import IoniconsSelect from '../IoniconsSelect/IoniconsSelect'
import AppPageBox from '../../atoms/appPageBox/AppPageBox'
import Accordion from '../../atoms/accordion/Accordion'
import TextButton from '../../atoms/button/TextButton'
import RadioButton from '../../atoms/radioButton/RadioButton'
import PopoverItem from '../popoverItem/PopoverItem'
import ButtonOptions from './ButtonOptions'
import SquareOptions from '../../molecules/squareOprions/SquareOptions'
import VisibilityOptions from '../../molecules/visibilityOptions/VisibilityOptions'
import useOutsideClick from '../../../hooks/UseOutsideClick/useOutsideClick'
import colorpicker from '../../../assets/images/colorpicker.png'

import getEntityFieldsByDataTypes from '../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../common/fieldDataTypes'
import relationTypes from '../../../common/enums/relationTypes'
import showToastError from '../../../methods/showToastError'
import actionTypes from '../../../common/actionTypes'

import validateWithRegExp from '../../../methods/validateWithRegExp'
import timeoutDelay from '../../../methods/timeoutDelay'
import dataTypeRegExes from '../../../common/regExp/dataType'
import formulas from '../../../common/formulas'

import { ReactComponent as STPopup } from '../../../assets/icons/Settings/ScreenType-Popup.svg'
import { ReactComponent as STTransparent } from '../../../assets/icons/Settings/ScreenType-Transparent.svg'
import { ReactComponent as STModal } from '../../../assets/icons/Settings/ScreenType-Modal.svg'
import { ReactComponent as STScreen } from '../../../assets/icons/Settings/ScreenType-Screen.svg'
import MentionsInput from '../../atoms/mentionsInput/MentionsInput'
import KozmikCheckBox from '../../atoms/kozmikCheckBox/KozmikCheckBox'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	mb20: {
		marginBottom: 20
	},
	mainContainer: {
		display: 'flex',
		flexDirection: 'column'
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px'
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		marginBottom: 15
	},
	errorText: {
		color: '#d32f2f',
		fontSize: 12,
		margin: 0
	},
	growBox: {
		flexGrow: 1
	},
	labelBox: {
		width: 80,
		minWidth: 80
	},
	popoverBox: {
		padding: 8
	},
	popoverIcon: {
		color: '#000000',
		opacity: 0.7,
		width: '14px'
	},
	link: {
		color: theme.custom.colors.kozmikBlue
	}
}))

const getListStyle = (isDraggingOver) => ({
	background: isDraggingOver ? '#D7DBFB' : 'transparent',
	borderRadius: 6,
	padding: 2,
	width: '100%'
})

const getElementStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	margin: `0 0 10px 0`,
	borderRadius: 6,
	border: isDragging ? '2px dashed gray' : 'none',
	overflow: isDragging ? 'hidden' : 'visible',
	cursor: 'default',

	...draggableStyle
})

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}

const ScreenOptions = (props) => {
	const { t } = useTranslation()
	let { appId } = useParams()
	const classes = useStyles()

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen?.data?.source,
		fieldDataTypes.textBased,
		null,
		true,
		relationTypes.one
	)
	const textOptions = textFields?.length ? textFields : []
	const textOptionsWithEmpty = [{ label: '-', name: '' }, ...textOptions]

	const imageFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen?.data?.source,
		['Image'],
		null,
		true,
		relationTypes.one
	)
	const imageOptions = imageFields?.length ? imageFields : formulas.profile
	const imageOptionsWithEmpty = [{ label: '-', name: '' }, ...imageOptions]

	const [timer, setTimer] = useState(0)
	const [icon, setIcon] = useState('')
	const [screenName, setScreenName] = useState('')
	const [nameErrorText, setNameErrorText] = useState('')

	const onDragEnd = (result) => {
		const { destination } = result

		if (!destination) {
			return
		}

		const elements = reorder(
			props.activeScreen?.data?.header?.buttons?.right,
			result.source.index,
			result.destination.index
		)

		updateRightButtonsOrder(elements)
	}

	const updateRightButtonsOrder = (buttons) => {
		const modifiedScreenData = update(props.activeScreen?.data, {
			header: {
				buttons: {
					right: {
						$set: buttons
					}
				}
			}
		})

		props.updateActiveScreen(modifiedScreenData)
	}

	const iconChange = (val) => {
		const screen = props.activeScreen?.data
		props.updateScreenIcon(screen, props.activeScreen.screenGroup, val)
		setIcon(val)
	}

	const nameChange = (value) => {
		submitName(value)
		setScreenName(value)
	}

	const submitName = (name) => {
		const validation = props.generateScreenPath(
			name,
			props.activeScreen?.data?.source !== null
		)
		if (validation.isValid) {
			const screen = props.activeScreen?.data
			props.updateScreenName(
				screen,
				props.activeScreen.screenGroup,
				name,
				validation.generatedPath
			)
			setNameErrorText('')
		} else {
			setNameErrorText(validation.message)
		}
	}

	/****TITLE***************************/
	const [isTitleTextMode, setIsTitleTextMode] = useState(false)
	const [titleField, setTitleField] = useState('')
	const [titleDefault, setTitleDefault] = useState('')
	const [titleElementName, setTitleElementName] = useState(null)

	const titleChange = (data) => {
		if (isTitleTextMode) {
			submitTitle(data)
			setTitleDefault(data)
		} else {
			submitTitle(data?.value)
			setTitleField(data?.value)
		}
	}
	const submitTitle = (x) => {
		const selectedField = isTitleTextMode
			? null
			: textFields?.find((y) => y.name == x)

		const modifiedScreenData = update(props.activeScreen?.data, {
			header: {
				mapping: {
					title: {
						field: { $set: isTitleTextMode ? null : x },
						default: { $set: isTitleTextMode ? x : null },
						type: { $set: isTitleTextMode ? null : selectedField?.dataType }
					}
				}
			}
		})
		props.updateActiveScreen(modifiedScreenData)
	}

	/****TABLE***************************/
	const [table, setTable] = useState(null)
	const tables = props.entities?.map((x) => ({
		label: x.label,
		value: x.name
	}))
	const tableChange = (e) => {
		submitTable(e.target.value)
		setTable(e.target.value)
	}
	const submitTable = (tableName) => {
		if (tableName === '') tableName = null

		const validation = props.generateScreenPath(
			props.activeScreen?.data.name,
			tableName !== null
		)

		if (validation.isValid) {
			const screen = props.activeScreen?.data
			props.updateTable(
				screen,
				props.activeScreen.screenGroup,
				tableName,
				validation.generatedPath
			)
		} else {
			showToastError(validation.message)
		}
	}

	/****BUTTONS_RIGHT***************************/
	const changeRightButtonTextByIndex = (value, index) => {
		let modifiedScreenData

		if (props.activeScreen?.data?.header?.buttons?.right?.[index].mapping) {
			modifiedScreenData = update(props.activeScreen?.data, {
				header: {
					buttons: {
						right: {
							[index]: {
								mapping: {
									text: {
										default: { $set: value }
									}
								}
							}
						}
					}
				}
			})
		} else {
			const copyOfCurrent = {
				...props.activeScreen?.data.header.buttons.right[index],
				...{
					mapping: {
						text: {
							field: null,
							default: value,
							type: null
						}
					}
				}
			}
			modifiedScreenData = update(props.activeScreen?.data, {
				header: {
					buttons: {
						right: {
							[index]: {
								$set: copyOfCurrent
							}
						}
					}
				}
			})
		}

		props.updateActiveScreen(modifiedScreenData)
	}

	const changeRightButtonIconByIndex = (icon, index) => {
		const modifiedScreenData = update(props.activeScreen?.data, {
			header: {
				buttons: {
					right: {
						[index]: {
							icon: { $set: icon }
						}
					}
				}
			}
		})
		props.updateActiveScreen(modifiedScreenData)
	}
	const deleteRightButtonByIndex = (index) => {
		const modifiedScreenData = update(props.activeScreen?.data, {
			header: {
				buttons: {
					right: {
						$splice: [[index, 1]]
					}
				}
			}
		})
		props.updateActiveScreen(modifiedScreenData)
		setSelectedButtonIndex(null)
	}
	const addNewRightButton = () => {
		const modifiedScreenData = update(props.activeScreen?.data, {
			header: {
				buttons: {
					right: {
						$push: [
							{
								mapping: {
									text: {
										field: null,
										default: null,
										type: null
									}
								},
								icon: 'rocket',
								actions: [{ type: null }]
							}
						]
					}
				}
			}
		})
		props.updateActiveScreen(modifiedScreenData)
		setSelectedButtonIndex(
			props.activeScreen?.data?.header?.buttons?.right?.length
		)
	}
	const updateRightButtonActionsByIndex = (newActions, index) => {
		const modifiedScreenData = update(props.activeScreen?.data, {
			header: {
				buttons: {
					right: {
						[index]: {
							actions: { $set: newActions }
						}
					}
				}
			}
		})
		props.updateActiveScreen(modifiedScreenData)
	}

	const styleOptions = [
		{
			text: t('screens.screenOptions.styleOptions.screen'),
			value: 'screen',
			icon: STScreen
		},
		{
			text: t('screens.screenOptions.styleOptions.modal'),
			value: 'modal',
			icon: STModal
		},
		{
			text: t('screens.screenOptions.styleOptions.bottom'),
			value: 'bottom',
			icon: STTransparent
		},
		{
			text: t('screens.screenOptions.styleOptions.popup'),
			value: 'popup',
			icon: STPopup
		}
	]
	/****TYPE***************************/
	const [style, setStyle] = useState('screen')
	const styleChange = (val) => {
		submitStyle(val)
		setStyle(val)
	}
	const submitStyle = (val) => {
		const screen = props.activeScreen?.data
		props.updateScreenStyle(screen, props.activeScreen.screenGroup, val)
	}

	/****BACKGROUND_COLOR***************************/
	const colorList = [
		{
			color: '#F25757',
			name: '#F25757'
		},
		{
			color: '#9DA658',
			name: '#9DA658'
		},
		{
			color: '#1EBF92',
			name: '#1EBF92'
		},
		{
			color: '#06AFF2',
			name: '#06AFF2'
		},
		{
			color: '#A673D9',
			name: '#A673D9'
		}
	]

	const [backgroundColor, setBackgroundColor] = useState('')
	const [backgroundColorIndex, setBackgroundColorIndex] = useState(0)
	const [backgroundCustomColor, setBackgroundCustomColor] = useState('#BB0101')
	const [backgroundColorPickerVisible, setBackgroundColorPickerVisible] =
		useState(false)

	const submitBackgroundColor = (x) => {
		const modifiedScreenData = update(props.activeScreen?.data, {
			backgroundColor: { $set: x }
		})
		props.updateActiveScreen(modifiedScreenData)
	}

	const backgroundColorPickerRef = useRef(null)

	useOutsideClick(backgroundColorPickerRef, () => {
		setBackgroundColorPickerVisible(false)
	})

	/****BACKGROUND_IMAGE***************************/
	const [isBackgroundImageUrlTextMode, setIsBackgroundImageUrlTextMode] =
		useState(false)
	const [backgroundImageUrlField, setBackgroundImageUrlField] = useState('')
	const [backgroundImageUrlDefault, setBackgroundImageUrlDefault] = useState('')
	const [backgroundImageUrlDefaultValid, setBackgroundImageUrlDefaultValid] =
		useState(true)

	const backgroundImageUrlChange = (data) => {
		if (isBackgroundImageUrlTextMode) {
			const backgroundImageUrlRegExp = dataTypeRegExes.Image.re

			validateWithRegExp(data.target.value, backgroundImageUrlRegExp).then(
				(isValid) => {
					setBackgroundImageUrlDefaultValid(isValid)
					if (isValid) {
						timeoutDelay(submitBackgroundImageUrl, data.target.value, 1000)
					}
				}
			)

			setBackgroundImageUrlDefault(data.target.value)
		} else {
			submitBackgroundImageUrl(data?.value)
			setBackgroundImageUrlField(data?.value)
		}
	}
	const submitBackgroundImageUrl = (x) => {
		const selectedField = imageOptions?.find((y) => y.name == x)

		const modifiedScreenData = update(props.activeScreen?.data, {
			mapping: {
				backgroundImageUrl: {
					field: { $set: isBackgroundImageUrlTextMode ? null : x },
					default: { $set: isBackgroundImageUrlTextMode ? x : null },
					type: {
						$set: isBackgroundImageUrlTextMode ? null : selectedField?.dataType
					}
				}
			}
		})
		props.updateActiveScreen(modifiedScreenData)
	}

	/****SHOW_LOGO***************************/
	const [showLogo, setShowLogo] = useState(false)
	const showLogoChange = (e) => {
		submitShowLogo(e.target.checked)
		setShowLogo(e.target.checked)
	}
	const submitShowLogo = (checked) => {
		const modifiedScreenData = update(props.activeScreen?.data, {
			header: {
				showLogo: { $set: checked }
			}
		})
		props.updateActiveScreen(modifiedScreenData)
	}
	/****INITIALIZATION***************************/
	const initOptions = () => {
		/****BACKGROUND_COLOR***************************/
		const tempColorIndex = colorList.indexOf(
			colorList.filter(
				(x) => x.color == props?.activeScreen?.data?.backgroundColor
			)[0]
		)
		if (tempColorIndex > -1) {
			setBackgroundColorIndex(tempColorIndex)
			setBackgroundColor(colorList[tempColorIndex].color)
		} else {
			if (props?.activeScreen?.data?.backgroundColor) {
				setBackgroundColorIndex('custom')
				setBackgroundColor(props?.activeScreen?.data?.backgroundColor)
				setBackgroundCustomColor(props?.activeScreen?.data?.backgroundColor)
			} else {
				setBackgroundColorIndex('none')
				setBackgroundColor(null)
			}
		}
		/****BACKGROUND_IMAGE***************************/
		props?.activeScreen?.data?.mapping?.backgroundImageUrl?.field ||
		props?.activeScreen?.data?.mapping?.backgroundImageUrl?.field === ''
			? setIsBackgroundImageUrlTextMode(false)
			: setIsBackgroundImageUrlTextMode(true)
		setBackgroundImageUrlField(
			props?.activeScreen?.data?.mapping?.backgroundImageUrl?.field || ''
		)
		setBackgroundImageUrlDefault(
			props?.activeScreen?.data?.mapping?.backgroundImageUrl?.default || ''
		)

		setIcon(props?.activeScreen?.data?.icon || '')
		setStyle(props?.activeScreen?.data?.style || 'screen')
		setScreenName(props?.activeScreen?.data?.name || null)
		setTable(props?.activeScreen?.data?.source || '')
		setShowLogo(props?.activeScreen?.data?.header?.showLogo || false)

		/****TITLE***************************/
		props?.activeScreen?.data?.header?.mapping?.title?.field ||
		props?.activeScreen?.data?.header?.mapping?.title?.field === ''
			? setIsTitleTextMode(false)
			: setIsTitleTextMode(true)

		setTitleField(
			props?.activeScreen?.data?.header?.mapping?.title?.field || ''
		)
		setTitleDefault(
			props?.activeScreen?.data?.header?.mapping?.title?.default || ''
		)
		setTitleElementName(props?.activeScreen?.data?.key)

		if (!props?.activeScreen?.data?.mapping) {
			const modifiedScreenData = update(props.activeScreen?.data, {
				mapping: {
					$set: {
						backgroundImageUrl: {
							field: null,
							default: null,
							type: null
						}
					}
				}
			})
			props.updateActiveScreen(modifiedScreenData)
		}
	}

	useEffect(() => {
		props.activeScreen && initOptions()
		setNameErrorText('')
	}, [props.activeScreen])

	const [buttonPop, setButtonPop] = useState(null)
	const [selectedButtonIndex, setSelectedButtonIndex] = useState(0)

	const openButtonPop = (event, index) => {
		setSelectedButtonIndex(index)
		setButtonPop(event.currentTarget)
	}

	const closeButtonPop = () => {
		setButtonPop(null)
	}

	const buttonPopOpen = Boolean(buttonPop)

	return (
		icon && (
			<>
				<AppPageBox>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.screenOptions.label')}</Label>
						</Grid>
						<Grid item xs={8}>
							<Grid container>
								<Grid item xs={9} lg={9} style={{ paddingRight: 10 }}>
									<MentionsInput
										name={`${titleElementName}-name`}
										whitelist={[]}
										onChange={nameChange}
										value={screenName}
										multiline={false}
										inputStyle={{
											borderRadius: 4,
											backgroundColor: kozmikTheme.colors.kozmikGray,
											fontSize: '14px',
											color: kozmikTheme.colors.textPrimary
										}}
									/>
									{nameErrorText && (
										<p className={classes.errorText}>{nameErrorText}</p>
									)}
								</Grid>
								<Grid item xs={3} lg={3}>
									<IoniconsSelect
										fullWidth
										onChange={iconChange}
										value={icon}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.screenOptions.table')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.screenOptions.tableEmptyField')}
								className={classes.input}
								onChange={tableChange}
								value={table}
								data={tables}
								textKey='label'
								valueKey='value'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.screenOptions.title')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateMentions
								name={`${titleElementName}-title`}
								multiline={false}
								fullWidth={true}
								isTextMode={isTitleTextMode}
								setIsTextMode={setIsTitleTextMode}
								valChange={titleChange}
								textVal={titleDefault}
								autoCompVal={titleField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
					<Grid item>
						<div className={cx(classes.item)}>
							<div>
								<KozmikCheckBox checked={showLogo} onChange={showLogoChange} />
							</div>
							<div className={cx(classes.labelBox, classes.growBox)}>
								<Label>
									{t('screens.screenOptions.showLogo1')}
									<Link
										to={`/app/${appId}/settings/info`}
										className={classes.link}
									>
										{t('screens.screenOptions.showLogo2')}
									</Link>
									{t('screens.screenOptions.showLogo3')}
								</Label>
							</div>
						</div>
					</Grid>
				</AppPageBox>

				<AppPageBox>
					<Accordion
						title={t('screens.screenOptions.designTitle')}
						expanded={true}
					>
						<Grid
							item
							alignItems='center'
							className={classes.mb20}
							style={{
								display: 'flex',
								justifyContent: 'space-around'
							}}
						>
							<SquareOptions
								width={48}
								height={61}
								data={styleOptions}
								getChange={styleChange}
								activeValue={style}
							/>
						</Grid>
						<Grid
							container
							display={'flex'}
							className={classes.mb10}
							style={{
								justifyContent: 'space-between',
								alignItems: 'center'
							}}
						>
							<Grid item xs={12} lg={12} className={classes.mb10}>
								<Label>
									{t('screens.elementSettings.container.backgroundColor')}
								</Label>
							</Grid>
							<Grid
								item
								container
								xs={12}
								lg={12}
								display='flex'
								alignItems='center'
								justifyContent={'center'}
								style={{ position: 'relative' }}
							>
								<RadioButton
									className={classes.radio}
									checked={'none' == backgroundColorIndex}
									onClick={() => {
										setBackgroundColorIndex('none')
										submitBackgroundColor(null)
									}}
									icon={BrowserNotSupported}
								/>
								{colorList.map((item, index) => (
									<RadioButton
										className={classes.radio}
										checked={index == backgroundColorIndex}
										key={index}
										onClick={() => {
											setBackgroundColor(item.color)
											setBackgroundColorIndex(index)
											submitBackgroundColor(item.color)
										}}
										radioColor={item?.color}
										imgUrl={item?.imgUrl}
									/>
								))}
								<RadioButton
									className={classes.radio}
									checked={'custom' == backgroundColorIndex}
									onClick={() => {
										setBackgroundColorPickerVisible(true)
									}}
									imgUrl={colorpicker}
								/>
								{backgroundColorPickerVisible && (
									<div
										ref={backgroundColorPickerRef}
										className={classes.pickerPopover}
									>
										<ChromePicker
											disableAlpha
											color={backgroundColor ? backgroundColor : '#000000'}
											onChangeComplete={(color) => {
												setBackgroundColor(color.hex)
												setBackgroundColorIndex('custom')
												setBackgroundCustomColor(color.hex)
												submitBackgroundColor(color.hex)
											}}
										/>
									</div>
								)}
							</Grid>
						</Grid>
						<Grid container alignItems='center' className={classes.mb10}>
							<Grid item xs={12} className={classes.mb10}>
								<Label>{t('screens.screenOptions.backgroundImage')}</Label>
							</Grid>
							<Grid item xs={12}>
								<DoubleStateText
									fullWidth={true}
									textError={!backgroundImageUrlDefaultValid}
									isTextMode={isBackgroundImageUrlTextMode}
									setIsTextMode={setIsBackgroundImageUrlTextMode}
									valChange={backgroundImageUrlChange}
									textVal={backgroundImageUrlDefault}
									autoCompVal={backgroundImageUrlField}
									autoCompOptions={imageOptionsWithEmpty}
									uppyPicker
									uppyAllowedFileTypes={['image/*']}
									valueKey='name'
									labelKey='label'
								/>
							</Grid>
						</Grid>
					</Accordion>
				</AppPageBox>

				<AppPageBox>
					<Accordion
						title={t('screens.screenOptions.buttonsTitle')}
						expanded={true}
					>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId='droppable'>
								{(provided, snapshot) => (
									<div
										{...provided.droppableProps}
										ref={provided.innerRef}
										style={getListStyle(snapshot.isDraggingOver)}
									>
										{!!props.activeScreen?.data?.header?.buttons?.right
											?.length &&
											props.activeScreen?.data?.header?.buttons?.right.map(
												(item, index) => (
													<Draggable
														key={`droppable-button-${index}`}
														draggableId={`droppable-button-${index}`}
														index={index}
													>
														{(provided, snapshot) => (
															<div
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																style={getElementStyle(
																	snapshot.isDragging,
																	provided.draggableProps.style
																)}
															>
																<div className={classes.mb10} key={index}>
																	<AccordionItem
																		title={
																			item.mapping?.text?.default
																				? item.mapping.text.default
																				: actionTypes?.find(
																						(x) =>
																							x.value ===
																							item.actions?.[0]?.type
																				  )?.label ||
																				  t('screens.screenOptions.button')
																		}
																		expanded={selectedButtonIndex === index}
																		onChange={() => (event, newExpanded) => {
																			setSelectedButtonIndex(
																				selectedButtonIndex === index
																					? null
																					: index
																			)
																		}}
																		padding={8}
																		moreClick={(e) => openButtonPop(e, index)}
																	>
																		<ButtonOptions
																			name={`${titleElementName}-buttons`}
																			entities={props.entities}
																			activeScreen={props.activeScreen}
																			button={item}
																			buttonIndex={index}
																			changeButtonTextByIndex={
																				changeRightButtonTextByIndex
																			}
																			changeButtonIconByIndex={
																				changeRightButtonIconByIndex
																			}
																			updateButtonActionsByIndex={
																				updateRightButtonActionsByIndex
																			}
																		/>
																	</AccordionItem>
																</div>
															</div>
														)}
													</Draggable>
												)
											)}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>

						{props.activeScreen?.data?.header?.buttons?.right?.length < 2 && (
							<TextButton
								onClick={addNewRightButton}
								icon={AddBox}
								text={t('screens.screenOptions.addNewButton')}
							/>
						)}
					</Accordion>
				</AppPageBox>

				<VisibilityOptions
					block={props.activeScreen?.data}
					activeScreen={props.activeScreen}
					entities={props.entities}
					updateBlock={props.updateActiveScreen}
				/>

				<Popover
					open={buttonPopOpen}
					anchorEl={buttonPop}
					onClose={() => {
						closeButtonPop()
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
				>
					<Box className={classes.popoverBox}>
						<PopoverItem
							text={t('screens.screenOptions.buttonDelete')}
							onClick={() => {
								closeButtonPop()
								deleteRightButtonByIndex(selectedButtonIndex)
							}}
							color='#FF2619'
						>
							<DeleteOutlined
								className={classes.popoverIcon}
								style={{ color: '#FF2619', opacity: 1 }}
							/>
						</PopoverItem>
					</Box>
				</Popover>
			</>
		)
	)
}

export default ScreenOptions

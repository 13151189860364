import list from './schemas/list'
import table from './schemas/table'
import tileList from './schemas/tileList'
import reviews from './schemas/reviews'
import mapList from './schemas/mapList'
import carousel from './schemas/carousel'
import gallery from './schemas/gallery'
import header from './schemas/header'
import form from './schemas/form'
import imagePicker from './schemas/imagePicker'
import inputMail from './schemas/inputMail'
import inputNumeric from './schemas/inputNumeric'
import inputMoney from './schemas/inputMoney'
import inputPhone from './schemas/inputPhone'
import inputText from './schemas/inputText'
import titleCard from './schemas/titleCard'
import phoneButton from './schemas/phoneButton'
import emailButton from './schemas/emailButton'
import linkButton from './schemas/linkButton'
import noteBox from './schemas/noteBox'
import userInfo from './schemas/userInfo'
import logoutButton from './schemas/logoutButton'
import seperator from './schemas/seperator'
import textArea from './schemas/textArea'
import columns from './schemas/columns'
import image from './schemas/image'
import button from './schemas/button'
import buttonBar from './schemas/buttonBar'
import checkbox from './schemas/checkbox'
import inputCheckbox from './schemas/inputCheckbox'
import video from './schemas/video'
import filePicker from './schemas/filePicker'
import webView from './schemas/webView'
import map from './schemas/map'
import datePicker from './schemas/datePicker'
import choice from './schemas/choice'
import inputChoice from './schemas/inputChoice'
import calendarList from './schemas/calendarList'
import numberEntry from './schemas/numberEntry'

import chartLine from './schemas/chartLine'
import chartBar from './schemas/chartBar'
import chartPie from './schemas/chartPie'
import chartProgress from './schemas/chartProgress'
import progressBar from './schemas/progressBar'

import container from './schemas/container'
import barcode from './schemas/barcode'
import qrCode from './schemas/qrCode'

import numbers from './schemas/numbers'
import rating from './schemas/rating'
import chat from './schemas/chat'

import settingsButton from './schemas/settingsButton'
import filterModal from './schemas/filterModal'

export default {
	numbers,
	rating,
	chartLine,
	chartBar,
	chartPie,
	chartProgress,
	progressBar,
	list,
	table,
	columns,
	tileList,
	reviews,
	mapList,
	chat,
	carousel,
	container,
	gallery,
	header,
	form,
	imagePicker,
	inputMail,
	inputNumeric,
	inputMoney,
	inputPhone,
	inputText,
	titleCard,
	phoneButton,
	emailButton,
	linkButton,
	noteBox,
	userInfo,
	logoutButton,
	seperator,
	textArea,
	image,
	button,
	buttonBar,
	checkbox,
	inputCheckbox,
	video,
	filePicker,
	webView,
	map,
	datePicker,
	choice,
	inputChoice,
	calendarList,
	numberEntry,
	barcode,
	qrCode,
	settingsButton,
	filterModal
}

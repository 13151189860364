import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import {
	SwapVert,
	BrowserNotSupported,
	FilterAltOutlined,
	PlusOne
} from '@mui/icons-material'

import KozmikTextField from '../../../atoms/kozmikTextField/KozmikTextField'
import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Slider } from '../../../atoms/kozmikSlider/KozmikSlider'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import AccordionItem from '../../../atoms/accordionItem/AccordionItem'
import FilterList from '../../../molecules/filterList/FilterList'
import ActionList from '../../actionList/ActionList'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import defaultElementSchemas from '../../../../defaultElementSchemas'

import relationTypes from '../../../../common/enums/relationTypes'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	inputLight: {
		borderRadius: 3,
		backgroundColor: '#ffffff',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const Rating = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [elementName, setElementName] = useState(null)

	/****SOURCE***************************/
	const [source, setSource] = useState('')

	const numberFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		['Number', 'Rollup', 'Formula'],
		null,
		true,
		relationTypes.one
	)?.filter(
		(x) =>
			!x.formula || (x.formula && x.formula.formulaFieldDataType === 'Number')
	)

	const sourceChange = (e) => {
		submitSource(source, e.target.value)
		setSource(e.target.value)
	}
	const submitSource = (oldTableName, tableName) => {
		const table = props?.entities?.find((y) => y.name == tableName)

		const newElementData = defaultElementSchemas.rating(
			table,
			props.appDetail?.DataSource?.UsersTableName
		)

		let modifiedElementData = update(props.elementData, {
			source: { $set: newElementData.source },
			mapping: {
				text: {
					default: { $set: newElementData.mapping?.text?.default }
				},
				rating: {
					field: { $set: newElementData.mapping?.rating?.field },
					type: { $set: newElementData.mapping?.rating?.type }
				}
			},
			filters: {
				groupOp: { $set: newElementData.filters?.groupOp },
				rules: { $set: newElementData.filters?.rules }
			},
			actions: { $set: newElementData.actions }
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****TEXT***************************/
	const [text, setText] = useState('')

	const textChange = (e) => {
		timeoutDelay(submitText, e.target.value, 1000)
		setText(e.target.value)
	}

	const submitText = (x) => {
		const modifiedElementData = update(props.elementData, {
			mapping: {
				text: {
					default: { $set: x }
				}
			}
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****RATING***************************/
	const [rating, setRating] = useState('')

	const ratingChange = (e) => {
		submitRating(e.target.value)
		setRating(e.target.value)
	}

	const submitRating = (x) => {
		const selectedField = numberFields?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				rating: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****MAX_RATING***************************/
	const [maxRating, setMaxRating] = useState(5)

	const maxRatingChange = (e, newVal) => {
		if (typeof newVal === 'number') {
			submitMaxRating(newVal)
			setMaxRating(newVal)
		}
	}

	const submitMaxRating = (x) => {
		const modifiedElementData = update(props.elementData, {
			maxRating: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ACTIONS***************************/
	const updateActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			actions: { $set: newActions }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****LIMIT***************************/
	const [limit, setLimit] = useState(10)
	const limitChange = (val) => {
		timeoutDelay(submitLimit, val, 1000)
		setLimit(val)
	}
	const submitLimit = (x) => {
		const modifiedElementData = update(props.elementData, {
			limit: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****FILTER_&_SORT***************************/
	const [localElementData, setLocalElementData] = useState(null)

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****DATA***************************/
		setSource(elementData?.source ?? '')
		setLimit(elementData?.limit ?? 10)

		setText(elementData?.mapping?.text?.default || '')
		setRating(elementData?.mapping?.rating?.field || '')
		setMaxRating(elementData?.maxRating || 5)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
			setLocalElementData(props.elementData)
		}
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.source')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={12}>
							<SelectBox
								className={classes.input}
								onChange={sourceChange}
								value={source}
								data={props.entities}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>

					<div className={classes.mb10}>
						<AccordionItem
							icon={FilterAltOutlined}
							title={t('screens.elementSettings.rating.filter')}
							expanded={false}
							padding={8}
						>
							<FilterList
								filter={localElementData?.filters}
								table={props?.entities?.find(
									(y) => y.name == props?.elementData?.source
								)}
								activeScreen={props?.activeScreen}
								entities={props?.entities}
								tableName={props?.elementData?.source}
								screenTable={props?.entities?.find(
									(y) => y.name == props.activeScreen?.data?.source
								)}
								filtering={props.filtering}
								setLocalElementData={setLocalElementData}
							/>
						</AccordionItem>
					</div>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.rating.rating')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.rating.emptyField')}
								className={classes.input}
								onChange={ratingChange}
								value={rating}
								data={numberFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.rating.maxRating')}</Label>
						</Grid>
						<Grid
							item
							style={{
								display: 'flex',
								alignItems: 'center'
							}}
							xs={12}
							lg={8}
						>
							<Slider
								value={maxRating}
								min={1}
								step={1}
								max={10}
								onChange={maxRatingChange}
								aria-labelledby='non-linear-slider'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.rating.text')}</Label>
						</Grid>
						<Grid
							item
							style={{
								display: 'flex',
								alignItems: 'center'
							}}
							xs={12}
							lg={8}
						>
							<KozmikTextField fullWidth value={text} onChange={textChange} />
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.actions')}
					expanded={true}
				>
					{source && (
						<ActionList
							name={elementName}
							entities={props.entities}
							activeScreen={props.activeScreen}
							actions={props.elementData.actions}
							updateActions={updateActions}
							isList={true}
							listSource={source}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default Rating

import React from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

const CustomButton = styled((props) => {
	const { textColor, bgColor, ...restProps } = props
	return <Button {...restProps} />
})((props) => ({
	color: props?.textColor || '#000000',
	backgroundColor: props?.bgColor || props.theme.custom.colors.kozmikBlue,
	'&:hover': {
		backgroundColor: props?.bgColor || props.theme.custom.colors.kozmikBlue
	}
}))

const ColoredButton = (props) => {
	return <CustomButton {...props} />
}

export default ColoredButton

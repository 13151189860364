import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import makeStyles from '@mui/styles/makeStyles'
import { toast } from 'react-toastify'
import Cookies from 'universal-cookie'
import { /*useNavigate*/ useHistory } from 'react-router-dom'
import {
	CardMedia,
	TextField,
	Button,
	Typography,
	CircularProgress
} from '@mui/material'

import ColoredButton from '../../atoms/button/ColoredButton'
import authorizationClient from '../../../services/authorizationClient'
import { default as appConfig } from '../../../config/app.json'
import showToastResponseError from '../../../methods/showToastResponseError'

import KozmikLogoLight from '../../../assets/icons/KozmikLogoLight.png'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		padding: 27
	},
	contentContainer: {
		marginTop: '20vh',
		maxWidth: 374,
		width: 374,
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	subTitle: {
		marginBottom: 30,
		textAlign: 'center',
		fontSize: 14,
		fontWeight: '600',
		color: '#ffffff'
	},
	inputContainer: {
		marginBottom: 30
	},
	buttonContainer: {
		margin: '10px 0'
	},
	logoBox: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 80
	},
	logo: {
		width: 163,
		height: 'auto'
	},
	input: {
		'& .MuiOutlinedInput-root': {
			overflow: 'hidden',
			height: 50,
			backgroundColor: 'rgba(255, 255, 255, 0.6)',
			borderRadius: 8,
			fontSize: 14,
			fontWeight: 600
		}
	},
	button: {
		borderRadius: 8,
		boxShadow: 'none !important',
		textTransform: 'unset',
		height: 50,
		backgroundColor: `${theme.custom.colors.kozmikGreen} !important`
	},
	submitText: {
		fontSize: 16,
		fontWeight: 600,
		color: theme.custom.colors.kozmikDarkBlue
	},
	loader: {
		margin: '20px',
		color: theme.custom.colors.kozmikDarkBlue
	}
}))

const ForgotPassword = () => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [submitting, setSubmitting] = useState(false)

	const cookies = new Cookies()
	const history = useHistory()
	const navigate = (url) => {
		history.push(url)
	} //let navigate = useNavigate()
	const token = cookies.get(appConfig.cookieKeys.MY_SECURE_TOKEN_KEY)

	const {
		control,
		handleSubmit,
		formState: { errors, isValid }
	} = useForm({
		defaultValues: {
			email: ''
		}
	})
	const onSubmit = (data) => {
		setSubmitting(true)
		authorizationClient
			.forgotPassword(data)
			.then((response) => {
				if (response?.data?.success) {
					// toast.success(response?.data?.message)
					navigate('/login')
				} else {
					showToastResponseError(response)
				}
				setSubmitting(false)
			})
			.catch(() => {
				setSubmitting(false)
			})
	}

	const handleUserKeyPress = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			handleSubmit(onSubmit)()
		}
	}

	useEffect(() => {
		if (token && token !== 'undefined') {
			navigate('')
		}
	}, [])

	return (
		<div className={classes.mainContainer}>
			<div className={classes.contentContainer}>
				<div>
					<div className={classes.logoBox}>
						<a href='https://kozmik.io'>
							<CardMedia
								image={KozmikLogoLight}
								component='img'
								className={classes.logo}
							/>
						</a>
					</div>
					<h2 className={classes.subTitle}>
						{t('authScreens.forgotPassword.subTitle')}
					</h2>
				</div>
				<div className={classes.inputContainer}>
					<Controller
						control={control}
						name='email'
						rules={{
							required: {
								value: true,
								message: t('authScreens.forgotPassword.mailInput.requredError')
							},
							pattern: {
								value:
									/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: t('authScreens.forgotPassword.mailInput.patternError')
							}
						}}
						render={({ field: { onChange, value } }) => {
							return (
								<TextField
									className={classes.input}
									error={!!errors?.email}
									helperText={errors?.email ? errors?.email?.message : ''}
									placeholder={t(
										'authScreens.forgotPassword.mailInput.placeholder'
									)}
									fullWidth
									value={value}
									onChange={(e) => onChange(e.target.value)}
									onKeyPress={handleUserKeyPress}
								/>
							)
						}}
					/>
				</div>

				<div className={classes.buttonContainer}>
					<Button
						fullWidth
						variant='contained'
						className={classes.button}
						onClick={handleSubmit(onSubmit)}
						disabled={submitting}
						sx={{
							opacity: submitting ? 0.6 : 1
						}}
					>
						<Typography className={classes.submitText}>
							{t('authScreens.forgotPassword.acceptButton')}
						</Typography>
						{submitting && (
							<CircularProgress size={20} className={classes.loader} />
						)}
					</Button>
				</div>
				{/* <div className={classes.buttonContainer}>
					<ColoredButton
						onClick={() => navigate('/login')}
						variant='text'
						textColor={'#00EF91'}
						bgColor='transparent'
						size='large'
						fullWidth
						style={{
							textTransform: 'unset',
							fontSize: 16,
							fontWeight: 600
						}}
					>
						{t('authScreens.forgotPassword.cancelButton')}
					</ColoredButton>
				</div> */}
			</div>
		</div>
	)
}

export default ForgotPassword

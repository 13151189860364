import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from '@mui/material'
import cx from 'classnames'

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		width: 100,
		height: 'auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		padding: '7px 10px',
		position: 'relative'
	},
	aspectRatioContainer: {
		height: 0,
		width: '100%',
		paddingTop: '70.74%', // 147:104
		position: 'relative'
	},
	iconBox: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		'&:hover': {
			boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.17)'
		},
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.custom.colors.kozmikGray,
		borderRadius: '4px'
	},
	icon: {
		color: theme.custom.colors.textPrimary,
		width: '100%',
		height: '100%'
	},
	signText: {
		textAlign: 'center',
		color: theme.custom.colors.textPrimary,
		fontSize: 12
	},
	badge: {
		fontSize: 8,
		fontWeight: 'bold',
		borderRadius: 7.5,
		padding: '0 4px',
		position: 'absolute',
		top: 12,
		left: 15,
		backgroundColor: (props) =>
			props?.badgeColor ? props.badgeColor : '#78FFAA'
	}
}))

const ResponsiveCard = (props) => {
	const classes = useStyles({ badgeColor: props?.badgeColor })

	return (
		<div className={cx(classes.cardContainer, props.className)}>
			<div className={classes.aspectRatioContainer}>
				<div className={classes.iconBox} onClick={props?.onClick}>
					<img className={classes.icon} src={props.icon} alt='element image' />
					{/* <props.icon className={classes.icon} /> */}
				</div>
			</div>
			{!!props?.badgeText && (
				<span className={classes.badge}>{props.badgeText}</span>
			)}
			{!!props?.text && (
				<Typography className={classes.signText}>{props.text}</Typography>
			)}
		</div>
	)
}
export default ResponsiveCard

import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateText from '../../../molecules/doubleStateField/DoubleStateText'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import ActionList from '../../actionList/ActionList'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import validateWithRegExp from '../../../../methods/validateWithRegExp'
import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import dataTypeRegExes from '../../../../common/regExp/dataType'
import relationTypes from '../../../../common/enums/relationTypes'
import formulas from '../../../../common/formulas'

import { ReactComponent as ImageSquare } from '../../../../assets/icons/Settings/Square.svg'
import { ReactComponent as ImageRectangle } from '../../../../assets/icons/Settings/Rectangle.svg'
import { ReactComponent as ImageOriginal } from '../../../../assets/icons/Settings/Original.svg'
import { ReactComponent as ImageOdakli } from '../../../../assets/icons/Settings/Image-Odakli.svg'
import { ReactComponent as ImageOrtali } from '../../../../assets/icons/Settings/Image-Ortali.svg'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const Image = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [elementName, setElementName] = useState(null)

	const imageFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen?.data?.source,
		['Image'],
		null,
		true,
		relationTypes.one
	)
	const imageOptions = imageFields?.length ? imageFields : formulas.profile
	const imageOptionsWithEmpty = [{ label: '-', name: '' }, ...imageOptions]

	const appearanceOptions = [
		{
			text: t('screens.elementSettings.image.appearOpts.square'),
			value: 'square',
			icon: ImageSquare
		},
		{
			text: t('screens.elementSettings.image.appearOpts.rectangle'),
			value: 'rectangle',
			icon: ImageRectangle
		},
		{
			text: t('screens.elementSettings.image.appearOpts.full'),
			value: 'full',
			icon: ImageOriginal
		}
	]
	const resizeOptions = [
		{
			text: t('screens.elementSettings.image.resizeOpts.cover'),
			value: 'cover',
			icon: ImageOdakli
		},
		{
			text: t('screens.elementSettings.image.resizeOpts.contain'),
			value: 'contain',
			icon: ImageOrtali
		}
	]

	/****IMG_URL***************************/
	const [isImgUrlTextMode, setIsImgUrlTextMode] = useState(false)
	const [imgUrlField, setImgUrlField] = useState('')
	const [imgUrlDefault, setImgUrlDefault] = useState('')
	const [imgUrlDefaultValid, setImgUrlDefaultValid] = useState(true)

	const imgUrlChange = (data) => {
		if (isImgUrlTextMode) {
			const imgUrlRegExp = dataTypeRegExes.Image.re

			validateWithRegExp(data.target.value, imgUrlRegExp).then((isValid) => {
				setImgUrlDefaultValid(isValid)
				if (isValid) {
					timeoutDelay(submitImgUrl, data.target.value, 1000)
				}
			})

			setImgUrlDefault(data.target.value)
		} else {
			submitImgUrl(data?.value)
			setImgUrlField(data?.value)
		}
	}
	const submitImgUrl = (x) => {
		const selectedField = imageOptions?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				imgUrl: {
					field: { $set: isImgUrlTextMode ? null : x },
					default: { $set: isImgUrlTextMode ? x : null },
					type: { $set: isImgUrlTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****APPEARANCE***************************/
	const [appearance, setAppearance] = useState(null)

	const appearanceChange = (val) => {
		submitAppearance(val)
		setAppearance(val)
	}

	const submitAppearance = (x) => {
		const modifiedElementData = update(props.elementData, {
			appearance: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****IMAGE_RESIZE_MODE***************************/
	const [imageResizeMode, setImageResizeMode] = useState(null)

	const imageResizeModeChange = (val) => {
		submitImageResizeMode(val)
		setImageResizeMode(val)
	}

	const submitImageResizeMode = (x) => {
		const modifiedElementData = update(props.elementData, {
			imageResizeMode: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ACTIONS***************************/
	const updateActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			actions: { $set: newActions }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****IMG_URL***************************/
		elementData?.mapping?.imgUrl?.field ||
		elementData?.mapping?.imgUrl?.field === ''
			? setIsImgUrlTextMode(false)
			: setIsImgUrlTextMode(true)

		setImgUrlField(elementData?.mapping?.imgUrl?.field || '')
		setImgUrlDefault(elementData?.mapping?.imgUrl?.default || '')
		/****APPEARANCE***************************/
		setAppearance(elementData?.appearance)
		/****IMAGE_RESIZE_MODE***************************/
		setImageResizeMode(elementData?.imageResizeMode)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		props?.elementData && initStates(props.elementData)
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.image.image')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateText
								fullWidth={true}
								textError={!imgUrlDefaultValid}
								isTextMode={isImgUrlTextMode}
								setIsTextMode={setIsImgUrlTextMode}
								textModePlaceholder={t(
									'screens.elementSettings.image.textPlaceholder'
								)}
								valChange={imgUrlChange}
								textVal={imgUrlDefault}
								autoCompVal={imgUrlField}
								autoCompOptions={imageOptionsWithEmpty}
								uppyPicker
								uppyAllowedFileTypes={['image/*']}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.image.size')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={appearanceOptions}
								getChange={appearanceChange}
								activeValue={appearance}
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.image.imgUsage')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={resizeOptions}
								getChange={imageResizeModeChange}
								activeValue={imageResizeMode}
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.actions')}
					expanded={true}
				>
					<ActionList
						name={elementName}
						entities={props.entities}
						activeScreen={props.activeScreen}
						actions={props.elementData.actions}
						updateActions={updateActions}
					/>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default Image

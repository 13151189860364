import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { default as Label } from '../../atoms/settingLabel/SettingLabel'
import MentionsInput from '../../atoms/mentionsInput/MentionsInput'
import IoniconsSelect from '../IoniconsSelect/IoniconsSelect'
import ActionList from '../../organisms/actionList/ActionList'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	actionsTitle: {
		fontSize: 14,
		fontWeight: 'bold',
		marginBottom: 6
	}
}))

const ButtonOptions = (props) => {
	const { t } = useTranslation()
	const classes = useStyles(props)

	const [elementName, setElementName] = useState(null)
	const [icon, setIcon] = useState('')
	const [textDefault, setTextDefault] = useState('')

	const textChange = (value) => {
		props.changeButtonTextByIndex(value, props.buttonIndex)
		setTextDefault(value)
	}

	const initStates = (button) => {
		/****TEXT***************************/
		setTextDefault(
			button?.mapping?.text?.default ? button.mapping.text.default : ''
		)
		/****ICON***************************/
		setIcon(button?.icon || '')

		setElementName(`${props.name}-${props.buttonIndex}`)
	}

	useEffect(() => {
		props?.button && initStates(props.button)
	}, [props.button])

	return (
		<>
			<Grid container alignItems='center' className={classes.mb10}>
				<Grid item xs={4}>
					<Label>{t('screens.screenOptions.title')}</Label>
				</Grid>
				<Grid item xs={8}>
					<MentionsInput
						name={`${elementName}-text`}
						whitelist={[]}
						onChange={textChange}
						value={textDefault}
						multiline={false}
						inputStyle={{
							borderRadius: 4,
							backgroundColor: '#ffffff',
							fontSize: '14px'
						}}
					/>
				</Grid>
			</Grid>
			<Grid container alignItems='center' className={classes.mb10}>
				<Grid item xs={4}>
					<Label>{t('screens.screenOptions.icon')}</Label>
				</Grid>
				<Grid item xs={4}>
					<IoniconsSelect
						emptySelectable
						bgColor='#ffffff'
						onChange={(changedIcon) =>
							props.changeButtonIconByIndex(changedIcon, props.buttonIndex)
						}
						value={icon}
						fullWidth
					/>
				</Grid>
			</Grid>
			<Grid container alignItems='center' className={classes.mb10}>
				<Grid item xs={12}>
					<div className={classes.actionsTitle}>
						{t('screens.screenOptions.actions')}
					</div>
					<ActionList
						name={props.name}
						entities={props.entities}
						activeScreen={props.activeScreen}
						actions={props.button.actions}
						updateActions={(newActions) =>
							props.updateButtonActionsByIndex(newActions, props.buttonIndex)
						}
					/>
				</Grid>
			</Grid>
		</>
	)
}

export default ButtonOptions

import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Autocomplete, InputAdornment } from '@mui/material'
import { KeyboardArrowDown, TipsAndUpdatesOutlined } from '@mui/icons-material'

import BorderlessTextField from '../../atoms/borderlessTextField/BorderlessTextField'
import Dropdown from '../dropdown/Dropdown'

const useStyles = makeStyles((theme) => ({
	input: {
		backgroundColor: (props) => (props?.bgColor ? props.bgColor : '#FAFAFC'),
		borderRadius: '3px !important',
		border: 'none',
		'& .MuiInputBase-adornedStart': {
			paddingLeft: 0,
			overflow: 'hidden'
		}
	},
	autocomplete: {
		'& .MuiInputBase-adornedStart': {
			paddingLeft: '0px !important',
			overflow: 'hidden'
		}
	},
	inputSize: {
		borderRadius: '3px !important',
		backgroundColor: (props) => (props?.bgColor ? props.bgColor : '#FAFAFC'),
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	adornment: {
		height: 38,
		backgroundColor: '#F0F0F4',
		padding: '6px 10px',
		color: theme.custom.colors.textPrimary,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	adornmentActive: {
		height: 38,
		backgroundColor: theme.custom.colors.kozmikLightBlue,
		padding: '6px 10px',
		color: theme.custom.colors.kozmikBlue,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	inputIcon: {
		color: 'gray',
		fontSize: 16
	},
	inputIconActive: {
		color: theme.custom.colors.kozmikBlue,
		fontSize: 16
	},
	errorText: {
		color: '#d32f2f',
		fontSize: 12,
		margin: 0
	}
}))

const DoubleStateNumber = (props) => {
	const {
		textError,
		errorText,
		valChange,
		textVal,
		autoCompOptions,
		autoCompVal,
		autoCompEmptyText,
		isTextMode,
		setIsTextMode,
		valueKey,
		labelKey,
		callback,
		undefinedText,
		bgColor,
		icon,
		textModePlaceholder,
		...restProps
	} = props

	const classes = useStyles(props)

	const [open, setOpen] = useState(false)
	const closeDropdown = () => setOpen(false)
	const openDropdown = () => setOpen(true)

	const switchButton = (textMode, callbackFunc = null) => {
		setIsTextMode(textMode)
		if (callbackFunc) {
			if (textMode) {
				callbackFunc(textMode, textVal)
			} else {
				callbackFunc(textMode, autoCompVal)
			}
		}
	}

	return isTextMode ? (
		<>
			<BorderlessTextField
				type='number'
				size='small'
				variant='outlined'
				placeholder={textModePlaceholder || ''}
				error={textError}
				value={textVal}
				onChange={valChange}
				className={classes.input}
				InputProps={{
					classes: {
						input: classes.inputSize
					},
					startAdornment: (
						<InputAdornment position='start'>
							<Box
								className={classes.adornmentActive}
								onClick={() => switchButton(false, callback ? callback : null)}
							>
								{icon ? (
									<props.icon className={classes.inputIconActive} />
								) : (
									<TipsAndUpdatesOutlined className={classes.inputIconActive} />
								)}
							</Box>
						</InputAdornment>
					)
				}}
				{...restProps}
			/>
			{textError && (
				<p className={classes.errorText}>
					{errorText ? errorText : 'hatalı giriş' /*TODO:Localization*/}
				</p>
			)}
		</>
	) : (
		<Dropdown
			bgColor={bgColor}
			onItemClick={valChange}
			value={autoCompVal}
			data={autoCompOptions}
			textKey={labelKey}
			valueKey={valueKey}
			groupKey={labelKey}
			groupKeySeperator='.'
			startAdornment={
				<Box
					className={classes.adornment}
					onClick={() => switchButton(true, callback ? callback : null)}
				>
					{icon ? (
						<props.icon className={classes.inputIcon} />
					) : (
						<TipsAndUpdatesOutlined className={classes.inputIcon} />
					)}
				</Box>
			}
		/>
	)
}
export default DoubleStateNumber

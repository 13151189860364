import getFilterOpByDataType from './getFilterOpByDataType'

const getFieldDataType = (fieldName, fields) => {
	if (fieldName) {
		const fieldData = fields?.find((x) => x.name == fieldName)
		const fieldDataType =
			fieldData?.dataType === 'Relation'
				? fieldData?.dataType
				: fieldData?.formula?.formulaFieldDataType ??
				  fieldData?.displayFieldDataType ??
				  fieldData?.dataType

		const filterOps = getFilterOpByDataType(fieldDataType)

		return filterOps?.type ? filterOps.type : null
	} else {
		return null
	}
}

export default getFieldDataType

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { Grid } from '@mui/material'

import SelectBox from '../selectBox/SelectBox'
import SquareOptions from '../squareOprions/SquareOptions'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	inputLight: {
		borderRadius: 3,
		backgroundColor: '#ffffff',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const Sorting = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	/****SORTING_FIELD***************************/
	const [sortingField, setSortingField] = useState('')

	const sortingFieldChange = (e) => {
		if (props.index === undefined) {
			props.sorting.updateField(e.target.value)
		} else {
			props.sorting.updateField(
				e.target.value,
				props.index,
				props.feature === undefined ? null : props.feature
			)
		}
		setSortingField(e.target.value)
	}

	/****SORTING_SORT***************************/
	const [sortingSort, setSortingSort] = useState(null)

	const sortingSortChange = (val) => {
		if (props.index === undefined) {
			props.sorting.updateSort(val)
		} else {
			props.sorting.updateSort(
				val,
				props.index,
				props.feature === undefined ? null : props.feature
			)
		}
		setSortingSort(val)
	}

	/****INITIALIZATION***************************/
	const initStates = (sortingData) => {
		/****SORTING_FIELD***************************/
		setSortingField(sortingData?.field ? sortingData.field : '')
		/****SORTING_SORT***************************/
		setSortingSort(sortingData?.sort ? sortingData.sort : 'asc')
	}

	useEffect(() => {
		if (props?.sortingData) {
			initStates(props.sortingData)
		}
	}, [props.sortingData])

	return (
		<Grid container alignItems='center'>
			<Grid item xs={12} lg={6} className={classes.mb10}>
				<SelectBox
					emptyText={t('screens.elementSettings.sorting.emptyField')}
					className={classes.inputLight}
					onChange={sortingFieldChange}
					value={sortingField}
					data={props.fieldOptions}
					textKey='label'
					valueKey='name'
				/>
			</Grid>
			<Grid item xs={12} lg={6} className={classes.mb10}>
				<SquareOptions
					compact
					boxColor='#ffffff'
					data={props.sorting.options}
					getChange={sortingSortChange}
					activeValue={sortingSort}
				/>
			</Grid>
		</Grid>
	)
}

export default Sorting

import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import KozmikTextField from '../../../atoms/kozmikTextField/KozmikTextField'
import { setDetail } from '../../../../redux/actions/appDataActions'

const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%',
		paddingTop: 20,
		paddingBottom: 20
	},
	label: {
		fontSize: 14,
		fontWeight: 600,
		marginBottom: 5
	}
}))

const KozmikTablesAPI = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	let { appId } = useParams()

	const [kozmikApiKey, setKozmikApiKey] = useState('')

	const initialization = () => {
		if (props.appDetail?.APIKey) {
			setKozmikApiKey(props.appDetail.APIKey)
		}
	}

	/****INITIALIZATION***************************/
	useEffect(() => {
		props.appDetail && initialization()
	}, [props.appDetail])

	return (
		<div className={classes.container}>
			<Typography className={classes.label}>
				{t('appIntegrationsTemp.details.kozmikTablesAPI.apiKey')}
			</Typography>
			<KozmikTextField
				fullWidth
				disabled
				value={kozmikApiKey}
				sx={{
					'& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
						{
							border: 0
						}
				}}
			/>
		</div>
	)
}

const mapStateToProps = (state) => ({
	...state.appData
})

const mapDispatchToProps = (dispatch) => {
	return {
		setDetail: (x) => dispatch(setDetail(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(KozmikTablesAPI)

import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Grid, Box } from '@mui/material'
import {
	AddBox,
	ArrowDownward,
	ArrowUpward,
	ArrowForwardIos,
	CleaningServicesSharp
} from '@mui/icons-material'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'
// import { v4 as uuidv4 } from 'uuid'
import cx from 'classnames'
import { useParams } from 'react-router-dom'

import { connect } from 'react-redux'
import { setConfig } from '../../../redux/actions/appDataActions'

import Label from '../../atoms/label/Label'
import AppPageBox from '../../atoms/appPageBox/AppPageBox'
import ScreenLists from '../../molecules/screenLists/ScreenLists'
import ScreenOptions from '../../molecules/screenOptions/ScreenOptions'
import ScreenElementList from '../../molecules/screenElementList/ScreenElementList'
import ElementAddList from '../../molecules/elementAddList/ElementAddList'
import ElementSettings from '../../organisms/elementSettings/ElementSettings'
import filterOperators from '../../../common/filterOperators'

import showToastError from '../../../methods/showToastError'
import showToastResponseError from '../../../methods/showToastResponseError'
import appMethods from '../../../methods/app'
import Screen from '../../../objects/Screen'
import postMessageToPreview from '../../../methods/postMessageToPreview'
import getValueType from '../../../methods/getValueType'
import elementNames from '../../../common/enums/elementNames'

import appClient from '../../../services/appClient'

import { default as kozmikTheme } from '../../../assets/theme/theme.json'
import KozmikModal from '../../molecules/kozmikModal/KozmikModal'

import Onboarding from '../../organisms/onboarding/Onboarding'
import OnboardingMobile from '../../organisms/onboarding/OnboardingMobile'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		height: '100%',
		justifyContent: 'space-between',
		[theme.breakpoints.down('md')]: {
			height: 'auto'
		}
	},
	sectionContainer: {
		backgroundColor: '#ffffff',
		height: '100%',
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		flexWrap: 'nowrap',
		borderRadius: 16,
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.03)'
	},
	spaceContainer: {
		height: 0,
		[theme.breakpoints.down('md')]: {
			visibility: 'hidden',
			height: '0px !important',
			width: '0px !important',
			order: 2
		}
	},
	leftSectionPadding: {
		height: '100%',
		padding: '10px 0 10px 0',
		[theme.breakpoints.down('md')]: {
			padding: '10px 5px 10px 10px'
		},
		[theme.breakpoints.down('sm')]: {
			padding: '10px'
		}
	},
	rightSectionPadding: {
		height: '100%',
		padding: '10px 10px 10px 0',
		[theme.breakpoints.down('md')]: {
			padding: '10px 10px 10px 5px'
		},
		[theme.breakpoints.down('sm')]: {
			padding: '10px'
		}
	},
	titleBox: {
		width: '100%',
		padding: '10px 15px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		minHeight: 45
	},
	screenTitleBox: {
		width: '100%',
		padding: '10px 15px',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'flex-start',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		minHeight: 45
	},
	title: {
		color: '#000000',
		fontSize: 14,
		lineHeight: '22px',
		textAlign: 'left'
	},
	forwardIcon: {
		fontSize: 14,
		color: theme.custom.colors.middleGray
	},
	pointer: {
		cursor: 'pointer'
	},
	semiBold: {
		fontWeight: '600'
	},
	bold: {
		fontWeight: 'bold'
	},
	colorGray: {
		color: `#737374`
	},
	borderBottom: {
		borderBottom: `1px solid ${kozmikTheme.colors.gray}`
	},
	mb10: {
		marginBottom: 10
	},
	button: {
		backgroundColor: kozmikTheme.colors.kozmikDarkGray,
		borderRadius: 6,
		padding: '4px 10px'
	},
	activeButton: {
		borderRadius: 6,
		padding: '4px 10px',
		backgroundColor: kozmikTheme.colors.kozmikLightBlue,
		color: theme.custom.colors.kozmikBlue
	},
	zeroBottomPadding: {
		paddingBottom: 0
	}
}))

const Screens = (props) => {
	let { appId } = useParams()
	const { t } = useTranslation()
	const classes = useStyles()

	const {
		entities,
		setEntities,
		activePreviewRoute,
		setActivePreviewRoute,
		activeScreen,
		setActiveScreen,
		activeElement,
		setActiveElement,
		activeParentElement,
		setActiveParentElement,
		rightContentType,
		setRightContentType,
		activeMenuEvent,
		...restProps
	} = props

	// const [activeScreen, setActiveScreen] = useState(null)
	// const [activeElement, setActiveElement] = useState(null)
	// const [activeParentElement, setActiveParentElement] = useState(null)
	// const [rightContentType, setRightContentType] = useState(null)
	const [addElementModalVisible, setAddElementModalVisible] = useState(false)
	const [showAllScreens, setShowAllScreens] = useState(false)
	const [isOnboardingVisible, setIsOnboardingVisible] = useState(false)

	const allScreens = props.appConfig?.screens
		? [
				...props.appConfig.screens.tabs,
				...props.appConfig.screens.drawers,
				...props.appConfig.screens.hiddens
		  ]
		: []

	const hideAddElementModal = () => {
		setAddElementModalVisible(false)
	}

	const getRightSectionContent = (type) => {
		switch (type) {
			case 'screenSettings':
				return (
					((props.builderOnMobile && !showAllScreens) ||
						!props.builderOnMobile) && (
						<>
							<div className={cx(classes.screenTitleBox, classes.borderBottom)}>
								<span className={cx(classes.title, classes.bold)}>
									{t('screens.screenSettingsTitle')}
								</span>
							</div>

							<ScreenOptions
								entities={entities}
								linkingScreens={props.appConfig?.linking?.config?.screens}
								activeScreen={activeScreen}
								updateScreenIcon={updateScreenIcon}
								updateScreenStyle={updateScreenStyle}
								updateScreenName={updateScreenName}
								updateTable={updateTable}
								generateScreenPath={generateScreenPath}
								updateActiveScreen={updateActiveScreen}
								updateBulkScreensWithModifiedData={
									updateBulkScreensWithModifiedData
								}
							/>
						</>
					)
				)

			case 'elementSettings':
				return (
					((props.builderOnMobile && !showAllScreens) ||
						!props.builderOnMobile) && (
						<>
							<div className={cx(classes.screenTitleBox, classes.borderBottom)}>
								<span className={cx(classes.title, classes.bold)}>
									{elementNames?.[activeElement?.component]}{' '}
									{t('screens.settingsTitle')}
								</span>
							</div>

							<ElementSettings
								allScreens={allScreens}
								entities={entities}
								activeScreen={activeScreen}
								activeElement={activeElement}
								activeParentElement={activeParentElement}
								updateActiveScreen={updateActiveScreen}
								updateSelectedElement={updateSelectedElement}
								updateBulkScreensWithModifiedData={
									updateBulkScreensWithModifiedData
								}
								sorting={sorting}
								filtering={filtering}
								linkingObject={props.appConfig?.linking?.config?.screens}
							/>
						</>
					)
				)
			case 'newElement':
				return renderAddElementList()
			case 'newContainerElement':
				return renderAddElementList()
			default:
				return null
		}
	}

	/****SORTING_START***************************/
	const sortingOptions = [
		{
			value: 'asc',
			icon: ArrowUpward
		},
		{
			value: 'desc',
			icon: ArrowDownward
		}
	]

	const updateSortingField = (x, index = null, feature = null) => {
		let modifiedElementData

		if (index === null) {
			modifiedElementData = update(activeElement, {
				sorting: {
					field: { $set: x || null }
				}
			})
		} else {
			if (feature) {
				modifiedElementData = update(activeElement, {
					[feature]: {
						body: {
							[index]: {
								sorting: {
									field: { $set: x || null }
								}
							}
						}
					}
				})
			} else {
				modifiedElementData = update(activeElement, {
					body: {
						[index]: {
							sorting: {
								field: { $set: x || null }
							}
						}
					}
				})
			}
		}
		updateSelectedElement(modifiedElementData)
	}

	const updateSortingSort = (x, index = null, feature = null) => {
		let modifiedElementData

		if (index === null) {
			modifiedElementData = update(activeElement, {
				sorting: {
					sort: { $set: x || 'asc' }
				}
			})
		} else {
			if (feature) {
				modifiedElementData = update(activeElement, {
					[feature]: {
						body: {
							[index]: {
								sorting: {
									sort: { $set: x || 'asc' }
								}
							}
						}
					}
				})
			} else {
				modifiedElementData = update(activeElement, {
					body: {
						[index]: {
							sorting: {
								sort: { $set: x || 'asc' }
							}
						}
					}
				})
			}
		}

		updateSelectedElement(modifiedElementData)
	}

	const sorting = {
		options: sortingOptions,
		updateField: updateSortingField,
		updateSort: updateSortingSort
	}
	/****SORTING_END***************************/

	/****FILTERING_START***************************/
	const addNewFilterRule = (table, index = null, feature = null) => {
		if (table?.fields?.length) {
			const firstField = table.fields.find((x) => x.name != 'Id')
			const filterOp = filterOperators.find((x) =>
				x.dataTypes.includes(firstField.dataType)
			)

			const newRule = {
				type: 'Field',
				dataType: firstField?.dataType,
				field: firstField?.name,
				op: filterOp?.searchOp ?? 'eq',
				data: firstField?.name
			}

			let modifiedElementData

			if (index === null) {
				modifiedElementData = update(activeElement, {
					filters: {
						rules: { $push: [newRule] }
					}
				})
			} else {
				if (feature) {
					modifiedElementData = update(activeElement, {
						[feature]: {
							body: {
								[index]: {
									filters: {
										rules: { $push: [newRule] }
									}
								}
							}
						}
					})
				} else {
					modifiedElementData = update(activeElement, {
						body: {
							[index]: {
								filters: {
									rules: { $push: [newRule] }
								}
							}
						}
					})
				}
			}
			updateSelectedElement(modifiedElementData)
		} else {
			console.log('Table has no fields')
		}
	}

	const updateRuleField = (
		ruleIndex,
		dataType,
		value,
		filterOp,
		index = null,
		feature = null,
		ruleType = 'Field',
		dataValue = null
	) => {
		let modifiedElementData

		if (index === null) {
			modifiedElementData = update(activeElement, {
				filters: {
					rules: {
						[ruleIndex]: {
							type: { $set: ruleType },
							dataType: { $set: dataType },
							field: { $set: value },
							op: { $set: filterOp },
							data: { $set: dataValue !== null ? dataValue : value }
						}
					}
				}
			})
		} else {
			if (feature) {
				modifiedElementData = update(activeElement, {
					[feature]: {
						body: {
							[index]: {
								filters: {
									rules: {
										[ruleIndex]: {
											type: { $set: ruleType },
											field: { $set: value },
											op: { $set: filterOp },
											data: {
												$set: dataValue !== null ? dataValue : value
											}
										}
									}
								}
							}
						}
					}
				})
			} else {
				modifiedElementData = update(activeElement, {
					body: {
						[index]: {
							filters: {
								rules: {
									[ruleIndex]: {
										type: { $set: ruleType },
										field: { $set: value },
										op: { $set: filterOp },
										data: {
											$set: dataValue !== null ? dataValue : value
										}
									}
								}
							}
						}
					}
				})
			}
		}

		updateSelectedElement(modifiedElementData)
		return modifiedElementData
	}

	const updateRuleOp = (ruleIndex, value, index = null, feature = null) => {
		let modifiedElementData

		if (index === null) {
			modifiedElementData = update(activeElement, {
				filters: {
					rules: {
						[ruleIndex]: {
							op: { $set: value }
						}
					}
				}
			})
		} else {
			if (feature) {
				modifiedElementData = update(activeElement, {
					[feature]: {
						body: {
							[index]: {
								filters: {
									rules: {
										[ruleIndex]: {
											op: { $set: value }
										}
									}
								}
							}
						}
					}
				})
			} else {
				modifiedElementData = update(activeElement, {
					body: {
						[index]: {
							filters: {
								rules: {
									[ruleIndex]: {
										op: { $set: value }
									}
								}
							}
						}
					}
				})
			}
		}

		updateSelectedElement(modifiedElementData)
		return modifiedElementData
	}

	const updateRuleTypeAndData = (
		ruleIndex,
		data,
		ruleType,
		index = null,
		feature = null
	) => {
		let modifiedElementData

		if (index === null) {
			modifiedElementData = update(activeElement, {
				filters: {
					rules: {
						[ruleIndex]: {
							type: { $set: getValueType(data, ruleType) },
							data: { $set: data }
						}
					}
				}
			})
		} else {
			if (feature) {
				modifiedElementData = update(activeElement, {
					[feature]: {
						body: {
							[index]: {
								filters: {
									rules: {
										[ruleIndex]: {
											type: { $set: getValueType(data, ruleType) },
											data: { $set: data }
										}
									}
								}
							}
						}
					}
				})
			} else {
				modifiedElementData = update(activeElement, {
					body: {
						[index]: {
							filters: {
								rules: {
									[ruleIndex]: {
										type: { $set: getValueType(data, ruleType) },
										data: { $set: data }
									}
								}
							}
						}
					}
				})
			}
		}

		updateSelectedElement(modifiedElementData)
		return modifiedElementData
	}

	const updateGroupOp = (value, index = null, feature = null) => {
		let modifiedElementData

		if (index === null) {
			modifiedElementData = update(activeElement, {
				filters: {
					groupOp: { $set: value }
				}
			})
		} else {
			if (feature) {
				modifiedElementData = update(activeElement, {
					[feature]: {
						body: {
							[index]: {
								filters: {
									groupOp: { $set: value }
								}
							}
						}
					}
				})
			} else {
				modifiedElementData = update(activeElement, {
					body: {
						[index]: {
							filters: {
								groupOp: { $set: value }
							}
						}
					}
				})
			}
		}

		updateSelectedElement(modifiedElementData)
		return modifiedElementData
	}

	const deleteRule = (ruleIndex, index = null, feature = null) => {
		let modifiedElementData

		if (index === null) {
			modifiedElementData = update(activeElement, {
				filters: {
					rules: {
						$splice: [[ruleIndex, 1]]
					}
				}
			})
		} else {
			if (feature) {
				modifiedElementData = update(activeElement, {
					[feature]: {
						body: {
							[index]: {
								filters: {
									rules: {
										$splice: [[ruleIndex, 1]]
									}
								}
							}
						}
					}
				})
			} else {
				modifiedElementData = update(activeElement, {
					body: {
						[index]: {
							filters: {
								rules: {
									$splice: [[ruleIndex, 1]]
								}
							}
						}
					}
				})
			}
		}

		updateSelectedElement(modifiedElementData)
		return modifiedElementData
	}

	const filtering = {
		addNewRule: addNewFilterRule,
		updateRuleField: updateRuleField,
		updateRuleOp: updateRuleOp,
		updateRuleTypeAndData: updateRuleTypeAndData,
		updateGroupOp: updateGroupOp,
		deleteRule: deleteRule
	}
	/****FILTERING_END***************************/

	const getActiveScreen = (activeRoute, screens) => {
		let screen = {
			route: activeRoute.route,
			data: null,
			screenGroup: null,
			screenGroupIndex: null
		}

		const current = allScreens.find((x) => x.key === activeRoute.route.name)
		screen.data = current
		const screenGroup = getScreenGroup(current, screens)
		screen.screenGroup = screenGroup

		const groupScreens = screens[screenGroup]
		const index = groupScreens.findIndex((x) => x.key === current.key)

		screen.screenGroupIndex = index

		return screen
	}

	const getScreenGroup = (screen, screens) => {
		//NOTE: not for nested child routes
		const screenGroups = ['tabs', 'drawers', 'hiddens']
		let screenGroup = null

		for (let i = 0; i < screenGroups.length; i++) {
			const groupItems = screens[screenGroups[i]]
			for (let j = 0; j < groupItems.length; j++) {
				const element = groupItems[j]
				if (screen == element) {
					screenGroup = screenGroups[i]
					break
				}
			}
			if (screenGroup) {
				break
			}
		}

		return screenGroup
	}

	const navigatePreview = (target) => {
		postMessageToPreview({ source: 'kozmik-screens', navigate: target })
		setShowAllScreens(false)
	}

	const scrollToPreviewElement = (id) => {
		props.previewButtonIndex != 2 &&
			postMessageToPreview({ source: 'kozmik-screens', scrollToId: id })
	}

	const submitPreviewConfig = (configData) => {
		const generatedScreenCount = Object.keys(
			configData.linking?.config?.screens
		)?.length

		if (generatedScreenCount < 2) {
			setActiveScreen(null)
			setRightContentType(null)
		}

		postMessageToPreview({
			source: 'kozmik-screens',
			configData: configData
		})

		appClient.config(appId, configData).then((response) => {
			if (!response?.data?.success) {
				showToastResponseError(response)
			}
		})
	}

	const updateScreenIcon = (screen, screenGroup, icon) => {
		const index = props.appConfig.screens[screenGroup].findIndex(
			(x) => x.key === screen.key
		)

		const modifiedData = update(props.appConfig.screens[screenGroup], {
			[index]: {
				icon: { $set: icon }
			}
		})

		updateScreenGroup(screenGroup, modifiedData)
	}

	const updateScreenStyle = (screen, screenGroup, style) => {
		const index = props.appConfig.screens[screenGroup].findIndex(
			(x) => x.key === screen.key
		)
		const modifiedData = update(props.appConfig.screens[screenGroup], {
			[index]: {
				style: { $set: style }
			}
		})

		updateScreenGroup(screenGroup, modifiedData)
	}

	const updateTable = (screen, screenGroup, tableName, path) => {
		const index = props.appConfig.screens[screenGroup].findIndex(
			(x) => x.key === screen.key
		)
		const modifiedScreens = update(props.appConfig.screens, {
			[screenGroup]: {
				[index]: {
					source: { $set: tableName },
					path: { $set: path }
				}
			}
		})

		const newLinkingObj = modifiedScreens[screenGroup][index].path

		let modifiedLinking = update(props.appConfig?.linking, {
			config: {
				screens: { $unset: [screen.key] }
			}
		}) //NOTE:deletes old linking object
		modifiedLinking = update(modifiedLinking, {
			config: { screens: { [screen.key]: { $set: newLinkingObj } } }
		}) //NOTE:adds new linking object

		setAppConfig(modifiedLinking, modifiedScreens)
		// resetPreviewNav(key)
	}

	const updateScreenName = (screen, screenGroup, name, path) => {
		const index = props.appConfig.screens[screenGroup].findIndex(
			(x) => x.key === screen.key
		)

		const modifiedScreens = update(props.appConfig.screens, {
			[screenGroup]: {
				[index]: {
					name: { $set: name },
					path: { $set: path }
				}
			}
		})

		const newLinkingObj = modifiedScreens[screenGroup][index].path

		let modifiedLinking = update(props.appConfig?.linking, {
			config: {
				screens: { $unset: [screen.key] }
			}
		}) //NOTE:deletes old linking object
		modifiedLinking = update(modifiedLinking, {
			config: { screens: { [screen.key]: { $set: newLinkingObj } } }
		}) //NOTE:adds new linking object

		setAppConfig(modifiedLinking, modifiedScreens)
		// resetPreviewNav(key)
	}

	const updateActiveScreen = (modifiedScr) => {
		//NOTE: linking güncellemeye gerek olmayan değişiklikler için
		const modifiedScreens = getScreensByModifiedScreen(modifiedScr)

		setScreens(modifiedScreens)

		props.builderOnMobile && setAddElementModalVisible(false)
	}

	const getScreensByModifiedScreen = (modifiedScr) => {
		//NOTE: linking güncellemeye gerek olmayan değişiklikler için
		const modifiedScreens = update(props.appConfig?.screens, {
			[activeScreen?.screenGroup]: {
				[activeScreen?.screenGroupIndex]: { $set: modifiedScr }
			}
		})

		return modifiedScreens
	}

	const generateScreenPath = (name, idPath = false) => {
		return appMethods.generateScreenPathByName(
			allScreens,
			activeScreen?.data.key,
			name,
			idPath
		)
	}

	const updateScreenGroup = (screenGroup, data) => {
		//TODO:linking güncellenmeli
		const modifiedData = update(props.appConfig, {
			screens: {
				[screenGroup]: {
					$set: data
				}
			}
		})
		props.setConfig(modifiedData)
		submitPreviewConfig(modifiedData)
	}

	const setAppConfig = (linking, screens) => {
		let modifiedData = update(props.appConfig, {
			screens: { $set: screens }
		})
		modifiedData = update(modifiedData, {
			linking: { $set: linking }
		})

		props.setConfig(modifiedData)
		submitPreviewConfig(modifiedData)
	}

	const setScreens = (screens) => {
		const modifiedData = update(props.appConfig, {
			screens: { $set: screens }
		})

		props.setConfig(modifiedData)
		submitPreviewConfig(modifiedData)
	}

	const updateScreenElements = (screen, screenGroup, elements) => {
		const index = props.appConfig.screens[screenGroup].findIndex(
			(x) => x.key === screen.key
		)

		const modifiedScreens = update(props.appConfig.screens, {
			[screenGroup]: {
				[index]: {
					body: { $set: elements }
				}
			}
		})

		setScreens(modifiedScreens)
	}

	const updateSelectedElement = (element) => {
		if (activeParentElement) {
			const index = activeParentElement.body?.findIndex(
				(x) => x._uid == element?._uid
			)

			const modifiedParentElement = update(activeParentElement, {
				body: {
					[index]: {
						$set: element
					}
				}
			})

			const modifiedScreens = appMethods.getModifiedScreensByElement(
				props.appConfig.screens,
				activeScreen,
				activeParentElement,
				modifiedParentElement
			)

			setActiveParentElement(modifiedParentElement)
			setActiveElement(element)

			setScreens(modifiedScreens)
		} else {
			const modifiedScreens = appMethods.getModifiedScreensByElement(
				props.appConfig.screens,
				activeScreen,
				activeElement,
				element
			)

			setActiveElement(element)

			setScreens(modifiedScreens)
		}
	}

	const updateBulkScreensWithModifiedData = async (
		tableName,
		modifiedScreensData = null,
		modifiedElementData = null
	) => {
		const table = getTableByName(tableName)

		let modifiedScreens = modifiedScreensData
			? modifiedScreensData
			: props.appConfig?.screens

		if (modifiedElementData) {
			if (activeParentElement) {
				const index = activeParentElement.body?.findIndex(
					(x) => x._uid == modifiedElementData?._uid
				)

				const modifiedParentElement =
					activeParentElement._uid === modifiedElementData._uid
						? modifiedElementData
						: update(activeParentElement, {
								body: {
									[index]: {
										$set: modifiedElementData
									}
								}
						  })

				modifiedScreens = appMethods.getModifiedScreensByElement(
					props.appConfig.screens,
					activeScreen,
					activeParentElement,
					modifiedParentElement
				)
			} else {
				modifiedScreens = appMethods.getModifiedScreensByElement(
					props.appConfig.screens,
					activeScreen,
					activeElement,
					modifiedElementData
				)
			}
		}

		const managed = await appMethods.getAutoGeneratedScreensByTable(
			table,
			modifiedScreens,
			props.appConfig?.linking,
			{
				detail: true
			}
		)

		if (!activeParentElement) {
			managed.screens = await appMethods.fixDetailScreenActions(
				managed.screens,
				activeScreen,
				activeParentElement ? activeParentElement : activeElement,
				modifiedElementData
			)
		}

		setAppConfig(managed.linking, managed.screens)

		props.builderOnMobile && setAddElementModalVisible(false)
	}

	const addNewScreen = async () => {
		let basicScreenObj = new Screen()

		const newItem = appMethods.getIncrementedScreen(allScreens, basicScreenObj)
		const copyLinkingObj = newItem.path

		const modifiedLinking = update(props.appConfig?.linking, {
			config: { screens: { [newItem.key]: { $set: copyLinkingObj } } }
		})
		const modifiedList = update(props.appConfig?.screens['hiddens'], {
			$push: [newItem]
		})
		const modifiedData = appMethods.getModifiedScreensByGroup(
			props.appConfig?.screens,
			'hiddens',
			modifiedList
		)

		setAppConfig(modifiedLinking, modifiedData)
		setTimeout(() => {
			navigatePreview({
				key: newItem.key,
				detailKey: null
			})
		}, 100)
	}

	const copyItem = (screenGroup, item) => {
		const copyItem = appMethods.getIncrementedScreen(allScreens, item)
		const copyLinkingObj = copyItem.path

		const modifiedLinking = update(props.appConfig?.linking, {
			config: { screens: { [copyItem.key]: { $set: copyLinkingObj } } }
		})

		const modifiedList = update(props.appConfig?.screens[screenGroup], {
			$push: [copyItem]
		})
		const modifiedData = appMethods.getModifiedScreensByGroup(
			props.appConfig?.screens,
			screenGroup,
			modifiedList
		)

		setAppConfig(modifiedLinking, modifiedData)
	}

	const deleteScreen = async (screenGroup, index) => {
		const modifiedList = update(props.appConfig?.screens[screenGroup], {
			$splice: [[index, 1]]
		})
		const modifiedData = appMethods.getModifiedScreensByGroup(
			props.appConfig?.screens,
			screenGroup,
			modifiedList
		)

		const modifiedLinking = update(props.appConfig?.linking, {
			config: {
				screens: { $unset: [props.appConfig?.screens[screenGroup][index].key] }
			}
		})

		if (
			modifiedData.drawers.length === 0 &&
			modifiedData.hiddens.length === 0 &&
			modifiedData.tabs.length === 0
		) {
			showToastError(t('screens.lastScreenCannotBeDeleted'))
		} else {
			setAppConfig(modifiedLinking, modifiedData)
		}
	}

	const deleteElement = async (element, parentElement) => {
		const activeScreenData = activeScreen.data
		const activeScreenGroup = activeScreen.screenGroup

		const index = props.appConfig.screens[activeScreenGroup].findIndex(
			(x) => x.key === activeScreenData.key
		)

		if (parentElement) {
			const modifiedElements = props.appConfig.screens[activeScreenGroup][
				index
			].body?.map((pe) => {
				if (pe === parentElement) {
					pe.body = pe.body?.filter((x) => x !== element)
					return pe
				} else return pe
			})

			const modifiedScreens = update(props.appConfig.screens, {
				[activeScreenGroup]: {
					[index]: {
						body: { $set: modifiedElements }
					}
				}
			})

			!props.builderOnMobile && setRightContentType('newElement')
			setAppConfig(props.appConfig.linking, modifiedScreens)
		} else {
			const modifiedElements = props.appConfig.screens[activeScreenGroup][
				index
			].body.filter((x) => x !== element)

			const modifiedScreens = update(props.appConfig.screens, {
				[activeScreenGroup]: {
					[index]: {
						body: { $set: modifiedElements }
					}
				}
			})

			!props.builderOnMobile && setRightContentType('newElement')
			setAppConfig(props.appConfig.linking, modifiedScreens)
		}
	}

	const getTableByName = (tableName) => {
		const table = entities?.find((x) => x.name == tableName)

		return table
	}

	const renderBreadcrumbs = () => {
		return (
			<div className={cx(classes.screenTitleBox, classes.borderBottom)}>
				<span
					className={cx(
						classes.title,
						classes.pointer,
						classes.semiBold,
						classes.colorGray,
						classes.button
					)}
					onClick={() => {
						setRightContentType('screenSettings')
						setShowAllScreens(true)
					}}
				>
					{t('screens.screensTitleShort')}
				</span>
				<ArrowForwardIos className={classes.forwardIcon} />
				<span
					className={cx(
						classes.title,
						classes.pointer,
						elementNames?.[activeElement?.component]
							? [classes.semiBold, classes.colorGray]
							: [classes.semiBold],
						classes.activeButton
					)}
					onClick={() => setRightContentType('screenSettings')}
				>
					{activeScreen?.data.name}
				</span>
				{!!elementNames?.[activeElement?.component] && (
					<>
						<ArrowForwardIos className={classes.forwardIcon} />
						<span className={cx(classes.title, classes.semiBold)}>
							{elementNames[activeElement.component]}
						</span>
					</>
				)}
			</div>
		)
	}

	const renderElementList = () => {
		return (
			<>
				<div
					className={cx(
						classes.titleBox,
						props.builderOnMobile && [classes.borderBottom, classes.mb10]
					)}
				>
					<Label
						text={t('screens.elementsTitle')}
						className={cx(classes.title, classes.bold)}
					/>
					<AddBox
						onClick={() => {
							setActiveParentElement(null)
							props.builderOnMobile
								? setAddElementModalVisible(true)
								: setRightContentType('newElement')
						}}
						className={classes.pointer}
					/>
				</div>
				<AppPageBox style={{ flex: 1, overflow: 'auto', paddingTop: 0 }}>
					<ScreenElementList
						emptyText={t('screens.emptyElementsText')}
						elements={
							props.appConfig?.screens?.[activeScreen?.screenGroup]?.[
								activeScreen?.screenGroupIndex
							]?.body
						}
						activeScreen={activeScreen}
						activeElement={activeElement}
						activeParentElement={activeParentElement}
						setRightContentType={setRightContentType}
						scrollToPreviewElement={scrollToPreviewElement}
						setElements={updateScreenElements}
						deleteElement={deleteElement}
						setActiveElement={setActiveElement}
						setActiveParentElement={setActiveParentElement}
						builderOnMobile={props.builderOnMobile}
						setAddElementModalVisible={setAddElementModalVisible}
					/>
				</AppPageBox>
			</>
		)
	}

	const renderAddElementList = () => {
		return (
			activeScreen && (
				<ElementAddList
					entities={entities}
					screens={props.appConfig?.screens}
					activeParentElement={activeParentElement}
					activeScreen={activeScreen}
					updateActiveScreen={updateActiveScreen}
					updateBulkScreensWithModifiedData={updateBulkScreensWithModifiedData}
					setActiveElement={setActiveElement}
				/>
			)
		)
	}

	useEffect(() => {
		activeElement && setRightContentType('elementSettings')
	}, [activeElement])

	useEffect(() => {
		if (rightContentType != 'elementSettings' && activeElement) {
			setActiveElement(null)
		}

		if (rightContentType === 'newElement' && activeParentElement) {
			setActiveParentElement(null)
		}
	}, [rightContentType])

	useEffect(() => {
		if (activePreviewRoute && props.appConfig?.screens) {
			const result = getActiveScreen(
				activePreviewRoute,
				props.appConfig.screens
			)

			setActiveScreen(result)

			if (activeParentElement) {
				const ape = result.data.body?.filter(
					(x) => x._uid == activeParentElement?._uid
				)[0]

				setActiveParentElement(ape)
				setActiveElement(
					ape?.body?.filter((x) => x._uid == activeElement?._uid)[0]
				)
			} else {
				setActiveElement(
					result.data.body?.filter((x) => x._uid == activeElement?._uid)[0]
				)
			}
		}

		if (activePreviewRoute?.route?.name != activeScreen?.route?.name) {
			setRightContentType('screenSettings')
			setActiveParentElement(null)
			setActiveElement(null)
		}
	}, [activePreviewRoute])

	useEffect(() => {
		if (activeMenuEvent && activeMenuEvent.reVisit) {
			setRightContentType('screenSettings')
			setShowAllScreens(true)
		}
	}, [activeMenuEvent])

	useEffect(() => {
		document.title = t('docTitles.screens')
	}, [])

	return (
		<>
			<Onboarding
				builderOnMobile={props.builderOnMobile}
				isOnboardingVisible={isOnboardingVisible}
				setIsOnboardingVisible={setIsOnboardingVisible}
			/>

			<OnboardingMobile
				builderOnMobile={props.builderOnMobile}
				isOnboardingVisible={isOnboardingVisible}
				setIsOnboardingVisible={setIsOnboardingVisible}
			/>

			<Grid container className={classes.mainContainer}>
				{showAllScreens && (
					<Grid
						item
						xs={12}
						sm={12}
						md={2}
						className={classes.leftSectionPadding}
					>
						<Box className={classes.sectionContainer}>
							<div className={cx(classes.titleBox, classes.zeroBottomPadding)}>
								<Label
									text={t('screens.screensTitle')}
									className={classes.title}
								/>
								<AddBox onClick={addNewScreen} style={{ cursor: 'pointer' }} />
							</div>
							<AppPageBox style={{ flex: 1, overflow: 'auto', paddingTop: 0 }}>
								<ScreenLists
									setRightContentType={setRightContentType}
									linkingScreens={props.appConfig?.linking?.config?.screens}
									setScreens={setScreens}
									deleteScreen={deleteScreen}
									copyItem={copyItem}
									items={props.appConfig?.screens}
									navigatePreview={navigatePreview}
									activePreviewRoute={activePreviewRoute}
								/>
							</AppPageBox>
						</Box>
					</Grid>
				)}

				{!showAllScreens &&
					(props.builderOnMobile ? (
						activeScreen && (
							<>
								<Grid
									item
									xs={12}
									sm={12}
									md={2}
									className={classes.leftSectionPadding}
								>
									<Box className={classes.sectionContainer}>
										{renderBreadcrumbs()}
									</Box>
								</Grid>

								{!activeElement && (
									<Grid
										item
										xs={12}
										sm={12}
										md={2}
										className={classes.leftSectionPadding}
									>
										<Box className={cx(classes.sectionContainer)}>
											{renderElementList()}
										</Box>
									</Grid>
								)}
							</>
						)
					) : (
						<Grid
							item
							xs={12}
							sm={12}
							md={2}
							className={classes.leftSectionPadding}
						>
							<Box className={classes.sectionContainer}>
								{activeScreen && renderBreadcrumbs()}
								{activeScreen && renderElementList()}
							</Box>
						</Grid>
					))}

				<Grid item xs={12} sm={0} md={7} className={classes.spaceContainer}>
					{/* MIDDLE */}
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					md={3}
					className={classes.rightSectionPadding}
				>
					<Box className={classes.sectionContainer}>
						{getRightSectionContent(rightContentType)}
					</Box>
				</Grid>
			</Grid>

			<KozmikModal
				maxHeight='100%'
				visible={addElementModalVisible}
				hideModal={hideAddElementModal}
			>
				{renderAddElementList()}
			</KozmikModal>
		</>
	)
}

const mapStateToProps = (state) => ({
	...state.auth,
	...state.appData
})

const mapDispatchToProps = (dispatch) => {
	return {
		setConfig: (x) => dispatch(setConfig(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Screens)

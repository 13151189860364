import { createElement } from 'react'

import KozmikTablesAPI from './integrations/KozmikTablesAPI'
import GoogleMaps from './integrations/GoogleMaps'
import AppStore from './integrations/AppStore'
import PlayStore from './integrations/PlayStore'
import WebPush from './integrations/WebPush'
import CustomDomain from './integrations/CustomDomain'

const Integrations = {
	kozmikTablesAPI: KozmikTablesAPI,
	googleMaps: GoogleMaps,
	appStore: AppStore,
	playStore: PlayStore,
	webPush: WebPush,
	customDomain: CustomDomain
}

const IntegrationSettings = (props) => {
	return Integrations?.[props?.integration]
		? createElement(Integrations[props.integration], {})
		: null
}

export default IntegrationSettings

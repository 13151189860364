import update from 'immutability-helper'

const screenGroups = ['tabs', 'drawers', 'hiddens']

const getModifiedScreensByGroup = (screens, screenGroup, groupScreens) => {
	let modifiedData = screens

	if (screenGroups.includes(screenGroup)) {
		modifiedData = update(screens, {
			[screenGroup]: { $set: groupScreens }
		})
	}

	return modifiedData
}

export default getModifiedScreensByGroup

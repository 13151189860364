import React from 'react'
import { Grid, Typography, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import cx from 'classnames'

import PageBox from '../../atoms/pageBox/PageBox'
import Header from '../../molecules/header/Header'
import UsageTable from '../../molecules/usageTable/UsageTable'

import kozmikTheme from '../../../assets/theme/theme.json'
import { default as appConfig } from '../../../config/app.json'

const paymentDomain = appConfig.domains.payment

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		// maxHeight: '100%',
		// overflowY: 'hidden'
	},
	inputIcon: {
		color: theme.custom.colors.textSecondary,
		opacity: 0.6,
		cursor: 'pointer'
	},
	buttonContainer: {
		marginBottom: 10
	},
	searchInput: {
		backgroundColor: '#ffffff',
		borderRadius: 4,
		height: 38,
		width: '100%'
	},
	buttonTxt: {
		color: '#ffffff',
		fontSize: 14,
		fontWeight: '600',
		textAlign: 'left',
		textTransform: 'none',
		whiteSpace: 'nowrap'
	},
	title: {
		display: 'block',
		fontSize: '16px',
		fontWeight: 600,
		width: '100%',
		marginBottom: 15
	},
	pageBox: {
		width: '100%',
		padding: '20px 25px 45px 25px',
		flexWrap: 'wrap'
	},
	button: {
		textTransform: 'none',
		boxShadow: 'none !important',
		borderRadius: '4px',
		margin: '5px',
		flexGrow: 1,
		padding: 0,
		height: 40
	},
	highlightedButton: {
		backgroundColor: `${theme.custom.colors.kozmikGreen} !important`
	},
	borderedButton: {
		backgroundColor: `#ffffff !important`,
		border: `1px solid ${theme.custom.colors.kozmikDarkBlue}`
	},
	btnText: {
		fontSize: 14,
		fontWeight: 600,
		color: theme.custom.colors.kozmikDarkBlue
	},
	planTitle: {
		fontSize: 16,
		fontWeight: 600
	},
	planText: {
		fontSize: 14
	},
	link: {
		color: theme.custom.colors.kozmikBlue
	},
	hideMobile: {
		[theme.breakpoints.down('md')]: {
			display: 'none'
		}
	}
}))

const formButtonStyle = {
	fontSize: 14,
	color: kozmikTheme.colors.kozmikBlue,
	textTransform: 'none',
	backgroundColor: `transparent !important`,
	boxShadow: 'none !important',
	borderRadius: '4px',
	padding: '0px !important'
}

const getPriceTextWithCurrency = (price, currency) => {
	return currency == 'usd'
		? ` $${price}`
		: currency == 'try'
		? ` ${price}₺`
		: ''
}

const SubscriptionTemplate = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const permission = props.currentOrg?.roleType == 'Owner'

	return (
		<Grid container spacing={2} className={classes.mainContainer}>
			<Grid item xs={12}>
				<Header text={t('subscriptionTemp.headerTitle')} />
			</Grid>

			<Grid container item xs={12} lg={9} spacing={2}>
				{!!props?.usage && (
					<Grid item xs={12}>
						<PageBox className={classes.pageBox}>
							<Typography className={cx(classes.title, classes.hideMobile)}>
								{t('subscriptionTemp.yourPlan')}
							</Typography>

							<Grid
								container
								justifyContent='space-between'
								alignItems='center'
							>
								<Grid
									container
									item
									xs={12}
									md={6}
									display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block' }}
								>
									<Grid container item xs={12}>
										<Grid item xs={5}>
											<span className={cx(classes.planTitle)}>
												{props.usage?.Plan}
											</span>
										</Grid>
										<Grid item xs={7}>
											<span className={classes.planTitle}>
												{getPriceTextWithCurrency(
													props.usage?.PlanPrice,
													props.usage?.PlanCurrency
												)}
											</span>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									container
									item
									xs={12}
									md={6}
									display={{
										xs: 'block',
										sm: 'block',
										md: 'block',
										lg: 'none'
									}}
								>
									<Grid container item xs={12}>
										<Grid item xs={6}>
											<Typography className={classes.title}>
												{t('subscriptionTemp.yourPlan')}
											</Typography>
										</Grid>
										<Grid item xs={6} style={{ textAlign: 'right' }}>
											<span className={classes.planTitle}>
												{props.usage?.Plan}
											</span>
											<span className={classes.planTitle}>
												{getPriceTextWithCurrency(
													props.usage?.PlanPrice,
													props.usage?.PlanCurrency
												)}
											</span>
										</Grid>
									</Grid>
								</Grid>

								<Grid
									container
									item
									flexWrap={'nowrap'}
									alignItems='center'
									xs={12}
									md={4}
								>
									<Button
										size='large'
										variant='contained'
										onClick={() => props.setModalVisible(true)}
										className={cx(classes.button, classes.highlightedButton)}
									>
										<Typography className={classes.btnText}>
											{t('subscriptionTemp.upgrade')}
										</Typography>
									</Button>
									<Button
										size='large'
										variant='contained'
										onClick={() => props.setModalVisible(true)}
										className={cx(classes.button, classes.borderedButton)}
									>
										<Typography className={classes.btnText}>
											{t('subscriptionTemp.viewPlans')}
										</Typography>
									</Button>
								</Grid>
							</Grid>
						</PageBox>
					</Grid>
				)}

				<Grid item xs={12}>
					<PageBox className={classes.pageBox}>
						<Typography className={classes.title}>
							{t('subscriptionTemp.usage')}
						</Typography>

						<UsageTable data={props.usage} />
					</PageBox>
				</Grid>
			</Grid>

			<Grid item xs={12} lg={3}>
				<PageBox className={classes.pageBox}>
					<Typography className={classes.title}>
						{t('subscriptionTemp.billing')}
					</Typography>
					<Grid container spacing={2}>
						<Grid container alignItems='center' item xs={12}>
							<Grid item xs={6}>
								<span className={classes.planText}>
									{t('subscriptionTemp.paymentMethod')}
								</span>
							</Grid>
							<Grid item container justifyContent='flex-end' xs={6}>
								<form action={paymentDomain.portal} method='POST'>
									<input
										type='hidden'
										name='orgId'
										value={props?.currentOrg?.id}
									/>
									<Button
										type='submit'
										className={cx(classes.planText, classes.link)}
										sx={formButtonStyle}
									>
										{t('subscriptionTemp.addCard')}
									</Button>
								</form>
							</Grid>
						</Grid>

						<Grid container alignItems='center' item xs={12}>
							<Grid item xs={6}>
								<span className={classes.planText}>
									{t('subscriptionTemp.billInfo')}
								</span>
							</Grid>
							<Grid item container justifyContent='flex-end' xs={6}>
								<form action={paymentDomain.portal} method='POST'>
									<input
										type='hidden'
										name='orgId'
										value={props?.currentOrg?.id}
									/>
									<Button
										type='submit'
										// className={cx(classes.planText, classes.link)}
										sx={formButtonStyle}
									>
										{t('subscriptionTemp.updateInfo')}
									</Button>
								</form>
							</Grid>
						</Grid>

						<Grid container alignItems='center' item xs={12}>
							<Grid item xs={6}>
								<span className={classes.planText}>
									{t('subscriptionTemp.invoiceHistory')}
								</span>
							</Grid>
							<Grid item container justifyContent='flex-end' xs={6}>
								<form action={paymentDomain.portal} method='POST'>
									<input
										type='hidden'
										name='orgId'
										value={props?.currentOrg?.id}
									/>
									<Button
										type='submit'
										// className={cx(classes.planText, classes.link)}
										sx={formButtonStyle}
									>
										{t('subscriptionTemp.history')}
									</Button>
								</form>
							</Grid>
						</Grid>
					</Grid>
				</PageBox>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state) => ({
	...state.userInfo,
	...state.usage
})

export default connect(mapStateToProps, null)(SubscriptionTemplate)

import React, {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
	useCallback
} from 'react'
import {
	Grid,
	Popover,
	Box,
	Button,
	Typography,
	FormGroup,
	FormControlLabel,
	CircularProgress,
	TextField,
	Autocomplete,
	InputAdornment,
	SvgIcon
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
	Search,
	FilePresent,
	DeleteOutlined,
	DensityLarge,
	DensityMedium,
	DensitySmall,
	Compress,
	AddRoad,
	Visibility,
	VisibilityOff,
	Download,
	FileUpload,
	HighlightOff,
	Email,
	Info,
	CheckOutlined,
	CloseOutlined
} from '@mui/icons-material'
import { enUS, trTR, useGridApiRef } from '@mui/x-data-grid-pro'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { format, isMatch } from 'date-fns'
import { connect } from 'react-redux'

import { default as kozmikTheme } from '../../../assets/theme/theme.json'

import StripedDataGrid from '../../atoms/stripedDataGrid/StripedDataGrid'
import DataTableColumnMenu from '../../molecules/dataTableColumnMenu/DataTableColumnMenu'
import DataTableFooter from '../../molecules/dataTableFooter/DataTableFooter'
import SelectBox from '../../molecules/selectBox/SelectBox'
import WarningDialog from '../../molecules/dialog/warningDialog/WarningDialog'
// import DataTableToolbar from '../../molecules/dataTableToolbar/DataTableToolbar'
import DensityMenuItem from '../../molecules/densityMenuItem/DensityMenuItem'
import KozmikModal from '../../molecules/kozmikModal/KozmikModal'
import APIDoc from '../../molecules/apiDoc/ApiDoc'
import KozmikCheckBox from '../../atoms/kozmikCheckBox/KozmikCheckBox'
import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'
import MentionsInput from '../../atoms/mentionsInput/MentionsInput'
import { default as Label } from '../../atoms/settingLabel/SettingLabel'
import { default as Checkbox } from '../../atoms/kozmikCheckBox/KozmikCheckBox'

import getEntityFieldsByDataTypes from '../../../methods/getEntityFieldsByDataTypes'
import checkBase64 from '../../../methods/checkBase64'
import fieldDataTypes from '../../../common/fieldDataTypes'
import aggOperators from '../../../common/aggOperators'
import evaluateMath from '../../../methods/evaluateMath'
import timeoutDelay from '../../../methods/timeoutDelay'

import dataTypeRegExes from '../../../common/regExp/dataType'
import systemFieldNames from '../../../common/systemFieldNames/systemFieldNames'
import UppyPicker from '../../atoms/uppyPicker/UppyPicker'

import dataClient from '../../../services/dataClient'
import showToastResponseError from '../../../methods/showToastResponseError'

/***************CUSTOM_DATETIME_PICKERS***************/
import PropTypes from 'prop-types'
import {
	useGridApiContext,
	GRID_DATE_COL_DEF,
	GRID_DATETIME_COL_DEF,
	GRID_CHECKBOX_SELECTION_COL_DEF
} from '@mui/x-data-grid'
import {
	DatePicker,
	DateTimePicker,
	LocalizationProvider
} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import localeEN from 'date-fns/locale/en-US'
import localeTR from 'date-fns/locale/tr'
import InputBase from '@mui/material/InputBase'
import { styled } from '@mui/material/styles'

function buildApplyDateFilterFn(filterItem, compareFn, showTime = false) {
	if (!filterItem.value) {
		return null
	}

	const filterValueMs = filterItem.value.getTime()

	return ({ value }) => {
		if (!value) {
			return false
		}

		// Make a copy of the date to not reset the hours in the original object
		const dateCopy = new Date(value)
		dateCopy.setHours(
			showTime ? value.getHours() : 0,
			showTime ? value.getMinutes() : 0,
			0,
			0
		)

		const cellValueMs = dateCopy.getTime()

		return compareFn(cellValueMs, filterValueMs)
	}
}

function getDateFilterOperators(showTime = false) {
	return [
		{
			value: 'is',
			getApplyFilterFn: (filterItem) => {
				return buildApplyDateFilterFn(
					filterItem,
					(value1, value2) => value1 === value2,
					showTime
				)
			},
			InputComponent: GridFilterDateInput,
			InputComponentProps: { showTime }
		},
		{
			value: 'not',
			getApplyFilterFn: (filterItem) => {
				return buildApplyDateFilterFn(
					filterItem,
					(value1, value2) => value1 !== value2,
					showTime
				)
			},
			InputComponent: GridFilterDateInput,
			InputComponentProps: { showTime }
		},
		{
			value: 'after',
			getApplyFilterFn: (filterItem) => {
				return buildApplyDateFilterFn(
					filterItem,
					(value1, value2) => value1 > value2,
					showTime
				)
			},
			InputComponent: GridFilterDateInput,
			InputComponentProps: { showTime }
		},
		{
			value: 'onOrAfter',
			getApplyFilterFn: (filterItem) => {
				return buildApplyDateFilterFn(
					filterItem,
					(value1, value2) => value1 >= value2,
					showTime
				)
			},
			InputComponent: GridFilterDateInput,
			InputComponentProps: { showTime }
		},
		{
			value: 'before',
			getApplyFilterFn: (filterItem) => {
				return buildApplyDateFilterFn(
					filterItem,
					(value1, value2) => value1 < value2,
					showTime
				)
			},
			InputComponent: GridFilterDateInput,
			InputComponentProps: { showTime }
		},
		{
			value: 'onOrBefore',
			getApplyFilterFn: (filterItem) => {
				return buildApplyDateFilterFn(
					filterItem,
					(value1, value2) => value1 <= value2,
					showTime
				)
			},
			InputComponent: GridFilterDateInput,
			InputComponentProps: { showTime }
		},
		{
			value: 'isEmpty',
			getApplyFilterFn: () => {
				return ({ value }) => {
					return value == null
				}
			},
			requiresFilterValue: false
		},
		{
			value: 'isNotEmpty',
			getApplyFilterFn: () => {
				return ({ value }) => {
					return value != null
				}
			},
			requiresFilterValue: false
		}
	]
}

// const dateAdapter = new AdapterDateFns({ localeEN })

/**
 * `date` column
 */

const dateColumnType = {
	...GRID_DATE_COL_DEF,
	resizable: true,
	renderEditCell: (params) => {
		return <GridEditDateCell {...params} />
	},
	filterOperators: getDateFilterOperators()
	// valueFormatter: (params) => {
	// 	if (typeof params.value === 'string') {
	// 		return params.value
	// 	}
	// 	if (params.value) {
	// 		return dateAdapter.format(params.value, 'keyboardDate')
	// 	}
	// 	return ''
	// }
}

const GridEditDateInput = styled(InputBase)({
	fontSize: 'inherit',
	padding: '0 9px'
})

function GridEditDateCell({ id, field, value, colDef }) {
	const apiRef = useGridApiContext()

	const Component = colDef.type === 'dateTime' ? DateTimePicker : DatePicker

	const handleChange = (newValue) => {
		apiRef.current.setEditCellValue({ id, field, value: newValue })
	}

	return (
		<Component
			value={value}
			renderInput={({ inputRef, inputProps, InputProps, disabled, error }) => (
				<GridEditDateInput
					fullWidth
					autoFocus
					ref={inputRef}
					{...InputProps}
					disabled={disabled}
					error={error}
					inputProps={inputProps}
				/>
			)}
			onChange={handleChange}
		/>
	)
}

GridEditDateCell.propTypes = {
	/**
	 * The column of the row that the current cell belongs to.
	 */
	colDef: PropTypes.object.isRequired,
	/**
	 * The column field of the cell that triggered the event.
	 */
	field: PropTypes.string.isRequired,
	/**
	 * The grid row id.
	 */
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	/**
	 * The cell value.
	 * If the column has `valueGetter`, use `params.row` to directly access the fields.
	 */
	value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
}

function GridFilterDateInput(props) {
	const { item, showTime, applyValue, apiRef } = props

	const Component = showTime ? DateTimePicker : DatePicker

	const handleFilterChange = (newValue) => {
		applyValue({ ...item, value: newValue })
	}

	return (
		<Component
			value={item.value || null}
			renderInput={(params) => (
				<TextField
					{...params}
					variant='standard'
					label={apiRef.current.getLocaleText('filterPanelInputLabel')}
				/>
			)}
			InputAdornmentProps={{
				sx: {
					'& .MuiButtonBase-root': {
						marginRight: -1
					}
				}
			}}
			onChange={handleFilterChange}
		/>
	)
}

/**
 * `dateTime` column
 */

GridFilterDateInput.propTypes = {
	apiRef: PropTypes.shape({
		current: PropTypes.object.isRequired
	}).isRequired,
	applyValue: PropTypes.func.isRequired,
	item: PropTypes.shape({
		/**
		 * The column from which we want to filter the rows.
		 */
		columnField: PropTypes.string.isRequired,
		/**
		 * Must be unique.
		 * Only useful when the model contains several items.
		 */
		id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		/**
		 * The name of the operator we want to apply.
		 * Will become required on `@mui/x-data-grid@6.X`.
		 */
		operatorValue: PropTypes.string,
		/**
		 * The filtering value.
		 * The operator filtering function will decide for each row if the row values is correct compared to this value.
		 */
		value: PropTypes.any
	}).isRequired,
	showTime: PropTypes.bool
}

const dateTimeColumnType = {
	...GRID_DATETIME_COL_DEF,
	resizable: true,
	renderEditCell: (params) => {
		return <GridEditDateCell {...params} />
	},
	filterOperators: getDateFilterOperators(true)
	// valueFormatter: (params) => {
	// 	if (typeof params.value === 'string') {
	// 		return params.value
	// 	}
	// 	if (params.value) {
	// 		return dateAdapter.format(params.value, 'keyboardDateTime')
	// 	}
	// 	return ''
	// }
}
/***************CUSTOM_DATETIME_PICKERS***************/

const useStyles = makeStyles((theme) => ({
	searchInput: {
		backgroundColor: theme.custom.colors.kozmikGray,
		borderRadius: 4,
		margin: '12px 6px 0px 6px',
		height: 38,
		[theme.breakpoints.down('md')]: {
			maxWidth: 175
		},
		[theme.breakpoints.down('sm')]: {
			maxWidth: 125
		}
	},
	input: {
		borderRadius: '4px',
		backgroundColor: kozmikTheme.colors.kozmikGray,
		fontSize: '14px',
		color: kozmikTheme.colors.textPrimary
	},
	inputIcon: {
		color: theme.custom.colors.textSecondary,
		opacity: 0.6,
		cursor: 'pointer'
	},
	addFieldIcon: {
		cursor: 'pointer'
	},
	popoverBox: {
		padding: 16,
		width: 250
	},
	multilinePopoverBox: {
		padding: 16,
		width: 400
	},
	columnVisibilityBox: {
		width: 250
	},
	headerBox: {
		padding: '16px 16px 8px 16px',
		width: '100%'
	},
	scrollBox: {
		padding: '8px 16px',
		width: '100%',
		maxHeight: 300,
		overflowY: 'auto'
	},
	footerBox: {
		padding: '0 16px 16px 16px',
		width: '100%'
	},
	scrollPopoverBox: {
		padding: 16,
		width: 250,
		maxHeight: 400,
		overflowY: 'auto'
	},
	popoverBtn: {
		borderRadius: '4px',
		width: '48%',
		height: '35px'
	},
	btnBlue: {
		backgroundColor: theme.custom.colors.kozmikBlue
	},
	btnRed: {
		backgroundColor: theme.custom.colors.red,
		'&:hover': {
			backgroundColor: theme.custom.colors.darkRed
		}
	},
	popoverInput: {
		borderRadius: 4,
		backgroundColor: theme.custom.colors.kozmikGray,
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	popoverBtnTxt: {
		color: '#FFFFFF',
		fontSize: 14,
		fontWeight: 'bold',
		textAlign: 'left',
		textTransform: 'none',
		whiteSpace: 'nowrap'
	},
	errorText: {
		color: theme.custom.colors.red
	},
	kozmikBlue: {
		color: theme.custom.colors.kozmikBlue
	},
	kozmikTxtPrimary: {
		color: theme.custom.colors.textPrimary
	},
	kozmikRed: {
		color: theme.custom.colors.red
	},
	checkIcon: {
		fontSize: 20
	},
	checkFieldLabel: {
		fontSize: 14
	},
	checkLabel: {
		fontSize: 12,
		maxWidth: 180,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	},
	allBox: {
		display: 'flex',
		cursor: 'pointer',
		marginTop: 16
	},
	allLabel: {
		paddingLeft: 5,
		fontSize: 12
	},
	inputWithAdornment: {
		backgroundColor: theme.custom.colors.kozmikGray,
		borderRadius: '3px',
		border: 'none',
		'& .MuiInputBase-adornedStart': {
			paddingLeft: 0,
			overflow: 'hidden'
		}
	},
	adornment: {
		backgroundColor: theme.custom.colors.kozmikGray,
		padding: '6px 10px',
		color: theme.custom.colors.textPrimary,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	buttonIcon: {
		fontSize: 22
	},
	textButtonIcon: {
		fontSize: 18,
		marginRight: 5,
		[theme.breakpoints.down('md')]: {
			fontSize: 22,
			marginRight: 0
		}
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	labelBox: {
		width: 80,
		minWidth: 80
	},
	tips: {
		fontSize: 12,
		marginTop: 5
	},
	tipsIcon: {
		fontSize: 16,
		color: theme.custom.colors.darkGray,
		marginRight: 5
	},
	templateNotValidIcon: {
		color: theme.custom.colors.red,
		fontSize: 16,
		marginRight: 5
	},
	templateNotValid: {
		color: theme.custom.colors.red,
		fontSize: 12,
		marginTop: 5
	},
	badge: {
		marginLeft: 5,
		color: '#000000',
		fontSize: 8,
		fontWeight: 'bold',
		borderRadius: 7.5,
		padding: '4px 8px',
		backgroundImage:
			'linear-gradient(90deg, #96f1ce 50%, rgba(27,54,254,0.3) 100%)'
	},
	trueIcon: {
		fontSize: 20,
		color: theme.custom.colors.textPrimary
	},
	falseIcon: {
		fontSize: 20,
		color: theme.custom.colors.disabledPrimary
	},
	labelDisabled: {
		fontSize: 14,
		color: theme.custom.colors.disabledPrimary
	}
}))

const getIconByDataType = (item) => {
	if (item.dataType === 'Formula') {
		const data = fieldDataTypes.formulaData.find(
			(x) => x.value == item.formula?.formulaType
		)

		return data ? (
			<SvgIcon
				inheritViewBox={true}
				style={{ fontSize: 16 }}
				component={data.icon}
			/>
		) : null
	} else {
		const data = fieldDataTypes.allData.find((x) => x.value == item.dataType)

		return data ? (
			<SvgIcon
				inheritViewBox={true}
				style={{ fontSize: 16 }}
				component={data.icon}
			/>
		) : null
	}
}

const AppDataData = forwardRef((props, ref) => {
	const { t } = useTranslation()
	let { appId } = useParams()
	const classes = useStyles()

	// const dataGridApiRef = useGridApiRef()

	const culture = localStorage.getItem('i18nextLng')

	const imagePicker = useRef(null)
	const filePicker = useRef(null)

	const unitOpts = [
		{
			label: 'Second',
			value: 'second'
		},
		{
			label: 'Minute',
			value: 'minute'
		},
		{
			label: 'Hour',
			value: 'hour'
		},
		{
			label: 'Day',
			value: 'day'
		},
		{
			label: 'Month',
			value: 'month'
		},
		{
			label: 'Year',
			value: 'year'
		}
	]
	const precisionOpts = [
		{
			label: '1',
			value: '0'
		},
		{
			label: '1.0',
			value: '1'
		},
		{
			label: '1.00',
			value: '2'
		},
		{
			label: '1.000',
			value: '3'
		},
		{
			label: '1.0000',
			value: '4'
		},
		{
			label: '1.00000',
			value: '5'
		},
		{
			label: '1.000000',
			value: '6'
		}
	]

	const [apiDocModalVisibility, setAPIDocModalVisibility] = useState(false)

	const [columns, setColumns] = useState([])
	const [fieldPop, setFieldPop] = useState(null)
	const [editPopIsOpen, setEditPopIsOpen] = useState(false)
	const [editPopPosition, setEditPopPosition] = useState(null)
	const [fieldNameVal, setFieldNameVal] = useState('')
	const [fieldNameValid, setFieldNameValid] = useState(true)
	const [fieldTypeValid, setFieldTypeValid] = useState(true)
	const [fieldType, setFieldType] = useState(null)
	const [formulaTypeValid, setFormulaTypeValid] = useState(true)
	const [formulaType, setFormulaType] = useState(null)
	const [relationTypeMany, setRelationTypeMany] = useState(false)

	const [relationEntityName, setRelationEntityName] = useState(null)
	const [relationEntityNameValid, setRelationEntityNameValid] = useState(true)
	const [relationOptions, setRelationOptions] = useState([])
	const [relationDisplayFieldNameOpts, setRelationDisplayFieldNameOpts] =
		useState(null)
	const [relationDisplayFieldName, setRelationDisplayFieldName] = useState(null)
	const [relationDisplayFieldNameValid, setRelationDisplayFieldNameValid] =
		useState(true)

	const [lookupRelationName, setLookupRelationName] = useState(null)
	const [lookupRelationNameValid, setLookupRelationNameValid] = useState(true)
	const [lookupDisplayFieldNameOpts, setLookupDisplayFieldNameOpts] =
		useState(null)
	const [lookupDisplayFieldName, setLookupDisplayFieldName] = useState(null)
	const [lookupDisplayFieldNameValid, setLookupDisplayFieldNameValid] =
		useState(true)

	const [rollupRelationName, setRollupRelationName] = useState(null)
	const [rollupRelationNameValid, setRollupRelationNameValid] = useState(true)
	const [rollupFieldNameOpts, setRollupFieldNameOpts] = useState(null)
	const [rollupFieldName, setRollupFieldName] = useState(null)
	const [rollupFieldNameValid, setRollupFieldNameValid] = useState(true)
	const [rollupAggregation, setRollupAggregation] = useState(null)
	const [rollupAggregationValid, setRollupAggregationValid] = useState(null)

	const [mathTemplate, setMathTemplate] = useState(null)
	const [mathTemplateOpts, setMathTemplateOpts] = useState(null)
	const [mathTemplateValid, setMathTemplateValid] = useState(true)
	const [textTemplate, setTextTemplate] = useState(null)
	const [textTemplateOpts, setTextTemplateOpts] = useState(null)
	const [textTemplateValid, setTextTemplateValid] = useState(true)
	const [dateFormulaFieldNameOpts, setDateFormulaFieldNameOpts] = useState(null)
	const [dateFormatTemplate, setDateFormatTemplate] = useState(null)
	const [dateFormatTemplateValid, setDateFormatTemplateValid] = useState(true)
	const [dateFormatFieldName, setDateFormatFieldName] = useState(null)
	const [dateFormatFieldNameValid, setDateFormatFieldNameValid] = useState(true)
	const [dateDiffStartDateFieldName, setDateDiffStartDateFieldName] =
		useState(null)
	const [dateDiffStartDateFieldNameValid, setDateDiffStartDateFieldNameValid] =
		useState(true)
	const [dateDiffEndDateFieldName, setDateDiffEndDateFieldName] = useState(null)
	const [dateDiffEndDateFieldNameValid, setDateDiffEndDateFieldNameValid] =
		useState(true)
	const [dateDiffUnit, setDateDiffUnit] = useState('day')
	const [dateDiffUnitValid, setDateDiffUnitValid] = useState(true)
	const [dateAddDateFieldName, setDateAddDateFieldName] = useState(null)
	const [dateAddDateFieldNameValid, setDateAddDateFieldNameValid] =
		useState(true)
	const [dateAddNumberFieldName, setDateAddNumberFieldName] = useState(null)
	const [dateAddNumberFieldNameValid, setDateAddNumberFieldNameValid] =
		useState(true)
	const [dateAddUnit, setDateAddUnit] = useState('day')
	const [dateAddUnitValid, setDateAddUnitValid] = useState(true)
	const [numberFieldNameOpts, setNumberFieldNameOpts] = useState(null)

	const [fieldDesc, setFieldDesc] = useState('')
	const [selectedFieldId, setSelectedFieldId] = useState(null)
	const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
		useState(false)
	const [deleteRowConfirmationVisible, setDeleteRowConfirmationVisible] =
		useState(false)
	const [rowData, setRowData] = useState([])
	const [editState, setEditState] = useState(null)
	const [selectedRows, setSelectedRows] = useState(null)

	const [customEditPopPosition, setCustomEditPopPosition] = useState(null)

	const [multiLinePopIsOpen, setMultiLinePopIsOpen] = useState(false)
	const [multiLineVal, setMultiLineVal] = useState('')
	const [relationVal, setRelationVal] = useState(null)
	const [currentRelation, setCurrentRelation] = useState(null)
	const [relationPopIsOpen, setRelationPopIsOpen] = useState(false)
	const [pinnedColumns, setPinnedColumns] = useState({
		left: [],
		right: []
	})
	const [columnVisibilityModel, setColumnVisibilityModel] = useState({})

	const [updatingImage, setUpdatingImage] = useState(null)
	const [updatingFile, setUpdatingFile] = useState(null)

	const scrollToTopInGrid = () => {
		// dataGridApiRef?.current?.scrollToIndexes({
		// 	rowIndex: 0,
		// 	colIndex: 0
		// })
	}

	const handlePinnedColumnsChange = useCallback((updatedPinnedColumns) => {
		setPinnedColumns(updatedPinnedColumns)
	}, [])

	const clearPinnedColumns = () => {
		setPinnedColumns({
			left: [],
			right: []
		})
	}
	const pinDisplayNameField = () => {
		const displayField = props.fieldsData?.find((x) => x.isDisplayField)
		setPinnedColumns({
			left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, displayField?.name] ?? [],
			right: []
		})
	}

	const handleOnRowsScrollEnd = (params) => {
		if (params.virtualRowsCount % props.pageSize === 0) {
			const page = Math.round(params.virtualRowsCount / props.pageSize)
			props.setPage(page + 1)
		}
	}

	const numberFormatter = new Intl.NumberFormat(culture)

	const validateText = (text) => {
		if (!text) return true

		const re = dataTypeRegExes.Text.re
		return re.test(String(text).toLowerCase())
	}

	const validateEmail = (email) => {
		if (!email) return true

		const re = dataTypeRegExes.Email.re
		return re.test(String(email).toLowerCase())
	}

	const validateURL = (url) => {
		if (!url) return true

		const re = dataTypeRegExes.URL.re
		return re.test(String(url))
	}

	const validateGeoLocation = (geoLocation) => {
		if (!geoLocation) return true

		const re = dataTypeRegExes.GeoLocation.re
		return re.test(String(geoLocation))
	}

	const validateMoney = (money, cltr) => {
		if (!money) return true

		const re = {
			tr: dataTypeRegExes.Number.re.tr,
			en: dataTypeRegExes.Number.re.en
		}

		return cltr === 'tr-TR'
			? re.tr.test(String(money))
			: re.en.test(String(money))
	}

	const validateNumber = (number, cltr) => {
		if (!number) return true

		const re = {
			tr: dataTypeRegExes.Integer.re.tr,
			en: dataTypeRegExes.Integer.re.en
		}

		return cltr === 'tr-TR'
			? re.tr.test(String(number))
			: re.en.test(String(number))
	}

	const columnGenerator = (item) => {
		let object = {
			field: item.name,
			headerName: item.label,
			minWidth: 150,
			// flex: 1,
			type: 'string',
			editable: !systemFieldNames.notVisible.includes(item.name),
			originalData: item,
			hide: systemFieldNames.notVisible.includes(item.name),
			renderHeader: () => {
				return (
					<strong
						style={{ padding: 10, display: 'flex', alignItems: 'center' }}
					>
						<div
							style={{ display: 'flex', alignItems: 'center', marginRight: 5 }}
						>
							{getIconByDataType(item)}
						</div>
						<span>{item.label}</span>
					</strong>
				)
			}
		}

		switch (item.dataType) {
			case 'RowId':
				object.editable = false
				break
			case 'Text':
				object.preProcessEditCellProps = (params) => {
					const isValid = validateText(params.props.value)
					return { error: !isValid, ...params.props }
				}
				break
			case 'LongText':
				break
			// case 'AutoNumber':
			// 	object.type = 'number'
			// 	object.editable = false
			// 	break
			case 'Checkbox':
				object.type = 'boolean'
				object.renderCell = (cellValues) => {
					return cellValues.row[item.name] === null ? (
						''
					) : cellValues.row[item.name] ? (
						<CheckOutlined className={classes.trueIcon} />
					) : (
						<CloseOutlined className={classes.falseIcon} />
					)
				}
				break
			case 'Number':
				// object.valueFormatter = ({ value }) => {
				// 	return numberFormatter.format(Number(value))
				// }
				object.headerAlign = 'right'
				object.align = 'right'
				object.preProcessEditCellProps = (params) => {
					if (item.precision && item.precision > 0) {
						const isValid = validateMoney(params.props.value, culture)
						return {
							error: !isValid,
							...params.props
						}
					} else {
						const isValid = validateNumber(params.props.value, culture)
						return {
							error: !isValid,
							...params.props
						}
					}
				}
				break
			case 'Image':
				object.editable = false
				object.sortable = false
				object.renderCell = (cellValues) => {
					return (
						<Box
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							{cellValues.row[item.name] ? (
								cellValues.row[item.name] === 'kozmik_loading_spinner' ? (
									<CircularProgress
										size={20}
										sx={{
											color: (theme) => theme.custom.colors.kozmikBlue
										}}
									/>
								) : (
									<>
										<HighlightOff
											onClick={(e) => {
												deleteImage(
													cellValues.row?.Id,
													item.name,
													cellValues.row[item.name]
												)
											}}
											style={{
												width: '20px',
												height: '20px',
												marginRight: 10,
												cursor: 'pointer'
											}}
										/>
										<Box
											style={{
												width: 38,
												height: 38,
												backgroundImage: `url(${cellValues.row[item.name]})`,
												backgroundSize: 'cover'
											}}
										/>
										<Download
											onClick={(e) => {
												// e.stopPropagation()
												downloadImage(cellValues.row[item.name], 'image') //TODO:name
											}}
											style={{
												width: '20px',
												height: '20px',
												marginLeft: 10,
												cursor: 'pointer'
											}}
										/>
									</>
								)
							) : (
								<FileUpload
									style={{
										width: '20px',
										height: '20px',
										cursor: 'pointer'
									}}
									onClick={() => {
										openImagePicker(
											cellValues.row?.Id,
											item.name,
											cellValues.row[item.name]
										)
									}}
								/>
							)}
						</Box>
					)
				}
				break
			case 'File':
				object.editable = false
				object.sortable = false
				object.renderCell = (cellValues) => {
					return (
						<Box
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							{cellValues.row[item.name] ? (
								cellValues.row[item.name] === 'kozmik_loading_spinner' ? (
									<CircularProgress
										size={20}
										sx={{
											color: (theme) => theme.custom.colors.kozmikBlue
										}}
									/>
								) : (
									<>
										<HighlightOff
											onClick={() => {
												deleteFile(
													cellValues.row?.Id,
													item.name,
													cellValues.row[item.name]
												)
											}}
											style={{
												width: '20px',
												height: '20px',
												marginRight: 10,
												cursor: 'pointer'
											}}
										/>
										<FilePresent />
										<Download
											onClick={(e) => {
												// e.stopPropagation()
												downloadFile(cellValues.row[item.name], 'file') //TODO:name
											}}
											style={{
												width: '20px',
												height: '20px',
												marginLeft: 10,
												cursor: 'pointer'
											}}
										/>
									</>
								)
							) : (
								<FileUpload
									style={{
										width: '20px',
										height: '20px',
										cursor: 'pointer'
									}}
									onClick={() => {
										openFilePicker(
											cellValues.row?.Id,
											item.name,
											cellValues.row[item.name]
										)
									}}
								/>
							)}
						</Box>
					)
				}
				break
			case 'Date':
				if (item.includeTime) {
					object.type = 'dateTime'
					// object.valueFormatter = ({ value }) => {
					// 	const createdDate = new Date(value)
					// 	const localeString = culture === 'tr-TR' ? 'tr' : 'en'
					// 	return value
					// 		? `${createdDate.toLocaleDateString(
					// 				localeString
					// 		  )} - ${createdDate.toLocaleTimeString(localeString)}`
					// 		: ''
					// }
					object = { ...dateTimeColumnType, ...object }
				} else {
					object.type = 'date'
					// object.valueFormatter = ({ value }) => {
					// 	const createdDate = new Date(value)
					// 	return value
					// 		? createdDate.toLocaleDateString(
					// 				culture === 'tr-TR' ? 'tr' : 'en'
					// 		  )
					// 		: ''
					// }
					object = { ...dateColumnType, ...object }
				}
				break
			case 'Email':
				object.preProcessEditCellProps = (params) => {
					const isValid = validateEmail(params.props.value)
					return { error: !isValid, ...params.props }
				}
				break
			case 'Phone':
				//TODO:Phone validation*length ve 20char*sona bırakılacak
				break
			case 'URL':
				object.preProcessEditCellProps = (params) => {
					const isValid = validateURL(params.props.value)
					return { error: !isValid, ...params.props }
				}
				break
			case 'GeoLocation':
				object.preProcessEditCellProps = (params) => {
					const isValid = validateGeoLocation(params.props.value)
					return { error: !isValid, ...params.props }
				}
				break
			case 'Relation':
				object.sortable = false
				object.renderCell = (cellValues) => {
					if (item.relationType === 'One') {
						return (
							cellValues?.value?.Name ||
							(cellValues?.value?.Name === '' && t('appData.relationPop.empty'))
						)
					} else if (item.relationType === 'Many') {
						return cellValues.value?.length > 0 ? (
							<div style={{ display: 'flex' }}>
								{cellValues.value.map((item, index) => (
									<div
										key={index}
										style={{
											backgroundColor: '#EBEBEB',
											borderRadius: 16,
											padding: '3px 5px',
											fontSize: 13,
											marginRight: 5
										}}
									>
										{item.Name || t('appData.relationPop.empty')}
									</div>
								))}
							</div>
						) : (
							''
						)
					} else {
						return ''
					}
				}
				break
			case 'Lookup':
				if (
					item.displayFieldDataType &&
					item.displayFieldDataType === 'Number'
				) {
					object.headerAlign = 'right'
					object.align = 'right'
				}

				object.editable = false
				object.renderCell = (cellValues) => {
					if (Array.isArray(cellValues.value) && cellValues.value?.length > 0) {
						return (
							<div style={{ display: 'flex' }}>
								{cellValues.value.map((item, index) => (
									<div
										key={index}
										style={{
											backgroundColor: '#EBEBEB',
											borderRadius: 16,
											padding: '3px 5px',
											fontSize: 13,
											marginRight: 5
										}}
									>
										{item || t('appData.relationPop.empty')}
									</div>
								))}
							</div>
						)
					} else {
						return null
					}
				}
				break
			case 'Rollup':
				object.editable = false
				if (
					item.displayFieldDataType &&
					item.displayFieldDataType === 'Number'
				) {
					object.headerAlign = 'right'
					object.align = 'right'
				}
				break
			case 'Formula':
				switch (item?.formula?.formulaType) {
					case 'Math':
						object.editable = false
						object.headerAlign = 'right'
						object.align = 'right'
						break
					case 'Template':
						object.editable = false
						break
					case 'DateFormat':
						object.editable = false
						break
					case 'DateDifference':
						object.editable = false
						object.headerAlign = 'right'
						object.align = 'right'
						break
					case 'DateAdd':
						object.editable = false
						break
				}
				break
		}

		return object
	}

	const convertColumnData = (data) => {
		let convertedData = []
		// const addFieldColumn = {
		// 	field: 'createField',
		// 	width: 55,
		// 	sortable: false,
		// 	disableColumnMenu: true,
		// 	renderHeader: () => (
		// 		<Add className={classes.addFieldIcon} onClick={openFieldPop} />
		// 	)
		// }

		// let column = {}
		data.map((item) => {
			const column = columnGenerator(item)
			convertedData.push(column)
		})
		// convertedData.push(addFieldColumn)

		return convertedData
	}

	const validateEmptyOrSpecial = (name) => {
		return new Promise((resolve) => {
			// const re = /^[a-zA-Z0-9 ]*$/
			// const isValid = re.test(name)
			const isValid = true
			if (isValid && name != '') {
				setFieldNameValid(true)
				resolve(true)
			} else {
				setFieldNameValid(false)
				resolve(false)
			}
		})
	}
	const submitFieldName = () => {
		validateEmptyOrSpecial(fieldNameVal).then((isValid) => {
			if (isValid) {
				if (fieldType) {
					if (fieldType === 'Number') {
						const extraData = {
							precision: precision ?? 0
						}
						props.createField(fieldType, fieldNameVal, fieldDesc, extraData)
					} else if (fieldType === 'Date') {
						const extraData = {
							includeTime: includeTime ?? false
						}
						props.createField(fieldType, fieldNameVal, fieldDesc, extraData)
					} else if (fieldType === 'Relation') {
						if (relationEntityName && relationDisplayFieldName) {
							const extraData = {
								relatedEntityName: relationEntityName,
								relationType: relationTypeMany ? 'Many' : 'One',
								displayFieldName: relationDisplayFieldName
							}
							props.createField(fieldType, fieldNameVal, fieldDesc, extraData)
						} else {
							!relationEntityName && setRelationEntityNameValid(false)
							!relationDisplayFieldName &&
								setRelationDisplayFieldNameValid(false)
						}
					} else if (fieldType === 'Lookup') {
						if (lookupRelationName && lookupDisplayFieldName) {
							const extraData = {
								relationName: lookupRelationName,
								displayFieldName: lookupDisplayFieldName
							}
							props.createField(fieldType, fieldNameVal, fieldDesc, extraData)
						} else {
							!lookupRelationName && setLookupRelationNameValid(false)
							!lookupDisplayFieldName && setLookupDisplayFieldNameValid(false)
						}
					} else if (fieldType === 'Rollup') {
						if (rollupRelationName && rollupFieldName && rollupAggregation) {
							const extraData = {
								relationName: rollupRelationName,
								displayFieldName: rollupFieldName,
								aggregation: rollupAggregation
							}
							props.createField(fieldType, fieldNameVal, fieldDesc, extraData)
						} else {
							!rollupRelationName && setRollupRelationNameValid(false)
							!rollupFieldName && setRollupFieldNameValid(false)
							!rollupAggregation && setRollupAggregationValid(false)
						}
					} else if (fieldType === 'Formula') {
						if (formulaType === 'Math') {
							if (mathTemplate) {
								const extraData = {
									formula: {
										formulaType: formulaType,
										formulaFieldDataType: 'Number',
										params: {
											math: {
												template: mathTemplate
											}
										}
									}
								}
								props.createField(fieldType, fieldNameVal, fieldDesc, extraData)
							} else {
								!mathTemplate && setMathTemplateValid(false)
							}
						} else if (formulaType === 'Template') {
							if (textTemplate) {
								const extraData = {
									formula: {
										formulaType: formulaType,
										formulaFieldDataType: 'Text',
										params: {
											template: {
												template: textTemplate
											}
										}
									}
								}

								props.createField(fieldType, fieldNameVal, fieldDesc, extraData)
							} else {
								!textTemplate && setTextTemplateValid(false)
							}
						} else if (formulaType === 'DateFormat') {
							if (dateFormatFieldName && dateFormatTemplate) {
								const extraData = {
									formula: {
										formulaType: formulaType,
										formulaFieldDataType: 'Text',
										params: {
											dateFormat: {
												template: dateFormatTemplate,
												fieldName: dateFormatFieldName
											}
										}
									}
								}

								props.createField(fieldType, fieldNameVal, fieldDesc, extraData)
							} else {
								!dateFormatFieldName && setDateFormatFieldNameValid(false)
								!dateFormatTemplate && setDateFormatTemplateValid(false)
							}
						} else if (formulaType === 'DateDifference') {
							if (
								dateDiffStartDateFieldName &&
								dateDiffEndDateFieldName &&
								dateDiffUnit
							) {
								const extraData = {
									formula: {
										formulaType: formulaType,
										formulaFieldDataType: 'Number',
										params: {
											dateDifference: {
												startDateFieldName: dateDiffStartDateFieldName,
												endDateFieldName: dateDiffEndDateFieldName,
												unit: dateDiffUnit
											}
										}
									}
								}

								props.createField(fieldType, fieldNameVal, fieldDesc, extraData)
							} else {
								!dateDiffStartDateFieldName &&
									setDateDiffStartDateFieldNameValid(false)
								!dateDiffEndDateFieldName &&
									setDateDiffEndDateFieldNameValid(false)
								!dateDiffUnit && setDateDiffUnitValid(false)
							}
						} else if (formulaType === 'DateAdd') {
							if (
								dateAddDateFieldName &&
								dateAddNumberFieldName &&
								dateAddUnit
							) {
								const extraData = {
									formula: {
										formulaType: formulaType,
										formulaFieldDataType: 'Date',
										params: {
											dateAdd: {
												dateField: dateAddDateFieldName,
												numberField: dateAddNumberFieldName,
												unit: dateAddUnit
											}
										}
									}
								}

								props.createField(fieldType, fieldNameVal, fieldDesc, extraData)
							} else {
								!dateAddDateFieldName && setDateAddDateFieldNameValid(false)
								!dateAddNumberFieldName && setDateAddNumberFieldNameValid(false)
								!dateAddUnit && setDateAddUnitValid(false)
							}
						}
					} else {
						props.createField(fieldType, fieldNameVal, fieldDesc)
					}
				} else {
					setFieldTypeValid(false)
				}
			}
		})
	}
	const updateFieldName = () => {
		validateEmptyOrSpecial(fieldNameVal).then((isValid) => {
			if (isValid) {
				if (fieldType) {
					if (fieldType === 'Number') {
						const extraData = {
							precision: precision ?? 0
						}
						props.editField(
							selectedFieldId,
							fieldType,
							fieldNameVal,
							fieldDesc,
							extraData
						)
					} else if (fieldType === 'Date') {
						const extraData = {
							includeTime: includeTime ?? false
						}
						props.editField(
							selectedFieldId,
							fieldType,
							fieldNameVal,
							fieldDesc,
							extraData
						)
					} else if (fieldType === 'Rollup') {
						if (rollupRelationName && rollupFieldName && rollupAggregation) {
							const extraData = {
								relationName: rollupRelationName,
								displayFieldName: rollupFieldName,
								aggregation: rollupAggregation
							}

							props.editField(
								selectedFieldId,
								fieldType,
								fieldNameVal,
								fieldDesc,
								extraData
							)
						} else {
							!rollupRelationName && setRollupRelationNameValid(false)
							!rollupFieldName && setRollupFieldNameValid(false)
							!rollupAggregation && setRollupAggregationValid(false)
						}
					} else if (fieldDataTypes.convertibleTextBased.includes(fieldType)) {
						const extraData = {
							dataType: fieldType
						}
						props.editField(
							selectedFieldId,
							fieldType,
							fieldNameVal,
							fieldDesc,
							extraData
						)
					} else if (fieldType === 'Formula') {
						if (formulaType === 'Math') {
							if (mathTemplate) {
								const extraData = {
									formula: {
										formulaType: formulaType,
										formulaFieldDataType: 'Number',
										params: {
											math: {
												template: mathTemplate
											}
										}
									}
								}
								props.editField(
									selectedFieldId,
									fieldType,
									fieldNameVal,
									fieldDesc,
									extraData
								)
							} else {
								!mathTemplate && setMathTemplateValid(false)
							}
						} else if (formulaType === 'Template') {
							if (textTemplate) {
								const extraData = {
									formula: {
										formulaType: formulaType,
										formulaFieldDataType: 'Text',
										params: {
											template: {
												template: textTemplate
											}
										}
									}
								}
								props.editField(
									selectedFieldId,
									fieldType,
									fieldNameVal,
									fieldDesc,
									extraData
								)
							} else {
								!textTemplate && setTextTemplateValid(false)
							}
						} else if (formulaType === 'DateFormat') {
							if (dateFormatFieldName && dateFormatTemplate) {
								const extraData = {
									formula: {
										formulaType: formulaType,
										formulaFieldDataType: 'Text',
										params: {
											dateFormat: {
												template: dateFormatTemplate,
												fieldName: dateFormatFieldName
											}
										}
									}
								}
								props.editField(
									selectedFieldId,
									fieldType,
									fieldNameVal,
									fieldDesc,
									extraData
								)
							} else {
								!dateFormatFieldName && setDateFormatFieldNameValid(false)
								!dateFormatTemplate && setDateFormatTemplateValid(false)
							}
						} else if (formulaType === 'DateDifference') {
							if (
								dateDiffStartDateFieldName &&
								dateDiffEndDateFieldName &&
								dateDiffUnit
							) {
								const extraData = {
									formula: {
										formulaType: formulaType,
										formulaFieldDataType: 'Number',
										params: {
											dateDifference: {
												startDateFieldName: dateDiffStartDateFieldName,
												endDateFieldName: dateDiffEndDateFieldName,
												unit: dateDiffUnit
											}
										}
									}
								}
								props.editField(
									selectedFieldId,
									fieldType,
									fieldNameVal,
									fieldDesc,
									extraData
								)
							} else {
								!dateDiffStartDateFieldName &&
									setDateDiffStartDateFieldNameValid(false)
								!dateDiffEndDateFieldName &&
									setDateDiffEndDateFieldNameValid(false)
								!dateDiffUnit && setDateDiffUnitValid(false)
							}
						} else if (formulaType === 'DateAdd') {
							if (
								dateAddDateFieldName &&
								dateAddNumberFieldName &&
								dateAddUnit
							) {
								const extraData = {
									formula: {
										formulaType: formulaType,
										formulaFieldDataType: 'Date',
										params: {
											dateAdd: {
												dateField: dateAddDateFieldName,
												numberField: dateAddNumberFieldName,
												unit: dateAddUnit
											}
										}
									}
								}

								props.editField(
									selectedFieldId,
									fieldType,
									fieldNameVal,
									fieldDesc,
									extraData
								)
							} else {
								!dateAddDateFieldName && setDateAddDateFieldNameValid(false)
								!dateAddNumberFieldName && setDateAddNumberFieldNameValid(false)
								!dateAddUnit && setDateAddUnitValid(false)
							}
						}
					} else {
						props.editField(selectedFieldId, fieldType, fieldNameVal, fieldDesc)
					}
				} else {
					setFieldTypeValid(false)
				}
			}
		})
	}
	const fieldNameOnchange = (e) => {
		setFieldNameVal(e.target.value)
		validateEmptyOrSpecial(e.target.value)
	}

	const mathTemplateWhiteList =
		props?.fieldsData
			?.filter(
				(x) =>
					x.name !== fieldNameVal &&
					(x.dataType === 'Number' ||
						x.dataType === 'Checkbox' ||
						(x.displayFieldDataType &&
							(x.displayFieldDataType === 'Number' ||
								x.displayFieldDataType === 'Checkbox')) ||
						(x.formula &&
							(x.formula.formulaFieldDataType === 'Number' ||
								x.formula.formulaFieldDataType === 'Checkbox')))
			)
			?.map((item) => ({
				id: item.id,
				name: item.name,
				label: item.label
			})) ?? []

	const templateWhiteList =
		props?.fieldsData
			?.filter(
				(x) =>
					x.name !== fieldNameVal &&
					((x.dataType !== 'Relation' && x.dataType !== 'Lookup') ||
						(x.dataType === 'Lookup' && x.relationType === 'One'))
			)
			?.map((item) => ({
				id: item.id,
				name: item.name,
				label: item.label
			})) ?? []

	const dateFieldOpts =
		props?.fieldsData
			?.filter(
				(x) =>
					x.name !== fieldNameVal &&
					(x.dataType === 'Date' ||
						(x.displayFieldDataType && x.displayFieldDataType === 'Date') ||
						(x.formula &&
							x.formula.formulaFieldDataType &&
							x.formula.formulaFieldDataType === 'Date'))
			)
			?.map((item) => ({
				id: item.id,
				name: item.name,
				label: item.label
			})) ?? []

	const numberFieldOpts =
		props?.fieldsData
			?.filter(
				(x) =>
					x.name !== fieldNameVal &&
					(x.dataType === 'Number' ||
						(x.displayFieldDataType && x.displayFieldDataType === 'Number'))
			)
			?.map((item) => ({
				id: item.id,
				name: item.name,
				label: item.label
			})) ?? []

	const dataTypeOnChange = (e, option) => {
		if (option?.value) {
			setFieldType(option.value)
			setFieldTypeValid(true)

			setRelationEntityName(null)
			setRelationEntityNameValid(true)
			setRelationTypeMany(false)

			setLookupRelationName(null)
			setLookupRelationNameValid(true)

			setRollupRelationName(null)
			setRollupRelationNameValid(true)

			setPrecision(0)
			setIncludeTime(false)

			setFormulaType(null)
		} else {
			setFieldType(null)
			setFieldTypeValid(false)
		}
	}

	const formulaTypeOnChange = (e, option) => {
		if (option?.value) {
			setFormulaType(option.value)
			setFormulaTypeValid(true)

			switch (option?.value) {
				case 'Math':
					{
						setMathTemplate(null)
						setMathTemplateValid(true)
						setMathTemplateOpts(mathTemplateWhiteList)
					}
					break
				case 'Template':
					{
						setTextTemplate(null)
						setTextTemplateValid(true)
						setTextTemplateOpts(templateWhiteList)
					}
					break
				case 'DateFormat':
					{
						setDateFormatFieldName(null)
						setDateFormatFieldNameValid(true)
						setDateFormatTemplate(null)
						setDateFormatTemplateValid(true)

						setDateFormulaFieldNameOpts(dateFieldOpts)
					}
					break
				case 'DateDifference':
					{
						setDateDiffStartDateFieldName(null)
						setDateDiffStartDateFieldNameValid(true)
						setDateDiffEndDateFieldName(null)
						setDateDiffEndDateFieldNameValid(true)
						setDateDiffUnit('day')
						setDateDiffUnitValid(true)

						setDateFormulaFieldNameOpts(dateFieldOpts)
					}
					break
				case 'DateAdd':
					{
						setDateAddDateFieldName(null)
						setDateAddDateFieldNameValid(true)
						setDateAddNumberFieldName(null)
						setDateAddNumberFieldNameValid(true)
						setDateAddUnit('day')
						setDateAddUnitValid(true)

						setDateFormulaFieldNameOpts(dateFieldOpts)
						setNumberFieldNameOpts(numberFieldOpts)
					}
					break
			}
		} else {
			setFormulaType(null)
			setFormulaTypeValid(false)
		}
	}

	const initRelationFieldNameOpts = (tableName) => {
		const displayFields = getEntityFieldsByDataTypes(
			props?.tables,
			tableName,
			fieldDataTypes.relationDisplayFieldTypes,
			null,
			false,
			null
		)

		setRelationDisplayFieldNameOpts(displayFields)
	}

	const relationEntityNameOnChange = (e, option) => {
		if (option?.name) {
			setRelationEntityName(option.name)
			setRelationEntityNameValid(true)

			initRelationFieldNameOpts(option.name)
		} else {
			setRelationEntityName(null)
			setRelationEntityNameValid(false)
		}
	}
	const relationDisplayFieldNameOnChange = (e, option) => {
		if (option?.name) {
			setRelationDisplayFieldName(option.name)
			setRelationDisplayFieldNameValid(true)
		} else {
			setRelationDisplayFieldName(null)
			setRelationDisplayFieldNameValid(false)
		}
	}

	const initLookupFieldNameOpts = (tableName) => {
		var lookupTable = props?.tables.find((x) => x.name === tableName)

		var sortedFields = lookupTable?.fields
			.filter(
				(x) =>
					(x.dataType !== 'Formula' &&
						x.dataType !== 'Lookup' &&
						x.dataType !== 'Relation') ||
					(x.dataType === 'Relation' && x.relationType !== 'Many')
			)
			.sort(function (a, b) {
				return a.label.localeCompare(b.label)
			})

		setLookupDisplayFieldNameOpts(sortedFields)
	}

	const lookupRelationNameOnChange = (e, option) => {
		if (option?.name) {
			setLookupRelationName(option.name)
			setLookupRelationNameValid(true)

			initLookupFieldNameOpts(option.relatedEntityName)
		} else {
			setLookupRelationName(null)
			setLookupRelationNameValid(false)
		}
	}
	const lookupDisplayFieldNameOnChange = (e, option) => {
		if (option?.name) {
			setLookupDisplayFieldName(option.name)
			setLookupDisplayFieldNameValid(true)
		} else {
			setLookupDisplayFieldName(null)
			setLookupDisplayFieldNameValid(false)
		}
	}

	const initRollupFieldNameOpts = (table) => {
		var relationTable = props?.tables.find((x) => x.name === table)

		var sortedFields = relationTable?.fields
			.filter(
				(x) =>
					x.dataType !== 'RowId' &&
					x.dataType !== 'Relation' &&
					x.dataType !== 'Lookup' &&
					x.dataType !== 'Formula'
			)
			.sort(function (a, b) {
				return a.label.localeCompare(b.label)
			})

		setRollupFieldNameOpts(sortedFields)
	}

	const rollupRelationNameOnChange = (e, option) => {
		if (option?.name) {
			setRollupRelationName(option.name)
			setRollupRelationNameValid(true)

			initRollupFieldNameOpts(option.relatedEntityName)
		} else {
			setRollupRelationName(null)
			setRollupRelationNameValid(false)
		}
	}
	const rollupFieldNameOnChange = (e, option) => {
		if (option?.name) {
			setRollupFieldName(option.name)
			setRollupFieldNameValid(true)
		} else {
			setRollupFieldName(null)
			setRollupFieldNameValid(false)
		}
	}
	const rollupAggregationOnChange = (e, option) => {
		if (option?.value) {
			setRollupAggregation(option.value)
			setRollupAggregationValid(true)
		} else {
			setRollupAggregation(null)
			setRollupAggregationValid(false)
		}
	}

	const mathTemplateOnChange = (value) => {
		if (value) {
			try {
				let temp = value

				temp?.replace(/\[(.*?)\]/g, (tagText) => {
					temp = temp.replace(tagText, 0)
				})

				evaluateMath(temp)
				eval(temp)

				setMathTemplate(value)
				setMathTemplateValid(true)
			} catch (err) {
				setMathTemplate(null)
				setMathTemplateValid(false)
			}
		} else {
			setMathTemplate(null)
			setMathTemplateValid(false)
		}
	}

	const textTemplateOnChange = (value) => {
		if (value) {
			const isValid = !/[`]/.test(value)

			if (isValid) {
				setTextTemplate(value)
				setTextTemplateValid(true)
			} else {
				setTextTemplate(null)
				setTextTemplateValid(false)
			}
		} else {
			setTextTemplate(null)
			setTextTemplateValid(false)
		}
	}

	const dateFormatFieldNameOnChange = (e, option) => {
		if (option?.name) {
			setDateFormatFieldName(option.name)
			setDateFormatFieldNameValid(true)
		} else {
			setDateFormatFieldName(null)
			setDateFormatFieldNameValid(false)
		}
	}
	const dateFormatTemplateOnChange = (e) => {
		if (e?.target?.value) {
			let isValid = false

			try {
				const d = new Date()
				const fd = format(d, e?.target?.value)

				isValid = true
			} catch {
				isValid = false
			}

			// const isValid = !/[`]/.test(e.target.value)

			if (isValid) {
				setDateFormatTemplate(e.target.value)
				setDateFormatTemplateValid(true)
			} else {
				setDateFormatTemplate(null)
				setDateFormatTemplateValid(false)
			}
		} else {
			setDateFormatTemplate(null)
			setDateFormatTemplateValid(false)
		}
	}

	const dateDiffStartDateFieldNameOnChange = (e, option) => {
		if (option?.name) {
			setDateDiffStartDateFieldName(option.name)
			setDateDiffStartDateFieldNameValid(true)
		} else {
			setDateDiffStartDateFieldName(null)
			setDateDiffStartDateFieldNameValid(false)
		}
	}
	const dateDiffEndDateFieldNameOnChange = (e, option) => {
		if (option?.name) {
			setDateDiffEndDateFieldName(option.name)
			setDateDiffEndDateFieldNameValid(true)
		} else {
			setDateDiffEndDateFieldName(null)
			setDateDiffEndDateFieldNameValid(false)
		}
	}
	const dateDiffUnitChange = (e) => {
		setDateDiffUnit(e.target?.value ?? 'day')
	}

	const dateAddDateFieldNameOnChange = (e, option) => {
		if (option?.name) {
			setDateAddDateFieldName(option.name)
			setDateAddDateFieldNameValid(true)
		} else {
			setDateAddDateFieldName(null)
			setDateAddDateFieldNameValid(false)
		}
	}
	const dateAddNumberFieldNameOnChange = (e, option) => {
		if (option?.name) {
			setDateAddNumberFieldName(option.name)
			setDateAddNumberFieldNameValid(true)
		} else {
			setDateAddNumberFieldName(null)
			setDateAddNumberFieldNameValid(false)
		}
	}
	const dateAddUnitChange = (e) => {
		setDateAddUnit(e.target?.value ?? 'day')
	}

	const [precision, setPrecision] = useState(0)
	const precisionChange = (e) => {
		setPrecision(Number(e.target?.value ?? 0))
	}

	const [includeTime, setIncludeTime] = useState(false)
	const includeTimeChange = (e) => {
		setIncludeTime(e.target.checked)
	}

	const openFieldPop = (event) => {
		setFieldPop(event.currentTarget)
	}
	const closeFieldPop = () => {
		setFieldPop(null)
		setFieldNameVal('')
		setFieldNameValid(true)
		setFieldType(null)
		setFieldDesc('')
	}
	const fieldPopOpen = Boolean(fieldPop)

	const openEditPop = (
		position,
		fieldId,
		fieldName,
		fieldDesc,
		originalData
	) => {
		setEditPopPosition(position)
		setEditPopIsOpen(true)
		setSelectedFieldId(fieldId)
		setFieldNameVal(fieldName)
		setFieldDesc(fieldDesc)

		setFieldType(originalData?.dataType)
		setFieldTypeValid(true)

		setPrecision(originalData?.precision ?? 0)
		setIncludeTime(originalData?.includeTime ?? false)

		if (originalData?.dataType === 'Relation') {
			setRelationEntityName(originalData?.relatedEntityName)
			setRelationEntityNameValid(true)

			initRelationFieldNameOpts(originalData?.relatedEntityName)

			setRelationDisplayFieldName(originalData?.displayFieldName)
			setRelationDisplayFieldNameValid(true)

			setRelationTypeMany(originalData?.relationType === 'Many')
		} else if (originalData?.dataType === 'Lookup') {
			setLookupRelationName(originalData?.relationName)
			setLookupRelationNameValid(true)

			initLookupFieldNameOpts(originalData?.relatedEntityName)

			setLookupDisplayFieldName(originalData?.displayFieldName)
			setLookupDisplayFieldNameValid(true)
		} else if (originalData?.dataType === 'Rollup') {
			setRollupRelationName(originalData?.relationName)
			setRollupRelationNameValid(true)

			initRollupFieldNameOpts(originalData?.relatedEntityName)

			setRollupFieldName(originalData?.displayFieldName)
			setRollupFieldNameValid(true)

			setRollupAggregation(originalData?.aggregation)
			setRollupAggregationValid(true)
		} else if (originalData?.dataType === 'Formula') {
			setFormulaType(originalData?.formula?.formulaType)

			switch (originalData?.formula?.formulaType) {
				case 'Math':
					{
						setMathTemplate(originalData?.formula?.params?.math.template)
						setMathTemplateValid(true)
						setMathTemplateOpts(mathTemplateWhiteList)
					}
					break
				case 'Template':
					{
						setTextTemplate(originalData?.formula?.params?.template?.template)
						setTextTemplateValid(true)
						setTextTemplateOpts(templateWhiteList)
					}
					break
				case 'DateFormat':
					{
						setDateFormatFieldName(
							originalData?.formula?.params?.dateFormat?.fieldName
						)
						setDateFormatFieldNameValid(true)
						setDateFormatTemplate(
							originalData?.formula?.params?.dateFormat?.template
						)
						setDateFormatTemplateValid(true)

						setDateFormulaFieldNameOpts(dateFieldOpts)
					}
					break
				case 'DateDifference':
					{
						setDateDiffStartDateFieldName(
							originalData?.formula?.params?.dateDifference?.startDateFieldName
						)
						setDateDiffStartDateFieldNameValid(true)
						setDateDiffEndDateFieldName(
							originalData?.formula?.params?.dateDifference?.endDateFieldName
						)
						setDateDiffEndDateFieldNameValid(true)
						setDateDiffUnit(originalData?.formula?.params?.dateDifference?.unit)
						setDateDiffUnitValid(true)

						setDateFormulaFieldNameOpts(dateFieldOpts)
					}
					break
				case 'DateAdd':
					{
						setDateAddDateFieldName(
							originalData?.formula?.params?.dateAdd?.dateField
						)
						setDateAddDateFieldNameValid(true)
						setDateAddNumberFieldName(
							originalData?.formula?.params?.dateAdd?.numberField
						)
						setDateAddNumberFieldNameValid(true)
						setDateAddUnit(originalData?.formula?.params?.dateAdd?.unit)
						setDateAddUnitValid(true)

						setDateFormulaFieldNameOpts(dateFieldOpts)
						setNumberFieldNameOpts(numberFieldOpts)
					}
					break
			}
		}
	}
	const closeEditPop = () => {
		setEditPopIsOpen(false)
		setFieldNameVal('')
		setFieldNameValid(true)
		setFieldDesc('')
		setFieldType(null)
		setPrecision(0)
		setIncludeTime(false)

		setMathTemplate(null)
		setMathTemplateValid(true)
		setMathTemplateOpts(null)

		setTextTemplate(null)
		setTextTemplateValid(true)
		setTextTemplateOpts(null)

		setDateFormatFieldName(null)
		setDateFormatFieldNameValid(true)
		setDateFormatTemplate(null)
		setDateFormatTemplateValid(true)

		setDateDiffStartDateFieldName(null)
		setDateDiffStartDateFieldNameValid(true)
		setDateDiffEndDateFieldName(null)
		setDateDiffEndDateFieldNameValid(true)
		setDateDiffUnit(null)
		setDateDiffUnitValid(true)

		setDateFormulaFieldNameOpts(null)
		setNumberFieldNameOpts(null)
	}

	const openAPIDocModal = (event) => {
		props?.currentOrg?.plan?.includes('business')
			? setAPIDocModalVisibility(true)
			: (window.location.href = '/subscription?modal=true')
	}
	const hideAPIDocModal = () => {
		setAPIDocModalVisibility(false)
	}
	/******DELETE_FIELD*******************/
	const handleDeleteDialogClose = () => {
		setDeleteConfirmationVisible(false)
	}
	const handleDeleteDialogAccept = () => {
		props.deleteField(selectedFieldId)
		setDeleteConfirmationVisible(false)
	}

	/******DELETE_ROW*******************/
	const handleDelRowClick = () => {
		setDeleteRowConfirmationVisible(true)
	}
	const handleDeleteRowDialogClose = () => {
		setDeleteRowConfirmationVisible(false)
	}
	const handleDeleteRowDialogAccept = () => {
		props.deleteRows(selectedRows)
		setDeleteRowConfirmationVisible(false)
	}

	/******DENSITY*******************/
	const [density, setDensity] = useState('standard')
	const [densityPop, setDensityPop] = useState(null)

	const densityOpts = [
		{
			label: 'Compact',
			icon: DensitySmall,
			value: 'compact'
		},
		{
			label: 'Standart',
			icon: DensityMedium,
			value: 'standard'
		},
		{
			label: 'Comfortable',
			icon: DensityLarge,
			value: 'comfortable'
		}
	]
	const openDensityPop = (event) => {
		setDensityPop(event.currentTarget)
	}
	const closeDensityPop = () => {
		setDensityPop(null)
	}
	const densityPopOpen = Boolean(densityPop)

	/******COLUMN_VISIBILITY*******************/
	const [visibilitySearchTerm, setVisibilitySearchTerm] = useState('')
	const [colVisibilityPop, setColVisibilityPop] = useState(null)

	const openColVisibilityPop = (event) => {
		setColVisibilityPop(event.currentTarget)
	}
	const closeColVisibilityPop = () => {
		setColVisibilityPop(null)
	}
	const colVisibilityPopOpen = Boolean(colVisibilityPop)

	const initColumnVisibilityModel = (fieldsData) => {
		let createdModel = {}
		fieldsData?.map((x) => {
			createdModel[x.name] = !systemFieldNames.notVisible.includes(x.name)
		})

		setColumnVisibilityModel(createdModel)
	}

	const switchColumnVisibility = (modelField) => {
		let newModel = { ...columnVisibilityModel }
		newModel[modelField] = !columnVisibilityModel?.[modelField]

		setColumnVisibilityModel(newModel)
	}

	const setAllColumnVisibility = (val) => {
		let newModel = { ...columnVisibilityModel }
		Object.keys(newModel).forEach((x) => (newModel[x] = val))

		setColumnVisibilityModel(newModel)
	}

	const visibilitySearchTermOnchange = (e) => {
		setVisibilitySearchTerm(e.target.value)
	}

	/******SEARCH*******************/
	const [searchVal, setSearchVal] = useState('')

	const searchOnKeyPress = (e) => {
		if (e.charCode === 13) {
			props.search(e.target.value)
		}
	}

	/******IMAGE_PICKER*******************/
	// const postImage = (base64Image) => {
	// 	props.updateRow(
	// 		editState.id,
	// 		editState.field,
	// 		base64Image,
	// 		editState.value,
	// 		true
	// 	)
	// 	document.getElementById('image-picker').value = null
	// }

	const deleteImage = (rowId, field, currentVal) => {
		props.updateRow(rowId, field, null, currentVal, true)
	}

	// const pickImage = async (e) => {
	// 	if (e.target.files[0]) {
	// 		await convertBase64(e.target.files[0]).then((result) => {
	// 			// setImgName(result.name)
	// 			// setImage(result.data)
	// 			postImage(result.data) //TODO:Loader gerekirse editState row id kullanılabilir
	// 		})
	// 		// const imageUrl = URL.createObjectURL(e.target.files[0])

	// 		// setImageSrc(imageUrl)
	// 	}
	// }

	const openImagePicker = (id, name, oldVal) => {
		setUpdatingImage({
			id: id,
			name: name,
			oldVal: oldVal
		})
		imagePicker.current.open()
	}

	const downloadImage = (imageSrc, imageName) => {
		checkBase64(imageSrc).then(async (isBase64) => {
			let a = document.createElement('a')
			if (isBase64) {
				a.href = imageSrc
				a.download = imageName
			} else {
				a.href = imageSrc
				a.target = '_blank'
			}
			a.click()
		})
	}

	/******FILE_PICKER*******************/
	// const postFile = (base64File) => {
	// 	props.updateRow(
	// 		editState.id,
	// 		editState.field,
	// 		base64File,
	// 		editState.value,
	// 		true
	// 	)
	// 	document.getElementById('file-picker').value = null
	// }

	const deleteFile = (rowId, field, currentVal) => {
		props.updateRow(rowId, field, null, currentVal)
	}

	// const pickFile = async (e) => {
	// 	if (e.target.files[0]) {
	// 		convertBase64(e.target.files[0]).then((result) => {
	// 			postFile(result.data) //TODO:Loader gerekirse editState row id kullanılabilir
	// 		})
	// 	}
	// }

	// const openFilePicker = () => {
	// 	filePicker.current.click()
	// }

	const openFilePicker = (id, name, oldVal) => {
		setUpdatingFile({
			id: id,
			name: name,
			oldVal: oldVal
		})
		filePicker.current.open()
	}

	const downloadFile = (fileSource, downloadFileName) => {
		checkBase64(fileSource).then(async (isBase64) => {
			let a = document.createElement('a')
			if (isBase64) {
				a.href = fileSource
				a.download = downloadFileName
			} else {
				a.href = fileSource
				a.target = '_blank'
			}
			a.click()
		})
	}

	const multiLineOnchange = (e) => {
		setMultiLineVal(e.target.value)
	}
	const openMultiLinePop = (position) => {
		setCustomEditPopPosition(position)
		setMultiLinePopIsOpen(true)
	}
	const closeMultiLinePop = () => {
		setMultiLinePopIsOpen(false)
	}

	const editdataPopSubmit = (value, reload = false, objectProp = null) => {
		if (value != editState.value) {
			props.updateRow(
				editState.id,
				editState.field,
				value,
				editState.value,
				false,
				reload,
				objectProp
			)
		}
	}

	const relationOnchange = (e, val) => {
		if (!val || (Array.isArray(val) && val.length === 0)) {
			setRelationVal(val)
			editdataPopSubmit(val, true, 'Id')
			return
		}

		if (currentRelation?.type === 'One') {
			const submitVal = {
				Id: val?.Id,
				Name: val?.[currentRelation.displayFieldName]
			}
			const autocompleteVal = currentRelation.tableRows.find(
				(x) => x.Id === submitVal.Id
			)

			setRelationVal(autocompleteVal)
			editdataPopSubmit(submitVal, true, 'Id')
		} else if (currentRelation?.type === 'Many') {
			const submitVal = val.map((item) => {
				return {
					Id: item.Id,
					Name: item?.[currentRelation.displayFieldName]
				}
			})

			const autocompleteVal = currentRelation.tableRows.filter((x) =>
				submitVal.find((y) => y.Id === x.Id)
			)
			setRelationVal(autocompleteVal)
			editdataPopSubmit(submitVal, true, 'Id')
		}
	}
	const openRelationPop = (position) => {
		setCustomEditPopPosition(position)
		setRelationPopIsOpen(true)
	}
	const closeRelationPop = () => {
		setRelationPopIsOpen(false)
	}

	const deleteDataPopSubmit = () => {
		props.updateRow(editState.id, editState.field, null, editState.value)
	}

	const handleEditStart = (params, event) => {
		setEditState(params)
		const data = params.colDef.originalData
		if (data.dataType == 'LongText') {
			event.defaultMuiPrevented = true
			setMultiLineVal(params.value)
			openMultiLinePop({
				left: window.innerWidth / 2,
				top: window.innerHeight / 2
			})
		} else if (data.dataType == 'Relation') {
			event.defaultMuiPrevented = true
			const colDef = params.colDef.originalData
			const rowValues = params.row

			getRelationOptions(colDef).then(() => {
				const relatedTableRows = relationOptions?.[colDef?.relatedEntityName]
					?.length
					? relationOptions[colDef.relatedEntityName]
					: []

				const currentVal =
					colDef.relationType === 'Many'
						? relatedTableRows.filter((x) =>
								rowValues[colDef.name]?.find((y) => y.Id === x.Id)
						  )
						: relatedTableRows?.find((x) => rowValues[colDef.name]?.Id === x.Id)

				setCurrentRelation({
					tableRows: relatedTableRows,
					type: colDef.relationType,
					displayFieldName: colDef.displayFieldName,
					value: rowValues[colDef.name]
				})
				setRelationVal(currentVal)
				openRelationPop({
					left: window.innerWidth / 2,
					top: window.innerHeight / 2
				})
			})
		}
		// else if (data.dataType == 'File') {
		// 	event.defaultMuiPrevented = true
		// 	openFilePicker()
		// }
		//else if (data.dataType == 'Image') {
		// 	event.defaultMuiPrevented = true
		// 	openImagePicker()
		// }
	}
	const handleCellKeyDown = (params, event) => {
		if (event.keyCode == 46) {
			//DEL
			event.defaultMuiPrevented = true
			props.updateRow(params.id, params.field, null, params.value)
		}
	}
	const handleRowSelection = (selectedIds) => {
		setSelectedRows(selectedIds)
	}
	const handleSortModelChange = (sortModel) => {
		props.sort(sortModel)
	}

	const handleEdit = (params, event) => {}
	const formatCellByDataType = (cellParams) => {
		const currentCol = columns.find((x) => x.field === cellParams.field)
		let editedParams = { ...cellParams }

		switch (currentCol.originalData.dataType) {
			case 'Number':
				if (
					currentCol.originalData.precision &&
					currentCol.originalData.precision > 0
				) {
					editedParams.value = numberFormatter.format(
						Number(
							culture === 'tr-TR'
								? cellParams.value.replace(/\./g, '').replace(/,/g, '.')
								: cellParams.value.replace(/,/g, '')
						)
					)
				} else {
					editedParams.value = numberFormatter.format(
						Number(
							culture === 'tr-TR'
								? cellParams.value.replace(/\./g, '')
								: cellParams.value.replace(/,/g, '')
						)
					)
				}

				return editedParams
			default:
				return cellParams
		}
	}
	const handleCellCommit = (params /*, event*/) => {
		// event.defaultMuiPrevented = true
		if (params.value != editState?.value) {
			// props.updateRow(params.id, params.field, params.value, params.value)
			const editedParams = formatCellByDataType(params)
			props.updateRow(
				params.id,
				params.field,
				editedParams.value,
				editedParams.value,
				false,
				true
			)
		}
		// setEditState(null)
	}

	const setColumnsByFields = (fieldsData) => {
		setColumns([])
		const convertedData = convertColumnData(fieldsData)
		setColumns(convertedData)
	}

	const getRelationOptions = async (field) => {
		if (!relationOptions?.[field.relatedEntityName]) {
			const parameters = {
				sort: 'Created desc',
				fields: 'Id,' + field.displayFieldName
			}
			return await dataClient
				.searchData(appId, field.relatedEntityName, parameters)
				.then((response) => {
					if (response?.data) {
						relationOptions[field.relatedEntityName] = response.data.records

						setRelationOptions(relationOptions)
					} else {
						showToastResponseError(response)
					}
				})
		}
	}

	const setRowDataByEntity = (entityData) => {
		setRowData(entityData)
	}

	const handleColumnOrderChange = (params, event, details) => {
		const fieldNames = details.api
			.getAllColumns()
			.filter((x) => columns.some((y) => y.field === x.field))
			.map((x) => x.field)

		timeoutDelay(props.reOrder, fieldNames, 1000)
	}

	useEffect(() => {
		setRelationOptions([])
		setColumnsByFields(props.fieldsData)
		initColumnVisibilityModel(props.fieldsData)
	}, [props.fieldsData])

	useEffect(() => {
		setColumnsByFields(props.fieldsData)
		setRowDataByEntity(props.entityData)
	}, [props.entityData])

	useImperativeHandle(ref, () => ({
		closeFieldPop() {
			closeFieldPop()
		},
		closeEditPop() {
			closeEditPop()
		},
		closeMultiLinePop() {
			closeMultiLinePop()
		},
		clearPinnedColumns() {
			clearPinnedColumns()
		},
		pinDisplayNameField() {
			pinDisplayNameField()
		},
		scrollToTopInGrid() {
			scrollToTopInGrid()
		}
	}))

	const dataTypes = props?.metadata?.external
		? fieldDataTypes.externalData
		: fieldDataTypes.allData
	const formulaTypes = fieldDataTypes.formulaData

	// const handleDoubleCellClick = useCallback((params, event) => {
	// 	event.defaultMuiPrevented = true
	// }, [])
	// const handleCellKeyDown = useCallback((params, event) => {
	// 	if (params.cellMode === 'edit' && 'Enter' === event.key) {
	// 		event.defaultMuiPrevented = true
	// 	}
	// }, [])
	// const handleCellFocusOut = useCallback((params, event) => {
	// 	if (params.cellMode === 'edit' && event) {
	// 		//event.defaultMuiPrevented = true
	// 	}
	// }, [])

	const createRow = async () => {
		await props.createRow()
	}

	return (
		<>
			<UppyPicker
				ref={imagePicker}
				allowedFileTypes={['image/*']}
				uploadComplete={(result) => {
					result?.successful?.length &&
						props.updateRow(
							updatingImage.id,
							updatingImage.name,
							result.successful[0].uploadURL,
							updatingImage.oldVal,
							true
						)
				}}
			/>
			<UppyPicker
				ref={filePicker}
				allowedFileTypes={[
					'image/*',
					'.gif',
					'.pdf',
					'.xlsx',
					'.xls',
					'.pdf'
					// '.csv',
					// '.txt'
				]}
				uploadComplete={(result) => {
					result?.successful?.length &&
						props.updateRow(
							updatingFile.id,
							updatingFile.name,
							result.successful[0].uploadURL,
							updatingFile.oldVal,
							true
						)
				}}
			/>
			<Grid container item style={{ flex: 1 }}>
				<Box
					style={{
						width: '100%',
						backgroundColor: '#ffffff',
						borderRadius: 8,
						display: 'flex',
						flexDirection: 'column'
					}}
					sx={{
						'& .MuiDataGrid-cell--editing': {
							outline: 'none !important',
							'& .MuiDataGrid-editInputCell': {
								outline: '1px solid blue'
							}
						},
						'& .Mui-error': {
							outline: '1px solid red !important',
							color: (theme) => theme.custom.colors.red
						}
					}}
				>
					{props?.metadata && (
						<div
							style={{
								padding: '0px 6px 12px 6px',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'flex-start',
								justifyContent: 'space-between'
							}}
						>
							<div>
								<Button
									size='large'
									variant='contained'
									onClick={createRow}
									sx={{
										textTransform: 'none',
										backgroundColor: `${kozmikTheme.colors.kozmikGray} !important`,
										boxShadow: 'none !important',
										fontSize: 14,
										borderRadius: '4px',
										margin: '12px 6px 0px 6px'
									}}
								>
									<AddRoad
										className={cx(
											classes.kozmikTxtPrimary,
											classes.textButtonIcon
										)}
										style={{ transform: 'rotate(-90deg)' }}
									/>
									<Typography
										display={{ xs: 'none', md: 'block' }}
										className={cx(
											classes.popoverBtnTxt,
											classes.kozmikTxtPrimary
										)}
									>
										{t('appData.addData')}
									</Typography>
								</Button>

								{selectedRows?.length > 0 && (
									<Button
										size='large'
										variant='contained'
										onClick={handleDelRowClick}
										sx={{
											textTransform: 'none',
											backgroundColor: `${kozmikTheme.colors.kozmikGray} !important`,
											boxShadow: 'none !important',
											fontSize: 14,
											borderRadius: '4px',
											margin: '12px 0px 0px 6px'
										}}
									>
										<DeleteOutlined
											className={cx(
												classes.kozmikTxtPrimary,
												classes.textButtonIcon,
												classes.kozmikRed
											)}
										/>
										<Typography
											display={{ xs: 'none', md: 'block' }}
											className={cx(classes.popoverBtnTxt, classes.kozmikRed)}
										>
											{t('appData.deleteData')}
										</Typography>
									</Button>
								)}
							</div>
							<div
								style={{
									flexGrow: 1,
									display: 'flex',
									flexWrap: 'wrap',
									justifyContent: 'flex-end'
								}}
							>
								<Button
									size='large'
									variant='contained'
									onClick={openFieldPop}
									sx={{
										textTransform: 'none',
										backgroundColor: `${kozmikTheme.colors.kozmikGray} !important`,
										boxShadow: 'none !important',
										fontSize: 14,
										borderRadius: '4px',
										margin: '12px 6px 0px 0px'
									}}
								>
									<AddRoad
										className={cx(
											classes.kozmikTxtPrimary,
											classes.textButtonIcon
										)}
									/>
									<Typography
										display={{ xs: 'none', md: 'block' }}
										className={cx(
											classes.popoverBtnTxt,
											classes.kozmikTxtPrimary
										)}
									>
										{t('appData.addColumn')}
									</Typography>
								</Button>
								<Button
									size='large'
									variant='contained'
									onClick={openColVisibilityPop}
									sx={{
										textTransform: 'none',
										backgroundColor: `${kozmikTheme.colors.kozmikGray} !important`,
										boxShadow: 'none !important',
										fontSize: 14,
										borderRadius: '4px',
										margin: '12px 6px 0px 6px'
									}}
								>
									<VisibilityOff
										className={cx(classes.kozmikTxtPrimary, classes.buttonIcon)}
									/>
								</Button>
								<Button
									size='large'
									variant='contained'
									onClick={openDensityPop}
									sx={{
										textTransform: 'none',
										backgroundColor: `${kozmikTheme.colors.kozmikGray} !important`,
										boxShadow: 'none !important',
										fontSize: 14,
										borderRadius: '4px',
										margin: '12px 6px 0px 6px'
									}}
								>
									<Compress
										className={cx(classes.kozmikTxtPrimary, classes.buttonIcon)}
									/>
								</Button>
								<Button
									size='large'
									variant='contained'
									onClick={openAPIDocModal}
									sx={{
										textTransform: 'none',
										backgroundColor: `${kozmikTheme.colors.kozmikGray} !important`,
										boxShadow: 'none !important',
										fontSize: 14,
										borderRadius: '4px',
										margin: '12px 6px 0px 6px'
									}}
								>
									<Typography
										className={cx(
											classes.popoverBtnTxt,
											classes.kozmikTxtPrimary
										)}
									>
										{t('appData.apiDoc')}
									</Typography>
									{!props?.currentOrg?.plan?.includes('business') && (
										<Typography className={classes.badge}>
											{t('publishModule.upgrade')}
										</Typography>
									)}
								</Button>
								<KozmikTextField
									className={classes.searchInput}
									placeholder={t('appData.search')}
									value={searchVal}
									onChange={(e) => setSearchVal(e.target.value)}
									onKeyPress={searchOnKeyPress}
									bgColor={kozmikTheme.colors.kozmikGray}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<Search
													className={classes.inputIcon}
													onClick={() => props.search(searchVal)}
												/>
											</InputAdornment>
										)
									}}
								/>
							</div>
						</div>
					)}

					{!!columns?.length && (
						<LocalizationProvider
							dateAdapter={AdapterDateFns}
							adapterLocale={culture === 'tr-TR' ? localeTR : localeEN}
						>
							<StripedDataGrid
								// sx={{
								// }}
								// loading={true}
								// apiRef={dataGridApiRef}
								// pinnedColumns={pinnedColumns}
								// autoHeight={props.builderOnMobile}
								columnVisibilityModel={columnVisibilityModel}
								onPinnedColumnsChange={handlePinnedColumnsChange}
								getRowId={(row) => row.Id}
								density={density}
								components={{
									// Toolbar: DataTableToolbar,
									ColumnMenu: DataTableColumnMenu,
									BaseCheckbox: KozmikCheckBox,
									Footer: DataTableFooter
								}}
								localeText={
									culture == 'tr-TR'
										? trTR.components.MuiDataGrid.defaultProps.localeText
										: enUS.components.MuiDataGrid.defaultProps.localeText
								}
								componentsProps={{
									// toolbar: {
									// 	createRow: props.createRow,
									// 	selectedRows: selectedRows,
									// 	deleteRows: props.deleteRows,
									// 	openFieldPop: openFieldPop
									// },
									columnMenu: {
										sx: {
											'& .MuiTypography-root, .MuiMenuItem-root, .MuiListItem-root':
												{
													fontSize: 14
												}
										},
										openEditPop: openEditPop,
										setSelectedFieldId: setSelectedFieldId,
										setDeleteConfirmationVisible: setDeleteConfirmationVisible
									},
									panel: {
										sx: {
											'& .MuiTypography-root, .MuiButton-root': {
												fontSize: 14
											},
											'& .MuiDataGrid-filterForm .MuiInput-input': {
												fontSize: 14
											}
										}
									},
									footer: {
										totalRecords: props.totalRecords
									}
								}}
								onColumnOrderChange={handleColumnOrderChange}
								onCellEditCommit={handleCellCommit}
								onCellEditStart={handleEditStart}
								onCellKeyDown={handleCellKeyDown}
								onEditCellPropsChange={handleEdit}
								onSortModelChange={handleSortModelChange}
								sortingMode='server'
								columns={columns}
								rows={rowData}
								// loading={true}
								hideFooterPagination
								onRowsScrollEnd={handleOnRowsScrollEnd}
								headerHeight={40}
								rowHeight={38}
								checkboxSelection
								onSelectionModelChange={handleRowSelection}
								disableSelectionOnClick
								// onCellDoubleClick={handleDoubleCellClick}
								// onCellKeyDown={handleCellKeyDown}
								// onCellFocusOut={handleCellFocusOut}
							/>
						</LocalizationProvider>
					)}

					<Popover
						id={'density-popover'}
						open={densityPopOpen}
						anchorEl={densityPop}
						onClose={closeDensityPop}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
					>
						<Box className={classes.popoverBox}>
							{densityOpts.map((item, index) => (
								<DensityMenuItem
									key={index}
									item={item}
									isActive={item.value == density}
									onClick={() => setDensity(item.value)}
								/>
							))}
						</Box>
					</Popover>
					<Popover
						id={'column-visibility-popover'}
						open={colVisibilityPopOpen}
						anchorEl={colVisibilityPop}
						onClose={closeColVisibilityPop}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
					>
						<Grid container className={classes.columnVisibilityBox}>
							<Grid item xs={12} className={classes.headerBox}>
								<KozmikTextField
									className={classes.inputWithAdornment}
									fullWidth
									placeholder={t('appData.visibilityPop.search')}
									value={visibilitySearchTerm}
									onChange={visibilitySearchTermOnchange}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<Box className={classes.adornment}>
													<Search className={classes.inputIcon} />
												</Box>
											</InputAdornment>
										)
									}}
								/>
								{/* <BorderlessTextField
									className={classes.inputWithAdornment}
									size='small'
									variant='outlined'
									fullWidth={true}
									placeholder={t('appData.visibilityPop.search')}
									value={visibilitySearchTerm}
									onChange={visibilitySearchTermOnchange}
									InputProps={{
										classes: {
											input: classes.popoverInput
										},
										startAdornment: (
											<InputAdornment position='start'>
												<Box className={classes.adornment}>
													<Search className={classes.inputIcon} />
												</Box>
											</InputAdornment>
										)
									}}
								/> */}
							</Grid>
							<Grid item xs={12} className={classes.scrollBox}>
								<FormGroup>
									{props.fieldsData
										?.filter((x) =>
											x.label
												.toLowerCase()
												.includes(visibilitySearchTerm.toLowerCase())
										)
										?.map((item, index) => (
											<FormControlLabel
												key={`colVisibility_${index}`}
												classes={{ label: classes.checkLabel }}
												control={
													<KozmikCheckBox
														checked={columnVisibilityModel[item.name]}
														icon={
															<VisibilityOff className={classes.checkIcon} />
														}
														checkedIcon={
															<Visibility
																className={cx(
																	classes.kozmikBlue,
																	classes.checkIcon
																)}
															/>
														}
														onChange={() => switchColumnVisibility(item.name)}
														name={item.label}
													/>
												}
												label={item.label}
											/>
										))}
								</FormGroup>
							</Grid>
							<Grid
								container
								item
								xs={12}
								justifyContent='space-between'
								style={{ borderTop: `2px solid ${kozmikTheme.colors.gray}` }}
								className={classes.footerBox}
							>
								<Box
									onClick={() => setAllColumnVisibility(false)}
									className={classes.allBox}
								>
									<VisibilityOff className={classes.checkIcon} />
									<Typography className={classes.allLabel}>
										{t('appData.visibilityPop.hideAll')}
									</Typography>
								</Box>
								<Box
									onClick={() => setAllColumnVisibility(true)}
									className={classes.allBox}
								>
									<Visibility
										className={cx(classes.checkIcon, classes.kozmikBlue)}
									/>
									<Typography className={classes.allLabel}>
										{t('appData.visibilityPop.showAll')}
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Popover>
					<Popover
						id={'field-popover'}
						open={fieldPopOpen}
						anchorEl={fieldPop}
						onClose={closeFieldPop}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
					>
						<Box className={classes.popoverBox}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<KozmikTextField
										error={!fieldNameValid}
										helperText={
											fieldNameValid ? '' : t('appData.fieldValidationError')
										}
										fullWidth
										placeholder={t('appData.fieldNameInput')}
										value={fieldNameVal}
										onChange={fieldNameOnchange}
										autoFocus
									/>
								</Grid>
								<Grid item xs={12}>
									<KozmikTextField
										fullWidth
										placeholder={t('appData.fieldDescInput')}
										value={fieldDesc}
										onChange={(e) => setFieldDesc(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12}>
									<Autocomplete
										size='small'
										sx={{
											'& .MuiOutlinedInput-root': {
												borderRadius: '4px',
												backgroundColor: kozmikTheme.colors.kozmikGray,
												fontSize: '14px',
												color: kozmikTheme.colors.textPrimary
											}
										}}
										options={dataTypes}
										autoHighlight
										getOptionLabel={(option) => option.label}
										onChange={dataTypeOnChange}
										renderOption={(props, option) => (
											<div {...props} style={{ fontSize: 14 }}>
												<option.icon
													sx={{
														fontSize: '18px'
													}}
													style={{ marginRight: 5 }}
												/>
												{option.label}
											</div>
										)}
										renderInput={(params) => (
											<KozmikTextField
												error={!fieldTypeValid}
												helperText={
													fieldTypeValid ? '' : t('appData.requiredError')
												}
												fullWidth
												placeholder={t('appData.fieldDataTypeInput')}
												{...params}
												inputProps={{
													...params.inputProps
												}}
											/>
										)}
									/>
								</Grid>
								{fieldType === 'Date' && (
									<Grid item xs={12}>
										<FormControlLabel
											label={t('appData.fieldIncludeTimeInput')}
											classes={{ label: classes.checkFieldLabel }}
											control={
												<Checkbox
													checked={includeTime}
													onChange={includeTimeChange}
												/>
											}
										/>
									</Grid>
								)}
								{fieldType === 'Number' && (
									<Grid item xs={12}>
										<SelectBox
											className={classes.input}
											onChange={precisionChange}
											value={precision}
											data={precisionOpts}
											textKey='label'
											valueKey='value'
											placeholder={t('appData.fieldPrecisionInput')}
										/>
									</Grid>
								)}
								{fieldType === 'Relation' && (
									<>
										<Grid item xs={12}>
											<Autocomplete
												size='small'
												sx={{
													'& .MuiOutlinedInput-root': {
														borderRadius: '4px',
														backgroundColor: kozmikTheme.colors.kozmikGray,
														fontSize: '14px',
														color: kozmikTheme.colors.textPrimary
													}
												}}
												options={props?.tables}
												autoHighlight
												getOptionLabel={(option) => option.label}
												onChange={relationEntityNameOnChange}
												renderOption={(props, option) => (
													<div {...props} style={{ fontSize: 14 }}>
														{option.label}
													</div>
												)}
												renderInput={(params) => (
													<KozmikTextField
														error={!relationEntityNameValid}
														helperText={
															relationEntityNameValid
																? ''
																: t('appData.requiredError')
														}
														fullWidth
														placeholder={t('appData.relatedEntity')}
														{...params}
														inputProps={{
															...params.inputProps
														}}
													/>
												)}
											/>
										</Grid>
										{!!relationEntityName && (
											<Grid item xs={12}>
												<Autocomplete
													size='small'
													sx={{
														'& .MuiOutlinedInput-root': {
															borderRadius: '4px',
															backgroundColor: kozmikTheme.colors.kozmikGray,
															fontSize: '14px',
															color: kozmikTheme.colors.textPrimary
														}
													}}
													options={relationDisplayFieldNameOpts}
													autoHighlight
													getOptionLabel={(option) => option.label}
													onChange={relationDisplayFieldNameOnChange}
													renderOption={(props, option) => (
														<div {...props} style={{ fontSize: 14 }}>
															{option.label}
														</div>
													)}
													renderInput={(params) => (
														<KozmikTextField
															error={!relationDisplayFieldNameValid}
															helperText={
																relationDisplayFieldNameValid
																	? ''
																	: t('appData.requiredError')
															}
															fullWidth
															placeholder={t(
																'appData.relationDisplayFieldName'
															)}
															{...params}
															inputProps={{
																...params.inputProps
															}}
														/>
													)}
												/>
											</Grid>
										)}
										<Grid item xs={12}>
											<div className={cx(classes.item)}>
												<div>
													<KozmikCheckBox
														checked={relationTypeMany}
														onChange={(e) => {
															setRelationTypeMany(e.target.checked)
														}}
													/>
												</div>
												<div className={cx(classes.labelBox, classes.growBox)}>
													<Label>{t('appData.relationTypeMany')}</Label>
												</div>
											</div>
										</Grid>
									</>
								)}
								{fieldType === 'Lookup' && (
									<>
										<Grid item xs={12}>
											<Autocomplete
												size='small'
												sx={{
													'& .MuiOutlinedInput-root': {
														borderRadius: '4px',
														backgroundColor: kozmikTheme.colors.kozmikGray,
														fontSize: '14px',
														color: kozmikTheme.colors.textPrimary
													}
												}}
												options={props?.fieldsData?.filter(
													(x) => x.dataType === 'Relation'
												)}
												autoHighlight
												getOptionLabel={(option) => option.label}
												onChange={lookupRelationNameOnChange}
												renderOption={(props, option) => (
													<div {...props} style={{ fontSize: 14 }}>
														{option.label}
													</div>
												)}
												renderInput={(params) => (
													<KozmikTextField
														error={!lookupRelationNameValid}
														helperText={
															lookupRelationNameValid
																? ''
																: t('appData.requiredError')
														}
														fullWidth
														placeholder={t('appData.lookupRelationName')}
														{...params}
														inputProps={{
															...params.inputProps
														}}
													/>
												)}
											/>
										</Grid>
										{!!lookupRelationName && (
											<Grid item xs={12}>
												<Autocomplete
													size='small'
													sx={{
														'& .MuiOutlinedInput-root': {
															borderRadius: '4px',
															backgroundColor: kozmikTheme.colors.kozmikGray,
															fontSize: '14px',
															color: kozmikTheme.colors.textPrimary
														}
													}}
													options={lookupDisplayFieldNameOpts}
													autoHighlight
													getOptionLabel={(option) => option.label}
													onChange={lookupDisplayFieldNameOnChange}
													renderOption={(props, option) => (
														<div {...props} style={{ fontSize: 14 }}>
															{option.label}
														</div>
													)}
													renderInput={(params) => (
														<KozmikTextField
															error={!lookupDisplayFieldNameValid}
															helperText={
																lookupDisplayFieldNameValid
																	? ''
																	: t('appData.requiredError')
															}
															fullWidth
															placeholder={t('appData.lookupDisplayFieldName')}
															{...params}
															inputProps={{
																...params.inputProps
															}}
														/>
													)}
												/>
											</Grid>
										)}
									</>
								)}
								{fieldType === 'Rollup' && (
									<>
										<Grid item xs={12}>
											<Autocomplete
												size='small'
												sx={{
													'& .MuiOutlinedInput-root': {
														borderRadius: '4px',
														backgroundColor: kozmikTheme.colors.kozmikGray,
														fontSize: '14px',
														color: kozmikTheme.colors.textPrimary
													}
												}}
												options={props?.fieldsData?.filter(
													(x) =>
														x.dataType === 'Relation' &&
														x.relationType === 'Many'
												)}
												autoHighlight
												getOptionLabel={(option) => option.label}
												onChange={rollupRelationNameOnChange}
												renderOption={(props, option) => (
													<div {...props} style={{ fontSize: 14 }}>
														{option.label}
													</div>
												)}
												renderInput={(params) => (
													<KozmikTextField
														error={!rollupRelationNameValid}
														helperText={
															rollupRelationNameValid
																? ''
																: t('appData.requiredError')
														}
														fullWidth
														placeholder={t('appData.rollupRelationName')}
														{...params}
														inputProps={{
															...params.inputProps
														}}
													/>
												)}
											/>
										</Grid>
										{!!rollupRelationName && (
											<Grid item xs={12}>
												<Autocomplete
													size='small'
													sx={{
														'& .MuiOutlinedInput-root': {
															borderRadius: '4px',
															backgroundColor: kozmikTheme.colors.kozmikGray,
															fontSize: '14px',
															color: kozmikTheme.colors.textPrimary
														}
													}}
													options={rollupFieldNameOpts}
													autoHighlight
													getOptionLabel={(option) => option.label}
													onChange={rollupFieldNameOnChange}
													renderOption={(props, option) => (
														<div {...props} style={{ fontSize: 14 }}>
															{option.label}
														</div>
													)}
													renderInput={(params) => (
														<KozmikTextField
															error={!rollupFieldNameValid}
															helperText={
																rollupFieldNameValid
																	? ''
																	: t('appData.requiredError')
															}
															fullWidth
															placeholder={t('appData.rollupFieldName')}
															{...params}
															inputProps={{
																...params.inputProps
															}}
														/>
													)}
												/>
											</Grid>
										)}
										{!!rollupFieldName && (
											<Grid item xs={12}>
												<Autocomplete
													size='small'
													sx={{
														'& .MuiOutlinedInput-root': {
															borderRadius: '4px',
															backgroundColor: kozmikTheme.colors.kozmikGray,
															fontSize: '14px',
															color: kozmikTheme.colors.textPrimary
														}
													}}
													options={aggOperators}
													autoHighlight
													getOptionLabel={(option) => option.label}
													onChange={rollupAggregationOnChange}
													renderOption={(props, option) => (
														<div {...props} style={{ fontSize: 14 }}>
															{option.label}
														</div>
													)}
													renderInput={(params) => (
														<KozmikTextField
															error={!rollupAggregationValid}
															helperText={
																rollupAggregationValid
																	? ''
																	: t('appData.requiredError')
															}
															fullWidth
															placeholder={t('appData.rollupAggregation')}
															{...params}
															inputProps={{
																...params.inputProps
															}}
														/>
													)}
												/>
											</Grid>
										)}
									</>
								)}
								{fieldType === 'Formula' && (
									<>
										<Grid item xs={12}>
											<Autocomplete
												size='small'
												sx={{
													'& .MuiOutlinedInput-root': {
														borderRadius: '4px',
														backgroundColor: kozmikTheme.colors.kozmikGray,
														fontSize: '14px',
														color: kozmikTheme.colors.textPrimary
													}
												}}
												options={formulaTypes}
												autoHighlight
												getOptionLabel={(option) => option.label}
												onChange={formulaTypeOnChange}
												renderOption={(props, option) => (
													<div {...props} style={{ fontSize: 14 }}>
														<option.icon
															sx={{
																fontSize: '18px'
															}}
															style={{ marginRight: 5 }}
														/>
														{option.label}
													</div>
												)}
												renderInput={(params) => (
													<KozmikTextField
														error={!formulaTypeValid}
														helperText={
															formulaTypeValid ? '' : t('appData.requiredError')
														}
														fullWidth
														placeholder={t('appData.fieldFormulaTypeInput')}
														{...params}
														inputProps={{
															...params.inputProps
														}}
													/>
												)}
											/>
										</Grid>
										{formulaType === 'Math' && (
											<Grid item xs={12}>
												<MentionsInput
													placeholder={t('appData.placeholders.math')}
													delay={0}
													whitelist={mathTemplateOpts}
													onChange={mathTemplateOnChange}
													value={mathTemplate}
													multiline={false}
													labelKey='label'
													inputStyle={{
														borderRadius: 4,
														backgroundColor: kozmikTheme.colors.kozmikGray,
														fontSize: '14px',
														color: kozmikTheme.colors.textPrimary
													}}
												></MentionsInput>
												{mathTemplateValid && (
													<Typography className={classes.tips}>
														<Info className={classes.tipsIcon} />
														{t('appData.tips.template')}
													</Typography>
												)}
												{!mathTemplateValid && (
													<Typography className={classes.templateNotValid}>
														<Info className={classes.templateNotValidIcon} />
														{t('appData.validations.math')}
													</Typography>
												)}
											</Grid>
										)}
										{formulaType === 'Template' && (
											<Grid item xs={12}>
												<MentionsInput
													placeholder={t('appData.placeholders.template')}
													delay={0}
													whitelist={textTemplateOpts}
													onChange={textTemplateOnChange}
													value={textTemplate}
													multiline={false}
													labelKey='label'
													inputStyle={{
														borderRadius: 4,
														backgroundColor: kozmikTheme.colors.kozmikGray,
														fontSize: '14px',
														color: kozmikTheme.colors.textPrimary
													}}
												></MentionsInput>
												{textTemplateValid && (
													<Typography className={classes.tips}>
														<Info className={classes.tipsIcon} />
														{t('appData.tips.template')}
													</Typography>
												)}
												{!textTemplateValid && (
													<Typography className={classes.templateNotValid}>
														<Info className={classes.templateNotValidIcon} />
														{t('appData.validations.template')}
													</Typography>
												)}
											</Grid>
										)}
										{formulaType === 'DateFormat' && (
											<>
												<Grid item xs={12}>
													<Autocomplete
														size='small'
														sx={{
															'& .MuiOutlinedInput-root': {
																borderRadius: '4px',
																backgroundColor: kozmikTheme.colors.kozmikGray,
																fontSize: '14px',
																color: kozmikTheme.colors.textPrimary
															}
														}}
														options={dateFormulaFieldNameOpts}
														autoHighlight
														getOptionLabel={(option) => option.label}
														onChange={dateFormatFieldNameOnChange}
														renderOption={(props, option) => (
															<div {...props} style={{ fontSize: 14 }}>
																{option.label}
															</div>
														)}
														renderInput={(params) => (
															<KozmikTextField
																error={!dateFormatFieldNameValid}
																helperText={
																	dateFormatFieldNameValid
																		? ''
																		: t('appData.requiredError')
																}
																fullWidth
																placeholder={t('appData.fieldNameInput')}
																{...params}
																inputProps={{
																	...params.inputProps
																}}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={12}>
													<KozmikTextField
														error={!dateFormatTemplateValid}
														helperText={
															dateFormatTemplateValid
																? ''
																: t('appData.fieldValidationError')
														}
														fullWidth
														placeholder={t('appData.dateFormatTemplateInput')}
														value={dateFormatTemplate}
														onChange={dateFormatTemplateOnChange}
													/>
												</Grid>
											</>
										)}
										{formulaType === 'DateDifference' && (
											<>
												<Grid item xs={12}>
													<Autocomplete
														size='small'
														sx={{
															'& .MuiOutlinedInput-root': {
																borderRadius: '4px',
																backgroundColor: kozmikTheme.colors.kozmikGray,
																fontSize: '14px',
																color: kozmikTheme.colors.textPrimary
															}
														}}
														options={dateFormulaFieldNameOpts}
														autoHighlight
														getOptionLabel={(option) => option.label}
														onChange={dateDiffStartDateFieldNameOnChange}
														renderOption={(props, option) => (
															<div {...props} style={{ fontSize: 14 }}>
																{option.label}
															</div>
														)}
														renderInput={(params) => (
															<KozmikTextField
																error={!dateDiffStartDateFieldNameValid}
																helperText={
																	dateDiffStartDateFieldNameValid
																		? ''
																		: t('appData.requiredError')
																}
																fullWidth
																placeholder={t(
																	'appData.dateDiffStartDateInput'
																)}
																{...params}
																inputProps={{
																	...params.inputProps
																}}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={12}>
													<Autocomplete
														size='small'
														sx={{
															'& .MuiOutlinedInput-root': {
																borderRadius: '4px',
																backgroundColor: kozmikTheme.colors.kozmikGray,
																fontSize: '14px',
																color: kozmikTheme.colors.textPrimary
															}
														}}
														options={dateFormulaFieldNameOpts}
														autoHighlight
														getOptionLabel={(option) => option.label}
														onChange={dateDiffEndDateFieldNameOnChange}
														renderOption={(props, option) => (
															<div {...props} style={{ fontSize: 14 }}>
																{option.label}
															</div>
														)}
														renderInput={(params) => (
															<KozmikTextField
																error={!dateDiffEndDateFieldNameValid}
																helperText={
																	dateDiffEndDateFieldNameValid
																		? ''
																		: t('appData.requiredError')
																}
																fullWidth
																placeholder={t('appData.dateDiffEndDateInput')}
																{...params}
																inputProps={{
																	...params.inputProps
																}}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={12}>
													<SelectBox
														className={classes.input}
														onChange={dateDiffUnitChange}
														value={dateDiffUnit}
														data={unitOpts}
														textKey='label'
														valueKey='value'
														placeholder={t('appData.dateDiffUnitInput')}
													/>
												</Grid>
											</>
										)}
										{formulaType === 'DateAdd' && (
											<>
												<Grid item xs={12}>
													<Autocomplete
														size='small'
														sx={{
															'& .MuiOutlinedInput-root': {
																borderRadius: '4px',
																backgroundColor: kozmikTheme.colors.kozmikGray,
																fontSize: '14px',
																color: kozmikTheme.colors.textPrimary
															}
														}}
														options={dateFormulaFieldNameOpts}
														autoHighlight
														getOptionLabel={(option) => option.label}
														onChange={dateAddDateFieldNameOnChange}
														renderOption={(props, option) => (
															<div {...props} style={{ fontSize: 14 }}>
																{option.label}
															</div>
														)}
														renderInput={(params) => (
															<KozmikTextField
																error={!dateAddDateFieldNameValid}
																helperText={
																	dateAddDateFieldNameValid
																		? ''
																		: t('appData.requiredError')
																}
																fullWidth
																placeholder={t('appData.dateAddDateInput')}
																{...params}
																inputProps={{
																	...params.inputProps
																}}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={12}>
													<Autocomplete
														size='small'
														sx={{
															'& .MuiOutlinedInput-root': {
																borderRadius: '4px',
																backgroundColor: kozmikTheme.colors.kozmikGray,
																fontSize: '14px',
																color: kozmikTheme.colors.textPrimary
															}
														}}
														options={numberFieldOpts}
														autoHighlight
														getOptionLabel={(option) => option.label}
														onChange={dateAddNumberFieldNameOnChange}
														renderOption={(props, option) => (
															<div {...props} style={{ fontSize: 14 }}>
																{option.label}
															</div>
														)}
														renderInput={(params) => (
															<KozmikTextField
																error={!dateAddNumberFieldNameValid}
																helperText={
																	dateAddNumberFieldNameValid
																		? ''
																		: t('appData.requiredError')
																}
																fullWidth
																placeholder={t('appData.dateAddNumberInput')}
																{...params}
																inputProps={{
																	...params.inputProps
																}}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={12}>
													<SelectBox
														className={classes.input}
														onChange={dateAddUnitChange}
														value={dateAddUnit}
														data={unitOpts}
														textKey='label'
														valueKey='value'
														placeholder={t('appData.dateAddUnitInput')}
													/>
												</Grid>
											</>
										)}
									</>
								)}

								<Grid item container xs={12} justifyContent='space-between'>
									<Button
										size='small'
										variant='contained'
										onClick={closeFieldPop}
										sx={{
											textTransform: 'none',
											backgroundColor: `#ffffff !important`,
											boxShadow: 'none !important',
											fontSize: 16,
											borderRadius: '4px',
											width: '48%'
										}}
									>
										<Typography
											className={cx(classes.popoverBtnTxt, classes.kozmikBlue)}
										>
											{t('appData.fieldCancelButton')}
										</Typography>
									</Button>
									<Button
										size='small'
										variant='contained'
										onClick={submitFieldName}
										sx={{
											textTransform: 'none',
											backgroundColor: `${kozmikTheme.colors.kozmikLightBlue} !important`,
											boxShadow: 'none !important',
											fontSize: 16,
											borderRadius: '4px',
											width: '48%'
										}}
									>
										<Typography
											className={cx(classes.popoverBtnTxt, classes.kozmikBlue)}
										>
											{t('appData.fieldNameButton')}
										</Typography>
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Popover>
					<Popover
						id={'edit-popover'}
						anchorReference='anchorPosition'
						anchorPosition={editPopPosition}
						open={editPopIsOpen}
						onClose={closeEditPop}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
					>
						<Box className={classes.popoverBox}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<KozmikTextField
										error={!fieldNameValid}
										helperText={
											fieldNameValid ? '' : t('appData.fieldValidationError')
										}
										fullWidth
										placeholder={t('appData.fieldNameInput')}
										value={fieldNameVal}
										onChange={fieldNameOnchange}
									/>
								</Grid>
								<Grid item xs={12}>
									<KozmikTextField
										fullWidth
										placeholder={t('appData.fieldDescInput')}
										value={fieldDesc}
										onChange={(e) => setFieldDesc(e.target.value)}
									/>
								</Grid>
								{fieldDataTypes.convertibleTextBased.includes(fieldType) && (
									<Grid item xs={12}>
										<Autocomplete
											size='small'
											sx={{
												'& .MuiOutlinedInput-root': {
													borderRadius: '4px',
													backgroundColor: kozmikTheme.colors.kozmikGray,
													fontSize: '14px',
													color: kozmikTheme.colors.textPrimary
												}
											}}
											value={
												fieldDataTypes.allData.find(
													(x) => x.value === fieldType
												) ?? ''
											}
											options={fieldDataTypes.allData.filter((x) =>
												fieldDataTypes.convertibleTextBased.includes(x.value)
											)}
											autoHighlight
											getOptionLabel={(option) => option.label}
											onChange={dataTypeOnChange}
											renderOption={(props, option) => (
												<div {...props} style={{ fontSize: 14 }}>
													<option.icon
														sx={{
															fontSize: '18px'
														}}
														style={{ marginRight: 5 }}
													/>
													{option.label}
												</div>
											)}
											renderInput={(params) => (
												<KozmikTextField
													error={!fieldTypeValid}
													helperText={
														fieldTypeValid ? '' : t('appData.requiredError')
													}
													fullWidth
													placeholder={t('appData.fieldDataTypeInput')}
													{...params}
													inputProps={{
														...params.inputProps
													}}
												/>
											)}
										/>
									</Grid>
								)}
								{fieldType === 'Date' && (
									<Grid item xs={12}>
										<FormControlLabel
											label={t('appData.fieldIncludeTimeInput')}
											classes={{ label: classes.checkFieldLabel }}
											control={
												<Checkbox
													checked={includeTime}
													onChange={includeTimeChange}
												/>
											}
										/>
									</Grid>
								)}
								{fieldType === 'Number' && (
									<Grid item xs={12}>
										<SelectBox
											className={classes.input}
											onChange={precisionChange}
											value={precision}
											data={precisionOpts}
											textKey='label'
											valueKey='value'
											placeholder={t('appData.fieldPrecisionInput')}
										/>
									</Grid>
								)}
								{fieldType === 'Relation' && (
									<>
										<Grid item xs={12}>
											<Autocomplete
												disabled={true}
												size='small'
												sx={{
													'& .MuiOutlinedInput-root': {
														borderRadius: '4px',
														backgroundColor: kozmikTheme.colors.kozmikGray,
														fontSize: '14px',
														color: kozmikTheme.colors.textPrimary
													},
													'& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
														{
															border: 0
														}
												}}
												value={props?.tables?.find(
													(x) => x.name === relationEntityName
												)}
												options={props?.tables}
												autoHighlight
												getOptionLabel={(option) => option.label}
												onChange={relationEntityNameOnChange}
												renderOption={(props, option) => (
													<div {...props} style={{ fontSize: 14 }}>
														{option.label}
													</div>
												)}
												renderInput={(params) => (
													<KozmikTextField
														error={!relationEntityNameValid}
														helperText={
															relationEntityNameValid
																? ''
																: t('appData.requiredError')
														}
														fullWidth
														placeholder={t('appData.relatedEntity')}
														{...params}
														inputProps={{
															...params.inputProps
														}}
													/>
												)}
											/>
										</Grid>
										{!!relationEntityName && (
											<Grid item xs={12}>
												<Autocomplete
													disabled={true}
													size='small'
													sx={{
														'& .MuiOutlinedInput-root': {
															borderRadius: '4px',
															backgroundColor: kozmikTheme.colors.kozmikGray,
															fontSize: '14px',
															color: kozmikTheme.colors.textPrimary
														},
														'& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
															{
																border: 0
															}
													}}
													value={relationDisplayFieldNameOpts?.find(
														(x) => x.name === relationDisplayFieldName
													)}
													options={relationDisplayFieldNameOpts}
													autoHighlight
													getOptionLabel={(option) => option.label}
													onChange={relationDisplayFieldNameOnChange}
													renderOption={(props, option) => (
														<div {...props} style={{ fontSize: 14 }}>
															{option.label}
														</div>
													)}
													renderInput={(params) => (
														<KozmikTextField
															error={!relationDisplayFieldNameValid}
															helperText={
																relationDisplayFieldNameValid
																	? ''
																	: t('appData.requiredError')
															}
															fullWidth
															placeholder={t(
																'appData.relationDisplayFieldName'
															)}
															{...params}
															inputProps={{
																...params.inputProps
															}}
														/>
													)}
												/>
											</Grid>
										)}
										<Grid item xs={12}>
											<div className={cx(classes.item)}>
												<div>
													<KozmikCheckBox
														disabled={true}
														checked={relationTypeMany}
														onChange={(e) => {
															setRelationTypeMany(e.target.checked)
														}}
													/>
												</div>
												<div className={cx(classes.labelBox, classes.growBox)}>
													<Label className={cx(classes.labelDisabled)}>
														{t('appData.relationTypeMany')}
													</Label>
												</div>
											</div>
										</Grid>
									</>
								)}
								{fieldType === 'Lookup' && (
									<>
										<Grid item xs={12}>
											<Autocomplete
												disabled={true}
												size='small'
												sx={{
													'& .MuiOutlinedInput-root': {
														borderRadius: '4px',
														backgroundColor: kozmikTheme.colors.kozmikGray,
														fontSize: '14px',
														color: kozmikTheme.colors.textPrimary
													},
													'& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
														{
															border: 0
														}
												}}
												value={props?.fieldsData?.find(
													(x) => x.name === lookupRelationName
												)}
												options={props?.fieldsData?.filter(
													(x) => x.dataType === 'Relation'
												)}
												autoHighlight
												getOptionLabel={(option) => option.label}
												onChange={lookupRelationNameOnChange}
												renderOption={(props, option) => (
													<div {...props} style={{ fontSize: 14 }}>
														{option.label}
													</div>
												)}
												renderInput={(params) => (
													<KozmikTextField
														error={!lookupRelationNameValid}
														helperText={
															lookupRelationNameValid
																? ''
																: t('appData.requiredError')
														}
														fullWidth
														placeholder={t('appData.lookupRelationName')}
														{...params}
														inputProps={{
															...params.inputProps
														}}
													/>
												)}
											/>
										</Grid>
										{!!lookupRelationName && (
											<Grid item xs={12}>
												<Autocomplete
													disabled={true}
													size='small'
													sx={{
														'& .MuiOutlinedInput-root': {
															borderRadius: '4px',
															backgroundColor: kozmikTheme.colors.kozmikGray,
															fontSize: '14px',
															color: kozmikTheme.colors.textPrimary
														},
														'& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
															{
																border: 0
															}
													}}
													value={lookupDisplayFieldNameOpts?.find(
														(x) => x.name === lookupDisplayFieldName
													)}
													options={lookupDisplayFieldNameOpts}
													autoHighlight
													getOptionLabel={(option) => option.label}
													onChange={lookupDisplayFieldNameOnChange}
													renderOption={(props, option) => (
														<div {...props} style={{ fontSize: 14 }}>
															{option.label}
														</div>
													)}
													renderInput={(params) => (
														<KozmikTextField
															error={!lookupDisplayFieldNameValid}
															helperText={
																lookupDisplayFieldNameValid
																	? ''
																	: t('appData.requiredError')
															}
															fullWidth
															placeholder={t('appData.lookupDisplayFieldName')}
															{...params}
															inputProps={{
																...params.inputProps
															}}
														/>
													)}
												/>
											</Grid>
										)}
									</>
								)}
								{fieldType === 'Rollup' && (
									<>
										<Grid item xs={12}>
											<Autocomplete
												disabled={true}
												size='small'
												sx={{
													'& .MuiOutlinedInput-root': {
														borderRadius: '4px',
														backgroundColor: kozmikTheme.colors.kozmikGray,
														fontSize: '14px',
														color: kozmikTheme.colors.textPrimary
													},
													'& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
														{
															border: 0
														}
												}}
												value={props?.fieldsData?.find(
													(x) => x.name === rollupRelationName
												)}
												options={props?.fieldsData?.filter(
													(x) =>
														x.dataType === 'Relation' &&
														x.relationType === 'Many'
												)}
												autoHighlight
												getOptionLabel={(option) => option.label}
												onChange={rollupRelationNameOnChange}
												renderOption={(props, option) => (
													<div {...props} style={{ fontSize: 14 }}>
														{option.label}
													</div>
												)}
												renderInput={(params) => (
													<KozmikTextField
														error={!rollupRelationNameValid}
														helperText={
															rollupRelationNameValid
																? ''
																: t('appData.requiredError')
														}
														fullWidth
														placeholder={t('appData.rollupRelationName')}
														{...params}
														inputProps={{
															...params.inputProps
														}}
													/>
												)}
											/>
										</Grid>
										{!!rollupRelationName && (
											<Grid item xs={12}>
												<Autocomplete
													disabled={true}
													size='small'
													sx={{
														'& .MuiOutlinedInput-root': {
															borderRadius: '4px',
															backgroundColor: kozmikTheme.colors.kozmikGray,
															fontSize: '14px',
															color: kozmikTheme.colors.textPrimary
														},
														'& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
															{
																border: 0
															}
													}}
													value={rollupFieldNameOpts?.find(
														(x) => x.name === rollupFieldName
													)}
													options={rollupFieldNameOpts}
													autoHighlight
													getOptionLabel={(option) => option.label}
													onChange={rollupFieldNameOnChange}
													renderOption={(props, option) => (
														<div {...props} style={{ fontSize: 14 }}>
															{option.label}
														</div>
													)}
													renderInput={(params) => (
														<KozmikTextField
															error={!rollupFieldNameValid}
															helperText={
																rollupFieldNameValid
																	? ''
																	: t('appData.requiredError')
															}
															fullWidth
															placeholder={t('appData.rollupFieldName')}
															{...params}
															inputProps={{
																...params.inputProps
															}}
														/>
													)}
												/>
											</Grid>
										)}
										{!!rollupFieldName && (
											<Grid item xs={12}>
												<Autocomplete
													size='small'
													sx={{
														'& .MuiOutlinedInput-root': {
															borderRadius: '4px',
															backgroundColor: kozmikTheme.colors.kozmikGray,
															fontSize: '14px',
															color: kozmikTheme.colors.textPrimary
														}
													}}
													value={aggOperators?.find(
														(x) => x.value === rollupAggregation
													)}
													options={aggOperators}
													autoHighlight
													getOptionLabel={(option) => option.label}
													onChange={rollupAggregationOnChange}
													renderOption={(props, option) => (
														<div {...props} style={{ fontSize: 14 }}>
															{option.label}
														</div>
													)}
													renderInput={(params) => (
														<KozmikTextField
															error={!rollupAggregationValid}
															helperText={
																rollupAggregationValid
																	? ''
																	: t('appData.requiredError')
															}
															fullWidth
															placeholder={t('appData.rollupAggregation')}
															{...params}
															inputProps={{
																...params.inputProps
															}}
														/>
													)}
												/>
											</Grid>
										)}
									</>
								)}
								{fieldType === 'Formula' && (
									<>
										<Grid item xs={12}>
											<Autocomplete
												size='small'
												sx={{
													'& .MuiOutlinedInput-root': {
														borderRadius: '4px',
														backgroundColor: kozmikTheme.colors.kozmikGray,
														fontSize: '14px',
														color: kozmikTheme.colors.textPrimary
													}
												}}
												options={formulaTypes}
												value={formulaTypes.find(
													(x) => x.value === formulaType
												)}
												autoHighlight
												getOptionLabel={(option) => option.label}
												onChange={formulaTypeOnChange}
												renderOption={(props, option) => (
													<div {...props} style={{ fontSize: 14 }}>
														<option.icon
															sx={{
																fontSize: '18px'
															}}
															style={{ marginRight: 5 }}
														/>
														{option.label}
													</div>
												)}
												renderInput={(params) => (
													<KozmikTextField
														error={!formulaTypeValid}
														helperText={
															formulaTypeValid ? '' : t('appData.requiredError')
														}
														fullWidth
														placeholder={t('appData.fieldFormulaTypeInput')}
														{...params}
														inputProps={{
															...params.inputProps
														}}
													/>
												)}
											/>
										</Grid>
										{formulaType === 'Math' && (
											<Grid item xs={12}>
												<MentionsInput
													placeholder={t('appData.placeholders.math')}
													delay={0}
													whitelist={mathTemplateOpts}
													onChange={mathTemplateOnChange}
													value={mathTemplate}
													multiline={false}
													labelKey='label'
													inputStyle={{
														borderRadius: 4,
														backgroundColor: kozmikTheme.colors.kozmikGray,
														fontSize: '14px',
														color: kozmikTheme.colors.textPrimary
													}}
												></MentionsInput>
												{mathTemplateValid && (
													<Typography className={classes.tips}>
														<Info className={classes.tipsIcon} />
														{t('appData.tips.template')}
													</Typography>
												)}
												{!mathTemplateValid && (
													<Typography className={classes.templateNotValid}>
														<Info className={classes.templateNotValidIcon} />
														{t('appData.validations.math')}
													</Typography>
												)}
											</Grid>
										)}
										{formulaType === 'Template' && (
											<Grid item xs={12}>
												<MentionsInput
													placeholder={t('appData.placeholders.template')}
													delay={0}
													whitelist={textTemplateOpts}
													onChange={textTemplateOnChange}
													value={textTemplate}
													multiline={false}
													labelKey='label'
													inputStyle={{
														borderRadius: 4,
														backgroundColor: kozmikTheme.colors.kozmikGray,
														fontSize: '14px',
														color: kozmikTheme.colors.textPrimary
													}}
												></MentionsInput>
												{textTemplateValid && (
													<Typography className={classes.tips}>
														<Info className={classes.tipsIcon} />
														{t('appData.tips.template')}
													</Typography>
												)}
												{!textTemplateValid && (
													<Typography className={classes.templateNotValid}>
														<Info className={classes.templateNotValidIcon} />
														{t('appData.validations.template')}
													</Typography>
												)}
											</Grid>
										)}
										{formulaType === 'DateFormat' && (
											<>
												<Grid item xs={12}>
													<Autocomplete
														size='small'
														sx={{
															'& .MuiOutlinedInput-root': {
																borderRadius: '4px',
																backgroundColor: kozmikTheme.colors.kozmikGray,
																fontSize: '14px',
																color: kozmikTheme.colors.textPrimary
															}
														}}
														options={dateFormulaFieldNameOpts}
														value={dateFormulaFieldNameOpts.find(
															(x) => x.name === dateFormatFieldName
														)}
														autoHighlight
														getOptionLabel={(option) => option.label}
														onChange={dateFormatFieldNameOnChange}
														renderOption={(props, option) => (
															<div {...props} style={{ fontSize: 14 }}>
																{option.label}
															</div>
														)}
														renderInput={(params) => (
															<KozmikTextField
																error={!dateFormatFieldNameValid}
																helperText={
																	dateFormatFieldNameValid
																		? ''
																		: t('appData.requiredError')
																}
																fullWidth
																placeholder={t('appData.fieldNameInput')}
																{...params}
																inputProps={{
																	...params.inputProps
																}}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={12}>
													<KozmikTextField
														error={!dateFormatTemplateValid}
														helperText={
															dateFormatTemplateValid
																? ''
																: t('appData.fieldValidationError')
														}
														fullWidth
														placeholder={t('appData.dateFormatTemplateInput')}
														value={dateFormatTemplate}
														onChange={dateFormatTemplateOnChange}
													/>
												</Grid>
											</>
										)}
										{formulaType === 'DateDifference' && (
											<>
												<Grid item xs={12}>
													<Autocomplete
														size='small'
														sx={{
															'& .MuiOutlinedInput-root': {
																borderRadius: '4px',
																backgroundColor: kozmikTheme.colors.kozmikGray,
																fontSize: '14px',
																color: kozmikTheme.colors.textPrimary
															}
														}}
														options={dateFormulaFieldNameOpts}
														value={dateFormulaFieldNameOpts.find(
															(x) => x.name === dateDiffStartDateFieldName
														)}
														autoHighlight
														getOptionLabel={(option) => option.label}
														onChange={dateDiffStartDateFieldNameOnChange}
														renderOption={(props, option) => (
															<div {...props} style={{ fontSize: 14 }}>
																{option.label}
															</div>
														)}
														renderInput={(params) => (
															<KozmikTextField
																error={!dateDiffStartDateFieldNameValid}
																helperText={
																	dateDiffStartDateFieldNameValid
																		? ''
																		: t('appData.requiredError')
																}
																fullWidth
																placeholder={t(
																	'appData.dateDiffStartDateInput'
																)}
																{...params}
																inputProps={{
																	...params.inputProps
																}}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={12}>
													<Autocomplete
														size='small'
														sx={{
															'& .MuiOutlinedInput-root': {
																borderRadius: '4px',
																backgroundColor: kozmikTheme.colors.kozmikGray,
																fontSize: '14px',
																color: kozmikTheme.colors.textPrimary
															}
														}}
														options={dateFormulaFieldNameOpts}
														value={dateFormulaFieldNameOpts.find(
															(x) => x.name === dateDiffEndDateFieldName
														)}
														autoHighlight
														getOptionLabel={(option) => option.label}
														onChange={dateDiffEndDateFieldNameOnChange}
														renderOption={(props, option) => (
															<div {...props} style={{ fontSize: 14 }}>
																{option.label}
															</div>
														)}
														renderInput={(params) => (
															<KozmikTextField
																error={!dateDiffEndDateFieldNameValid}
																helperText={
																	dateDiffEndDateFieldNameValid
																		? ''
																		: t('appData.requiredError')
																}
																fullWidth
																placeholder={t('appData.dateDiffEndDateInput')}
																{...params}
																inputProps={{
																	...params.inputProps
																}}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={12}>
													<SelectBox
														className={classes.input}
														onChange={dateDiffUnitChange}
														value={dateDiffUnit}
														data={unitOpts}
														textKey='label'
														valueKey='value'
														placeholder={t('appData.dateDiffUnitInput')}
													/>
												</Grid>
											</>
										)}
										{formulaType === 'DateAdd' && (
											<>
												<Grid item xs={12}>
													<Autocomplete
														size='small'
														sx={{
															'& .MuiOutlinedInput-root': {
																borderRadius: '4px',
																backgroundColor: kozmikTheme.colors.kozmikGray,
																fontSize: '14px',
																color: kozmikTheme.colors.textPrimary
															}
														}}
														options={dateFormulaFieldNameOpts}
														value={dateFormulaFieldNameOpts.find(
															(x) => x.name === dateAddDateFieldName
														)}
														autoHighlight
														getOptionLabel={(option) => option.label}
														onChange={dateAddDateFieldNameOnChange}
														renderOption={(props, option) => (
															<div {...props} style={{ fontSize: 14 }}>
																{option.label}
															</div>
														)}
														renderInput={(params) => (
															<KozmikTextField
																error={!dateAddDateFieldNameValid}
																helperText={
																	dateAddDateFieldNameValid
																		? ''
																		: t('appData.requiredError')
																}
																fullWidth
																placeholder={t('appData.dateAddDateInput')}
																{...params}
																inputProps={{
																	...params.inputProps
																}}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={12}>
													<Autocomplete
														size='small'
														sx={{
															'& .MuiOutlinedInput-root': {
																borderRadius: '4px',
																backgroundColor: kozmikTheme.colors.kozmikGray,
																fontSize: '14px',
																color: kozmikTheme.colors.textPrimary
															}
														}}
														options={numberFieldOpts}
														value={numberFieldOpts.find(
															(x) => x.name === dateAddNumberFieldName
														)}
														autoHighlight
														getOptionLabel={(option) => option.label}
														onChange={dateAddNumberFieldNameOnChange}
														renderOption={(props, option) => (
															<div {...props} style={{ fontSize: 14 }}>
																{option.label}
															</div>
														)}
														renderInput={(params) => (
															<KozmikTextField
																error={!dateAddNumberFieldNameValid}
																helperText={
																	dateAddNumberFieldNameValid
																		? ''
																		: t('appData.requiredError')
																}
																fullWidth
																placeholder={t('appData.dateAddNumberInput')}
																{...params}
																inputProps={{
																	...params.inputProps
																}}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={12}>
													<SelectBox
														className={classes.input}
														onChange={dateAddUnitChange}
														value={dateAddUnit}
														data={unitOpts}
														textKey='label'
														valueKey='value'
														placeholder={t('appData.dateAddUnitInput')}
													/>
												</Grid>
											</>
										)}
									</>
								)}
								<Grid item container xs={12} justifyContent='space-between'>
									<Button
										size='small'
										variant='contained'
										onClick={closeEditPop}
										sx={{
											textTransform: 'none',
											backgroundColor: `#ffffff !important`,
											boxShadow: 'none !important',
											fontSize: 16,
											borderRadius: '4px',
											width: '48%'
										}}
									>
										<Typography
											className={cx(classes.popoverBtnTxt, classes.kozmikBlue)}
										>
											{t('appData.fieldCancelButton')}
										</Typography>
									</Button>
									<Button
										size='small'
										variant='contained'
										onClick={updateFieldName}
										sx={{
											textTransform: 'none',
											backgroundColor: `${kozmikTheme.colors.kozmikLightBlue} !important`,
											boxShadow: 'none !important',
											fontSize: 16,
											borderRadius: '4px',
											width: '48%'
										}}
									>
										<Typography
											className={cx(classes.popoverBtnTxt, classes.kozmikBlue)}
										>
											{t('appData.fieldEditButton')}
										</Typography>
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Popover>
					<Popover
						id={'multiline-popover'}
						anchorReference='anchorPosition'
						anchorPosition={customEditPopPosition}
						open={multiLinePopIsOpen}
						onClose={closeMultiLinePop}
						anchorOrigin={{
							vertical: 'center',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'center',
							horizontal: 'center'
						}}
					>
						<Box className={classes.multilinePopoverBox}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<KozmikTextField
										fullWidth
										placeholder={editState?.colDef?.headerName}
										value={multiLineVal}
										onChange={multiLineOnchange}
										className={classes.popoverInput}
										multiline
										rows={5}
										autoFocus
									/>
									{/* <BorderlessTextField
										size='small'
										variant='outlined'
										fullWidth={true}
										placeholder={editState?.colDef?.headerName}
										value={multiLineVal}
										onChange={multiLineOnchange}
										className={classes.popoverInput}
										multiline
										rows={3}
									/> */}
								</Grid>
								<Grid item container xs={12} justifyContent='space-between'>
									<Button
										size='small'
										variant='contained'
										onClick={() => deleteDataPopSubmit()}
										sx={{
											textTransform: 'none',
											backgroundColor: `transparent !important`,
											boxShadow: 'none !important',
											fontSize: 16,
											borderRadius: '4px',
											width: '48%'
										}}
									>
										<Typography
											className={cx(classes.popoverBtnTxt, classes.kozmikRed)}
										>
											{t('appData.multilinePopClear')}
										</Typography>
									</Button>
									<Button
										size='small'
										variant='contained'
										onClick={() => editdataPopSubmit(multiLineVal)}
										sx={{
											textTransform: 'none',
											backgroundColor: `${kozmikTheme.colors.kozmikLightBlue} !important`,
											boxShadow: 'none !important',
											fontSize: 16,
											borderRadius: '4px',
											width: '48%'
										}}
									>
										<Typography
											className={cx(classes.popoverBtnTxt, classes.kozmikBlue)}
										>
											{t('appData.multilinePopSave')}
										</Typography>
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Popover>
					<Popover
						id={'relation-popover'}
						anchorReference='anchorPosition'
						anchorPosition={customEditPopPosition}
						open={relationPopIsOpen}
						onClose={closeRelationPop}
						anchorOrigin={{
							vertical: 'center',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'center',
							horizontal: 'center'
						}}
					>
						<Box className={classes.popoverBox}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Autocomplete
										value={relationVal}
										onChange={relationOnchange}
										fullWidth={true}
										size='small'
										multiple={currentRelation?.type === 'Many'}
										id='tags-outlined'
										options={currentRelation?.tableRows ?? []}
										getOptionLabel={(option) => {
											var keys = Object.keys(option)
											var textfieldName = keys?.filter(
												(x) => x.toLowerCase() != 'id'
											)[0]

											return (
												option[textfieldName] || t('appData.relationPop.empty')
											)
										}}
										filterSelectedOptions
										renderOption={(props, option) => {
											var keys = Object.keys(option)
											var textfieldName = keys?.filter(
												(x) => x.toLowerCase() != 'id'
											)[0]

											return (
												<div {...props} style={{ fontSize: 14 }}>
													{option[textfieldName] ||
														t('appData.relationPop.empty')}
												</div>
											)
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label={t('appData.relationPop.label')}
												placeholder={t('appData.relationPop.placeholder')}
											/>
										)}
									/>
								</Grid>
							</Grid>
						</Box>
					</Popover>

					{props.metadata && (
						<KozmikModal
							visible={apiDocModalVisibility}
							hideModal={hideAPIDocModal}
							lg
						>
							<APIDoc tableName={props.metadata.name} appId={appId}></APIDoc>
						</KozmikModal>
					)}

					<WarningDialog
						dialogVisible={deleteConfirmationVisible}
						dialogClose={handleDeleteDialogClose}
						dialogAccept={handleDeleteDialogAccept}
						dialogTitle={t('appData.fieldModal.dialogTitle')}
						title={null}
						text={t('appData.fieldModal.text')}
						dialogCancelText={t('appData.fieldModal.closeButton')}
						dialogAcceptText={t('appData.fieldModal.acceptButton')}
					/>
					<WarningDialog
						dialogVisible={deleteRowConfirmationVisible}
						dialogClose={handleDeleteRowDialogClose}
						dialogAccept={handleDeleteRowDialogAccept}
						dialogTitle={t('appData.dataModal.dialogTitle')}
						title={null}
						text={t('appData.dataModal.text')}
						dialogCancelText={t('appData.dataModal.closeButton')}
						dialogAcceptText={t('appData.dataModal.acceptButton')}
					/>
				</Box>
			</Grid>
		</>
	)
})

const mapStateToProps = (state) => ({
	...state.userInfo,
	...state.currentOrg
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(
	AppDataData
)

import {
	CallMergeOutlined,
	CalculateOutlined,
	EditCalendarOutlined,
	QueryBuilderOutlined,
	EventOutlined
} from '@mui/icons-material'
import i18next from '../../../i18n/i18n'

const formulaData = [
	{
		icon: CalculateOutlined,
		label: i18next.t('appData.fieldDataTypes.math'),
		value: 'Math'
	},
	{
		icon: CallMergeOutlined,
		label: i18next.t('appData.fieldDataTypes.template'),
		value: 'Template'
	},
	{
		icon: EventOutlined,
		label: i18next.t('appData.fieldDataTypes.dateFormat'),
		value: 'DateFormat'
	},
	{
		icon: QueryBuilderOutlined,
		label: i18next.t('appData.fieldDataTypes.dateDifference'),
		value: 'DateDifference'
	},
	{
		icon: EditCalendarOutlined,
		label: i18next.t('appData.fieldDataTypes.dateAdd'),
		value: 'DateAdd'
	}
]

export default formulaData

import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import DoubleStateField from '../doubleStateField/DoubleStateField'

import getFilterOpByDataType from '../../../methods/getFilterOpByDataType'
import validateWithRegExp from '../../../methods/validateWithRegExp'
import getRegExpByDataType from '../../../methods/getRegExpByDataType'
import timeoutDelay from '../../../methods/timeoutDelay'
import dataClient from '../../../services/dataClient'
import showToastResponseError from '../../../methods/showToastResponseError'
import getCurrentTableField from '../../../methods/getCurrentTableField'
import getFieldDataType from '../../../methods/getFieldDataType'

import systemFieldNames from '../../../common/systemFieldNames/systemFieldNames'
import formulas from '../../../common/formulas'

const useStyles = makeStyles((theme) => ({}))

const ActionFieldMapping = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	let { appId } = useParams()

	const {
		item,
		activeScreen,
		entityField,
		entityFields,
		updateActionFieldMapping,
		index,
		languageCode,
		...restProps
	} = props

	/****RULE_DATA***************************/
	const [dataPrimitiveType, setDataPrimitiveType] = useState(null)
	const [isDataSecondaryMode, setIsDataSecondaryMode] = useState(false)
	const [dataField, setDataField] = useState('')
	const [ruleDataDefault, setDataDefault] = useState('')
	const [dataDefaultValid, setDataDefaultValid] = useState(true)
	const [dataRegExp, setDataRegExp] = useState(null)
	const [dataErrorText, setDataErrorText] = useState('')

	const [relationTableData, setRelationTableData] = useState([])
	const [dataRelation, setDataRelation] = useState([])

	const updateActionFieldMappingLocal = (val, isRelation = false) => {
		var valueType = isRelation
			? 'Relation'
			: isDataSecondaryMode
			? 'Data'
			: 'auto'

		updateActionFieldMapping(index, val, valueType)
	}

	const filterTableFieldsByDataType = (dataType, tableFields) => {
		if (dataType) {
			const filterOps = getFilterOpByDataType(dataType)
			const allowedDataTypes =
				dataType === 'Text'
					? [...filterOps?.dataTypes, 'Relation']
					: filterOps?.dataTypes

			const filteredFields = tableFields
				?.filter((x) => allowedDataTypes?.includes(x.dataType))
				?.sort(function (a, b) {
					return a.label.localeCompare(b.label)
				})

			return filteredFields
		} else {
			return []
		}
	}

	const getFormulaFieldsOptions = (dataType) => {
		if (dataType) {
			const filterOps = getFilterOpByDataType(dataType)

			let formulaFields = []

			if (filterOps?.type === 'string') {
				formulaFields = formulaFields
					.concat(formulas.profile)
					.concat(formulas.string)
			} else if (filterOps?.type === 'relation') {
				formulaFields = formulaFields.concat(formulas.onlyProfile)
			} else if (filterOps?.type === 'datetime') {
				formulaFields = formulaFields.concat(formulas.datetime)
			}

			return formulaFields ?? []
		} else {
			return []
		}
	}

	const getScreenFieldsOptions = (screenSource) => {
		if (screenSource) {
			const screenFields = props?.entities?.find(
				(x) => x.name == screenSource
			)?.fields

			const filteredScreenFields = filterTableFieldsByDataType(
				entityField?.dataType,
				screenFields
			)?.filter(
				(x) =>
					entityField?.dataType !== 'Relation' ||
					(entityField?.dataType === 'Relation' &&
						(entityField?.relatedEntityName === x.relatedEntityName ||
							(entityField?.relatedEntityName === screenSource &&
								x.isKeyField)))
			)

			const formattedScreenFields = filteredScreenFields?.map((x) => ({
				label: `Screen.${x.label}`,
				name: `Screen.${x.name}`
			}))

			return (
				formattedScreenFields?.sort(function (a, b) {
					return a.label.localeCompare(b.label)
				}) ?? []
			)
		} else {
			return []
		}
	}

	const getListFieldsOptions = (listSource) => {
		if (listSource) {
			const listFields = props?.entities?.find(
				(x) => x.name == listSource
			)?.fields

			const filteredListFields = filterTableFieldsByDataType(
				entityField?.dataType,
				listFields
			)

			const formattedListFields = filteredListFields?.map((x) => ({
				label: x.label,
				name: `${x.name}`
			}))

			return (
				formattedListFields?.sort(function (a, b) {
					return a.label.localeCompare(b.label)
				}) ?? []
			)
		} else {
			return []
		}
	}

	const getFormFieldsOptions = (formSource) => {
		if (formSource) {
			const formFields = props?.entities?.find(
				(x) => x.name == formSource
			)?.fields

			const filteredFormFields = filterTableFieldsByDataType(
				entityField?.dataType,
				formFields
			)?.filter(
				(x) =>
					!systemFieldNames.notVisible.includes(x.name) &&
					(entityField?.dataType !== 'Relation' ||
						(entityField?.dataType === 'Relation' &&
							(entityField?.relatedEntityName === x.relatedEntityName ||
								(entityField?.relatedEntityName === formSource &&
									x.isKeyField)))) &&
					x.dataType !== 'Lookup' &&
					x.dataType !== 'Formula' &&
					x.dataType !== 'Rollup'
			)

			const formattedFormFields = filteredFormFields?.map((x) => ({
				label: x.label,
				name: `Form.${x.name}`
			}))

			return (
				formattedFormFields?.sort(function (a, b) {
					return a.label.localeCompare(b.label)
				}) ?? []
			)
		} else {
			return []
		}
	}

	const getActionResultOptions = (relatedEntityName) => {
		if (relatedEntityName) {
			let formattedFields = []

			if (!!props?.actions?.length === true) {
				const prevs = props.actions?.slice(
					0,
					props.actionIndex > 0 ? props.actionIndex + 1 : 0
				)

				const fields =
					prevs
						?.map((item, index) => {
							if (
								!(item.type === 'create' && item.source === relatedEntityName)
							)
								return

							const ent = props.entities?.find((e) => e.name === item.source)

							const actionLabel = props.actionTypes?.find(
								(x) => x.value === 'create'
							)?.label
							const label = ent.label
							const idLabel = ent.fields?.find((x) => x.isKeyField)?.label

							return {
								id: null,
								name: `Action.${index}.data`,
								label: `Action.${actionLabel}.${label}.${idLabel}`,
								dataType: 'RowId'
							}
						})
						?.filter((x) => x) ?? []

				formattedFields = formattedFields?.concat(fields)
			}

			return formattedFields ?? []
		} else {
			return []
		}
	}

	const emptyOptions = [{ label: '-', name: '' }]
	// const fieldOptions =
	// 	props.formSource || props.listSource
	// 		? []
	// 		: filterTableFieldsByDataType(entityField?.dataType, entityFields)
	const screenFieldsOptions = getScreenFieldsOptions(activeScreen.data?.source)
	const formFieldsOptions = getFormFieldsOptions(props.formSource)
	const listFieldsOptions = getListFieldsOptions(props.listSource)
	const formulaFieldsOptions = getFormulaFieldsOptions(entityField?.dataType)
	const actionResultOptions = getActionResultOptions(
		entityField?.relatedEntityName
	)

	const fieldOptionsWithScreenFields = [
		...emptyOptions,
		// ...fieldOptions,
		...listFieldsOptions,
		...formFieldsOptions,
		...screenFieldsOptions,
		...formulaFieldsOptions,
		...actionResultOptions
	]

	const getRelationTableData = async (field) => {
		if (!field) return

		setRelationTableData([])

		const parameters = {
			sort: 'Created desc',
			fields: 'Id,' + (field?.displayFieldName ?? 'Name')
		}

		return dataClient
			.searchData(appId, field.relatedEntityName, parameters)
			.then((response) => {
				if (response?.data) {
					setRelationTableData(response.data.records)
				} else {
					showToastResponseError(response)
				}
			})
	}

	const getFormattedRelationDataById = (field, idArray) => {
		const filteredData = relationTableData.filter((x) => idArray.includes(x.Id))
		const convertedData = filteredData?.map((item) => ({
			Id: item?.Id,
			Name: item?.[field.displayFieldName ?? 'Name']
		}))

		if (field.relationType == 'One') {
			return convertedData ? convertedData[0] : {}
		} else {
			return convertedData ? convertedData : []
		}
	}

	const fieldDataChange = (e) => {
		if (dataPrimitiveType === 'string' || dataPrimitiveType === 'numeric') {
			if (isDataSecondaryMode) {
				validateWithRegExp(e.target.value, dataRegExp).then((isValid) => {
					setDataDefaultValid(isValid)
					if (isValid) {
						timeoutDelay(updateActionFieldMappingLocal, e.target.value, 1000)
					}
				})

				setDataDefault(e.target.value)
				setDataField('')
			} else {
				updateActionFieldMappingLocal(e.value)

				setDataField(e.value)
				setDataDefault('')
			}
			setDataRelation([])
		} else if (dataPrimitiveType === 'boolean') {
			if (isDataSecondaryMode) {
				const vl = typeof e.target.value === 'boolean' ? e.target.value : null

				updateActionFieldMappingLocal(vl)

				setDataDefault('')
				setDataField('')
			} else {
				updateActionFieldMappingLocal(e.value)

				setDataField(e.value)
				setDataDefault('')
			}
			setDataRelation([])
		} else if (dataPrimitiveType === 'datetime') {
			if (isDataSecondaryMode) {
				updateActionFieldMappingLocal(e)

				setDataDefault(e)
				setDataField('')
			} else {
				updateActionFieldMappingLocal(e.value)

				setDataField(e.value)
				setDataDefault('')
			}
			setDataRelation([])
		} else if (dataPrimitiveType === 'relation') {
			if (isDataSecondaryMode) {
				const idArray =
					typeof e.target.value === 'string'
						? e.target.value.split(',')
						: e.target.value
				const dataForSubmit = getFormattedRelationDataById(entityField, idArray)

				updateActionFieldMappingLocal(dataForSubmit, true)

				setDataRelation(dataForSubmit?.length ? dataForSubmit : [])
				setDataField('')
				setDataDefault('')
			} else {
				updateActionFieldMappingLocal(e.value)

				setDataField(e.value)
				setDataDefault('')
				setDataRelation([])
			}
		}
	}

	const changeFieldDataMode = (isSecondaryMode, value) => {
		if (isSecondaryMode) {
			validateWithRegExp(value, dataRegExp).then((isValid) => {
				setDataDefaultValid(isValid)
			})
		}
	}

	const initDataRegExp = (dataType) => {
		const proceedWithCondition = (condition) => {
			const dataTypeRegExObj = getRegExpByDataType(dataType, languageCode)
			if (condition) {
				if (dataTypeRegExObj) {
					setDataRegExp(dataTypeRegExObj.re)
					setDataErrorText(dataTypeRegExObj.msg)
					return dataTypeRegExObj.re
				} else {
					setDataRegExp(null)
					setDataErrorText('')
					return null
				}
			} else {
				setDataRegExp(null) //accept all
				return null
			}
		}

		switch (dataType) {
			// case 'AutoNumber':
			case 'Number':
				return proceedWithCondition(true)
			case 'Checkbox':
			case 'Date':
				return proceedWithCondition(false)
			default:
				return proceedWithCondition(false)
		}
	}

	/****INITIALIZATION***************************/
	const initStates = (item) => {
		const fieldPrimitiveType = getFieldDataType(item.field, entityFields)

		setDataPrimitiveType(fieldPrimitiveType)

		const isSecondaryMode = !(
			item.valueType == 'Screen' ||
			item.valueType == 'Form' ||
			item.valueType == 'Field' ||
			item.valueType == 'Variables' ||
			item.valueType == 'Action'
		)

		const fieldValueText =
			typeof item.value == 'boolean' ? item.value : item.value || ''

		setIsDataSecondaryMode(isSecondaryMode)

		const regExp = initDataRegExp(item.type)
		if (fieldPrimitiveType === 'string' || fieldPrimitiveType === 'numeric') {
			if (isSecondaryMode) {
				setDataDefault(fieldValueText)
				setDataField('')
				validateWithRegExp(fieldValueText, regExp).then((isValid) => {
					setDataDefaultValid(isValid)
				})
			} else {
				setDataField(item.value || '')
				setDataDefault('')
			}
			setDataRelation([])
		} else if (fieldPrimitiveType === 'boolean') {
			if (isSecondaryMode) {
				setDataDefault(fieldValueText)
				setDataField('')
			} else {
				setDataField(item.value || '')
				setDataDefault('')
			}
			setDataRelation([])
		} else if (fieldPrimitiveType === 'datetime') {
			if (isSecondaryMode) {
				setDataDefault(fieldValueText)
				setDataField('')
			} else {
				setDataField(item.value || '')
				setDataDefault('')
			}
			setDataRelation([])
		} else if (fieldPrimitiveType === 'relation') {
			if (isSecondaryMode) {
				if (entityField?.relationType === 'One')
					setDataRelation(item.value ? [item.value] : [])
				else setDataRelation(item.value?.length ? item.value : [])

				setDataField('')
				setDataDefault('')
			} else {
				setDataRelation([])
				setDataField(item.value || '')
				setDataDefault('')
			}
		}
	}

	useEffect(() => {
		initStates(item)
	}, [item])

	return (
		<>
			<DoubleStateField
				appId={appId}
				languageCode={languageCode}
				fieldOptionsWithScreenFields={fieldOptionsWithScreenFields}
				dataType={dataPrimitiveType}
				fieldValue={dataField}
				fieldDataType={
					getCurrentTableField(entityField?.name, entityFields)?.dataType
				}
				fieldDataChange={fieldDataChange}
				entityField={getCurrentTableField(entityField?.name, entityFields)}
				isSecondaryMode={isDataSecondaryMode}
				setIsSecondaryMode={setIsDataSecondaryMode}
				callback={changeFieldDataMode}
				dataDefault={
					dataPrimitiveType === 'relation' ? dataRelation : ruleDataDefault
				}
				dataDefaultValid={!dataDefaultValid}
				dataErrorText={dataErrorText}
				relationTableData={relationTableData}
				refreshRelation={getRelationTableData}
			></DoubleStateField>
		</>
	)
}

export default ActionFieldMapping

import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { SwapVert, FilterAltOutlined, PlusOne } from '@mui/icons-material'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import Accordion from '../../../atoms/accordion/Accordion'
import NumericInput from '../../../atoms/numericInput/NumericInput'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import KozmikTextField from '../../../atoms/kozmikTextField/KozmikTextField'
import AccordionItem from '../../../atoms/accordionItem/AccordionItem'
import FilterList from '../../../molecules/filterList/FilterList'
import Sorting from '../../../molecules/sorting/Sorting'
import ActionList from '../../actionList/ActionList'
import FilterModalOptions from '../../../molecules/filterModalOptions/FilterModalOptions'
import SearchOptions from '../../../molecules/searchOptions/SearchOptions'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import getFilterOpByDataType from '../../../../methods/getFilterOpByDataType'
import relationTypes from '../../../../common/enums/relationTypes'
import getArrayExcludingForbidArray from '../../../../methods/getArrayExcludingForbidArray'
import systemFieldNames from '../../../../common/systemFieldNames/systemFieldNames'

import { ReactComponent as KaroKoseli } from '../../../../assets/icons/Settings/KaroTip-Koseli.svg'
import { ReactComponent as KaroOval } from '../../../../assets/icons/Settings/KaroTip-Oval.svg'
import { ReactComponent as ImageOdakli } from '../../../../assets/icons/Settings/Image-Odakli.svg'
import { ReactComponent as ImageOrtali } from '../../../../assets/icons/Settings/Image-Ortali.svg'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	inputLight: {
		borderRadius: 3,
		backgroundColor: '#ffffff',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const Carousel = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [elementName, setElementName] = useState(null)

	const cornerOptions = [
		{
			text: t('screens.elementSettings.carousel.cornerOpts.square'),
			value: 'square',
			icon: KaroKoseli
		},
		{
			text: t('screens.elementSettings.carousel.cornerOpts.oval'),
			value: 'oval',
			icon: KaroOval
		}
	]
	const imageOptions = [
		{
			text: t('screens.elementSettings.carousel.imgOpts.cover'),
			value: 'cover',
			icon: ImageOdakli
		},
		{
			text: t('screens.elementSettings.carousel.imgOpts.contain'),
			value: 'contain',
			icon: ImageOrtali
		}
	]

	/****SOURCE***************************/
	const [source, setSource] = useState('')

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		fieldDataTypes.textBased,
		null,
		true
		// relationTypes.one
	)
	const imageFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		['Image'],
		null,
		true,
		relationTypes.one
	)

	const sourceChange = (e) => {
		submitSource(source, e.target.value)
		setSource(e.target.value)
	}

	const submitSource = (oldTableName, tableName) => {
		const table = props?.entities?.find((y) => y.name == tableName)

		const txtFields = getEntityFieldsByDataTypes(
			props?.entities,
			tableName,
			fieldDataTypes.textBased,
			null,
			true
			// relationTypes.one
		)
		const imgFields = getEntityFieldsByDataTypes(
			props?.entities,
			tableName,
			['Image'],
			null,
			true,
			relationTypes.one
		)

		const firstImgField = imgFields?.length ? imgFields[0] : null

		const filteredTxtFields = getArrayExcludingForbidArray(
			txtFields,
			'name',
			systemFieldNames.all
		)
		const titleField = txtFields?.find((x) => x.name == 'Name')

		let modifiedElementData = update(props.elementData, {
			source: { $set: tableName },
			mapping: {
				imgUrl: {
					field: { $set: firstImgField?.name },
					type: { $set: firstImgField?.dataType }
				}
			},
			sorting: {
				field: { $set: titleField?.name },
				sort: { $set: 'asc' }
			},
			filters: {
				groupOp: { $set: 'AND' },
				rules: { $set: [] }
			},
			searchOp: { $set: 'cn' },
			seeAllUrl: {
				$set: props.elementData?.seeAllUrl
			}
		})

		props.updateBulkScreensWithModifiedData(
			table.name,
			null,
			modifiedElementData
		)
	}

	/****IMGURL***************************/
	const [imgUrl, setImgUrl] = useState('')

	const imgUrlChange = (e) => {
		submitImgUrl(e.target.value)
		setImgUrl(e.target.value)
	}

	const submitImgUrl = (x) => {
		const selectedField = imageFields?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				imgUrl: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ACTIONS***************************/
	const updateActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			actions: { $set: newActions }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****LIMIT***************************/
	const [limit, setLimit] = useState(25)
	const limitChange = (val) => {
		timeoutDelay(submitLimit, val, 1000)
		setLimit(val)
	}
	const submitLimit = (x) => {
		const modifiedElementData = update(props.elementData, {
			limit: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****CORNER***************************/
	const [corner, setCorner] = useState(null)

	const cornerChange = (val) => {
		submitCorner(val)
		setCorner(val)
	}

	const submitCorner = (x) => {
		const modifiedElementData = update(props.elementData, {
			corner: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****IMAGE_RESIZE_MODE***************************/
	const [imageResizeMode, setImageResizeMode] = useState(null)

	const imageResizeModeChange = (val) => {
		submitImageResizeMode(val)
		setImageResizeMode(val)
	}

	const submitImageResizeMode = (x) => {
		const modifiedElementData = update(props.elementData, {
			imageResizeMode: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****FILTER_&_SORT***************************/
	const [localElementData, setLocalElementData] = useState(null)

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****SOURCE***************************/
		setSource(elementData?.source ? elementData.source : '')
		/****IMGURL***************************/
		setImgUrl(
			elementData?.mapping?.imgUrl?.field
				? elementData.mapping.imgUrl.field
				: ''
		)
		/****LIMIT***************************/
		setLimit(elementData?.limit ?? 10)
		/****CORNER***************************/
		setCorner(elementData?.corner)
		/****IMAGE_RESIZE_MODE***************************/
		setImageResizeMode(elementData?.imageResizeMode)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
			setLocalElementData(props.elementData)
		}
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.source')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={12}>
							<SelectBox
								className={classes.input}
								onChange={sourceChange}
								value={source}
								data={props.entities}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>

					<div className={classes.mb10}>
						<AccordionItem
							icon={FilterAltOutlined}
							title={t('screens.elementSettings.carousel.filter')}
							expanded={false}
							padding={8}
						>
							<FilterList
								filter={localElementData?.filters}
								table={props?.entities?.find(
									(y) => y.name == props?.elementData?.source
								)}
								activeScreen={props?.activeScreen}
								entities={props?.entities}
								tableName={props?.elementData?.source}
								screenTable={props?.entities?.find(
									(y) => y.name == props.activeScreen?.data?.source
								)}
								filtering={props.filtering}
								setLocalElementData={setLocalElementData}
							/>
						</AccordionItem>
					</div>

					<div className={classes.mb10}>
						<AccordionItem
							icon={SwapVert}
							title={t('screens.elementSettings.carousel.sort')}
							expanded={false}
							padding={8}
						>
							<Sorting
								sortingData={localElementData?.sorting}
								sorting={props.sorting}
								fieldOptions={textFields}
							/>
						</AccordionItem>
					</div>

					<div className={classes.mb10}>
						<AccordionItem
							icon={PlusOne}
							title={t('screens.elementSettings.carousel.limit')}
							expanded={false}
							padding={8}
						>
							<NumericInput minVal={0} value={limit} onChange={limitChange} />
						</AccordionItem>
					</div>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.carousel.image')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.carousel.emptyField')}
								className={classes.input}
								onChange={imgUrlChange}
								value={imgUrl}
								data={imageFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.carousel.tileType')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={cornerOptions}
								getChange={cornerChange}
								activeValue={corner}
							/>
						</Grid>
					</Grid>
					{/* <Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.carousel.imgUsage')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={imageOptions}
								getChange={imageResizeModeChange}
								activeValue={imageResizeMode}
							/>
						</Grid>
					</Grid> */}
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.actions')}
					expanded={true}
				>
					{source && (
						<ActionList
							name={elementName}
							entities={props.entities}
							activeScreen={props.activeScreen}
							actions={props.elementData.actions}
							updateActions={updateActions}
							isList={true}
							listSource={source}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default Carousel

import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Grid from '@mui/material/Grid'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import AppHeader from '../../templates/appHeader/AppHeader'
import { setDetail } from '../../../redux/actions/appDataActions'
import DetailLeftFrame from '../../templates/detailLeftFrame/DetailLeftFrame'

import appClient from '../../../services/appClient'
import storeSettingClient from '../../../services/storeSettingClient'

import showToastResponseError from '../../../methods/showToastResponseError'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		overflow: (props) => (props?.isFullPagePreview ? 'hidden' : 'auto')
	},
	pageContainer: {
		height: 'calc(100vh - 64px)'
	},
	contentContainer: {
		height: '100%',
		backgroundColor: '#FAFAFC',
		flex: 1,
		overflow: 'hidden',
		[theme.breakpoints.down('md')]: {
			height: 'auto',
			paddingBottom: 90
		},
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 10
		}
	}
}))

const AppDetailLayout = (props) => {
	const classes = useStyles({ isFullPagePreview: props.isFullPagePreview })
	let { appId } = useParams()

	const [setting, setSetting] = useState(null)

	const getDetailData = () => {
		if (appId) {
			appClient.detail(appId).then((response) => {
				if (response.status == 200) {
					props.setDetail(response.data)
				} else {
					showToastResponseError(response)
				}
			})
		}
	}

	const getSetting = () => {
		storeSettingClient.getSetting(appId).then((response) => {
			if (response?.data?.success) {
				setSetting(response?.data?.data)
			} else {
				showToastResponseError(response)
			}
		})
	}

	useEffect(() => {
		getDetailData()
		getSetting()

		return () => {
			props.setDetail(null)
		}
	}, [])

	return (
		<div className={classes.mainContainer}>
			{props.auth && (
				<AppHeader
					previewButtonsVisible={props.previewButtonsVisible}
					previewButtonIndex={props.previewButtonIndex}
					setPreviewButtonIndex={props.setPreviewButtonIndex}
					setting={setting}
				/>
			)}
			<Grid container className={classes.pageContainer}>
				{props.auth && (
					<DetailLeftFrame
						activeMenuEvent={props.activeMenuEvent}
						setActiveMenuEvent={props.setActiveMenuEvent}
						isFullPagePreview={props.isFullPagePreview}
					/>
				)}
				<div className={classes.contentContainer}>{props?.children}</div>
			</Grid>
		</div>
	)
}

const mapStateToProps = (state) => ({
	...state.auth
})

const mapDispatchToProps = (dispatch) => {
	return {
		setDetail: (x) => dispatch(setDetail(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AppDetailLayout)

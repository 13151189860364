import React from 'react'

import { Link, useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import cx from 'classnames'

const useStyles = makeStyles((theme) => ({
	menuItem: {
		textDecoration: 'none !important',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		height: 40,
		marginBottom: 5,
		paddingLeft: 10,
		borderRadius: 6,
		'&:hover': {
			backgroundColor: '#FAFAFC'
		}
	},
	active: {
		backgroundColor: `${theme.custom.colors.kozmikLightBlue} !important`,
		'& $text': {
			color: theme.custom.colors.kozmikBlue,
			fontWeight: '600'
		}
	},
	text: {
		fontSize: 14,
		color: theme.custom.colors.textPrimary,
		textAlign: 'left'
	}
}))

const AppMenuItem = (props) => {
	const classes = useStyles()
	let currentPage = useLocation().pathname

	const activeHref = props.data.href

	return (
		<Link
			to={props.data.href}
			className={cx(
				classes.menuItem,
				activeHref.indexOf(currentPage) >= 0 && classes.active
			)}
		>
			<Typography className={classes.text}>{props.data.name}</Typography>
		</Link>
	)
}

export default AppMenuItem

import CryptoJS from 'crypto-js'

const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY)
const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_IV)

const encrypt = (plaintext) => {
	const encrypted = CryptoJS.AES.encrypt(plaintext, key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	})

	const encryptedData = encrypted.toString()

	return encryptedData
}

const decrypt = (cipherText) => {
	const encryptedBytes = CryptoJS.enc.Base64.parse(cipherText)

	const decrypted = CryptoJS.AES.decrypt(
		{
			ciphertext: encryptedBytes
		},
		key,
		{
			iv: iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7
		}
	)

	const decryptedData = decrypted.toString(CryptoJS.enc.Utf8)

	return decryptedData
}

export default { encrypt, decrypt }

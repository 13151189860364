import { createElement } from 'react'

import PhoneButton from './elements/PhoneButton'
import EmailButton from './elements/EmailButton'
import LinkButton from './elements/LinkButton'
import List from './elements/List'
import Table from './elements/Table'
import Columns from './elements/Columns'
import TileList from './elements/TileList'
import Chat from './elements/Chat'
import Reviews from './elements/Reviews'
import MapList from './elements/MapList'
import Carousel from './elements/Carousel'
import Container from './elements/Container'
import Gallery from './elements/Gallery'
import TitleCard from './elements/TitleCard'
import NoteBox from './elements/NoteBox'
import Form from './elements/Form'
import UserInfo from './elements/UserInfo'
import LogoutButton from './elements/LogoutButton'
import SettingsButton from './elements/SettingsButton'
import Seperator from './elements/Seperator'
import TextArea from './elements/TextArea'
import Image from './elements/Image'
import Button from './elements/Button'
import ButtonBar from './elements/ButtonBar'
import Checkbox from './elements/Checkbox'
import Video from './elements/Video'
import WebView from './elements/WebView'
import Map from './elements/Map'
import Choice from './elements/Choice'
import CalendarList from './elements/CalendarList'
import NumberEntry from './elements/NumberEntry'

import ChartLine from './elements/ChartLine'
import ChartBar from './elements/ChartBar'
import ChartPie from './elements/ChartPie'
import ChartProgress from './elements/ChartProgress'
import ProgressBar from './elements/ProgressBar'

import Barcode from './elements/Barcode'
import QRCode from './elements/QRCode'

import Numbers from './elements/Numbers'
import Rating from './elements/Rating'

const Components = {
	numbers: Numbers,
	rating: Rating,
	chartLine: ChartLine,
	chartBar: ChartBar,
	chartPie: ChartPie,
	chartProgress: ChartProgress,
	progressBar: ProgressBar,
	phoneButton: PhoneButton,
	emailButton: EmailButton,
	linkButton: LinkButton,
	list: List,
	table: Table,
	columns: Columns,
	tileList: TileList,
	reviews: Reviews,
	mapList: MapList,
	chat: Chat,
	carousel: Carousel,
	container: Container,
	gallery: Gallery,
	titleCard: TitleCard,
	noteBox: NoteBox,
	form: Form,
	userInfo: UserInfo,
	logoutButton: LogoutButton,
	settingsButton: SettingsButton,
	seperator: Seperator,
	textArea: TextArea,
	image: Image,
	button: Button,
	buttonBar: ButtonBar,
	checkbox: Checkbox,
	video: Video,
	webView: WebView,
	map: Map,
	choice: Choice,
	calendarList: CalendarList,
	numberEntry: NumberEntry,
	barcode: Barcode,
	qrCode: QRCode
}

const ElementSettings = (props) => {
	return Components?.[props?.activeElement?.component]
		? createElement(Components[props.activeElement.component], {
				allScreens: props.allScreens,
				entities: props.entities,
				activeScreen: props.activeScreen,
				updateActiveScreen: props.updateActiveScreen,
				elementData: props.activeElement,
				updateSelectedElement: props.updateSelectedElement,
				updateBulkScreensWithModifiedData:
					props.updateBulkScreensWithModifiedData,
				sorting: props.sorting,
				filtering: props.filtering,
				linkingObject: props.linkingObject
		  })
		: null
}

export default ElementSettings

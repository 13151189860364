import * as React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { IconButton, Box } from '@mui/material'
import { Publish } from '@mui/icons-material'

const useStyles = makeStyles((/*theme*/) => ({
	mainContainer: {
		margin: '0 1%'
	},
	input: {
		display: 'none'
	},
	label: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 1
	},
	button: {
		color: '#000000',
		fontSize: '14px',
		fontWeight: 600,
		borderRadius: '8px',
		padding: '8px'
	}
}))

const MediaUploadButton = (props) => {
	const classes = useStyles()

	return (
		<Box className={classes.mainContainer}>
			<input
				// refs='file'
				accept='image/*'
				className={classes.input}
				id='icon-button-file'
				type='file'
				{...props}
			/>

			<label htmlFor='icon-button-file' className={classes.label}>
				<IconButton
					aria-label='upload picture'
					component='span'
					className={classes.button}
					size='large'
				>
					<Publish style={{ marginRight: 5 }} />
					{props?.text}
				</IconButton>
			</label>
		</Box>
	)
}

export default MediaUploadButton

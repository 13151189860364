import i18next from '../../i18n/i18n'

const formulas = {
	onlyProfile: [
		{
			label: `${i18next.t('formulaOpts.profile.prefix')}.${i18next.t(
				'formulaOpts.profile.id'
			)}`,
			name: '{{profile.id}}',
			dataType: 'Variables'
		}
	],
	profile: [
		{
			label: `${i18next.t('formulaOpts.profile.prefix')}.${i18next.t(
				'formulaOpts.profile.id'
			)}`,
			name: '{{profile.id}}',
			dataType: 'Variables'
		},
		{
			label: `${i18next.t('formulaOpts.profile.prefix')}.${i18next.t(
				'formulaOpts.profile.name'
			)}`,
			name: '{{profile.name}}',
			dataType: 'Variables'
		},
		{
			label: `${i18next.t('formulaOpts.profile.prefix')}.${i18next.t(
				'formulaOpts.profile.email'
			)}`,
			name: '{{profile.email}}',
			dataType: 'Variables'
		},
		{
			label: `${i18next.t('formulaOpts.profile.prefix')}.${i18next.t(
				'formulaOpts.profile.photo'
			)}`,
			name: '{{profile.photo}}',
			dataType: 'Variables'
		}
	],
	datetime: [
		{
			label: `${i18next.t('formulaOpts.current.prefix')}.${i18next.t(
				'formulaOpts.current.today'
			)}`,
			name: '{{datetime.date}}',
			dataType: 'Variables'
		},
		{
			label: `${i18next.t('formulaOpts.current.prefix')}.${i18next.t(
				'formulaOpts.current.now'
			)}`,
			name: '{{datetime.time}}',
			dataType: 'Variables'
		}
	],
	string: [
		{
			label: `${i18next.t('formulaOpts.current.prefix')}.${i18next.t(
				'formulaOpts.current.year'
			)}`,
			name: '{{datetime.year}}',
			dataType: 'Variables'
		},
		{
			label: `${i18next.t('formulaOpts.current.prefix')}.${i18next.t(
				'formulaOpts.current.month'
			)}`,
			name: '{{datetime.month}}',
			dataType: 'Variables'
		}
	]
}

export default formulas

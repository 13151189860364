import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { toast } from 'react-toastify'
import { /*useNavigate*/ useHistory } from 'react-router-dom'
import { CardMedia, Button, Typography, CircularProgress } from '@mui/material'

import ColoredButton from '../../atoms/button/ColoredButton'

import authorizationClient from '../../../services/authorizationClient'

import useQuery from '../../../hooks/UseQuery/useQuery'
import showToastResponseError from '../../../methods/showToastResponseError'

import KozmikLogoLight from '../../../assets/icons/KozmikLogoLight.png'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		padding: 27
	},
	contentContainer: {
		marginTop: '20vh',
		maxWidth: 374,
		width: 374,
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	subTitle: {
		marginBottom: 30,
		textAlign: 'center',
		fontSize: 14,
		fontWeight: '600',
		color: '#ffffff'
	},
	buttonContainer: {
		margin: '10px 0'
	},
	logoBox: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 80
	},
	logo: {
		width: 163,
		height: 'auto'
	},
	button: {
		borderRadius: 8,
		boxShadow: 'none !important',
		textTransform: 'unset',
		height: 50,
		backgroundColor: `${theme.custom.colors.kozmikGreen} !important`
	},
	submitText: {
		fontSize: 16,
		fontWeight: 600,
		color: theme.custom.colors.kozmikDarkBlue
	},
	loader: {
		margin: '20px',
		color: theme.custom.colors.kozmikDarkBlue
	}
}))

const AcceptInvitation = () => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [submitting, setSubmitting] = useState(false)

	const history = useHistory()
	const navigate = (url) => {
		history.push(url)
	} //let navigate = useNavigate()
	let queryParams = useQuery()

	const submit = () => {
		const data = {
			token: queryParams.token,
			orgToken: queryParams.orgToken
		}
		setSubmitting(true)
		authorizationClient
			.acceptInvitation(data)
			.then((response) => {
				if (response?.data?.success) {
					// toast.success(response?.data?.message)
					navigate('/login')
				} else {
					showToastResponseError(response)
				}
				setSubmitting(false)
			})
			.catch(() => {
				setSubmitting(false)
			})
	}

	return (
		<div className={classes.mainContainer}>
			<div className={classes.contentContainer}>
				<div>
					<div className={classes.logoBox}>
						<a href='https://kozmik.io'>
							<CardMedia
								image={KozmikLogoLight}
								component='img'
								className={classes.logo}
							/>
						</a>
					</div>
					<h2 className={classes.subTitle}>
						{t('authScreens.acceptInvitation.subTitle')}
					</h2>
				</div>

				<div className={classes.buttonContainer}>
					<Button
						fullWidth
						variant='contained'
						className={classes.button}
						onClick={submit}
						disabled={submitting}
						sx={{
							opacity: submitting ? 0.6 : 1
						}}
					>
						<Typography className={classes.submitText}>
							{t('authScreens.acceptInvitation.acceptButton')}
						</Typography>
						{submitting && (
							<CircularProgress size={20} className={classes.loader} />
						)}
					</Button>
				</div>

				{/* <div className={classes.buttonContainer}>
					<ColoredButton
						onClick={() => navigate('/login')}
						variant='text'
						textColor={'#00EF91'}
						bgColor='transparent'
						size='large'
						fullWidth
						style={{
							textTransform: 'unset',
							fontSize: 16,
							fontWeight: 600
						}}
					>
						{t('authScreens.acceptInvitation.cancelButton')}
					</ColoredButton>
				</div> */}
			</div>
		</div>
	)
}

export default AcceptInvitation

import { v4 as uuidv4 } from 'uuid'

import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import i18next from '../../i18n/i18n'
import relationTypes from '../../common/enums/relationTypes'

const method = (table) => {
	const imgFields = getEntityFieldsByDataTypes(
		null,
		null,
		['Image'],
		table,
		true,
		relationTypes.one
	)
	const firstImgField = imgFields?.length ? imgFields[0] : null

	let object = {
		_uid: uuidv4(),
		component: 'image',
		mapping: {
			imgUrl: {
				field: firstImgField?.name,
				default: firstImgField?.name ? null : null, //i18next.t('schemas.image.imgUrl'),
				type: firstImgField?.name ? firstImgField?.dataType : null
			}
		},
		spacing: 'xl',
		appearance: 'square',
		imageResizeMode: 'cover',
		actions: [{ type: null }]
	}

	return object
}

export default method

import Cookies from 'universal-cookie'

import store from '../../redux/store/store'
import { setCurrentOrg } from '../../redux/actions/userInfoActions'
import authorizationClient from '../authorizationClient'

const set = (org) => {
	if (!org) return

	const cookies = new Cookies()

	cookies.set('orgId', org.id, {
		path: '/',
		sameSite: 'none',
		secure: true,
		domain: process.env.REACT_APP_COOKIE_DOMAIN
	})

	authorizationClient.getUsage()

	store.dispatch(setCurrentOrg(org))
}

const remove = () => {
	const cookies = new Cookies()

	cookies.remove('orgId', {
		path: '/',
		sameSite: 'none',
		secure: true,
		domain: process.env.REACT_APP_COOKIE_DOMAIN
	})

	store.dispatch(setCurrentOrg(null))
}

export default { set, remove }

import dataTypeRegExes from '../common/regExp/dataType'

const getRegExpByDataType = (dataType, languageCode = null) => {
	const regExObj = dataTypeRegExes?.[dataType]

	if (languageCode && regExObj?.re?.tr) {
		return {
			re: regExObj.re[languageCode],
			msg: regExObj.msg
		}
	} else {
		return regExObj
	}
}

export default getRegExpByDataType

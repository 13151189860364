import React, { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Menu, SubMenu, MenuItem, MenuButton } from '@szhsin/react-menu'
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@mui/icons-material'
import cx from 'classnames'

import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import { Tooltip } from '@mui/material'

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		overflow: 'hidden',
		borderRadius: 3
	},
	menuButtonContainer: {
		display: 'flex',
		width: '100%',
		height: 38,
		backgroundColor: (props) => (props?.bgColor ? props.bgColor : '#FAFAFC'),
		border: 'none',
		alignItems: 'center',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	menuButton: {
		flexGrow: 1,
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		textAlign: 'left',
		padding: '8.5px 14px',
		borderRadius: 4,
		color: theme.custom.colors.textPrimary,
		fontSize: 14
	},
	menuItem: {
		fontSize: 14
	},
	menuButtonIcon: {
		color: theme.custom.colors.textSecondary,
		fontSize: '1.5rem',
		marginRight: 7
	}
}))

const Dropdown = (props) => {
	const classes = useStyles(props)

	const {
		textKey,
		valueKey,
		groupKey,
		groupKeySeperator,
		objectValueKey,
		data,
		value,
		emptyText,
		startAdornment,
		bgColor,
		...restProps
	} = props

	const forwardIcon =
		'<span style="font-size:12px;color:#DEDEDE;margin:0 5px;">&#62;</span>'

	const groupedData =
		groupKey &&
		data?.reduce((group, item) => {
			const seperatorIndex =
				groupKeySeperator && item[groupKey]?.indexOf(groupKeySeperator)

			const gk =
				groupKeySeperator && seperatorIndex > -1
					? item[groupKey]?.slice(0, seperatorIndex)
					: item[groupKey]
			group[gk] = group[gk] ?? []
			group[gk].push(item)

			return group
		}, {})

	const groupedDataArr =
		groupedData &&
		Object.keys(groupedData).map((key) => [key, groupedData[key]])

	const valueText = data?.find((x) => x[valueKey] === value)?.[textKey] ?? ''

	const beautifyText = (text) => {
		if (!text) return null

		return groupKeySeperator ? text?.split('.').join(forwardIcon) : text
	}

	const getItemText = (text) => {
		return groupKeySeperator
			? beautifyText(
					text?.slice((text?.indexOf(groupKeySeperator) ?? 0) + 1, text?.length)
			  ) ?? ''
			: text
	}

	const renderMenuItem = (item, index) => {
		return item?.disabled && item?.tooltip ? (
			<Tooltip key={`${index}-tooltip`} title={item?.tooltip} followCursor>
				<MenuItem
					key={index}
					value={
						objectValueKey
							? item?.[valueKey]?.[objectValueKey]
							: valueKey
							? item?.[valueKey]
							: item
					}
					disabled={item?.disabled}
					className={classes.menuItem}
				>
					<span
						dangerouslySetInnerHTML={{
							__html: getItemText(item[textKey])
						}}
					/>
				</MenuItem>
			</Tooltip>
		) : (
			<MenuItem
				key={index}
				value={
					objectValueKey
						? item?.[valueKey]?.[objectValueKey]
						: valueKey
						? item?.[valueKey]
						: item
				}
				disabled={item?.disabled}
				className={classes.menuItem}
			>
				<span
					dangerouslySetInnerHTML={{
						__html: getItemText(item[textKey])
					}}
				/>
			</MenuItem>
		)
	}

	return (
		<div className={classes.container}>
			{startAdornment}
			<Menu
				menuButton={({ open }) => (
					<div className={classes.menuButtonContainer}>
						<MenuButton className={classes.menuButton}>
							<span
								dangerouslySetInnerHTML={{
									__html: beautifyText(valueText ?? value) ?? emptyText
								}}
							/>
						</MenuButton>
						{open ? (
							<ArrowDropUpOutlined className={classes.menuButtonIcon} />
						) : (
							<ArrowDropDownOutlined className={classes.menuButtonIcon} />
						)}
					</div>
				)}
				transition
				{...restProps}
			>
				{restProps?.placeholder && (
					<MenuItem disabled value={null} className={classes.menuItem}>
						{restProps.placeholder}
					</MenuItem>
				)}

				{!!emptyText && (
					<MenuItem value={null} className={classes.menuItem}>
						{emptyText}
					</MenuItem>
				)}

				{!!groupedDataArr?.length &&
					groupedDataArr.map((group, groupIndex) => {
						return group[0] === group[1][0][textKey] ? (
							renderMenuItem(group[1][0], groupIndex)
						) : (
							<SubMenu
								key={groupIndex}
								label={group[0]}
								className={classes.menuItem}
							>
								{group[1].map((item, index) =>
									renderMenuItem(item, `${groupIndex}-${index}`)
								)}
							</SubMenu>
						)
					})}
			</Menu>
		</div>
	)
}
export default Dropdown

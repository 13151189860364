import { SETDETAIL, SETCONFIG } from '../action-types/appDataActionTypes'

export const setDetail = (value) => ({
	type: SETDETAIL,
	payload: value
})
export const setConfig = (value) => ({
	type: SETCONFIG,
	payload: value
})

import { v4 as uuidv4 } from 'uuid'

import i18next from '../../i18n/i18n'
import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../common/fieldDataTypes'
import relationTypes from '../../common/enums/relationTypes'
import getArrayExcludingForbidArray from '../../methods/getArrayExcludingForbidArray'
import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'

const method = (table) => {
	const txtFields = getEntityFieldsByDataTypes(
		null,
		null,
		fieldDataTypes.textBased,
		table,
		true,
		relationTypes.one
	)

	const filteredTxtFields = getArrayExcludingForbidArray(
		txtFields,
		'name',
		systemFieldNames.notVisible
	)

	const object = {
		_uid: uuidv4(),
		component: 'buttonBar',
		mapping: {
			leftText: {
				field: filteredTxtFields?.[0]?.name,
				default: filteredTxtFields?.[0]?.name
					? null
					: i18next.t('schemas.buttonBar.leftText'),
				type: filteredTxtFields?.[0]?.name
					? filteredTxtFields?.[0]?.dataType
					: null
			},
			rightText: {
				field: filteredTxtFields?.[1]?.name,
				default: filteredTxtFields?.[1]?.name
					? null
					: i18next.t('schemas.buttonBar.rightText'),
				type: filteredTxtFields?.[1]?.name
					? filteredTxtFields?.[1]?.dataType
					: null
			}
		},
		left: {
			icon: null,
			corner: 'oval',
			type: 'full',
			actions: [{ type: null }]
		},
		right: {
			icon: null,
			corner: 'oval',
			type: 'border',
			actions: [{ type: null }]
		},
		spacing: 'sm'
	}

	return object
}

export default method

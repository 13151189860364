import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import cx from 'classnames'
import CardMedia from '@mui/material/CardMedia'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		backgroundColor: (props) => (props?.bgColor ? props.bgColor : '#FFFFFF'),
		border: `1px solid ${theme.custom.colors.gray}`,
		overflow: 'hidden',
		borderRadius: (props) => (props?.circle ? '50%' : '4px'),
		width: (props) => (props?.size ? props.size : 40),
		height: (props) => (props?.size ? props.size : 40),
		minWidth: (props) => (props?.size ? props.size : 40),
		minHeight: (props) => (props?.size ? props.size : 40),
		fontSize: (props) => (props?.size ? props.size / 2.86 : '14px')
	},
	text: {
		color: theme.custom.colors.textSecondary,
		fontWeight: 'bold',
		textTransform: 'uppercase'
	},
	centerContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	image: {
		width: '100%',
		height: '100%'
	}
}))

const ProfileCard = (props) => {
	const classes = useStyles({
		size: props?.size,
		bgColor: props?.bgColor,
		circle: props?.circle
	})

	const textManager = (text) => {
		const letterArray = text.match(/\b(\w)/g)
		const letters = letterArray.join('')

		return letters.slice(0, 3)
	}

	return (
		<div
			className={cx(
				classes.mainContainer,
				classes.centerContainer,
				classes.text
			)}
			style={
				props?.size && {
					width: props.size,
					height: props.size,
					minWidth: props.size,
					minHeight: props.size,
					fontSize: props.size / 2.86
				}
			}
		>
			{props?.imgUrl ? (
				<CardMedia
					image={props.imgUrl}
					component='img'
					className={classes.image}
				/>
			) : (
				props?.text && textManager(props.text)
			)}
		</div>
	)
}

export default ProfileCard

import systemFieldNames from '../common/systemFieldNames/systemFieldNames'

const convertFieldsToActionSourceFields = (
	entity,
	listSource,
	formSource,
	screenSource,
	entities = null
) => {
	if (entity) {
		const listFields =
			listSource &&
			(entity.name === listSource
				? entity.fields
				: entities?.find((x) => x.name === listSource)?.fields)
		const formFields =
			formSource &&
			(entity.name === formSource
				? entity.fields
				: entities?.find((x) => x.name === formSource)?.fields)
		const screenFields =
			screenSource &&
			(entity.name === screenSource
				? entity.fields
				: entities?.find((x) => x.name === screenSource)?.fields)

		const availableFields = entity.fields
			?.filter(
				(x) =>
					!systemFieldNames.notVisible.includes(x.name) &&
					x.dataType !== 'Lookup' &&
					x.dataType !== 'Formula' &&
					x.dataType !== 'Rollup'
			)
			.map((x) => {
				const listField =
					listFields?.find((f) => f.name === x.name)?.name ?? null
				const formField =
					formFields?.find((f) => f.name === x.name)?.name ?? null
				const screenField =
					screenFields?.find((f) => f.name === x.name)?.name ?? null

				return {
					label: x.label,
					field: x.name,
					type: x.dataType,
					relationType: x.relationType,
					value: listField
						? x.name
						: formField
						? `Form.${x.name}`
						: screenField
						? `Screen.${x.name}`
						: null,
					valueType: listField
						? 'Field'
						: formField
						? 'Form'
						: screenField
						? 'Screen'
						: 'Field'
				}
			})

		return availableFields
	}

	return []
}

export default convertFieldsToActionSourceFields

import { DataGridPro } from '@mui/x-data-grid-pro'
import { styled } from '@mui/material/styles'

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
	border: 0,
	fontSize: 13,
	color:
		theme.palette.mode === 'light'
			? 'rgba(0,0,0,.85)'
			: 'rgba(255,255,255,0.85)',
	WebkitFontSmoothing: 'auto',
	letterSpacing: 'normal',
	'& .MuiDataGrid-columnsContainer': {
		backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d'
	},
	'& .MuiDataGrid-iconSeparator': {
		visibility: 'hidden'
	},
	// '& .MuiDataGrid-columnHeadersInner--scrollable': {
	// 	alignItems: 'stretch',
	// 	width: '4000px !important'
	// 	// transform: 'none !important'
	// },
	'& .MuiDataGrid-columnHeadersInner': {
		alignItems: 'stretch',
		// width: '0px !important'
		// transform: 'none !important'
		'& .MuiDataGrid-columnHeaderTitle': {
			fontWeight: 'bold'
		}
	},
	'& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
		borderRight: `1px solid ${
			theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
		}`
	},
	'& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
		borderBottom: `1px solid ${
			theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
		}`
	},
	'& .MuiDataGrid-row.Mui-selected': {
		backgroundColor: `${theme.custom.colors.kozmikLightBlue2} !important`
	},
	'& .MuiDataGrid-row:hover': {
		backgroundColor: theme.custom.colors.kozmikGray
	},
	'& .MuiDataGrid-cell': {
		fontWeight: 400,
		color:
			theme.palette.mode === 'light'
				? theme.custom.colors.textPrimary
				: 'rgba(255,255,255,0.65)'
	},
	'& .MuiPaginationItem-root': {
		borderRadius: 0
	}
}))

export default StripedDataGrid

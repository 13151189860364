import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Popover } from '@mui/material'
import { connect } from 'react-redux'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import ProfileCard from '../../molecules/profileCard/ProfileCard'
import PopoverItem from '../../molecules/popoverItem/PopoverItem'

import { setGhostUser } from '../../../redux/actions/userInfoActions'

import kozmikTheme from '../../../assets/theme/theme.json'

const useStyles = makeStyles((theme) => ({
	infoBox: {
		flexGrow: 1,
		paddingLeft: 10,
		paddingRight: 10,
		overflow: 'hidden'
	},
	nameTxt: {
		color: theme.custom.colors.textPrimary,
		fontSize: 11,
		textAlign: 'left',
		fontWeight: 600,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	mainContainer: {
		display: 'flex',
		alignItems: 'center',
		maxWidth: 200,
		height: 40,
		borderRadius: 6,
		padding: 5,
		cursor: 'pointer',
		position: 'absolute',
		left: '18%',
		top: 10,
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.03)',
		[theme.breakpoints.down('md')]: {
			display: 'none'
		}
	},
	mainContainerInline: {
		display: 'flex',
		alignItems: 'center',
		maxWidth: 200,
		borderRadius: 6,
		padding: 5,
		cursor: 'pointer',
		[theme.breakpoints.down('md')]: {
			display: 'none'
		}
	},
	mainContainerMobile: {
		display: 'flex',
		alignItems: 'center',
		maxWidth: 200,
		borderRadius: 6,
		padding: '2px 5px',
		cursor: 'pointer',
		backgroundColor: theme.custom.colors.kozmikLightBlue2,
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	},
	popoverBox: {
		padding: 8,
		display: 'flex',
		flexDirection: 'column'
	},
	viewAsIcon: {
		fontSize: 18,
		color: theme.custom.colors.textPrimary
	}
}))

const ViewAs = (props) => {
	const classes = useStyles(props)
	const { t } = useTranslation()

	const [currenUser, setCurrenUser] = useState(null)
	const [viewAsPop, setViewAsPop] = useState(null)

	const openViewAsPop = (event) => {
		setViewAsPop(event.currentTarget)
	}
	const closeViewAsPop = () => {
		setViewAsPop(null)
	}
	const viewAsPopOpen = Boolean(viewAsPop)

	const selectViewAsUser = (user) => {
		setCurrenUser(user)

		props.setGhostUser(user)

		closeViewAsPop()
	}

	useEffect(() => {
		props.ghostUser &&
			props.ghostUser != currenUser &&
			setCurrenUser(props.ghostUser)
	}, [props.ghostUser])

	return (
		<>
			<div
				className={
					props.mobile
						? classes.mainContainerMobile
						: props.inline
						? classes.mainContainerInline
						: classes.mainContainer
				}
				onClick={openViewAsPop}
			>
				<ProfileCard
					imgUrl={currenUser?.Photo}
					text={currenUser?.Name ?? t('viewAs.anyone')}
					size={25}
					circle
					bgColor={kozmikTheme.colors.gray}
				/>
				<div className={classes.infoBox}>
					<div className={classes.nameTxt}>
						{t('viewAs.viewAs')}
						{currenUser?.Name ?? t('viewAs.anyone')}
					</div>
				</div>
				{props.users?.length > 0 && (
					<ArrowDropDownIcon className={classes.viewAsIcon} />
				)}
			</div>

			{props.users?.length > 0 && (
				<Popover
					style={{ maxHeight: 500 }}
					open={viewAsPopOpen}
					anchorEl={viewAsPop}
					onClose={closeViewAsPop}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left'
					}}
				>
					<Box className={classes.popoverBox}>
						<PopoverItem
							key={-1}
							text={t('viewAs.anyone')}
							onClick={() => {
								selectViewAsUser(null)
							}}
							style={{ marginTop: 3, marginBottom: 3 }}
							color='#000000'
						>
							<ProfileCard
								text={t('viewAs.anyone')}
								size={25}
								circle
								bgColor={kozmikTheme.colors.gray}
							/>
						</PopoverItem>
						{props.users
							?.filter(
								(x) => (currenUser && x.Id != currenUser?.Id) || !currenUser
							)
							.map((item, index) => (
								<PopoverItem
									key={index}
									text={item?.Name}
									onClick={() => {
										selectViewAsUser(item)
									}}
									style={{ marginTop: 3, marginBottom: 3 }}
									color='#000000'
								>
									<ProfileCard
										imgUrl={item?.Photo}
										text={item?.Name}
										size={25}
										circle
										bgColor={kozmikTheme.colors.gray}
									/>
								</PopoverItem>
							))}
					</Box>
				</Popover>
			)}
		</>
	)
}

const mapStateToProps = (state) => ({
	...state.appData,
	...state.userInfo,
	...state.ghostUser
})

const mapDispatchToProps = (dispatch) => {
	return {
		setGhostUser: (x) => dispatch(setGhostUser(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAs)

import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import { setOnboarding } from '../../../redux/actions/onboardingActions'

import CircularProgress from '@mui/material/CircularProgress'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CardMedia } from '@mui/material'

import KozmikLogo from '../../../assets/icons/KozmikLogo.png'

const Onboarding = (props) => {
	const { t } = useTranslation()
	const history = useHistory()

	useEffect(() => {
		if (props.onboarding) {
			props.setOnboarding(false)

			setTimeout(() => {
				history.replace('')
			}, 2000)
		} else {
			history.replace('')
		}
	}, [])

	return (
		<div
			style={{
				display: 'flex',
				height: '100vh',
				flexWrap: 'wrap',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<div style={{ maxWidth: 800 }}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginBottom: 50
					}}
				>
					<a href='/'>
						<CardMedia
							image={KozmikLogo}
							component='img'
							style={{ width: 75, height: 'auto' }}
						/>
					</a>
				</div>
				<h2
					style={{
						margin: 20,
						fontSize: 20,
						textAlign: 'center',
						marginBottom: 20
					}}
				>
					{t('onboarding.welcome')}
				</h2>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center'
					}}
				>
					<CircularProgress />
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	...state.onboarding
})

const mapDispatchToProps = (dispatch) => {
	return {
		setOnboarding: (x) => dispatch(setOnboarding(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding)

import { ApiClient } from './client'

import { default as appConfig } from '../config/app.json'

const dataUrl = `${appConfig.domains.dataDomain}/api/datasource`

export default {
	async testConnection(data = null) {
		let url = `/test`
		let headers = {}

		return await new ApiClient(dataUrl, headers).post(url, data)
	},
	async getEntitySummaries(data = null) {
		let url = `/getEntitySummaries`
		let headers = {}

		return await new ApiClient(dataUrl, headers).post(url, data)
	}
}

import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import {
	SwapVert,
	BrowserNotSupported,
	FilterAltOutlined,
	PlusOne
} from '@mui/icons-material'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import Accordion from '../../../atoms/accordion/Accordion'
import FilterModalOptions from '../../../molecules/filterModalOptions/FilterModalOptions'
import SearchOptions from '../../../molecules/searchOptions/SearchOptions'
import NumericInput from '../../../atoms/numericInput/NumericInput'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import AccordionItem from '../../../atoms/accordionItem/AccordionItem'
import FilterList from '../../../molecules/filterList/FilterList'
import Sorting from '../../../molecules/sorting/Sorting'
import ActionList from '../../actionList/ActionList'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import { ReactComponent as Koseli } from '../../../../assets/icons/Settings/Koseli.svg'
import { ReactComponent as Oval } from '../../../../assets/icons/Settings/Oval.svg'

import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import aggOperators from '../../../../common/aggOperators'

import relationTypes from '../../../../common/enums/relationTypes'

import defaultElementSchemas from '../../../../defaultElementSchemas'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	inputLight: {
		borderRadius: 3,
		backgroundColor: '#ffffff',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const ChartPie = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [elementName, setElementName] = useState(null)

	/****SOURCE***************************/
	const [source, setSource] = useState('')

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		['Text', 'LongText', 'Number', 'Date', 'Email', 'Formula'],
		null,
		true,
		relationTypes.one
	)
	const numericFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		['Number', 'Rollup', 'Formula'],
		null,
		true,
		relationTypes.one
	)?.filter(
		(x) =>
			!x.formula || (x.formula && x.formula.formulaFieldDataType === 'Number')
	)

	const sourceChange = (e) => {
		submitSource(source, e.target.value)
		setSource(e.target.value)
	}
	const submitSource = (oldTableName, tableName) => {
		const table = props?.entities?.find((y) => y.name == tableName)

		const txtFields = getEntityFieldsByDataTypes(
			props?.entities,
			tableName,
			['Text', 'LongText', 'Number', 'Date', 'Email'],
			null,
			true,
			relationTypes.one
		)
		const numericFields = getEntityFieldsByDataTypes(
			props?.entities,
			tableName,
			['Number'],
			null,
			true,
			relationTypes.one
		)

		const labelField =
			txtFields?.find((x) => x.name === 'Name') ??
			txtFields?.find((x) => !numericFields?.find((y) => x.name === y.name)) ??
			txtFields?.[0]
		const numericField = numericFields?.length ? numericFields[0] : null

		let modifiedElementData = update(props.elementData, {
			source: { $set: tableName },
			mapping: {
				aggregation: { $set: 'count' },
				label: {
					field: { $set: labelField?.name },
					type: { $set: labelField?.dataType }
				},
				value: {
					field: { $set: numericField?.name },
					type: { $set: numericField?.dataType }
				}
			},
			sorting: {
				field: { $set: numericField?.name },
				sort: { $set: 'asc' }
			},
			filters: {
				groupOp: { $set: 'AND' },
				rules: { $set: [] }
			},
			filterModal: {
				$set: defaultElementSchemas.filterModal()
			}
		})

		// props.updateBulkScreensWithModifiedData(
		// 	table.name,
		// 	null,
		// 	modifiedElementData
		// )

		props.updateSelectedElement(modifiedElementData)
	}

	/****LABEL***************************/
	const [label, setLabel] = useState('')
	const labelChange = (e) => {
		submitLabel(e.target.value)
		setLabel(e.target.value)
	}
	const submitLabel = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				label: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****AGGREGATION***************************/
	const [aggregation, setAggregation] = useState('')
	const aggregationChange = (e) => {
		submitAggregation(e.target.value)
		setAggregation(e.target.value)
	}
	const submitAggregation = (x) => {
		const modifiedElementData = update(props.elementData, {
			mapping: {
				aggregation: { $set: x }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****VALUE***************************/
	const [value, setValue] = useState('')
	const valuceChange = (e) => {
		submitValue(e.target.value)
		setValue(e.target.value)
	}
	const submitValue = (x) => {
		const selectedField = numericFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				value: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ACTIONS***************************/
	const updateActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			actions: { $set: newActions }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****LIMIT***************************/
	const [limit, setLimit] = useState(10)
	const limitChange = (val) => {
		timeoutDelay(submitLimit, val, 1000)
		setLimit(val)
	}
	const submitLimit = (x) => {
		const modifiedElementData = update(props.elementData, {
			limit: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****BACKGROUND***************************/
	const [background, setBackground] = useState('none')
	const backgroundChange = (val) => {
		submitBackground(val)
		setBackground(val)
	}
	const submitBackground = (x) => {
		const modifiedElementData = update(props.elementData, {
			background: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}
	const backgroundOptions = [
		{
			text: t('screens.elementSettings.chart.backgroundOpts.none'),
			value: 'none',
			icon: BrowserNotSupported
		},
		{
			text: t('screens.elementSettings.chart.backgroundOpts.square'),
			value: 'square',
			icon: Koseli
		},
		{
			text: t('screens.elementSettings.chart.backgroundOpts.oval'),
			value: 'oval',
			icon: Oval
		}
	]

	/****FILTER_&_SORT***************************/
	const [localElementData, setLocalElementData] = useState(null)

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****DATA***************************/
		setSource(elementData?.source ?? '')
		setLimit(elementData?.limit ?? 10)
		/****CONTENT***************************/
		setLabel(elementData?.mapping?.label?.field ?? '')
		setAggregation(elementData.mapping?.aggregation ?? '')
		setValue(elementData?.mapping?.value?.field ?? '')
		/****DESIGN***************************/
		setBackground(elementData?.background ?? 'none')

		if (!elementData?.filterModal) {
			const modifiedElementData = update(elementData, {
				filterModal: {
					$set: defaultElementSchemas.filterModal()
				}
			})
			props.updateSelectedElement(modifiedElementData)
		}

		if (!elementData?.searchPlaceholder) {
			const modifiedElementData = update(elementData, {
				searchPlaceholder: {
					$set: {
						default: t('schemas.numbers.searchPlaceholder')
					}
				}
			})

			props.updateSelectedElement(modifiedElementData)
		}

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
			setLocalElementData(props.elementData)
		}
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.source')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={12}>
							<SelectBox
								className={classes.input}
								onChange={sourceChange}
								value={source}
								data={props.entities}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>

					<div className={classes.mb10}>
						<AccordionItem
							icon={FilterAltOutlined}
							title={t('screens.elementSettings.chart.filter')}
							expanded={false}
							padding={8}
						>
							<FilterList
								filter={localElementData?.filters}
								table={props?.entities?.find(
									(y) => y.name == props?.elementData?.source
								)}
								activeScreen={props?.activeScreen}
								entities={props?.entities}
								tableName={props?.elementData?.source}
								screenTable={props?.entities?.find(
									(y) => y.name == props.activeScreen?.data?.source
								)}
								filtering={props.filtering}
								setLocalElementData={setLocalElementData}
							/>
						</AccordionItem>
					</div>

					<div className={classes.mb10}>
						<AccordionItem
							icon={SwapVert}
							title={t('screens.elementSettings.chart.sort')}
							expanded={false}
							padding={8}
						>
							<Sorting
								sortingData={localElementData?.sorting}
								sorting={props.sorting}
								fieldOptions={textFields}
							/>
						</AccordionItem>
					</div>

					<div className={classes.mb10}>
						<AccordionItem
							icon={PlusOne}
							title={t('screens.elementSettings.chart.limit')}
							expanded={false}
							padding={8}
						>
							<NumericInput minVal={0} value={limit} onChange={limitChange} />
						</AccordionItem>
					</div>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.chart.label')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.chart.emptyField')}
								className={classes.input}
								onChange={labelChange}
								value={label}
								data={textFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.chart.value')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.chart.emptyField')}
								className={classes.input}
								onChange={valuceChange}
								value={value}
								data={numericFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.chart.aggregation')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.chart.emptyField')}
								className={classes.input}
								onChange={aggregationChange}
								value={aggregation}
								data={aggOperators}
								textKey='label'
								valueKey='value'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.chart.background')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={backgroundOptions}
								getChange={backgroundChange}
								activeValue={background}
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			{/* <AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.search')}
					expanded={true}
				>
					<SearchOptions
						source={source}
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox> */}

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.inAppFilter')}
					expanded={false}
				>
					{source && (
						<FilterModalOptions
							source={source}
							activeScreen={props.activeScreen}
							elementData={props.elementData}
							entities={props.entities}
							sorting={props.sorting}
							filtering={props.filtering}
							updateSelectedElement={props.updateSelectedElement}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.actions')}
					expanded={true}
				>
					{source && (
						<ActionList
							name={elementName}
							entities={props.entities}
							activeScreen={props.activeScreen}
							actions={props.elementData.actions}
							updateActions={updateActions}
							isList={true}
							listSource={source}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default ChartPie

import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TableChart, PhoneIphone, Hub, Settings } from '@mui/icons-material'

import DetailMenu from '../../molecules/detailMenu/DetailMenu'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		padding: 15,
		width: 75,
		backgroundColor: '#FAFAFC',
		[theme.breakpoints.down('md')]: {
			display: (props) => (props?.isFullPagePreview ? 'none' : 'block'),
			padding: 7,
			paddingRight: 70,
			width: '100%',
			position: 'fixed',
			bottom: 0,
			zIndex: 11,
			borderTop: `1px solid ${theme.custom.colors.gray}`
		}
	}
}))

const DetailLeftFrame = (props) => {
	const { t } = useTranslation()
	const classes = useStyles({ isFullPagePreview: props.isFullPagePreview })
	let { appId } = useParams()

	const menuList = [
		{
			id: 'data',
			icon: TableChart,
			name: t('detailLeftFrame.data'),
			href: `/app/${appId}/data`,
			isActive: false
		},
		{
			id: 'screens',
			icon: PhoneIphone,
			name: t('detailLeftFrame.screens'),
			href: `/app/${appId}/screens`,
			isActive: true
		},
		{
			id: 'settings',
			icon: Settings,
			name: t('detailLeftFrame.settings'),
			href: `/app/${appId}/settings/info`,
			activeHrefs: [
				`/app/${appId}/settings/info`,
				`/app/${appId}/settings/theme`,
				`/app/${appId}/settings/splash`
			],
			isActive: false
		},
		{
			id: 'integrations',
			icon: Hub,
			name: t('detailLeftFrame.integrations'),
			href: `/app/${appId}/integrations`,
			isActive: false
		}
	]
	return (
		<div className={classes.mainContainer}>
			<DetailMenu
				activeMenuEvent={props.activeMenuEvent}
				setActiveMenuEvent={props.setActiveMenuEvent}
				menuItems={menuList}
			/>
		</div>
	)
}

export default DetailLeftFrame

import { SETUSAGE } from '../action-types/usageActionTypes'

const initialState = {
	usage: null
}

const usageReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case SETUSAGE:
			return { ...state, usage: action.payload }
		default:
			return state
	}
}

export default usageReducer

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import makeStyles from '@mui/styles/makeStyles'
import { /*useNavigate*/ useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie'
import {
	CardMedia,
	TextField,
	Button,
	CircularProgress,
	Typography
} from '@mui/material'

import ColoredButton from '../../atoms/button/ColoredButton'

import authorizationClient from '../../../services/authorizationClient'
import tokenServices from '../../../services/tokenServices'
import { default as appConfig } from '../../../config/app.json'

import useQuery from '../../../hooks/UseQuery/useQuery'
import showToastResponseError from '../../../methods/showToastResponseError'
import commonRegExes from '../../../common/regExp/commonRegExes'

import KozmikLogoLight from '../../../assets/icons/KozmikLogoLight.png'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		padding: 27
	},
	contentContainer: {
		marginTop: '20vh',
		maxWidth: 374,
		width: 374,
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	subTitle: {
		marginBottom: 30,
		textAlign: 'center',
		fontSize: 14,
		fontWeight: '600',
		color: '#ffffff'
	},
	inputContainer: {
		marginBottom: 20
	},
	buttonContainer: {
		margin: '10px 0'
	},
	logoBox: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 80
	},
	logo: {
		width: 163,
		height: 'auto'
	},
	input: {
		'& .MuiOutlinedInput-root': {
			overflow: 'hidden',
			height: 50,
			backgroundColor: 'rgba(255, 255, 255, 0.6)',
			borderRadius: 8,
			fontSize: 14,
			fontWeight: 600
		}
	},
	button: {
		borderRadius: 8,
		boxShadow: 'none !important',
		textTransform: 'unset',
		height: 50,
		backgroundColor: `${theme.custom.colors.kozmikGreen} !important`
	},
	submitText: {
		fontSize: 16,
		fontWeight: 600,
		color: theme.custom.colors.kozmikDarkBlue
	},
	loader: {
		margin: '20px',
		color: theme.custom.colors.kozmikDarkBlue
	}
}))

const ResetPassword = () => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [submitting, setSubmitting] = useState(false)

	const cookies = new Cookies()
	const history = useHistory()
	const navigate = (url) => {
		history.push(url)
	} //let navigate = useNavigate()
	let queryParams = useQuery()
	const token = cookies.get(appConfig.cookieKeys.MY_SECURE_TOKEN_KEY)

	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
		getValues
	} = useForm({
		defaultValues: {
			password: '',
			confirmPassword: ''
		}
	})
	const onSubmit = (data) => {
		const dataWithToken = {
			token: queryParams.token,
			...data
		}
		setSubmitting(true)

		authorizationClient
			.resetpassword(dataWithToken)
			.then((response) => {
				if (response?.data?.success) {
					tokenServices.add(response.data.data)
					navigate('')
				} else {
					showToastResponseError(response)
				}
				setSubmitting(false)
			})
			.catch(() => {
				setSubmitting(false)
			})
	}

	const handleUserKeyPress = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			handleSubmit(onSubmit)()
		}
	}

	useEffect(() => {
		if (token && token !== 'undefined') {
			navigate('')
		}
	}, [])

	return (
		<div className={classes.mainContainer}>
			<div className={classes.contentContainer}>
				<div style={{ marginBottom: 60 }}>
					<div>
						<div className={classes.logoBox}>
							<a href='https://kozmik.io'>
								<CardMedia
									image={KozmikLogoLight}
									component='img'
									className={classes.logo}
								/>
							</a>
						</div>
						<h2 className={classes.subTitle}>
							{t('authScreens.resetPassword.subTitle')}
						</h2>
					</div>
					<div className={classes.inputContainer}>
						<Controller
							control={control}
							name='password'
							rules={{
								required: {
									value: true,
									message: t(
										'authScreens.resetPassword.passwordInput.requredError'
									)
								},
								pattern: {
									value: commonRegExes.password,
									message: t(
										'authScreens.resetPassword.passwordInput.patternError'
									)
								}
							}}
							render={({ field: { onChange, value } }) => {
								return (
									<TextField
										className={classes.input}
										error={!!errors?.password}
										helperText={errors?.password?.message || ''}
										placeholder={t(
											'authScreens.resetPassword.passwordInput.placeholder'
										)}
										fullWidth
										value={value}
										type='password'
										onChange={(e) => onChange(e.target.value)}
										onKeyPress={handleUserKeyPress}
									/>
								)
							}}
						/>
					</div>
					<div className={classes.inputContainer}>
						<Controller
							control={control}
							name='confirmPassword'
							rules={{
								required: {
									value: true,
									message: t(
										'authScreens.resetPassword.confirmPassInput.requredError'
									)
								},
								pattern: {
									value: commonRegExes.password,
									message: t(
										'authScreens.resetPassword.confirmPassInput.patternError'
									)
								},
								validate: (value) =>
									value === getValues('password') ||
									t(
										'authScreens.resetPassword.confirmPassInput.validationError'
									)
							}}
							render={({ field: { onChange, value } }) => {
								return (
									<TextField
										className={classes.input}
										error={!!errors?.confirmPassword}
										helperText={errors?.confirmPassword?.message || ''}
										placeholder={t(
											'authScreens.resetPassword.confirmPassInput.placeholder'
										)}
										fullWidth
										value={value}
										type='password'
										onChange={(e) => onChange(e.target.value)}
										onKeyPress={handleUserKeyPress}
									/>
								)
							}}
						/>
					</div>
				</div>

				<div className={classes.buttonContainer}>
					<Button
						fullWidth
						variant='contained'
						className={classes.button}
						onClick={handleSubmit(onSubmit)}
						disabled={submitting}
						sx={{
							opacity: submitting ? 0.6 : 1
						}}
					>
						<Typography className={classes.submitText}>
							{t('authScreens.resetPassword.acceptButton')}
						</Typography>
						{submitting && (
							<CircularProgress size={20} className={classes.loader} />
						)}
					</Button>
				</div>
				<div className={classes.buttonContainer}>
					<ColoredButton
						onClick={() => navigate('')}
						variant='text'
						textColor={'#00EF91'}
						bgColor='transparent'
						size='large'
						fullWidth
						style={{
							textTransform: 'unset',
							fontSize: 16,
							fontWeight: 600
						}}
					>
						{t('authScreens.resetPassword.cancelButton')}
					</ColoredButton>
				</div>
			</div>
		</div>
	)
}

export default ResetPassword

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import makeStyles from '@mui/styles/makeStyles'
import Cookies from 'universal-cookie'
import { /*useNavigate*/ useHistory } from 'react-router-dom'
import {
	CardMedia,
	TextField,
	Button,
	CircularProgress,
	Typography
} from '@mui/material'

import authorizationClient from '../../../services/authorizationClient'
import tokenServices from '../../../services/tokenServices'
import { default as appConfig } from '../../../config/app.json'

import useQuery from '../../../hooks/UseQuery/useQuery'
import showToastResponseError from '../../../methods/showToastResponseError'
import KozmikLogoLight from '../../../assets/icons/KozmikLogoLight.png'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		padding: 27
	},
	contentContainer: {
		marginTop: '20vh',
		maxWidth: 374,
		width: 374,
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	inputContainer: {
		marginBottom: 20
	},
	forgotContainer: {
		textAlign: 'right'
	},
	forgotText: {
		fontWeight: '600',
		fontSize: 14,
		cursor: 'pointer',
		color: theme.custom.colors.kozmikGreen,
		paddingLeft: 10
	},
	buttonContainer: {
		marginTop: 40,
		marginBottom: 20
	},
	bottomText: {
		textAlign: 'center',
		fontSize: 14,
		alignContent: 'center',
		color: '#ffffff'
	},
	greenLink: {
		color: theme.custom.colors.kozmikGreen,
		cursor: 'pointer',
		fontWeight: 'bold'
	},
	logoBox: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 80
	},
	logo: {
		width: 163,
		height: 'auto'
	},
	input: {
		'& .MuiOutlinedInput-root': {
			overflow: 'hidden',
			height: 50,
			backgroundColor: 'rgba(255, 255, 255, 0.6)',
			borderRadius: 8,
			fontSize: 14,
			fontWeight: 600
		}
	},
	button: {
		borderRadius: 8,
		boxShadow: 'none !important',
		textTransform: 'unset',
		height: 50,
		backgroundColor: `${theme.custom.colors.kozmikGreen} !important`
	},
	submitText: {
		fontSize: 16,
		fontWeight: 600,
		color: theme.custom.colors.kozmikDarkBlue
	},
	loader: {
		margin: '20px',
		color: theme.custom.colors.kozmikDarkBlue
	}
}))

const Login = () => {
	const { t } = useTranslation()
	const classes = useStyles()
	let queryParams = useQuery()

	const [submitting, setSubmitting] = useState(false)

	const cookies = new Cookies()
	const history = useHistory()
	const navigate = (url) => {
		history.push(url)
	} //let navigate = useNavigate()
	const token = cookies.get(appConfig.cookieKeys.MY_SECURE_TOKEN_KEY)

	const {
		control,
		handleSubmit,
		formState: { errors, isValid }
	} = useForm({
		defaultValues: {
			email: '',
			password: ''
		}
	})
	const onSubmit = (data) => {
		setSubmitting(true)
		authorizationClient
			.login(data)
			.then((response) => {
				if (response?.data?.success) {
					tokenServices.add(response.data.data)
					if (queryParams?.import) {
						navigate(`/import/${queryParams.import}`)
					} else {
						navigate('')
					}
				} else {
					showToastResponseError(response)
				}
				setSubmitting(false)
			})
			.catch(() => {
				setSubmitting(false)
			})
	}

	const handleUserKeyPress = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			handleSubmit(onSubmit)()
		}
	}

	useEffect(() => {
		if (token && token !== 'undefined') {
			if (queryParams?.import) {
				navigate(`/import/${queryParams.import}`)
			} else {
				navigate('')
			}
		}
	}, [])

	return (
		<div className={classes.mainContainer}>
			<div className={classes.contentContainer}>
				<div className={classes.logoBox}>
					<a href='https://kozmik.io'>
						<CardMedia
							image={KozmikLogoLight}
							component='img'
							className={classes.logo}
						/>
					</a>
				</div>
				<div className={classes.inputContainer}>
					<Controller
						control={control}
						name='email'
						rules={{
							required: {
								value: true,
								message: t('authScreens.login.mailInput.requredError')
							},
							pattern: {
								value:
									/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: t('authScreens.login.mailInput.patternError')
							}
						}}
						render={({ field: { onChange, value } }) => {
							return (
								<TextField
									className={classes.input}
									error={!!errors?.email}
									helperText={errors?.email ? errors?.email?.message : ''}
									placeholder={t('authScreens.login.mailInput.placeholder')}
									fullWidth
									value={value}
									onChange={(e) => onChange(e.target.value)}
									onKeyPress={handleUserKeyPress}
								/>
							)
						}}
					/>
				</div>
				<div className={classes.inputContainer}>
					<Controller
						control={control}
						name='password'
						rules={{
							required: {
								value: true,
								message: t('authScreens.login.passwordInput.requredError')
							}
						}}
						render={({ field: { onChange, value } }) => {
							return (
								<TextField
									className={classes.input}
									error={!!errors?.password}
									helperText={errors?.password ? errors?.password?.message : ''}
									placeholder={t('authScreens.login.passwordInput.placeholder')}
									fullWidth
									value={value}
									type='password'
									onChange={(e) => onChange(e.target.value)}
									onKeyPress={handleUserKeyPress}
								/>
							)
						}}
					/>
				</div>
				<div className={classes.forgotContainer}>
					<span
						onClick={() => navigate('/forgotpassword')}
						className={classes.forgotText}
					>
						{t('authScreens.login.forgotPassword')}
					</span>
				</div>

				<div className={classes.buttonContainer}>
					<Button
						fullWidth
						variant='contained'
						className={classes.button}
						onClick={handleSubmit(onSubmit)}
						disabled={submitting}
						sx={{
							opacity: submitting ? 0.6 : 1
						}}
					>
						<Typography className={classes.submitText}>
							{t('authScreens.login.loginButton')}
						</Typography>
						{submitting && (
							<CircularProgress size={20} className={classes.loader} />
						)}
					</Button>
				</div>
				<div className={classes.bottomText}>
					{t('authScreens.login.noAccount')}{' '}
					<a className={classes.greenLink} onClick={() => navigate('/signup')}>
						{t('authScreens.login.register')}
					</a>
				</div>
			</div>
		</div>
	)
}

export default Login

import React, { useEffect, useState } from 'react'

import makeStyles from '@mui/styles/makeStyles'
import { Grid, Box } from '@mui/material'

import cx from 'classnames'
import Cookies from 'universal-cookie'
import { useParams } from 'react-router-dom'

import { connect } from 'react-redux'
import { setConfig } from '../../../redux/actions/appDataActions'
import { setGhostUser } from '../../../redux/actions/userInfoActions'
import dataClient from '../../../services/dataClient'
import dataEntityClient from '../../../services/dataEntityClient'

import Preview from '../../organisms/preview/Preview'
import AppLeftFrame from '../../templates/appLeftFrame/AppLeftFrame'
import postMessageToPreview from '../../../methods/postMessageToPreview'
import showToastResponseError from '../../../methods/showToastResponseError'
import ViewAs from '../../organisms/viewAs/ViewAs'

const useStyles = makeStyles((theme) => ({
	rightContainer: {
		backgroundColor: '#ffffff',
		height: '100%',
		overflowY: 'auto',
		borderRadius: 16,
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.03)'
	},
	childrenContainer: {
		height: '100%'
	},
	previewContainer: {
		height: '100%',
		overflowY: 'auto'
	},
	absolutePreview: {
		[theme.breakpoints.down('md')]: {
			height: '100%',
			overflowY: 'auto'
		},
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
			marginLeft: 'auto',
			marginRight: '25%',
			left: 0,
			right: 0,
			height: '100%',
			overflowY: 'auto'
		}
	},
	preview: {
		[theme.breakpoints.down('md')]: {
			visibility: 'hidden',
			height: '0px !important',
			width: '0px !important',
			order: 2
		}
	},
	previewHidden: {
		visibility: 'hidden',
		height: '0px !important',
		order: 2
	},
	leftSectionPadding: {
		padding: '10px 0 10px 0',
		[theme.breakpoints.down('md')]: {
			padding: '10px 5px 10px 10px'
		},
		[theme.breakpoints.down('sm')]: {
			padding: '10px'
		}
	},
	rightSectionPadding: {
		padding: '10px 10px 10px 0',
		[theme.breakpoints.down('md')]: {
			padding: '10px 10px 10px 5px'
		},
		[theme.breakpoints.down('sm')]: {
			padding: '10px'
		}
	},
	mainContainer: {
		height: '100%',
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
			paddingBottom: 78 //DetailLeftFrame height
		}
	}
}))

const cookies = new Cookies()

const AppPreviewLayout = (props) => {
	let { appId } = useParams()
	const classes = useStyles()

	const [entities, setEntities] = useState([])

	// for Screens.js
	const [activeScreen, setActiveScreen] = useState(null)
	const [activeElement, setActiveElement] = useState(null)
	const [activeParentElement, setActiveParentElement] = useState(null)
	const [rightContentType, setRightContentType] = useState(null)

	// for AppDataTemplate.js
	const [activeTable, setActiveTable] = useState(null)

	const [isPreviewLoaded, setIsPreviewLoaded] = useState(false)
	const [isPreviewConfigPosted, setIsPreviewConfigPosted] = useState(false)
	const [activePreviewRoute, setActivePreviewRoute] = useState(null)
	const [users, setUsers] = useState([])

	const messageListener = (event) => {
		//TODO:dinlenen postmessage düzenlenmeli ve güvenlik konusuna bakılmalı

		if (event.data?.source === 'kozmik-preview') {
			event.data?.previewLoad && setIsPreviewLoaded(true)
			event.data?.configData && props.setConfig(event.data.configData)
			event.data?.activeRoute && setActivePreviewRoute(event.data.activeRoute)
		}
	}

	const triggerBuilderOnMobilePostMessage = (mobile) => {
		const postData = {
			source: 'kozmik-builder',
			builderOnMobile: mobile
		}

		postMessageToPreview(postData)
	}

	const triggerConfigPostMessage = (configData, ghostU) => {
		const postData = {
			source: 'kozmik-builder',
			updatedConfig: {
				objectName: 'appearence',
				value: configData
			}
		}

		postMessageToPreview(postData)
	}

	const triggerShortNamePostMessage = (shortName) => {
		const postData = {
			source: 'kozmik-builder',
			shortName: shortName
		}

		postMessageToPreview(postData)
	}

	const triggerAppIdPostMessage = (appId) => {
		const postData = {
			source: 'kozmik-builder',
			appId: appId
		}

		postMessageToPreview(postData)
	}

	const triggerOrgIdPostMessage = (orgId) => {
		const postData = {
			source: 'kozmik-builder',
			orgId: orgId
		}

		postMessageToPreview(postData)
	}

	const triggerUserPostMessage = () => {
		const postData = {
			source: 'kozmik-builder',
			user: props.userInfo
		}

		postMessageToPreview(postData)
	}

	const triggerGhostUserPostMessage = (ghostUser) => {
		const postData = {
			source: 'kozmik-builder',
			ghostUser: ghostUser
		}

		postMessageToPreview(postData)
	}

	const appDetailToPreviewConfig = (detailData) => {
		//NOTE:platform settings/appearance sayfasına gelen dataya uygun previewin loadı için
		const obj = {
			Theme: detailData.Theme,
			Logo: detailData.Logo,
			LogoPosition: detailData.LogoPosition,
			AccentColor: detailData.AccentColor,
			AccentContrast: detailData.AccentContrast,
			FontFamily: detailData.FontFamily
		}
		triggerConfigPostMessage(obj)
		triggerAppIdPostMessage(detailData.Id)
		//triggerShortNamePostMessage(detailData.ShortName)
	}

	const getEntities = () => {
		let params = {}

		dataEntityClient.entities(appId, params).then((response) => {
			if (response?.data?.success) {
				if (response.data?.data.length > 0) {
					setEntities(response.data.data)
				}
			} else {
				showToastResponseError(response)
			}
		})
	}

	useEffect(() => {
		window.addEventListener('message', messageListener)
		props.setPreviewButtonsVisible(true)
		return () => {
			props.setPreviewButtonsVisible(false)
			window.removeEventListener('message', messageListener)

			props.setConfig(null)
			props.setGhostUser(null)
		}
	}, [])

	useEffect(() => {
		if (!props.appDetail) return

		const parameters = {
			sort: 'Name asc',
			fields: 'Id,Name,Photo'
		}

		dataClient
			.searchData(
				props.appDetail.Id,
				props.appDetail.DataSource.UsersTableName,
				parameters
			)
			.then((response) => {
				if (response?.data) {
					setUsers(response.data.records)
				} else {
					setUsers([])
				}
			})
	}, [props.appDetail])

	useEffect(() => {
		if (isPreviewLoaded && props.appDetail && !isPreviewConfigPosted) {
			appDetailToPreviewConfig(props.appDetail)

			const orgId = cookies.get('orgId')
			triggerOrgIdPostMessage(orgId)
			triggerUserPostMessage()

			setIsPreviewConfigPosted(true)
		}
	}, [isPreviewLoaded, props.appDetail])

	useEffect(() => {
		isPreviewLoaded && triggerGhostUserPostMessage(props.ghostUser)
	}, [isPreviewLoaded, props.ghostUser])

	useEffect(() => {
		isPreviewLoaded && triggerBuilderOnMobilePostMessage(props.builderOnMobile)
	}, [isPreviewLoaded, props.builderOnMobile])

	useEffect(() => {
		props.data || props.integrations
			? props.setPreviewButtonsVisible(false)
			: props.setPreviewButtonsVisible(true)
	}, [props.data, props.integrations])

	useEffect(() => {
		getEntities()
	}, [])

	return (
		<Grid item container xs={12} className={classes.mainContainer}>
			{props.settings && (
				<Grid
					item
					xs={12}
					sm={6}
					md={2}
					className={classes.leftSectionPadding}
					style={{
						height: '100%'
					}}
				>
					<Box
						style={{
							backgroundColor: '#ffffff',
							height: '100%',
							overflowY: 'auto',
							borderRadius: 16,
							boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.03)'
						}}
					>
						{props.auth && <AppLeftFrame />}
						{props?.left && !props.settings && props.left}
					</Box>
				</Grid>
			)}

			<Grid
				item
				xs={12}
				sm={0}
				md={7}
				className={cx(
					props.settings ? classes.previewContainer : classes.absolutePreview,
					props.data || props.integrations
						? classes.previewHidden
						: classes.preview
				)}
			>
				{props.auth && (
					<Preview
						users={users}
						previewButtonIndex={props.previewButtonIndex}
						shortName={props.appDetail ? props.appDetail.ShortName : null}
						activePreviewRoute={activePreviewRoute}
						splash={props?.splash}
						isFullPagePreview={props.isFullPagePreview}
						setIsFullPagePreview={props.setIsFullPagePreview}
					/>
				)}
			</Grid>

			{props.settings || props.screens ? (
				<>
					<Grid
						item
						xs={12}
						sm={props.settings ? 6 : 12}
						md={props.settings ? 3 : 12}
						className={props.settings ? classes.rightSectionPadding : null}
						style={{
							height: '100%'
						}}
					>
						<Box
							className={
								props.settings
									? classes.rightContainer
									: classes.childrenContainer
							}
						>
							{props?.children &&
								React.cloneElement(props.children, {
									setActivePreviewRoute: setActivePreviewRoute,
									activePreviewRoute: activePreviewRoute,
									entities: entities,
									setEntities: setEntities,
									activeScreen: activeScreen,
									setActiveScreen: setActiveScreen,
									activeElement: activeElement,
									setActiveElement: setActiveElement,
									activeParentElement: activeParentElement,
									setActiveParentElement: setActiveParentElement,
									rightContentType: rightContentType,
									setRightContentType: setRightContentType,
									builderOnMobile: props.builderOnMobile
								})}
						</Box>
					</Grid>
					{props.previewButtonIndex != 2 && <ViewAs users={users} />}
				</>
			) : (
				props?.children &&
				React.cloneElement(props.children, {
					tables: entities,
					setTables: setEntities,
					activeTable: activeTable,
					setActiveTable: setActiveTable,
					builderOnMobile: props.builderOnMobile
				})
			)}
		</Grid>
	)
}

const mapStateToProps = (state) => ({
	...state.auth,
	...state.appData,
	...state.userInfo,
	...state.ghostUser
})

const mapDispatchToProps = (dispatch) => {
	return {
		setConfig: (x) => dispatch(setConfig(x)),
		setGhostUser: (x) => dispatch(setGhostUser(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AppPreviewLayout)

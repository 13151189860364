import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import AppIntegrationsTemplate from '../../templates/appIntegrationsTemplate/AppIntegrationsTemplate'

const AppIntegrations = (props) => {
	const { t } = useTranslation()

	const { tables, setTables, activeTable, setActiveTable, ...restProps } = props

	useEffect(() => {
		document.title = t('docTitles.integrations')
	}, [])

	return <AppIntegrationsTemplate tables={tables} />
}

export default AppIntegrations

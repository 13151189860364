import { combineReducers } from 'redux'

import authReducer from './authReducer'
import onboardingReducer from './onboardingReducer'
import userInfoReducer from './userInfoReducer'
import appDataReducer from './appDataReducer'
import applicationsReducer from './applicationsReducer'
import usageReducer from './usageReducer'

const rootReducer = combineReducers({
	auth: authReducer,
	onboarding: onboardingReducer,
	userInfo: userInfoReducer,
	appData: appDataReducer,
	applications: applicationsReducer,
	usage: usageReducer
})

export default rootReducer

import replaceTrToEng from '../replaceTrToEng'
import i18next from '../../i18n/i18n'
import builtInScreenPaths from '../../common/builtInScreenPaths/builtInScreenPaths'
import commonRegExes from '../../common/regExp/commonRegExes'

const generateScreenPathByName = (
	allScreens,
	screenKey,
	name,
	idPath = false
) => {
	//NOTE:Generate screen path with validation
	let generatedPath = replaceTrToEng(name).toLowerCase().replace(/ /g, '-')
	if (idPath) {
		generatedPath += '/:id'
	}
	let result = { generatedPath: generatedPath, isValid: true, message: '' }
	const nameNotEmpty = /\S/.test(name) //not empty & not only white spaces

	if (nameNotEmpty) {
		if (allScreens) {
			for (let i = 0; i < allScreens.length; i++) {
				const screen = allScreens[i]

				if (builtInScreenPaths.includes(generatedPath)) {
					result.isValid = false
					result.message = i18next.t('screens.validation.existBuiltIn')
					break
				} else if (
					screenKey &&
					screen.key !== screenKey &&
					screen.path === generatedPath
				) {
					result.isValid = false
					result.message = i18next.t('screens.validation.exist')
					break
				}
				// else if (commonRegExes.alphanumeric.test(generatedPath)) {
				// 	result.isValid = false
				// 	result.message = i18next.t('screens.validation.special')
				// break
				// }
			}
		}
	} else {
		result.isValid = false
		result.message = i18next.t('screens.validation.empty')
	}

	return result
}

export default generateScreenPathByName

import i18n from '../i18n/i18n'
import { toast } from 'react-toastify'

const showToastCatchError = (error) => {
	if (error?.response?.data?.validations) {
		var messagesArr = error?.response?.data?.validations.map(function (el) {
			return el.message
		})
		var messages = messagesArr.toString()

		toast.error(messages || i18n.t('api.error'))
	} else if (error?.response?.status === 429) {
		toast.error(i18n.t('api.rateLimiting'))
	} else {
		toast.error(
			error?.response?.data?.message ||
				error?.response?.data?.errorDetails ||
				i18n.t('api.error')
		)
	}
}

export default showToastCatchError

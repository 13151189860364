import { v4 as uuidv4 } from 'uuid'

const method = () => {
	let object = {
		_uid: uuidv4(),
		component: 'container',
		mapping: {
			backgroundImageUrl: {
				field: null,
				default: null,
				type: null
			}
		},
		body: [],
		background: 'none',
		backgroundColor: null,
		size: 'lg',
		spacing: 'sm'
	}

	return object
}

export default method

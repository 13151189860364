import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import { connect } from 'react-redux'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { SwapVert, FilterAltOutlined, PlusOne } from '@mui/icons-material'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import Accordion from '../../../atoms/accordion/Accordion'
import NumericInput from '../../../atoms/numericInput/NumericInput'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import KozmikTextField from '../../../atoms/kozmikTextField/KozmikTextField'
import { default as Slider } from '../../../atoms/kozmikSlider/KozmikSlider'
import AccordionItem from '../../../atoms/accordionItem/AccordionItem'
import FilterList from '../../../molecules/filterList/FilterList'
import Sorting from '../../../molecules/sorting/Sorting'
import ActionList from '../../actionList/ActionList'
import SearchOptions from '../../../molecules/searchOptions/SearchOptions'
import EmptyOptions from '../../../molecules/emptyOptions/EmptyOptions'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import relationTypes from '../../../../common/enums/relationTypes'

import defaultElementSchemas from '../../../../defaultElementSchemas'

import MoreOptions from '../../../molecules/moreOptions/MoreOptions'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	inputLight: {
		borderRadius: 3,
		backgroundColor: '#ffffff',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const Chat = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [elementName, setElementName] = useState(null)

	/****SOURCE***************************/
	const [source, setSource] = useState('')

	const emailFields = getEntityFieldsByDataTypes(props?.entities, source, [
		'Email',
		'Text',
		'LongText'
	])
	const timeFields = getEntityFieldsByDataTypes(props?.entities, source, [
		'Date'
	])
	const messageFields = getEntityFieldsByDataTypes(props?.entities, source, [
		'LongText'
	])
	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		fieldDataTypes.textBased,
		null,
		true,
		relationTypes.one
	)
	const imageFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		['Image'],
		null,
		true,
		relationTypes.one
	)

	const sourceChange = (e) => {
		submitSource(source, e.target.value)
		setSource(e.target.value)
	}

	const submitSource = (oldTableName, tableName) => {
		const table = props?.entities?.find((y) => y.name == tableName)

		const newElementData = defaultElementSchemas.chat(
			table,
			props.appDetail?.DataSource?.UsersTableName
		)

		let modifiedElementData = update(props.elementData, {
			source: { $set: newElementData.source },
			mapping: {
				id: {
					field: { $set: newElementData.mapping?.id?.field },
					type: { $set: newElementData.mapping?.id?.type }
				},
				userEmail: {
					field: { $set: newElementData.mapping?.userEmail?.field },
					type: { $set: newElementData.mapping?.userEmail?.type }
				},
				userName: {
					field: { $set: newElementData.mapping?.userName?.field },
					type: { $set: newElementData.mapping?.userName?.type }
				},
				userPhoto: {
					field: { $set: newElementData.mapping?.userPhoto?.field },
					type: { $set: newElementData.mapping?.userPhoto?.type }
				},
				message: {
					field: { $set: newElementData.mapping?.message?.field },
					type: { $set: newElementData.mapping?.message?.type }
				},
				time: {
					field: { $set: newElementData.mapping?.time?.field },
					type: { $set: newElementData.mapping?.time?.type }
				}
			},
			sorting: {
				field: { $set: newElementData.sorting?.field },
				sort: { $set: newElementData.sorting?.sort }
			},
			filters: {
				groupOp: { $set: newElementData.filters?.groupOp },
				rules: { $set: newElementData.filters?.rules }
			},
			actions: { $set: newElementData.actions }
		})

		props.updateBulkScreensWithModifiedData(
			table.name,
			null,
			modifiedElementData
		)
	}

	/****USEREMAIL***************************/
	const [userEmail, setUserEmail] = useState('')
	const userEmailChange = (e) => {
		submitUserEmail(e.target.value)
		setUserEmail(e.target.value)
	}
	const submitUserEmail = (x) => {
		const selectedField = emailFields?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				userEmail: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****USERNAME***************************/
	const [userName, setUserName] = useState('')
	const userNameChange = (e) => {
		submitUserName(e.target.value)
		setUserName(e.target.value)
	}
	const submitUserName = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				userName: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****USERPHOTO***************************/
	const [userPhoto, setUserPhoto] = useState('')
	const userPhotoChange = (e) => {
		submitUserPhoto(e.target.value)
		setUserPhoto(e.target.value)
	}
	const submitUserPhoto = (x) => {
		const selectedField = imageFields?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				userPhoto: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****MESSAGE***************************/
	const [message, setMessage] = useState('')

	const messageChange = (e) => {
		submitMessage(e.target.value)
		setMessage(e.target.value)
	}

	const submitMessage = (x) => {
		const selectedField = messageFields?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				message: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****TIME***************************/
	const [time, setTime] = useState('')

	const timeChange = (e) => {
		submitTime(e.target.value)
		setTime(e.target.value)
	}

	const submitTime = (x) => {
		const selectedField = messageFields?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				time: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ACTIONS***************************/
	const updateActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			actions: { $set: newActions }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****LIMIT***************************/
	const [limit, setLimit] = useState(25)
	const limitChange = (val) => {
		timeoutDelay(submitLimit, val, 1000)
		setLimit(val)
	}
	const submitLimit = (x) => {
		const modifiedElementData = update(props.elementData, {
			limit: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****FILTER_&_SORT***************************/
	const [localElementData, setLocalElementData] = useState(null)

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		setSource(elementData?.source || '')
		setUserEmail(elementData?.mapping?.userEmail?.field || '')
		setUserName(elementData?.mapping?.userName?.field || '')
		setUserPhoto(elementData?.mapping?.userPhoto?.field || '')
		setMessage(elementData?.mapping?.message?.field || '')
		setTime(elementData?.mapping?.time?.field || '')
		setLimit(elementData?.limit || 10)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
			setLocalElementData(props.elementData)
		}
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.source')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={12}>
							<SelectBox
								className={classes.input}
								onChange={sourceChange}
								value={source}
								data={props.entities}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>

					<div className={classes.mb10}>
						<AccordionItem
							icon={FilterAltOutlined}
							title={t('screens.elementSettings.chat.filter')}
							expanded={false}
							padding={8}
						>
							<FilterList
								filter={localElementData?.filters}
								table={props?.entities?.find(
									(y) => y.name == props?.elementData?.source
								)}
								activeScreen={props?.activeScreen}
								entities={props?.entities}
								tableName={props?.elementData?.source}
								screenTable={props?.entities?.find(
									(y) => y.name == props.activeScreen?.data?.source
								)}
								filtering={props.filtering}
								setLocalElementData={setLocalElementData}
							/>
						</AccordionItem>
					</div>

					<div className={classes.mb10}>
						<AccordionItem
							icon={SwapVert}
							title={t('screens.elementSettings.chat.sort')}
							expanded={false}
							padding={8}
						>
							<Sorting
								sortingData={localElementData?.sorting}
								sorting={props.sorting}
								fieldOptions={timeFields}
							/>
						</AccordionItem>
					</div>

					<div className={classes.mb10}>
						<AccordionItem
							icon={PlusOne}
							title={t('screens.elementSettings.chat.limit')}
							expanded={false}
							padding={8}
						>
							<NumericInput minVal={0} value={limit} onChange={limitChange} />
						</AccordionItem>
					</div>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.chat.userEmail')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.chat.emptyField')}
								className={classes.input}
								onChange={userEmailChange}
								value={userEmail}
								data={emailFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.chat.userName')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.chat.emptyField')}
								className={classes.input}
								onChange={userNameChange}
								value={userName}
								data={textFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.chat.userPhoto')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.chat.emptyField')}
								className={classes.input}
								onChange={userPhotoChange}
								value={userPhoto}
								data={imageFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.chat.message')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.chat.emptyField')}
								className={classes.input}
								onChange={messageChange}
								value={message}
								data={messageFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.chat.time')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.chat.emptyField')}
								className={classes.input}
								onChange={timeChange}
								value={time}
								data={timeFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			{/* <AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.empty')}
					expanded={true}
				>
					<EmptyOptions
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox> */}

			{/* <AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.more')}
					expanded={true}
				>
					<MoreOptions
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox> */}

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.search')}
					expanded={true}
				>
					<SearchOptions
						source={source}
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.actions')}
					expanded={true}
				>
					{source && (
						<ActionList
							name={elementName}
							entities={props.entities}
							activeScreen={props.activeScreen}
							actions={props.elementData.actions}
							updateActions={updateActions}
							isList={true}
							listSource={source}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

const mapStateToProps = (state) => ({
	...state.appData
})

export default connect(mapStateToProps, null)(Chat)

import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Lock, Info, Brush } from '@mui/icons-material'

import Label from '../../atoms/label/Label'
import AppMenu from '../../molecules/appMenu/AppMenu'

const useStyles = makeStyles(() => ({
	appLabelText: {
		color: '#000000',
		lineHeight: '22px',
		textAlign: 'left',
		fontWeight: 'bold'
	}
}))

const AppLeftFrame = () => {
	const { t } = useTranslation()
	const classes = useStyles()
	let { appId } = useParams()

	const menuList = [
		{
			icon: Info,
			name: t('appDetailMenu.menu.info'),
			href: `/app/${appId}/settings/info`,
			isActive: true
		},
		{
			icon: Brush,
			name: t('appDetailMenu.menu.theme'),
			href: `/app/${appId}/settings/theme`,
			isActive: false
		},
		{
			icon: Lock,
			name: t('appDetailMenu.menu.splash'),
			href: `/app/${appId}/settings/splash`,
			isActive: false
		}
	]
	return (
		<Grid container style={{ padding: 15 }}>
			<Grid item xs={12}>
				<Label
					text={t('appDetailMenu.title')}
					className={classes.appLabelText}
				/>
			</Grid>
			<Grid item xs={12}>
				<AppMenu menuItems={menuList} />
			</Grid>
		</Grid>
	)
}

export default AppLeftFrame

import { v4 as uuidv4 } from 'uuid'

// import i18next from '../../i18n/i18n'
import languages from '../../i18n/languages'

const method = (corner = 'circle', height = 48) => {
	let object = {
		_uid: uuidv4(),
		component: 'logoutButton',
		height: height,
		fontSize: 'sm',
		spacing: 'xl',
		corner: corner,
		text: {
			default: languages.en.schemas.logoutButton.text
		},
		icon: 'alert-circle',
		redirectUrl: '/'
	}

	return object
}

export default method

import { v4 as uuidv4 } from 'uuid'

// import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
// import i18next from '../../i18n/i18n'
import languages from '../../i18n/languages'
import getFirstFieldExcludingForbidArray from '../../methods/getFirstFieldExcludingForbidArray'
import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'

const method = (table) => {
	// const relationFields = getEntityFieldsByDataTypes(
	// 	null,
	// 	null,
	// 	['Relation'],
	// 	table
	// )
	const relationFields = table?.fields?.filter((x) => x.dataType == 'Relation')

	const firstRelationField = getFirstFieldExcludingForbidArray(
		relationFields,
		'name',
		systemFieldNames.notVisible
	)

	const object = {
		_uid: uuidv4(),
		component: 'choice',
		type: 'dropdown', // dropdown,chips,radio,checkbox
		relation: {
			type: firstRelationField ? firstRelationField.relationType : null,
			source: firstRelationField ? firstRelationField.relatedEntityName : null,
			field: firstRelationField ? firstRelationField.displayFieldName : null
		},
		mapping: {
			value: {
				field: firstRelationField?.name,
				type: firstRelationField?.dataType
			},
			title: {
				field: null,
				default: firstRelationField ? firstRelationField?.name : '',
				type: null
			}
		},
		sorting: {
			field: firstRelationField ? firstRelationField.displayFieldName : null,
			sort: 'asc'
		},
		filters: {
			groupOp: 'OR',
			rules: []
		},
		submit: {
			text: {
				default: languages.en.schemas.choice.submit
			}
		},
		cancel: {
			text: {
				default: languages.en.schemas.choice.cancel
			}
		},
		placeholder: {
			default: languages.en.schemas.choice.placeholder
		},
		emptyText: {
			default: languages.en.schemas.choice.empty
		},
		spacing: 'xl',
		limit: 1000
	}

	return object
}

export default method

import React from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
	label: {
		fontSize: 14,
		color: theme.custom.colors.textPrimary
	}
}))

const SettingLabel = (props) => {
	const classes = useStyles()
	return (
		<Typography className={classes.label} {...props}>
			{props.children}
		</Typography>
	)
}

export default SettingLabel

import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from '@mui/material'
import cx from 'classnames'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
		alignItems: 'start'
	},
	itemContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	selectBox: {
		height: 37,
		padding: '0 10px',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.custom.colors.kozmikGray
	},
	selectActive: {
		border: `1px solid ${theme.custom.colors.kozmikBlue}`,
		borderRadius: '4px',
		textAlign: 'center'
	},
	selectPassive: {
		borderRadius: '4px',
		textAlign: 'center'
	},
	signText: {
		textAlign: 'center',
		color: theme.custom.colors.textPrimary,
		fontSize: '12px'
	}
}))

const ButtonOptions = (props) => {
	const classes = useStyles({ padding: props.padding })

	const [selectedIndex, setSelectedIndex] = useState()

	const setSelectedIndexByActiveValue = (activeValue) => {
		const activeData = props?.data?.find((x) => x.value == activeValue)
		const activeIndex = props?.data?.indexOf(activeData)

		activeIndex >= 0 && setSelectedIndex(activeIndex)
	}

	useEffect(() => {
		if (props?.activeValue || typeof props.activeValue == 'boolean') {
			setSelectedIndexByActiveValue(props.activeValue)
		}
	}, [props.activeValue])

	return (
		<div className={classes.mainContainer}>
			{!!props?.data?.length &&
				props.data.map((item, index) => (
					<div
						key={index}
						className={classes.itemContainer}
						style={{
							paddingLeft: index > 0 ? (props?.padding ? props.padding : 10) : 0
						}}
					>
						<div
							className={cx(
								classes.selectBox,
								selectedIndex === index
									? classes.selectActive
									: classes.selectPassive
							)}
							style={
								props?.boxColor ? { backgroundColor: props.boxColor } : null
							}
							onClick={() => {
								setSelectedIndex(index)
								props.getChange(item.value)
							}}
						>
							<Typography className={classes.signText}>{item.text}</Typography>
						</div>
					</div>
				))}
		</div>
	)
}
export default ButtonOptions

import { SETONBOARDING } from '../action-types/onboardingActionTypes'

const initialState = {
	onboarding: false
}

const onboardingReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case SETONBOARDING:
			return { ...state, onboarding: action.payload }

		default:
			return state
	}
}

export default onboardingReducer

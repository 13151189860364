import { v4 as uuidv4 } from 'uuid'

import languages from '../../i18n/languages'

const method = (fieldData) => {
	let object = {
		_uid: uuidv4(),
		component: 'inputPhone',
		mapping: {
			field: fieldData.name,
			type: fieldData.dataType
		},
		title: fieldData.label,
		placeholder: {
			default: languages.en.schemas.inputPhone.placeholder
		},
		required: {
			status: fieldData?.isRequired || false,
			text: {
				default: languages.en.schemas.inputPhone.required
			}
		},
		errorText: {
			default: languages.en.schemas.inputPhone.error
		},
		spacing: 'xl',
		scanner: false
	}

	return object
}

export default method

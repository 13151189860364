import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateMentions from '../../../molecules/doubleStateField/DoubleStateMentions'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import relationTypes from '../../../../common/enums/relationTypes'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const NoteBox = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const propsRef = useRef()
	propsRef.current = props

	const [elementName, setElementName] = useState(null)

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		fieldDataTypes.textBased
	) //TODO:NoteBox note dataType kısıtlanmalı
	const textFieldsWithLookup = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		fieldDataTypes.textBased,
		null,
		true,
		relationTypes.one
	)
	const textOptions = textFieldsWithLookup?.length ? textFieldsWithLookup : []
	const textOptionsWithEmpty = [{ label: '-', name: '' }, ...textOptions]

	/****NOTE***************************/
	const [note, setNote] = useState('')

	const noteChange = (e) => {
		submitNote(e.target.value)
		setNote(e.target.value)
	}

	const submitNote = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				note: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****TITLE***************************/
	const [isTitleTextMode, setIsTitleTextMode] = useState(false)
	const [titleField, setTitleField] = useState('')
	const [titleDefault, setTitleDefault] = useState('')

	const titleChange = (data) => {
		if (isTitleTextMode) {
			submitTitle(data)
			setTitleDefault(data)
		} else {
			submitTitle(data?.value)
			setTitleField(data?.value)
		}
	}
	const submitTitle = (x) => {
		const selectedField = textFieldsWithLookup?.find((y) => y.name == x)
		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				title: {
					field: { $set: isTitleTextMode ? null : x },
					default: { $set: isTitleTextMode ? x : null },
					type: { $set: isTitleTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****PLACEHOLDER***************************/
	const [isPlaceholderTextMode, setIsPlaceholderTextMode] = useState(false)
	const [placeholderField, setPlaceholderField] = useState('')
	const [placeholderDefault, setPlaceholderDefault] = useState('')

	const placeholderChange = (data) => {
		if (isPlaceholderTextMode) {
			submitPlaceholder(data)
			setPlaceholderDefault(data)
		} else {
			submitPlaceholder(data?.value)
			setPlaceholderField(data?.value)
		}
	}
	const submitPlaceholder = (x) => {
		const selectedField = textFieldsWithLookup?.find((y) => y.name == x)
		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				placeholder: {
					field: { $set: isPlaceholderTextMode ? null : x },
					default: { $set: isPlaceholderTextMode ? x : null },
					type: { $set: isPlaceholderTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****NOTE***************************/
		setNote(
			elementData?.mapping?.note?.field ? elementData.mapping.note.field : ''
		)

		/****TITLE***************************/
		elementData?.mapping?.title?.field ||
		elementData?.mapping?.title?.field === ''
			? setIsTitleTextMode(false)
			: setIsTitleTextMode(true)

		setTitleField(elementData?.mapping?.title?.field || '')
		setTitleDefault(elementData?.mapping?.title?.default || '')

		/****PLACEHOLDER***************************/
		elementData?.mapping?.placeholder?.field ||
		elementData?.mapping?.placeholder?.field === ''
			? setIsPlaceholderTextMode(false)
			: setIsPlaceholderTextMode(true)

		setPlaceholderField(elementData?.mapping?.placeholder?.field || '')
		setPlaceholderDefault(elementData?.mapping?.placeholder?.default || '')

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		props?.elementData && initStates(props.elementData)
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.noteBox.column')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.noteBox.emptyField')}
								className={cx(classes.input, classes.inputSize)}
								onChange={noteChange}
								value={note}
								data={textFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.noteBox.title')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateMentions
								name={`${elementName}-title`}
								multiline={false}
								fullWidth={true}
								isTextMode={isTitleTextMode}
								setIsTextMode={setIsTitleTextMode}
								valChange={titleChange}
								textVal={titleDefault}
								autoCompVal={titleField}
								autoCompOptions={textOptionsWithEmpty}
								autoCompEmptyText={t(
									'screens.elementSettings.noteBox.emptyField'
								)}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.noteBox.placeholder')}</Label>
						</Grid>
						<Grid item xs={8}>
							<DoubleStateMentions
								name={`${elementName}-placeholder`}
								multiline={false}
								fullWidth={true}
								isTextMode={isPlaceholderTextMode}
								setIsTextMode={setIsPlaceholderTextMode}
								valChange={placeholderChange}
								textVal={placeholderDefault}
								autoCompVal={placeholderField}
								autoCompOptions={textOptionsWithEmpty}
								autoCompEmptyText={t(
									'screens.elementSettings.noteBox.emptyField'
								)}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default NoteBox

import React, { useEffect, useState } from 'react'
import { CardMedia, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import KozmikTextField from '../../../atoms/kozmikTextField/KozmikTextField'

import appClient from '../../../../services/appClient'
import { setDetail } from '../../../../redux/actions/appDataActions'

import timeoutDelay from '../../../../methods/timeoutDelay'
import showToastResponseError from '../../../../methods/showToastResponseError'
import postMessageToPreview from '../../../../methods/postMessageToPreview'

const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%',
		paddingTop: 20,
		paddingBottom: 20
	},
	label: {
		fontSize: 14,
		fontWeight: 600,
		marginBottom: 5
	}
}))

const GoogleMaps = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	let { appId } = useParams()

	const [googleApiKey, setGoogleApiKey] = useState('')

	const initialization = () => {
		if (props.appDetail?.GoogleAPIKey) {
			setGoogleApiKey(props.appDetail.GoogleAPIKey)
		}
	}

	const triggerPropPostMessage = (objectName, propName, value) => {
		const postData = {
			source: 'kozmik-builder',
			updatedProp: {
				objectName: objectName,
				propName: propName,
				value: value
			}
		}

		postMessageToPreview(postData)
	}

	const postData = (
		propName,
		propData,
		configobjectName = null,
		configPropName = null
	) => {
		if (appId) {
			let updateData = {
				Id: appId
			}
			updateData[propName] = propData

			appClient
				.update(updateData)
				.then((response) => {
					if (response.status == 200) {
						updateReduxDetailData(propName, propData)
						if (configobjectName && configPropName) {
							triggerPropPostMessage(configobjectName, configPropName, propData)
						}
					} else {
						showToastResponseError(response)
						initialization()
					}
				})
				.catch((error) => {
					initialization()
				})
		}
	}

	const updateReduxDetailData = (propName, propData) => {
		let tempData = { ...props.appDetail }
		tempData[propName] = propData
		props.setDetail(tempData)
	}

	/****GOOGLE_API_KEY***************************/
	const changeGoogleApiKey = (e) => {
		timeoutDelay(submitGoogleApiKey, e.target.value, 1000)
		setGoogleApiKey(e.target.value)
	}

	const submitGoogleApiKey = (gApiKey) => {
		postData('GoogleAPIKey', gApiKey, 'settings', 'google_api_key')
	}

	/****INITIALIZATION***************************/
	useEffect(() => {
		props.appDetail && initialization()
	}, [props.appDetail])

	return (
		<div className={classes.container}>
			<Typography className={classes.label}>
				{t('appIntegrationsTemp.details.googleMaps.apiKey')}
			</Typography>
			<KozmikTextField
				fullWidth
				placeholder={t(
					'appIntegrationsTemp.details.googleMaps.apiKeyPlaceholder'
				)}
				value={googleApiKey}
				onChange={changeGoogleApiKey}
			/>
		</div>
	)
}

const mapStateToProps = (state) => ({
	...state.appData
})

const mapDispatchToProps = (dispatch) => {
	return {
		setDetail: (x) => dispatch(setDetail(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleMaps)

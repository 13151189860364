import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import makeStyles from '@mui/styles/makeStyles'
import { /*useNavigate*/ useHistory } from 'react-router-dom'
import {
	CardMedia,
	TextField,
	Button,
	Typography,
	CircularProgress
} from '@mui/material'

import authorizationClient from '../../../services/authorizationClient'
import tokenServices from '../../../services/tokenServices'

import useQuery from '../../../hooks/UseQuery/useQuery'
import showToastResponseError from '../../../methods/showToastResponseError'
import commonRegExes from '../../../common/regExp/commonRegExes'

import KozmikLogoLight from '../../../assets/icons/KozmikLogoLight.png'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		padding: 27
	},
	contentContainer: {
		marginTop: '20vh',
		maxWidth: 374,
		width: 374,
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	inputContainer: {
		marginBottom: 20
	},
	buttonContainer: {
		marginTop: 20,
		marginBottom: 20
	},
	bottomText: {
		textAlign: 'center',
		fontSize: 14,
		alignContent: 'center',
		color: '#ffffff'
	},
	greenLink: {
		color: theme.custom.colors.kozmikGreen,
		cursor: 'pointer',
		fontWeight: 'bold'
	},
	logoBox: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 80
	},
	logo: {
		width: 163,
		height: 'auto'
	},
	input: {
		'& .MuiOutlinedInput-root': {
			overflow: 'hidden',
			height: 50,
			backgroundColor: 'rgba(255, 255, 255, 0.6)',
			borderRadius: 8,
			fontSize: 14,
			fontWeight: 600
		}
	},
	button: {
		borderRadius: 8,
		boxShadow: 'none !important',
		textTransform: 'unset',
		height: 50,
		backgroundColor: `${theme.custom.colors.kozmikGreen} !important`
	},
	submitText: {
		fontSize: 16,
		fontWeight: 600,
		color: theme.custom.colors.kozmikDarkBlue
	},
	loader: {
		margin: '20px',
		color: theme.custom.colors.kozmikDarkBlue
	}
}))

const InvitationRegister = () => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [submitting, setSubmitting] = useState(false)

	const history = useHistory()
	const navigate = (url) => {
		history.push(url)
	} //let navigate = useNavigate()
	let queryParams = useQuery()

	const {
		control,
		handleSubmit,
		formState: { errors, isValid }
	} = useForm({
		defaultValues: {
			name: '',
			password: ''
		}
	})
	const onSubmit = (data) => {
		const dataWithToken = {
			token: queryParams.token,
			orgToken: queryParams.orgToken,
			...data
		}
		setSubmitting(true)
		authorizationClient
			.invitation(dataWithToken)
			.then((response) => {
				if (response?.data?.success) {
					tokenServices.add(response.data.data)
					navigate('')
				} else {
					showToastResponseError(response)
				}
				setSubmitting(false)
			})
			.catch(() => {
				setSubmitting(true)
			})
	}

	const handleUserKeyPress = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			handleSubmit(onSubmit)()
		}
	}

	return (
		<div className={classes.mainContainer}>
			<div className={classes.contentContainer}>
				<div className={classes.logoBox}>
					<a href='https://kozmik.io'>
						<CardMedia
							image={KozmikLogoLight}
							component='img'
							className={classes.logo}
						/>
					</a>
				</div>
				<div className={classes.inputContainer}>
					<Controller
						control={control}
						name='name'
						rules={{
							required: {
								value: true,
								message: t(
									'authScreens.invitationRegister.nameInput.requredError'
								)
							}
						}}
						render={({ field: { onChange, value } }) => {
							return (
								<TextField
									className={classes.input}
									error={!!errors?.name}
									helperText={errors?.name ? errors?.name?.message : ''}
									placeholder={t(
										'authScreens.invitationRegister.nameInput.placeholder'
									)}
									fullWidth
									value={value}
									onChange={(e) => onChange(e.target.value)}
									onKeyPress={handleUserKeyPress}
									inputProps={{
										autoComplete: 'off'
									}}
								/>
							)
						}}
					/>
				</div>

				<div className={classes.inputContainer}>
					<Controller
						control={control}
						name='password'
						rules={{
							required: {
								value: true,
								message: t(
									'authScreens.invitationRegister.passwordInput.requredError'
								)
							},
							pattern: {
								value: commonRegExes.password,
								message: t(
									'authScreens.invitationRegister.passwordInput.patternError'
								)
							}
						}}
						render={({ field: { onChange, value } }) => {
							return (
								<TextField
									className={classes.input}
									error={!!errors?.password}
									helperText={errors?.password ? errors?.password?.message : ''}
									placeholder={t(
										'authScreens.invitationRegister.passwordInput.placeholder'
									)}
									fullWidth
									value={value}
									type='password'
									onChange={(e) => onChange(e.target.value)}
									onKeyPress={handleUserKeyPress}
									inputProps={{
										autoComplete: 'new-password'
									}}
								/>
							)
						}}
					/>
				</div>

				<div className={classes.buttonContainer}>
					<Button
						fullWidth
						variant='contained'
						className={classes.button}
						onClick={handleSubmit(onSubmit)}
						disabled={submitting}
						sx={{
							opacity: submitting ? 0.6 : 1
						}}
					>
						<Typography className={classes.submitText}>
							{t('authScreens.invitationRegister.signupButton')}
						</Typography>
						{submitting && (
							<CircularProgress size={20} className={classes.loader} />
						)}
					</Button>
				</div>
				<div className={classes.bottomText}>
					{t('authScreens.invitationRegister.haveAccount')}{' '}
					<span
						className={classes.greenLink}
						onClick={() => navigate('/login')}
					>
						{t('authScreens.invitationRegister.login')}
					</span>
				</div>
			</div>
		</div>
	)
}

export default InvitationRegister

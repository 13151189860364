import Cookies from 'universal-cookie'

import { default as appConfig } from '../config/app.json'

const add = (tokenData) => {
	const cookies = new Cookies()

	cookies.set(
		appConfig.cookieKeys.MY_SECURE_TOKEN_KEY,
		tokenData.access_token,
		{
			path: '/',
			maxAge: tokenData.expires_in,
			sameSite: 'none',
			secure: true,
			domain: process.env.REACT_APP_COOKIE_DOMAIN
			// httpOnly: true
		}
	)
	cookies.set(
		appConfig.cookieKeys.MY_SECURE_REFRESH_TOKEN_KEY,
		tokenData.refresh_token,
		{
			path: '/',
			maxAge: tokenData.expires_in,
			sameSite: 'none',
			secure: true,
			domain: process.env.REACT_APP_COOKIE_DOMAIN
			// httpOnly: true
		}
	)
}

const remove = () => {
	const cookies = new Cookies()

	cookies.remove(appConfig.cookieKeys.MY_SECURE_REFRESH_TOKEN_KEY, {
		path: '/',
		sameSite: 'none',
		secure: true,
		domain: process.env.REACT_APP_COOKIE_DOMAIN
		// httpOnly: true
	})
	cookies.remove(appConfig.cookieKeys.MY_SECURE_TOKEN_KEY, {
		path: '/',
		sameSite: 'none',
		secure: true,
		domain: process.env.REACT_APP_COOKIE_DOMAIN
		// httpOnly: true
	})
	cookies.remove('token', {
		path: '/',
		sameSite: 'none',
		secure: true,
		domain: process.env.REACT_APP_COOKIE_DOMAIN
		// httpOnly: true
	})
	cookies.remove('refresh_token', {
		path: '/',
		sameSite: 'none',
		secure: true,
		domain: process.env.REACT_APP_COOKIE_DOMAIN
		// httpOnly: true
	})
}

// const get = {
// 	token: () => {
// 		const cookies = new Cookies()
// 		return cookies.get(appConfig.cookieKeys.MY_SECURE_TOKEN_KEY)
// 	}
// }

export default { add, remove }

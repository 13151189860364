import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Search } from '@mui/icons-material'
import InputAdornment from '@mui/material/InputAdornment'

import PageBox from '../../atoms/pageBox/PageBox'
import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'
import kozmikTheme from '../../../assets/theme/theme.json'
import Header from '../../molecules/header/Header'
import TeamTable from '../../organisms/teamTable/TeamTable'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		height: '100%',
		overflow: 'hidden'
	},
	inputIcon: {
		color: theme.custom.colors.textSecondary,
		opacity: 0.6,
		cursor: 'pointer'
	},
	buttonContainer: {
		marginBottom: 10
	},
	searchInput: {
		backgroundColor: '#ffffff',
		borderRadius: 4,
		height: 38,
		width: '100%'
	},
	buttonTxt: {
		color: '#ffffff',
		fontSize: 14,
		fontWeight: '600',
		textAlign: 'left',
		textTransform: 'none',
		whiteSpace: 'nowrap'
	}
}))

const TeamTemplate = forwardRef((props, ref) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [searchVal, setSearchVal] = useState('')

	const permission = props.currentOrg?.roleType == 'Owner'

	const searchOnKeyPress = (e) => {
		if (e.charCode === 13) {
			props?.search(e.target.value)
		}
	}

	useImperativeHandle(ref, () => ({
		triggerSearch() {
			props?.search(searchVal)
		}
	}))

	return (
		<Grid
			container
			flexDirection='column'
			flexWrap='nowrap'
			className={classes.mainContainer}
		>
			<Grid container item justifyContent='space-between' alignItems='flex-end'>
				<Grid item xs={12} style={{ marginBottom: 10 }}>
					<Header text={t('usersTemp.headerTitle')} />
				</Grid>
				<Grid
					container
					item
					xs={12}
					sm={5}
					md={2}
					justifyContent='flex-start'
					alignItems='flex-end'
					className={classes.buttonContainer}
				>
					{!!permission && (
						<Button
							fullWidth
							size='large'
							variant='contained'
							onClick={() => props.setVisibleModal('invite')}
							sx={{
								textTransform: 'none',
								backgroundColor: `${kozmikTheme.colors.kozmikBlue} !important`,
								color: '#ffffff',
								boxShadow: 'none !important',
								fontSize: '14px',
								borderRadius: '4px',
								border: `1px solid ${kozmikTheme.colors.kozmikBlue}`
							}}
						>
							<Typography className={classes.buttonTxt}>
								{t('usersTemp.inviteUser')}
							</Typography>
						</Button>
					)}
				</Grid>
				<Grid
					container
					item
					xs={12}
					sm={5}
					md={2}
					justifyContent='flex-end'
					alignItems='flex-end'
					className={classes.buttonContainer}
				>
					<KozmikTextField
						className={classes.searchInput}
						placeholder={t('usersTemp.searchPlaceholder')}
						value={searchVal}
						onChange={(e) => setSearchVal(e.target.value)}
						onKeyPress={searchOnKeyPress}
						bgColor='#ffffff'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<Search
										className={classes.inputIcon}
										onClick={() => props?.search(searchVal)}
									/>
								</InputAdornment>
							)
						}}
					/>
				</Grid>
			</Grid>
			<Grid item style={{ flexGrow: 1, overflow: 'hidden' }}>
				<PageBox
					style={{
						paddingBottom: 10,
						width: '100%',
						maxHeight: '100%',
						overflow: 'hidden'
					}}
				>
					<TeamTable
						userData={props?.userData}
						userGroupsChange={props?.userGroupsChange}
						roleOpts={props?.roleOpts}
						setVisibleModal={props?.setVisibleModal}
						statusChange={props?.statusChange}
						statusOpts={props?.statusOpts}
						setDeleteId={props?.setDeleteId}
						permission={permission}
					/>
				</PageBox>
			</Grid>
		</Grid>
	)
})

const mapStateToProps = (state) => ({
	...state.userInfo
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(
	TeamTemplate
)

import React, { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import update from 'immutability-helper'
import {
	AddBox,
	DeleteOutlined,
	ToggleOnOutlined,
	CheckBoxOutlined
} from '@mui/icons-material'
import {
	Box,
	Popover,
	Grid,
	Button,
	List,
	ListItem,
	ListItemButton,
	ListItemText
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import cx from 'classnames'

import timeoutDelay from '../../../methods/timeoutDelay'
import { getInputByDataType } from '../../../defaultElementSchemas/schemas/form'

import AccordionItem from '../../atoms/accordionItem/AccordionItem'
import AppPageBox from '../../atoms/appPageBox/AppPageBox'
import Accordion from '../../atoms/accordion/Accordion'

import PopoverItem from '../popoverItem/PopoverItem'

import Deprecated_FormHiddenFieldListItem from '../deprecated_FormHiddenFieldListItem/Deprecated_FormHiddenFieldListItem'
import getValueType from '../../../methods/getValueType'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	popoverBox: {
		padding: 8,
		width: 200
	},
	popoverIcon: {
		color: '#000000',
		opacity: 0.7,
		width: '14px'
	}
}))

const Deprecated_FormHiddenFieldList = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const hiddenElementList = props.localElementData?.hiddenFields
	const fields = props.entities?.find((x) => x.name === props.source)?.fields

	const [selectedElementIndex, setSelectedElementIndex] = useState(null)

	/****ADD_HIDDEN_FIELD_POPOVER***************************/
	const [hiddenAnchorEl, setHiddenAnchorEl] = useState(null)
	const hiddenOpen = Boolean(hiddenAnchorEl)

	const handleHiddenClick = (event) => {
		setHiddenAnchorEl(event.currentTarget)
	}
	const handleHiddenClose = () => {
		setHiddenAnchorEl(null)
	}

	const [elementPop, setElementPop] = useState(null)
	const elementPopOpen = Boolean(elementPop)

	const openPop = (event, index) => {
		setSelectedElementIndex(index)
		setElementPop(event.currentTarget)
	}

	const closePop = () => {
		setElementPop(null)
	}

	/****HIDDEN_FIELDS***************************/
	const updateHiddenField = (index, val, valueType) => {
		const modifiedElementData = update(props.elementData, {
			hiddenFields: {
				[index]: {
					value: { $set: val },
					valueType: { $set: getValueType(val, valueType) }
				}
			}
		})

		timeoutDelay(props.updateSelectedElement, modifiedElementData, 1000)
		props.setLocalElementData(modifiedElementData)
	}
	const addNewHiddenField = (field) => {
		const newHiddenField = {
			label: field.label,
			field: field.name,
			type: field.dataType,
			relationType: field.relationType,
			value: null,
			valueType: 'Field'
		}

		const modifiedElementData = update(props.elementData, {
			hiddenFields: {
				$push: [newHiddenField]
			}
		})

		props.updateSelectedElement(modifiedElementData)

		setSelectedElementIndex(hiddenElementList.length)
	}
	const deleteHiddenField = () => {
		const modifiedElementData = update(props.elementData, {
			hiddenFields: {
				$splice: [[selectedElementIndex, 1]]
			}
		})

		props.updateSelectedElement(modifiedElementData)
		props.setLocalElementData(modifiedElementData)
		setSelectedElementIndex(null)
	}

	return (
		!!hiddenElementList?.length && (
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.form.hiddenFieldsTitle')}
					expanded={true}
				>
					{hiddenElementList.map((item, index) => {
						const field = fields?.find((x) => x.name === item?.field)

						return (
							<div key={index} className={classes.mb10}>
								<AccordionItem
									title={field?.label || item?.mapping?.field}
									expanded={selectedElementIndex === index}
									onChange={() => (event, newExpanded) => {
										setSelectedElementIndex(
											selectedElementIndex === index ? null : index
										)
									}}
									moreClick={(e) => openPop(e, index)}
								>
									<Deprecated_FormHiddenFieldListItem
										key={`form_hidden_${index}`}
										updateHiddenField={updateHiddenField}
										conditionIndex={index}
										condition={item}
										/********/
										elements={props.localElementData?.body}
										activeScreen={props.activeScreen}
										source={props.source}
										languageCode={props.languageCode}
										element={item}
										entities={props.entities}
										entityFields={fields}
										entityField={field}
									/>
								</AccordionItem>
							</div>
						)
					})}

					<div style={{ width: '100%', position: 'relative', padding: 2 }}>
						<Button
							startIcon={<AddBox />}
							fullWidth
							size='small'
							variant='contained'
							onClick={props.unusedFields?.length ? handleHiddenClick : null}
							sx={{
								textTransform: 'none',
								backgroundColor: '#D7DBFB !important', //kozmikLightBlue
								color: '#1B36FE', //kozmikBlue
								boxShadow: 'none',
								fontSize: '14px',
								borderRadius: '6px'
							}}
						>
							{t('screens.elementSettings.form.addNewHidden')}
						</Button>

						{!!props.unusedFields?.length && (
							<Popover
								open={hiddenOpen}
								anchorEl={hiddenAnchorEl}
								onClose={handleHiddenClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left'
								}}
								PaperProps={{
									style: { right: 17 } //NOTE:for 100% width of parent container
								}}
							>
								<List>
									{props.unusedFields.map((item, index) => (
										<ListItem disablePadding key={`form_element_${index}`}>
											<ListItemButton
												style={{ height: 35 }}
												onClick={() => {
													handleHiddenClose()
													addNewHiddenField(item)
												}}
											>
												<ListItemText
													primary={item.label}
													primaryTypographyProps={{ fontSize: 13 }}
												/>
											</ListItemButton>
										</ListItem>
									))}
								</List>
							</Popover>
						)}
					</div>

					<Popover
						open={elementPopOpen}
						anchorEl={elementPop}
						onClose={() => {
							closePop()
						}}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
					>
						<Box className={classes.popoverBox}>
							<PopoverItem
								text={t('screens.elementSettings.form.hiddenField.delete')}
								onClick={() => {
									closePop()
									deleteHiddenField()
								}}
								color='#FF2619'
							>
								<DeleteOutlined
									className={classes.popoverIcon}
									style={{ color: '#FF2619', opacity: 1 }}
								/>
							</PopoverItem>
						</Box>
					</Popover>
				</Accordion>
			</AppPageBox>
		)
	)
}

export default Deprecated_FormHiddenFieldList

import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
	badge: {
		fontSize: 8,
		fontWeight: 'bold',
		borderRadius: 7.5,
		minWidth: 15,
		height: 15,
		padding: 4,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.custom.colors.kozmikBlue,
		color: '#ffffff'
	}
}))

const Badge = (props) => {
	const classes = useStyles()

	return (
		<Typography
			className={classes.badge}
			style={{
				backgroundColor: props?.bgColor || null,
				color: props?.textColor || null
			}}
		>
			{props?.text}
		</Typography>
	)
}

export default Badge

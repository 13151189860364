const replaceTrToEng = (string) => {
	const replacements = {
		ğ: 'g',
		Ğ: 'G',
		ü: 'u',
		Ü: 'U',
		ş: 's',
		Ş: 'S',
		ı: 'i',
		İ: 'I',
		ö: 'o',
		Ö: 'O',
		ç: 'c',
		Ç: 'C'
	}
	return string.replace(
		/ğ|Ğ|ü|Ü|ş|Ş|ı|İ|ö|Ö|ç|Ç/g,
		(char) => replacements[char]
	)
}

export default replaceTrToEng

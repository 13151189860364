import React from 'react'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'
import Radio from '@mui/material/Radio'
import { textAlign } from '@mui/system'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 4,
		'&:hover': {
			backgroundColor: 'transparent'
		}
	},
	icon: {
		borderRadius: 6,
		width: 26,
		height: 26,
		backgroundColor: (props) => {
			return props?.radioColor ? props.radioColor : '#ffffff'
		},
		backgroundImage: (props) => {
			return props?.imgUrl ? `url("${props.imgUrl}")` : null
		},
		// boxShadow: '0 0 2px gray inset',
		backgroundSize: 'cover',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2
		},
		'input:hover ~ &': {
			//backgroundColor: '#ebf1f5'
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(206,217,224,.5)'
		}
	},
	checkedIcon: {
		border: `2px solid ${theme.custom.colors.kozmikBlue}`
		// position: 'relative',
		// //backgroundColor: theme.custom.colors.kozmikBlue,
		// // border: '2px solid red',
		// // backgroundImage:
		// // 	'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		// //backgroundImage: 'blue',
		// '&:before': {
		// 	position: 'absolute',
		// 	right: 0,
		// 	top: -1,
		// 	left: -1,
		// 	bottom: 0,
		// 	display: 'block',
		// 	width: 28,
		// 	height: 28,
		// 	borderRadius: 6,
		// 	border: `1px solid ${theme.custom.colors.kozmikBlue}`,
		// 	//backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		// 	backgroundColor: 'transparent',
		// 	content: '""'
		// }
		// // 'input:hover ~ &': {
		// // 	backgroundColor: theme.custom.colors.kozmikBlue
		// // }
	}
}))

const RadioButton = (props) => {
	const classes = useStyles(props)

	return (
		<Radio
			className={classes.root}
			checked={props?.checked}
			onClick={props?.onClick}
			disableRipple
			color='default'
			checkedIcon={
				props?.icon ? (
					<span className={classes.checkedIcon}>
						<props.icon
							className={classes.icon}
							style={{ display: 'inherit' }}
						/>
					</span>
				) : (
					<span className={clsx(classes.icon, classes.checkedIcon)} />
				)
			}
			icon={
				props?.icon ? (
					<props.icon className={classes.icon} style={{ display: 'inherit' }} />
				) : (
					<span className={classes.icon} />
				)
			}
		/>
	)
}

export default RadioButton

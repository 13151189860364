import React, { forwardRef } from 'react'
import withStyles from '@mui/styles/withStyles'
import Checkbox from '@mui/material/Checkbox'

const CustomCheckBox = withStyles((theme) => ({
	root: {
		'& .MuiSvgIcon-root': {
			color: '#E9E9E9'
		},
		'&.Mui-checked .MuiSvgIcon-root': {
			color: theme.custom.colors.kozmikBlue
		}
	}
}))(Checkbox)

const KozmikCheckBox = forwardRef((props, ref) => {
	return <CustomCheckBox variant='outlined' ref={ref} {...props} />
})
export default KozmikCheckBox

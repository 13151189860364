import React from 'react'
import { makeStyles } from '@mui/styles'
import cx from 'classnames'

const useStyles = makeStyles((theme) => ({
	switchContainer: {
		width: 320,
		height: 59,
		backgroundColor: '#ffffff',
		borderRadius: 29.5,
		position: 'relative',
		padding: 8,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	active: {
		'& $switch': {
			left: 'calc(100% - 158px)'
		}
	},
	passive: {
		'& $switch': {
			left: 8
		}
	},
	switch: {
		width: 'calc(50% - 10px)',
		backgroundColor: theme.custom.colors.kozmikDarkBlue,
		borderRadius: 21.5,
		position: 'absolute',
		top: 8,
		bottom: 8,
		transition: '0.2s',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden'
	},
	switchText: {
		fontSize: 16,
		color: '#ffffff'
	},
	textBox: {
		width: 'calc(50% - 10px)',
		fontSize: 16,
		color: theme.custom.colors.kozmikDarkBlue,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden'
	}
}))

const TextSwitch = ({ isOn, handleToggle, onText, offText }) => {
	const classes = useStyles()

	return (
		<div
			className={cx(
				classes.switchContainer,
				isOn ? classes.active : classes.passive
			)}
			onClick={handleToggle}
		>
			<div className={classes.switch}>
				<span className={classes.switchText}>{isOn ? onText : offText}</span>
			</div>

			<div className={classes.textBox}>{offText}</div>
			<div className={classes.textBox}>{onText}</div>
		</div>
	)
}

export default TextSwitch

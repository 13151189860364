import { SETDETAIL, SETCONFIG } from '../action-types/appDataActionTypes'

const initialState = {
	appDetail: null,
	appConfig: null
}

const appDataReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case SETDETAIL:
			return { ...state, appDetail: action.payload }
		case SETCONFIG:
			return { ...state, appConfig: action.payload }
		default:
			return state
	}
}

export default appDataReducer

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import enLang from './en/translations.json'

const resources = {
	'en-US': { common: enLang },
	'tr-TR': { common: enLang }
}

const options = {
	order: ['querystring', 'navigator'],
	lookupQuerystring: 'lng'
}

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		detection: options,
		resources,
		ns: ['common'],
		defaultNS: 'common',
		fallbackLng: 'en-US',
		supportedLngs: ['en-US', 'tr-TR'],
		interpolation: {
			escapeValue: false
		},
		debug: false
	})

export default i18n

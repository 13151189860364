import React, {
	useState,
	forwardRef,
	useImperativeHandle,
	useEffect
} from 'react'
import { Grid, Box, Popover, Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { AddBox, MoreVert } from '@mui/icons-material'
import cx from 'classnames'

import { default as kozmikTheme } from '../../../assets/theme/theme.json'

import Label from '../../atoms/label/Label'
import WarningDialog from '../../molecules/dialog/warningDialog/WarningDialog'
import TableMenuItem from '../../molecules/tableMenuItem/TableMenuItem'
import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'
import KozmikTabs from '../../atoms/kozmikTabs/KozmikTabs'
import KozmikTab from '../../atoms/kozmikTabs/KozmikTab'
import SelectBox from '../../molecules/selectBox/SelectBox'

const useStyles = makeStyles((theme) => ({
	titleBox: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	tablesTitle: {
		color: '#000000',
		fontSize: 14,
		textAlign: 'left',
		fontWeight: 'bold'
	},
	tablesBox: {
		paddingTop: 10,
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'nowrap'
	},
	addIcon: {
		cursor: 'pointer'
	},
	popoverBox: {
		padding: 16,
		width: 250
	},
	popoverBtn: {
		borderRadius: '4px',
		width: '48%',
		height: '35px'
	},
	btnBlue: {
		backgroundColor: theme.custom.colors.kozmikBlue
	},
	btnRed: {
		backgroundColor: theme.custom.colors.red,
		'&:hover': {
			backgroundColor: theme.custom.colors.darkRed
		}
	},
	popoverBtnTxt: {
		color: '#FFFFFF',
		fontSize: 14,
		fontWeight: 'bold',
		textAlign: 'left',
		textTransform: 'none'
	},
	kozmikBlue: {
		color: theme.custom.colors.kozmikBlue
	},
	kozmikRed: {
		color: theme.custom.colors.red
	},
	input: {
		borderRadius: 3,
		backgroundColor: theme.custom.colors.kozmikGray,
		fontSize: '14px'
	},
	inputTitle: {
		fontSize: '12px',
		marginBottom: 4
	}
}))

const AppDataTables = forwardRef((props, ref) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const onlyOwnerOptions = [
		{
			label: t('appData.onlyOwnerOpts.everyone'),
			value: 'false'
		},
		{
			label: t('appData.onlyOwnerOpts.creator'),
			value: 'true'
		}
	]

	const [tableNameVal, setTableNameVal] = useState('')
	const [tableOnlyOwner, setTableOnlyOwner] = useState('false')
	const [editNameVal, setEditNameVal] = useState('')
	const [editTableOnlyOwner, setEditTableOnlyOwner] = useState('false')
	const [managed, setManaged] = useState(true)
	const [tablePop, setTablePop] = useState(null)
	const [editPop, setEditPop] = useState(null)
	const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
		useState(false)
	const [tableNameValid, setTableNameValid] = useState(true)

	const openTablePop = (event) => {
		setTableOnlyOwner('false')
		setTablePop(event.currentTarget)
	}
	const openEditPop = (event) => {
		setEditPop(event.currentTarget)
	}

	const closeTablePop = () => {
		setTablePop(null)
		setTableNameVal('')
		setTableNameValid(true)
	}
	const closeEditPop = () => {
		setEditPop(null)
		setTableNameValid(true)
	}

	const tablePopOpen = Boolean(tablePop)
	const editPopOpen = Boolean(editPop)

	const submitTableName = () => {
		validateTableName(tableNameVal).then((isValid) => {
			isValid && props.createTable(tableNameVal, JSON.parse(tableOnlyOwner))
		})
	}
	const submitEditName = () => {
		validateTableName(editNameVal).then((isValid) => {
			isValid &&
				props.editTable(
					props.activeTable.id,
					editNameVal,
					JSON.parse(editTableOnlyOwner)
				)
		})
	}
	const handleDeleteDialogClose = () => {
		setDeleteConfirmationVisible(false)
	}
	const handleDeleteDialogAccept = () => {
		props.deleteTable(props.activeTable.id)
		setDeleteConfirmationVisible(false)
	}
	const tableNameOnchange = (e) => {
		setTableNameVal(e.target.value)
		validateTableName(e.target.value)
	}
	const editNameOnchange = (e) => {
		setEditNameVal(e.target.value)
		validateTableName(e.target.value)
	}
	const handleTableSelection = (id, newId) => {
		const selected = props.tables?.find((x) => x?.id === newId)
		props.setActiveTable(selected)
	}
	const handleTableEdit = (item, e) => {
		openEditPop(e)
	}
	const validateTableName = (name) => {
		return new Promise((resolve) => {
			// // const re = /^[a-zA-Z0-9]*$/
			// const re = /^[a-zA-Z0-9+\s]*$/
			// const isValid = re.test(name)
			const isValid = true
			if (isValid && name != '') {
				setTableNameValid(true)
				resolve(true)
			} else {
				setTableNameValid(false)
				resolve(false)
			}
		})
	}

	useImperativeHandle(ref, () => ({
		closeTablePop() {
			closeTablePop()
		},
		closeEditPop() {
			closeEditPop()
		}
	}))

	const onlyOwnerChange = (e) => {
		setTableOnlyOwner(e.target.value)
	}
	const editOnlyOwnerChange = (e) => {
		setEditTableOnlyOwner(e.target.value)
	}

	useEffect(() => {
		if (props?.activeTable) {
			setManaged(props.activeTable.managed)
			setEditNameVal(props.activeTable.label)
			setEditTableOnlyOwner(props.activeTable.onlyOwner ? 'true' : 'false')
		}
	}, [props.activeTable])

	return (
		<>
			<Popover //TODO:popoverlar componentleştirilebilir
				id={'table-popover'}
				open={tablePopOpen}
				anchorEl={tablePop}
				onClose={closeTablePop}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<Box className={classes.popoverBox}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<KozmikTextField
								error={!tableNameValid}
								helperText={
									tableNameValid ? '' : t('appData.tableValidationError')
								}
								fullWidth
								placeholder={t('appData.tableNameInput')}
								value={tableNameVal}
								onChange={tableNameOnchange}
								autoFocus
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography className={classes.inputTitle}>
								{t('appData.onlyOwnerTitle')}
							</Typography>
							<SelectBox
								className={classes.input}
								onChange={onlyOwnerChange}
								value={tableOnlyOwner}
								data={onlyOwnerOptions}
								textKey='label'
								valueKey='value'
							/>
						</Grid>

						<Grid item container xs={12} justifyContent='space-between'>
							<Button
								// startIcon={<AddBox />}
								// fullWidth
								size='small'
								variant='contained'
								onClick={closeTablePop}
								sx={{
									textTransform: 'none',
									backgroundColor: `#ffffff !important`,
									boxShadow: 'none !important',
									fontSize: 16,
									borderRadius: '4px',
									width: '48%'
								}}
							>
								<Typography
									className={cx(classes.popoverBtnTxt, classes.kozmikBlue)}
								>
									{t('appData.tableCancelButton')}
								</Typography>
							</Button>
							<Button
								// startIcon={<AddBox />}
								// fullWidth
								size='small'
								variant='contained'
								onClick={submitTableName}
								sx={{
									textTransform: 'none',
									backgroundColor: `${kozmikTheme.colors.kozmikLightBlue} !important`,
									boxShadow: 'none !important',
									fontSize: 16,
									borderRadius: '4px',
									width: '48%'
								}}
							>
								<Typography
									className={cx(classes.popoverBtnTxt, classes.kozmikBlue)}
								>
									{t('appData.tableNameButton')}
								</Typography>
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Popover>
			<Grid item container className={classes.tablesBox}>
				<KozmikTabs
					value={props?.activeTable?.id || false}
					onChange={handleTableSelection}
					variant='scrollable'
					scrollButtons='auto'
					aria-label='scrollable auto tabs example'
					style={{ marginLeft: 10 }}
				>
					{!!props?.tables?.length &&
						props.tables.map((item, index) => (
							<KozmikTab
								key={index}
								label={item.label}
								value={item.id}
								iconPosition='end'
								icon={
									props.activeTable?.name === item.name ? (
										<MoreVert
											className={classes.moreIcon}
											style={{ marginRight: '-14px', marginLeft: 0 }}
											onClick={(e) => {
												handleTableEdit(item, e)
											}}
										/>
									) : (
										''
									)
								}
							/>
						))}
				</KozmikTabs>
				{!props.activeTable?.external && (
					<AddBox className={classes.addIcon} onClick={openTablePop} />
				)}
				{!props.tables?.length ? (
					<span style={{ fontSize: 12, fontStyle: 'italic' }}>
						{t('appData.emptyTablesText')}
					</span>
				) : null}
				{/* {props?.tables.length > 0 &&
					props.tables.map((item, index) => (
						<TableMenuItem
							key={index}
							item={item}
							isActive={item.id == props.activeTable?.id}
							click={handleTableSelection}
							editClick={handleTableEdit}//TODO:edit
						/>
					))} */}
				<Popover
					id={'edit-popover'}
					open={editPopOpen}
					anchorEl={editPop}
					onClose={closeEditPop}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
				>
					<Box className={classes.popoverBox}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<KozmikTextField
									error={!tableNameValid}
									helperText={
										tableNameValid ? '' : t('appData.tableValidationError')
									}
									fullWidth
									placeholder={t('appData.tableNameInput')}
									value={editNameVal}
									onChange={editNameOnchange}
								/>
							</Grid>
							{!managed && (
								<Grid item xs={12}>
									<Typography className={classes.inputTitle}>
										{t('appData.onlyOwnerTitle')}
									</Typography>
									<SelectBox
										className={classes.input}
										onChange={editOnlyOwnerChange}
										value={editTableOnlyOwner}
										data={onlyOwnerOptions}
										textKey='label'
										valueKey='value'
									/>
								</Grid>
							)}
							<Grid item container xs={12} justifyContent='space-between'>
								{!managed && (
									<Button
										// startIcon={<AddBox />}
										// fullWidth
										size='small'
										variant='contained'
										onClick={() => setDeleteConfirmationVisible(true)}
										sx={{
											textTransform: 'none',
											backgroundColor: `transparent !important`,
											boxShadow: 'none !important',
											fontSize: 16,
											borderRadius: '4px',
											width: '48%'
										}}
									>
										<Typography
											className={cx(classes.popoverBtnTxt, classes.kozmikRed)}
										>
											{t('appData.tableDeleteButton')}
										</Typography>
									</Button>
								)}
								<Button
									// startIcon={<AddBox />}
									// fullWidth
									size='small'
									variant='contained'
									onClick={submitEditName}
									sx={{
										textTransform: 'none',
										backgroundColor: `${kozmikTheme.colors.kozmikLightBlue} !important`,
										boxShadow: 'none !important',
										fontSize: 16,
										borderRadius: '4px',
										width: '48%'
									}}
								>
									<Typography
										className={cx(classes.popoverBtnTxt, classes.kozmikBlue)}
									>
										{t('appData.tableEditButton')}
									</Typography>
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Popover>
				<WarningDialog
					dialogVisible={deleteConfirmationVisible}
					dialogClose={handleDeleteDialogClose}
					dialogAccept={handleDeleteDialogAccept}
					dialogTitle={t('appData.tableModal.dialogTitle')}
					title={null}
					text={t('appData.tableModal.text')}
					dialogCancelText={t('appData.tableModal.closeButton')}
					dialogAcceptText={t('appData.tableModal.acceptButton')}
				/>
			</Grid>
		</>
	)
})

export default AppDataTables

import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { SwapVert, FilterAltOutlined, PlusOne } from '@mui/icons-material'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import FilterModalOptions from '../../../molecules/filterModalOptions/FilterModalOptions'
import SearchOptions from '../../../molecules/searchOptions/SearchOptions'
import EmptyOptions from '../../../molecules/emptyOptions/EmptyOptions'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import Accordion from '../../../atoms/accordion/Accordion'
import NumericInput from '../../../atoms/numericInput/NumericInput'
import { default as Checkbox } from '../../../atoms/kozmikCheckBox/KozmikCheckBox'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import KozmikTextField from '../../../atoms/kozmikTextField/KozmikTextField'
import AccordionItem from '../../../atoms/accordionItem/AccordionItem'
import FilterList from '../../../molecules/filterList/FilterList'
import Sorting from '../../../molecules/sorting/Sorting'
import MoreOptions from '../../../molecules/moreOptions/MoreOptions'
import ActionList from '../../actionList/ActionList'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import getFilterOpByDataType from '../../../../methods/getFilterOpByDataType'
import relationTypes from '../../../../common/enums/relationTypes'
import getArrayExcludingForbidArray from '../../../../methods/getArrayExcludingForbidArray'
import systemFieldNames from '../../../../common/systemFieldNames/systemFieldNames'

import { ReactComponent as Standart } from '../../../../assets/icons/Settings/Standard.svg'
import { ReactComponent as Kompakt } from '../../../../assets/icons/Settings/Kompakt.svg'

import { ReactComponent as ImagePositionLeft } from '../../../../assets/icons/Settings/Image-Position-Left.svg'
import { ReactComponent as ImagePositionRight } from '../../../../assets/icons/Settings/Image-Position-Right.svg'
import { ReactComponent as Koseli } from '../../../../assets/icons/Settings/Koseli.svg'
import { ReactComponent as Oval } from '../../../../assets/icons/Settings/Oval.svg'
import { ReactComponent as Daire } from '../../../../assets/icons/Settings/KaroTip-Daire.svg'

import defaultElementSchemas from '../../../../defaultElementSchemas'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	inputLight: {
		borderRadius: 3,
		backgroundColor: '#ffffff',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const List = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [elementName, setElementName] = useState(null)

	const sizeOptions = [
		{
			text: t('screens.elementSettings.list.sizeOpts.standard'),
			value: 80,
			icon: Standart
		},
		{
			text: t('screens.elementSettings.list.sizeOpts.compact'),
			value: 48,
			icon: Kompakt
		}
	]

	const imgPositionOptions = [
		{
			text: t('screens.elementSettings.list.imgPositionOpts.left'),
			value: 'left',
			icon: ImagePositionLeft
		},
		{
			text: t('screens.elementSettings.list.imgPositionOpts.right'),
			value: 'right',
			icon: ImagePositionRight
		}
	]

	/****SOURCE***************************/
	const [source, setSource] = useState('')

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		fieldDataTypes.textBased,
		null,
		true
		// relationTypes.one
	)

	const imageFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		['Image'],
		null,
		true,
		relationTypes.one
	)

	const sourceChange = (e) => {
		submitSource(source, e.target.value)
		setSource(e.target.value)
	}

	const submitSource = (oldTableName, tableName) => {
		const table = props?.entities?.find((y) => y.name == tableName)

		const txtFields = getEntityFieldsByDataTypes(
			props?.entities,
			tableName,
			fieldDataTypes.textBased,
			null,
			true
			// relationTypes.one
		)
		const imgFields = getEntityFieldsByDataTypes(
			props?.entities,
			tableName,
			['Image'],
			null,
			true,
			relationTypes.one
		)

		const firstImgField = imgFields?.length ? imgFields[0] : null

		const filteredTxtFields = getArrayExcludingForbidArray(
			txtFields,
			'name',
			systemFieldNames.all
			// systemFieldNames.notVisible
		)
		const titleField = txtFields?.find((x) => x.name == 'Name')

		let modifiedElementData = update(props.elementData, {
			source: { $set: tableName },
			mapping: {
				title: {
					field: { $set: titleField?.name },
					type: { $set: titleField?.dataType }
				},
				text: {
					field: { $set: filteredTxtFields?.[0]?.name },
					type: { $set: filteredTxtFields?.[0]?.dataType }
				},
				subText: {
					field: { $set: filteredTxtFields?.[1]?.name },
					type: { $set: filteredTxtFields?.[1]?.dataType }
				},
				imgUrl: {
					field: { $set: firstImgField?.name },
					type: { $set: firstImgField?.dataType }
				}
			},
			sorting: {
				field: { $set: titleField?.name },
				sort: { $set: 'asc' }
			},
			filters: {
				groupOp: { $set: 'AND' },
				rules: { $set: [] }
			},
			seeAllUrl: {
				$set: props.elementData?.seeAllUrl
			},
			filterModal: {
				$set: defaultElementSchemas.filterModal()
			}
		})

		props.updateBulkScreensWithModifiedData(
			table.name,
			null,
			modifiedElementData
		)
	}

	/****TITLE***************************/
	const [title, setTitle] = useState('')

	const titleChange = (e) => {
		submitTitle(e.target.value)
		setTitle(e.target.value)
	}

	const submitTitle = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)

		const selectedFieldFilterOps = selectedField
			? getFilterOpByDataType(selectedField.dataType)
			: null

		const modifiedElementData = update(props.elementData, {
			mapping: {
				title: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			},
			searchOp: {
				$set: selectedFieldFilterOps ? selectedFieldFilterOps.searchOp : null
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****TEXT***************************/
	const [text, setText] = useState('')

	const textChange = (e) => {
		submitText(e.target.value)
		setText(e.target.value)
	}

	const submitText = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				text: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****SUBTEXT***************************/
	const [subText, setSubText] = useState('')

	const subTextChange = (e) => {
		submitSubText(e.target.value)
		setSubText(e.target.value)
	}

	const submitSubText = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				subText: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****IMGURL***************************/
	const [imgUrl, setImgUrl] = useState('')

	const imgUrlChange = (e) => {
		if (e.target.value) {
			submitImgUrl(e.target.value, imgPosition, imgCorner)
			setImgUrl(e.target.value)
		} else {
			submitImgUrl('', 'left', 'oval')
			setImgUrl('')
			setImgPosition('left')
			setImgCorner('oval')
		}
	}

	const submitImgUrl = (x, imgPosition, imgCorner) => {
		const selectedField = imageFields?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				imgUrl: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			},
			imgPosition: { $set: imgPosition },
			corner: { $set: imgCorner }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****GROUP_BY***************************/
	const [groupBy, setGroupBy] = useState('')

	const groupByChange = (e) => {
		submitGroupBy(e.target.value)
		setGroupBy(e.target.value)
	}

	const submitGroupBy = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)

		let modifiedElementData
		if (props.elementData.mapping.groupBy) {
			modifiedElementData = update(props.elementData, {
				mapping: {
					groupBy: {
						field: { $set: x },
						type: { $set: selectedField?.dataType }
					}
				}
			})
		} else {
			modifiedElementData = {
				...props.elementData,
				...{
					mapping: {
						...props.elementData.mapping,
						...{
							groupBy: {
								field: x,
								type: selectedField?.dataType
							}
						}
					},
					groupBy: {
						showGroupsOnTop: true
					}
				}
			}
		}

		props.updateSelectedElement(modifiedElementData)
	}

	/****SHOWGROUPSONTOP***************************/
	const [showGroupsOnTop, setShowGroupsOnTop] = useState(false)

	const showGroupsOnTopChange = (e) => {
		setShowGroupsOnTop(e.target.checked)
		submitShowGroupsOnTop(e.target.checked)
	}

	const submitShowGroupsOnTop = (x) => {
		const modifiedElementData = update(props.elementData, {
			groupBy: {
				showGroupsOnTop: { $set: x }
			}
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****ACTIONS***************************/
	const updateActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			actions: { $set: newActions }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****HEIGHT-SIZE***************************/
	const [size, setSize] = useState(null)

	const sizeChange = (val) => {
		submitSize(val)
		setSize(val)
	}

	const submitSize = (x) => {
		const modifiedElementData = update(props.elementData, {
			height: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****IMG_POSITION***************************/
	const [imgPosition, setImgPosition] = useState(null)

	const imgPositionChange = (val) => {
		submitImgPosition(val)
		setImgPosition(val)
	}

	const submitImgPosition = (x) => {
		const modifiedElementData = update(props.elementData, {
			imgPosition: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****IMG_CORNER***************************/
	const [imgCorner, setImgCorner] = useState(null)

	const imgCornerChange = (val) => {
		submitImgCorner(val)
		setImgCorner(val)
	}

	const submitImgCorner = (x) => {
		const modifiedElementData = update(props.elementData, {
			corner: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	const imgCornerOptions = [
		{
			text: t('screens.elementSettings.list.imgCornerOpts.square'),
			value: 'square',
			icon: Koseli
		},
		{
			text: t('screens.elementSettings.list.imgCornerOpts.oval'),
			value: 'oval',
			icon: Oval
		},
		{
			text: t('screens.elementSettings.list.imgCornerOpts.circle'),
			value: 'circle',
			icon: Daire
		}
	]

	/****LIMIT***************************/
	const [limit, setLimit] = useState(25)
	const limitChange = (val) => {
		timeoutDelay(submitLimit, val, 1000)
		setLimit(val)
	}
	const submitLimit = (x) => {
		const modifiedElementData = update(props.elementData, {
			limit: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****FILTER_&_SORT***************************/
	const [localElementData, setLocalElementData] = useState(null)

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****SOURCE***************************/
		setSource(elementData?.source ? elementData.source : '')
		/****TITLE***************************/
		setTitle(
			elementData?.mapping?.title?.field ? elementData.mapping.title.field : ''
		)
		/****TEXT***************************/
		setText(
			elementData?.mapping?.text?.field ? elementData.mapping.text.field : ''
		)
		/****SUBTEXT***************************/
		setSubText(
			elementData?.mapping?.subText?.field
				? elementData.mapping.subText.field
				: ''
		)
		/****IMGURL***************************/
		setImgUrl(
			elementData?.mapping?.imgUrl?.field
				? elementData.mapping.imgUrl.field
				: ''
		)
		/****GROUP_BY***************************/
		setGroupBy(
			elementData?.mapping?.groupBy?.field
				? elementData.mapping.groupBy.field
				: ''
		)
		/****HEIGHT-SIZE***************************/
		setSize(elementData?.height ? elementData.height : null)
		/****IMG_POSITION***************************/
		setImgPosition(elementData?.imgPosition ? elementData.imgPosition : 'left')
		/****IMG_CORNER***************************/
		setImgCorner(elementData?.corner ? elementData.corner : null)
		/****LIMIT***************************/
		setLimit(elementData?.limit ?? 10)
		/****SHOWGROUPSONTOP***************************/
		setShowGroupsOnTop(elementData?.groupBy?.showGroupsOnTop ?? false)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
			setLocalElementData(props.elementData)
		}
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.source')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						{/* <Grid item xs={4}>
								<Label>{t('screens.elementSettings.list.source')}</Label>
							</Grid> */}
						<Grid item xs={12}>
							<SelectBox
								className={classes.input}
								onChange={sourceChange}
								value={source}
								data={props.entities}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>

					<div className={classes.mb10}>
						<AccordionItem
							icon={FilterAltOutlined}
							title={t('screens.elementSettings.list.filter')}
							expanded={false}
							padding={8}
						>
							<FilterList
								filter={localElementData?.filters}
								table={props?.entities?.find(
									(y) => y.name == props?.elementData?.source
								)}
								activeScreen={props?.activeScreen}
								entities={props?.entities}
								tableName={props?.elementData?.source}
								screenTable={props?.entities?.find(
									(y) => y.name == props.activeScreen?.data?.source
								)}
								filtering={props.filtering}
								setLocalElementData={setLocalElementData}
							/>
						</AccordionItem>
					</div>

					<div className={classes.mb10}>
						<AccordionItem
							icon={SwapVert}
							title={t('screens.elementSettings.list.sort')}
							expanded={false}
							padding={8}
						>
							<Sorting
								sortingData={localElementData?.sorting}
								sorting={props.sorting}
								fieldOptions={textFields}
							/>
						</AccordionItem>
					</div>

					<div className={classes.mb10}>
						<AccordionItem
							icon={PlusOne}
							title={t('screens.elementSettings.list.limit')}
							expanded={false}
							padding={8}
						>
							<NumericInput minVal={0} value={limit} onChange={limitChange} />
						</AccordionItem>
					</div>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.list.title')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.list.emptyField')}
								className={classes.input}
								onChange={titleChange}
								value={title}
								data={textFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.list.desc')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.list.emptyField')}
								className={classes.input}
								onChange={textChange}
								value={text}
								data={textFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.list.subTitle')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.list.emptyField')}
								className={classes.input}
								onChange={subTextChange}
								value={subText}
								data={textFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.list.image')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.list.emptyField')}
								className={classes.input}
								onChange={imgUrlChange}
								value={imgUrl}
								data={imageFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.group')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.list.groupBy.title')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.list.emptyField')}
								className={classes.input}
								onChange={groupByChange}
								value={groupBy}
								data={textFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					{groupBy && (
						<Grid container alignItems='center' className={classes.mb10}>
							<div className={cx(classes.item)}>
								<div>
									<Checkbox
										checked={showGroupsOnTop}
										onChange={showGroupsOnTopChange}
									/>
								</div>
								<div className={cx(classes.labelBox, classes.growBox)}>
									<Label>
										{t('screens.elementSettings.list.groupBy.showGroupsOnTop')}
									</Label>
								</div>
							</div>
						</Grid>
					)}
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.list.size')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={sizeOptions}
								getChange={sizeChange}
								activeValue={size}
							/>
						</Grid>
					</Grid>
					{!!imgUrl && (
						<>
							<Grid container alignItems='center' className={classes.mb10}>
								<Grid item xs={4}>
									<Label>{t('screens.elementSettings.list.type')}</Label>
								</Grid>
								<Grid item xs={8}>
									<SquareOptions
										compact
										data={imgPositionOptions}
										getChange={imgPositionChange}
										activeValue={imgPosition}
									/>
								</Grid>
							</Grid>

							<Grid container alignItems='center' className={classes.mb10}>
								<Grid item xs={4}>
									<Label>{t('screens.elementSettings.list.imgCorner')}</Label>
								</Grid>
								<Grid item xs={8}>
									<SquareOptions
										compact
										data={imgCornerOptions}
										getChange={imgCornerChange}
										activeValue={imgCorner}
									/>
								</Grid>
							</Grid>
						</>
					)}
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.empty')}
					expanded={true}
				>
					<EmptyOptions
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.more')}
					expanded={true}
				>
					<MoreOptions
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.search')}
					expanded={true}
				>
					<SearchOptions
						source={source}
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.inAppFilter')}
					expanded={false}
				>
					{source && (
						<FilterModalOptions
							source={source}
							activeScreen={props.activeScreen}
							elementData={props.elementData}
							entities={props.entities}
							sorting={props.sorting}
							filtering={props.filtering}
							updateSelectedElement={props.updateSelectedElement}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.actions')}
					expanded={true}
				>
					{source && (
						<ActionList
							name={elementName}
							entities={props.entities}
							activeScreen={props.activeScreen}
							actions={props.elementData.actions}
							updateActions={updateActions}
							isList={true}
							listSource={source}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default List

import { ApiClient } from './client'

import { default as appConfig } from '../config/app.json'

const templateDomain = `${appConfig.domains.platformDomain}/api/templates`

export default {
	async getCategories() {
		let url = '/categories'

		return await new ApiClient(templateDomain, {}).get(url)
	},
	async getTags() {
		let url = '/tags'

		return await new ApiClient(templateDomain, {}).get(url)
	},
	async getSubmitHistory(appId, headers = {}) {
		headers['AppId'] = appId

		const url = `/submithistory/${appId}`

		return await new ApiClient(templateDomain, headers).get(url)
	},
	async submitTemplate(appId, data = null, headers = {}) {
		headers['Content-Type'] = 'application/json'
		headers['AppId'] = appId
		const url = `/submit/${appId}`

		return await new ApiClient(templateDomain, headers).post(
			url,
			JSON.stringify(data)
		)
	}
}

import React, { useEffect, useState, useRef } from 'react'
import { CardMedia, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import cx from 'classnames'
import IconButton from '@mui/material/IconButton'
import { Close } from '@mui/icons-material'

import IntegrationSettings from '../../organisms/integrationSettings/IntegrationSettings'

import appClient from '../../../services/appClient'
import { setDetail } from '../../../redux/actions/appDataActions'

import { default as KozmikTablesAPI } from '../../../assets/icons/Integrations/KozmikTablesAPI.svg'
import { default as GoogleMaps } from '../../../assets/icons/Integrations/GoogleMaps.svg'
import { default as CallToAPI } from '../../../assets/icons/Integrations/CallToAPI.svg'
import { default as OpenAI } from '../../../assets/icons/Integrations/OpenAI.svg'
import { default as MSSQL } from '../../../assets/icons/Integrations/MSSQL.svg'
import { default as MySQL } from '../../../assets/icons/Integrations/MySQL.svg'
import { default as PostgreSQL } from '../../../assets/icons/Integrations/PostgreSQL.svg'
import { default as PushNotifications } from '../../../assets/icons/Integrations/PushNotifications.svg'
import { default as PlayStore } from '../../../assets/icons/Integrations/PlayStore.svg'
import { default as AppStore } from '../../../assets/icons/Integrations/AppStore.svg'
import { default as CustomDomain } from '../../../assets/icons/Integrations/CustomDomain.svg'

const useStyles = makeStyles((theme) => ({
	mb20: {
		marginBottom: 20
	},
	mb10: {
		marginBottom: 10
	},
	mainContainer: {
		width: '100%',
		padding: '20px 40px 20px 20px',
		[theme.breakpoints.down('md')]: {
			padding: 20
		},
		[theme.breakpoints.up('md')]: {
			height: '100vh',
			overflow: 'auto'
		}
	},
	groups: {
		marginTop: 10,
		marginBottom: 30
	},
	group: {
		marginLeft: 10,
		padding: '7px 20px',
		fontSize: 14,
		lineHeight: '22px',
		fontWeight: '600',
		textAlign: 'center',
		cursor: 'pointer',
		color: theme.custom.colors.darkGray,
		border: `1px solid transparent`,
		[theme.breakpoints.down('md')]: {
			padding: '7px 10px'
		}
	},
	activeGroup: {
		color: '#000000',
		backgroundColor: '#FFFFFF',
		border: `1px solid ${theme.custom.colors.gray}`,
		borderRadius: '4px'
	},
	boxes: {},
	boxContainer: {
		paddingLeft: 10,
		paddingRight: 10,
		paddingBottom: 20
	},
	box: {
		position: 'relative',
		backgroundColor: '#FFFFFF',
		borderRadius: '16px',
		padding: 25,
		'&:hover': {
			boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.17)'
		},
		cursor: 'pointer'
	},
	boxClose: {
		position: 'absolute',
		backgroundColor: '#9A9CAD',
		color: '#FFFFFF',
		borderRadius: '8px',
		fontSize: 28,
		top: 25,
		right: 25,
		'&:hover': {
			backgroundColor: '#9A9CAD'
		}
	},
	boxIcon: {
		width: 'auto',
		height: 50
	},
	boxTitle: {
		height: 20,
		fontSize: 16,
		fontWeight: 600
	},
	boxSubTitle: {
		height: 60,
		fontSize: 14,
		overflow: 'hidden'
	},
	badge: {
		fontSize: 9,
		fontWeight: 'bold',
		borderRadius: 7.5,
		padding: '2px 8px',
		position: 'absolute',
		top: 25,
		right: 25
	},
	activeBadgePosition: {
		top: 25,
		left: 100,
		right: 'unset'
	},
	backgroundGradient: {
		backgroundImage:
			'linear-gradient(90deg, #96f1ce 50%, rgba(27,54,254,0.3) 100%)'
	}
}))

const AppIntegrationsTemplate = (props) => {
	const { t } = useTranslation()
	const classes = useStyles(props)
	let { appId } = useParams()

	const containerRef = useRef(null)

	const integrations = [
		{
			name: 'kozmikTablesAPI',
			icon: KozmikTablesAPI,
			title: t('appIntegrationsTemp.integrations.kozmikTablesAPI.title'),
			description: t(
				'appIntegrationsTemp.integrations.kozmikTablesAPI.description'
			),
			badge: null,
			groups: ['all', 'data'],
			plans: ['business']
		},
		{
			name: 'googleMaps',
			icon: GoogleMaps,
			title: t('appIntegrationsTemp.integrations.googleMaps.title'),
			description: t('appIntegrationsTemp.integrations.googleMaps.description'),
			badge: null,
			groups: ['all', 'communication'],
			plans: ['pro', 'business']
		},
		{
			name: 'appStore',
			icon: AppStore,
			title: t('appIntegrationsTemp.integrations.appStore.title'),
			description: t('appIntegrationsTemp.integrations.appStore.description'),
			badge: null,
			groups: ['all', 'store'],
			plans: ['pro', 'business']
		},
		{
			name: 'playStore',
			icon: PlayStore,
			title: t('appIntegrationsTemp.integrations.playStore.title'),
			description: t('appIntegrationsTemp.integrations.playStore.description'),
			badge: null,
			groups: ['all', 'store'],
			plans: ['pro', 'business']
		},
		{
			name: 'webPush',
			icon: PushNotifications,
			title: t('appIntegrationsTemp.integrations.webPush.title'),
			description: t('appIntegrationsTemp.integrations.webPush.description'),
			badge: null,
			groups: ['all'],
			plans: ['pro', 'business']
		},
		{
			name: 'customDomain',
			icon: CustomDomain,
			title: t('appIntegrationsTemp.integrations.customDomain.title'),
			description: t(
				'appIntegrationsTemp.integrations.customDomain.description'
			),
			badge: null,
			groups: ['all'],
			plans: ['starter', 'pro', 'business']
		},
		{
			name: 'callToAPI',
			icon: CallToAPI,
			title: t('appIntegrationsTemp.integrations.callToAPI.title'),
			description: t('appIntegrationsTemp.integrations.callToAPI.description'),
			badge: {
				// 	background: '#78FFAA',
				background: '#F8D169',
				color: '#000000',
				text: t('appIntegrationsTemp.badges.soon')
			},
			groups: ['all', 'data', 'communication', 'flow'],
			plans: ['business'],
			disabled: true
		},
		{
			name: 'openAI',
			icon: OpenAI,
			title: t('appIntegrationsTemp.integrations.openAI.title'),
			description: t('appIntegrationsTemp.integrations.openAI.description'),
			badge: {
				background: '#F8D169',
				color: '#000000',
				text: t('appIntegrationsTemp.badges.soon')
			},
			groups: ['all', 'data', 'communication'],
			plans: ['business'],
			disabled: true
		}
	]

	const [integration, setIntegration] = useState(null)

	const integrationDetail = integrations.find((x) => x.name === integration)

	const groups = [
		{
			name: 'all',
			title: t('appIntegrationsTemp.groups.all')
		},
		{
			name: 'data',
			title: t('appIntegrationsTemp.groups.data')
		},
		{
			name: 'communication',
			title: t('appIntegrationsTemp.groups.communication')
		},
		{
			name: 'flow',
			title: t('appIntegrationsTemp.groups.flow')
		},
		{
			name: 'store',
			title: t('appIntegrationsTemp.groups.store')
		}
	]

	const [group, setGroup] = useState('all')

	const filteredIntegrations = group
		? integrations.filter((x) => x.groups.includes(group))
		: integrations

	const initialization = () => {}

	/****INITIALIZATION***************************/
	useEffect(() => {
		props.appDetail && initialization()
	}, [props.appDetail])

	return (
		<div className={classes.mainContainer}>
			<Grid container ref={containerRef}>
				<Grid container item xs={12} className={classes.groups}>
					{groups.map((i, index) => (
						<Typography
							key={index}
							className={cx(
								classes.group,
								group === i.name && classes.activeGroup
							)}
							onClick={() => {
								setGroup(i.name)
								setIntegration(null)
							}}
						>
							{i.title}
						</Typography>
					))}
				</Grid>

				<Grid container item xs={12} className={classes.boxes}>
					{integrationDetail && (
						<Grid
							container
							item
							lg={12}
							xs={12}
							className={classes.boxContainer}
						>
							<Grid container className={classes.box}>
								<Grid container item xs={12} lg={12}>
									<Grid item xs={12} className={classes.mb20}>
										<CardMedia
											image={integrationDetail.icon}
											className={classes.boxIcon}
											component='img'
										/>
										<IconButton
											onClick={() => setIntegration(null)}
											className={classes.boxClose}
										>
											<Close />
										</IconButton>

										{integrationDetail.badge && (
											<span
												className={cx(
													classes.badge,
													classes.activeBadgePosition
												)}
												style={{
													backgroundColor: integrationDetail.badge?.background,
													color: integrationDetail.badge?.color
												}}
											>
												{integrationDetail.badge?.text}
											</span>
										)}
									</Grid>
									<Grid item xs={12} className={classes.mb10}>
										<Typography className={classes.boxTitle}>
											{integrationDetail.title}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography className={classes.boxSubTitle}>
											{integrationDetail.description}
										</Typography>
									</Grid>
								</Grid>
								<Grid item xs={12} lg={6}>
									<IntegrationSettings integration={integration} />
								</Grid>
							</Grid>
						</Grid>
					)}

					{filteredIntegrations.map((integration, index) => {
						const authority = integration.plans.some((x) =>
							props?.currentOrg?.plan?.includes(x)
						)

						return (
							<Grid
								key={index}
								container
								item
								xs={12}
								sm={6}
								md={4}
								lg={3}
								className={classes.boxContainer}
							>
								<Grid
									container
									className={classes.box}
									onClick={() => {
										if (integration.disabled) return

										if (authority) {
											setIntegration(integration.name)
											containerRef.current &&
												containerRef.current.scrollIntoView({
													behavior: 'smooth'
												})
										} else {
											window.location.href = '/subscription?modal=true'
										}
									}}
								>
									<Grid item xs={12} className={classes.mb20}>
										<CardMedia
											image={integration.icon}
											className={classes.boxIcon}
											component='img'
										/>

										{!authority ? (
											<span
												className={cx(
													classes.badge,
													classes.backgroundGradient
												)}
											>
												{t('appIntegrationsTemp.upgrade')}
											</span>
										) : (
											integration.badge && (
												<span
													className={cx(classes.badge)}
													style={{
														backgroundColor: integration.badge?.background,
														color: integration.badge?.color
													}}
												>
													{integration.badge?.text}
												</span>
											)
										)}
									</Grid>
									<Grid item xs={12} className={classes.mb10}>
										<Typography className={classes.boxTitle}>
											{integration.title}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography className={classes.boxSubTitle}>
											{integration.description}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						)
					})}
				</Grid>
			</Grid>
		</div>
	)
}

const mapStateToProps = (state) => ({
	...state.appData,
	...state.userInfo,
	...state.currentOrg
})

const mapDispatchToProps = (dispatch) => {
	return {
		setDetail: (x) => dispatch(setDetail(x))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AppIntegrationsTemplate)

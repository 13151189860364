import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Autocomplete, InputAdornment } from '@mui/material'
import { KeyboardArrowDown, TipsAndUpdatesOutlined } from '@mui/icons-material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

import BorderlessTextField from '../../atoms/borderlessTextField/BorderlessTextField'
import Dropdown from '../dropdown/Dropdown'

const useStyles = makeStyles((theme) => ({
	autocomplete: {
		'& .MuiInputBase-adornedStart': {
			paddingLeft: '0px !important',
			overflow: 'hidden'
		}
	},
	adornment: {
		height: 38,
		backgroundColor: '#F0F0F4',
		padding: '6px 10px',
		color: theme.custom.colors.textPrimary,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	adornmentActive: {
		height: 38,
		backgroundColor: theme.custom.colors.kozmikLightBlue,
		padding: '6px 10px',
		color: theme.custom.colors.kozmikBlue,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	inputIcon: {
		color: 'gray',
		fontSize: 16
	},
	inputIconActive: {
		color: theme.custom.colors.kozmikBlue,
		fontSize: 16
	},
	dateBox: {
		overflow: 'hidden',
		borderRadius: '3px !important',
		backgroundColor: (props) => (props?.bgColor ? props.bgColor : '#FAFAFC'),
		display: 'flex'
	},
	dateTextBox: {
		display: 'flex',
		flex: 1,
		cursor: 'pointer',
		alignItems: 'center',
		padding: '0 10px',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis'
	}
}))

const DoubleStateDate = (props) => {
	const {
		includeTime,
		emptyText,
		valChange,
		dateVal,
		autoCompOptions,
		autoCompVal,
		isDateMode,
		setIsDateMode,
		valueKey,
		labelKey,
		callback,
		undefinedText,
		bgColor,
		icon,
		languageCode,
		dataType,
		...restProps
	} = props

	const classes = useStyles(props)

	const [open, setOpen] = useState(false)
	const closeDropdown = () => setOpen(false)
	const openDropdown = () => setOpen(true)

	const switchButton = (textMode, callbackFunc = null) => {
		setIsDateMode(textMode)
		if (callbackFunc) {
			if (textMode) {
				callbackFunc(textMode, dateVal)
			} else {
				callbackFunc(textMode, autoCompVal)
			}
		}
	}

	return isDateMode ? (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			{!includeTime ? (
				<DatePicker
					value={dateVal}
					onChange={(newValue) => {
						valChange(newValue)
					}}
					renderInput={(params) => {
						const dateValue = new Date(dateVal)
						return (
							<div className={classes.dateBox}>
								<Box
									className={classes.adornmentActive}
									onClick={() =>
										switchButton(false, callback ? callback : null)
									}
								>
									{icon ? (
										<props.icon className={classes.inputIconActive} />
									) : (
										<TipsAndUpdatesOutlined
											className={classes.inputIconActive}
										/>
									)}
								</Box>
								<Box
									onClick={
										params?.InputProps?.endAdornment?.props?.children?.props
											?.onClick
									}
									ref={params?.inputRef}
									className={classes.dateTextBox}
								>
									{dateVal
										? dateValue.toLocaleDateString(languageCode)
										: emptyText}
								</Box>
								{/* <div>{params?.InputProps?.endAdornment}</div> */}
							</div>
						)
					}}
					{...props}
				/>
			) : (
				<DateTimePicker
					value={dateVal}
					onChange={(newValue) => {
						valChange(newValue)
					}}
					renderInput={(params) => {
						const dateValue = new Date(dateVal)
						return (
							<div className={classes.dateBox}>
								<Box
									className={classes.adornmentActive}
									onClick={() =>
										switchButton(false, callback ? callback : null)
									}
								>
									{icon ? (
										<props.icon className={classes.inputIconActive} />
									) : (
										<TipsAndUpdatesOutlined
											className={classes.inputIconActive}
										/>
									)}
								</Box>
								<Box
									onClick={
										params?.InputProps?.endAdornment?.props?.children?.props
											?.onClick
									}
									ref={params?.inputRef}
									className={classes.dateTextBox}
								>
									{dateVal
										? `${dateValue.toLocaleDateString(
												languageCode
										  )} - ${dateValue.toLocaleTimeString(languageCode)}`
										: emptyText}
								</Box>
								{/* <div>{params?.InputProps?.endAdornment}</div> */}
							</div>
						)
					}}
					{...props}
				/>
			)}
		</LocalizationProvider>
	) : (
		<Dropdown
			bgColor={bgColor}
			onItemClick={valChange}
			value={autoCompVal}
			data={autoCompOptions}
			textKey={labelKey}
			valueKey={valueKey}
			groupKey={labelKey}
			groupKeySeperator='.'
			startAdornment={
				<Box
					className={classes.adornment}
					onClick={() => switchButton(true, callback ? callback : null)}
				>
					{icon ? (
						<props.icon className={classes.inputIcon} />
					) : (
						<TipsAndUpdatesOutlined className={classes.inputIcon} />
					)}
				</Box>
			}
		/>
	)
}
export default DoubleStateDate

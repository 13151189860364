import i18next from '../../../i18n/i18n'

const dataTypeRegExes = {
	RowId: {
		re: /^.{0,250}$/,
		msg: i18next.t('screens.elementSettings.dataTypeRegExp.Text')
	},
	Text: {
		re: /^.{0,250}$/,
		msg: i18next.t('screens.elementSettings.dataTypeRegExp.Text')
	},
	LongText: undefined,
	Integer: {
		re: {
			tr: /(^\d+$)|(^-?\d{1,3}(?:\.\d{3})*?$)/,
			en: /(^\d+$)|(^-?\d{1,3}(?:\,\d{3})*?$)/
		},
		msg: i18next.t('screens.elementSettings.dataTypeRegExp.Number')
	},
	Checkbox: undefined,
	Number: {
		re: {
			tr: /(^-?[0-9]{1,3}(?:\.[0-9]{3})*(?:\,[0-9]{1,6})?$)|(^-?[0-9]*(?:\,[0-9]{1,6})?$)/,
			en: /(^-?[0-9]{1,3}(?:\,[0-9]{3})*(?:\.[0-9]{1,6})?$)|(^-?[0-9]*(?:\.[0-9]{1,6})?$)/
		},
		msg: i18next.t('screens.elementSettings.dataTypeRegExp.Money')
	},
	Image: {
		re: /^(ftp|http|https):\/\/[^ "]+$/,
		msg: i18next.t('screens.elementSettings.dataTypeRegExp.Image')
	},
	File: {
		re: /^(ftp|http|https):\/\/[^ "]+$/,
		msg: i18next.t('screens.elementSettings.dataTypeRegExp.File')
	},
	Datetime: undefined,
	Date: undefined,
	Email: {
		re: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		msg: i18next.t('screens.elementSettings.dataTypeRegExp.Email')
	},
	Phone: {
		re: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
		msg: i18next.t('screens.elementSettings.dataTypeRegExp.Phone')
	},
	URL: {
		re: /^(ftp|http|https):\/\/[^ "]+$/,
		msg: i18next.t('screens.elementSettings.dataTypeRegExp.URL')
	},
	GeoLocation: {
		re: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s*,\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
		msg: i18next.t('screens.elementSettings.dataTypeRegExp.GeoLocation')
	}
}

export default dataTypeRegExes

import React, { useEffect, useState } from 'react'
import { CardMedia, Grid, Typography, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import cx from 'classnames'

import KozmikTextField from '../../../atoms/kozmikTextField/KozmikTextField'
import KozmikFilePicker from '../../../atoms/kozmikFilePicker/KozmikFilePicker'

import timeoutDelay from '../../../../methods/timeoutDelay'

import appClient from '../../../../services/appClient'
import storeSettingClient from '../../../../services/storeSettingClient'
import showToastResponseError from '../../../../methods/showToastResponseError'

import { setDetail } from '../../../../redux/actions/appDataActions'

const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%',
		paddingTop: 20,
		paddingBottom: 20
	},
	contentTitle: {
		marginBottom: 10,
		fontSize: 16,
		fontWeight: '600'
	},
	contentDesc: {
		marginBottom: 15,
		fontSize: 14
	},
	subTitle: {
		fontSize: 12,
		fontWeight: '600'
	},
	input: {
		fontSize: 14,
		backgroundColor: theme.custom.colors.kozmikGray,
		borderRadius: 6,
		border: 'none',
		'& .MuiOutlinedInput-root': {
			padding: 0
		},
		'& .MuiOutlinedInput-adornedStart': {
			paddingLeft: 0
		},
		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: 0
		}
	}
}))

const AppStore = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	let { appId } = useParams()

	const chooseText = t('publishModule.androidModal.chooseFile')

	const [setting, setSetting] = useState(null)

	/****BUNDLE_ID***************************/
	const [bundleId, setBundleId] = useState('')
	const changeBundleId = (e) => {
		setBundleId(e.target.value)

		timeoutDelay(
			updateSetting,
			{
				IOSBundleId: e.target.value
			},
			1000
		)
	}

	/****ISSUER_ID***************************/
	const [issuerId, setIssuerId] = useState('')
	const changeIssuerId = (e) => {
		setIssuerId(e.target.value)

		timeoutDelay(
			updateSetting,
			{
				IOSConnectIssuerId: e.target.value
			},
			1000
		)
	}

	/****TEAM_ID***************************/
	const [teamId, setTeamId] = useState('')
	const changeTeamId = (e) => {
		setTeamId(e.target.value)

		timeoutDelay(
			updateSetting,
			{
				IOSTeamId: e.target.value
			},
			1000
		)
	}

	/****APNS_KEY_ID***************************/
	const [apnsKeyId, setAPNsKeyId] = useState('')
	const changeAPNsKeyId = (e) => {
		setAPNsKeyId(e.target.value)

		timeoutDelay(
			updateSetting,
			{
				IOSAPNsKeyId: e.target.value
			},
			1000
		)
	}

	/****APNS_KEY_FILE***************************/
	const [apnsKeyFile, setAPNsKeyFile] = useState(null)
	const [apnsKeyFileName, setAPNsKeyFileName] = useState(chooseText)
	const apnsKeyFileOnChange = (fileObject) => {
		setAPNsKeyFileName(fileObject?.name)
		setAPNsKeyFile(fileObject?.base64File)

		updateSetting({
			IOSAPNsKeyFile: fileObject?.base64File
		})
	}

	/****CONNECT_KEY_FILE***************************/
	const [connectKeyFile, setConnectKeyFile] = useState(null)
	const [connectKeyFileName, setConnectKeyFileName] = useState(chooseText)
	const connectKeyFileOnChange = (fileObject) => {
		setConnectKeyFileName(fileObject?.name)
		setConnectKeyFile(fileObject?.base64File)

		updateSetting({
			IOSConnectKeyFile: fileObject?.base64File
		})
	}

	/****CONNECT_KEY_ID***************************/
	const [connectKeyId, setConnectKeyId] = useState('')
	const changeConnectKeyId = (e) => {
		setConnectKeyId(e.target.value)

		timeoutDelay(
			updateSetting,
			{
				IOSConnectKeyId: e.target.value
			},
			1000
		)
	}

	/****IOS_APP_ID***************************/
	const [iosAppId, setIosAppId] = useState('')
	const changeIosAppId = (e) => {
		setIosAppId(e.target.value)

		timeoutDelay(
			updateSetting,
			{
				IOSAppId: e.target.value
			},
			1000
		)
	}

	const getSetting = () => {
		storeSettingClient.getSetting(appId).then((response) => {
			if (response?.data?.success) {
				setSetting(response?.data?.data)
			} else {
				showToastResponseError(response)
			}
		})
	}

	const updateSetting = (data) => {
		storeSettingClient
			.updateSetting(appId, data)
			.then((response) => {
				if (response?.data?.success) {
					setSetting({ ...setting, ...data })
				} else {
					setSetting(setting)
					showToastResponseError(response)
				}
			})
			.catch((error) => {
				setSetting(setting)
			})
	}

	const initializationSetting = (setting) => {
		/****CONNECT_KEY_FILE***************************/
		setConnectKeyFile(setting?.IOSConnectKeyFile || null)
		setConnectKeyFileName(
			setting?.IOSConnectKeyFile
				? t('publishModule.iosModal.connectKeyFilePlaceholder')
				: chooseText
		)
		/****CONNECT_KEY_ID***************************/
		setConnectKeyId(setting?.IOSConnectKeyId || '')
		/****CONNECT_ISSUER_ID***************************/
		setIssuerId(setting?.IOSConnectIssuerId || '')
		/****BUNDLE_ID***************************/
		setBundleId(setting?.IOSBundleId || '')
		/****IOS_APP_ID***************************/
		setIosAppId(setting?.IOSAppId || '')

		/****APNs_KEY_FILE***************************/
		setAPNsKeyFile(setting?.IOSAPNsKeyFile || null)
		setAPNsKeyFileName(
			setting?.IOSAPNsKeyFile
				? t('publishModule.iosModal.apnsKeyFilePlaceholder')
				: chooseText
		)
		/****APNs_KEY_ID***************************/
		setAPNsKeyId(setting?.IOSAPNsKeyId || '')
		/****TEAM_ID***************************/
		setTeamId(setting?.IOSTeamId || '')
	}

	useEffect(() => {
		setting && initializationSetting(setting)
	}, [setting])

	useEffect(() => {
		getSetting()
	}, [])

	return (
		<div className={classes.container}>
			<Typography className={classes.contentTitle}>
				{t('publishModule.iosModal.credentialsTitle')}
			</Typography>
			<Typography className={classes.contentDesc}>
				{t('publishModule.iosModal.credentialsDesc')}
			</Typography>

			<Box style={{ marginBottom: 10 }}>
				<Typography className={classes.subTitle}>
					{t('publishModule.iosModal.bundleId')}
				</Typography>
				<KozmikTextField
					className={classes.input}
					fullWidth
					placeholder={t('publishModule.iosModal.bundleIdPlaceholder')}
					style={{ backgroundColor: '#FAFAFC', overflow: 'hidden' }}
					value={bundleId}
					onChange={changeBundleId}
				/>
			</Box>

			<Box style={{ marginBottom: 10 }}>
				<Typography className={classes.subTitle}>
					{t('publishModule.iosModal.appId')}
				</Typography>
				<KozmikTextField
					className={classes.input}
					fullWidth
					placeholder={t('publishModule.iosModal.appIdPlaceholder')}
					style={{ backgroundColor: '#FAFAFC', overflow: 'hidden' }}
					value={iosAppId}
					onChange={changeIosAppId}
				/>
			</Box>

			<Box style={{ marginBottom: 10 }}>
				<Typography className={classes.subTitle}>
					{t('publishModule.iosModal.connectKeyFile')}
				</Typography>
				<KozmikFilePicker
					onChange={connectKeyFileOnChange}
					fileName={connectKeyFileName}
					accept='.p8'
				/>
			</Box>

			<Box style={{ marginBottom: 10 }}>
				<Typography className={classes.subTitle}>
					{t('publishModule.iosModal.connectKeyId')}
				</Typography>
				<KozmikTextField
					className={classes.input}
					fullWidth
					placeholder={t('publishModule.iosModal.connectKeyIdPlaceholder')}
					style={{ backgroundColor: '#FAFAFC', overflow: 'hidden' }}
					value={connectKeyId}
					onChange={changeConnectKeyId}
				/>
			</Box>

			<Box style={{ marginBottom: 30 }}>
				<Typography className={classes.subTitle}>
					{t('publishModule.iosModal.issuerId')}
				</Typography>
				<KozmikTextField
					className={classes.input}
					fullWidth
					placeholder={t('publishModule.iosModal.issuerIdPlaceholder')}
					style={{ backgroundColor: '#FAFAFC', overflow: 'hidden' }}
					value={issuerId}
					onChange={changeIssuerId}
				/>
			</Box>

			<Box style={{ marginBottom: 10 }}>
				<Typography className={classes.contentTitle}>
					{t('publishModule.iosModal.pushCredentialsTitle')}
				</Typography>
				<Typography className={classes.contentDesc}>
					{t('publishModule.iosModal.pushCredentialsDesc')}
				</Typography>
			</Box>

			<Box style={{ marginBottom: 10 }}>
				<Typography className={classes.subTitle}>
					{t('publishModule.iosModal.apnsKeyFile')}
				</Typography>
				<KozmikFilePicker
					onChange={apnsKeyFileOnChange}
					fileName={apnsKeyFileName}
					accept='.p8'
				/>
			</Box>
			<Box style={{ marginBottom: 10 }}>
				<Typography className={classes.subTitle}>
					{t('publishModule.iosModal.apnsKeyId')}
				</Typography>
				<KozmikTextField
					className={classes.input}
					fullWidth
					placeholder={t('publishModule.iosModal.apnsKeyIdPlaceholder')}
					style={{ backgroundColor: '#FAFAFC', overflow: 'hidden' }}
					value={apnsKeyId}
					onChange={changeAPNsKeyId}
				/>
			</Box>

			<Box style={{ marginBottom: 10 }}>
				<Typography className={classes.subTitle}>
					{t('publishModule.iosModal.teamId')}
				</Typography>
				<KozmikTextField
					className={classes.input}
					fullWidth
					placeholder={t('publishModule.iosModal.teamIdPlaceholder')}
					style={{ backgroundColor: '#FAFAFC', overflow: 'hidden' }}
					value={teamId}
					onChange={changeTeamId}
				/>
			</Box>
		</div>
	)
}

const mapStateToProps = (state) => ({
	...state.appData
})

const mapDispatchToProps = (dispatch) => {
	return {
		setDetail: (x) => dispatch(setDetail(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AppStore)

import React, { useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Box } from '@mui/material'
import { TipsAndUpdatesOutlined } from '@mui/icons-material'
import cx from 'classnames'

import { default as kozmikTheme } from '../../../assets/theme/theme.json'

import MentionsInput from '../../atoms/mentionsInput/MentionsInput'
import Dropdown from '../dropdown/Dropdown'

const useStyles = makeStyles((theme) => ({
	tagsContainer: {
		display: 'flex',
		justifyContent: 'end',
		flexFlow: 'row',
		alignItems: 'flex-start',
		borderRadius: 3,
		overflow: 'hidden'
	},
	tagsInputContainer: {
		overflow: 'hidden',
		flexGrow: 1
	},
	adornment: {
		height: 38,
		backgroundColor: '#F0F0F4',
		padding: '6px 10px',
		color: theme.custom.colors.textPrimary,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	adornmentActive: {
		height: 38,
		backgroundColor: theme.custom.colors.kozmikLightBlue,
		padding: '6px 10px',
		color: theme.custom.colors.kozmikBlue,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	inputIcon: {
		color: 'gray',
		fontSize: 16
	},
	inputIconActive: {
		color: theme.custom.colors.kozmikBlue,
		fontSize: 16
	},
	errorText: {
		color: '#d32f2f',
		fontSize: 12,
		margin: 0
	}
}))

const DoubleStateMentions = (props) => {
	const {
		textError,
		errorText,
		valChange,
		textVal,
		autoCompOptions,
		autoCompVal,
		autoCompEmptyText,
		isTextMode,
		setIsTextMode,
		valueKey,
		labelKey,
		callback,
		undefinedText,
		bgColor,
		icon,
		textModePlaceholder,
		uppyPicker,
		uppyAllowedFileTypes,
		fieldDataType,
		multiline,
		...restProps
	} = props

	const classes = useStyles(props)

	const [open, setOpen] = useState(false)
	const closeDropdown = () => setOpen(false)
	const openDropdown = () => setOpen(true)

	const switchButton = (textMode, callbackFunc = null) => {
		setIsTextMode(textMode)
		if (callbackFunc) {
			if (textMode) {
				callbackFunc(textMode, textVal)
			} else {
				callbackFunc(textMode, autoCompVal)
			}
		}
	}

	const tagOptions = autoCompOptions
		?.filter((item) => item.name?.length > 0)
		.map((item) => ({
			id: item.id,
			name: item.name,
			label: item.label,
			dataType: item.dataType
		}))

	return isTextMode ? (
		<>
			<div className={classes.tagsContainer}>
				<Box
					className={cx(classes.adornmentActive)}
					onClick={() => switchButton(false, callback ? callback : null)}
				>
					{icon ? (
						<props.icon className={classes.inputIconActive} />
					) : (
						<TipsAndUpdatesOutlined className={classes.inputIconActive} />
					)}
				</Box>
				<div className={classes.tagsInputContainer}>
					<MentionsInput
						name={props.name}
						inputStyle={{
							backgroundColor: props?.bgColor
								? props.bgColor
								: kozmikTheme.colors.kozmikGray,
							borderRadius: 3,
							border: 'none',
							color: kozmikTheme.colors.textPrimary
						}}
						placeholder={textModePlaceholder || ''}
						whitelist={tagOptions}
						onChange={valChange}
						value={textVal}
						multiline={multiline}
						{...restProps}
					></MentionsInput>
				</div>
			</div>
		</>
	) : (
		<Dropdown
			bgColor={bgColor}
			onItemClick={valChange}
			value={autoCompVal}
			data={autoCompOptions}
			textKey={labelKey}
			valueKey={valueKey}
			groupKey={labelKey}
			groupKeySeperator='.'
			startAdornment={
				<Box
					className={classes.adornment}
					onClick={() => switchButton(true, callback ? callback : null)}
				>
					{icon ? (
						<props.icon className={classes.inputIcon} />
					) : (
						<TipsAndUpdatesOutlined className={classes.inputIcon} />
					)}
				</Box>
			}
		/>
	)
}
export default DoubleStateMentions

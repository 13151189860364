import { v4 as uuidv4 } from 'uuid'

// import i18next from '../../i18n/i18n'
import languages from '../../i18n/languages'

const method = (fieldData) => {
	let object = {
		_uid: uuidv4(),
		component: 'inputNumeric',
		mapping: {
			field: fieldData.name,
			type: fieldData.dataType
		},
		title: fieldData.label,
		placeholder: {
			default: languages.en.schemas.inputNumeric.placeholder
		},
		required: {
			status: fieldData?.isRequired || false,
			text: {
				default: languages.en.schemas.inputNumeric.required
			}
		},
		errorText: {
			default: languages.en.schemas.inputNumeric.error
		},
		maxVal: null,
		minVal: null,
		spacing: 'xl',
		regExp: null,
		maxLength: null,
		minLength: null,
		mask: null,
		scanner: false
	}

	return object
}

export default method

import React, { useState, useRef } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Box } from '@mui/material'
import { Cancel, Add } from '@mui/icons-material'

import convertBase64 from '../../../methods/convertBase64'

const useStyles = makeStyles((theme) => ({
	aspectRatioContainer: {
		height: 0,
		width: '100%',
		paddingTop: '177.77%', // 9/16
		position: 'relative'
	},
	card: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		'&:hover': {
			boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.17)'
		},
		borderRadius: 3,
		backgroundColor: theme.custom.colors.kozmikGray,
		padding: 22,
		zIndex: 2,
		cursor: 'pointer'
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'contain'
	},
	addBox: {
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
}))

const TemplateImageItem = (props) => {
	const classes = useStyles()

	const [imageSrc, setImageSrc] = useState(props.image)

	const picker = useRef(null)
	const pickerClickHandler = () => {
		picker.current.click()
	}

	const pickImage = async (e) => {
		if (e.target.files[0]) {
			await convertBase64(e.target.files[0]).then((result) => {
				props.setImage(result.data)
				const imageUrl = URL.createObjectURL(e.target.files[0])
				setImageSrc(imageUrl)
			})
		}
	}

	const clearPicker = (e) => {
		e.stopPropagation()
		picker.current.value = null
		props.setImage(null)
		setImageSrc(null)
	}

	return (
		<>
			<input
				ref={picker}
				// refs='file'
				accept={props?.accept || '.jpg,.jpeg,.png'}
				style={{ display: 'none' }}
				type='file'
				onChange={(e) => {
					pickImage(e)
				}}
			/>
			<Box className={classes.aspectRatioContainer}>
				<Box
					elevation={0}
					className={classes.card}
					onClick={pickerClickHandler}
				>
					<Cancel
						style={{
							position: 'absolute',
							right: 5,
							top: 5,
							cursor: 'pointer'
						}}
						onClick={clearPicker}
					/>
					{imageSrc ? (
						<img src={imageSrc} className={classes.image} />
					) : (
						<Box className={classes.addBox}>
							<Add
								style={{
									width: 50,
									height: 50
								}}
							/>
						</Box>
					)}
				</Box>
			</Box>
		</>
	)
}

export default TemplateImageItem

import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import update from 'immutability-helper'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { SwapVert, FilterAltOutlined, PlusOne } from '@mui/icons-material'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateText from '../../../molecules/doubleStateField/DoubleStateText'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import Accordion from '../../../atoms/accordion/Accordion'
import NumericInput from '../../../atoms/numericInput/NumericInput'
import { default as Checkbox } from '../../../atoms/kozmikCheckBox/KozmikCheckBox'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import { default as Slider } from '../../../atoms/kozmikSlider/KozmikSlider'
import KozmikTextField from '../../../atoms/kozmikTextField/KozmikTextField'
import AccordionItem from '../../../atoms/accordionItem/AccordionItem'
import FilterList from '../../../molecules/filterList/FilterList'
import Sorting from '../../../molecules/sorting/Sorting'
import ActionList from '../../actionList/ActionList'
import FilterModalOptions from '../../../molecules/filterModalOptions/FilterModalOptions'
import SearchOptions from '../../../molecules/searchOptions/SearchOptions'
import EmptyOptions from '../../../molecules/emptyOptions/EmptyOptions'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'
import IoniconsSelect from '../../../molecules/IoniconsSelect/IoniconsSelect'

import validateWithRegExp from '../../../../methods/validateWithRegExp'
import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import relationTypes from '../../../../common/enums/relationTypes'
import defaultElementSchemas from '../../../../defaultElementSchemas'
import dataTypeRegExes from '../../../../common/regExp/dataType'

import { ReactComponent as ImageSquare } from '../../../../assets/icons/Settings/Square.svg'
import { ReactComponent as ImageRectangle } from '../../../../assets/icons/Settings/Rectangle.svg'
import { ReactComponent as ImageFull } from '../../../../assets/icons/Settings/Full.svg'

import MoreOptions from '../../../molecules/moreOptions/MoreOptions'

const useStyles = makeStyles((theme) => ({
	mb10: {
		marginBottom: 10
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	growBox: {
		flexGrow: 1
	},
	input: {
		borderRadius: 3,
		backgroundColor: '#FAFAFC',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	inputLight: {
		borderRadius: 3,
		backgroundColor: '#ffffff',
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	labelBox: {
		width: 80,
		minWidth: 80
	}
}))

const MapList = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [elementName, setElementName] = useState(null)

	const [source, setSource] = useState('')

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		fieldDataTypes.textBased,
		null,
		true
		// relationTypes.one
	)
	const textOptions = textFields?.length ? textFields : []

	const imageFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		['Image'],
		null,
		true,
		relationTypes.one
	)
	const imageOptions = imageFields?.length ? imageFields : []

	const geoLocationFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		['GeoLocation', 'Text'],
		null,
		true,
		relationTypes.one
	)
	const geoLocationOptions = geoLocationFields?.length ? geoLocationFields : []

	const appearanceOptions = [
		{
			text: t('screens.elementSettings.map.appearOpts.full'),
			value: 'full',
			icon: ImageFull
		},
		{
			text: t('screens.elementSettings.map.appearOpts.square'),
			value: 'square',
			icon: ImageSquare
		},
		{
			text: t('screens.elementSettings.map.appearOpts.rectangle'),
			value: 'rectangle',
			icon: ImageRectangle
		}
	]

	const mapTypeOptions = [
		{
			label: t('screens.elementSettings.map.typeOpts.roadmap'),
			name: 'roadmap'
		},
		{
			label: t('screens.elementSettings.map.typeOpts.satellite'),
			name: 'satellite'
		},
		{
			label: t('screens.elementSettings.map.typeOpts.hybrid'),
			name: 'hybrid'
		}
	]

	/****SOURCE***************************/
	const sourceChange = (e) => {
		submitSource(source, e.target.value)
		setSource(e.target.value)
	}

	const submitSource = (oldTableName, tableName) => {
		const table = props?.entities?.find((y) => y.name == tableName)

		const newElementData = defaultElementSchemas.mapList(
			table,
			props.appDetail?.DataSource?.UsersTableName
		)

		let modifiedElementData = update(props.elementData, {
			source: { $set: newElementData.source },
			mapping: {
				id: {
					field: { $set: newElementData.mapping?.id?.field },
					type: { $set: newElementData.mapping?.id?.type }
				},
				title: {
					field: { $set: newElementData.mapping?.title?.field },
					type: { $set: newElementData.mapping?.title?.type }
				},
				text: {
					field: { $set: newElementData.mapping?.text?.field },
					type: { $set: newElementData.mapping?.text?.type }
				},
				subText: {
					field: { $set: newElementData.mapping?.subText?.field },
					type: { $set: newElementData.mapping?.subText?.type }
				},
				markerText: {
					field: { $set: newElementData.mapping?.markerText?.field },
					type: { $set: newElementData.mapping?.markerText?.type }
				},
				imgUrl: {
					field: { $set: newElementData.mapping?.imgUrl?.field },
					type: { $set: newElementData.mapping?.imgUrl?.type }
				},
				geoLocation: {
					field: { $set: newElementData.mapping?.geoLocation?.field },
					type: { $set: newElementData.mapping?.geoLocation?.type }
				}
			},
			filters: {
				groupOp: { $set: newElementData.filters?.groupOp },
				rules: { $set: newElementData.filters?.rules }
			},
			actions: { $set: newElementData.actions }
		})

		props.updateBulkScreensWithModifiedData(
			table.name,
			null,
			modifiedElementData
		)
	}

	/****TITLE***************************/
	const [titleField, setTitleField] = useState('')
	const titleChange = (e) => {
		submitTitle(e.target.value)
		setTitleField(e.target.value)
	}
	const submitTitle = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				title: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****TEXT***************************/
	const [textField, setTextField] = useState('')
	const textChange = (e) => {
		submitText(e.target.value)
		setTextField(e.target.value)
	}
	const submitText = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				text: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****SUBTEXT***************************/
	const [subTextField, setSubTextField] = useState('')
	const subTextChange = (e) => {
		submitSubText(e.target.value)
		setSubTextField(e.target.value)
	}
	const submitSubText = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				subText: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****MARKER_TEXT***************************/
	const [markerTextField, setMarkerTextField] = useState('')
	const markerTextChange = (e) => {
		submitMarkerText(e.target.value)
		setMarkerTextField(e.target.value)
	}
	const submitMarkerText = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				markerText: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****MARKER_ICON***************************/
	const [markerIcon, setMarkerIcon] = useState('')
	const markerIconChange = (val) => {
		setMarkerIcon(val)

		const modifiedElementData = update(props.elementData, {
			markerIcon: { $set: val }
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****IMGURL***************************/
	const [imgUrlField, setImgUrlField] = useState('')
	const imgUrlChange = (e) => {
		submitImgUrl(e.target.value)
		setImgUrlField(e.target.value)
	}
	const submitImgUrl = (x) => {
		const selectedField = imageFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				imgUrl: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****GEOLOCATION***************************/
	const [geoLocationField, setGeoLocationField] = useState('')
	const geoLocationChange = (e) => {
		submitGeoLocation(e.target.value)
		setGeoLocationField(e.target.value)
	}
	const submitGeoLocation = (x) => {
		const selectedField = geoLocationFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				geoLocation: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ZOOM***************************/
	const [zoom, setZoom] = useState('')

	const zoomOptions = [
		{
			label: t('screens.elementSettings.map.zoomOpts.1x'),
			value: '1x'
		},
		{
			label: t('screens.elementSettings.map.zoomOpts.2x'),
			value: '2x'
		},
		{
			label: t('screens.elementSettings.map.zoomOpts.3x'),
			value: '3x'
		}
	]

	const zoomChange = (e) => {
		submitZoom(e.target.value)
		setZoom(e.target.value)
	}

	const submitZoom = (x) => {
		const modifiedElementData = update(props.elementData, {
			zoom: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****APPEARANCE***************************/
	const [appearance, setAppearance] = useState(null)

	const appearanceChange = (val) => {
		submitAppearance(val)
		setAppearance(val)
	}

	const submitAppearance = (x) => {
		const modifiedElementData = update(props.elementData, {
			appearance: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****APPEARANCE***************************/
	const [mapType, setMapType] = useState(null)

	const mapTypeChange = (e) => {
		submitMapType(e.target.value)
		setMapType(e.target.value)
	}

	const submitMapType = (x) => {
		const modifiedElementData = update(props.elementData, {
			mapType: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ZOOM_CONTROL***************************/
	const [zoomControl, setZoomControl] = useState(false)

	const zoomControlChange = (e) => {
		setZoomControl(e.target.checked)
		submitZoomControl(e.target.checked)
	}

	const submitZoomControl = (x) => {
		const modifiedElementData = update(props.elementData, {
			zoomControl: { $set: x }
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****SCROLLWHEEL***************************/
	const [scrollwheel, setScrollwheel] = useState(true)

	const scrollwheelChange = (e) => {
		setScrollwheel(e.target.checked)
		submitScrollwheel(e.target.checked)
	}

	const submitScrollwheel = (x) => {
		const modifiedElementData = update(props.elementData, {
			scrollwheel: { $set: x }
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****ACTIONS***************************/
	const updateActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			actions: { $set: newActions }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****LIMIT***************************/
	const [limit, setLimit] = useState(25)
	const limitChange = (val) => {
		timeoutDelay(submitLimit, val, 1000)
		setLimit(val)
	}
	const submitLimit = (x) => {
		const modifiedElementData = update(props.elementData, {
			limit: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****FILTER_&_SORT***************************/
	const [localElementData, setLocalElementData] = useState(null)

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		setSource(elementData?.source || '')
		setLimit(elementData?.limit || 10)

		/****TITLE***************************/
		setTitleField(elementData?.mapping?.title?.field || '')
		/****TEXT***************************/
		setTextField(elementData?.mapping?.text?.field || '')
		/****SUBTEXT***************************/
		setSubTextField(elementData?.mapping?.subText?.field || '')
		/****MARKER_TEXT***************************/
		setMarkerTextField(elementData?.mapping?.markerText?.field || '')
		/****IMGURL***************************/
		setImgUrlField(elementData?.mapping?.imgUrl?.field || '')
		/****GEOLOCATION***************************/
		setGeoLocationField(elementData?.mapping?.geoLocation?.field || '')
		/****APPEARANCE***************************/
		setAppearance(elementData?.appearance)
		/****ZOOM***************************/
		setZoom(elementData?.zoom)
		/****MAP_TYPE***************************/
		setMapType(elementData?.mapType)
		/****ZOOM_CONTROL***************************/
		setZoomControl(elementData?.zoomControl)
		/****SCROLL_WHEEL***************************/
		setScrollwheel(elementData?.scrollwheel)
		/****MARKER_ICON***************************/
		setMarkerIcon(elementData?.markerIcon)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
			setLocalElementData(props.elementData)
		}
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.source')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={12}>
							<SelectBox
								className={classes.input}
								onChange={sourceChange}
								value={source}
								data={props.entities}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>

					<div className={classes.mb10}>
						<AccordionItem
							icon={FilterAltOutlined}
							title={t('screens.elementSettings.mapList.filter')}
							expanded={false}
							padding={8}
						>
							<FilterList
								filter={localElementData?.filters}
								table={props?.entities?.find(
									(y) => y.name == props?.elementData?.source
								)}
								activeScreen={props?.activeScreen}
								entities={props?.entities}
								tableName={props?.elementData?.source}
								screenTable={props?.entities?.find(
									(y) => y.name == props.activeScreen?.data?.source
								)}
								filtering={props.filtering}
								setLocalElementData={setLocalElementData}
							/>
						</AccordionItem>
					</div>

					{/* 
					<div className={classes.mb10}>
						<AccordionItem
							icon={SwapVert}
							title={t('screens.elementSettings.mapList.sort')}
							expanded={false}
							padding={8}
						>
							<Sorting
								sortingData={localElementData?.sorting}
								sorting={props.sorting}
								fieldOptions={textFields}
							/>
						</AccordionItem>
					</div> */}

					<div className={classes.mb10}>
						<AccordionItem
							icon={PlusOne}
							title={t('screens.elementSettings.mapList.limit')}
							expanded={false}
							padding={8}
						>
							<NumericInput minVal={0} value={limit} onChange={limitChange} />
						</AccordionItem>
					</div>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.mapList.geoLoc')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.mapList.emptyField')}
								className={classes.input}
								onChange={geoLocationChange}
								value={geoLocationField}
								data={geoLocationOptions}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.mapList.markerText')}</Label>
						</Grid>

						<Grid container item xs={8}>
							<Grid item xs={9} lg={9} style={{ paddingRight: 10 }}>
								<SelectBox
									emptyText={t('screens.elementSettings.mapList.emptyField')}
									className={classes.input}
									onChange={markerTextChange}
									value={markerTextField}
									data={textOptions}
									textKey='label'
									valueKey='name'
								/>
							</Grid>
							<Grid item xs={3} lg={3}>
								<IoniconsSelect
									fullWidth
									onChange={markerIconChange}
									value={markerIcon}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.mapList.title')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.mapList.emptyField')}
								className={classes.input}
								onChange={titleChange}
								value={titleField}
								data={textOptions}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.mapList.desc')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.mapList.emptyField')}
								className={classes.input}
								onChange={textChange}
								value={textField}
								data={textOptions}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.mapList.subTitle')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.mapList.emptyField')}
								className={classes.input}
								onChange={subTextChange}
								value={subTextField}
								data={textOptions}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.mapList.image')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								emptyText={t('screens.elementSettings.mapList.emptyField')}
								className={classes.input}
								onChange={imgUrlChange}
								value={imgUrlField}
								data={imageOptions}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.mapList.size')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SquareOptions
								compact
								data={appearanceOptions}
								getChange={appearanceChange}
								activeValue={appearance}
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.mapList.zoom')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								className={classes.input}
								onChange={zoomChange}
								value={zoom}
								data={zoomOptions}
								textKey='label'
								valueKey='value'
							/>
						</Grid>
					</Grid>
					<Grid container alignItems='center' className={classes.mb10}>
						<Grid item xs={4}>
							<Label>{t('screens.elementSettings.mapList.mapType')}</Label>
						</Grid>
						<Grid item xs={8}>
							<SelectBox
								className={classes.input}
								onChange={mapTypeChange}
								value={mapType}
								data={mapTypeOptions}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</Grid>
					<Grid item className={classes.mb10}>
						<div className={cx(classes.item)}>
							<div>
								<Checkbox checked={zoomControl} onChange={zoomControlChange} />
							</div>
							<div className={cx(classes.labelBox, classes.growBox)}>
								<Label>
									{t('screens.elementSettings.mapList.zoomControl')}
								</Label>
							</div>
						</div>
					</Grid>
					<Grid item>
						<div className={cx(classes.item)}>
							<div>
								<Checkbox checked={scrollwheel} onChange={scrollwheelChange} />
							</div>
							<div className={cx(classes.labelBox, classes.growBox)}>
								<Label>
									{t('screens.elementSettings.mapList.scrollwheel')}
								</Label>
							</div>
						</div>
					</Grid>
				</Accordion>
			</AppPageBox>

			{/* <AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.empty')}
					expanded={true}
				>
					<EmptyOptions
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox> */}

			{/* <AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.more')}
					expanded={true}
				>
					<MoreOptions
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox> */}

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.search')}
					expanded={true}
				>
					<SearchOptions
						source={source}
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.inAppFilter')}
					expanded={false}
				>
					{source && (
						<FilterModalOptions
							source={source}
							activeScreen={props.activeScreen}
							elementData={props.elementData}
							entities={props.entities}
							sorting={props.sorting}
							filtering={props.filtering}
							updateSelectedElement={props.updateSelectedElement}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.actions')}
					expanded={true}
				>
					{source && (
						<ActionList
							name={elementName}
							entities={props.entities}
							activeScreen={props.activeScreen}
							actions={props.elementData.actions}
							updateActions={updateActions}
							isList={true}
							listSource={source}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default MapList

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useQuery from '../../../hooks/UseQuery/useQuery'
import { CardMedia } from '@mui/material'

import KozmikLogo from '../../../assets/icons/KozmikLogo.png'

const ErrorPage = () => {
	const { t } = useTranslation()

	const errorData = {
		notFound: {
			title: t('errorData.notFound.title'),
			content: t('errorData.notFound.content')
		},
		tryLater: {
			title: t('errorData.tryLater.title'),
			content: t('errorData.tryLater.content')
		},
		copyError: {
			title: t('errorData.copyError.title'),
			content: t('errorData.copyError.content')
		},
		rateLimiting: {
			title: t('errorData.rateLimiting.title'),
			content: t('errorData.rateLimiting.content')
		}
	}

	const [error, setError] = useState(null)

	let queryParams = useQuery()

	useEffect(() => {
		if (queryParams.type) {
			const data = errorData[queryParams.type]
			data && setError(data)
		} else {
			setError(errorData.notFound)
		}
	}, [])

	return (
		<div
			style={{
				display: 'flex',
				height: '100vh',
				flexWrap: 'wrap',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			{error && (
				<div style={{ maxWidth: 800 }}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							marginBottom: 50
						}}
					>
						<a href='/'>
							<CardMedia
								image={KozmikLogo}
								component='img'
								style={{ width: 75, height: 'auto' }}
							/>
						</a>
					</div>
					<h1 style={{ fontSize: 36, marginBottom: 10, textAlign: 'center' }}>
						{error.title}
					</h1>
					<p style={{ fontSize: 20, textAlign: 'center' }}>{error.content}</p>

					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							marginTop: 60
						}}
					>
						<a href='https://kozmik.io' style={{ fontSize: 14 }}>
							kozmik.io
						</a>
					</div>
				</div>
			)}
		</div>
	)
}

export default ErrorPage

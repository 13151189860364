import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Autocomplete, InputAdornment } from '@mui/material'
import { TipsAndUpdatesOutlined } from '@mui/icons-material'
import cx from 'classnames'

import BorderlessTextField from '../../atoms/borderlessTextField/BorderlessTextField'
import SelectBox from '../selectBox/SelectBox'
import Dropdown from '../dropdown/Dropdown'

const useStyles = makeStyles((theme) => ({
	input: {
		backgroundColor: (props) => (props?.bgColor ? props.bgColor : '#FAFAFC'),
		borderRadius: '3px !important',
		border: 'none',
		'& .MuiInputBase-adornedStart': {
			paddingLeft: 0,
			overflow: 'hidden'
		}
	},
	autocomplete: {
		'& .MuiInputBase-adornedStart': {
			paddingLeft: '0px !important',
			overflow: 'hidden'
		}
	},
	selectBox: {
		paddingLeft: '0px !important',
		overflow: 'hidden'
	},
	inputSize: {
		borderRadius: '3px !important',
		backgroundColor: (props) => (props?.bgColor ? props.bgColor : '#FAFAFC'),
		fontSize: '14px',
		color: theme.custom.colors.textPrimary
	},
	adornment: {
		height: 38,
		backgroundColor: '#F0F0F4',
		padding: '6px 10px',
		color: theme.custom.colors.textPrimary,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	adornmentActive: {
		height: 38,
		backgroundColor: theme.custom.colors.kozmikLightBlue,
		padding: '6px 10px',
		color: theme.custom.colors.kozmikBlue,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	inputIcon: {
		color: 'gray',
		fontSize: 16
	},
	inputIconActive: {
		color: theme.custom.colors.kozmikBlue,
		fontSize: 16
	}
}))

const DoubleStateSelect = (props) => {
	const {
		onOpen,
		secondaryMultiple,
		primaryValueKey,
		secondaryValueKey,
		primaryVal,
		secondaryVal,
		primaryValChange,
		secondaryValChange,
		primaryOptions,
		secondaryOptions,
		primaryLabelKey,
		secondaryLabelKey,
		isSecondaryMode,
		setIsSecondaryMode,
		callback,
		undefinedText,
		bgColor,
		icon,
		...restProps
	} = props

	const classes = useStyles(props)

	const [openP, setOpenP] = useState(false)
	const closePrimary = () => setOpenP(false)
	const openPrimary = () => setOpenP(true)

	const [openS, setOpenS] = useState(false)
	const closeSecondary = () => setOpenS(false)
	const openSecondary = () => {
		onOpen && onOpen()
		setOpenS(true)
	}

	const getLabelFieldName = (valueFieldName, textfieldName) => {
		if (secondaryOptions.length == 0) return 'Name'

		const keys = Object.keys(secondaryOptions?.[0])

		let fieldName = keys?.find((x) => x == textfieldName)
		if (!fieldName) {
			fieldName = keys?.find((x) => x != valueFieldName)
		}

		return fieldName ?? 'Name'
	}

	const switchButton = (secondaryMode, callbackFunc = null) => {
		setIsSecondaryMode(secondaryMode)

		if (callbackFunc) {
			if (secondaryMode) {
				callbackFunc(secondaryMode, secondaryVal)
			} else {
				callbackFunc(secondaryMode, primaryVal)
			}
		}

		if (secondaryMode) {
			openSecondary()
		} else {
			openPrimary()
		}
	}

	return isSecondaryMode ? (
		<SelectBox
			className={cx(classes.input, classes.inputSize, classes.selectBox)}
			onChange={secondaryValChange}
			onOpen={openSecondary}
			open={openS}
			onClose={closeSecondary}
			value={secondaryVal}
			data={secondaryOptions}
			valueKey={secondaryValueKey}
			textKey={getLabelFieldName(secondaryValueKey, secondaryLabelKey)}
			multiple={secondaryMultiple}
			startAdornment={
				<InputAdornment position='start'>
					<Box
						className={classes.adornmentActive}
						onClick={() => switchButton(false, callback ? callback : null)}
					>
						{icon ? (
							<props.icon className={classes.inputIconActive} />
						) : (
							<TipsAndUpdatesOutlined className={classes.inputIconActive} />
						)}
					</Box>
				</InputAdornment>
			}
		/>
	) : (
		<Dropdown
			bgColor={bgColor}
			onItemClick={primaryValChange}
			value={primaryVal}
			data={primaryOptions}
			textKey={primaryLabelKey}
			valueKey={primaryValueKey}
			groupKey={primaryLabelKey}
			groupKeySeperator='.'
			startAdornment={
				<Box
					className={classes.adornment}
					onClick={() => switchButton(true, callback ? callback : null)}
				>
					{icon ? (
						<props.icon className={classes.inputIcon} />
					) : (
						<TipsAndUpdatesOutlined className={classes.inputIcon} />
					)}
				</Box>
			}
		/>
	)
}
export default DoubleStateSelect

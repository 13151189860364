import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Popover, Typography } from '@mui/material'
import {
	MoreVert,
	DeleteOutlined,
	ContentCopy,
	DragIndicator
} from '@mui/icons-material'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import PopoverItem from '../popoverItem/PopoverItem'
import WarningDialog from '../dialog/warningDialog/WarningDialog'

const useStyles = makeStyles((theme) => ({
	menuItem: {
		cursor: 'move',
		textDecoration: 'none !important',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		height: 30,
		// paddingLeft: 10,
		borderRadius: 6,
		justifyContent: 'space-between',
		backgroundColor: '#ffffff',
		'&:hover': {
			backgroundColor: '#FAFAFC',
			'& $moreIcon': {
				visibility: 'visible'
			}
		}
	},
	active: {
		backgroundColor: `${theme.custom.colors.kozmikLightBlue} !important`,
		'& $text': {
			color: theme.custom.colors.kozmikBlue,
			fontWeight: '600'
		},
		'& $moreIcon': {
			visibility: 'visible'
		}
	},
	text: {
		flex: 1,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		fontSize: 14,
		color: theme.custom.colors.textPrimary,
		textAlign: 'left'
	},
	moreIcon: {
		color: theme.custom.colors.textPrimary,
		display: 'block',
		visibility: 'hidden',
		cursor: 'pointer'
	},
	popoverBox: {
		padding: 8,
		width: 200
	},
	popoverIcon: {
		color: '#000000',
		opacity: 0.7,
		width: '14px'
	},
	dragIcon: {
		marginLeft: 5,
		marginRight: 5,
		fontSize: 16,
		color: theme.custom.colors.disabledPrimary
	}
}))

const ScreenMenuItem = (props) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const {
		text,
		deleteItem,
		isActive,
		navigatePreview,
		copyItem,
		nonDraggable,
		...restProps
	} = props

	const [screenPop, setScreenPop] = useState(null)
	const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
		useState(false)

	const openScreenPop = (e) => {
		e.stopPropagation()
		setScreenPop(e.currentTarget)
	}

	const closeScreenPop = () => {
		setScreenPop(null)
	}

	const screenPopOpen = Boolean(screenPop)

	const handleDeleteDialogClose = () => {
		setDeleteConfirmationVisible(false)
	}

	const handleDeleteDialogAccept = () => {
		deleteItem()
		setDeleteConfirmationVisible(false)
	}

	return (
		<div {...restProps}>
			<div
				className={cx(classes.menuItem, isActive ? classes.active : null)}
				style={nonDraggable ? { cursor: 'pointer' } : null}
				onClick={() => {
					navigatePreview()
				}}
			>
				<DragIndicator className={classes.dragIcon} />
				<Typography className={classes.text}>{text}</Typography>
				<MoreVert className={classes.moreIcon} onClick={openScreenPop} />
			</div>
			<Popover
				open={screenPopOpen}
				anchorEl={screenPop}
				onClose={() => {
					closeScreenPop()
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<Box className={classes.popoverBox}>
					<PopoverItem
						text={t('screens.menuItem.copy')}
						onClick={() => {
							closeScreenPop()
							copyItem()
						}}
						color='#000000'
					>
						<ContentCopy className={classes.popoverIcon} />
					</PopoverItem>
					<PopoverItem
						text={t('screens.menuItem.delete')}
						onClick={() => {
							closeScreenPop()
							setDeleteConfirmationVisible(true)
						}}
						color='#FF2619'
					>
						<DeleteOutlined
							className={classes.popoverIcon}
							style={{ color: '#FF2619', opacity: 1 }}
						/>
					</PopoverItem>
				</Box>
			</Popover>
			<WarningDialog
				dialogVisible={deleteConfirmationVisible}
				dialogClose={handleDeleteDialogClose}
				dialogAccept={handleDeleteDialogAccept}
				dialogTitle={t('screens.menuItem.deleteModal.dialogTitle')}
				title={null}
				text={t('screens.menuItem.deleteModal.text')}
				dialogCancelText={t('screens.menuItem.deleteModal.closeButton')}
				dialogAcceptText={t('screens.menuItem.deleteModal.acceptButton')}
			/>
		</div>
	)
}

export default ScreenMenuItem

import { v4 as uuidv4 } from 'uuid'

import i18next from '../../i18n/i18n'
import getEntityFieldsByDataTypes from '../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../common/fieldDataTypes'
import relationTypes from '../../common/enums/relationTypes'
import getFirstFieldExcludingForbidArray from '../../methods/getFirstFieldExcludingForbidArray'
import systemFieldNames from '../../common/systemFieldNames/systemFieldNames'

const method = (table) => {
	const txtFields = getEntityFieldsByDataTypes(
		null,
		null,
		fieldDataTypes.textBased,
		table,
		true,
		relationTypes.one
	)

	const firstTxtField = getFirstFieldExcludingForbidArray(
		txtFields,
		'name',
		systemFieldNames.notVisible
	)

	const object = {
		_uid: uuidv4(),
		component: 'qrCode',
		mapping: {
			text: {
				field: firstTxtField?.name,
				default: firstTxtField?.name ? null : i18next.t('schemas.qrCode.text'),
				type: firstTxtField?.name ? firstTxtField?.dataType : null
			}
		},
		spacing: 'xl',
		actions: [{ type: null }]
	}

	return object
}

export default method

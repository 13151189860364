import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import dark from '../../../assets/images/dark.png'
import light from '../../../assets/images/light.png'
import AppThemeTemplate from '../../templates/appThemeTemplate/AppThemeTemplate'

const colorList = [
	{
		color: '#E22345',
		name: '#E22345'
	},
	{
		color: '#F25757',
		name: '#F25757'
	},
	{
		color: '#F27777',
		name: '#F27777'
	},
	{
		color: '#D98841',
		name: '#D98841'
	},
	{
		color: '#D9A768',
		name: '#D9A768'
	},
	{
		color: '#F2C84C',
		name: '#F2C84C'
	},
	{
		color: '#9DA658',
		name: '#9DA658'
	},
	{
		color: '#1EBF92',
		name: '#1EBF92'
	},
	{
		color: '#2E8C6A',
		name: '#2E8C6A'
	},
	{
		color: '#06AFF2',
		name: '#06AFF2'
	},
	{
		color: '#0440F2',
		name: '#0440F2'
	},
	{
		color: '#A673D9',
		name: '#A673D9'
	}
]

const fontData = [
	{
		text: 'Open Sans',
		value: 'Open Sans'
	},
	{
		text: 'Roboto',
		value: 'Roboto'
	}
]

const AppTheme = () => {
	const { t } = useTranslation()
	const [data, setData] = useState(null)

	const themeData = [
		{
			name: t('appTheme.light'),
			value: 'Light',
			src: light
		},
		{
			name: t('appTheme.dark'),
			value: 'Dark',
			src: dark
		}
	]

	const getData = () => {
		const combinedData = {
			colorList: colorList,
			accentColorList: colorList,
			themeData: themeData,
			fontData: fontData
		}
		setData(combinedData)
	}

	useEffect(() => {
		document.title = t('docTitles.settingsTheme')
		getData()
	}, [])

	return data ? <AppThemeTemplate data={data} /> : null
}

export default AppTheme

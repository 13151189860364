import React, { useEffect, useState, useRef } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import AppDataTables from '../../organisms/appDataTables/AppDataTables'
import AppDataData from '../../organisms/appDataData/AppDataData'

import dataEntityClient from '../../../services/dataEntityClient'
import dataClient from '../../../services/dataClient'
import showToastResponseError from '../../../methods/showToastResponseError'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		height: '100%'
	},
	dataContainer: {
		padding: 5,
		height: 'auto',
		[theme.breakpoints.down('md')]: {
			minHeight: 'calc(100vh - 150px)'
		}
	},
	titleBox: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	tablesTitle: {
		color: '#000000',
		fontSize: 18,
		textAlign: 'left',
		fontWeight: 'bold',
		paddingLeft: '10px'
	},
	tablesBox: {
		marginTop: 10
	},
	addIcon: {
		fontSize: 30,
		cursor: 'pointer'
	},
	popoverBox: {
		padding: 8,
		width: 250
		// maxWidth: 250
	},
	popoverBtn: {
		borderRadius: '4px',
		//minWidth: '80px',
		width: '48%',
		height: '35px'
	},
	btnBlue: {
		backgroundColor: theme.custom.colors.kozmikBlue
	},
	btnRed: {
		backgroundColor: theme.custom.colors.red,
		'&:hover': {
			backgroundColor: theme.custom.colors.darkRed
		}
	},
	popoverInput: {
		width: '100%'
	},
	tableBtn: {
		padding: '5px 4px 5px 20px',
		borderRadius: 4,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		'&:hover $moreIcon': {
			display: 'block'
		}
	},
	tableBtnActive: {
		backgroundColor: theme.custom.colors.gray,
		'& $tableBtnTxt': {
			fontWeight: 'bold'
		},
		'& $moreIcon': {
			display: 'block'
		}
	},
	tableBtnTxt: {
		fontWeight: '600'
	},
	moreIcon: {
		display: 'none'
	}
}))

const AppDataTemplate = (props) => {
	const { t } = useTranslation()
	let { appId } = useParams()
	const classes = useStyles()

	const { tables, setTables, activeTable, setActiveTable, ...restProps } = props

	// const [activeTable, setActiveTable] = useState(null)
	const [metadata, setMetadata] = useState(null)
	const [fieldsData, setFieldsData] = useState([])
	const [entityData, setEntityData] = useState([])
	const [totalRecords, setTotalRecords] = useState(0)

	const [currentRowData, setCurrentRowData] = useState(null)

	const [sortField, setSortField] = useState('Created')
	const [sortDirection, setSortDirection] = useState('desc')
	const [term, setTerm] = useState(null)
	const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(100)

	const getTables = (activeTable) => {
		let params = {}

		dataEntityClient.entities(appId, params).then((response) => {
			if (response?.data?.success) {
				setTables(response.data.data)

				if (activeTable) {
					setActiveTable(activeTable)
				} else if (response.data?.data?.length) {
					setActiveTable(response.data.data[0])
				}
			} else {
				showToastResponseError(response)
			}
		})
	}
	const createTable = (label, onlyOwner = false) => {
		let tableData = {
			label: label,
			onlyOwner: onlyOwner
		}
		dataEntityClient.createEntity(appId, tableData).then((response) => {
			if (response?.data?.success) {
				dataTablesRef.current.closeTablePop()
				tableData['id'] = response.data?.data?.id
				tableData['name'] = response.data?.data?.name

				getTables(response.data?.data)
			} else {
				showToastResponseError(response)
			}
		})
	}
	const editTable = (id, label, onlyOwner = false) => {
		let tableData = {
			id: id,
			label: label,
			onlyOwner: onlyOwner
		}
		dataEntityClient.updateEntity(appId, tableData).then((response) => {
			if (response?.data?.success) {
				dataTablesRef.current.closeEditPop()
				let editedTables = tables.slice()
				let editedTable = editedTables.find((x) => x.id === id)
				editedTable.label = label
				editedTable.onlyOwner = onlyOwner

				setTables(editedTables)
			} else {
				showToastResponseError(response)
			}
		})
	}
	const deleteTable = (id) => {
		const deleteObject = tables.find((x) => x.id === id)
		dataEntityClient.deleteEntity(appId, deleteObject.id).then((response) => {
			if (response?.data?.success) {
				dataTablesRef.current.closeEditPop()
				let editedTables = tables.filter((x) => x.id !== id)
				if (editedTables?.length === 0) {
					getTables()
				} else {
					setTables(editedTables)
					if (activeTable?.id == id) {
						!!editedTables?.length && setActiveTable(editedTables[0])
					}
				}
			} else {
				showToastResponseError(response)
			}
		})
	}

	const fixMetadataCorruptions = (entityId, tablesCopy) => {
		if (!tablesCopy) tablesCopy = [...tables]

		return dataEntityClient.metadata(appId, entityId).then((response) => {
			if (response?.status == 200) {
				const tableData = tablesCopy.find((x) => x.id === entityId)
				const tableIndex = tablesCopy.indexOf(tableData)
				if (tableIndex > -1) {
					tablesCopy.splice(tableIndex, 1)
					tablesCopy.splice(tableIndex, 0, response.data)
				} else {
					tablesCopy.push(response.data)
				}
			} else {
				showToastResponseError(response)
			}
		})
	}

	const getMetadata = (entityId, tables) => {
		const table = tables?.find((x) => x.id === entityId)
		if (table) {
			setMetadata(table)
			setFieldsData(table.fields)
		}
	}

	const createField = (dataType, label, description = null, extra = null) => {
		let data = {
			entityId: activeTable.id,
			name: label,
			label: label,
			dataType: dataType,
			description: description
		}

		if (extra) {
			if (dataType === 'Number') {
				data.precision = extra.precision
			} else if (dataType === 'Date') {
				data.includeTime = extra.includeTime
			} else if (dataType === 'Relation') {
				data.relationType = extra.relationType //one-many
				data.relatedEntityName = extra.relatedEntityName
				data.displayFieldName = extra.displayFieldName
			} else if (dataType === 'Lookup') {
				data.relationName = extra.relationName
				data.displayFieldName = extra.displayFieldName
			} else if (dataType === 'Rollup') {
				data.relationName = extra.relationName
				data.displayFieldName = extra.displayFieldName
				data.aggregation = extra.aggregation
			} else if (dataType === 'Formula') {
				data.formula = extra.formula
			}
		}

		dataEntityClient.createField(appId, data).then(async (response) => {
			if (response?.data?.success) {
				dataRef.current.closeFieldPop()
				dataRef.current.scrollToTopInGrid()

				const tablesCopy = [...tables]

				// reloading data because of lookup fields are enriched on server.
				// if page number equals one, state is not changed so data fetch directly
				if (page === 1) {
					await getTableData(activeTable.name)
					await fixMetadataCorruptions(activeTable.id, tablesCopy)
				} else {
					setPage(1)

					await fixMetadataCorruptions(activeTable.id, tablesCopy)
				}

				// If related entity name is not null, refresh related entity
				if (extra?.relatedEntityName && extra?.relationType === 'Many') {
					const relatedEntity = tables.find(
						(x) => x.name === extra?.relatedEntityName
					)
					if (relatedEntity) {
						await fixMetadataCorruptions(relatedEntity.id, tablesCopy)
					}
				}

				setTables(tablesCopy)
				getMetadata(activeTable.id, tablesCopy)
			} else {
				showToastResponseError(response)
			}
		})
	}
	const editField = (id, dataType, label, description = null, extra = null) => {
		let data = {
			id: id,
			label: label,
			description: description
		}
		!description && delete data.description

		if (extra) {
			if (dataType === 'Number') {
				data.precision = extra.precision
			} else if (dataType === 'Date') {
				data.includeTime = extra.includeTime
			} else if (dataType === 'Rollup') {
				data.relationName = extra.relationName
				data.displayFieldName = extra.displayFieldName
				data.aggregation = extra.aggregation
			} else if (dataType === 'Formula') {
				data.formula = extra.formula
			}

			if (extra.dataType) {
				data.dataType = extra.dataType
			}
		}

		dataEntityClient.updateField(appId, data).then(async (response) => {
			if (response?.data?.success) {
				dataRef.current.closeEditPop()

				// updating fields data
				let editedFields = fieldsData.slice()
				let editedField = editedFields?.find((x) => x.id === id)
				editedField.label = label
				if (description) {
					editedField.description = description
				}

				let refreshData = false
				if (extra) {
					if (dataType === 'Number') {
						editedField.precision = extra.precision

						refreshData = true
					} else if (dataType === 'Date') {
						editedField.includeTime = extra.includeTime

						refreshData = true
					} else if (dataType === 'Rollup') {
						editedField.relationName = extra.relationName
						editedField.displayFieldName = extra.displayFieldName
						editedField.aggregation = extra.aggregation

						refreshData = true
					} else if (dataType === 'Formula') {
						editedField.formula = extra.formula

						refreshData = true
					}

					if (extra.dataType) {
						editedField.dataType = extra.dataType
					}
				}

				setFieldsData(editedFields)

				if (refreshData) {
					// reloading data because of lookup fields are enriched on server.
					// if page number equals one, state is not changed so data fetch directly
					if (page === 1) {
						await getTableData(activeTable.name)
					} else {
						setPage(1)
					}
				}

				// updating tables data
				let editedTables = tables.slice()
				let editedTable = editedTables.find((x) => x.id === activeTable.id)
				editedTable.fields = editedFields
				setTables(editedTables)
			} else {
				showToastResponseError(response)
			}
		})
	}
	const deleteField = (id) => {
		const deleteObject = fieldsData.find((x) => x.id === id)
		dataEntityClient.deleteField(appId, deleteObject.id).then((response) => {
			if (response?.data?.success) {
				// updating fields data
				let editedFields = fieldsData.filter((x) => x.id !== id)
				setFieldsData(editedFields)

				// updating tables data
				let editedTables = tables.slice()
				let editedTable = editedTables.find((x) => x.id === activeTable.id)
				editedTable.fields = editedFields
				setTables(editedTables)
			} else {
				showToastResponseError(response)
			}
		})
	}

	const reOrder = (fieldNames) => {
		const data = {
			id: activeTable.id,
			fields: fieldNames
		}

		dataEntityClient.reOrder(appId, data).then(async (response) => {
			if (response?.data?.success) {
				const orderedFieldsData = [...fieldsData].sort(
					(a, b) => fieldNames.indexOf(a.name) - fieldNames.indexOf(b.name)
				)
				setFieldsData(orderedFieldsData)

				let editedTables = tables.slice()
				let editedTable = editedTables.find((x) => x.id === activeTable.id)
				editedTable.fields = orderedFieldsData
				setTables(editedTables)
			} else {
				showToastResponseError(response)
			}
		})
	}

	const getRowData = (tableName, rowId) => {
		if (!rowId) return Promise.resolve()

		return dataClient.detailData(appId, tableName, rowId).then((response) => {
			if (response.data) {
				setCurrentRowData(response.data)
			} else {
				showToastResponseError(response)
			}
		})
	}

	const filters = {
		sortField: sortField,
		sortDirection: sortDirection,
		term: term,
		page: page,
		rows: pageSize
	}

	const getTableData = (tableName) => {
		if (!tableName) return

		return dataClient
			.postSearchData(appId, tableName, filters)
			.then((response) => {
				if (response?.data) {
					const result = response.data.records.map((x) => x.fields)

					// if page number equals to one, entity data is being cleaned.
					// Otherwise existing data and new data are merged.
					if (page <= 1) {
						setEntityData(result)
					} else {
						const mergedRows = entityData.concat(result)
						const uniqRows = mergedRows.filter((obj, index) => {
							return index === mergedRows.findIndex((o) => obj.Id === o.Id)
						})
						setEntityData(uniqRows)
					}

					setTotalRecords(response.data.total)
				} else {
					showToastResponseError(response)
					setEntityData([])
					setTotalRecords(0)
				}
			})
	}

	const search = (term) => {
		setTerm(term)
		setPage(1)
	}

	const sort = (sortModal) => {
		if (!sortModal || sortModal.length === 0) return

		setSortField(sortModal[0].field)
		setSortDirection(sortModal[0].sort)
		setPage(1)
	}

	const createRow = async () => {
		let data = {}
		return dataClient
			.insertData(appId, activeTable.name, data)
			.then((response) => {
				if (response?.data?.success) {
					data['Id'] = response.data?.unique?.Value

					setCurrentRowData(data)

					return data.Id
				} else {
					showToastResponseError(response)
				}
			})
	}
	const updateRow = (
		rowId,
		field,
		value,
		oldVal,
		withLoader = false,
		reload = false,
		objectProp = null //For relation dataType
	) => {
		let data = {
			Id: rowId
		}
		if (objectProp) {
			if (Array.isArray(value)) {
				data[field] =
					value?.length > 0 ? value.map((x) => ({ Id: x[objectProp] })) : []
			} else {
				data[field] = value?.[objectProp] ? { Id: value[objectProp] } : null
			}
		} else {
			data[field] = value
		}

		const currentRows = entityData.slice()

		let editedRowIndex = currentRows.findIndex((x) => x.Id === data.Id)

		if (editedRowIndex >= 0) {
			//TODO:Tablolar arasında gezinirken önceki call bitmemişse tablo buglanıyor
			if (withLoader) {
				const editedRows = entityData.slice()
				editedRows[editedRowIndex][field] = 'kozmik_loading_spinner'
				setCurrentRowData(editedRows[editedRowIndex])
			}
			dataClient
				.updateData(appId, activeTable.name, data)
				.then((response) => {
					if (response?.data?.success) {
						setCurrentRowData((prev) => {
							const editedRows = entityData.slice()
							editedRows[editedRowIndex][field] = value

							return { ...prev, ...editedRows[editedRowIndex] }
						})

						closecustomEditPops()

						if (reload && activeTable) {
							getRowData(activeTable.name, rowId)
						}
					} else {
						setCurrentRowData((prev) => {
							const editedRows = entityData.slice()
							editedRows[editedRowIndex][field] = oldVal

							return { ...prev, ...editedRows[editedRowIndex] }
						})

						closecustomEditPops()
						showToastResponseError(response)
					}
				})
				.catch((err) => {
					setCurrentRowData((prev) => {
						const editedRows = entityData.slice()
						editedRows[editedRowIndex][field] = oldVal

						return { ...prev, ...editedRows[editedRowIndex] }
					})

					closecustomEditPops()
				})
		} else {
			console.log('editedRow undefined')
		}
	}
	const deleteRows = (data) => {
		dataClient
			.bulkDeleteData(appId, activeTable.name, data)
			.then((response) => {
				let currentRows = entityData.slice()
				response?.data?.forEach((element) => {
					if (element?.success) {
						currentRows = currentRows.filter(
							(x) => x.Id !== element?.unique?.Value
						)
						setEntityData(currentRows)
					} else {
						toast.error(
							element?.message || element?.errorDetails || t('api.error')
						)
					}
				})

				const numberOfRemovedRecords =
					response?.data?.filter((x) => x?.success)?.length ?? 0

				setTotalRecords(
					totalRecords === 0 ? 0 : totalRecords - numberOfRemovedRecords
				)
			})
	}

	const closecustomEditPops = () => {
		dataRef?.current?.closeMultiLinePop()
	}

	useEffect(() => {
		!!tables?.length && !activeTable && setActiveTable(tables[0])
	}, [tables])

	useEffect(async () => {
		if (activeTable) {
			setFieldsData([])
			setEntityData([])
			setTotalRecords(0)
			getMetadata(activeTable.id, tables)
			setTerm(null)
			setSortField('Created')
			setSortDirection('desc')
			setPage(1)
		}
	}, [activeTable])

	useEffect(() => {
		if (!currentRowData) return

		setEntityData((prevItems) => {
			const currentObjectIndex = prevItems.findIndex(
				(x) => x.Id === currentRowData.Id
			)

			if (currentObjectIndex >= 0) {
				const rowsData = [...prevItems]
				rowsData[currentObjectIndex] = {
					...rowsData[currentObjectIndex],
					...currentRowData
				}

				return rowsData
			} else {
				let rowsData = prevItems.slice()
				if (rowsData.length % pageSize === 0) {
					rowsData.splice(rowsData.length - 1, 1)
				}
				rowsData.unshift(currentRowData)

				setTotalRecords(totalRecords + 1)

				return rowsData
			}
		})
	}, [currentRowData])

	useEffect(async () => {
		if (metadata) {
			await getTableData(metadata.name)
		}
	}, [page, metadata])

	useEffect(async () => {
		await getTableData(metadata?.name)
	}, [term, sortField, sortDirection])

	useEffect(() => {
		document.title = t('docTitles.data')
	}, [])

	const dataTablesRef = useRef()
	const dataRef = useRef()

	return (
		<>
			<Grid container className={classes.mainContainer} flexDirection='column'>
				<Grid
					container
					item
					xs={12}
					flexDirection='column'
					className={classes.dataContainer}
				>
					<AppDataTables
						ref={dataTablesRef}
						tables={tables}
						setTables={setTables}
						activeTable={activeTable}
						setActiveTable={setActiveTable}
						createTable={createTable}
						editTable={editTable}
						deleteTable={deleteTable}
					/>

					{!!tables?.length && (
						<AppDataData
							ref={dataRef}
							tables={tables}
							search={search}
							sort={sort}
							metadata={metadata}
							fieldsData={fieldsData}
							createField={createField}
							editField={editField}
							reOrder={reOrder}
							deleteField={deleteField}
							entityData={entityData}
							totalRecords={totalRecords}
							page={page}
							setPage={setPage}
							pageSize={pageSize}
							setPageSize={setPageSize}
							createRow={createRow}
							updateRow={updateRow}
							deleteRows={deleteRows}
							builderOnMobile={props.builderOnMobile}
						/>
					)}
				</Grid>
			</Grid>
		</>
	)
}

const mapStateToProps = (state) => ({
	...state.userInfo
})

export default connect(mapStateToProps)(AppDataTemplate)

import i18n from '../i18n/i18n'
import { toast } from 'react-toastify'

const showToastResponseError = (response) => {
	if (response.status === 429) {
		toast.error(i18n.t('api.rateLimiting'))
	} else {
		toast.error(
			response?.data?.message ||
				response?.data?.errorDetails ||
				i18n.t('api.error')
		)
	}
}

export default showToastResponseError

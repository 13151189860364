import { createTheme } from '@mui/material/styles'

import kozmikTheme from './theme.json'

const theme = createTheme({
	typography: {
		fontFamily: ['Open Sans', 'sans-serif'].join(',')
	},
	custom: kozmikTheme
})

export default theme

import React from 'react'
import withStyles from '@mui/styles/withStyles'
import Slider from '@mui/material/Slider'

const CustomSlider = withStyles((theme) => ({
	root: {
		color: theme.custom.colors.kozmikBlue
	}
}))(Slider)

const KozmikSlider = (props) => {
	return <CustomSlider {...props} />
}
export default KozmikSlider
